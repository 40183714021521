import React, { useState, useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './radio-buttons-group.scss';

export default function RadioButtonsGroup({ options = [], handleChange, row = false, defaultValue = '0' }) {
    const [value, setValue] = useState(options ? options[0].value : '0');

    const setNewValue = (event) => {
        setValue(event.target.value);
        if (handleChange) {
            handleChange(options.filter(x => x.value === event.target.value)[0].value)
        }
    };

    useEffect(() => {
        setValue(defaultValue);
        handleChange(defaultValue);
    }, [defaultValue, handleChange])

    return (
        <FormControl component="fieldset">
            <RadioGroup row={row} value={value} onChange={setNewValue}>
                {options.map((option, index) => <FormControlLabel className="radio-button-label" key={index} value={option.value} control={<Radio />} label={option.label} />)}
            </RadioGroup>
        </FormControl>
    );
}
