import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { generalStyleClasses } from '../../../../utils/general-styles';
import InterestedSectors from '../../../../shared/InvestorPreferancesInputs/InterestedSectors';
import InovationEnablers from '../../../../shared/InvestorPreferancesInputs/InovationEnablers';
import { useMutation } from '@apollo/client';
import LessInterestedSectors from '../../../../shared/InvestorPreferancesInputs/LessInterestedSectors';
import LessInterestedInovationEnablers from '../../../../shared/InvestorPreferancesInputs/LessInterestedInovationEnablers';
import AdditionalInformations from '../../../../shared/InvestorPreferancesInputs/AdditionalInformation';
import { EventThrower, Events } from '../../../../shared/EventEmitter/EventEmitter';
import { UPDATE_INVESTOR_PROFILE_INDUSTRY } from '../../../../utils/investor-preferances-queries';
import { useSelector } from 'react-redux';

const Industry = ({ nextStep }) => {
    const classes = generalStyleClasses();
    const [additionalInformations, setAdditionalInformations] = useState("");
    const [interestedSectors, setInterestedSectors] = useState([]);
    const [inovationEnablers, setInovationEnablers] = useState([]);
    const [lessInterestedSectors, setLessInterestedSectors] = useState([]);
    const [lessInovationEnablers, setLessInovationEnablers] = useState([]);
    const [updateIndustryPreferences] = useMutation(UPDATE_INVESTOR_PROFILE_INDUSTRY);
    const investorProfileId = useSelector(store => store.adminCreateAccount.investorProfileId);
    const clientCreationId = useSelector(store => store.adminCreateAccount.accountId);

    const handleSaveChanges = () => {
        const industryPreferencesData = {
            firebase_id: clientCreationId,
            sectors: interestedSectors.map(x => ({ sector: x.value, profile_id: investorProfileId })),
            enablers: inovationEnablers.map(x => ({ enabler: x.value, profile_id: investorProfileId })),
            industry_additional_information: additionalInformations,
            sectors_less: lessInterestedSectors.map(x => ({ sector: x.value, profile_id: investorProfileId })),
            enablers_less: lessInovationEnablers.map(x => ({ enabler: x.value, profile_id: investorProfileId })),
        }
        updateIndustryPreferences({ variables: industryPreferencesData })
            .then(() => nextStep())
            .catch(() => {
                EventThrower.throwClientSideError(Events.InformationChangedError)
            });
    }

    return (
        <Fragment>
            <InterestedSectors handleChange={value => setInterestedSectors(value)} fullWidth={true} />
            <InovationEnablers handleChange={value => setInovationEnablers(value)} rootClasses={classes.marginTop16} fullWidth={true} />
            <LessInterestedSectors handleChange={value => setLessInterestedSectors(value)} rootClasses={classes.marginTop16} fullWidth={true} />
            <LessInterestedInovationEnablers handleChange={value => setLessInovationEnablers(value)} rootClasses={classes.marginTop16} fullWidth={true} />
            <AdditionalInformations handleChange={setAdditionalInformations} rootClasses={classes.marginTop16} />
            <div className={[classes.flexJustifyEnd, classes.marginTop32].join(' ')}>
                <Button variant="contained" color="primary" onClick={handleSaveChanges} style={{ width: "200px" }} > Next </Button>
            </div>
        </Fragment >
    )
}


export default Industry;