import { createMuiTheme } from "@material-ui/core/styles";

const buttonStyleOverride = {
  fontFamily: "Roboto",
  fontWeight: "400",
  fontStyle: "normal",
  fontSize: "14px",
  lineHeight: "16px",
  textTransform: "none",
  boxShadow: "none",
};

export default createMuiTheme({
  palette: {
    primary: {
      main: "#434448",
      "&$:disabled": {
        color: "red",
      },
    },
    secondary: {
      main: "#796E6E",
    },
    action: {
      disabled: "#F9F9F9",
      disabledBackground: "#D8CECE",
    },
    info: {
      main: "#ffffff",
    },
  },
  MuiLinearProgress: {
    colorPrimary: "#F4F4F4",
    bar1Determinate: {
      backgroundColor: "#1AA684",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#47567B",
        textTransform: "none",
        "&:disabled": {
            color: "red !important"
        }
      },
      containedPrimary: {
        ...buttonStyleOverride,
        height: "48px",
        textTransform: "none",
        "&:disabled": { color: "#dbdbdb" },
      },
      containedSecondary: {
        ...buttonStyleOverride,
        height: "40px",
        textTransform: "none",
      },
      outlinedPrimary: {
        ...buttonStyleOverride,
        height: "48px",
        textTransform: "none",
        "&:disabled": { color: "#dbdbdb" },
      },
      outlinedSecondary: {
        ...buttonStyleOverride,
        height: "40px",
        textTransform: "none",
      },
    },
    MuiLinearProgress: {
      colorPrimary: "#F4F4F4",
      bar1Determinate: {
        backgroundColor: "#1AA684",
      },
    },
  },
});
