import { useMutation, useQuery } from '@apollo/client';
import React, { Fragment, useEffect, useState } from 'react';
import { CANCEL_DEAL, GET_ALL_COMPANIES, PUBLISH_COMPANY, UNPUBLISH_COMPANY } from '../../queries/admin-queries';
import Loading from '../../../shared/Loading/Loading'
import { Button, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from '../../AdminStyles';
import { Events, EventThrower, hasuraErrorHandler } from '../../../shared/EventEmitter/EventEmitter';
import OrderButtons from '../../../shared/OrderButtons/OrderButtons';
import { unpublishCompany } from '../../queries/admin-http-calls';
import PublishIcon from '@material-ui/icons/Publish';

const CompanyRow = ({ company }) => {
  const typographyClasses = adminTypografyStyles();
  const tableClasses = adminTablesStyle();
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const [publishCompany] = useMutation(PUBLISH_COMPANY, {variables: {id: company.id}});
  const [published, setPublished] = useState(!company.unpublished_at)
  const handleUnpublishCompany = () => {
    unpublishCompany(company.id).then(() => {
      EventThrower.throwClientSideMessage(Events.SuccessMessage("Company and related deals unpublished"));
      setPublished(false);
    }).catch(err => {
      console.log(err);
      hasuraErrorHandler.printError("Error unpublishing the company")
    });
  }

  const handlePublishCompany = () => {
    publishCompany().then(() => {
      EventThrower.throwClientSideMessage(Events.SuccessMessage("Company published"));
      setPublished(true);
    }).catch(err => {
      console.log(err);
      hasuraErrorHandler.printError("Error publishing the company")
    });
  }
  return (
    <div className={[dimensionClasses.containerWidth, alignmentClasses.flexAlignCenter, tableClasses.row, typographyClasses.fontSize14].join(' ')} style={{color: !published ? "rgb(171, 171, 171)" : ""}}>
      <p className={dimensionClasses.width200}>{company.name}</p>
      <p className={dimensionClasses.width200}>{new Date(company.created_at).toLocaleDateString()}</p>
      <p className={dimensionClasses.width200}>{new Date(company.updated_at).toLocaleDateString()}</p>
      <p className={dimensionClasses.widthGrow}>{company.Deals_aggregate?.aggregate?.count || '-'}</p>
      <Tooltip arrow title="Edit">
        <IconButton style={{ padding: "4px", marginLeft: "8px" }} component={Link} to={`/admin/opportunities/edit-company/${company.id}`}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={published ? "Unpublish" : "Publish"}>
        {
          published
          ? (
          <IconButton style={{ padding: "4px", marginLeft: "8px" }} onClick={() => handleUnpublishCompany()}>
            <DeleteIcon />
          </IconButton>
          )
          : (
            <IconButton style={{ padding: "4px", marginLeft: "8px" }} onClick={() => handlePublishCompany()}>
              <PublishIcon />
            </IconButton>
            )
        }
      </Tooltip>
    </div>
  )
}

const CompanyListing = ({ nameFilter }) => {
  const typographyClasses = adminTypografyStyles();
  const tableClasses = adminTablesStyle();
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const [createdOrder, setCreatedOrder] = useState(null);
  const [updatedOrder, setUpdatedOrder] = useState(null);
  const [dealsOrder, setDealsOrder] = useState(null);
  const [nameOrder, setNameOrder] = useState(null);
  const [companies, setCompanies] = useState([]);
  const { data } = useQuery(GET_ALL_COMPANIES, { fetchPolicy: "network-only", variables: { created_at: createdOrder, updated_at: updatedOrder, countOrder: dealsOrder, name: nameOrder, nameFilter: `%${nameFilter}%` } });

  const orderFunctions = [setCreatedOrder, setUpdatedOrder, setNameOrder, setDealsOrder];
  useEffect(() => {
    if (data) {
      hasuraErrorHandler.checkResponseForErrors(data);
      setCompanies(data.Company);
    }
  }, [data])

  return (
    <Fragment>
      <div className={[dimensionClasses.containerWidth, alignmentClasses.flexAlignCenter, dimensionClasses.headerMarginFix, tableClasses.tableHeader, typographyClasses.fontSize14].join(' ')}>
        <div className={[dimensionClasses.width200, alignmentClasses.flexCenterSpaceBetween, dimensionClasses.marginTop24].join(' ')}>
          Company
          <OrderButtons value={nameOrder} orderFunctions={orderFunctions} specificFunction={value => setNameOrder(value)} />
        </div>
        <div className={[dimensionClasses.width200, alignmentClasses.flexCenterSpaceBetween, dimensionClasses.marginTop24].join(' ')}>
          Date Added
          <OrderButtons value={createdOrder} orderFunctions={orderFunctions} specificFunction={value => setCreatedOrder(value)} />
        </div>
        <div className={[dimensionClasses.width200, alignmentClasses.flex, dimensionClasses.marginTop24].join(' ')}>
          <span className={dimensionClasses.marginRight50}>Last modified</span>
          <OrderButtons value={updatedOrder} orderFunctions={orderFunctions} specificFunction={value => setUpdatedOrder(value)} />
        </div>
        <div className={[dimensionClasses.width200, alignmentClasses.flex, dimensionClasses.marginTop24].join(' ')}>
          <span className={dimensionClasses.marginRight50}>Published deals</span>
          <OrderButtons value={dealsOrder} orderFunctions={orderFunctions} specificFunction={value => setDealsOrder(value)} />
        </div>
        <Button variant="outlined" color="primary" className={[dimensionClasses.smallHeigth, dimensionClasses.marginTop24].join(' ')} component={Link} to="/admin/opportunities/add-company">
          Add company
        <AddIcon />
        </Button>
      </div>
      {
        companies.map(x => <CompanyRow key={x.id} company={x} />)
      }
    </Fragment>
  )

}

export default CompanyListing;