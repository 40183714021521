import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { topsideMenuStyles } from './TopsideMenuStyle';
import { Button, FormControl } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { generalStyleClasses } from "../../../../../utils/general-styles";
import { SET_DEAL_FILTERS } from '../../../../../store/utils/action-types';
import { Filters } from '../Filters/filterHolder';
import { debounce } from 'lodash';

const sortTypes = {
  RECENT: "RECENT" ,
  DEADLINE: "DEADLINE" ,
  NAME: "NAME" ,
  VALUATION: "VALUATION" ,
  OFFERING: "OFFERING",
}

const sortDetails = [
  { type: sortTypes.RECENT, up: "Most recent", low: "Least recent" },
  { type: sortTypes.DEADLINE, up: "Closest deadline", low: "Furthest deadline" },
  { type: sortTypes.NAME, up: "Name A-Z", low: "Name Z-A" },
  { type: sortTypes.VALUATION, up: "Lowest valuation", low: "Highest valuation" },
  { type: sortTypes.OFFERING, up: "Lowest offering", low: "Highest offering" }
];

const TopsideMenu = ({ isWatchlist = false }) => {
  const classes = topsideMenuStyles();
  const generalStyles = generalStyleClasses();
  const dispatch = useDispatch();
  const [sortField, setSortField] = useState(sortDetails[0])
  const [sortDirection, setSortDirection] = useState(true);
  const { url } = useRouteMatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showClosestDeadline = () => {
    return window.location.href.indexOf("active") !== -1 || window.location.href.indexOf("prospective") !== -1
  }

  const handleSetDealType = (type) => {
    if (!isWatchlist) {
      Filters.setDealType(type);
      Filters.page = 0;
      dispatch({ type: SET_DEAL_FILTERS, payload: Filters.getContent() });
    }
  }

  const handleSearchChange = debounce(value => {
        Filters.setSeatchText(value)
        Filters.page = 0;
        dispatch({ type: SET_DEAL_FILTERS, payload: Filters.getContent() })
      }, 300);
  

  const handleSetNewSort = (type) => {
    Filters.page = 0;
    setSortField(sortDetails.filter(x => x.type === type)[0]);
    handleClose();
  }

  const changeSortDirection = () => {
    Filters.page = 0;
    setSortDirection(!sortDirection);
  }
  
  useEffect(() => {
   Filters.setSorting(sortField.type, sortDirection)
    dispatch({type: SET_DEAL_FILTERS, payload: Filters.getContent()});
  }, [sortDirection, sortField])


  return (
    <div className={[generalStyles.mainPageWidth, generalStyles.flexSpaceBetweenCenter].join(' ')}>
      <FormControl>
        <InputBase
          id="input-with-icon-adornment"
          inputProps={{ 'aria-label': 'naked' }}
          placeholder="Search..."
          onChange={e => handleSearchChange(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
      <div className={classes.buttonGroup}>
        {isWatchlist ? <span className={[generalStyles.fontSize16, generalStyles.fontColorDark].join(' ')} style={{ marginRight: "16px" }}>Watchlist</span> : ""}
        <ButtonGroup variant="contained" color="inherit" aria-label="contained primary button group" disableElevation>
          <NavLink
            className={[classes.buttonGroupItem, generalStyles.fontSize15, generalStyles.fontColorLight].join(' ')}
            activeClassName={classes.activeLink} to={`${url}/all`}
            onClick={() => handleSetDealType("")}>All</NavLink>
          <NavLink
            className={[classes.buttonGroupItem, generalStyles.fontSize15, generalStyles.fontColorLight].join(' ')}
            activeClassName={classes.activeLink}
            to={`${url}/active`}
            onClick={() => handleSetDealType("ACTIVE")}>Active</NavLink>
          <NavLink
            className={[classes.buttonGroupItem, generalStyles.fontSize15, generalStyles.fontColorLight].join(' ')}
            activeClassName={classes.activeLink}
            to={`${url}/prospective`}
            onClick={() => handleSetDealType("PROSPECTIVE")}>Prospective</NavLink>
          <NavLink
            className={[classes.buttonGroupItem, generalStyles.fontSize15, generalStyles.fontColorLight].join(' ')}
            activeClassName={classes.activeLink}
            to={`${url}/closed`}
            onClick={() => handleSetDealType("CLOSED")}>Closed</NavLink>
        </ButtonGroup>
      </div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={[classes.dropdownButton, generalStyles.fontSize13, generalStyles.fontColorLight].join(' ')}>
        { sortDirection ? sortField.up : sortField.low }
          <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSetNewSort(sortTypes.RECENT)} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Published date
        </MenuItem>
        {
          showClosestDeadline()
            ? (
              <MenuItem onClick={() => handleSetNewSort(sortTypes.DEADLINE)} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
                Deadline
              </MenuItem>
            )
            : ""
        }
        <MenuItem onClick={() => handleSetNewSort(sortTypes.NAME)} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Name
        </MenuItem>
        <MenuItem onClick={() => handleSetNewSort(sortTypes.VALUATION)} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Valuation
        </MenuItem>
        <MenuItem onClick={() => handleSetNewSort(sortTypes.OFFERING)} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Offering
        </MenuItem>
      </Menu>
      <div className={classes.sortArrows}>
        <svg onClick={() => changeSortDirection()} className={!sortDirection ? classes.activeSortArrow : ''} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.25 1.83588e-06L6 6L0.75 0L11.25 1.83588e-06Z" fill="#A9A9A9" />
        </svg>
        <svg onClick={() => changeSortDirection()} className={sortDirection ? classes.activeSortArrow : ''} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.75 6L6 4.5897e-07L11.25 6L0.75 6Z" fill="#A9A9A9" />
        </svg>
      </div>
    </div>
  );
}

export default TopsideMenu