export const mapToStatistics = (data) => {
  const preferedSectors = { maxCount: 0 };
  const leastPreferedSectors = { maxCount: 0 };
  const preferedInovations = { maxCount: 0 };
  const leastPreferedInovations = { maxCount: 0 };

  const addToList = (list, data) => {
    data.filter(x => x).forEach(x => {
      if (!list[x.value]) {
        list[x.value] = { count: 1, name: x.description }
      }
      else {
        list[x.value].count += 1;
      }
      list.maxCount += 1;
    })
  }

  data.Account
    .filter(account => account.investor_profile.length > 0)
    .map(account => account.investor_profile[0])
    .forEach(investorProfile => {
      addToList(preferedSectors, investorProfile.interested_sectors.map(x => x.ENUM_interested_sector))
      addToList(leastPreferedSectors, investorProfile.investor_profile_interested_sectors_lesses.map(x => x.ENUM_interested_sector))
      addToList(preferedInovations, investorProfile.innovation_enablers.map(x => x.ENUM_innovation_enabler))
      addToList(leastPreferedInovations, investorProfile.investor_profile_innovation_enablers_lesses.map(x => x.ENUM_innovation_enabler))
    })

  return {
    preferedSectors,
    leastPreferedSectors,
    preferedInovations,
    leastPreferedInovations,
  }
}

export const combineCompanyClicksAnalytics = (data) => {
  const result = [];
};

const combineFeedbacks = (feedbackArray = [], defaultFields) => {
  const result = {
    maxCount: feedbackArray.length,
    ...defaultFields
  }
  feedbackArray.forEach(feedback => {
    if (result[feedback]) {
      result[feedback].count += 1;
    } else {
      result[feedback] = {count: 1, name: feedback};
    }
  })
  return result
}

const feedbackDefaultOptions = {
  company: () => ({
    "To early stage": {count: 0, name: "To early stage"},
    "Unclear competitive advantage": {count: 0, name: "Unclear competitive advantage"},
    "Uncompelling business model": {count: 0, name: "Uncompelling business model"},
    "Limited ability to scale": {count: 0, name: "Limited ability to scale"},
    "Management team": {count: 0, name: "Management team"},
    "Other": {count: 0, name: "Other"},
  }),
  industry: () => ({
    "Not Relevant": {count: 0, name: "Not Relevant"},
    "Small market": {count: 0, name: "Small market"},
    "Limited growth prospect": {count: 0, name: "Limited growth prospect"},
    "Intense competition": {count: 0, name: "Intense competition"},
    "Other": {count: 0, name: "Other"},
  }),
  investment: () => ({
    "Unattractive valuation": {count: 0, name: "Unattractive valuation"},
    "Types of securities": {count: 0, name: "Types of securities"},
    "Short timeline": {count: 0, name: "Short timeline"},
    "High Fees": {count: 0, name: "High Fees"},
    "Other": {count: 0, name: "Other"},
  })
}
export const mapFeedback = (data) => {
  const result = data.map(company => ({
    companyName: company.name,
    deals: company.Deals.map(deal => {
      const dealData = {
        dealName: deal.title,
        feedbacks: {
          company: combineFeedbacks(deal.Interest_feedbacks.map(x => x.company.split(',')).reduce((a, b) => a.concat(b), []), feedbackDefaultOptions.company()),
          industry: combineFeedbacks(deal.Interest_feedbacks.map(x => x.industry.split(',')).reduce((a, b) => a.concat(b), []), feedbackDefaultOptions.industry()),
          investment: combineFeedbacks(deal.Interest_feedbacks.map(x => x.investment.split(',')).reduce((a, b) => a.concat(b), []), feedbackDefaultOptions.investment()),
          other: deal.Interest_feedbacks.map(x => x.other).reduce((a, b) => a.concat(b), [])
        }
      }
      return dealData;
    })
  }))
  return result;
}