import React, { Fragment, useEffect, useRef } from 'react';
import AddOpportunityHeader from './AddOpportunityHeader';
import AddOpportunitySections from './AddOportunitySections';
import OpportunityDetails from './OpportunitySections/OpportunityDetails';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_OPPORTUNITY, GET_DEAL_DETAILS_BY_ID } from '../../queries/admin-queries';
import { adminOpportunityActions } from '../../../store/slices/adminOpportunity';
import Loading from '../../../shared/Loading/Loading';
import { DealSectionType } from '../../../utils/enums';
import { mapDealForCreate, mapDealForEdit } from './opportunityMappers';
import { addDocumentsForSection, addImageForSection, fileDeleter } from '../../../utils/file-queries';
import { Events, EventThrower, hasuraErrorHandler } from '../../../shared/EventEmitter/EventEmitter';
import { cloneFlowForDeal, publishDeal } from '../../queries/admin-http-calls';
import { sectionInserts, sectionUpdates, sectionDeletes } from './updateQueryBuilders';

const canSaveOpportunity = (opportunity) => {
  if (!opportunity.companyId) {
    hasuraErrorHandler.printError("Company not specified");
    return false;
  }
  if (!opportunity.type || opportunity.type === 'VOIDED' || opportunity.type === 'CANCELED') {
    hasuraErrorHandler.printError("Opportunity status not specified");
    return false;
  }
  if (!opportunity.offeringType) {
    hasuraErrorHandler.printError("Offering type not specified");
    return false;
  }
  if (!opportunity.securityType) {
    hasuraErrorHandler.printError("Security type not specified");
    return false;
  }
  if (new Date(opportunity.deadline) < new Date()) {
    hasuraErrorHandler.printError("Date cannot be set in the past");
    return false;
  }
  if (!opportunity.valuation) {
    hasuraErrorHandler.printError("Valuation not specified");
    return false;
  }
  if (!opportunity.investmentMin) {
    hasuraErrorHandler.printError("Minimum investment value not specified");
    return false;
  }
  if (!opportunity.investmentMax) {
    hasuraErrorHandler.printError("Maximum investment value not specified");
    return false;
  }
  if (opportunity.investmentMin > opportunity.investmentMax) {
    hasuraErrorHandler.printError("Max investment value must be higher than the min. one");
    return false;
  }
  if (opportunity.investmentMax > opportunity.valuation) {
    hasuraErrorHandler.printError("Max investment value must be lower than the total offered ammount");
    return false;
  }
  return true;
}


const useOpportunityProcessing = (opportunityRef, dispatch) => {
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const history = useHistory();
  const [createOpportunity] = useMutation(CREATE_OPPORTUNITY);

  // deal with image and documents
  const apolloClient = useApolloClient();
  return async () => {
    const { saving, drafting, publishing, resultingOpportunity, selectedOpportunity, sectionsToDelete, itemsToDelete } = opportunityRef.current || {}

    const result = { id: selectedOpportunity?.id || 0 };
    if (!saving) return;
    if (drafting && canSaveOpportunity(resultingOpportunity)) {
      if (selectedOpportunity && selectedOpportunity.id) {
        // edit opportunity
        const company_management_order = resultingOpportunity.sections.find(x => x.type === DealSectionType.MANAGEMENT)?.order || -1;
        const company_description_order = resultingOpportunity.sections.find(x => x.type === DealSectionType.DESCRIPTION)?.order || -1;
        const funding_history_order = resultingOpportunity.sections.find(x => x.type === DealSectionType.FUNDING)?.order || -1;
        apolloClient.mutate({
          // investment_amount_available: ${Math.max(resultingOpportunity.totalOfferedAmount - (selectedOpportunity.totalOfferedAmount - resultingOpportunity.amountAvailable), 0)}
          mutation: gql`
          mutation UpdateDeal {
            update_Deal_by_pk(
              pk_columns: { id: ${resultingOpportunity.id} }
              _set: {
                company_id: ${resultingOpportunity.companyId}
                company_management_order: ${company_management_order}
                company_description_order: ${company_description_order}
                funding_history_order: ${funding_history_order}
                investment_deadline: ${new Date(resultingOpportunity.deadline).valueOf()}
                investment_curency: "${resultingOpportunity.currency}"
                fees: "${resultingOpportunity.fees}"
                valuation: ${resultingOpportunity.valuation}
                status: ${resultingOpportunity.type}
                investment_min: ${resultingOpportunity.investmentMin},
                investment_max:  ${resultingOpportunity.investmentMax},
                investment_value: ${resultingOpportunity.totalOfferedAmount}
                opening_date: ${new Date(resultingOpportunity.deadline).valueOf()}
                security_type: ${resultingOpportunity.securityType}
                offering_type: ${resultingOpportunity.offeringType}
                title: "${resultingOpportunity.title}"
              }
            ) {
              id
              updated_at
            }
            ${resultingOpportunity.sections
              .filter(section => section.id < 0 && !!sectionInserts[section.type])
              .map(section => sectionInserts[section.type](section, resultingOpportunity.id))
              .join('\n')}
            ${resultingOpportunity.sections
              .filter(section => section.id > 0 && !!sectionUpdates[section.type])
              .map(section => sectionUpdates[section.type](section))
              .join('\n')}
            ${sectionsToDelete
              .filter(section => section.id > 0 && !!sectionDeletes[section.type])
              .map(section => sectionDeletes[section.type](section))
              .join('\n')}
          }`
        }).then(async resp => {
          const dealId = resp.data.update_Deal_by_pk.id;
          const filesToDelete = itemsToDelete;
          const fileSections = resultingOpportunity.sections.filter(section => section.type === DealSectionType.FILES);
          const imageSections = resultingOpportunity.sections.filter(section => section.type === DealSectionType.IMAGES);
          await Promise.all([
            fileSections
              .map(fileSection => ({
                sectionId: fileSection.id > 0 ? fileSection.id : (resp.data[`sectionInsert${fileSection.order}`]?.returning[0]?.id || null),
                filesToUpload: fileSection.data?.content.filter(file => file instanceof File),
              }))
              .filter(fileSection => fileSection.filesToUpload.length > 0 && fileSection.sectionId !== null)
              .reduce((previousValue, currentValue) => {
                return previousValue.then(() => addDocumentsForSection(currentValue.filesToUpload, dealId, currentValue.sectionId))
              }, Promise.resolve(true)),
            imageSections
              .filter(imageSection => imageSection.data?.content[0] instanceof File)
              .map(imageSection => ({
                sectionId: imageSection.id > 0 ? imageSection.id : (resp.data[`sectionInsert${imageSection.order}`]?.returning[0]?.id || null),
                imageToUpload: imageSection.data.content[0],
              }))
              .filter(imageSection => imageSection.imageToUpload && imageSection.sectionId !== null)
              .reduce((previousValue, currentValue) => {
                return previousValue.then(() => addImageForSection(currentValue.imageToUpload, dealId, currentValue.sectionId))
              }, Promise.resolve(true)),
            filesToDelete
              .reduce((previousValue, currentValue) => {
                return previousValue.then(() => fileDeleter(`deal/section/${currentValue.route}/`, currentValue.fileId))
              }, Promise.resolve(true)),
          ])
          if (selectedOpportunity.flowId !== resultingOpportunity.flowId) {
            await cloneFlowForDeal(resultingOpportunity.flowId, resultingOpportunity.id);
          }
        });
      } else {
        // create opportunity
        const mappedDeal = mapDealForCreate(resultingOpportunity, firebaseId);
        try {
          const createOpportunityResult = await createOpportunity({ variables: mappedDeal })
          const dealId = createOpportunityResult.data.insert_Deal.returning[0].id;
          result.id = dealId;
          await Promise.all([
            createOpportunityResult.data.insert_Deal.returning[0].section_images
              .map(imageSection => ({
                ...resultingOpportunity.sections[imageSection.order],
                ...imageSection
              }))
              .filter(imageSection => imageSection.data?.content[0])
              .reduce((previousValue, currentValue) => {
                return previousValue.then(() => addImageForSection(currentValue.data.content[0], dealId, currentValue.id))
              }, Promise.resolve(true)),
            createOpportunityResult.data.insert_Deal.returning[0].section_files
              .map(fileSection => ({
                ...resultingOpportunity.sections[fileSection.order],
                ...fileSection
              }))
              .filter(fileSection => fileSection.data?.content?.length)
              .reduce((previousValue, currentValue) => {
                return previousValue.then(() => addDocumentsForSection(currentValue.data.content, dealId, currentValue.id))
              }, Promise.resolve(true)),
          ]);
          if (resultingOpportunity.flowId) {
            await cloneFlowForDeal(resultingOpportunity.flowId, dealId);
          }
        } catch (error) {
          console.log(error);
        }
      }

      if (publishing) {
        if (resultingOpportunity.type === "ACTIVE" && !resultingOpportunity.flowId) {
          hasuraErrorHandler.printError("You cannot publish an active opportunity without an investment flow");
        }
        else if(resultingOpportunity.published) {
          EventThrower.throwClientSideMessage(Events.SuccessMessage("Deal updated"));
          history.push('/admin/opportunities/list-opportunities');
        }
        else if (!resultingOpportunity.published) {
          const publishResult = await publishDeal(result.id);
          if (publishResult.status === 200) {
            EventThrower.throwClientSideMessage(Events.SuccessMessage("Deal published"));
            history.push('/admin/opportunities/list-opportunities');
          } else {
            const error = await publishResult.json();
            if(error.status && error.status === "ERROR" && error.result.message) {
              hasuraErrorHandler.printError(error.result.message)
            } else {
              hasuraErrorHandler.printError("Error publishing the opportunity, please check all the fields")
            }
          }
        }
      } else {
        EventThrower.throwClientSideMessage(Events.SuccessMessage(`Deal ${selectedOpportunity && selectedOpportunity.id ? "updated" : "created"}`));
        history.push('/admin/opportunities/list-opportunities');
      }
    }

    dispatch(adminOpportunityActions.FINISH_SAVING());
  };
}

const AddOpportunityPage = () => {
  const dispatch = useDispatch();
  const params = useRouteMatch().params;
  const [getDealDetails, { data, loading, error }] = useLazyQuery(GET_DEAL_DETAILS_BY_ID, { fetchPolicy: "network-only" });
  const { saving, drafting, publishing, resultingOpportunity, selectedOpportunity, sectionsToDelete, itemsToDelete } = useSelector(state => state.adminOpportunity);
  const processing = useRef(null);
  const processOpportunity = useOpportunityProcessing(processing, dispatch);
  const canCreateOpportunity = useSelector(store => store.permissionAsignments.create_opportunity);
  const history = useHistory();
  useEffect(() => {
    if (!canCreateOpportunity) {
      hasuraErrorHandler.printError('You cannot create opportunity')
      history.goBack();
    }
  }, [canCreateOpportunity, history])
  // We want the porcessOpportunity function to get called ONLY when switching the saving flag 
  // (so state updates while the flag is the save (true or false) won't trigger it)
  useEffect(() => {
    if (saving && (!processing.current || !processing.current.saving)) {
      processing.current = {
        saving, drafting, publishing, resultingOpportunity, selectedOpportunity, sectionsToDelete, itemsToDelete
      }
      processOpportunity();
    } else if (!saving && processing.current && processing.current.saving) {
      processing.current = null;
    }
  }, [saving, drafting, publishing, resultingOpportunity, selectedOpportunity, processing, sectionsToDelete, itemsToDelete, processOpportunity])


  useEffect(() => {
    if (params.opportunityId) {
      getDealDetails({ variables: { id: params.opportunityId } });
    } else {
      // dispatch(adminOpportunityActions.CREATE_OPPORTUNITY({--custom init fields--}));
      dispatch(adminOpportunityActions.CLEANUP()); // reset state and new opp with default fields
    }
  }, [dispatch, getDealDetails, params.opportunityId]);

  useEffect(() => {
    if (data) {
      dispatch(adminOpportunityActions.EDIT_OPPORTUNITY(mapDealForEdit(data.Deal_by_pk)));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (error) {
      console.error("Error while retrieving deal details > ", error);
    }
  }, [error]);

  if (loading) return <Loading fullWidth />;

  return (
    <Fragment>
      <AddOpportunityHeader />
      <OpportunityDetails />
      <AddOpportunitySections />
    </Fragment>
  )
}

export default AddOpportunityPage;