import React from 'react';
import { topsideMenuStyles } from "./TopsideMenuStyles";
import { generalStyleClasses } from "../../../../../utils/general-styles"
import { NavLink } from 'react-router-dom';


const TopsideMenu = () => {
  const classes = topsideMenuStyles();
  const generalStyles = generalStyleClasses();
  return (
    <div className={[classes.continer, generalStyles.mainPageWidth].join(' ')}>
      <p className={[generalStyles.fontSize15, generalStyles.fontColorDark].join(' ')}>My investor profile</p>
      <div className={classes.menu}>
        <NavLink to="industry" activeClassName={[classes.activeMenuItem, generalStyles.fontColorDark].join(' ')} className={[classes.menuItem, generalStyles.fontSize15].join(' ')}>Industry</NavLink>
        <NavLink to="company" activeClassName={[classes.activeMenuItem, generalStyles.fontColorDark].join(' ')} className={[classes.menuItem, generalStyles.fontSize15].join(' ')}>Company</NavLink>
        <NavLink to="investment" activeClassName={[classes.activeMenuItem, generalStyles.fontColorDark].join(' ')} className={[classes.menuItem, generalStyles.fontSize15].join(' ')}>Investment</NavLink>
      </div>
    </div>
  )
}

export default TopsideMenu;