import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import StarBorderSharpIcon from '@material-ui/icons/StarBorderSharp';
import { generalStyleClasses } from "../../../../../utils/general-styles";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_TO_WATCHLIST,  DELETE_FROM_WATCHLIST, GET_DEAL_SUBMENU_DATA } from '../../../../../utils/deal-queries';
import StarIcon from '@material-ui/icons/Star';
import { Events, EventThrower } from '../../../../../shared/EventEmitter/EventEmitter';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment } from 'react';
import { insertAnalytics } from '../../../../../utils/analytics-queries';
import { utilStoreActions } from '../../../../../store/slices/utils';

const TopsideMenu = () => {
  const generalStyles = generalStyleClasses();
  const params = useRouteMatch().params;
  const firebase_id = useSelector(store => store.account.user.firebase_id);
  const [addToWatchlist, addToWatchlistQueryData] = useMutation(ADD_TO_WATCHLIST, { variables: { deal_id: params.id } })
  const [deleteFromWatchlist, deleteFromWatchlistQueryData] = useMutation(DELETE_FROM_WATCHLIST, { variables: { deal_id: params.id } })
  const { data } = useQuery(GET_DEAL_SUBMENU_DATA, { variables: { deal_id: params.id, firebase_id: firebase_id }, fetchPolicy: "network-only" });
  const [isWatchlist, setIsWatchlist] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [investmentId, setInvestmentId] = useState(null);
  const [interestId, setInterestId] = useState(null);
  const [investmentIsCompleted, setInvestmentIsCompleted] = useState(false);
  const [dealType, setDealType] = useState("CLOSED");
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(data);
    if (data && data.Deal_by_pk) {
      setFeedbackSent(data.Interest_feedback.length > 0)
      setDealType(data.Deal_by_pk?.status)
      setInvestmentId(data.Deal_by_pk?.Investments[0]?.id || null);
      setInterestId(data.Interest[0]?.id)
      const investmentIsMarkedCompleted = !!data.Deal_by_pk?.Investments[0]?.completed || false;
    console.log(data)
      dispatch(utilStoreActions.SET_UTIL_VALUE({name: 'InvestmentIsMarkedCompletedForCurrentOpenedDeal', value: investmentIsMarkedCompleted}));
      setInvestmentIsCompleted(investmentIsMarkedCompleted)
    }
    if (data && data.Watchlist) {
      setIsWatchlist(data.Watchlist.length > 0);
    }
  }, [data]);

  useEffect(() => {
    if (addToWatchlistQueryData.data && addToWatchlistQueryData.data.insert_Watchlist.affected_rows > 0) {
      setIsWatchlist(true);
      EventThrower.throwClientSideMessage(Events.CompanyAddedToWatchlist);
    }
  }, [addToWatchlistQueryData.data])

  useEffect(() => {
    if (deleteFromWatchlistQueryData.data && deleteFromWatchlistQueryData.data.delete_Watchlist.affected_rows > 0) {
      setIsWatchlist(false);
      EventThrower.throwClientSideMessage(Events.CompanyRemovedFromWatchlist);
    }
  }, [deleteFromWatchlistQueryData.data])

  const handleChangeWatchlistStatus = () => {
    if (isWatchlist) {
      deleteFromWatchlist();
    } else {
      addToWatchlist();
    }
  }

  const handleInvestButton = () => {
    if (dealType === "ACTIVE" && investmentId) {
      history.push(`/app/document-handler/${params.id}/${investmentId}`);
      return;
    }

    insertAnalytics({ firebaseId: firebase_id, dealId: params.id, companyId: data.Deal_by_pk.company_id, event: "DEAL_INTEREST_CLICK"}).then(console.log).catch(console.log)
    if (dealType === "ACTIVE") {
      history.push(`/app/invest/${params.id}`);
    } else {
      history.push(`/app/indicate-interest/${params.id}`);
    }
  }

  return (
    <div className={[generalStyles.mainPageWidth, generalStyles.flexSpaceBetweenCenter].join(' ')}>
      <Button onClick={() => history.push('/app/opportunities/all')}>
        <KeyboardBackspaceIcon style={{ marginRight: "8px" }} />
        Back
      </Button>
      <div className={generalStyles.flexSpaceBetweenCenter} style={{ width: "890px" }}>
        <Button onClick={() => handleChangeWatchlistStatus()} style={{ textTransform: "initial" }}>
          {isWatchlist ? "Remove from watchlist" : "Add to watchlist"}
          {isWatchlist
            ? <StarIcon style={{ fill: "#FAB54F" }} />
            : <StarBorderSharpIcon style={{ marginLeft: "12px" }} />}

        </Button>
        <div>
          {
            dealType !== "CLOSED"
              ? (
                <Fragment>
                  <Button variant="outlined" color="primary" style={{ height: "40px", color: feedbackSent ? "#434448" : "", borderColor: feedbackSent ? "#434448" : "" }} component={Link} to={`/app/not-interested/${params.id}`} >
                    {feedbackSent ? "Update feedback" : "Provide feedback"}
                  </Button>
                  <Button variant="contained" color="primary" style={{ height: "40px", marginLeft: "16px" }} onClick={() => handleInvestButton()}>
                    {
                      dealType === "ACTIVE"
                        ? (!investmentId ? "Invest" : (investmentIsCompleted ? "Investment completed" : "Manage"))
                        : (!interestId ? "Indicate interest" : "Modify interest") 
                    }
                  </Button>
                </Fragment>
              )
              : ""
          }
        </div>
      </div>
    </div>
  );
}

export default TopsideMenu;