import { makeStyles } from '@material-ui/core/styles';

export const topsideMenuStyles = makeStyles({ 

  buttonGroup: {
    flexGrow: '1',
    marginLeft: '78px',
  },
  buttonGroupItem: {
    background: 'white',
    padding: '12px 20px',
    textAlign: 'center',
    border: '1px solid #EBEBEB',
    borderLeft: '0px',
    "&:last-of-type": {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderLeft: '1px',
    },
    "&:first-of-type": {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    }
  },
  dropdownButton: {
    textTransform: 'initial',
    "& svg": {
      marginLeft: '19px',
    }
  },
  dropdownItem: {
    cursor: 'auto',
    "&:hover": {
      cursor: "pointer"
    }
  },
  activeLink: {
    background: '#434448 !important',
    color: '#ffffff !important'
  },
  activeSortArrow: {
    background: 'white !important',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    "& path": {
      fill: '#434448 !important',
    },
  },
  sortArrows: {
    "& svg": {
      cursor: 'pointer',
      padding: '8px',
      background: '#F9F9F9',
      "&:hover>path": {
        fill: '#434448 !important',
      }
    }
  },
  companySelect: {
    "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-filled.MuiInputBase-input.MuiFilledInput-input": {
      padding: "12px 10px",
    }
  }
})