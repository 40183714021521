import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import './help-dialog.scss';

function HelpDialog({ onClose, open }) {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} disableScrollLock={true}>
            <DialogTitle className="help-dialog-title">
                <span>
                    Accredited Investor
                </span>
                <img src={process.env.PUBLIC_URL + "/images/close.svg"} onClick={handleClose}/>
            </DialogTitle>
            <DialogContent className="help-dialog-body">An accredited investor is an individual or a business entity that is allowed to trade securities that may not be registered with financial authorities. They are entitled to this privileged access by satisfying at least one requirements regarding their income, net worth, asset size, governance status or professional experience.</DialogContent>
            <DialogActions className="help-dialog-actions">
                <Button variant="contained" color="primary" onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default HelpDialog;
