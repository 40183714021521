import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './checkboxes-group.scss';
import { Button } from '@material-ui/core';

const CheckboxesGroup = forwardRef((props, ref) => {
  const { optionsInput, optionsStyle = {}, containerStyle = {}, handleChange, defaultValues = [] } = props;
  const [options, setOptions] = React.useState(optionsInput.map(x => ({ name: x.name, value: x.value, checked: false })));

  useEffect(() => {
    if(defaultValues && defaultValues.length > 0) {
      setOptions(options.map(x => {
        x.checked = defaultValues.filter(selectedOption => x.value === selectedOption.value).length > 0;
        return x;
      }));
    }
  }, [])

  const valueChanges = (event) => {
    setOptions(options.map(x => {
      if (x.name === event.target.name) {
        x.checked = event.target.checked;
      }
      return x;
    }));
    if (handleChange) {
      handleChange(options.filter(x => x.checked));
    }
  };


  useImperativeHandle(ref, () => ({
    setAllValues(value) {
      const newOptions = options.map(x => ({...x, checked: value}))
      setOptions(newOptions);
      handleChange(newOptions.filter(x => x.checked));
    }
  }));

  return (
    <div>
      <FormControl component="fieldset">
        <FormGroup style={{ display: 'flex', flexDirection: 'row', ...containerStyle }}>
          {options.map((option, index) => <FormControlLabel
            key={index}
            style={{ marginRight: '24px', ...optionsStyle }}
            control={<Checkbox className="checkbox" checked={option.checked} onChange={valueChanges.bind(this)} name={option.name} />}
            label={<span className="checkbox-label">{option.name}</span>}
          />)}
        </FormGroup>
      </FormControl>
    </div>
  );
})

export default CheckboxesGroup;