import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextInput from '../../../shared/TextInput/TextInput';
import { useQuery } from '@apollo/client';
import { GET_ALL_PERMISSIONS } from '../../queries/admin-queries';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { IconButton, MenuItem, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { sendInvitationEmail } from '../../queries/admin-http-calls';
import { adminDimensionsStyle, adminAlignmentStyles, adminInputStyles } from '../../AdminStyles';
import { Events, EventThrower, hasuraErrorHandler } from '../../../shared/EventEmitter/EventEmitter';
import { formatUnderlineSeparatedName } from '../../../utils/formats';
import { permissionLabeles } from './permissionLabeles';

const AddEmployeeDialog = ({ open, setOpen }) => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const inputClasses = adminInputStyles();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAdress, setEmailAdress] = useState('');
  const [role, setRole] = useState(0);
  const roles = useQuery(GET_ALL_PERMISSIONS).data;

  const handleClose = () => {
    setOpen(false);
  };

  const inviteEmployee = () => {
    let missingFields = [];
    if (!emailAdress) missingFields.push("Email Adress");
    if (!firstName) missingFields.push("First Name");
    if (!lastName) missingFields.push("Last Name");
    if (!role) missingFields.push("Role");

    if (missingFields.length > 0) {
      hasuraErrorHandler.printError(`Missing fields: ${missingFields.join(', ')}`)
    } else {
      sendInvitationEmail(emailAdress, firstName, lastName, role)
        .then(res => res.json())
        .then(res => {
          EventThrower.throwClientSideMessage(Events.EmailSentSuccessfully)
          handleClose();
        }).catch(() => hasuraErrorHandler.printError(`Error sending email to ${emailAdress}`))
    }
  }

  if (!roles) {
    return "";
  }

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ style: { padding: "24px" } }} disableScrollLock={true}>
      <DialogTitle className={[alignmentClasses.modalTitle, dimensionClasses.padding0].join(' ')}>
        Add Employe
      <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={[dimensionClasses.padding0, dimensionClasses.hideOverflow].join(' ')} >
        <TextInput className={dimensionClasses.marginTop16} label="First name" isPassword={false} handleChange={value => setFirstName(value)} />
        <TextInput className={dimensionClasses.marginTop16} label="Last name" isPassword={false} handleChange={value => setLastName(value)} />
        <TextInput className={dimensionClasses.marginTop16} label="Email adress" isPassword={false} handleChange={value => setEmailAdress(value)} />
        <div className={[dimensionClasses.marginTop16, alignmentClasses.flexAlignCenter].join(' ')}>
          <span>
            Role
          </span>
          <FormControl variant="outlined" style={{ marginLeft: "16px", width: "250px" }}>
            <Select
              value={role}
              onChange={e => setRole(e.target.value)}
              MenuProps={{ variant: "menu", disableScrollLock: true }}
              className={[inputClasses.singleSelect, dimensionClasses.mediumHeight].join(' ')}
              renderValue={(selected) => {
                const role = roles.Permissions.filter(role => role.id === selected)[0];
                return role ? `${formatUnderlineSeparatedName(role.role)}, ` : ""
              }}
            >
              {roles.Permissions.map((x, index) => (
                <MenuItem key={index} value={x.id} style={{ padding: "0px" }}>
                  <Tooltip title={permissionLabeles[x.id.toString()]} placement="right" arrow >
                    <Button style={{ width: "100%", textTransform: "none" }} disableElevation disableFocusRipple disableRipple>
                      {formatUnderlineSeparatedName(x.role)}
                    </Button>
                  </Tooltip>
                </MenuItem>))}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions className={dimensionClasses.padding0}>
        <Button className={[dimensionClasses.smallHeigth, dimensionClasses.marginTop24].join(' ')} onClick={handleClose} variant="outlined" color="primary">
          Close
          </Button>
        <Button className={[dimensionClasses.smallHeigth, dimensionClasses.marginTop24].join(' ')} onClick={() => inviteEmployee()} variant="contained" color="primary">
          Add employe
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEmployeeDialog;