export const mapServerFlow = (flow) => {
  let activeStep = 0;
  let mappedFlow = flow
    .Onboarding_flow_section_edges
    .map((x, index) => {
      let data = {
        tabName: x.Flow_section_node.name,
        order: x.order,
        locked: x.completed,
        id: x.id,
        components: x.Flow_section_node.Onboarding_section_item_edges.map(part => {
          let component = {
            edgeId: part.id,
            type: part.Flow_item_node.type,
            text: part.Flow_item_node.text,
            label: part.Flow_item_node.name,
            inputField: part.Onboarding_flow_item_input_field,
            files: part.Files_metadata.map(x => ({name: x.name, id: x.id})),
          }
          return component;
        })
      }
      return data;
    })
  const sortedFlow = mappedFlow.sort((a, b) => a.order - b.order);
  return {flow: sortedFlow, current: sortedFlow.findIndex(x => !x.locked)};
}