import { gql } from "@apollo/client";

export const GET_COMPANY_FUNDING_HISTORY = gql`
query MyQuery($id: bigint!) {
  Company_by_pk(id: $id) {
    funding_histories {
      financing_round
      date
      notable_investors {
        notable_investor_name
      }
      pre_money_valuation
      pre_money_valuation_currency
      amount_raised
      amount_raised_curency
    }
  }
}`;

export const GET_COMPANY_DESCRIPTION_BY_ID = gql`
query GetDealCompanyDescription($id: bigint!) {
    Company_by_pk(id: $id) {
      description
  }
}
`;

export const GET_COMPANY_MANAGEMENT_BY_ID = gql`
  query GetDealCompanyManagement($id: bigint!) {
      Company_by_pk(id: $id) {
        company_managements {
          name
          position
        }
    }
  }
`;

export const GET_COMPANY_OVERVIEW_BY_ID = gql`
query MyQuery($id: bigint!) {
    Company_by_pk(id: $id)  {
      entity_type
      stage
      available_to_invest
      last_valuation
      business_status
      funding_stage
      sectors {
        ENUM_interested_sector {
          description
        }
      }
      innovation_enablers {
        ENUM_innovation_enabler {
          description
        }
      }
  }
}`;

export const GET_COMPANY_DEAL_TITLE = gql`
query MyQuery($id: bigint!) {
    Company_by_pk(id: $id) {
      name
      hq_city
      hq_country
      hq_state
      founding_year
      logo
    }
}`;