import React, { useEffect, useState } from 'react';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from '../AdminStyles';
import SmallButtonGroup from '../../shared/SmallButtonsGroup/SmallButttonsGroup';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_INVESTOR_GENERAL_ANALYTICS } from '../queries/admin-queries';
import { mapToStatistics } from './utils';
import AnalyticsGraph from './AnalyticsGraph';
import CompanyClickTable from './CompanyTable';
import { GET_TIMED_ANALYTICS } from "../../utils/analytics-queries";
import DealsTable from './DealsTable';
import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core';
import FeedbackPanel from './FeedbackPanel';

const timePeriodButtons = [
  { text: "24 hours", value: Date.now() - 1000 * 60 * 60 * 24 },
  { text: "7 days", value: Date.now() - 1000 * 60 * 60 * 24 * 7 },
  { text: "30 days", value: Date.now() - 1000 * 60 * 60 * 24 * 30 },
  { text: "All time", value: 0 },
]

const customStyle = makeStyles({
  button: {
    width: '129px',
    height: '32px',
    background: '#FFFFFF',
    border: '1px solid #EBEBEB',
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    marginTop: '8px',
    color: '#796E6E',
    "&:hover": {
      border: '1px solid rgba(67, 68, 72, 0.04)',
      backgroundColor: '#777777',
      color: "#ffffff",
    },
  },
  separator: {
    width: "100%",
    marginTop: "40px",
    marginBottom: "40px",
    borderBottom: "1px solid #D8CECE",
  }
})
const GeneralAnalytics = () => {
  const tableClasses = adminTablesStyle();
  const customClasses = customStyle();
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const typographyClasses = adminTypografyStyles();
  const { data } = useQuery(GET_INVESTOR_GENERAL_ANALYTICS, {
    fetchPolicy: "network-only",
  });
  const [
    getAnalytics,
    { data: generalAnayticsData },
  ] = useLazyQuery(GET_TIMED_ANALYTICS, { fetchPolicy: "network-only" });
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(new Date());
  const [statistics, setStatistics] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenCustomTime = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewDateSelected = (date) => {
    setStartDate(date);
    setEndDate(new Date());
    getAnalytics({ variables: { time: new Date(date).toISOString() } });;
  };

  useEffect(() => {
    if (data && data.Account) {
      setStatistics(mapToStatistics(data));
    }
  }, [data]);

  return (
    <div
      className={[
        dimensionClasses.headerMarginFix,
        dimensionClasses.containerWidth,
      ].join(" ")}
      style={{ maxWidth: "1080px" }}
    >
      <p
        className={[
          typographyClasses.fontbold,
          typographyClasses.fontSize15,
          dimensionClasses.marginTop24,
          dimensionClasses.marginBottom0,
        ].join(" ")}
      >
        Sector and Innovation Preferences:
      </p>
      <div
        className={[
          alignmentClasses.flexCenterSpaceBetween,
          dimensionClasses.marginTop16,
        ].join(" ")}
        style={{ alignItems: "flex-start" }}
      >
        {statistics.preferedSectors ? (
          <AnalyticsGraph
            label="Preferred sectors"
            data={statistics.preferedSectors}
          />
        ) : (
          ""
        )}
        {statistics.leastPreferedSectors ? (
          <AnalyticsGraph
            label="Least preferred sectors"
            data={statistics.leastPreferedSectors}
          />
        ) : (
          ""
        )}
      </div>
      <div
        className={[
          alignmentClasses.flexCenterSpaceBetween,
          dimensionClasses.marginTop40,
        ].join(" ")}
        style={{ alignItems: "flex-start" }}
      >
        {statistics.preferedInovations ? (
          <AnalyticsGraph
            label="Preferred innovation enablers"
            data={statistics.preferedInovations}
          />
        ) : (
          ""
        )}
        {statistics.leastPreferedInovations ? (
          <AnalyticsGraph
            label="Least preferred innovation enablers"
            data={statistics.leastPreferedInovations}
          />
        ) : (
          ""
        )}
      </div>
      <div className={customClasses.separator} />
      <div className={alignmentClasses.flexAlignCenter}>
        <span
          className={[
            typographyClasses.fontSize14,
            typographyClasses.fontbold,
            dimensionClasses.marginRight12,
          ].join(" ")}
        >
          Period of time
        </span>
        <SmallButtonGroup
          buttonList={timePeriodButtons}
          handleChange={(value) => handleNewDateSelected(value.value)}
          buttonStyle={{ marginRight: "16px", marginTop: "0px" }}
        />
        <Button
          onClick={handleOpenCustomTime}
          variant="outlined"
          color="primary"
          className={customClasses.button}
          style={{ marginTop: "0px" }}
        >
          Custom
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ marginTop: "40px" }}
          disableScrollLock={true}
        >
          <MenuItem className={alignmentClasses.flexCenterSpaceBetween}>
            <span>Start time</span>
            <input
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
              style={{ marginLeft: "12px" }}
            />
          </MenuItem>
          <MenuItem className={alignmentClasses.flexCenterSpaceBetween}>
            <span>End time</span>
            <input
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
              style={{ marginLeft: "12px" }}
            />
          </MenuItem>
        </Menu>
      </div>
      <p
        className={[
          typographyClasses.fontbold,
          typographyClasses.fontSize15,
          dimensionClasses.marginTop24,
          dimensionClasses.marginBottom0,
        ].join(" ")}
      >
        Company interests:
      </p>
      <CompanyClickTable
        startTime={new Date(startDate)}
        endTime={new Date(endDate)}
      />
      <p
        className={[
          typographyClasses.fontbold,
          typographyClasses.fontSize15,
          dimensionClasses.marginTop24,
          dimensionClasses.marginBottom0,
        ].join(" ")}
      >
        Deal interests:
      </p>
      <DealsTable startTime={new Date(startDate)} endTime={new Date(endDate)} />
      <div className={customClasses.separator} />
      <p
        className={[
          typographyClasses.fontbold,
          typographyClasses.fontSize15,
          dimensionClasses.marginTop40,
          dimensionClasses.marginBottom16,
        ].join(" ")}
      >
        Feedback from uninterested investors:
      </p>
      <FeedbackPanel />
    </div>
  );
};

export default GeneralAnalytics;