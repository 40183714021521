import React, { forwardRef, useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { FormControl, IconButton, MenuItem, Select } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { oportunitySectionStyle } from "./OportunitySectionStyles";
import {  useDispatch } from 'react-redux';
import { adminOpportunityActions } from '../../../../store/slices/adminOpportunity';
import { adminInputStyles, adminTypografyStyles } from '../../../AdminStyles';

const Section = forwardRef((props, ref) => {
  const { section, sectionsCount, disableDeleteButton } = props;
  const classes = oportunitySectionStyle();
  const inputClasses = adminInputStyles();
  const typografyClasses = adminTypografyStyles();
  const dispatch = useDispatch();
  // const sectionData = useSelector(store => store.addOpportunitySections.sections.filter(x => x.id === id)[0]);
  const handleChangeOrder = (newOrder) => dispatch(adminOpportunityActions.SECTION_REORDER({id: section.id, newOrder: newOrder}));
  const handleChangeExtended = (_id) => dispatch(adminOpportunityActions.TOGGLE_SECTION({id:_id}));
  const handleDelete = (_id) => dispatch(adminOpportunityActions.DELETE_SECTION({id:_id}));
  const handleTitleChange = (title) => {
    dispatch(adminOpportunityActions.UPDATE_SECTION({
      id: section.id, 
      title
    }))
  }

  const [orderOptions, setOrderOptions] = useState([]);

  useEffect(() => {
    const orders = Array.from({ length: sectionsCount - 1 }, (_, i) => i + 1);
    setOrderOptions(orders);
  }, [sectionsCount])

  if (!props.children) {
    return <div>Error fetching section, please refresh the page</div>
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerSection} style={{ flexGrow: "1" }}>
          <IconButton onClick={() => handleChangeExtended(section.id)}>
            {
              section.extended
                ? <ExpandLessIcon />
                : <ExpandMoreIcon />
            }
          </IconButton>
          {!section.editableTitle && <span>{section.title}</span>}
          {!!section.editableTitle && <>
            <span>Title</span>
            <input 
              placeholder="enter section title...."
              value={section.title}
              className={[inputClasses.input, typografyClasses.fontSize14].join(' ')}
              style={{ height: "32px", maxWidth: "580px", flexGrow: "1", marginLeft: "12px"}} 
              onChange={(e) => handleTitleChange(e.target.value)}
              />
          </>}
        </div>
        {
          disableDeleteButton === false
            ? (
              <div className={classes.headerSection}>
                <span className={classes.headerTitle}>Order</span>
                <FormControl variant="outlined" style={{ width: "100%" }} >
                  <Select
                    MenuProps={{ variant: "menu", disableScrollLock: true }}
                    className={classes.select}
                    onChange={e => handleChangeOrder(e.target.value)}
                    value={section.order}
                    disabled={disableDeleteButton}
                    >
                      {orderOptions.map(order => (<MenuItem key={`order-${section.id}-${order}`} value={order} selected={order === section.order}>{order}</MenuItem>))}
                  </Select>
                </FormControl>
                <IconButton disabled={disableDeleteButton} onClick={() => handleDelete(section.id)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            )
            : ""
        }
      </div>
      {!!section.extended && props.children}
    </div>
  )
})

export default Section;