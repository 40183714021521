import { onboardingSectionTypes } from "../../../store/slices/adminOnboardingFlow";

export const mapNewFlow = (flow) => {
  const firebaseId = flow.firebaseId;

  let dataToSend = {
    name: flow.title,
    Onboarding_flow_section_edges: {
      data: []
    }
  }

  if (flow.id) {
    dataToSend.id = flow.id;
  }

  flow.steps.forEach(step => {
    let data = {
      completed: false,
      current: false,
      shown: false,
      order: step.order,
      Flow_section_node: {
        data: {
          id: step.disableTitleUpdate ? step.id : null,
          name: step.title,
          Onboarding_section_item_edges: {
            data: []
          }
        }
      }
    }

    if (!data.Flow_section_node.data.id) {
      delete data.Flow_section_node.data.id;
    }

    if (step.edgeId) {
      data.id = step.edgeId;
      data.section_id = step.id;
    }

    if (firebaseId) {
      data.firebase_id = firebaseId;
    }

    step.parts.forEach(x => {
      let node = {
        id: x.edgeId,
        Flow_item_node: {
          data: {
            id: x.disableUpdate ? x.id : null,
            type: x.type,
            name: x.type === (onboardingSectionTypes.TEXT && x.data?.text) || (onboardingSectionTypes.INPUT_FIELD && x.data?.label) || "",
            text: x.type === onboardingSectionTypes.TEXT && x.data?.text || "",
          }
        }
      }
      if (!node.Flow_item_node.data.id) {
        delete node.Flow_item_node.data.id;
      }

      if (!node.id) {
        delete node.id;
      }

      if (firebaseId) {
        node.firebase_id = firebaseId;
      }
      
      data.Flow_section_node.data.Onboarding_section_item_edges.data.push(node);
    })

    dataToSend.Onboarding_flow_section_edges.data.push(data);
  });

  return dataToSend;
}

export const mappServerData = (data) => {
  let mappedData = {
    title: data.name,
    id: data.id,
    steps: data.Onboarding_flow_section_edges.map(x => {
      let newStep = {
        title: x.Flow_section_node.name,
        order: x.order,
        completed: x.completed,
        current: x.current,
        id: x.Flow_section_node.id,
        disableTitleUpdate: true,
        edgeId: x.id,
        parts: x.Flow_section_node.Onboarding_section_item_edges.map(part => {
          let newPart = {
            edgeId: part.id,
            id: part.Flow_item_node.id,
            order: part.order,
            disableUpdate: true,
            data: null, 
            type: part.Flow_item_node.type
            }
            switch (part.Flow_item_node.type) {
              case onboardingSectionTypes.TEXT:
                  newPart.data = {text: part.Flow_item_node.text}
                break;
              case onboardingSectionTypes.INPUT_FIELD:
                  newPart.data = {label: part.Flow_item_node.name, text: part.Onboarding_flow_item_input_field?.text || "Not response yet"}
                break;
              case onboardingSectionTypes.UPLOAD: 
                  newPart.data = {files: part.Files_metadata?.map(file => ({ name: file.name, id: file.id })) || []}
                break;
              case onboardingSectionTypes.DOWNLOAD: 
                newPart.data = part.Files_metadata?.map(file => ({ name: file.name, id: file.id })) || []
              break;
              default:
                break;
            }
          return newPart;
        })
      }
      return newStep;
    })
  }
return mappedData;
}


export const mapOnboardingToEdges = (flow) => {
  const edgeIds = {
    sectionEdges: [],
    itemEdges: [],
  }

  flow.Onboarding_flow_section_edge.forEach(sectionEdge => {
    edgeIds.sectionEdges.push(sectionEdge.id);
    sectionEdge.Onboarding_section_item_edge.forEach(itemEdge => edgeIds.itemEdges.push(itemEdge.id));
  })

  return edgeIds;
}

export const mapOnboardingForClone = (flow) => {
  const mappedFlow = {
    name: `${flow.name} - copy`,
    Onboarding_flow_section_edges: { data: [] },
  }

  flow.Onboarding_flow_section_edges.forEach(sectionEdge => {
    const edgeData = {
      completed: sectionEdge.completed,
      current: sectionEdge.current,
      order: sectionEdge.order,
      shown: sectionEdge.shown,
      Flow_section_node: {
        data: {
          name: sectionEdge.Flow_section_node.name,
          Onboarding_section_item_edges: {
            data: sectionEdge.Flow_section_node.Onboarding_section_item_edges
              .map(item => ({
                Flow_item_node: {
                  data: {
                    name: item.Flow_item_node.name,
                    type: item.Flow_item_node.type,
                    text: item.Flow_item_node.text,
                  }
                }
              }))
          }
        }
      }
    }
    mappedFlow.Onboarding_flow_section_edges.data.push(edgeData);
  })

  return mappedFlow;
}