import React, { Fragment, useEffect, useState } from 'react';
import TextInput from '../../shared/TextInput/TextInput';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import firebase from "firebase"
import { useDispatch } from 'react-redux';
import { SET_REGISTER_STEP_PERSONAL_INFOS, SET_REGISTRATION_USER_ID, SET_USER_AUTH } from '../../store/utils/action-types';
import { generalStyleClasses } from '../../utils/general-styles';
import { useHistory, withRouter } from 'react-router-dom';
import { emailValidator, passwordValidator, hasLowerCase, hasUpperCase, hasNumber } from "../../utils/validators";
import { EventThrower, Events, hasuraErrorHandler } from '../../shared/EventEmitter/EventEmitter';
import ErrorLabel from '../../shared/ErrorLabel/ErrorLabel';
import { validateAccountInvitationToken } from '../../utils/account-queries';

const CreateAccount = ({location}) => {
    const history = useHistory();
    const classes = generalStyleClasses();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [tocAccepted, setTocAccepted] = useState(false);
    const [valid, setValid] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordValidationErrors, setPasswordValidationErrors] = useState([true, true, true]);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const params = new URLSearchParams(location.search);
    const invitationToken = params.get("token");
    const invitationEmail = params.get("email");

    useEffect(() => {
        if(invitationEmail !== "") {
            setEmail(invitationEmail);
        }
    }, [invitationEmail])

    useEffect(() => {
        if(email !== '' && password !== '' && confirmPassword !== '' && tocAccepted === true) {
            setValid(true);         
        } else {
            setValid(false)
        }
    }, [password, confirmPassword, email, tocAccepted])

    useEffect(() => {
        if(email !== '') {
            setEmailError(false)
        }
    }, [email]);

    const handleChangePassword = (newValue) => {
        setPassword(newValue);
        if(newValue !== '') {
            setPasswordError(false);
        }
        let passValidators = passwordValidationErrors;
        if(!newValue) {
            passValidators = [1, 1, 1];
        } else {
            passValidators[0] = newValue.length < 8;
            passValidators[1] = !(hasLowerCase(newValue) && hasUpperCase(newValue));
            passValidators[2] = !hasNumber(newValue);
        }
        setPasswordValidationErrors(passValidators);
    }

    useEffect(() => {
        if(confirmPassword !== '') {
            setConfirmPasswordError(false);
        }
    }, [confirmPassword]);
    
   const register = () => {
        if(invitationToken) {
            validateAccountInvitationToken(invitationToken, invitationEmail).then(response => {
                if(response.status === 200) {
                    handleFirebaseRegister();
                } else {
                    hasuraErrorHandler.printError("Token validation failed");
                }
            }).catch(console.error);
        } else {
            handleFirebaseRegister();
        } 
    }
    
    const handleFirebaseRegister = () => {
        firebase.auth().createUserWithEmailAndPassword(email, password).then(userResult => {
            firebase.functions().httpsCallable('processSignUpCall')({}).then(response => {
                userResult.user.getIdTokenResult(true).then(result =>{ 
                    dispatch({type: SET_USER_AUTH, payload: {jwtToken: result.token}});
                    dispatch({type: SET_REGISTRATION_USER_ID, payload: {value: userResult.user.uid }});
                    dispatch({type: SET_REGISTER_STEP_PERSONAL_INFOS})
                    history.push("/onboarding/personal-information")
                });
            });
        }).catch(error => {
            EventThrower.throwClientSideError(Events.RegisterError(error.message));
        });
    }

    const handleContinueButton = () => {
        if (!emailValidator(email)) {
            setEmailError(true);
            EventThrower.throwClientSideError(Events.InvalidEmailError);
        } else if (!passwordValidator(password)) {
            setPasswordError(true);
            EventThrower.throwClientSideError(Events.WeekPasswordError);
        } else if (password !== confirmPassword) {
            setConfirmPasswordError(true);
            EventThrower.throwClientSideError(Events.PasswordsMismatchError);
        } else {          
            register();           
        }
    }

    return (
        <Fragment>
            <h3 className={[classes.marginBottom0, classes.marginTop0, classes.fontSize20, classes.fontColorDark].join(' ')}>Create your {localStorage.getItem("companyName")} account</h3>
            <h4 className={[classes.marginBottom0, classes.marginTop12, classes.fontSize16, classes.fontColorLight].join(' ')}>Enter your email and password to get started</h4>
            <div className={classes.marginTop32}>
                <TextInput label="Email" disabled={invitationToken !== null} handleChange={value => setEmail(value)} error={emailError} initialValue={email} />
            </div>
            <div className={classes.marginTop24}>
                <TextInput label="Password" isPassword={true} handleChange={value => handleChangePassword(value)} error={passwordError} />
            </div>
            <div className={classes.marginTop24}>
                <TextInput label="Confirm Password" isPassword={true} handleChange={value => setConfirmPassword(value)} error={confirmPasswordError} />
                <ErrorLabel label="Use 8 or more characters" active={passwordValidationErrors[0]}/>
                <ErrorLabel label="Uppercase and lowercase letters (e.g. Aa)" active={passwordValidationErrors[1]}/>
                <ErrorLabel label="Use numbers (e.g. 1234)" active={passwordValidationErrors[2]}/>
            </div>
            <FormControlLabel
                className={[classes.marginTop24, classes.fontColorLight].join(' ')}
                control={<Checkbox color="primary" checked={tocAccepted} onChange={e => setTocAccepted(e.target.checked)} />}
                label="I have read and agree to the Privacy Policy and Terms of Use, including consent to electronic delivery"
            />
            <Button className={[classes.marginTop32, classes.singleFullWidthLinkButton].join(' ')} variant="contained" color="primary" disabled={!valid} onClick={handleContinueButton}> Get Started </Button>
        </Fragment>
    )
}

export default withRouter(CreateAccount);