import { FormControl, Input, MenuItem, Select } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { generalStyleClasses } from '../../utils/general-styles';
import { curencies } from '../../utils/curencies';

const InvestmentSize = ({ changeMinValue, changeMaxValue, changeCurrency, rootClasses = {}, isOnboarding = false }) => {
  const classes = generalStyleClasses();
  const storeMinValue = useSelector(store => store.investorProfile.investment_size_low);
  const storeMaxValue = useSelector(store => store.investorProfile.investment_size_high);
  const currencyType = useSelector(store => store.investorProfile.investment_preffered_currency || "EUR");
  const [minValue, setMinValue] = useState(isOnboarding ? null : (storeMinValue !== 0 ? storeMinValue : null));
  const [maxValue, setMaxValue] = useState(isOnboarding ? null : (storeMaxValue !== 0 ? storeMaxValue : null));
  const [currency, setCurrency] = useState(isOnboarding ? null : (currencyType !== "" ? currencyType : null));
  
  useEffect(() => {
    if(storeMinValue) {
      setMinValue(storeMinValue);
    }
    if(storeMaxValue) {
      setMaxValue(storeMaxValue);
    }
  }, [storeMinValue, storeMaxValue])

  const handleChangeMinValue = (value) => {
    setMinValue(value);
    if (changeMinValue) {
      changeMinValue(value);
    }
  }

  const handleChangeMaxValue = (value) => {
    setMaxValue(value);
    if (changeMaxValue) {
      changeMaxValue(value)
    }
  }

  const handleChangeCurrency = (value) => {
    setCurrency(value);
    if (changeCurrency) {
      changeCurrency(value)
    }
  }
  return (
    <div className={[classes.marginTop16, ...rootClasses].join(' ')}>
      <span className={[classes.fontSize14, classes.fontInputColor].join(' ')}>Preferred Investment Size</span>
      <div className={[classes.marginTop16, isOnboarding ? classes.flexAlignCenter : ""].join(' ')}>
        <span className={[classes.fontSize14, classes.fontColorLight].join(' ')} style={{ marginRight: '8px' }}>Between</span>
        <FormControl variant="outlined" >
          <Select
            className={[classes.styledSelect].join(' ')}
            style={{ width: "80px", marginRight: "8px" }}
            value={currency}
            onChange={e => handleChangeCurrency(e.target.value)}
            color="primary"
            MenuProps={{ variant: "menu", disableScrollLock: true }}>
            {curencies.map((x, index) => <MenuItem key={index} value={x} selected={index === 0}>{x}</MenuItem>)}
          </Select>
        </FormControl>
        <NumberFormat allowNegative={false} max={maxValue} thousandSeparator={','} decimalSeparator={'.'} placeholder="e.g. 100,000" style={{ width: isOnboarding ? "140px" : "", borderColor: minValue > maxValue ? "red" : "" }} className={[classes.basicInputField, classes.fontSize14, classes.fontColorLight].join(' ')} value={minValue} onValueChange={valueObject => handleChangeMinValue(valueObject.floatValue)} />
        <span className={[classes.fontSize14, classes.fontColorLight].join(' ')} style={{ margin: '0px 12px' }}>and</span>
        <NumberFormat allowNegative={false} thousandSeparator={','} decimalSeparator={'.'} placeholder="e.g. 100,000" style={{ width: isOnboarding ? "140px" : "", borderColor: maxValue < minValue ? "red" : "" }} className={[classes.basicInputField, classes.fontSize14, classes.fontColorLight].join(' ')} value={maxValue} onValueChange={valueObject => handleChangeMaxValue(valueObject.floatValue)} />
      </div>
    </div>
  )
}

export default InvestmentSize;