import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generalStyleClasses } from '../../utils/general-styles';
import RadioButtonsGroup from '../RadioButtonsGroup/RadioButtonsGroup';
import { executionTimeline } from './Options/InvestmentOptions';

const ExecutionTimeline = ({ handleChange, rootClasses = {} }) => {
  const classes = generalStyleClasses();
  const defaultValue = useSelector(store => store.investorProfile.investment_execution_timeline);
 
  useEffect(() => {
    if(defaultValue && defaultValue > 0) {
      handleChange(defaultValue);
    }
  }, [])

  return (
    <div className={rootClasses}>
      <span className={[classes.fontSize14, classes.fontInputColor].join(' ')}>Execution Timeline</span>
      <div className={classes.marginTop16}>
        <RadioButtonsGroup options={executionTimeline} handleChange={handleChange} defaultValue={defaultValue}/>
      </div>
    </div>
  )
}

export default ExecutionTimeline;