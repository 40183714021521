export const calculateIndustriesProgress = (store) => {
  let progress = 0;
  if(store.investorProfile.innovation_enablers && store.investorProfile.innovation_enablers.length > 0) {
    progress += 25;
  }
  if(store.investorProfile.interested_sectors && store.investorProfile.interested_sectors.length > 0) {
    progress += 25;
  }
  if(store.investorProfile.investor_profile_innovation_enablers_lesses && store.investorProfile.investor_profile_innovation_enablers_lesses.length > 0) {
    progress += 25;
  }
  if(store.investorProfile.investor_profile_interested_sectors_lesses && store.investorProfile.investor_profile_interested_sectors_lesses.length > 0) {
    progress += 25;
  }
  return progress;
}

export const calculateCompanyProgress = (store) => {
  let progress = 0;
  if(store.investorProfile.company_business_model_B2B || 
    store.investorProfile.company_business_model_B2B2C || 
    store.investorProfile.company_business_model_B2C) {
    progress += 25;
  }
  if(store.investorProfile.company_development_stage_build_product || 
    store.investorProfile.company_business_model_B2B2C || 
    store.investorProfile.company_development_stage_has_product ||
    store.investorProfile.company_development_stage_is_profitable) {
    progress += 25;
  }
  if(store.investorProfile.company_funding_stage_any_stage || 
    store.investorProfile.company_funding_stage_post_seed || 
    store.investorProfile.company_funding_stage_pre_ipo || 
    store.investorProfile.company_funding_stage_seed || 
    store.investorProfile.company_funding_stage_series_a || 
    store.investorProfile.company_funding_stage_series_b || 
    store.investorProfile.company_funding_stage_series_c || 
    store.investorProfile.company_funding_stage_series_d || 
    store.investorProfile.company_funding_stage_series_e || 
    store.investorProfile.company_funding_stage_series_f) {
    progress += 25;
  }
  if(store.investorProfile.countries && store.investorProfile.countries.length > 0) {
    progress += 25;
  }
  return progress;
}

export const calculateInvestmentProgress = (store) => {
  let progress = 0;
  if(store.investorProfile.investment_type && store.investorProfile.investment_type > 0) {
    progress += 100/6;
  }
  if(store.investorProfile.investment_security_type && store.investorProfile.investment_security_type > 0) {
    progress += 100/6;
  }
  if(store.investorProfile.investment_execution_timeline && store.investorProfile.investment_execution_timeline > 0) {
    progress += 100/6;
  }
  if(store.investorProfile.investment_status && store.investorProfile.investment_status > 0) {
    progress += 100/6;
  }
  if(store.investorProfile.investment_size_high && store.investorProfile.investment_size_high > 0) {
    progress += 100/6;
  }
  if(store.investorProfile.investment_size_low && store.investorProfile.investment_size_low > 0) {
    progress += 100/6;
  }
  return Math.min(progress, 100);
}