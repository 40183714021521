import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import { Fragment } from 'react';
import Navbar from '../../onboarding/Navbar/Navbar';
import { Link, useHistory, withRouter } from 'react-router-dom';
import TextInput from '../../shared/TextInput/TextInput';
import { hasLowerCase, hasNumber, hasUpperCase } from '../../utils/validators';
import ErrorLabel from '../../shared/ErrorLabel/ErrorLabel';
import { Button, makeStyles } from '@material-ui/core';
import { generalStyleClasses } from '../../utils/general-styles';
import { Events, EventThrower } from '../../shared/EventEmitter/EventEmitter';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const contentStyles = makeStyles({
  container: {
    width: "560px",
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    padding: "40px",
    boxSizing: "border-box",
    marginTop: "200px",
    zIndex: "1",
  }
})
const ResetPasswordFinalStep = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const oobCode = params.get("oobCode");
  const continueUrl = params.get("continueUrl");
  const apiKey = params.get("apiKey");
  const classes = generalStyleClasses();
  const containerClasses = contentStyles();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordValidationErrors, setPasswordValidationErrors] = useState([true, true, true]);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [valid, setValid] = useState(false);
  const [oobValid, setOobValid] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const history = useHistory();

  const handlePasswordChange = (newValue) => {
    if (newValue === '' || !newValue) {
      setPasswordError(false);
      setPasswordValidationErrors([1, 1, 1]);
      return;
    }
    let passValidators = passwordValidationErrors;
    passValidators[0] = newValue.length < 8;
    passValidators[1] = !(hasLowerCase(newValue) && hasUpperCase(newValue));
    passValidators[2] = !hasNumber(newValue);
    setNewPassword(newValue);
    setValid(passValidators.filter(x => x === true).length === 0 && confirmPassword !== "")
    setPasswordValidationErrors(passValidators);
  }

  useEffect(() => {
    if (process.env.REACT_APP_FIREBASE_APIKEY !== apiKey) {
      EventThrower.throwClientSideError(Events.LoginError("Request origin validation failed"));
      history.push("/");
      return;
    }
    if(firebase.auth().currentUser) {
      firebase.auth().signOut()
    }
    firebase.auth().verifyPasswordResetCode(oobCode).then(email => {
      setOobValid(true);
    }).catch(() => {
      EventThrower.throwClientSideError(Events.LoginError("Request expired"));
      history.push("/");
    });
  }, [])

  useEffect(() => {
    if (confirmPassword !== '') {
      setConfirmPasswordError(false);
    }
    setValid(passwordValidationErrors.filter(x => x === true).length === 0 && confirmPassword !== "")
  }, [confirmPassword]);

  const handlePasswordReset = () => {
    if (newPassword !== confirmPassword) {
      EventThrower.throwClientSideError(Events.PasswordsMismatchError)
    } else {
      firebase.auth().confirmPasswordReset(oobCode, newPassword).then(resp => {
        setPasswordChanged(true);
      }).catch(console.log)
    }
  }

  if (!oobValid) return <div />

  return (
    <div className={classes.flexAlignCenterOnColumn}>
      <Navbar style={{ background: "#F4F4F4", width: "100%" }} />
      <div className={containerClasses.container}>
        {
          !passwordChanged
            ? (
              <Fragment>
                <p className={[classes.marginTop0, classes.marginbottom0, classes.fontSize20, classes.fontColorDark].join(' ')}>Reset Password</p>
                <div className={classes.marginTop32}>
                  <TextInput label="New Password" isPassword={true} handleChange={value => handlePasswordChange(value)} error={passwordError} />
                  {
                    newPassword !== ""
                      ? (
                        <Fragment>
                          <ErrorLabel label="Use 8 or more characters" active={passwordValidationErrors[0]} />
                          <ErrorLabel label="Uppercase and lowercase letters (e.g. Aa)" active={passwordValidationErrors[1]} />
                          <ErrorLabel label="Use numbers (e.g. 1234)" active={passwordValidationErrors[2]} />
                        </Fragment>
                      )
                      : ""
                  }
                </div>
                <div className={classes.marginTop24}>
                  <TextInput label="Confirm New Password" isPassword={true} handleChange={value => setConfirmPassword(value)} error={confirmPasswordError} />
                </div>
                <Button className={[classes.marginTop32, classes.singleFullWidthLinkButton].join(' ')} variant="contained" color="primary" disabled={!valid} onClick={() => handlePasswordReset()}> Reset Password </Button>
              </Fragment>
            )
            : (
              <div className={classes.flexAlignCenterOnColumn}>
                <CheckCircleOutlineIcon style={{ color: "#518149", width: "28px", height: "28px" }} />
                <p className={[classes.marginTop24, classes.marginBottom0, classes.fontSize15, classes.fontInputColor].join(' ')}>
                  <span>Password reseted successfully. </span>
                  <Link to={continueUrl}>Login to your account.</Link>
                </p>
              </div>
            )
        }
      </div>
    </div>
  )
}

export default withRouter(ResetPasswordFinalStep);