import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { formatMiliseconds, numberWithCommas } from '../../../utils/formats';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from '../../AdminStyles';
import { GET_CLIENT_ACTIVITY } from '../../queries/admin-queries';
import { mapClientActivity } from '../utils';

const activities = [
  {opportunity: "SpaceX", views: "11", sessionTime: "1h 5m", action: "Watchlist", details: "30,000", lastAction: new Date().toLocaleDateString()},
  {opportunity: "SpaceX", views: "11", sessionTime: "1h 5m", action: "Watchlist", details: "30,000", lastAction: new Date().toLocaleDateString()},
  {opportunity: "SpaceX", views: "11", sessionTime: "1h 5m", action: "Watchlist", details: "30,000", lastAction: new Date().toLocaleDateString()},
]

const ClientActivity = () => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const typografyClasses = adminTypografyStyles();
  const tableClasses = adminTablesStyle();
  const id = useRouteMatch().params.id;
  const history = useHistory();
  const {data} = useQuery(GET_CLIENT_ACTIVITY, {variables: {firebase_id: id}});
  const [mappedData, setMappedData] = useState([]);

  useEffect(() => {
    if(!id) {
      history.goBack();
    }
  }, [])

  useEffect(() => {
    if(data) {
      setMappedData(mapClientActivity(data.Account[0]?.Investments || [], data.Account[0]?.watchlist || [], data.Account[0]?.analytics || []));
    }
  }, [data])

  return (
    <Fragment>
      <div className={dimensionClasses.containerWidth}>
        <div className={[tableClasses.tableHeader, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14, dimensionClasses.marginTop24].join(' ')}>
          <p className={[dimensionClasses.width200].join(' ')}>Oportunity</p>
          <p className={[dimensionClasses.width200].join(' ')}>Views</p>
          <p className={dimensionClasses.width200}>Sesions time</p>
          <p className={dimensionClasses.width200}>Status</p>
          <p className={dimensionClasses.width200}>Investment value</p>
        </div>
        {
          data && !data.Account[0]?.Investments?.length && !data.Account[0]?.watchlist?.length
          ? "No activity data"
          : mappedData.map((x, index) => (
            <div key={index} className={[tableClasses.row, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14].join(' ')}>
                 <p className={[dimensionClasses.width200].join(' ')}>{x.name || '-'}</p>
                  <p className={[dimensionClasses.width200].join(' ')}>{x.views || '-'}</p>
                  <p className={dimensionClasses.width200}>{x.time && formatMiliseconds(x.time) || '-'}</p>
                  <p className={dimensionClasses.width200}>{x.status.status || '-'}</p>
                  <p className={dimensionClasses.width200}>{x.value && `$${numberWithCommas(x.value)}` || '-'}</p>
            </div>
          ))
        }
        {/* {
          
        } */}
      </div>
    </Fragment>
  )
}

export default ClientActivity;