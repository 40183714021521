import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analytics: false,
  app_content_access: false,
  assign_roles: false,
  create_opportunity: false,
  edit_opportunity: false,
  edit_opportunity_privacy: false,
  manage_client: false,
  manage_client_document: false,
  manage_roles: false,
  publish_opportunity: false,
  read_client_document: false,
  read_client_info: false,
  read_employee: false,
  settings: false,
  view_other_user: false,
  handle_onboarding: false,
  create_acc_for_investor: false,
  edit_client_info: false,
}

const permissionSlice = createSlice({
  initialState: initialState,
  name: "permissions",
  reducers: {
    RESET_PERMISSIONS: (state) => {
      for (let key of Object.keys(state)) {
        state[key] = false;
      }

    },
    SET_PERMISSIONS: (state, action) => {
      action.payload.forEach(permissionSet => {
        for (let key of Object.keys(permissionSet)) {
          state[key] = state[key] || permissionSet[key];
        }
      })
    }
  }
})

export const permissionAsignmentsActions = permissionSlice.actions;
export const permissionAsignmentseReducer = permissionSlice.reducer;