import { Button } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { hasuraErrorHandler } from '../../../shared/EventEmitter/EventEmitter';
import { adminDimensionsStyle } from '../../AdminStyles';
import ClientActivity from './ClientActivity';
import ClientDocuments from './ClientDocuments';
import SingleClientPageInvestorProfile from './InvestorProfile';

const SingleClientPage = () => {
  const dimensionClasses = adminDimensionsStyle();
  const canAccess = useSelector(store => store.permissionAsignments.read_client_info);
  const [tab, setTab] = useState(0);
  const history = useHistory();

  if(!canAccess) {
    hasuraErrorHandler.printError('You cannot view the client\'s information');
    history.push('/admin/clients/all');
  }
  return (
    <Fragment>
      <div className={[dimensionClasses.containerWidth, dimensionClasses.marginTop16, dimensionClasses.marginbottom16, dimensionClasses.headerMarginFix].join(' ')}>
        <Button variant={tab === 0 ? "contained" : "outlined"} onClick={() => setTab(0)} color="primary" className={[dimensionClasses.smallHeigth, dimensionClasses.marginRight12].join(' ')}>Investor profile</Button>
        <Button variant={tab === 1 ? "contained" : "outlined"} onClick={() => setTab(1)} color="primary" className={[dimensionClasses.smallHeigth, dimensionClasses.marginRight12].join(' ')}>Activity</Button>
        <Button variant={tab === 2 ? "contained" : "outlined"} onClick={() => setTab(2)} color="primary" className={[dimensionClasses.smallHeigth, dimensionClasses.marginRight12].join(' ')}>Documents</Button>
      </div>
      {
        tab === 0 && <SingleClientPageInvestorProfile /> ||
        tab === 1 && <ClientActivity /> ||
        tab === 2 && <ClientDocuments />
      }
    
    </Fragment>
  )
}

export default SingleClientPage;