import { useMutation } from "@apollo/client";
import { Button, MenuItem, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '@material-ui/core/Input';
import { adminAlignmentStyles, adminDimensionsStyle, adminInputStyles, adminTablesStyle, adminTypografyStyles } from "../../AdminStyles";
import { ADD_GENERAL_PERMISSION, DELETE_GENERAL_PERMISSION, UPDATE_PERMISSIONS_BY_ID } from "../../queries/admin-queries";
import { Events, EventThrower, hasuraErrorHandler } from "../../../shared/EventEmitter/EventEmitter";
import { formatUnderlineSeparatedName } from "../../../utils/formats";
import { permissionLabeles } from "./permissionLabeles";

const EmployeeManagementRow = ({ user, roles, canAssign, openManagementDialog }) => {
  const tableClasses = adminTablesStyle();
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const inputClasses = adminInputStyles();
  const typografyClasses = adminTypografyStyles();
  const [addGeneralPermission] = useMutation(ADD_GENERAL_PERMISSION);
  const [removeGeneralPermission] = useMutation(DELETE_GENERAL_PERMISSION);
  const [currentRoles, setCurrentRoles] = useState(user.permissions.map(x => x.role_id));

  const updatePermissions = (roles) => {
    // if(roles.indexOf(7) == -1) {
    //   roles = roles.filter(x => x !== 10);
    // }
    // const variables = {
    //   firebaseId: user.firebase_id,
    //   permissions: roles.map(role => ({ firebase_id: user.firebase_id, role_id: role }))
    // }
    // updateRoles({ variables: variables })
  }

  const handleRoleChanges = (roleId) => {
    console.log(roleId, currentRoles, user);
    if(currentRoles.indexOf(roleId) === -1) {
        addGeneralPermission({variables: {
          role_id: roleId, 
          firebase_id: user.firebase_id
        }}).then(resp => {
          if(resp.data.insert_Permissions_assignment_one.role_id) {
            setCurrentRoles([...currentRoles, roleId]);
            EventThrower.throwClientSideMessage(Events.RolesUpdatedSuccessfully)
          } else {
            hasuraErrorHandler.printError("Error updating roles");
          }
          console.log(resp)
        })
    } else {
      const roles = [roleId];
      if(roleId === 7) roles.push(10);
      removeGeneralPermission({variables: {
        role_id: roles, 
        firebase_id: user.firebase_id
      }}).then(resp => {
        if(resp.data.delete_Permissions_assignment.affected_rows) {
          setCurrentRoles(currentRoles.filter(x => roles.indexOf(x) === -1));
          EventThrower.throwClientSideMessage(Events.RolesUpdatedSuccessfully)
        } else {
          hasuraErrorHandler.printError("Error updating roles");
        }
        console.log(resp)
      })
    }
  };

  const handleOpenManagementDialog = (employeeId) => {
    if(openManagementDialog) {
      openManagementDialog(employeeId);
    }
  }


  return (
    <div className={[tableClasses.row, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14].join(' ')}>
      <p className={dimensionClasses.width200}>
        {
          user.first_name && user.last_name
            ? `${user.first_name} ${user.last_name}`
            : "Name not provided"
        }
      </p>
      <p className={dimensionClasses.width250}>{user.email}</p>
      <FormControl variant="outlined" className={dimensionClasses.widthGrow}>
        <Select
          variant="outlined"
          multiple
          disabled={!canAssign}
          className={[
            inputClasses.select,
            dimensionClasses.mediumHeight,
            dimensionClasses.width200,
            typografyClasses.fontSize14,
          ].join(' ')}
          value={currentRoles}
          input={<Input />}
          MenuProps={{ variant: "menu", disableScrollLock: true }}
          renderValue={(selected) => selected.map(x => {
            const role = roles.filter(role => role.id === x)[0];
            return role ? `${formatUnderlineSeparatedName(role.role)}, ` : ""})}>
          {roles.map(x => (
            <MenuItem key={x.id} value={x.id} style={{ padding: "0px" }}>
              <Tooltip title={permissionLabeles[x.id.toString()]} placement="right" arrow >
                <Button style={{ width: "100%", textTransform: "none" }} disableElevation disableFocusRipple disableRipple onClick={() => handleRoleChanges(x.id)}>
                  {formatUnderlineSeparatedName(x.role)}
                </Button>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="outlined" color="primary" className={dimensionClasses.mediumHeight} onClick={() => handleOpenManagementDialog(user.firebase_id)}>Manage</Button>
      <IconButton>
        <EditIcon />
      </IconButton>
    </div>
  )
}

export default EmployeeManagementRow;