import { ThemeProvider } from "@material-ui/core/styles";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import firebase from "firebase";
import "firebase/auth";
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import GlobalSetup from "./GlobalSetup";
import ErrorHandler from './shared/ErrorHandler/ErrorHandler';
import AppRouter from './AppRouter';
import theme from "./utils/theme";
import { useSelector } from "react-redux";
import { TimingSetup } from "./shared/TimingHook/Timer";
//import Delegate from "./utils/delegate";

function App() {
  const isAdmin = useSelector(store => store.account.isAdmin);
  const [whiteBackground, setWhiteBackground] = useState(false);
  localStorage.setItem("companyName", "Logoipsum");
  localStorage.setItem("companyLogo", "logoipsum.svg");
  localStorage.setItem("companySlogan", "Company slogan goes here");

  const firebaseConfig = {
    appId: process.env.REACT_APP_FIREBASE_APPID || '',
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY || '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID || '',
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN || '',
    databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECTID}.firebaseio.com`,
    storageBucket: 'sigma-e448a.appspot.com',
  };

  const isAdminRoute = () => window.location.href.indexOf("admin") === -1;

  useEffect(() => {
    if(isAdmin && window.location.href.indexOf('/admin') !== -1) {
      setWhiteBackground(true);
    } else {
      setWhiteBackground(false);
    }
  }, [isAdmin])

  return (
    <FirebaseAuthProvider  {...firebaseConfig} firebase={firebase}>
      <ThemeProvider theme={theme}>
        <GlobalSetup />
        <div style={{ paddingBottom: "50px", flexGrow: "1", display: "flex", flexDirection: "column", background: whiteBackground ? "white" : "none" }}>
          <TimingSetup />
          <Router>
            <AppRouter />
            {/* <div style={{position: "fixed", bottom: 0, right: 0}}>
              <Delegate/>
            </div> */}
          </Router>
        </div>
        {
          isAdminRoute() && <div className="footer">All rights reserved ®logipsum 2020</div>
        }
        <ErrorHandler />
      </ThemeProvider>
    </FirebaseAuthProvider>
  );
}

export default App;
