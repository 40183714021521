import { Redirect, Switch } from 'react-router-dom';
import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import AdminClientsPanel from './ClientsPage/ClientsPanel';
import AdminAdminPanel from './AdminPage/AdminPanel';
import AdminNavbar from './navbar/AdminNavbar';
import OpportunitiesPanel from './OpportunitiesPage/OpportunitiesPanel';
import AdminInvestmentPanel from './Investment/InvestmentPanel';
import AdminAnalyticsPanel from "./AnalyticsPage/AnalyticsPanel";
import AdminChatPanel from './ChatPage/ChatPanel';
import AdminAccount from './AdminAccount';

const AdminPanel = () => {
  return (
    <Fragment>
      <AdminNavbar />
      <Switch>
        <Route path="/admin/clients" component={AdminClientsPanel} />
        <Route path="/admin/admin" component={AdminAdminPanel} />
        <Route path="/admin/opportunities" component={OpportunitiesPanel} />
        <Route path="/admin/investment" component={AdminInvestmentPanel} />
        <Route path="/admin/analytics" component={AdminAnalyticsPanel} />
        <Route path="/admin/chat" component={AdminChatPanel} />
        <Route path="/admin/account" component={AdminAccount} />
        <Route
          path="*"
          component={() => <Redirect to="/admin/opportunities" />}
        />
      </Switch>
    </Fragment>
  );
}

export default AdminPanel;