import { makeStyles } from '@material-ui/core';
import { set } from 'lodash';
import React, { useEffect, useState } from 'react';

const style = makeStyles({
  container: {
    marginRight: "12px",
    display: "flex",
    flexDirection: "column",
    padding: "2px 5px",
    justifyContent: "space-around",
    borderRadius: "36px",
    height: "16px",
    transition: "0.2s all ease-in",
    cursor: "pointer",
    "&:hover": {
      background: "#f4f4f4",
    }
  }
})


export const OrderButtons = ({ value, orderFunctions = [], specificFunction = null }) => {
  const classes = style();
  const [order, setOrder] = useState("asc_nulls_first");

  const handleSetOrder = (newOrder) => {
    orderFunctions.forEach(x => x(null));
    setOrder(newOrder);
    if(specificFunction) {
      specificFunction(newOrder);
    }
  }

  useEffect(() => {
    setOrder(value)
  }, [value]);

  return (
    <div className={classes.container} onClick={() => handleSetOrder(order === "asc_nulls_first" ? "desc_nulls_last" : "asc_nulls_first")}>
      <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.75 6L6 4.5897e-07L11.25 6L0.75 6Z" fill={order === "asc_nulls_first" ? "#434448" : "#A9A9A9"} />
      </svg>

      <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.25 1.83588e-06L6 6L0.75 0L11.25 1.83588e-06Z" fill={order === "desc_nulls_last" ? "#434448" : "#A9A9A9"}  />
      </svg>
    </div>
  )
}

export default OrderButtons;