import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { GET_COMPANY_DETAILS_BY_ID } from '../../../queries/admin-queries';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from "../../../AdminStyles";
import { oportunitySectionStyle } from "./OportunitySectionStyles";
import { useSelector } from 'react-redux';
import { capitalize } from '../../../../utils/formats';

const CompanyFundingHistory = () => {
  const classes = oportunitySectionStyle();
  const dimensionClasses = adminDimensionsStyle();
  const tableStyles = adminTablesStyle();
  const typografyClasses = adminTypografyStyles();
  const alignmentClasses = adminAlignmentStyles();
  const { companyId } = useSelector(store => store.adminOpportunity.resultingOpportunity) || {};
  const [getCompanyDetails, { data }] = useLazyQuery(GET_COMPANY_DETAILS_BY_ID, { variables: { id: companyId } });
  
  useEffect(() => {
    companyId && getCompanyDetails && getCompanyDetails({ variables: { id: companyId } });
  }, [companyId, getCompanyDetails]);

  if (!data || !data.Company_by_pk) {
    return <div className={classes.content}>Company funding history loading</div>
  }
  
  return (
            <div className={[classes.content, alignmentClasses.flexWrap].join(' ')}>
              <div className={[tableStyles.tableHeader, alignmentClasses.flexAlignCenter, dimensionClasses.width100Percent].join(' ')}>
                <p className={[dimensionClasses.width200, dimensionClasses.marginTop8, dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')}>Financing round</p>
                <p className={[dimensionClasses.width200, dimensionClasses.marginTop8, dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')}>Pre-money valuation</p>
                <p className={[dimensionClasses.width200, dimensionClasses.marginTop8, dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')}>Amount raised</p>
                <p className={[dimensionClasses.widthGrow, dimensionClasses.marginTop8, dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')}>Date</p>
                <p className={[dimensionClasses.width200, dimensionClasses.marginTop8, dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')}>Notable investor</p>
              </div>
              {
                 data.Company_by_pk.funding_histories.length > 0 
                 ? data.Company_by_pk.funding_histories.map((x, index) => (
                  <div key={index} className={[tableStyles.row, alignmentClasses.flexAlignCenter, dimensionClasses.width100Percent].join(' ')}>
                    <p className={[dimensionClasses.width200, dimensionClasses.marginTop8, dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')}>
                      {x.financing_round.split('_').map(x => capitalize(x)).join(' ')}
                    </p>
                    <p className={[dimensionClasses.width200, dimensionClasses.marginTop8, dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')}>
                      {`${x.pre_money_valuation} ${x.pre_money_valuation_currency}`}
                    </p>
                    <p className={[dimensionClasses.width200, dimensionClasses.marginTop8, dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')}>
                    {`${x.amount_raised} ${x.amount_raised_curency}`}
                    </p>
                    <p className={[dimensionClasses.widthGrow, dimensionClasses.marginTop8, dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')}>
                      {new Date(x.date).toLocaleDateString()}
                    </p>
                    <p className={[dimensionClasses.width200, dimensionClasses.marginTop8, dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')}>
                      {x.notable_investors.map(x => x.notable_investor_name).join(', ')}
                    </p>
                  </div>  
                ))
                : "No funding history data for this company"
              }

            </div>
  )
}

export const companyFundingHistoryAreaSection = () => ({
  component: <CompanyFundingHistory />,
  title: (<span>Funding History  (this section is editable in the company page)</span>)
})


export default CompanyFundingHistory;