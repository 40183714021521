import { gql } from '@apollo/client';

export const CREATE_INVESTOR_PROFILE = gql`
mutation CreateInvestorProfile($firebase_id: String!) {
  insert_Investor_profile(objects: {company_business_model_B2B: false, company_business_model_B2B2C: false, company_business_model_B2C: false, company_development_stage_build_product: false, company_development_stage_generates_revenue: false, company_development_stage_has_product: false, company_development_stage_is_profitable: false, company_funding_stage_any_stage: false, company_funding_stage_post_seed: false, company_funding_stage_pre_ipo: false, company_funding_stage_seed: false, company_funding_stage_series_a: false, company_funding_stage_series_b: false, company_funding_stage_series_c: false, company_funding_stage_series_d: false, company_funding_stage_series_e: false, company_funding_stage_series_f: false, industry_innovation_enablers: "", industry_interested_sectors: "", investment_execution_timeline: "1", investment_security_type: "1", investment_size_high: "0", investment_size_low: "0", investment_status: "1", investment_type: "1", firebase_id: $firebase_id, first_time_viewed: 0}) {
    affected_rows
    returning {
      id
    }
  }
}
`;

export const UPDATE_COMPANY_INVESTOR_PREFERANCES = gql`
mutation UpdateCompanyInvestorProfile($firebase_id: String!, $company_funding_stage_series_f: Boolean = false, $company_funding_stage_series_e: Boolean = false, $company_funding_stage_series_d: Boolean = false, $company_funding_stage_series_c: Boolean = false, $company_funding_stage_series_b: Boolean = false, $company_funding_stage_series_a: Boolean = false, $company_funding_stage_seed: Boolean = false, $company_funding_stage_pre_ipo: Boolean = false, $company_funding_stage_post_seed: Boolean = false, $company_funding_stage_any_stage: Boolean = false, $company_development_stage_is_profitable: Boolean = false, $company_development_stage_has_product: Boolean = false, $company_development_stage_generates_revenue: Boolean = false, $company_development_stage_build_product: Boolean = false, $company_business_model_B2C: Boolean = false, $company_business_model_B2B2C: Boolean = false, $company_business_model_B2B: Boolean = false, $company_additional_information: String = "", $countries: [Investor_profile_countries_insert_input!]!, $company_funding_stage_convertible_note: Boolean = false, $company_funding_stage_other: Boolean = false, $company_funding_stage_pre_seed: Boolean = false, $company_funding_stage_series_g: Boolean = false, $company_funding_stage_series_h: Boolean = false, $company_funding_stage_series_i: Boolean = false, $company_funding_stage_series_j: Boolean = false, $company_funding_stage_series_k: Boolean = false) {
  delete_Investor_profile_countries(where: {investor_profile: {firebase_id: {_eq: $firebase_id}}}) {
    affected_rows
  }
  insert_Investor_profile_countries(objects: $countries) {
    affected_rows
  }
  update_Investor_profile(where: {firebase_id: {_eq: $firebase_id}}, _set: {company_business_model_B2B: $company_business_model_B2B, company_business_model_B2B2C: $company_business_model_B2B2C, company_business_model_B2C: $company_business_model_B2C, company_development_stage_build_product: $company_development_stage_build_product, company_development_stage_generates_revenue: $company_development_stage_generates_revenue, company_development_stage_has_product: $company_development_stage_has_product, company_development_stage_is_profitable: $company_development_stage_is_profitable, company_funding_stage_any_stage: $company_funding_stage_any_stage, company_funding_stage_post_seed: $company_funding_stage_post_seed, company_funding_stage_pre_ipo: $company_funding_stage_pre_ipo, company_funding_stage_seed: $company_funding_stage_seed, company_funding_stage_series_a: $company_funding_stage_series_a, company_funding_stage_series_b: $company_funding_stage_series_b, company_funding_stage_series_c: $company_funding_stage_series_c, company_funding_stage_series_d: $company_funding_stage_series_d, company_funding_stage_series_e: $company_funding_stage_series_e, company_funding_stage_series_f: $company_funding_stage_series_f, company_additional_information: $company_additional_information, company_funding_stage_convertible_note: $company_funding_stage_convertible_note, company_funding_stage_other: $company_funding_stage_other, company_funding_stage_pre_seed: $company_funding_stage_pre_seed, company_funding_stage_series_g: $company_funding_stage_series_g, company_funding_stage_series_h: $company_funding_stage_series_h, company_funding_stage_series_i: $company_funding_stage_series_i, company_funding_stage_series_j: $company_funding_stage_series_j, company_funding_stage_series_k: $company_funding_stage_series_k}) {
    affected_rows
    returning {
      company_additional_information
      company_business_model_B2B
      company_business_model_B2B2C
      company_business_model_B2C
      company_development_stage_build_product
      company_development_stage_generates_revenue
      company_development_stage_has_product
      company_development_stage_is_profitable
      company_funding_stage_any_stage
      company_funding_stage_post_seed
      company_funding_stage_pre_ipo
      company_funding_stage_seed
      company_funding_stage_series_a
      company_funding_stage_series_b
      company_funding_stage_series_c
      company_funding_stage_series_d
      company_funding_stage_series_f
      company_funding_stage_series_e
      company_funding_stage_convertible_note
      company_funding_stage_other
      company_funding_stage_pre_seed
      company_funding_stage_series_g
      company_funding_stage_series_h
      company_funding_stage_series_i
      company_funding_stage_series_j
      company_funding_stage_series_k
      industry_additional_information
      industry_innovation_enablers
      industry_interested_sectors
      countries {
        ENUM_country {
          description
          value
        }
      }
      investment_additional_information
      investment_execution_timeline
      investment_security_type
      investment_size_high
      investment_size_low
      investment_status
      investment_type
      investor_profile_innovation_enablers_lesses {
        ENUM_innovation_enabler {
          description
          value
        }
      }
      investor_profile_interested_sectors_lesses {
        ENUM_interested_sector {
          description
          value
        }
      }
      interested_sectors {
        ENUM_interested_sector {
          description
          value
        }
      }
      innovation_enablers {
        ENUM_innovation_enabler {
          description
          value
        }
      }
    }
  }
}

`;

export const UPDATE_INVESTMENT_PREFERANCES = gql`
mutation MyMutation($firebase_id: String!, $investment_type: String!, $investment_status: String!, $investment_size_low: bigint!, $investment_size_high: bigint!, $investment_security_type: String!, $investment_execution_timeline: String!, $investment_additional_information: String = "", $investment_preffered_currency: String = "") {
  update_Investor_profile(where: {firebase_id: {_eq: $firebase_id}}, _set: {investment_type: $investment_type, investment_status: $investment_status, investment_size_low: $investment_size_low, investment_size_high: $investment_size_high, investment_security_type: $investment_security_type, investment_execution_timeline: $investment_execution_timeline, investment_additional_information: $investment_additional_information, investment_preffered_currency: $investment_preffered_currency}) {
    affected_rows
    returning {
      company_additional_information
      company_business_model_B2B
      company_business_model_B2B2C
      company_business_model_B2C
      company_development_stage_build_product
      company_development_stage_generates_revenue
      company_development_stage_has_product
      company_development_stage_is_profitable
      company_funding_stage_any_stage
      company_funding_stage_post_seed
      company_funding_stage_pre_ipo
      company_funding_stage_seed
      company_funding_stage_series_a
      company_funding_stage_series_b
      company_funding_stage_series_c
      company_funding_stage_series_d
      company_funding_stage_series_f
      company_funding_stage_series_e
      company_funding_stage_convertible_note
      company_funding_stage_other
      company_funding_stage_pre_seed
      investment_preffered_currency
      company_funding_stage_series_g
      company_funding_stage_series_h
      company_funding_stage_series_i
      company_funding_stage_series_j
      company_funding_stage_series_k
      industry_additional_information
      industry_innovation_enablers
      industry_interested_sectors
      countries {
        ENUM_country {
          description
          value
        }
      }
      investment_additional_information
      investment_execution_timeline
      investment_security_type
      investment_size_high
      investment_size_low
      investment_status
      investment_type
      investor_profile_innovation_enablers_lesses {
        ENUM_innovation_enabler {
          description
          value
        }
      }
      investor_profile_interested_sectors_lesses {
        ENUM_interested_sector {
          description
          value
        }
      }
      interested_sectors {
        ENUM_interested_sector {
          description
          value
        }
      }
      innovation_enablers {
        ENUM_innovation_enabler {
          description
          value
        }
      }
    }
  }
}
`;

export const UPDATE_INVESTOR_PROFILE_INDUSTRY = gql`
mutation UpdateInterestedSectors($firebase_id: String!, $sectors: [Investor_profile_interested_sectors_insert_input!]!, $enablers: [Investor_profile_innovation_enablers_insert_input!]!, $industry_additional_information: String = "", $sectors_less: [Investor_profile_interested_sectors_less_insert_input!]!, $enablers_less: [Investor_profile_innovation_enablers_less_insert_input!]!) {
  delete_Investor_profile_interested_sectors(where: {investor_profile: {firebase_id: {_eq: $firebase_id}}}) {
    affected_rows
  }
  insert_Investor_profile_interested_sectors(objects: $sectors) {
    affected_rows
  }
  delete_Investor_profile_innovation_enablers(where: {investor_profile: {firebase_id: {_eq: $firebase_id}}}) {
    affected_rows
  }
  insert_Investor_profile_innovation_enablers(objects: $enablers) {
    affected_rows
  }
  delete_Investor_profile_interested_sectors_less(where: {investor_profile: {firebase_id: {_eq: $firebase_id}}}) {
    affected_rows
  }
  insert_Investor_profile_interested_sectors_less(objects: $sectors_less) {
    affected_rows
  }
  delete_Investor_profile_innovation_enablers_less(where: {investor_profile: {firebase_id: {_eq: $firebase_id}}}) {
    affected_rows
  }
  insert_Investor_profile_innovation_enablers_less(objects: $enablers_less) {
    affected_rows
  }
  update_Investor_profile(where: {firebase_id: {_eq: $firebase_id}}, _set: {industry_additional_information: $industry_additional_information}) {
    affected_rows
    returning {
      company_additional_information
      company_business_model_B2B
      company_business_model_B2B2C
      company_business_model_B2C
      company_development_stage_build_product
      company_development_stage_generates_revenue
      company_development_stage_has_product
      company_development_stage_is_profitable
      company_funding_stage_any_stage
      company_funding_stage_post_seed
      company_funding_stage_pre_ipo
      company_funding_stage_seed
      company_funding_stage_series_a
      company_funding_stage_series_b
      company_funding_stage_series_c
      company_funding_stage_series_d
      company_funding_stage_series_f
      company_funding_stage_series_e
      company_funding_stage_convertible_note
      company_funding_stage_other
      company_funding_stage_pre_seed
      company_funding_stage_series_g
      company_funding_stage_series_h
      company_funding_stage_series_i
      company_funding_stage_series_j
      company_funding_stage_series_k
      industry_additional_information
      industry_innovation_enablers
      industry_interested_sectors
      countries {
        ENUM_country {
          description
          value
        }
      }
      investment_additional_information
      investment_execution_timeline
      investment_security_type
      investment_size_high
      investment_size_low
      investment_status
      investment_type
      investor_profile_innovation_enablers_lesses {
        ENUM_innovation_enabler {
          description
          value
        }
      }
      investor_profile_interested_sectors_lesses {
        ENUM_interested_sector {
          description
          value
        }
      }
      interested_sectors {
        ENUM_interested_sector {
          description
          value
        }
      }
      innovation_enablers {
        ENUM_innovation_enabler {
          description
          value
        }
      }
    }
  }
}

`;

export const GET_INVESTOR_PROFILE = gql`query GetInvestorProfile($profile_id: bigint!) {
  Investor_profile(where: {id: {_eq: $profile_id}}) {
    company_additional_information
    company_business_model_B2B
    company_business_model_B2B2C
    company_business_model_B2C
    company_development_stage_build_product
    company_development_stage_generates_revenue
    company_development_stage_has_product
    company_development_stage_is_profitable
    company_funding_stage_any_stage
    company_funding_stage_post_seed
    company_funding_stage_pre_ipo
    company_funding_stage_seed
    company_funding_stage_series_a
    company_funding_stage_series_b
    company_funding_stage_series_c
    company_funding_stage_series_d
    company_funding_stage_series_f
    company_funding_stage_series_e
    company_funding_stage_convertible_note
    company_funding_stage_other
    company_funding_stage_pre_seed
    company_funding_stage_series_g
    company_funding_stage_series_h
    company_funding_stage_series_i
    company_funding_stage_series_j
    company_funding_stage_series_k
    countries(where: {profile_id: {_eq: $profile_id}}) {
      ENUM_country {
        description
        value
        __typename
      }
      __typename
    }
    industry_additional_information
    id
    innovation_enablers {
      ENUM_innovation_enabler {
        description
        value
        __typename
      }
      __typename
    }
    interested_sectors {
      ENUM_interested_sector {
        description
        value
        __typename
      }
      __typename
    }
    investment_additional_information
    investment_preffered_currency
    investment_execution_timeline
    investment_security_type
    investment_size_high
    investment_size_low
    investment_status
    investment_type
    first_time_viewed
    investor_profile_innovation_enablers_lesses {
      ENUM_innovation_enabler {
        description
        value
        __typename
      }
      __typename
    }
    investor_profile_interested_sectors_lesses {
      ENUM_interested_sector {
        description
        value
        __typename
      }
      __typename
    }
    __typename
   
  }
}
`;

export const SET_INVESTOR_PROFILE_VIEWED = gql`
mutation MyMutation($id: bigint!) {
  update_Investor_profile_by_pk(pk_columns: {id: $id}, _set: {first_time_viewed: ${Date.now()}}) {
    id
    first_time_viewed
  }
}
`;