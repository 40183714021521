export const investmentType = [
    {value: '1', label: "Primary"},
    {value: '2', label: "Secondary"},
    {value: '3', label: "Indifferent"},
]

export const securityType = [
    {value: '1', label: "Common"},
    {value: '2', label: "Preferred"},
    {value: '3', label: "Indifferent"},
]

export const executionTimeline = [
    {value: '1', label: "I prefer to see opportunities that are active and I can move quickly"},
    {value: '2', label: "I need a long-enough time to consider investing and I am comfortable expressing interest at prospective stage"},
]

export const investmentStatus = [
    {value: '1', label: "I am currently looking to invest in new deals"},
    {value: '2', label: "I am looking for new opportunities, but not likely to invest in the next 3 months"},
    {value: '3', label: "I am no longer investing until further notice"},
]