import { sectionData } from "../../../store/slices/adminOpportunity";
import store from "../../../store/store";
import { DealSectionType } from "../../../utils/enums";

export const mapDealForEdit = (deal) => {
  const flowId = deal.Investment_flow_section_edges[0]?.Flow_node?.id || "";
  const flowName = deal.Investment_flow_section_edges[0]?.Flow_node?.name || "";
  const sections = [
    sectionData(0, DealSectionType.OVERVIEW),
    deal.company_description_order > 0 && sectionData(deal.company_description_order, DealSectionType.DESCRIPTION),
    deal.company_management_order > 0 && sectionData(deal.company_management_order, DealSectionType.MANAGEMENT),
    deal.funding_history_order > 0 && sectionData(deal.funding_history_order, DealSectionType.FUNDING),
    ...(!deal.section_text_areas ? [] : deal.section_text_areas.map(textSection => sectionData(textSection.order, DealSectionType.RICH_TEXT_AREA, {
      id: textSection.id,
      title: textSection.title,
      data: {
        content: textSection.body
      }
    }))),
    ...(!deal.section_files ? [] : deal.section_files.map(fileSection => sectionData(fileSection.order, DealSectionType.FILES, {
      id: fileSection.id,
      title: fileSection.title,
      data: {content: fileSection.file},
    }))),
    ...(!deal.section_images ? [] : deal.section_images.map(imageSection => sectionData(imageSection.order, DealSectionType.IMAGES, {
      id: imageSection.id,
      title: imageSection.title,
      data: imageSection.files_metadata ? {content: [imageSection.files_metadata]} : null,
    })))
  ].filter(v => !!v).sort((a, b) => a.order - b.order);
  return {
    id: deal.id,
    companyId: deal.company_id,
    type: deal.status,
    offeringType: deal.offering_type,
    securityType: deal.security_type,
    currency: deal.investment_curency,
    valuation: deal.valuation,
    totalOfferedAmount: deal.investment_value,
    amountAvailable: deal.investment_amount_available,
    deadline: deal.investment_deadline,
    fees: deal.fees,
    title: deal.title,
    investmentMin: deal.investment_min,
    investmentMax: deal.investment_max,
    flowId: flowId,
    flowName: flowName,
    published: deal.published_at && deal.published_at > 0,
    sections: sections
  }
}


export const mapDealForCreate = (deal, firebaseId) => {
  const sectionsInfo = {
    [DealSectionType.MANAGEMENT]: -1,
    [DealSectionType.DESCRIPTION]: -1,
    [DealSectionType.FUNDING]: -1,
    [DealSectionType.FILES]: [],
    [DealSectionType.IMAGES]: [],
    [DealSectionType.RICH_TEXT_AREA]: [],
  } 

  deal.sections.forEach(section => {
    switch (section.type) {
      case DealSectionType.FUNDING:
      case DealSectionType.MANAGEMENT: 
      case DealSectionType.DESCRIPTION:
       sectionsInfo[section.type] = section.order;
      break;

      case DealSectionType.RICH_TEXT_AREA: 
        sectionsInfo[section.type].push({title: section.title || "", body: section.content || "", order: section.order })
        break;
      
      case DealSectionType.FILES: 
      case DealSectionType.IMAGES:
        sectionsInfo[section.type].push({title: section.title || "",  order: section.order })
        break;

      default:
        break;
    }
  })
  const data = {
    company_id: deal.companyId,
    investment_deadline: new Date(deal.deadline).valueOf(),
    investment_curency: deal.currency,
    fees: deal.fees,
    valuation: deal.valuation,
    status: deal.type,
    investment_value: deal.totalOfferedAmount,
    opening_date: new Date(deal.deadline).valueOf(),
    security_type: deal.securityType,
    offering_type: deal.offeringType,
    firebase_id: firebaseId,
    title: deal.title,
    investment_max: deal.investmentMax,
    investment_min: deal.investmentMin,
    company_management_order: sectionsInfo[DealSectionType.MANAGEMENT],
    company_description_order: sectionsInfo[DealSectionType.DESCRIPTION],
    funding_history_order: sectionsInfo[DealSectionType.FUNDING],
    section_text_areas: sectionsInfo[DealSectionType.RICH_TEXT_AREA],
    documents: sectionsInfo[DealSectionType.FILES],
    image_sections: sectionsInfo[DealSectionType.IMAGES],
  }
  return data;
}