import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Events, EventThrower } from '../../../../../shared/EventEmitter/EventEmitter';
import LabeledText from '../../../../../shared/LabeledText/LabeledText';
import Loading from '../../../../../shared/Loading/Loading';
import { cancelDealInvestment, GET_DEAL_INVESTMENTS_SUMARY } from '../../../../../utils/deal-queries';
import { numberWithCommas } from '../../../../../utils/formats';
import { generalStyleClasses } from '../../../../../utils/general-styles';
import OpportunityCard from '../../OpportunitiesPage/OpportunityCard/OpportunityCard';
import { ReviewAndSignCardNotification } from './CardNotifications/ReviewAndSignCardNotification';
import NoInvestmentsPage from './NoInvestmentPage';

const investmentsClasses = makeStyles({
  documentsContainer: {
    width: "266px",
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    height: "230px",
    marginRight: "24px",
    padding: "24px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  }
})

const InvestmentsOpportunitiesContainer = ({isCompleted = false, isInProgress = false, orderString = "", nameFilter = null}) => {
  const generalClasses = generalStyleClasses();
  const classes = investmentsClasses();
  const firebase_id = useSelector(store => store.account.user.firebase_id);
  const { data, loading } = useQuery(GET_DEAL_INVESTMENTS_SUMARY(isCompleted, isInProgress, orderString, nameFilter), { variables: { firebase_id: firebase_id }, fetchPolicy: "network-only" });
  const [investments, setInvestments] = useState([]);
  const [openInvestmentId, setOpenInvestmentId] = useState(false);

  const handleCancelInvestment = () => {
    if(openInvestmentId) {
      cancelDealInvestment(openInvestmentId).then(() => {
        setInvestments(investments.map(x => {
          if(x.Investments[0]?.id === openInvestmentId) {
            x.Investments[0].canceled = true;
          }
          return x;
        }))
        setOpenInvestmentId(null)
        EventThrower.throwClientSideMessage(Events.SuccessMessage("Investment canceled"));
      }).catch(console.log)
    }
  }

  useEffect(() => {
    if(data) {
      const mapped = JSON.parse(JSON.stringify(data.Deal));
      setInvestments(mapped.map(x => {
        if(x.Investments[0]) {
          x.Investments[0].canceled = false;
        }
        return x;
      }));
    }
  }, [data])

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loading />
      </div>
    )
  }

  if (!data || !data.Deal || data.Deal.length === 0) {
    return <NoInvestmentsPage />
  }

  return (
    <Fragment>
      {investments.filter(x => x.Investments.length === 0 || !x.Investments[0].canceled).map((x) => (
        <div className={generalClasses.flex} key={x.id}>
          <div className={classes.documentsContainer} style={{ paddingTop: '15px' }}>
            <LabeledText
              label={`Your ${x.Investments.length ? "investment" : "interest"}`}
              text={`$${numberWithCommas(x.Investments.length ? x.Investments[0].value : x.Interests[0].ammount)}`}
            />
            <LabeledText
              label="Date"
              text={new Date(
                x.Investments.length ? x.Investments[0].created_at : x.Interests[0].created_at
              ).toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
              customStyles={{ paddingTop: '7px' }}
            />
            <LabeledText label="Status" text={
              x.Investments.length
                ? (x.Investments[0].completed === 0 ? "In progress" : "Completed")
                : "Interest recorded"
            } customStyles={{ paddingTop: '7px', marginBottom: '7px' }} />
            <div className={x.Investments.length && generalClasses.dualLinkButtonsGroup || null}>
              {
                (x.Investments.length === 0 || x.Investments[0].completed === 0) && 
              <Button
                variant="outlined"
                color="primary"
                style={{ height: "30px", width: x.Investments.length === 0 ? '100%' : null }}
                onClick={() => setOpenInvestmentId(x.Investments[0]?.id || null)}
              >
                Cancel
              </Button>
              }
              {x.Investments.length &&
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "30px",  width: x.Investments.length !== 0 && x.Investments[0].completed !== 0 ? '100%' : null  }}
                  component={Link}
                  to={{pathname: `/app/document-handler/${x.id}/${x.Investments[0].id}`, state: { lastPage: "/app/investments/all" }}}
                >
                  Manage
                </Button>
                || null
              }
            </div>
          </div>
          <OpportunityCard
            deal={x}
            notification={x.Investments.length !== 0 && x.Investments[0].completed === 0 && <ReviewAndSignCardNotification /> || null}
          />
        </div>
      ))}
      <Dialog
        open={openInvestmentId}
        onClose={() => setOpenInvestmentId(false)}
        PaperProps={{style: {width: "504px", padding: "0px"}}}
      >
        <DialogTitle>Cancel your current investment?</DialogTitle>
        <DialogContent>
            Are you sure you want to cancel this investment? You will not be able to go back to it and all your progress will be lost.
        </DialogContent>
        <DialogActions>
          <Button style={{height: "36px"}} color="primary" variant="outlined" onClick={() => setOpenInvestmentId(false)} color="primary">
            Cancel
          </Button>
          <Button style={{height: "36px"}} color="primary" variant="contained" onClick={() => handleCancelInvestment()} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default InvestmentsOpportunitiesContainer;