import React, { Fragment, useState } from 'react';
import { generalStyleClasses } from '../../../utils/general-styles';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DarkCheckbox from '../../../shared/DarkCheckbox/DarkCheckbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './account.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { SET_USER_ACCOUNT } from '../../../store/utils/action-types';
import { EventThrower, Events } from '../../../shared/EventEmitter/EventEmitter';
import { UPDATE_ACCOUNT_EMAIL_PREFFERENCES } from '../../../utils/account-queries';

const EmailPreferances = () => {
  const classes = generalStyleClasses();
  const account = useSelector(store => store.account.user);
  const dispatch = useDispatch();
  const [updateAccount] = useMutation(UPDATE_ACCOUNT_EMAIL_PREFFERENCES);
  const [watchlistInvestments, setWatchlistInvestments] = useState(account.email_preference_watchlist );
  const [personalized, setPersonalized] = useState(account.email_preference_personalised_deals);
  const [investmentTips, setInvestmentTips] = useState(account.email_preference_investment_tips);
  const [investmentOpportunities, seInvestmentOpportunities] = useState(account.email_preference_investment_opportunities);
  const [emailFreq, setEmailFreq] = useState(account.email_preference_frequency);

  const updateEmailPrefferances = () => {
    updateAccount({
      variables: {
        firebase_id: account.firebase_id,
        email_preference_frequency: emailFreq,
        email_preference_investment_opportunities: investmentOpportunities,
        email_preference_investment_tips: investmentTips,
        email_preference_personalised_deals: personalized,
        email_preference_watchlist: watchlistInvestments
      }
    }).then(result => {
      dispatch({ type: SET_USER_ACCOUNT, payload: result.data.update_Account_by_pk })
      EventThrower.throwClientSideMessage(Events.InformationChangedMessage);
    }).catch(error => {
        console.error(error);
    })
  }

  return (
    <Fragment>
      <p className={[classes.fontSize16, classes.fontColorDark, classes.marginBottom0, classes.marginTop24].join(' ')}>Email preferences</p>
      <FormControlLabel style={{ width: "fit-content" }} control={<DarkCheckbox checked={watchlistInvestments} onChange={e => setWatchlistInvestments(e.target.checked)} />} label="Watchlist investments updates" className={[classes.fontColorLight, classes.fontSize14, classes.marginTop16].join(' ')} />
      <FormControlLabel style={{ width: "fit-content" }} control={<DarkCheckbox checked={personalized} onChange={e => setPersonalized(e.target.checked)} />} label="Personalized deals" className={[classes.fontColorLight, classes.fontSize14].join(' ')} />
      <FormControlLabel style={{ width: "fit-content" }} control={<DarkCheckbox checked={investmentTips} onChange={e => setInvestmentTips(e.target.checked)} />} label="Investment tips" className={[classes.fontColorLight, classes.fontSize14].join(' ')} />
      <FormControlLabel style={{ width: "fit-content" }} control={<DarkCheckbox checked={investmentOpportunities} onChange={e => seInvestmentOpportunities(e.target.checked)} />} label="Investment opportunities" className={[classes.fontColorLight, classes.fontSize14].join(' ')} />
      <div className={["bottom-select", classes.marginTop16].join(' ')}>
        <span className={[classes.fontColorDark, classes.fontSize16].join(' ')}>
          Investment opportunities mails frequency
          </span>
        <FormControl variant="outlined">
          <Select native value={emailFreq} onChange={e => setEmailFreq(e.target.value)}>
            <option value={1}>Once per day</option>
            <option value={2}>Once per week</option>
            <option value={3}>Two times per month</option>
            <option value={4}>Once per month</option>
            <option value={5}>Never</option>
          </Select>
        </FormControl>
      </div>
      <Button variant="contained" color="primary" className="bottom-button" onClick={updateEmailPrefferances}>Save preferences</Button>
    </Fragment>

  )
}

export default EmailPreferances;