import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { adminAlignmentStyles, adminDimensionsStyle, adminTypografyStyles } from '../../AdminStyles';
import { useMutation, useQuery } from '@apollo/client';
import MultipleSelectField from '../../../shared/MultipleSelect/MultipleSelectField';
import { GET_ALL_AVAILABLE_EMPLOYEES, GET_ASSIGNED_DEAL_MEMBERS, UPDATE_DEAL_EMPLOYEE_PERMISSIONS } from '../../queries/admin-queries';
import { useSelector } from 'react-redux';
import { selectionSetMatchesResult } from '@apollo/client/cache/inmemory/helpers';
import { Events, EventThrower, hasuraErrorHandler } from '../../../shared/EventEmitter/EventEmitter';

const OportunityEmployeesDialog = ({ open, setOpen, dealId }) => {
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const typografyClasses = adminTypografyStyles();
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const {data: employees} = useQuery(GET_ALL_AVAILABLE_EMPLOYEES, {fetchPolicy: 'network-only'});
  const {data: dealEmployees, loading} = useQuery(GET_ASSIGNED_DEAL_MEMBERS, {fetchPolicy: 'network-only', variables: {id: dealId}})
  const [updatePermissions] = useMutation(UPDATE_DEAL_EMPLOYEE_PERMISSIONS);
  const [allEmployees, setAllEmployees] = useState([]);
  const [oldEmployees, setOldEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };

  const handleNewEmployees = (newValue) => {
    setSelectedEmployees(newValue)
  }

  const handleApplyPermissions = () => {
    updatePermissions({
      variables: {
        newEmployees: selectedEmployees.map(x => ({role_id: "8", deal_id: dealId, firebase_id: x.value})),
        deal_id: dealId,
        firebase_id: oldEmployees.map(x => x.value)
      }
    }).then(() => {
      EventThrower.throwClientSideMessage(Events.SuccessMessage("Deal employees updated"))
      handleClose();
    }).catch(() => {
      hasuraErrorHandler.printError("Error updating deal employees")
    });
  }

  useEffect(() => {
    if(employees) {
      setAllEmployees(employees.Account.filter(x => x.firebase_id !== firebaseId).map(x => ({value: x.firebase_id, description: `${x.first_name} ${x.last_name}`})))
    }
  }, [employees]);

  useEffect(() => {
    if(dealEmployees && dealEmployees.Deal_by_pk) {
      setOldEmployees(dealEmployees.Deal_by_pk.permissions_assignments.map(x => ({value: x.firebase_id, description: `${x.account.first_name} ${x.account.last_name}`})))
    }
  }, [dealEmployees]);

  if(loading) return <div/>
  return (
    <Dialog open={open} onClose={handleClose} style={{ padding: "24px" }} PaperProps={{ style: { padding: "24px" } }} disableScrollLock={true}>
      <DialogTitle className={[alignmentClasses.modalTitle, dimensionClasses.padding0].join(' ')} >
        {dealEmployees && dealEmployees.Deal_by_pk && `${dealEmployees.Deal_by_pk.company.name} ${dealEmployees.Deal_by_pk.title ? '-' : ''} ${dealEmployees.Deal_by_pk.title} employees`}
      <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={dimensionClasses.padding0}>
        <p className={[typografyClasses.fontSize15, typografyClasses.fontColor373737].join(' ')}>Employees which can edit this deal:</p>
        <MultipleSelectField optionsInput={allEmployees} handleChange={value => handleNewEmployees(value)} defaultValues={oldEmployees} customFieldStyle={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '15px', marginRight: '0px'}}/>        
      </DialogContent>
      <DialogActions className={dimensionClasses.padding0}>
        <Button className={[dimensionClasses.marginTop24, dimensionClasses.smallHeigth]} onClick={handleClose} variant="outlined" color="primary">
          Close
          </Button>
          <Button className={[dimensionClasses.marginTop24, dimensionClasses.smallHeigth]} onClick={() => handleApplyPermissions()} variant="contained" color="primary">
          Apply
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OportunityEmployeesDialog;