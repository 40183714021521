import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { makeStyles } from '@material-ui/core';

const customStyle = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  icon: {
    fill: "#518149",
  },
  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#6F7074",
  }
})

const SuccessMessage = ({ message }) => {
  const classes = customStyle();

  return (
    <div className={classes.container}>
      <CheckCircleOutlineIcon className={classes.icon} />
      <p className={classes.text}>{message}</p>
    </div>
  )
}

export default SuccessMessage;