import React, { Fragment, useEffect, useState } from "react"
import { Link, NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { adminStyles, adminAlignmentStyles, adminDimensionsStyle, adminTypografyStyles, adminInputStyles } from "../AdminStyles";
import { OutlinedInput, InputAdornment, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AddIcon from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { DealSectionType } from "../../utils/enums";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { CANCEL_DEAL, GET_DEAL_IS_PUBLISHED } from "../queries/admin-queries";
import { adminOpportunityActions } from "../../store/slices/adminOpportunity";
import OpportunityPreviewDialog from "./AddOportunity/Preview/PreviewDialog";
import { unpublishDeal } from "../queries/admin-http-calls";
import { Events, EventThrower } from "../../shared/EventEmitter/EventEmitter";

export const DefaultHeader = ({ changeFilter }) => {
  const adminClasses = adminStyles();
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const typografyclasses = adminTypografyStyles();
  const [nameFilter, setNameFilter] = useState("");
  const { url } = useRouteMatch();
  
  useEffect(() => {
    if (changeFilter) {
      changeFilter(nameFilter);
    }
  }, [nameFilter, changeFilter]);

  return (
    <Fragment>
      <div className={alignmentClasses.flex}>
        <NavLink className={[adminClasses.navLinkButton, dimensionClasses.smallHeigth, alignmentClasses.flexAlignCenter, typografyclasses.fontSize14, typografyclasses.fontColor7B7B7B].join(' ')} activeClassName={adminClasses.navLinkActiveButton} to={`${url}/list-opportunities`}>Deals</NavLink>
        <NavLink className={[adminClasses.navLinkButton, dimensionClasses.smallHeigth, alignmentClasses.flexAlignCenter, typografyclasses.fontSize14, typografyclasses.fontColor7B7B7B].join(' ')} activeClassName={adminClasses.navLinkActiveButton} to={`${url}/companies`}>Companies</NavLink>
      </div>
      <OutlinedInput
        placeholder="Search..."
        variant="outlined"
        className={adminClasses.searchField}
        value={nameFilter}
        onChange={e => setNameFilter(e.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </Fragment>
  )
}

export const AddCompanyHeader = ({ saveCompanyCall }) => {
  const dimensionClasses = adminDimensionsStyle();
  const typografyclasses = adminTypografyStyles();
  const saveCompany = () => {
    if (saveCompanyCall) {
      saveCompanyCall();
    }
  }

  return (
    <Fragment>
      <Button component={Link} to="/admin/opportunities/companies" className={[dimensionClasses.smallHeigth, typografyclasses.fontSize16, typografyclasses.noTrasnform, typografyclasses.fontColor78756F].join(' ')}>
        <KeyboardBackspaceIcon />
        Back
      </Button>
      <Button variant="contained" color="primary" className={dimensionClasses.smallHeigth} onClick={saveCompany}>Save Company</Button>
    </Fragment>
  )
}

export const AddOpportunityHeader = ({ publishDeal, saveDealChanges, isEdit = false }) => {
  const inputClasses = adminInputStyles();
  const dimensionClasses = adminDimensionsStyle();
  const typografyclasses = adminTypografyStyles();
  const alignmentClasses = adminAlignmentStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const {data, loading} = useQuery(GET_DEAL_IS_PUBLISHED, {variables: {id: Number(window.location.href.substring(window.location.href.lastIndexOf('/')+1))}, fetchPolicy: "network-only"});
  const [cancelDeal] = useMutation(CANCEL_DEAL, {variables: {id: Number(window.location.href.substring(window.location.href.lastIndexOf('/')+1))}});
  const [isPublished, setIsPublished] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const closeMenu = () => {
    setAnchorEl(null);
  };
  
  const addSection = (sectionType) => {
    dispatch(adminOpportunityActions.ADD_SECTION({
      type: sectionType
    }));
    closeMenu();
  }

  const handleUnpublishDeal = () => {
    cancelDeal().then(result => {
      unpublishDeal(result.data.update_Deal_by_pk.id).then(res => {
        EventThrower.throwClientSideMessage(Events.SuccessMessage("Deal unpublished"));
        setIsPublished(false);
        history.goBack();
      })
    })
  }

  useEffect(() => {
    if(data && data.Deal_by_pk && data.Deal_by_pk.published_at) {
      setIsPublished(true)
    }
  },[data, loading])

  const buttonClasses = [dimensionClasses.smallHeigth, typografyclasses.fontSize14, typografyclasses.fontColor7B7B7B, typografyclasses.noTrasnform, dimensionClasses.marginRight8, inputClasses.whiteButton].join(' ');
  return (
    <Fragment>
      <div className={alignmentClasses.flexAlignCenter}>
        <Button component={Link} to="/admin/opportunities/list-opportunities" className={[dimensionClasses.smallHeigth, typografyclasses.fontSize16, typografyclasses.noTrasnform, typografyclasses.fontColor78756F].join(' ')}>
          <KeyboardBackspaceIcon />
        Back
      </Button>
        <span className={[typografyclasses.fontSize14, typografyclasses.fontColor696969, dimensionClasses.marginLeft40].join(' ')} style={{ marginTop: "3px" }}>
          {isEdit ? "Edit" : "Add" } opportunity
          </span>
      </div>
      <div>
        <Button variant="outlined" color="primary" className={buttonClasses} onClick={() => setPreviewOpen(true)}>
          Preview
          </Button>
        <Button variant="outlined" color="primary" className={buttonClasses}
          style={{ background: "white" }} aria-controls="simple-menu" aria-haspopup="true" onClick={openMenu}>
          Add section
          <AddIcon style={{ marginLeft: "8px" }} />
        </Button>
        {
          isPublished
          ? <Button variant="outlined" color="primary" className={buttonClasses} onClick={handleUnpublishDeal}> Unpublish </Button>
          : <Button variant="outlined" color="primary" className={buttonClasses} onClick={saveDealChanges}>
            {window.location.href.indexOf("edit-opportunity") !== -1 ? "Update" : "Save"} without publishing
            </Button>
        }
     
        <Button variant="contained" color="primary" className={dimensionClasses.smallHeigth} onClick={publishDeal}>
          {isPublished ? "Update" : "Publish"}
          </Button>
      </div>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        <MenuItem onClick={() => addSection(DealSectionType.RICH_TEXT_AREA)}>Rich text area</MenuItem>
        <MenuItem onClick={() => addSection(DealSectionType.IMAGES)}>Image</MenuItem>
        <MenuItem onClick={() => addSection(DealSectionType.FILES)}>Documents</MenuItem>
        <MenuItem onClick={() => addSection(DealSectionType.DESCRIPTION)}>Description</MenuItem>
        <MenuItem onClick={() => addSection(DealSectionType.FUNDING)}>Funding History</MenuItem>
        <MenuItem onClick={() => addSection(DealSectionType.MANAGEMENT)}>Management</MenuItem>
      </Menu>
          <OpportunityPreviewDialog open={previewOpen} setOpen={setPreviewOpen}/>
    </Fragment>
  )
}