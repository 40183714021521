import React, { useEffect, useRef, useState } from 'react';
import TopsideMenu from './TopsideMenu/TopsideMenu';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import Company from './Company';
import Industry from './Industry';
import Investment from './Investment';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { preferancesPageStyles, progressContainerStyles } from "./PreferancesPageStyles";
import { generalStyleClasses } from "../../../../utils/general-styles";
import { useQuery } from '@apollo/client';
import { GET_INVESTOR_PROFILE } from '../../../../utils/investor-preferances-queries';
import { useDispatch, useSelector } from 'react-redux';
import { SET_INVESTOR_PROFILE } from '../../../../store/utils/action-types';
import Loading from '../../../../shared/Loading/Loading';
import { calculateCompanyProgress, calculateIndustriesProgress, calculateInvestmentProgress } from './progressbar-calculator';
const PreferancesPage = () => {
  const classes = preferancesPageStyles();
  const dispatch = useDispatch();
  const investorProfileId = useSelector(store => store.account.investorProfileId);
  const { data, loading } = useQuery(GET_INVESTOR_PROFILE, {variables: {profile_id: investorProfileId}, fetchPolicy: "network-only"});
  const progressContainerClasses = progressContainerStyles();
  const generalStyles = generalStyleClasses();
  const { url } = useRouteMatch();
  const industriesProgressFromStore = useSelector(store => calculateIndustriesProgress(store));
  const companyProgressFromStore = useSelector(store => calculateCompanyProgress(store));
  const investmentProgressFromStore = useSelector(store => calculateInvestmentProgress(store));
  const [industriesProgress, setIndustriesProgress] = useState(0);
  const [companyProgress, setCompanyProgress] = useState(0);
  const [investmentProgress, setInvestmentProgress] = useState(0);
  const contentRef = useRef(null);

  const listenScrollEvent = (event) => {
    if(contentRef.current) {
      if (window.scrollY > 0) {
        contentRef.current.style.zIndex = 50;
      } else {
        contentRef.current.style.zIndex = 101;
      }
      }
  }
  
  useEffect(() => {
    listenScrollEvent(null);
    window.addEventListener('scroll', listenScrollEvent);
  
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);
  useEffect(() => {
    if(data) {
      dispatch({type: SET_INVESTOR_PROFILE, payload: data.Investor_profile[0]});
    }
  }, [data, dispatch])

  useEffect(() => {
    setIndustriesProgress(industriesProgressFromStore);
  }, [industriesProgressFromStore]);

  useEffect(() => {
    setCompanyProgress(companyProgressFromStore);
  }, [companyProgressFromStore]);

  useEffect(() => {
    setInvestmentProgress(investmentProgressFromStore);
  }, [investmentProgressFromStore]);

  if(loading) {
    return <Loading fullWidth/>
  }
  return (
    <div className={[generalStyles.fullViewWidth, generalStyles.flexAlignCenterOnColumn].join(' ')}>
      <div className={[classes.topsideMenu, generalStyles.flexAlignCenter].join(' ')}>
        <TopsideMenu />
      </div>
      <div className={classes.contentContainer}>
        <div className={progressContainerClasses.progressContainer}>
          <p className={[generalStyles.fontInputColor, generalStyles.fontSize13].join(' ')}>Please tell us about your investment criteria to receive opportunities that meet your preferences</p>
          <div className={[progressContainerClasses.progressIndicator, generalStyles.flexSpaceBetweenCenter].join(' ')}>
            <span className={[progressContainerClasses.progressIndicatorCircle, industriesProgress === 100 ? progressContainerClasses.completedIndicatorCircle : ''].join(' ')}>
              {
                industriesProgress === 100
                  ? <CheckCircleIcon />
                  : ''
              }
            </span>
            <span className={[progressContainerClasses.progressIndicatorText, generalStyles.fontSize13].join(' ')}>Industries</span>
            {
              industriesProgress < 100
                ? <LinearProgress variant="determinate" color="primary" value={industriesProgress} className={progressContainerClasses.progressBar} />
                : ""
            }

          </div>
          <div className={[progressContainerClasses.progressIndicator, generalStyles.flexSpaceBetweenCenter].join(' ')}>
            <span className={[progressContainerClasses.progressIndicatorCircle, companyProgress === 100 ? progressContainerClasses.completedIndicatorCircle : ''].join(' ')}>
              {
                companyProgress === 100
                  ? <CheckCircleIcon />
                  : ''
              }
            </span>
            <span className={[progressContainerClasses.progressIndicatorText, generalStyles.fontSize13].join(' ')}>Company</span>
            {
              companyProgress < 100
                ? <LinearProgress variant="determinate" color="primary" value={companyProgress} className={progressContainerClasses.progressBar} />
                : ""
            }
          </div>
          <div className={[progressContainerClasses.progressIndicator, generalStyles.flexSpaceBetweenCenter].join(' ')}>
            <span className={[progressContainerClasses.progressIndicatorCircle, investmentProgress === 100 ? progressContainerClasses.completedIndicatorCircle : ''].join(' ')}>
              {
                investmentProgress === 100
                  ? <CheckCircleIcon />
                  : ''
              }
            </span>
            <span className={[progressContainerClasses.progressIndicatorText, generalStyles.fontSize13].join(' ')}>Investment</span>
            {
              investmentProgress < 100
                ? <LinearProgress variant="determinate" color="primary" value={investmentProgress} className={progressContainerClasses.progressBar} />
                : ""
            }
          </div>
        </div>
        <div className={classes.content} ref={contentRef}>
          <Switch>
            <Route path={`${url}/industry`}>
              <Industry />
            </Route>
            <Route path={`${url}/company`}>
              <Company />
            </Route>
            <Route path={`${url}/investment`}>
              <Investment />
            </Route>
            <Route exact path={`${url}/`}>
              <Redirect to={`${url}/industry`} />
            </Route>
          </Switch>
        </div>

      </div>
    </div>
  );
}

export default PreferancesPage;