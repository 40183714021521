import { makeStyles } from '@material-ui/core/styles';

export const opportunitiesPageStyles = makeStyles({
  topsideMenu: {
    width: '100%',
    height: '72px',
    background: '#F4F4F4',
    position: "fixed", 
    top: "56px",
    zIndex: "100"
  },
  contentContainer: {
    display: 'flex',
  },
  filters: {
    width: '266px',
    marginRight: '24px',
    position: "fixed", 
    top: "130px"
  },
  content: {
    minWidth: '890px',
    marginLeft: "290px",
    marginTop: "64px",
    zIndex: 101
  }
})