import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import CompanyTitleSection from './Sections/CompanyTitle';
import CompanyOverview from './Sections/CompanyOverview';
import CompanyDescription from './Sections/CompanyDescription';
import CompanyManagement from './Sections/CompanyManagement';
import { useQuery } from '@apollo/client';
import { GET_DEAL_SECTION_ORDER_BY_ID } from '../../../../../utils/deal-queries';
import RichTextArea from './Sections/RichTextArea';
import CompanyFundingHistory from './Sections/CompanyFundingHistory';
import ImageSection from './Sections/ImageSection';
import DocumentsSection from './Sections/DocumentsSection';
import DetailedOpportunityFooter from './Sections/Footer';
import { Fragment } from 'react';
import { Element } from 'react-scroll';
import { insertAnalytics } from '../../../../../utils/analytics-queries';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTiming } from '../../../../../shared/TimingHook/Timer';

const styles = makeStyles({
  container: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    padding: "40px",
  },
})

const OpportunityContainer = ({ oportunityId }) => {
  const classes = styles();
  const { data, loading } = useQuery(GET_DEAL_SECTION_ORDER_BY_ID, { variables: { id: oportunityId } });
  const firebaseId = useSelector(store => store.account.user.firebase_id)
  const [dealData, setDealData] = useState(null);
  const investmentIsMarkedCompletedForCurrentOpenedDeal = useSelector(state => {
    return state.utils.InvestmentIsMarkedCompletedForCurrentOpenedDeal
  })

  useTiming({
    firebaseId: firebaseId || undefined,
    companyId: (dealData && dealData.Deal_by_pk && dealData.Deal_by_pk.company_id) || undefined,
    dealId: oportunityId || undefined,
    event: "DEAL_DETAILED_VIEW_SESSION",
    payload: "",
  })
  useEffect(() => {
    if (data) {
      setDealData(data);
    }
  }, [data])
  
  if (loading || !data.Deal_by_pk) return <div />
  console.log(data.Deal_by_pk)

  return (
    <div className={classes.container}>
      <Element name={`section${1}`}>
      <CompanyTitleSection opportunityId={oportunityId} />
      </Element>
      <CompanyOverview opportunityId={oportunityId} />
      {
        new Array(1).fill(0).map(() => {
          let sections = []
          if (data.Deal_by_pk.company_description_order > 0) {
            sections.push({ order: data.Deal_by_pk.company_description_order, component: <CompanyDescription companyId={oportunityId} /> });
          }
          if (data.Deal_by_pk.company_management_order > 0) {
            sections.push({ order: data.Deal_by_pk.company_management_order, component: <CompanyManagement companyId={oportunityId} /> });
          }
          if (data.Deal_by_pk.funding_history_order > 0) {
            sections.push({ order: data.Deal_by_pk.funding_history_order, component: <CompanyFundingHistory opportunityId={oportunityId} /> });
          }
          if (data.Deal_by_pk.section_text_areas) {
            data.Deal_by_pk.section_text_areas.forEach(textArea => {
              sections.push({ order: textArea.order, component: <RichTextArea title={textArea.title} body={textArea.body} /> });
            });
          }
          if (data.Deal_by_pk.section_images) {
            data.Deal_by_pk.section_images.forEach(imageSection => {
              sections.push({ order: imageSection.order, component: <ImageSection title={imageSection.title} fieldId={imageSection.file_id} /> });
            });
          }
          if (data.Deal_by_pk.section_files) {
            data.Deal_by_pk.section_files.forEach(filesSection => {
              sections.push({ order: filesSection.order, component: <DocumentsSection title={filesSection.title} files={filesSection.file} /> });
            });
          }
          return sections.sort((a, b) => (a.order - b.order))
        })[0].map((x, index) => (
          <div key={index} >
            <div id={`section${index + 2}`} />
            <Element name={`section${index + 2}`} >
              {x.component}
            </Element>
          </div>
        ))
      }
      {
        !investmentIsMarkedCompletedForCurrentOpenedDeal && dealData && (dealData.Deal_by_pk.status === "ACTIVE" || dealData.Deal_by_pk.status === "PROSPECTIVE") && <DetailedOpportunityFooter dealId={oportunityId}/>
      }
      
    </div>
  )
}

export default OpportunityContainer;