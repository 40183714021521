import { useQuery } from '@apollo/client';
import React, { Fragment } from 'react';
import { DEAL_COMPANY_MANAGEMENT_BY_ID } from '../../../../../../utils/deal-queries';
import { generalStyleClasses } from '../../../../../../utils/general-styles';
import ManagementLabeledText from './ManagementLabeledText';

const CompanyManagement = ({ companyId }) => {
  const generalClasses = generalStyleClasses();
  const { data, loading } = useQuery(DEAL_COMPANY_MANAGEMENT_BY_ID, { variables: { id: companyId } });

  if (loading) return <div />

  return (
    <Fragment>
      <p className={[generalClasses.marginTop64, generalClasses.fontSize18, generalClasses.fontColorDark, generalClasses.marginBottom0].join(' ')}>Management</p>
      <div className={generalClasses.flexWrap}>
        {
          data.Deal_by_pk.company.company_managements.map((x, index) => <ManagementLabeledText key={index} bio={x.bio} label={x.name} text={x.position} customStyles={{marginTop: "24px"}}/>)
        }
      </div>
    </Fragment>
  )
}

export default CompanyManagement;