import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { GET_COMPANY_DETAILS_BY_ID } from '../../../queries/admin-queries';
import { oportunitySectionStyle } from "./OportunitySectionStyles";
import { useSelector } from 'react-redux';

const DescriptionSection = () => {
  const classes = oportunitySectionStyle();
  const { companyId } = useSelector(store => store.adminOpportunity.resultingOpportunity) || {};
  const [getCompanyDetails, { data }] = useLazyQuery(GET_COMPANY_DETAILS_BY_ID, { variables: { id: companyId } });

  useEffect(() => {
    companyId && getCompanyDetails && getCompanyDetails({variables: {id: companyId}});
  }, [companyId, getCompanyDetails]);

  if (!data || !data.Company_by_pk) {
    return <div className={classes.content}>Company description loading</div>
  }
  return (
    <div className={classes.content}>
      {data.Company_by_pk.description}
    </div>
  )
}

export const companyDescriptionAreaSection = () => ({
  component: <DescriptionSection />,
  title: (<span>Company Description (this section is editable in the company page)</span>)
})


export default DescriptionSection;