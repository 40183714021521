import { makeStyles } from '@material-ui/core/styles';

export const preferancesPageStyles = makeStyles({
  topsideMenu: {
    width: '100%',
    height: '72px',
    background: '#F4F4F4',
    position: "fixed", 
    top: "56px",
    zIndex: "100"
  },
  contentContainer: {
    display: 'flex',
  },
  content: {
    width: '890px',
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    padding: "24px",
    boxSizing: "border-box",
    marginLeft: "290px",
    marginTop: "64px",
    zIndex: 101
  },  
})

export const progressContainerStyles = makeStyles({
  progressContainer: {
    position: "fixed", 
    top: "120px",
    width: '266px',
    marginRight: '24px',
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    padding: "24px",
    boxSizing: "border-box",
    background: "#FFFFFF",
    height: "184px",
    zIndex: 101,
  },
  progressIndicator: {
    margin: "8px 0px",
  },
  progressIndicatorText: {
    marginLeft: "12px",
    flexGrow: "1",
  },
  progressBar: {
    width: "76px",
    height: "8px",
    borderRadius: "24px",
    background: "#F4F4F4",
    color: "#6A6A6A",
  },
  progressIndicatorCircle: {
    width: "14px",
    height: "14px",
    background: "#FFFFFF",
    border: "1px solid #C9C9C9",
    boxSizing: "border-box",
    borderRadius: "20px",
  },
  completedIndicatorCircle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    "& svg": {
      width: "16px",
      height: "16px",
      fill: "#1AA684",
    }
  }
})