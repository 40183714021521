import { makeStyles } from '@material-ui/core/styles';

export const useInvestorPreferencesStyles = makeStyles({
    routerContainer: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    investmentPreferencesContainer: {
        width: '560px',
        display: 'flex',
        boxSizing: "border-box",
        flexDirection: 'column',
        padding: '40px',
        paddingTop: '32px',
        background: 'white',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.08)',
    },
    closeButton: {
        position: 'absolute',
        right: '32px',
        top: '32px',
    }
})

