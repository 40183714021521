import React, { Fragment, useEffect, useState } from 'react';
import { adminDimensionsStyle, adminInputStyles, adminTypografyStyles } from '../../AdminStyles';
import { useQuery } from '@apollo/client';
import { GET_RELATIONSHIP_MANAGERS_AND_ADMINS } from '../../queries/admin-queries';
import { MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { adminCreateAccountActions } from '../../../store/slices/rmCreatedAccount';

const AccreditedClientStep = () => {
    const typografyClasses = adminTypografyStyles();
    const dimensionClasses = adminDimensionsStyle();
    const inputClasses = adminInputStyles();
    const {data} = useQuery(GET_RELATIONSHIP_MANAGERS_AND_ADMINS);
    const isAdmin = useSelector(store => store.account.user.permissions.filter(x => x.role_id === 3).length > 0);
    const selectedRm = useSelector(store => store.adminCreateAccount.relationshipManagerId)
    const firebaseId = useSelector(store => store.account.user.firebase_id);
    const dispatch = useDispatch();
    
    const setRelationshipManager = (value) => {
        dispatch(adminCreateAccountActions.SET_RM(value))
    }

    useEffect(() => {
        if(!isAdmin) {
            setRelationshipManager(firebaseId);
        }
    }, []);

    return (
        <Fragment>
            <div className={dimensionClasses.marginTop24}>
                <p className={[dimensionClasses.marginBottom8, dimensionClasses.marginTop0, typografyClasses.fontColor505154, typografyClasses.fontSize16].join(' ')}>Relationship Manager</p>
                <Select
                    variant="outlined"
                    className={inputClasses.singleSelect}
                    style={{ width: "100%", background: "white", height: "36px", marginTop: "4px" }}
                    onChange={e => setRelationshipManager(e.target.value)}
                    value={selectedRm}
                    disabled={!isAdmin}
                    MenuProps={{ variant: "menu", disableScrollLock: true }}>
                        <MenuItem value={0}>None</MenuItem>
                        {data && data.Account ? data.Account.map((x, index) => (<MenuItem key={index} value={x.firebase_id}>{x.first_name} {x.last_name}</MenuItem>)) : []}
                </Select>
            </div>
        </Fragment>
    )
}

export default AccreditedClientStep;