import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    stepperContainer: {
        display: 'flex',
    },
    step: {
        width: '100%',
        textAlign: 'center',
        "&:first-of-type > div.border": {
            borderTopLeftRadius: '4px',
            "& .border-content": {
                borderTopLeftRadius: '4px',
            }
        },
        "&:last-of-type > div.border": {
            borderTopRightRadius: '4px',
            "& .border-content": {
                borderTopRightRadius: '4px',
            }
        }
    },
    stepBorderBottom: {
        width: '100%',
        height: '8px',
        background: '#EDEDED',
    },
    animatedStep: {
        width: '0%',
        height: '100%',
        transition: 'all 0.2s ease-in',
        background: '#9FBF85',
    },
    activeStep: {
        width: '100%',
    }
})
const Step = ({ label, drawBorder, activeLabel, click = () => null }) => {
    const classes = useStyles();
    return (
        <div className={classes.step} onClick={() => click()}>
            <p style={{marginTop: '0px', color: activeLabel ? "#434448" : "#796E6E", cursor: "pointer"}}>{label}</p>
            <div className={[classes.stepBorderBottom, "border"].join(' ')}>
                <div className={[classes.animatedStep, drawBorder ? classes.activeStep : '', "border-content"].join(' ')}></div>
            </div>
        </div>
    )
}

const Stepper = forwardRef(( props, ref ) => {
    const classes = useStyles();
    const [currentStep, setCurrentStep] = useState(0);

    useImperativeHandle(
        ref,
        () => ({
            setStep(step) {
                if(step >= 0 && step <= props.steps.length) {
                    setCurrentStep(step);
                }
            },
            increaseStep() {
                if (currentStep < props.steps.length) {
                    setCurrentStep(currentStep + 1);
                }
            },
            decreaseStep() {
                if (currentStep > 0) {
                    setCurrentStep(currentStep - 1);
                }
            }

        })
    )
    return (
        <div style={props.style}>
            <div className={classes.stepperContainer}>
                {props.steps.map((step, index) => <Step key={index} label={step} drawBorder={currentStep > index} activeLabel={currentStep === index} click={() =>  props.onStepSelect && props.onStepSelect(index) }/>)}
            </div>
        </div>
    )
});

export default Stepper;