import { useQuery } from '@apollo/client';
import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@material-ui/core';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../../../../shared/TextInput/TextInput';
import { adminOpportunityActions } from '../../../../store/slices/adminOpportunity';
import { addOportunityActions } from '../../../../store/utils/action-types';
import { curencies } from '../../../../utils/curencies';
import { adminAlignmentStyles, adminDimensionsStyle, adminInputStyles, adminTypografyStyles } from '../../../AdminStyles';
import { GET_SECURITY_TYPE } from '../../../queries/admin-queries';

const OpportunityDetails = (props, ref) => {
  const dimensionClasses = adminDimensionsStyle();
  const typografyClasses = adminTypografyStyles();
  const inputClasses = adminInputStyles();
  const alignmentClasses = adminAlignmentStyles();
  const securityTypes = useQuery(GET_SECURITY_TYPE).data;

  const dispatch = useDispatch();
  const {resultingOpportunity} = useSelector(store => store.adminOpportunity);
  const {
    companyId: selectedCompanyId,
    offeringType,
    securityType,
    type: opportunityType,
    currency,
    valuation,
    totalOfferedAmount: offeredAmount,
    amountAvailable,
    deadline,
    title,
    fees,
    isSent,
    investmentMin,
    investmentMax,
  } = resultingOpportunity || {};

  const handleCurrency = (_currency) => dispatch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({currency: _currency}));
  const handleTitle = (_title) => dispatch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({title: _title}));
  const handleOfferingType = (_offeringType) => dispatch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({offeringType: _offeringType}));
  const handleSecurityType = (_securityType) => dispatch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({securityType: _securityType}));
  const handleValuation = (_valuation) => dispatch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({valuation: _valuation}));
  const handleOfferedAmount = (_offeredAmount) => dispatch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({totalOfferedAmount: _offeredAmount}));
  const handleDeadline = (_deadline) => dispatch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({deadline: _deadline}));
  const handleFees = (_fees) => dispatch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({fees: _fees}));
  const handleInvestmentMin = (_ammount) => dispatch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({investmentMin: _ammount}));
  const handleInvestmentMax = (_ammount) => dispatch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({investmentMax: _ammount}));

  const mapDeadline = (deadline) => {
    if(typeof(deadline) === "number") {
      return new Date(deadline).toISOString().split('T')[0];
    }
    return deadline;
  }

  if (!selectedCompanyId) return null;

  return (
    <div className={[dimensionClasses.containerWidth, dimensionClasses.marginTop16, alignmentClasses.flexWrap, alignmentClasses.flexCenterSpaceBetween].join(' ')} style={{alignItems: "flex-start"}}>
       <TextInput className="small36-input-field" label="Deal Name" placeholder="New space launch investment" initialValue={title} handleChange={handleTitle} customStyle={{ width: "250px", marginRight: "40px" }} />
      <div>
        <p className={[typografyClasses.fontSize14, dimensionClasses.marginBottom0].join(' ')} style={{ color: "#333333" }}>Offering Type*</p>
        <RadioGroup row aria-label="position" name="position" defaultValue="top" value={offeringType} onChange={e => handleOfferingType(e.target.value)}>
          <FormControlLabel
            value="PIRMARY"
            control={<Radio color="primary" style={{color: isSent && !offeringType ? "#f44336" : ""}} />}
            label="Primary"
          />
          <FormControlLabel
            value="SECONDARY"
            control={<Radio color="primary" style={{color: isSent && !offeringType ? "#f44336" : ""}} />}
            label="Secondary"
          />
          <FormControlLabel
            value="COMBINATION"
            control={<Radio color="primary" style={{color: isSent && !offeringType ? "#f44336" : ""}} />}
            label="Combination of both"
          />
        </RadioGroup>
      </div>
      <div className={dimensionClasses.marginLeft20}>
        <p className={[typografyClasses.fontSize14, dimensionClasses.marginBottom8, dimensionClasses.marginTop8].join(' ')} style={{ color: "#333333" }}>Securiy Type*</p>
        <FormControl variant="outlined" >
          <Select
            error={isSent && !securityType}
            className={[dimensionClasses.mediumHeight, inputClasses.singleSelect].join(' ')}
            style={{ width: "240px" }}
            value={securityType}
            onChange={e => handleSecurityType(e.target.value)}
            color="primary"
            MenuProps={{ variant: "menu", disableScrollLock: true }}>
            {securityTypes ? securityTypes.ENUM_financing_round.map((x, index) => <MenuItem key={index} value={x.value}>{x.description}</MenuItem>) : ""}
          </Select>
        </FormControl>
      </div>
      <div style={{ flexBasis: "100%" }} className={dimensionClasses.marginTop16} />
      <div>
        <p className={[typografyClasses.fontSize14, dimensionClasses.marginBottom8, dimensionClasses.marginTop0].join(' ')} style={{ color: "#333333" }}>Currency</p>
        <FormControl variant="outlined" >
          <Select
            className={[dimensionClasses.mediumHeight, inputClasses.singleSelect].join(' ')}
            style={{ width: "160px" }}
            color="primary"
            value={currency}
            onChange={e => handleCurrency(e.target.value)}
            MenuProps={{ variant: "menu", disableScrollLock: true }}>
            {curencies.map((x, index) => <MenuItem key={index} value={x}>{x}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
      <div className={dimensionClasses.marginLeft20}>
        <TextInput className="small36-input-field" label="Valuation (pre-money)*" isNumber isMoneySum placeholder="10,000,000" initialValue={valuation} error={isSent && !valuation} handleChange={handleValuation} customStyle={{ width: "300px" }} />
        <p className={[dimensionClasses.marginTop0, dimensionClasses.marginBottom0, typografyClasses.fontSize12, typografyClasses.fontColor5B5B5B].join(' ')}>Only numbers</p>
      </div>
      <div className={dimensionClasses.marginLeft20}>
        <TextInput className="small36-input-field" min={offeredAmount - amountAvailable} label="Total Offered Amount*" isNumber isMoneySum placeholder="1,200,000" initialValue={offeredAmount} error={isSent && !offeredAmount} handleChange={handleOfferedAmount} customStyle={{ width: "300px" }} />
        <p className={[dimensionClasses.marginTop0, dimensionClasses.marginBottom0, typografyClasses.fontSize12, typografyClasses.fontColor5B5B5B].join(' ')}>Only numbers</p>
      </div>
      <TextInput className="small36-input-field" minValue={new Date().toISOString().split('T')[0]} label={opportunityType === "ACTIVE" ? "Deadline" : (opportunityType === "PROSPECTIVE" ? "Opening date" : "Closing date")} isDate initialValue={mapDeadline(deadline)} handleChange={e => handleDeadline(new Date(e).toISOString().split('T')[0])} customStyle={{ width: "160px", marginLeft: "20px" }} />
      <div style={{ flexBasis: "100%" }} />
      <div style={{display: "flex"}}>
      <TextInput className="small36-input-field" isNumber isMoneySum label="Min. investment value*" initialValue={investmentMin} handleChange={handleInvestmentMin} customStyle={{ width: "300px", marginTop: "16px" }} />
      <TextInput className="small36-input-field" isNumber isMoneySum label="Max. investment value*" initialValue={investmentMax} handleChange={handleInvestmentMax} customStyle={{ width: "300px", marginTop: "16px", marginLeft: "20px"  }} />
      </div>
      <div style={{ flexBasis: "100%" }} />
      <TextInput className="small36-input-field" label="Fees" initialValue={fees} handleChange={handleFees} customStyle={{ width: "100%", marginTop: "16px" }} />
    </div>
  )
}

export default OpportunityDetails;