import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  button: {
    width: '129px',
    height: '32px',
    background: '#FFFFFF',
    border: '1px solid #EBEBEB',
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    marginTop: '8px',
    color: '#796E6E',
    "&:hover": {
      border: '1px solid rgba(67, 68, 72, 0.04)',
      backgroundColor: '#777777',
      color: "#ffffff",
    },
  },
  selectButton: {
    background: '#434448 !important',
    color: "#ffffff",
  }
})
const SmallButtonGroup = forwardRef((props, ref) => {
  const { buttonList, handleChange, buttonStyle, customSelectedClass, containerStyle, allowMultiple, defaultSelectedButtons=[] } = props;
  const classes = styles();
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(allowMultiple ? [] : -1);

  const selectButton = (button, index) => {
    if(allowMultiple) {
      if(selectedButtonIndex.indexOf(index) === -1) {
        const newSelectedButtons = [...selectedButtonIndex, index];
        setSelectedButtonIndex(newSelectedButtons);
        handleChange(buttonList.filter((x, index) => newSelectedButtons.indexOf(index) !== -1).map(x => x.value));
      } else {
        const newSelectedButtons = selectedButtonIndex.filter(x => x !== index);
        setSelectedButtonIndex(newSelectedButtons);
        handleChange(buttonList.filter((x, index) => newSelectedButtons.indexOf(index) !== -1).map(x => x.value));
      }
      return;
    }
    if (selectedButtonIndex !== index) {
      setSelectedButtonIndex(index);
      handleChange(button);
    } else {
      setSelectedButtonIndex(-1);
      handleChange(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedButtonIndex(-1);
    }
  }))
  
  const buttonIsSelected = (index) => {
    if(allowMultiple) return selectedButtonIndex.indexOf(index) !== -1;
    return selectedButtonIndex === index;
  }

  useEffect(() => {
    if(defaultSelectedButtons.length > 0 && defaultSelectedButtons !== selectedButtonIndex) {
      const selected = [];
      buttonList.forEach((button, index) => {
        if(defaultSelectedButtons.findIndex(x => x === button.value) !== -1) {
          selected.push(index);
        }
      })
      setSelectedButtonIndex(selected)
    }
  }, [defaultSelectedButtons])

  const buttons = buttonList.map((button, index) =>
    <Button variant='outlined'
      color="primary"
      style={buttonStyle}
      className={[classes.button, buttonIsSelected(index) ? [customSelectedClass, classes.selectButton].join(" ") : ''].join(' ')}
      key={index}
      onClick={selectButton.bind(this, button, index)}>
      {button.text}
    </Button>
    )

  return (
    <div className={classes.container} style={containerStyle}>
      {buttons}
    </div>
  )
})

export default SmallButtonGroup;