
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { adminStyles, adminAlignmentStyles } from '../AdminStyles';
import CompanyAnalytics from './CompanyAnalytics';
import FeedbackAnalytics from './FeedbackAnalytics';
import GeneralAnalytics from './GeneralAnalytics';
import { DefaultAnalyticsHeader } from './Headers';
import SectorAnalytics from './SectorAnalytics';

const AdminAnalyticsPanel = () => {
  const canAccess = useSelector(store => store.permissionAsignments.analytics);
  const adminClasses = adminStyles();
  const alignmentClasses = adminAlignmentStyles();
  const { url } = useRouteMatch();

  if(!canAccess) return <div style={{marginTop: "80px", textAlign: "center"}}>You do not have permission to view the analytics tab</div>

  return (
    <div style={{marginTop: "63px"}}>
      <div className={adminClasses.headerContainer}>
        <div className={adminClasses.header}>
        <DefaultAnalyticsHeader />
        </div>
      </div>
      <div className={[adminClasses.contentContainer, alignmentClasses.flexAlignCenterOncolumn].join(' ')} >
        <Switch>
          <Route path={`${url}/general`} component={() => <GeneralAnalytics />} /> 
          <Route path={`${url}/company`} component={() => <CompanyAnalytics />} /> 
          <Route path={`${url}/sectors`} component={() => <SectorAnalytics />} /> 
          <Route path={`${url}/feedback`} component={() => <FeedbackAnalytics />} /> 
          <Route path="*" component={() => <Redirect to={`${url}/general`} />} />
        </Switch>
      </div>
    </div>
  )
}

export default AdminAnalyticsPanel;