import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useInvestorPreferencesStyles } from '../../../investor-preferences/InvestorPreferencesStyle';
import { generalStyleClasses } from '../../../utils/general-styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import { Fragment } from 'react';
import Loading from '../../../shared/Loading/Loading';
import SuccessMessage from '../../../shared/SuccessMessage/SuccessMessage';
import { useSelector } from 'react-redux';
import TextInput from '../../../shared/TextInput/TextInput';
import SmallButtonGroup from '../../../shared/SmallButtonsGroup/SmallButttonsGroup';
import { getDealInvestmentFlow, GET_DEAL_COMPANY_NAME, GET_INTEREST, GET_INVESTMENT_BY_USER_AND_DEAL_ID, INSERT_INTEREST, UPDATE_INTEREST } from '../../../utils/deal-queries';
import { adminDimensionsStyle, adminTypografyStyles, adminAlignmentStyles } from '../../../admin/AdminStyles';
import { hasuraErrorHandler } from '../../../shared/EventEmitter/EventEmitter';
import { getRoundedCeilValue, getRoundedFloorValue, numberWithCommas } from '../../../utils/formats';

const customStyles = makeStyles({
  headerOverlay: {
    position: "absolute",
    top: "0px",
    zIndex: "200",
    background: "#e5e5e5",
  },
  closeIcon: {
    position: "absolute",
    right: "20px",
    top: "20px",
  }
})

const ModifyInvestment = () => {
  const classes = useInvestorPreferencesStyles();
  const genericClasses = generalStyleClasses();
  const customClasses = customStyles();
  const typografyClasses = adminTypografyStyles();
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const history = useHistory();
  const firebaseId = useSelector(store => store.account.user.firebase_id)
  const dealId = useRouteMatch().params.id;
  const predefinedAmmount = useRouteMatch().params.predefinedAmmount;
  const [amount, setAmount] = useState(predefinedAmmount);
  const [open, setOpen] = React.useState(false);

  const [buttonOptions, setButtonOptions] = useState([
    { text: "$0", value: 0 },
    { text: "$0", value: 0 },
    { text: "$0", value: 0 },
    { text: "$0", value: 0 }
  ]);
  const companyData = useQuery(GET_DEAL_COMPANY_NAME, { variables: { id: dealId, firebase_id: firebaseId } }).data;

  const handleInvestButton = () => {
      getDealInvestmentFlow(Number(dealId), amount)
        .then(res => res.json())
        .then(res => {
          setOpen(false);
          if (res.status === "OK") {
            history.push(`/app/document-handler/${dealId}/${res.result.investmentId}`)
          } else {
            hasuraErrorHandler.printError(res.result.message)
          }
        })
        .catch(console.log);
  }

  useEffect(() => {
    if(companyData) {
      const min = getRoundedCeilValue(companyData.Deal_by_pk.investment_min)
      const max = getRoundedFloorValue(companyData.Deal_by_pk.investment_max);
      const first = getRoundedCeilValue((min+max)/3);
      if(!amount) {
        setAmount(companyData.Deal_by_pk.Investments[0]?.value || 0);
      }
      const second = getRoundedFloorValue((min+max)*2/3);
      setButtonOptions([
        { text: numberWithCommas(`$${min}`), value: min },
        { text: numberWithCommas(`$${first}`), value: first },
        { text: numberWithCommas(`$${second}`), value: second },
        { text: numberWithCommas(`$${max}`), value: max },
      ])
    }
  }, [companyData])

  if (!companyData) return <Loading fullWidth />

  return (
    <div className={[classes.routerContainer, customClasses.headerOverlay].join(' ')}>
      <IconButton className={customClasses.closeIcon} onClick={() => history.goBack()}>
        <CancelIcon />
      </IconButton>
      <p className={[genericClasses.marginTop40, genericClasses.marginBottom0, genericClasses.fontAlignCenter, genericClasses.fontInputColor, genericClasses.fontSize16].join(' ')} style={{ width: '560px' }}>
          What amount would you like to invest in {companyData.Deal_by_pk.company.name}?
      </p>
      <div className={classes.investmentPreferencesContainer} style={{ padding: "32px", marginTop: "38px" }}>
        {
              <div className={alignmentClasses.flexAlignCenterOncolumn}>
                <p className={[typografyClasses.fontColor505154, typografyClasses.fontSize15, dimensionClasses.marginBottom0, dimensionClasses.marginTop0]} style={{ textAlign: "center" }}>
                  This will cancel your previous investment.
                  </p>
                <div className={genericClasses.flexAlignCenter}>
                  <span style={{ marginRight: "20px", marginTop: "4px" }} className={[genericClasses.fontSize14, genericClasses.fontInputColor].join(' ')}>Min: ${numberWithCommas(companyData.Deal_by_pk.investment_min)}</span>
                  <TextInput inputStyle={{ padding: "12px" }} customStyle={{ width: "266px" }} placeholder="e.g. $500,000" isNumber isMoneySum initialValue={amount} handleChange={value => setAmount(value)} />
                  <span style={{ marginLeft: "20px", marginTop: "4px" }} className={[genericClasses.fontSize14, genericClasses.fontInputColor].join(' ')}>Max: ${numberWithCommas(companyData.Deal_by_pk.investment_max)}</span>
                </div>
                <SmallButtonGroup containerStyle={{ width: "100%", marginTop: "12px" }} buttonStyle={{ width: "100px" }} buttonList={buttonOptions} handleChange={amount => setAmount(amount.value)} />
                <div className={[alignmentClasses.flexCenterSpaceBetween, dimensionClasses.width100Percent, dimensionClasses.marginTop40].join(' ')}>
                  <Button className={dimensionClasses.width100Percent} variant="outlined" color="primary" onClick={() => history.goBack()}>Cancel</Button>
                  <Button disabled={!amount || (companyData && companyData.Deal_by_pk.Investments[0]?.value === amount)} className={[dimensionClasses.width100Percent, dimensionClasses.marginLeft20].join(' ')} variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Modify ammount
                  </Button>
                </div>
              </div>
        }
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{style: {padding: "0px", width: "600px", marginTop: "-22%"}}}
      >
        <DialogTitle>Modify the ammount?</DialogTitle>
        <DialogContent>
          Are you sure you want to change the amount? You will have to start over the process.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" variant="outlined" style={{height: "36px"}}>
            No
          </Button>
          <Button onClick={() => handleInvestButton()} color="primary" variant="contained" style={{height: "36px"}}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ModifyInvestment;