import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { LOGOUT, SET_USER_AUTH } from "../../../store/utils/action-types";
import { useHistory, Link, NavLink, useRouteMatch } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import firebase from 'firebase';
import './navbar.scss';
import { permissionAsignmentsActions } from '../../../store/slices/permissions';

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const route = useRouteMatch();
  const [accountSubmenuOpen, setAccountSubmenuOpen] = useState(false);
  const [manageSubmenuOpen, setManageSubmenuOpen] = useState(false);
  const [manageActiveStyle, setManageActiveStyle] = useState(false);
  const accountAnchorRef = useRef(null);
  const manageAnchorRef = useRef(null);

  const logout = () => {
    firebase.auth().signOut().then(() => {
      dispatch({type: SET_USER_AUTH, payload: {jwtToken: ''}});
      dispatch({ type: LOGOUT });
      dispatch(permissionAsignmentsActions.RESET_PERMISSIONS());
    })
  }

  const handleAccountMenuToggle = () => {
    setAccountSubmenuOpen(!accountSubmenuOpen);
  };

  const handleManageMenuToggle = () => {
    setManageSubmenuOpen(!manageSubmenuOpen);
  };

  const handleAccountMenuClose = (event) => {
    if (accountAnchorRef.current && accountAnchorRef.current.contains(event.target)) {
      return;
    }
    setAccountSubmenuOpen(false);
  };

  const handleManageMenuClose = (event) => {
    if (manageAnchorRef.current && manageAnchorRef.current.contains(event.target)) {
      return;
    }
    setManageSubmenuOpen(false);
  };

  const handleMessagesButton = () => {
    history.push('/app/messages');
  }

  useEffect(() =>{
    setManageActiveStyle(window.location.href.indexOf("investments") !== -1 || window.location.href.indexOf("watchlist") !== -1)
    }, [route])

  return (
    <div className="navbar-root">
      <div className="navbar-container">
        <Link to="/app">
          <img src={`${process.env.PUBLIC_URL}/images/logos/${localStorage.getItem("companyLogo")}`} alt="company logo" />
        </Link>
        <div className="navbar-controls">
          <NavLink color="primary" className="nav-button" activeClassName={!manageSubmenuOpen ? "selected" : ""} to="/app/opportunities">Opportunities</NavLink>
          <Button className={["dropdown-button", manageActiveStyle || manageSubmenuOpen ? "selected" : ""].join(' ')} disableElevation ref={manageAnchorRef} onClick={() => handleManageMenuToggle()}>Manage</Button>
          <NavLink color="primary" className="nav-button" activeClassName="selected" to="/app/preferences">Preferences</NavLink>
        </div>
        <div className="navbar-account-icons">
          <Button className={window.location.href.indexOf("messages") !== -1 ? 'bordered' : ''} onClick={() => handleMessagesButton()}>
            <img src={`${process.env.PUBLIC_URL}/images/chat.svg`} alt="chat" />
          </Button>
          <Button>
            <img src={`${process.env.PUBLIC_URL}/images/notifications-icon.svg`} alt="account" />
          </Button>
          <Button ref={accountAnchorRef} aria-controls={accountSubmenuOpen ? 'menu-list-grow' : undefined} className={accountSubmenuOpen === true || window.location.href.indexOf("account") !== -1 || window.location.href.indexOf("documents") !== -1 ? 'bordered' : ''} aria-haspopup="true" onClick={handleAccountMenuToggle}>
            <img src={`${process.env.PUBLIC_URL}/images/account-icon.svg`} alt="account" />
          </Button>

          <Popper open={manageSubmenuOpen} anchorEl={manageAnchorRef.current} transition placement="bottom-end"  style={{zIndex: 10000}}>
            <Paper>
              <ClickAwayListener onClickAway={handleManageMenuClose}>
                <MenuList autoFocusItem={manageSubmenuOpen} id="menu-list-grow">
                  <MenuItem onClick={handleManageMenuClose} component={Link} to="/app/investments">Investments</MenuItem>
                  <MenuItem onClick={handleManageMenuClose} component={Link} to="/app/watchlist">Watchlist</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>

          <Popper open={accountSubmenuOpen} anchorEl={accountAnchorRef.current} transition placement="bottom-end"  style={{zIndex: 10000}}>
            <Paper>
              <ClickAwayListener onClickAway={handleAccountMenuClose}>
                <MenuList autoFocusItem={accountSubmenuOpen} id="menu-list-grow">
                  <MenuItem onClick={handleAccountMenuClose} component={Link} to="/app/account">Account & Settings</MenuItem>
                  <MenuItem onClick={handleAccountMenuClose} component={Link} to="/app/documents">Documents</MenuItem>
                  <MenuItem onClick={() => logout()}>Sign out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </div>
      </div>
    </div>
  )
}

export default Navbar;