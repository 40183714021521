import React, { Fragment, useEffect, useState } from 'react';
import TextInput from '../../shared/TextInput/TextInput';
import Button from '@material-ui/core/Button';
import { generalStyleClasses } from '../../utils/general-styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SET_ACCOUNT_PERSONAL_INFOS } from "../../store/utils/action-types";
import { GET_ASSIGNED_ONBOARDING_OR_DEFAULT, GET_NAME_SURNAME_FROM_WHITELIST, SET_ACCOUNT_DETAILS } from '../../utils/account-queries';
import { useMutation, useQuery } from "@apollo/client";
import { COUNTRIES } from '../../utils/enum-queries';
import { MenuItem, Select } from '@material-ui/core';
import { cloneOnboardingFlowForInvestor } from '../../admin/queries/admin-http-calls';

//TODO: fix phone number format
const PersonalInformation = ({ isForgot }) => {
    const store = useSelector(store => store);
    const history = useHistory();
    const dispatch = useDispatch();
    const [updatePersonalInformation] = useMutation(SET_ACCOUNT_DETAILS);
    const account = useSelector(store => store.account.user);
    const firebaseId = useSelector(store => store.registration.registrationUserId || store.account.user.firebase_id)
    const accountEmail = useSelector(store => store.account && store.account.user && store.account.user.email || "")
    const { data, loading } = useQuery(GET_NAME_SURNAME_FROM_WHITELIST, { variables: { email: accountEmail } });
    const {data: assignedOrDefaultFlow} = useQuery(GET_ASSIGNED_ONBOARDING_OR_DEFAULT, {variables: {firebase_id: account?.firebase_id || firebaseId}});
    const initialData = useSelector(store => store.account.user);
    const countriesData = useQuery(COUNTRIES).data;
    const classes = generalStyleClasses();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [valid, setValid] = useState(false);
    const [phoneCountry, setPhoneCountry] = useState('US');

    useEffect(() => {
        setValid(firstName !== '' && lastName !== '' && phoneNumber !== '' && country !== '' && city !== '' && (account.app_client || assignedOrDefaultFlow));
    }, [firstName, lastName, phoneNumber, country, city, assignedOrDefaultFlow]);

    const handleContinueButton = async () => {
        if ((firebaseId) && assignedOrDefaultFlow) {
            const updatePersonalInfos = await updatePersonalInformation({
                variables: {
                    "userId": firebaseId,
                    "city": city,
                    "country": country,
                    "first_name": firstName,
                    "last_name": lastName,
                    "phone_number": phoneNumber.toString()
                }
            });
            if(!assignedOrDefaultFlow.Onboarding_flow_section_edge[0]?.Flow_node.id) {
                history.push("/onboarding/signup-successful");
            } else {
                const cloneResult = await cloneOnboardingFlowForInvestor(assignedOrDefaultFlow.Onboarding_flow_section_edge[0]?.Flow_node.id, firebaseId);
                dispatch({ type: SET_ACCOUNT_PERSONAL_INFOS, payload: updatePersonalInfos.data.update_Account_by_pk });
                history.push('/custom-onboarding');
            }
        
        }
    }

    useEffect(() => {
        if (data) {
            if (data.Whitelist && data.Whitelist.length > 0) {
                setFirstName(data.Whitelist[0].first_name);
                setLastName(data.Whitelist[0].last_name);
            }
            if (data.Account && data.Account.length > 0) {
                setCity(data.Account[0].city);
                setCountry(data.Account[0].country);
                setPhoneCountry(data.Account[0].phone_zone)
                setPhoneNumber(Number(data.Account[0].phone_number))
            }
        }
    }, [data, loading])

    useEffect(() => {
        if (initialData && initialData.first_time_viewed && !isForgot) {
            history.push('/')
        }
    }, [])

    return (
        <Fragment>
            <h3 className={[classes.marginTop0, classes.marginBottom0, classes.fontColorDark, classes.fontSize20].join(' ')}>Personal information</h3>
            <div className={classes.marginTop40}>
                <TextInput label="First Name" handleChange={value => setFirstName(value)} initialValue={firstName} />
            </div>
            <div className={classes.marginTop24}>
                <TextInput label="Last Name" handleChange={value => setLastName(value)} initialValue={lastName} />
            </div>
            <div className={[classes.marginTop24, classes.flexWrap].join(' ')}>
                <p className={[classes.marginBottom8, classes.marginTop0, classes.fontColorLight, classes.fontSize14].join(' ')} style={{ width: "100%" }}>Phone Number</p>
                <Select
                    variant="outlined"
                    className={classes.simpleDropdown}
                    value={phoneCountry}
                    style={{ background: "white", height: "36px", width: "60px", marginRight: "8px" }}
                    onChange={e => setPhoneCountry(e.target.value)}
                    MenuProps={{ variant: "menu", disableScrollLock: true }}>
                    {countriesData && countriesData.ENUM_countries ? countriesData.ENUM_countries.map((x, index) => (<MenuItem key={index} value={x.value}>{x.value}</MenuItem>)) : []}
                </Select>
                <TextInput isNumber handleChange={value => setPhoneNumber(value)} initialValue={phoneNumber} customStyle={{ flexGrow: "1", marginTop: "-8px", width: "unset" }} />


            </div>
            <div className={classes.marginTop24}>
                <p className={[classes.marginBottom8, classes.marginTop0, classes.fontColorLight, classes.fontSize14].join(' ')}>Country</p>
                <Select
                    variant="outlined"
                    className={classes.simpleDropdown}
                    style={{ width: "100%", background: "white", height: "36px" }}
                    value={country}
                    onChange={e => setCountry(e.target.value)}
                    MenuProps={{ variant: "menu", disableScrollLock: true }}>
                    {countriesData && countriesData.ENUM_countries ? countriesData.ENUM_countries.map((x, index) => (<MenuItem key={index} value={x.description}>{x.description}</MenuItem>)) : []}
                </Select>
            </div>
            <div className={classes.marginTop24}>
                <TextInput label="City" handleChange={value => setCity(value)} initialValue={city} />
            </div>
            <Button className={[classes.singleRightButton, classes.marginTop32].join(' ')} variant="contained" color="primary" disabled={!valid} onClick={() => handleContinueButton()}> Continue </Button>
        </Fragment>
    )
}

export default PersonalInformation;