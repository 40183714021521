import React, { Fragment } from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useQuery } from '@apollo/client';
import { Button  } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { GET_INVESTOR_DETAILS } from '../../queries/admin-queries';
import { adminDimensionsStyle, adminTypografyStyles } from '../../AdminStyles';

const SingleClientHeader = () => {
  const params = useParams();
  const typografyClasses = adminTypografyStyles();
  const dimenstionClasses = adminDimensionsStyle();
  const { data } = useQuery(GET_INVESTOR_DETAILS, {variables: { firebase_id: params.id }, fetchPolicy: "network-only"});

  return (
    <div>
      <Button component={Link} to="/admin/clients/all">
        <KeyboardBackspaceIcon />
        Back
      </Button>
      {
        data && data.Account_by_pk 
        ? (
            <Fragment>
              <span className={[typografyClasses.fontSize14, typografyClasses.fontbold, typografyClasses.fontColor5B5B5B, dimenstionClasses.marginLeft40].join(' ')}>{`${data.Account_by_pk.first_name}  ${data.Account_by_pk.last_name}`}</span>
              <span className={[typografyClasses.fontSize14, typografyClasses.fontColor5B5B5B, dimenstionClasses.marginLeft32].join(' ')}>{data.Account_by_pk.email}</span>
              <span className={[typografyClasses.fontSize14, typografyClasses.fontColor5B5B5B, dimenstionClasses.marginLeft32].join(' ')}>{new Date(data.Account_by_pk.created_at).toLocaleDateString()}</span>
            </Fragment>
          )
          : ""
      }

    </div>
  )
}

export default SingleClientHeader;