import { filterBuilder, templateFilter } from "./filterBuilder";

export class Filters {
  static filters = templateFilter();
  static page = 0;
  static dealsPerPage = 50;

  static setIndustryTypes(industryTypes) {
    this.filters.sectors = industryTypes
  }

  static setInovationEnablers(inovationEnablers) {
    this.filters.innovation_enablers = inovationEnablers
  }

  static setLastValuation(lastValuation) {
    Filters.resetLastValuation()
    if (lastValuation !== 0) {
      this.filters.last_valuation[lastValuation] = true;
    }
  }

  static setCompanyAge(companyAge) {
    Filters.resetCompanyAge()
    if (companyAge !== 0) {
      this.filters.company_age[companyAge] = true;
    }
  }

  static setFundingStage(fundingStage) {
    if (fundingStage !== "_") {
      this.filters.funding_stage = fundingStage;
    }
  }

  static resetFilters() {
    this.filters = {
      ...templateFilter(),
      order_by: this.filters.order_by,
      text: this.filters.text
    }
  }

  static setSorting(sortingField, sortingDirection) {
    this.filters.order_by.recent_asc = false;
    this.filters.order_by.recent_desc = false;
    this.filters.order_by.ending_asc = false;
    this.filters.order_by.ending_desc = false;
    this.filters.order_by.name_asc = false;
    this.filters.order_by.name_desc = false;
    this.filters.order_by.valuation_asc = false;
    this.filters.order_by.valuation_desc = false;
    switch (sortingField) {
      case "RECENT": {
          this.filters.order_by.recent_asc = sortingDirection;
          this.filters.order_by.recent_desc = !sortingDirection;
          break;
      }
      case "DEADLINE": {
        this.filters.order_by.ending_asc = sortingDirection;
        this.filters.order_by.ending_desc = !sortingDirection;
        break;
      }
      case "NAME": {
        this.filters.order_by.name_asc = sortingDirection;
        this.filters.order_by.name_desc = !sortingDirection;
        break;
      }
      case "VALUATION": {
        this.filters.order_by.valuation_asc = sortingDirection;
        this.filters.order_by.valuation_desc = !sortingDirection;
        break;
      }
      case "OFFERING": {
          this.filters.order_by.offering_asc = sortingDirection;
          this.filters.order_by.offering_desc = !sortingDirection;
          break;
      }
      default:
        break;
    }
  }

  static setSeatchText(text) {
    this.filters.text = text;
  }

  static setDealType(dealType) {
    this.filters.status = dealType
  }

  static resetLastValuation() {
    this.filters.valuation[1] = false;
    this.filters.valuation[2] = false;
    this.filters.valuation[3] = false;
    this.filters.valuation[4] = false;
  }

  static resetCompanyAge() {
    this.filters.company_age[1] = false;
    this.filters.company_age[2] = false;
    this.filters.company_age[3] = false;
    this.filters.company_age[4] = false;
  }

  static getContent() {
    return filterBuilder(Filters.filters, Filters.filters.order_by, Filters.dealsPerPage, Filters.page * Filters.dealsPerPage).slice(1, -1);
  }

  static getContentWithoutOffset() {
    return filterBuilder(Filters.filters, Filters.filters.order_by, null, null).slice(1, -1);
  }
}