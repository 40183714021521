export const hasUpperCase = (str) => {
    return /[A-Z]/.test(str);
}

export const hasLowerCase = (str) => {
    return /[a-z]/.test(str);
}

export const hasNumber = (str) => {
    return /\d/.test(str);
}

export const emailValidator = (email) => {
    return email && !!email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export const passwordValidator = (password) => {
    return password.length >= 8 && hasUpperCase(password) && hasNumber(password) && hasLowerCase(password); 
}

export const isNumber = (number) => {
    return /^\d+$/.test(number);
}

export const isPhone = (number) => {
    return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(number);
}

export const isAlpha = (text) => {
    return /^[a-zA-Z ]+$/.test(text);
}