import { IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { generalStyleClasses } from '../../../utils/general-styles';
import CloseIcon from '@material-ui/icons/Close';
import MessagesContainer from './MessagesContainer';
import MessagesPeopleSelector from './MessagesPeopleSelector';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_NAME } from '../../../utils/deal-queries';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import GeneralPeopleSelector from './GeneralPeopleSelector';

const styles = makeStyles({
  container: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    flexGrow: "1",
    height: "calc(100vh - 170px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    padding: "20px 16px",
    background: "#f9f9f9",
  },
  messageBox: {
    padding: "12px 15px",
    width: "fit-content",
    borderRadius: "4px",
    margin: "0",
  },
  messageContainer: {
    display: "flex",
    marginRight: "0px",
    marginLeft: "20px",
  },
  minimalMessageContainer: {
    display: "flex",
    marginRight: "80px",
    marginLeft: "4px",
  },
  messageOnRight: {
    justifyContent: "flex-end",
    marginRight: "20px",
    marginLeft: "0px",
    background: "none"
  },
  minimalRightMessage: {
    marginLeft: "80px",
    marginRight: "4px",
    justifyContent: "flex-end",
  },
  documentsMessage: {
    padding: "6px",
    color: "#1F3F70",
  },
  atachmentIcon: {
    transform: "rotate(135deg)",
  },
  messageStartWarning: {
    width: "100%",
    color: "#B67234",
  },
  messageInput: {
    width: "100%",
    boxSizing: "border-box",
    padding: "8px 14px",
    borderTop: "1px solid rgb(231, 231, 231)",
  },
  smallbutton: {
    height: "40px",
    width: "42px",
    minWidth: "42px",
  },
  minimalNoAdminTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: "0px",
  },
  closeButtton: {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  messagesContainer: {
    overflow: "auto",
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },
    " &::-webkit-scrollbar-thumb": {
      background: "#666",
    }
  }
})


const ChatContainer = ({isGeneral = false, isMinimal = false, defaultAdmin = null, handleClose = () => null, dealId = null }) => {
  const classes = styles();
  const generalClasses = generalStyleClasses();
  const [admin, setAdmin] = useState(defaultAdmin);
  const { data: dealCompanyName } = useQuery(GET_COMPANY_NAME, {variables: {id: admin?.dealId || 0}});
  
  useEffect(() => {
    setAdmin(defaultAdmin)
  }, [defaultAdmin])
  
  return (
    <div className={classes.container} style={{ height: isMinimal && "100%", alignItems: isMinimal && "flex-end" }}>
      {
        <p className={[generalClasses.fontInputColor, generalClasses.fontSize15, classes.minimalNoAdminTitle, generalClasses.marginTop16].join(' ')}>
          {
            admin
              ? dealCompanyName && dealCompanyName.Deal_by_pk && `${admin.firstName} ${admin.lastName} - ${dealCompanyName.Deal_by_pk?.company.name} (${dealCompanyName.Deal_by_pk?.status})`
            : "How can we help you?"
          }
          {isMinimal && <IconButton className={classes.closeButtton} onClick={() => handleClose()}><CloseIcon /></IconButton>}
        </p>
      }
      {
        admin
          ? <MessagesContainer isMinimal={isMinimal} admin={admin} />
          : (dealId && (
            isGeneral 
            ? <GeneralPeopleSelector dealId={dealId} setAdmin={admin => setAdmin(admin)} />
            : <MessagesPeopleSelector dealId={dealId} setAdmin={admin => setAdmin(admin)} />
            )
          )
      }

    </div>
  )
}

export default ChatContainer;