import { Button, Switch, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { Fragment, useEffect, useState } from 'react';
import { GET_ALL_ACCOUNTS_FROM_WHITELISTED_WITH_ROLE,  GET_ONBOARDING_FLOW_EDGES } from '../../queries/admin-queries';
import {  useMutation, useQuery } from '@apollo/client';
import { changeAccountStatus, cloneOnboardingFlowForInvestor, deleteOnboardingEdges, sendInvitationEmail } from '../../queries/admin-http-calls';
import { filterByName } from './tableUtils';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles, adminInputStyles } from '../../AdminStyles';
import { EventThrower, Events, hasuraErrorHandler } from '../../../shared/EventEmitter/EventEmitter';
import OrderButtons from '../../../shared/OrderButtons/OrderButtons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


const customStyle = makeStyles({
  customSwitch: {
    "& .Mui-checked": {
      color: "#0258A8 !important",
    },
  },
  track: {
    "& .MuiSwitch-track": {
      backgroundColor: "#0258A8 !important",
    }
  }
});

const mapResponseToEdges = (response) => {
  const edges = {
    sections: response.map(x => x.id),
    items: [],
  }

  response.forEach(edge => {
    edges.items.push(...edge.Onboarding_section_item_edge.map(x => x.id));
  })

  return edges;
}

const InterestedClientsTable = ({ nameFilter = "" }) => {
  const customClasses = customStyle();
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const tableClasses = adminTablesStyle();
  const typografyClasses = adminTypografyStyles();
  const canAccess = useSelector(store => store.permissionAsignments.manage_client);
  const [emailOrder, setEmailOrder] = useState(null);
  const [createdOrder, setCreatedOrder] = useState(null);
  const [nameOrder, setNameOrder] = useState(null);
  const [getOnboardingEdges] = useMutation(GET_ONBOARDING_FLOW_EDGES);
  const [interestedUsers, setInterestedUsers] = useState([]);
  //swap with roleid 1 to easily edit onbording flow, 2 is the default on
  const data = useQuery(GET_ALL_ACCOUNTS_FROM_WHITELISTED_WITH_ROLE, { variables: { roleId: "2", name: nameOrder, email: emailOrder, created_at: createdOrder }, fetchPolicy: "network-only" }).data;
  const [deletedEmails, setDeletedEmails] = useState([])

  const handleSelectOnboardingFlowForClient = async (firebaseId, flowId) => {
    try {
      const currentEdges = await getOnboardingEdges({ variables: { firebase_id: firebaseId } })
      const mappedEdges = mapResponseToEdges(currentEdges.data.update_Onboarding_flow_section_edge.returning);
      await deleteOnboardingEdges(mappedEdges.sections, mappedEdges.items);
      await cloneOnboardingFlowForInvestor(flowId, firebaseId)
      setInterestedUsers(interestedUsers.map(user => {
        if (user.firebaseId === firebaseId) {
          user.selectedFlow = flowId
        }
        return user;
      }))
      EventThrower.throwClientSideMessage(Events.SuccessMessage("Onboarding flow changed"))
    }
    catch (error) {
      console.log(error);
    }
  }

  const sendEmail = (account) => {
    sendInvitationEmail(account.email, account.firstName, account.lastName)
      .then(res => res.json())
      .then(body => {
        if (body.status === 'OK') {
          setDeletedEmails([...deletedEmails, account.email]);
          EventThrower.throwClientSideMessage(Events.EmailSentSuccessfully)
        }
      })
      .catch(() => hasuraErrorHandler.printError(`Error sending email to ${account.email}`))
  }

  const handleChangeAccountStatus = (account) => {
    changeAccountStatus(account.firebaseId)
      .then(res => res.json())
      .then(body => {
        if (body.status === 'OK') {
          setInterestedUsers(interestedUsers.map(x => {
            if(x.firebaseId === account.firebaseId) {
              x.disabled = !x.disabled;
            }
            return x;
          }))
          if(account.disabled) {
            EventThrower.throwClientSideMessage(Events.SuccessMessage("User disabled"))
          } else {
            EventThrower.throwClientSideMessage(Events.SuccessMessage("User enabled"))
          }
          
        } else {
          if(body.result.message) {
            hasuraErrorHandler.printError(body.result.message);
          }
        }
      })
      .catch(() => hasuraErrorHandler.printError(`Error changing account status`))
  }

  useEffect(() => {
    if (data) {
      hasuraErrorHandler.checkResponseForErrors(data);
      setInterestedUsers(data.Whitelist.map(user => ({
        firstName: user.account.first_name,
        lastName: user.account.last_name,
        email: user.email,
        created: user.created_at,
        firebaseId: user.account.firebase_id,
        selectedFlow: user.account.Onboarding_flow_section_edges[0]?.flow_id || 0,
        disabled: user.account.disabled > 0,
      })))
    }
  }, [data])

  const tableData = interestedUsers.filter(x => deletedEmails.indexOf(x.email) === -1 && filterByName(x, nameFilter)).map((account, index) => (
    <div className={[
      dimensionClasses.containerWidth,
      alignmentClasses.flexAlignCenter,
      tableClasses.row
    ].join(' ')} key={index}>
      <p className={dimensionClasses.width200}>
        {
          account.firstName && account.lastName
            ? `${account.firstName} ${account.lastName}`
            : "Name not provided"
        }
      </p>
      <p className={dimensionClasses.width250}>{account.email}</p>
      <p className={dimensionClasses.width150}>{new Date(account.created).toLocaleDateString()}</p>
      <div className={[dimensionClasses.widthGrow, alignmentClasses.flex].join(' ')}>
        <Button component={Link} disabled={!account.selectedFlow} style={{ color: !account.selectedFlow ? "#7b8ea5" : "" }} className={[typografyClasses.fontColor0258A8, typografyClasses.noTrasnform].join(' ')} to={`/admin/clients/onboarding/edit/${account.selectedFlow}/${account.firebaseId}`}>
          View Onboarding
        </Button>
        <Button className={[typografyClasses.fontColor0258A8, typografyClasses.noTrasnform].join(' ')} onClick={() => sendEmail(account)}>Approve account</Button>
      </div>
      <div className={dimensionClasses.width100}>
      <Switch
        className={[!account.disabled ? customClasses.customSwitch : "", !account.disabled ? customClasses.track : ""].join(' ')}
        onChange={() => handleChangeAccountStatus(account)}
        checked={!account.disabled}
        color="primary"
        name="checkedB"
        style={{"&.Mui-checked": {color: "red"}}}
/>
      </div>
      </div>
  ))

  
  if(!canAccess) return <div style={{marginTop: "80px", textAlign: "center"}}>You do not have permission to view the interested tab</div>

  return (
    <div className={dimensionClasses.marginTop24}>
      <div className={[
        dimensionClasses.containerWidth,
        alignmentClasses.flexAlignCenter,
        tableClasses.tableHeader,
        dimensionClasses.marginTop16,
        dimensionClasses.paddingBottom16,
        typografyClasses.fontSize14,
        dimensionClasses.headerMarginFix
      ].join(' ')}>
        <div className={[dimensionClasses.width200, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Name
          <OrderButtons value={nameOrder} orderFunctions={[setCreatedOrder, setEmailOrder, setNameOrder]} specificFunction={value => setNameOrder(value)} />
        </div>
        <div className={[dimensionClasses.width250, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Email
          <OrderButtons value={emailOrder} orderFunctions={[setCreatedOrder, setEmailOrder, setNameOrder]} specificFunction={value => setEmailOrder(value)} />
        </div>
        <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Account created
          <OrderButtons value={createdOrder} orderFunctions={[setCreatedOrder, setEmailOrder, setNameOrder]} specificFunction={value => setCreatedOrder(value)} />
        </div>
        <div className={[dimensionClasses.widthGrow, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Action
        </div>
        <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Enabled
        </div>
      </div>
      <div>
      {tableData.length === 0 && "You do not have any clients to manage"}
        {tableData}
      </div>
    </div>
  )
}

export default InterestedClientsTable;