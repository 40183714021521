import { useQuery } from '@apollo/client';
import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { GET_ALL_INVESTMENTS } from '../../../utils/deal-queries';
import { generalStyleClasses } from '../../../utils/general-styles';
import { GET_ONBOARDING_FLOW_EDGES_IDS } from "../../../utils/account-queries";
import { opportunitiesPageStyles } from '../../../pages/MainApp/MainPages/OpportunitiesPage/OpportunitiesPageStyle';


const sectionStyles = makeStyles({
  container: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    padding: "24px",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "19px",
    lineHeight: "22px",
    color: "#505154",
    marginBottom: "8px",
    marginTop: "0px",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#796E6E",
    marginBottom: "0px",
    marginTop: "0px",
  }
})
const DocumentsSection = ({ title, subTitle, link }) => {
  const classes = sectionStyles();
  return (
    <div className={classes.container}>
      <div>
        <p className={classes.title}>{title}</p>
        <p className={classes.subtitle}>{subTitle}</p>
      </div>
      <Button variant="outlined" color="primary" component={Link} to={link}>Access documents</Button>
    </div>
  )
}

const ClientDocuments = () => {
  const generalClasses = generalStyleClasses();
  const classes = opportunitiesPageStyles();
  const firebaseId = useRouteMatch().params.id;
  const {data: hasOnboardingData} = useQuery(GET_ONBOARDING_FLOW_EDGES_IDS , {variables: {firebase_id: firebaseId}})
  const { data } = useQuery(GET_ALL_INVESTMENTS, { variables: { firebase_id: firebaseId }, fetchPolicy: "network-only" });

  return (
    <div className={[generalClasses.fullViewWidth, generalClasses.flexAlignCenterOnColumn].join(' ')}>
      <div className={classes.contentContainer}>
        <div className={classes.content} style={{marginLeft: "0px"}}>
          {
            hasOnboardingData && hasOnboardingData.Onboarding_flow_section_edge.length > 0 && 
            <DocumentsSection 
            link={`/admin/clients/onboarding/edit/${hasOnboardingData.Onboarding_flow_section_edge[0].flow_id}/${firebaseId}`}
            subTitle="Descriptions of what documents are here..." 
            title="Onboarding documents" />
          }
          {
            data && data.Account_by_pk && data.Account_by_pk.Investments.filter(x => x.Investment_flow_section_edges.length).map((x, index) =>
              <DocumentsSection key={index}
                link={`/admin/investment/client-documents/${x.deal_id}/${x.Investment_flow_section_edges[0].flow_id}/${x.id}`}
                subTitle={`${x.completed ? 'Finalized:' : 'Last update:'}  ${new Date(x.updated_at).toDateString()}`}
                title={`${x.deal.company.name} ${x.deal.title ? '-' : ''} ${x.deal.title}`} />)
          }

        </div>
      </div>
    </div>

  )
}

export default ClientDocuments;