import React from 'react';
import { useDispatch } from 'react-redux';
import UploadComponent, { fileTypeOptions } from '../../../../shared/DocumentHandler/UploadComponent';
import { adminOpportunityActions } from '../../../../store/slices/adminOpportunity';

const FilesSection =  ({section}) => {
  const files = section.data?.content || [];
  const dispatch = useDispatch();

  const handleValueChange = (content) => {
    dispatch(adminOpportunityActions.UPDATE_SECTION_CONTENT_DIFFERENCE({
      id: section.id,
      content
    }))
  }

  const handleDeleteFile = (fileId) => {
    dispatch(adminOpportunityActions.DELETE_FILE({
      fileId: fileId,
      route: "documents"
    }))
  }

  return (
    <div>
      <UploadComponent acceptedFileType={fileTypeOptions.PDF} deleteCallback={fileId => handleDeleteFile(fileId)} initialFiles={files} setNewFiles={value => handleValueChange(value)} customStyle={{marginBottom: "20px", marginLeft: "20px", marginRight: "20px"}}/>
    </div>
  )
}

export default FilesSection;

