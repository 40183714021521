import { combineReducers, createStore } from "redux";
import account from './slices/account';
import registration from './slices/registration';
import opportunitiesSort from './slices/opportunitiesSort';
import investorProfile from './slices/investor-profile';
import adminAddInvestorFlowState from './slices/admin-add-investment-flow';
import dealFilter from './slices/deal-filter';
import {timingReducer} from './slices/timer';
import { adminOpportunityReducer } from "./slices/adminOpportunity";
import { adminOnboardingFlowReducer } from './slices/adminOnboardingFlow';
import { permissionAsignmentseReducer } from './slices/permissions';
import { utilStoreReducer } from './slices/utils';
import { adminCreateAccountReducer } from './slices/rmCreatedAccount';

const combineReducer = combineReducers({
  dealFilter,
  adminAddInvestorFlowState,
  investorProfile,
  account,
  registration,
  opportunitiesSort,
  timing: timingReducer,
  adminOpportunity: adminOpportunityReducer,
  adminOnboardingFlow: adminOnboardingFlowReducer,
  permissionAsignments: permissionAsignmentseReducer,
  utils: utilStoreReducer,
  adminCreateAccount: adminCreateAccountReducer,
});

const store =  createStore(combineReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({trace: true}));
export default store;