import React, { Fragment, useEffect, useState } from 'react';
import { fileDownloader } from '../../../../../utils/file-queries';
import { generalStyleClasses } from '../../../../../utils/general-styles';

const ImageSection = ({ title, image }) => {
  const generalClasses = generalStyleClasses();
  const [imageUrl, setImageUrl] = useState("")

  useEffect(() => {
    if(image) {
      if(image instanceof File) {
        setImageUrl(URL.createObjectURL(image))
      } else {
        fileDownloader(`deal/section/image/${image.id}`).then(response => setImageUrl(URL.createObjectURL(response)));
      }
    }
  }, [image])

  return (
    <Fragment>
      <p className={[generalClasses.marginTop64, generalClasses.fontSize18, generalClasses.fontColorDark].join(' ')}>{title}</p>
      <img src={imageUrl || `${process.env.PUBLIC_URL}/images/no-image.png`} alt="image section" style={{width: "100%"}}/>
    </Fragment>
  )
}

export default ImageSection;