import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { OutlinedInput, InputAdornment, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { adminStyles, adminAlignmentStyles, adminDimensionsStyle, adminTypografyStyles } from '../../AdminStyles';

const DefaultHeader = ({ changeFilter, openCreateAccountDialog =() => null}) => {
  const adminClasses = adminStyles();
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const typografyStyles = adminTypografyStyles();
  const { url } = useRouteMatch();
  const [nameFilter, setNameFilter] = useState("");

  const handleChangeFilter = (newValue) => {
    if (changeFilter) {
      changeFilter(newValue);
    }
    setNameFilter(newValue);
  };

  return (
    <Fragment>
      <div className={alignmentClasses.flex}>
        <NavLink className={[
          adminClasses.navLinkButton,
          dimensionClasses.smallHeigth,
          alignmentClasses.flexAlignCenter,
          typografyStyles.fontSize14,
          typografyStyles.fontColor7B7B7B].join(' ')} activeClassName={adminClasses.navLinkActiveButton} to={`${url}/all`}>List</NavLink>
        <NavLink className={[
          adminClasses.navLinkButton,
          dimensionClasses.smallHeigth,
          alignmentClasses.flexAlignCenter,
          typografyStyles.fontSize14,
          typografyStyles.fontColor7B7B7B].join(' ')} activeClassName={adminClasses.navLinkActiveButton} to={`${url}/whitelist`}>Whitelist</NavLink>
        <NavLink className={[
          adminClasses.navLinkButton,
          dimensionClasses.smallHeigth,
          alignmentClasses.flexAlignCenter,
          typografyStyles.fontSize14,
          typografyStyles.fontColor7B7B7B].join(' ')} activeClassName={adminClasses.navLinkActiveButton} to={`${url}/interested`}>Interested</NavLink>
        <NavLink className={[
          adminClasses.navLinkButton,
          dimensionClasses.smallHeigth,
          alignmentClasses.flexAlignCenter,
          typografyStyles.fontSize14,
          typografyStyles.fontColor7B7B7B].join(' ')} activeClassName={adminClasses.navLinkActiveButton} to={`${url}/onboarding`}>Onboarding</NavLink>
        <Button className={[
          adminClasses.navLinkButton,
          dimensionClasses.smallHeigth,
          alignmentClasses.flexAlignCenter,
          typografyStyles.fontSize14,
          typografyStyles.fontColor7B7B7B].join(' ')} onClick={() => openCreateAccountDialog()}>Create account</Button>
      </div>
      <OutlinedInput
        placeholder="Search..."
        variant="outlined"
        className={adminClasses.searchField}
        value={nameFilter}
        onChange={e => handleChangeFilter(e.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </Fragment>

  )
}

export default DefaultHeader;