import { useQuery } from '@apollo/client';
import { Tooltip } from '@material-ui/core';
import React, { createRef, Fragment, useEffect, useRef, useState } from 'react';
import OrderButtons from '../../shared/OrderButtons/OrderButtons';
import { GET_DEAL_ANALYTICS_GENERAL_INFO } from '../../utils/analytics-queries';
import { formatMiliseconds, formatValue, numberWithCommas } from '../../utils/formats';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from '../AdminStyles';


const DealsTable = ({startTime, endTime}) => {
  const dimensionClasses = adminDimensionsStyle();
  const tableClasses = adminTablesStyle();
  const typografyClasses = adminTypografyStyles();
  const alignmentClasses = adminAlignmentStyles();
  const [opportunityOrder, setOpportunityOrder] = useState("desc_nulls_last");
  const [statusOrder, setStatusOrder] = useState(null);
  const [deadlineOrder, setDeadlineOrder] = useState(null);
  const [viewsOrder, setViewsOrder] = useState(null);
  const [interestedOrder, setInterestedOrder] = useState(null);
  const [notInterestedOrder, setNotInterestedOrder] = useState(null);
  const [sessionTimeOrder, setSessionTimeOrder] = useState(null);
  const [declaredOrder, setDeclaredOrder] = useState(null);
  const [availableOrder, setAvailableOrder] = useState(null);
  const [commitedOrder, setCommitedOrder] = useState(null);
  const [completedOrder, setCompletedOrder] = useState(null);
  const [messagesOrder, setMessagesOrder] = useState(null);
  const rowRefs = useRef([]);

  const { data } = useQuery(GET_DEAL_ANALYTICS_GENERAL_INFO, {
    variables: {
      time1: startTime.toISOString(),
      time3: startTime.toISOString(),
      time2: endTime.toISOString(),
      time4: endTime.toISOString(),
      viewMoreOrder: viewsOrder,
      averageSessionOrder: sessionTimeOrder,
      titleOrder: opportunityOrder,
      statusOrder: statusOrder,
      investmentDeadlineOrder: deadlineOrder,
      interestedClickOrder: interestedOrder,
      investmentAmountAvailableOrder: availableOrder,
      declaredOrder: declaredOrder,
      interestFeedbackOrder: notInterestedOrder,
      messageOrder: messagesOrder,
      completedOrder: completedOrder,
      commitedOrder: commitedOrder
    }
  })

  const sortables = [setOpportunityOrder, setCompletedOrder, setMessagesOrder, setCommitedOrder, setStatusOrder, setDeadlineOrder, setViewsOrder, setInterestedOrder, setNotInterestedOrder, setSessionTimeOrder, setDeclaredOrder, setAvailableOrder];

  return (
    <Fragment>
      <div className={[dimensionClasses.containerWidth].join(' ')} style={{ display: "flex", maxWidth: "none" }}>
      <div >
          <div className={[tableClasses.tableHeader, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14, dimensionClasses.marginTop24].join(' ')}>
          <Tooltip arrow placement="top" title="Name of the deal, if any">
            <div className={[dimensionClasses.width250, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Opportunity
          <OrderButtons value={opportunityOrder} orderFunctions={sortables} specificFunction={value => setOpportunityOrder(value)} />
            </div>
          </Tooltip>
          </div>
          {
            data && data.Deal
            && data.Deal.map((x, index) => (
              <div ref={el => rowRefs.current.push(el)} key={index} className={[tableClasses.row, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14].join(' ')} style={{ width: "max-content", minHeight: "40px" }}>
                <p className={[dimensionClasses.width250, alignmentClasses.flexCenterSpaceBetween].join(' ')} style={{whiteSpace: "pre", textOverflow: "ellipsis", overflow: "hidden"}}>
                  {x.company.name} {x.title && '-'} {x.title}
                </p>
                </div>
                ))
          }
        </div>
        <div style={{ overflow: "auto" }}>
        <div className={[tableClasses.tableHeader, alignmentClasses.flexAlignCenter, dimensionClasses.marginTop24, typografyClasses.fontSize14].join(' ')} style={{ width: "max-content" }}>
           <Tooltip arrow placement="top" title="Active, Prospective or Closed">
            <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Status
          <OrderButtons value={statusOrder} orderFunctions={sortables} specificFunction={value => setStatusOrder(value)} />
            </div>
          </Tooltip>
          <Tooltip arrow placement="top" title="If relevant (i.e. Active and sometime Prospective deals)">
            <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Deadline
          <OrderButtons value={deadlineOrder} orderFunctions={sortables} specificFunction={value => setDeadlineOrder(value)} />
            </div>
          </Tooltip>
          <Tooltip arrow placement="top" title="Number of views recorded when a user clicks 'Learn more' in the deal page">
            <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Views
          <OrderButtons value={viewsOrder} orderFunctions={sortables} specificFunction={value => setViewsOrder(value)} />
            </div>
          </Tooltip>
          <Tooltip arrow placement="top" title="Number of recorded expressions of interest, when a user clicks 'Invest' or 'Indicate Interest' in the deal page">
            <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Interested
          <OrderButtons value={interestedOrder} orderFunctions={sortables} specificFunction={value => setInterestedOrder(value)} />
            </div>
          </Tooltip>
          <Tooltip arrow placement="top" title="Number of recorded feedbacks from users when they communicate why they're not interested in the investment opportunity (e.g. if the complete the section when they click 'If not interested, tell us why')">
            <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Not interested
          <OrderButtons value={notInterestedOrder} orderFunctions={sortables} specificFunction={value => setNotInterestedOrder(value)} />
            </div>
          </Tooltip>
          <Tooltip arrow placement="top" title="Number of recorded completed investments by investors">
            <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Completed
          <OrderButtons value={completedOrder} orderFunctions={sortables} specificFunction={value => setCompletedOrder(value)} />
            </div>
          </Tooltip>
          <Tooltip arrow placement="top" title="Average time spent on the detailed page">
            <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Avge session time
          <OrderButtons value={sessionTimeOrder} orderFunctions={sortables} specificFunction={value => setSessionTimeOrder(value)} />
            </div>
          </Tooltip>
          <Tooltip arrow placement="top" title="Total amount of recorded interest in the opportunity when the user clicks 'Invest' or 'Indicate Interest' and communicate an investment amount">
            <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Declared
          <OrderButtons value={declaredOrder} orderFunctions={sortables} specificFunction={value => setDeclaredOrder(value)} />
            </div>
          </Tooltip>
          <Tooltip arrow placement="top" title="Total amount of recorded binding commitment that has gone through the process all the way to signing investment documents and wiring funds">
            <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Commited
          <OrderButtons value={commitedOrder} orderFunctions={sortables} specificFunction={value => setCommitedOrder(value)} />
            </div>
          </Tooltip>
          <Tooltip arrow placement="top" title="Total amount available for the deal">
            <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Available
          <OrderButtons value={availableOrder} orderFunctions={sortables} specificFunction={value => setAvailableOrder(value)} />
            </div>
          </Tooltip>
          <Tooltip arrow placement="top" title="Number of messages users send about a deal">
            <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
              Messages
          <OrderButtons value={messagesOrder} orderFunctions={sortables} specificFunction={value => setMessagesOrder(value)} />
            </div>
          </Tooltip>
        </div>
        
        {
          data && data.Deal
            ? data.Deal.map((x, index) => (
              <div key={index} className={[tableClasses.row, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14].join(' ')} style={{ width: "max-content" }}>
                <p className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                  {x.status.toLowerCase()}
                </p>
                <p className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                  {new Date(x.investment_deadline).toLocaleDateString()}
                </p>
                <p className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                  {x.views_total.aggregate?.sum.counter || "-"}
                </p>
                <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                  {x.interested.aggregate?.sum.counter || "-"}
                </p>
                <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                  {x.not_interested.aggregate?.sum.counter || "-"}
                </p>
                <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                  {x.completed.aggregate?.sum.counter || "-"}
                </p>
                <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                  {formatMiliseconds(x.avge_session_time.aggregate?.avg.average) || "-"}
                </p>
                <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                  {x.declared.aggregate?.sum.value ? `$${numberWithCommas(x.declared.aggregate?.sum.value)}` : "-"}
                </p>
                <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                  {x.committed.aggregate?.sum.value ? `$${numberWithCommas(x.committed.aggregate?.sum.value)}` : "-"}
                </p>
                <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                  {x.available ? `$${numberWithCommas(x.available)}` : "-"}
                </p>
                <p className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                  {x.messages.aggregate.sum.counter || "-"}
                </p>
              </div>
            ))
            : ""
        }
      </div>
      </div>
    </Fragment>
  )
}

export default DealsTable;