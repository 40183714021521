import { gql } from '@apollo/client';
import { GraphQLString } from 'graphql';
import store from '../store/store';

export const ACCEPT_CONFIDENTIALITY_AGREEMENT = gql`
mutation SetAcceptConfidentialityAgreement($userId: String!) {
  update_Account_by_pk(pk_columns: {firebase_id: $userId}, _set: {accepted_confidentiality: true}) {
    accepted_confidentiality
    city
    country
    email
    firebase_id
    last_name
    first_name
    phone_number
  }
}
`;

export const GET_ACCOUNT_PERMISSIONS = gql`
query MyQuery($permission: [bigint!]!) {
  Permissions(where: {id: {_in: $permission}}) {
    analytics
    app_content_access
    assign_roles
    create_opportunity
    edit_opportunity
    edit_opportunity_privacy
    manage_client
    manage_client_document
    manage_roles
    publish_opportunity
    read_client_document
    read_client_info
    read_employee
    settings
    view_other_user
    handle_onboarding
    create_acc_for_investor
    edit_client_info
  }
}
`;


export const GET_ACCOUNT__BY_ID = gql`
query GetAccountById($userId: String!) {
  Account_by_pk(firebase_id: $userId) {
    accepted_confidentiality
    city
    country
    email
    firebase_id
    first_name
    phone_number
    last_name
    email_preference_frequency
    email_preference_investment_opportunities
    email_preference_investment_tips
    email_preference_personalised_deals
    email_preference_watchlist
    investor_profile {
      id
      first_time_viewed
    }
    permissions(where: {deal_id: {_is_null: true}}) {
      role_id
      permission {
        role_type
        analytics
        app_content_access
        assign_roles
        create_acc_for_investor
        create_opportunity
        edit_client_info
        edit_opportunity
        edit_opportunity_privacy
        handle_onboarding
        manage_client
        manage_client_document
        settings
        view_other_user
        read_employee
        read_client_info
        read_client_document
        publish_opportunity
        manage_roles
      }
    }
    first_time_viewed
    accredited_investor
    app_client
    Onboarding_flow_section_edges {
      completed
      id
    }
  }
}
`;

export const SET_ACCOUNT_DETAILS = gql`
mutation UpdateAccountDetails($userId: String!, $city: String!, $country: String!, $first_name: String!, $last_name: String!, $phone_number: String!) {
   update_Account_by_pk(pk_columns: {firebase_id: $userId}, _set: {country: $country, city: $city, first_name: $first_name, last_name: $last_name, phone_number: $phone_number}) {
     firebase_id
     first_name
     phone_number
     last_name
     country
     city    
  }
}
`;

export const UPDATE_ACCOUNT_DETAILS = gql`
mutation UpdateAccountDetails($userId: String!, $city: String!, $country: String!, $first_name: String!, $last_name: String!, $phone_number: String!) {
  update_Account_by_pk(pk_columns: {firebase_id: $userId}, _set: {country: $country, city: $city, first_name: $first_name, last_name: $last_name, phone_number: $phone_number}) {
    firebase_id
    first_name
    phone_number
    last_name
    country
    city
    accepted_confidentiality    
  }
}
`;

export const UPDATE_ACCOUNT_EMAIL_PREFFERENCES = gql`
mutation MyMutation($firebase_id: String!, $email_preference_frequency: Int!, $email_preference_investment_opportunities: Boolean!, $email_preference_investment_tips: Boolean!, $email_preference_personalised_deals: Boolean!, $email_preference_watchlist: Boolean!) {
  update_Account_by_pk(pk_columns: {firebase_id: $firebase_id}, _set: {email_preference_investment_opportunities: $email_preference_investment_opportunities, email_preference_investment_tips: $email_preference_investment_tips, email_preference_personalised_deals: $email_preference_personalised_deals, email_preference_frequency: $email_preference_frequency, email_preference_watchlist: $email_preference_watchlist}) {
    email_preference_frequency
    email_preference_investment_opportunities
    email_preference_investment_tips
    email_preference_personalised_deals
    email_preference_watchlist
  }
}
`;

export const GET_NAME_SURNAME_FROM_WHITELIST = gql`
query MyQuery($email: String = "") {
  Whitelist(where: {email: {_eq: $email}}) {
    first_name
    last_name
  }
  Account(where: {email: {_eq: $email}}) {
    phone_number
    phone_zone
    city
    country
  }
}
`;

export const SET_ACCOUNT_LAST_STEP_INFOS = gql`
mutation MyMutation($firebase_id: String!, $app_client: String = "", $accredited_investor: String = "") {
  update_Account_by_pk(pk_columns: {firebase_id: $firebase_id}, _set: {accredited_investor: $accredited_investor, app_client: $app_client, first_time_viewed: ${Date.now()}}) {
    updated_at
  }
}

`;

export const GET_ONBOARDING_FLOW = gql`
query MyQuery($firebase_id: String = "") {
  Onboarding_flow_section_edge(where: {firebase_id: {_eq: $firebase_id}}, distinct_on: firebase_id) {
    Flow_node {
      id
      name
      Onboarding_flow_section_edges(where: {firebase_id: {_eq: $firebase_id}}) {
        order
        shown
        current
        completed
        id
        Flow_section_node {
          id
          name
          Onboarding_section_item_edges(where: {firebase_id: {_eq: $firebase_id}}) {
            order
            id
            Flow_item_node {
              name
              text
              type
            }
            Files_metadata(where: {deleted: {_is_null: true}}) {
              id
              name
            }
            Onboarding_flow_item_input_field {
              id
              text
              edge_id
            }
          }
        }
      }
    }
  }
}
`;

export const GET_ASSIGNED_ONBOARDING_OR_DEFAULT = gql`
query MyQuery($firebase_id: String = "") {
  Account(where: {firebase_id: {_eq: $firebase_id}}) {
    Onboarding_flow_section_edges {
      Flow_node {
        id
      }
    }
    app_client
    accredited_investor
  }
  Onboarding_flow_section_edge(where: {is_default: {_eq: true}}) {
    Flow_node {
      id
    }
  }
}

`;

export const GET_DEFAULT_ONBOARDING_FLOW = gql`
query MyQuery($firebase_id: String = "") {
  Onboarding_flow_section_edge(where: {firebase_id: {_eq: $firebase_id}}, distinct_on: firebase_id) {
    Flow_node {
      id
      name
      Onboarding_flow_section_edges(where: {firebase_id: {_eq: $firebase_id}}) {
        order
        shown
        current
        completed
        id
        Flow_section_node {
          id
          name
          Onboarding_section_item_edges(where: {firebase_id: {_eq: $firebase_id}}) {
            order
            id
            Flow_item_node {
              name
              text
              type
            }
            Files_metadata(where: {deleted: {_is_null: true}}) {
              id
              name
            }
            Onboarding_flow_item_input_field {
              id
              text
              edge_id
            }
          }
        }
      }
    }
  }
}
`;

export const GET_ONBOARDING_FLOW_ITEMS_BY_EDGE_ID = gql`
query MyQuery($id: bigint!) {
  Onboarding_section_item_edge_by_pk(id: $id) {
    Files_metadata(where: {deleted: {_is_null: true}}) {
      name
      id
    }
    id
  }
}
`;

export const INSERT_FLOW_INPUT_FIELD = gql`
mutation MyMutation($edge_id: bigint!, $text: String!) {
  insert_Onboarding_flow_item_input_field(objects: {edge_id: $edge_id, text: $text}) {
    returning {
      id
      edge_id
      text
    }
    affected_rows
  }
}
`;

export const UPDATE_FLOW_INPUT_FILED = gql`
mutation MyMutation($id: bigint!, $text: String!) {
  update_Onboarding_flow_item_input_field_by_pk(pk_columns: {id: $id}, _set: {text: $text}) {
    id
    text
  }
}`;

export const CAN_ENTER_APP = gql`
query MyQuery($firebase_id: String = "") {
  Permissions_assignment(where: {firebase_id: {_eq: $firebase_id}, permission: {app_content_access: {_eq: true}}}) {
    id
  }
}
`;

export const validateAccountInvitationToken = (token, email) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/validate`, {
    method: "POST",
    body: JSON.stringify({ 
      token: token,
      email: email
     }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json",
    }
  });
}

export const GET_ONBOARDING_FLOW_EDGES_IDS = gql`
query MyQuery($firebase_id: String = "") {
  Onboarding_flow_section_edge(where: {firebase_id: {_eq: $firebase_id}}) {
    flow_id
    id
  }
}
`

export const GET_ACCOUNT_STATE = gql`
query MyQuery($firebase_id: String!) {
  Account_by_pk(firebase_id: $firebase_id) {
    last_name
    first_name
    city
    country
    phone_number
    app_client
    accredited_investor
    accepted_confidentiality
    first_time_viewed
    permissions(where: {permission: {app_content_access: {_eq: true}}, firebase_id: {_eq: $firebase_id}}) {
      permission {
        app_content_access
      }
    }
    investor_profile {
      id
      first_time_viewed
    }
    Onboarding_flow_section_edges {
      completed
    }
    whitelist_account {
      approved_at
    }
  }
  Onboarding_flow_section_edge(where: {is_default: {_eq: true}}) {
    Flow_node {
      id
    }
  }
}
`;