import { SET_ALL_COMPANY_FUNDING_STAGE, SET_INVESTOR_PROFILE, SET_INVESTOR_PROFILE_COMPANY, SET_INVESTOR_PROFILE_INDUSTRY, SET_INVESTOR_PROFILE_INVESTMENT, SET_INVESTOR_PROFILE_VIEWED } from "../utils/action-types";

const initialState = {
  company_additional_information: "",
  company_business_model_B2B: false,
  company_business_model_B2B2C: false,
  company_business_model_B2C: false,
  company_development_stage_build_product: false,
  company_development_stage_generates_revenue: false,
  company_development_stage_has_product: false,
  company_development_stage_is_profitable: false,
  company_funding_stage_any_stage: false,
  company_funding_stage_post_seed: false,
  company_funding_stage_pre_ipo: false,
  company_funding_stage_seed: false,
  company_funding_stage_series_a: false,
  company_funding_stage_series_b: false,
  company_funding_stage_series_c: false,
  company_funding_stage_series_d: false,
  company_funding_stage_series_f: false,
  company_funding_stage_series_e: false,
  company_funding_stage_convertible_note: false,
  company_funding_stage_other: false,
  company_funding_stage_pre_seed: false,
  company_funding_stage_series_g: false,
  company_funding_stage_series_h: false,
  company_funding_stage_series_i: false,
  company_funding_stage_series_j: false,
  company_funding_stage_series_k: false,
  countries: [],
  industry_additional_information: "",
  innovation_enablers: [],
  interested_sectors: [],
  investment_preffered_currency: "",
  investment_additional_information: "",
  investment_execution_timeline: 0,
  investment_security_type: 0,
  investment_size_high: 0,
  investment_size_low: 0,
  investment_status: 0,
  investment_type: 0,
  investor_profile_innovation_enablers_lesses: [],
  investor_profile_interested_sectors_lesses: [],
  first_time_viewed: 0,
}

const updateInvestorProfileState = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVESTOR_PROFILE: {
      if (!action.payload) return state;
      const inovationEnablers = action.payload.innovation_enablers && action.payload.innovation_enablers.length > 0
        ? action.payload.innovation_enablers.map(x => ({ value: x.ENUM_innovation_enabler.value, description: x.ENUM_innovation_enabler.description }))
        : [];
      const sectors = action.payload.interested_sectors && action.payload.interested_sectors.length > 0
        ? action.payload.interested_sectors.map(x => ({ value: x.ENUM_interested_sector.value, description: x.ENUM_interested_sector.description }))
        : [];
      const lessInovationEnablers = action.payload.investor_profile_innovation_enablers_lesses && action.payload.investor_profile_innovation_enablers_lesses.length > 0
        ? action.payload.investor_profile_innovation_enablers_lesses.map(x => ({ value: x.ENUM_innovation_enabler.value, description: x.ENUM_innovation_enabler.description }))
        : [];
      const lessSectors = action.payload.investor_profile_interested_sectors_lesses && action.payload.investor_profile_interested_sectors_lesses.length > 0
        ? action.payload.investor_profile_interested_sectors_lesses.map(x => ({ value: x.ENUM_interested_sector.value, description: x.ENUM_interested_sector.description }))
        : [];
      const companyCountries = action.payload.countries && action.payload.countries.length > 0
        ? action.payload.countries.map(x => ({ value: x.ENUM_country.value, description: x.ENUM_country.description }))
        : [];
      return {
        company_additional_information: action.payload.company_additional_information,
        company_business_model_B2B: action.payload.company_business_model_B2B,
        company_business_model_B2B2C: action.payload.company_business_model_B2B2C,
        company_business_model_B2C: action.payload.company_business_model_B2C,
        company_development_stage_build_product: action.payload.company_development_stage_build_product,
        company_development_stage_generates_revenue: action.payload.company_development_stage_generates_revenue,
        company_development_stage_has_product: action.payload.company_development_stage_has_product,
        company_development_stage_is_profitable: action.payload.company_development_stage_is_profitable,
        company_funding_stage_any_stage: action.payload.company_funding_stage_any_stage,
        company_funding_stage_post_seed: action.payload.company_funding_stage_post_seed,
        company_funding_stage_pre_ipo: action.payload.company_funding_stage_pre_ipo,
        company_funding_stage_seed: action.payload.company_funding_stage_seed,
        company_funding_stage_series_a: action.payload.company_funding_stage_series_a,
        company_funding_stage_series_b: action.payload.company_funding_stage_series_b,
        company_funding_stage_series_c: action.payload.company_funding_stage_series_c,
        company_funding_stage_series_d: action.payload.company_funding_stage_series_d,
        company_funding_stage_series_f: action.payload.company_funding_stage_series_f,
        company_funding_stage_series_e: action.payload.company_funding_stage_series_e,
        company_funding_stage_convertible_note: action.payload.company_funding_stage_convertible_note,
        company_funding_stage_other: action.payload.company_funding_stage_other,
        company_funding_stage_pre_seed: action.payload.company_funding_stage_pre_seed,
        company_funding_stage_series_g: action.payload.company_funding_stage_series_g,
        company_funding_stage_series_h: action.payload.company_funding_stage_series_h,
        company_funding_stage_series_i: action.payload.company_funding_stage_series_i,
        company_funding_stage_series_j: action.payload.company_funding_stage_series_j,
        company_funding_stage_series_k: action.payload.company_funding_stage_series_k,
        countries: companyCountries,
        industry_additional_information: action.payload.industry_additional_information,
        innovation_enablers: inovationEnablers,
        interested_sectors: sectors,
        investment_preffered_currency: action.payload.investment_preffered_currency,
        investment_additional_information: action.payload.investment_additional_information,
        investment_execution_timeline: action.payload.investment_execution_timeline,
        investment_security_type: action.payload.investment_security_type,
        investment_size_high: action.payload.investment_size_high,
        investment_size_low: action.payload.investment_size_low,
        investment_status: action.payload.investment_status,
        investment_type: action.payload.investment_type,
        investor_profile_innovation_enablers_lesses: lessInovationEnablers,
        investor_profile_interested_sectors_lesses: lessSectors,
        first_time_viewed: action.payload.first_time_viewed,
      }
    }
    case SET_ALL_COMPANY_FUNDING_STAGE: {
      return {
        ...state,
        company_funding_stage_any_stage: action.payload,
        company_funding_stage_post_seed: action.payload,
        company_funding_stage_pre_ipo: action.payload,
        company_funding_stage_seed: action.payload,
        company_funding_stage_series_a: action.payload,
        company_funding_stage_series_b: action.payload,
        company_funding_stage_series_c: action.payload,
        company_funding_stage_series_d: action.payload,
        company_funding_stage_series_f: action.payload,
        company_funding_stage_series_e: action.payload,
        company_funding_stage_convertible_note: action.payload,
        company_funding_stage_other: action.payload,
        company_funding_stage_pre_seed: action.payload,
        company_funding_stage_series_g: action.payload,
        company_funding_stage_series_h: action.payload,
        company_funding_stage_series_i: action.payload,
        company_funding_stage_series_j: action.payload,
        company_funding_stage_series_k: action.payload,
      }
    }
    case SET_INVESTOR_PROFILE_INDUSTRY: {
      return {
        ...state,
        innovation_enablers: action.payload.innovationEnablers,
        interested_sectors: action.payload.interestedSectors,
        investor_profile_innovation_enablers_lesses: action.payload.innovationEnablersLess,
        investor_profile_interested_sectors_lesses: action.payload.interestedSectorsLess,
      }
    }
    case SET_INVESTOR_PROFILE_COMPANY: {
      return {
        ...state,
        company_additional_information: action.payload.company_additional_information,
        company_business_model_B2B: action.payload.company_business_model_B2B,
        company_business_model_B2B2C: action.payload.company_business_model_B2B2C,
        company_business_model_B2C: action.payload.company_business_model_B2C,
        company_development_stage_build_product: action.payload.company_development_stage_build_product,
        company_development_stage_generates_revenue: action.payload.company_development_stage_generates_revenue,
        company_development_stage_has_product: action.payload.company_development_stage_has_product,
        company_development_stage_is_profitable: action.payload.company_development_stage_is_profitable,
        company_funding_stage_any_stage: action.payload.company_funding_stage_any_stage,
        company_funding_stage_post_seed: action.payload.company_funding_stage_post_seed,
        company_funding_stage_pre_ipo: action.payload.company_funding_stage_pre_ipo,
        company_funding_stage_seed: action.payload.company_funding_stage_seed,
        company_funding_stage_series_a: action.payload.company_funding_stage_series_a,
        company_funding_stage_series_b: action.payload.company_funding_stage_series_b,
        company_funding_stage_series_c: action.payload.company_funding_stage_series_c,
        company_funding_stage_series_d: action.payload.company_funding_stage_series_d,
        company_funding_stage_series_e: action.payload.company_funding_stage_series_e,
        company_funding_stage_series_f: action.payload.company_funding_stage_series_f,
        countries: action.payload.countries
      }
    }
    case SET_INVESTOR_PROFILE_INVESTMENT: {
      return {
        ...state,
        investment_preffered_currency: action.payload.investment_preffered_currency,
        investment_additional_information: action.payload.investment_additional_information,
        investment_execution_timeline: action.payload.investment_execution_timeline,
        investment_security_type: action.payload.investment_security_type,
        investment_size_high: action.payload.investment_size_high,
        investment_size_low: action.payload.investment_size_low,
        investment_status: action.payload.investment_status,
        investment_type: action.payload.investment_type,
      }
    }
    case SET_INVESTOR_PROFILE_VIEWED: {
      return {
        ...state,
        first_time_viewed: action.payload.first_time_viewed,
      }
    }
    default:
      return state;
  }
}

export default updateInvestorProfileState;