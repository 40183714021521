import { useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { GET_ALL_PERMISSIONS, GET_ALL_EMPLOYEES_PERMISSIONS } from "../../queries/admin-queries";
import Loading from '../../../shared/Loading/Loading';
import AddEmployeeDialog from './AddEmployeeDialog';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from "../../AdminStyles";
import EmployeeManagementRow from "./EmployeeManagementRow";
import OrderButtons from "../../../shared/OrderButtons/OrderButtons";
import { useSelector } from "react-redux";
import EmployeeManagementDialog from "./EmployeeManagementDialog";

const EmployeesManagement = ({ nameFilter = "" }) => {
  const dimenstionsClasses = adminDimensionsStyle();
  const alignmentStyles = adminAlignmentStyles();
  const tableStyles = adminTablesStyle();
  const canChangeRoles = useSelector(store => store.permissionAsignments.assign_roles && store.permissionAsignments.manage_roles);
  const typografyStyles = adminTypografyStyles();
  const [open, setOpen] = useState(false);
  const [managementDialogId, setManagementDialogId] = useState(0);
  const [emailOrder, setEmailOrder] = useState(null);
  const [nameOrder, setNameOrder] = useState(null);
  const roles = useQuery(GET_ALL_PERMISSIONS).data;
  const users = useQuery(GET_ALL_EMPLOYEES_PERMISSIONS, { fetchPolicy: "network-only", variables: {name: nameOrder, email: emailOrder} }).data;

  if (!users || !roles) {
    return <Loading fullWidth />
  }

  return (
    <Fragment>
      <div className={[dimenstionsClasses.containerWidth, dimenstionsClasses.headerMarginFix].join(' ')}>
        <div className={[
          dimenstionsClasses.marginTop16,
          tableStyles.tableHeader,
          typografyStyles.fontSize14,
          typografyStyles.fontColor7B7B7B,
          alignmentStyles.flexAlignCenter].join(' ')}>
          <div className={[dimenstionsClasses.width200, alignmentStyles.flexCenterSpaceBetween].join(' ')}>
            Name
            <OrderButtons value={nameOrder} orderFunctions={[setEmailOrder, setNameOrder]} specificFunction={value => setNameOrder(value)} />
          </div>
          <div className={[dimenstionsClasses.width250, alignmentStyles.flexCenterSpaceBetween].join(' ')}>
            Email
            <OrderButtons value={emailOrder} orderFunctions={[setEmailOrder, setNameOrder]} specificFunction={value => setEmailOrder(value)} />
          </div>
          <p className={dimenstionsClasses.widthGrow}>Role</p>
          <Button disabled={!canChangeRoles} className={dimenstionsClasses.smallHeigth} variant="outlined" color="primary" onClick={() => setOpen(true)} >Add employee</Button>
        </div>
        {
          users.Account.filter(account => {
            if (nameFilter === "") return true;
            if (account.first_name === null || account.last_name === null) return false;
            if (account.email.toLowerCase().search(nameFilter.toLowerCase()) > -1) return true;
            if (account.first_name.toLowerCase().search(nameFilter.toLowerCase()) > -1) return true;
            if (account.last_name.toLowerCase().search(nameFilter.toLowerCase()) > -1) return true;
            return false;
          })
            .map((user, index) => <EmployeeManagementRow key={index} user={user} roles={roles.Permissions} canAssign={canChangeRoles} openManagementDialog={id => setManagementDialogId(id)}/>)
        }
      </div>
      <AddEmployeeDialog open={open} setOpen={value => setOpen(value)} />
      <EmployeeManagementDialog open={managementDialogId !== 0} employeeId={managementDialogId} close={() => setManagementDialogId(0)} />
    </Fragment>
  )
}

export default EmployeesManagement;