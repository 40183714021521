import { Button, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Fragment, useEffect, useState } from 'react';
import { GET_ALL_ACCOUNTS_FROM_WHITELISTED_WITH_ROLE, GET_ALL_ONBOARDING_FLOWS, GET_NOT_APPROVED_EMAILS } from '../../queries/admin-queries';
import { useQuery } from '@apollo/client';
import { sendInvitationEmail, sendProvisoryInvitationEmail } from '../../queries/admin-http-calls';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { filterByName } from './tableUtils';
import { adminAlignmentStyles, adminDimensionsStyle, adminInputStyles, adminTablesStyle, adminTypografyStyles } from '../../AdminStyles';
import { EventThrower, hasuraErrorHandler, Events } from '../../../shared/EventEmitter/EventEmitter';
import OrderButtons from '../../../shared/OrderButtons/OrderButtons';
import { useSelector } from 'react-redux';

const filter = createFilterOptions();

const WhitelistedClientsTable = ({ nameFilter = "" }) => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const tableClasses = adminTablesStyle();
  const typografyClasses = adminTypografyStyles();
  const canAccess = useSelector(store => store.permissionAsignments.manage_client);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [emailOrder, setEmailOrder] = useState(null);
  const [addedOrder, setAddedOrder] = useState(null);
  const [nameOrder, setNameOrder] = useState(null);
  const data = useQuery(GET_ALL_ACCOUNTS_FROM_WHITELISTED_WITH_ROLE, { variables: { roleId: "1",  name: nameOrder, email: emailOrder, updated_at: addedOrder}, fetchPolicy: "network-only" }).data;
  const allEmails = useQuery(GET_NOT_APPROVED_EMAILS).data;

  const sendEmail = (account) => {
    if(account.account.firebase_id.startsWith('PROVISORY_ID_')) {
      sendProvisoryInvitationEmail(account.email, account.account.firebase_id)
      .then(res => res.json())
      .then(() => EventThrower.throwClientSideMessage(Events.EmailSentSuccessfully))
      .catch(() => hasuraErrorHandler.printError(`Error sending email to ${account.email}`))
    } else {
      sendInvitationEmail(account.email, account.account.first_name, account.account.last_name)
        .then(res => res.json())
        .then(() => EventThrower.throwClientSideMessage(Events.EmailSentSuccessfully))
        .catch(() => hasuraErrorHandler.printError(`Error sending email to ${account.email}`))
    }
  }

  const sendMultipleEmails = () => {
    if (selectedEmails && selectedEmails.length > 0) {
      selectedEmails.forEach(x => {
        sendInvitationEmail(x, '', '')
          .then(res => res.json())
          .then(() => EventThrower.throwClientSideMessage(Events.EmailSentSuccessfully))
          .catch(() => hasuraErrorHandler.printError(`Error sending email to ${x}`))
      })
    }
  }

  useEffect(() => {
    if (data) {
      hasuraErrorHandler.checkResponseForErrors(data);
    }
  }, [data])

  const tableData = data
    ? data.Whitelist.filter(x => filterByName(x, nameFilter)).map((account, index) => (
      <div className={[
        dimensionClasses.containerWidth,
        alignmentClasses.flexAlignCenter,
        tableClasses.row
      ].join(' ')} key={index}>
        <p className={dimensionClasses.width200}>
        {
            account.account && account.account.first_name && account.account.last_name
              ? `${account.account.first_name} ${account.account.last_name}`
              : "Name not provided"
          }
        </p>
        <p className={dimensionClasses.width250}>{account.email}</p>
        <p className={dimensionClasses.widthGrow}>{new Date(account.updated_at).toLocaleDateString()}</p>
        <Button className={[typografyClasses.fontColor0258A8, typografyClasses.noTrasnform].join(' ')}
          onClick={() => sendEmail(account)} >Resend email</Button>
      </div>
    )) : []

  if(!canAccess) return <div style={{marginTop: "80px", textAlign: "center"}}>You do not have permission to view the whitelist tab</div>

  return (
    <Fragment>
      <div className={[dimensionClasses.containerWidth, dimensionClasses.headerMarginFix].join(' ')}>
        <p className={[dimensionClasses.marginBottom4, typografyClasses.fontSize14].join(' ')} style={{ color: "#333333" }}>Whitelist approved clients emails</p>
        <div className={[dimensionClasses.containerWidth, alignmentClasses.flex].join(' ')}>
          <Autocomplete
            className={[dimensionClasses.widthGrow, dimensionClasses.mediumHeight].join(' ')}
            multiple
            limitTags={5}
            size="small"
            onChange={(event, newValue) => setSelectedEmails(newValue)}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== '') {
                filtered.push(params.inputValue);
              }

              return filtered;
            }}
            options={allEmails && allEmails.Whitelist ? allEmails.Whitelist.map(x => x.email) : []}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder="Add multiple emails" />
            )}
          />
          <Button variant="contained" color="primary" className={[dimensionClasses.marginLeft16, dimensionClasses.mediumHeight].join(' ')} onClick={() => sendMultipleEmails()}>Add email(s)</Button>
        </div>
      </div>
      <div className={[
        dimensionClasses.containerWidth,
        alignmentClasses.flexAlignCenter,
        tableClasses.tableHeader,
        dimensionClasses.marginTop16,
        typografyClasses.fontSize14,
        dimensionClasses.paddingBottom16,
      ].join(' ')}>
         <div className={[dimensionClasses.width200, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Name
            <OrderButtons value={nameOrder} orderFunctions={[setAddedOrder, setEmailOrder, setNameOrder]} specificFunction={value => setNameOrder(value)} />
        </div>
        <div className={[dimensionClasses.width250, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Email
          <OrderButtons value={emailOrder} orderFunctions={[setEmailOrder, setAddedOrder, setNameOrder]} specificFunction={value => setEmailOrder(value)} />
        </div>
        <div className={alignmentClasses.flex}>
          <span className={dimensionClasses.marginRight50}>Added</span>
          <OrderButtons value={addedOrder} orderFunctions={[setEmailOrder, setAddedOrder, setNameOrder]} specificFunction={value => setAddedOrder(value)} />
        </div>
      </div>
      <div>
      {tableData.length === 0 && "You do not have any clients to manage"}
        {tableData}
      </div>
    </Fragment>
  )
}

export default WhitelistedClientsTable;