import { useMutation, useQuery } from '@apollo/client';
import { Avatar, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CREATE_DEAL_CHAT, GET_AVALABILE_CHAT_ADMINS, GET_DEAL_CHAT_CONVERSATIONS, GET_GENERAL_CHAT_ADMINS } from '../../../utils/deal-queries';
import { generalStyleClasses } from '../../../utils/general-styles';
import { hasuraErrorHandler} from '../../../shared/EventEmitter/EventEmitter'
const styles = makeStyles({
  container: {
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  },
  personContainer: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
    border: "1px solid #D8CECE",
    marginBottom: "16px",
    cursor: "pointer",
    "&:hover": {
      background: "#eeeeee",
    }
  },
})

const GeneralPeopleSelector = ({dealId, setAdmin = (admin) => null}) => {
  const [availablePersons, setAvailablePersons] = useState([]);
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const {data: createdChatsData} = useQuery(GET_GENERAL_CHAT_ADMINS, {variables: {investor_id: firebaseId}, fetchPolicy: "network-only"});
  const [createDealChat] = useMutation(CREATE_DEAL_CHAT);
  const generalClasses = generalStyleClasses();
  const classes = styles();
  const handleSelectAdmin = async (admin) => {
    if(!admin.chatId) {
      const createChatResult = await createDealChat({variables: { employee_id: admin.id, investor_id: firebaseId, deal_id: dealId }})
      if(createChatResult.data) {
        setAdmin({...admin, chatId: createChatResult.data.insert_Deal_chat.returning[0]?.id || 0});
      } else {
        hasuraErrorHandler.printError("Unable to create conversation")
      }
    } else {
      setAdmin(admin);
    }
  }

  useEffect(() => {
    if(createdChatsData) {
      setAvailablePersons([
        ...createdChatsData.account_basic_info.map(x => ({
          id: x.firebase_id, 
          firstName: x.first_name, 
          lastName: x.last_name, 
          role: "Application Admin", 
          dealId: null,
          chatId: createdChatsData.General_chat.filter(chat => chat.employee_id === x.firebase_id)[0]?.id || 0
        })),
        ...createdChatsData.relationship_managers.map(x => ({
          id: x.firebase_id, 
          firstName: x.first_name, 
          lastName: x.last_name, 
          role: "Relationship Manager", 
          dealId: null,
          chatId: createdChatsData.General_chat.filter(chat => chat.employee_id === x.firebase_id)[0]?.id || 0
        })),
      ])
      
    }
  }, [createdChatsData])

  return (
    <div className={classes.container}>
      {
        availablePersons.map((person, index) => (
          <div key={index} className={classes.personContainer} onClick={() => handleSelectAdmin(person)}>
            <Avatar>{`${person.firstName[0]}${person.lastName[0]}`}</Avatar>
            <div style={{marginLeft: "16px"}}>
              <p className={[generalClasses.marginTop0, generalClasses.marginBottom0, generalClasses.fontInputColor, generalClasses.fontSize14].join(' ')}>{`${person.firstName} ${person.lastName}`}</p>
              <p className={[generalClasses.marginTop8, generalClasses.marginBottom0, generalClasses.fontSize12].join(' ')} style={{color : "#A9A9A9"}}>{person.role}</p>
              </div>
            </div>
        ))
      }
    </div>
  )
}

export default GeneralPeopleSelector;