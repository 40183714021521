import React, { useEffect, useRef, useState } from 'react';
import { opportunitiesPageStyles } from '../../OpportunitiesPage/OpportunitiesPageStyle';
import { generalStyleClasses } from '../../../../../utils/general-styles';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import TopsideMenu from './TopsideMenu';
import InvestmentsOpportunitiesContainer from './InvestmentsOpportunitiesContainer';

const InvestmentsPage = () => {
  const classes = opportunitiesPageStyles();
  const generalStyles = generalStyleClasses();
  const { url } = useRouteMatch();
  const [orderString, setOrderString] = useState("");
  const contentRef = useRef();
  const [nameFilter, setNameFilter] = useState(null);
  const listenScrollEvent = (event) => {
    if (window.scrollY > 0) {
      contentRef.current.style.zIndex = 50;
    } else {
      contentRef.current.style.zIndex = 101;
    }
  }
  
  useEffect(() => {
    listenScrollEvent(null);
    window.addEventListener('scroll', listenScrollEvent);
  
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);


  return (
    <div className={[generalStyles.fullViewWidth, generalStyles.flexAlignCenterOnColumn].join(' ')}>
      <div className={[classes.topsideMenu, generalStyles.flexAlignCenter].join(' ')}>
        <TopsideMenu handleNewOrder={value => setOrderString(value)} setNewNameFilter={value => setNameFilter(value.replaceAll("\"", "'"))}/>
      </div>
      <div className={[generalStyles.flexAlignCenterOnColumn, classes.content].join(' ')} style={{ marginLeft: "0px" }} ref={contentRef}>
        <Switch>
          <Route path={`${url}/all`} component={() => <InvestmentsOpportunitiesContainer nameFilter={nameFilter} orderString={orderString} />} />
          <Route path={`${url}/in-progress`} component={() => <InvestmentsOpportunitiesContainer nameFilter={nameFilter} orderString={orderString} isInProgress={true} />} />
          <Route path={`${url}/complated`} component={() => <InvestmentsOpportunitiesContainer nameFilter={nameFilter} orderString={orderString} isCompleted={true} />} />
          <Route exact path={`${url}*`} component={() => <Redirect to={`${url}/all`} />} />
        </Switch>
      </div>
    </div>
  );
}

export default InvestmentsPage