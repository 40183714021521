import { Button,  MenuItem, Select } from '@material-ui/core';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import TextInput from '../../../shared/TextInput/TextInput';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { ADD_COMPANY, GET_COMPANY_DETAILS_BY_ID, GET_COMPANY_ENUMS, UPDATE_COMPANY_BY_ID } from '../../queries/admin-queries';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Loading from '../../../shared/Loading/Loading';
import { adminAlignmentStyles, adminDimensionsStyle, adminInputStyles, adminTypografyStyles } from '../../AdminStyles';
import MultipleSelectField from '../../../shared/MultipleSelect/MultipleSelectField';
import AddFundingHistory from './AddFundingHistory';
import AddManagement from './AddManagement';
import { Events, EventThrower, hasuraErrorHandler } from '../../../shared/EventEmitter/EventEmitter';
import { addCompanyLogo } from '../../../utils/file-queries';

const AddCompany = forwardRef((props, ref) => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const typografyClasses = adminTypografyStyles();
  const inputClasses = adminInputStyles();
  const routeParams = useRouteMatch().params;
  const selectData = useQuery(GET_COMPANY_ENUMS).data;
  const [addCompany] = useMutation(ADD_COMPANY);
  const [updateCompany] = useMutation(UPDATE_COMPANY_BY_ID);
  const [getCompanyById, { data }] = useLazyQuery(GET_COMPANY_DETAILS_BY_ID, { fetchPolicy: "network-only" });
  const [isSent, setIsSent] = useState(false);
  const [name, setName] = useState("");
  const [foundingYear, setFoundingYear] = useState(null);
  const [hqCountry, setHqCountry] = useState("");
  const [hqState, setHqState] = useState("");
  const [hqCity, setHqCity] = useState("");
  const [fundingStage, setFundingStage] = useState("");
  const [sectors, setSectors] = useState([]);
  const [inovationEnablers, setInovationEnablers] = useState([]);
  const [bussinessModel, setBussinessModel] = useState("");
  const [companyStage, setCompanyStage] = useState("");
  const [entityType, setEntityType] = useState("");
  const [lastValuation, setLastValuation] = useState(null);
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [fundingHistory, setFundingHistory] = useState([]);
  const [management, setManagement] = useState([])
  const [companyLogo, setCompanyLogo] = useState(null);
  const history = useHistory();

  
  useEffect(() => {
    if (routeParams && routeParams.companyId) {
      getCompanyById({ variables: { id: routeParams.companyId } })
    }
  }, [routeParams, getCompanyById])

  useEffect(() => {
    if (data && data.Company_by_pk) {
      setName(data.Company_by_pk.name);
      setFoundingYear(data.Company_by_pk.founding_year);
      setHqCountry(data.Company_by_pk.hq_country);
      setHqState(data.Company_by_pk.hq_state);
      setHqCity(data.Company_by_pk.hq_city);
      setFundingStage(data.Company_by_pk.funding_stage);
      setLastValuation(data.Company_by_pk.last_valuation);
      setSectors(data.Company_by_pk.sectors.map(x => x.ENUM_interested_sector));
      setInovationEnablers(data.Company_by_pk.innovation_enablers.map(x => x.ENUM_innovation_enabler));
      setBussinessModel(data.Company_by_pk.business_model);
      setCompanyStage(data.Company_by_pk.stage);
      setEntityType(data.Company_by_pk.entity_type);
      setDescription(data.Company_by_pk.description);
      setSummary(data.Company_by_pk.blurp);
      setCompanyLogo({name: data.Company_by_pk.logo_metadata?.name || ""})
      setFundingHistory(data.Company_by_pk.funding_histories.map(x => ({
        stage: x.financing_round,
        currency: x.amount_raised_curency,
        date: new Date(x.date),
        valuation: x.pre_money_valuation,
        amountRaised: x.amount_raised,
        investors: x.notable_investors.map(x => ({name: x.notable_investor_name, isSummary: x.notable_investor_is_summary}))
      })));
      setManagement(data.Company_by_pk.company_managements.map(x => ({
        name: x.name,
        position: x.position,
        description: x.bio,
      })))
    } else if(data && !data.Company_by_pk) {
      hasuraErrorHandler.printError("Undefined company id")
      history.push("/admin/opportunities/companies");
    }
  }, [data, history])

  const companyUpdatedSuccess = () => {
    EventThrower.throwClientSideMessage(Events.InformationsUpdated("Company"))
    history.push("/admin/opportunities/companies");
  }

  const addCompanyCall = () => {
    const uniqueInvestors = {};
    const variables = {
      name: name,
      founding_year: foundingYear,
      hq_country: hqCountry,
      hq_state: hqState,
      hq_city: hqCity,
      company_security: "security",
      business_status: "bussiness status",
      funding_stage: fundingStage,
      last_valuation: lastValuation,
      estimated_opening: "1607078975144",
      proposed_valuation: "100000005000",
      investment_deadline: "1607078975144",
      available_to_invest: "1500000000",
      sectors: sectors.map(x => ({ sector: x.value })),
      innovation_enablers: inovationEnablers.map(x => ({ innovation_enabler: x.value })),
      business_model: bussinessModel,
      stage: companyStage,
      description: description,
      blurp: summary,
      entity_type: entityType,
      notable_investor_names: fundingHistory
        .map(x => x.investors)
        .reduce((a, b) => a.concat(...b), [])
        .map(x => ({value: x.name}))
        .filter(x => uniqueInvestors.hasOwnProperty(x.value) ? false : (uniqueInvestors[x.value] = true)),
      funding_history: fundingHistory.map(x => ({
        financing_round: x.stage,
        amount_raised_curency: x.currency,
        pre_money_valuation_currency: x.currency,
        date: new Date(x.date).valueOf(),
        pre_money_valuation: x.valuation,
        amount_raised: x.amountRaised,
        notable_investors: {data: x.investors.map(x => ({notable_investor_name: x.name, notable_investor_is_summary: x.isSummary}))}, 
      })),
      company_management: management.map(x => ({ name: x.name, position: x.position, bio: x.description }))
    }
    addCompany({ variables: variables }).then(res => {
      if(companyLogo instanceof File && res.data.insert_Company && res.data.insert_Company.returning[0] && res.data.insert_Company.returning[0].id) {
        addCompanyLogo(companyLogo, res.data.insert_Company.returning[0].id)
        .then(res => res.json)
        .then(() => companyUpdatedSuccess())
        .catch(() => hasuraErrorHandler.printError("Error uploading company logo"))
      } else {
        companyUpdatedSuccess();
      }
    }).catch(() => {
      setIsSent(true);
      hasuraErrorHandler.printError("Please complete all the required fields");
    })
  }

  const updateCompanyCall = () => {
    const uniqueInvestors = {};
    const variables = {
      company_id: routeParams.companyId,
      name: name,
      founding_year: foundingYear,
      hq_country: hqCountry,
      hq_state: hqState,
      hq_city: hqCity,
      funding_stage: fundingStage,
      stage: companyStage,
      last_valuation: lastValuation,
      sectors: sectors.map(x => ({ sector: x.value, company_id: routeParams.companyId })),
      innovation_enablers: inovationEnablers.map(x => ({ innovation_enabler: x.value, company_id: routeParams.companyId })),
      business_model: bussinessModel,
      stage: companyStage,
      description: description,
      blurp: summary,
      entity_type: entityType,
      notable_investors: fundingHistory
        .map(x => x.investors)
        .reduce((a, b) => a.concat(...b), [])
        .map(x => ({value: x.name}))
        .filter(x => uniqueInvestors.hasOwnProperty(x.value) ? false : (uniqueInvestors[x.value] = true)),
      funding_history: fundingHistory.map(x => ({
        financing_round: x.stage,
        amount_raised_curency: x.currency,
        pre_money_valuation_currency: x.currency,
        date: new Date(x.date).valueOf(),
        pre_money_valuation: x.valuation,
        amount_raised: x.amountRaised,
        company_id: routeParams.companyId,
        notable_investors: {data: x.investors.map(x => ({notable_investor_name: x.name, notable_investor_is_summary: x.isSummary}))}, 
      })),
      company_management: management.map(x => ({ name: x.name, position: x.position, bio: x.description, company_id: routeParams.companyId }))
    }
    updateCompany({ variables: variables }).then(() => {
      if(companyLogo instanceof File) {
        addCompanyLogo(companyLogo, routeParams.companyId, companyUpdatedSuccess, () => hasuraErrorHandler.printError("Error uploading company logo"))
        .then(console.info).catch(console.warn)
      } else {
        companyUpdatedSuccess();
      }
    }).catch(err => {
      console.warn(err);
      setIsSent(true);
      hasuraErrorHandler.printError("Please complete all the required fields");
    })
  }

  const handleSaveCompany = () => {
    if (routeParams.companyId) {
      updateCompanyCall();
    } else {
      addCompanyCall();
    }
  }
  
  useImperativeHandle(ref, () => ({
    saveCompany() {
      handleSaveCompany();
    }
  }));

  if (routeParams && routeParams.companyId && !data) {
    return <Loading fullWidth />
  }

  if (!selectData) {
    return <Loading fullWidth />
  }

  return (
    <div className={[dimensionClasses.containerWidth, dimensionClasses.headerMarginFix].join(' ')} style={{ padding: "0px 40px" }}>
      <div className={[dimensionClasses.marginTop24, dimensionClasses.width100Percent, alignmentClasses.flexWrap, alignmentClasses.flexAlignCenter].join(' ')}>
        <TextInput initialValue={name} error={isSent && !name} handleChange={setName} customStyle={{ minWidth: "305px", height: "60px" }} label="Company name" placeholder="eg. SpaceX"/>
        <Button variant="outlined" color="primary" style={{ height: "36px", alignSelf: "flex-end", marginLeft: "32px" }} component="label"><input type="file" hidden accept='image/*' onChange={event => setCompanyLogo(event.target.files[0])}/>Attach company logo</Button>
        <span style={{marginBottom: "-16px", marginLeft: "8px"}}>{companyLogo && companyLogo.name}</span>
      </div>
      <div className={[dimensionClasses.marginTop24, dimensionClasses.width100Percent, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
        <TextInput initialValue={foundingYear} error={isSent && !foundingYear} handleChange={setFoundingYear} isNumber customStyle={{ minWidth: "305px", height: "60px" }} label="Founded year" style={"display: none;"} />
        <div style={{ minWidth: "305px", height: "60px", }}>
          <p className={[typografyClasses.fontSize14, dimensionClasses.marginTop0, dimensionClasses.marginBottom8].join(' ')} style={{ color: "#796E6E" }}>Headquarter Country*</p>
          <Select
            error={!hqCountry && isSent}
            variant="outlined"
            className={[dimensionClasses.mediumHeightm, inputClasses.singleSelect, !hqCountry && isSent ? inputClasses.singleSelectError: ""].join(' ')}
            style={{ minWidth: "302px" }}
            value={hqCountry}
            onChange={e => setHqCountry(e.target.value)}
            MenuProps={{ variant: "menu", style: { marginTop: "44px" }, disableScrollLock: true }}>
            {selectData.ENUM_countries.map((x, index) => (<MenuItem key={index} value={x.value}>{x.description}</MenuItem>))}
          </Select>
        </div>

        <TextInput initialValue={hqState} error={isSent && !hqState} handleChange={setHqState} customStyle={{ minWidth: "305px", height: "60px" }} label="Headquarter State" />
      </div>
      <div className={[dimensionClasses.marginTop24, dimensionClasses.width100Percent, alignmentClasses.flex].join(' ')} style={{ alignItems: "flex-start" }}>
        <TextInput initialValue={hqCity} error={isSent && !hqCity} handleChange={setHqCity} customStyle={{ minWidth: "305px", height: "60px" }} label="Headquarter City" />
        <div className={dimensionClasses.marginLeft32} style={{ minWidth: "305px", height: "60px"}}>
          <p className={[typografyClasses.fontSize14, dimensionClasses.marginTop0, dimensionClasses.marginBottom8].join(' ')} style={{ color: "#796E6E" }}>Funding Stage*</p>
          <Select
            error={!fundingStage && isSent}
            variant="outlined"
            className={[dimensionClasses.mediumHeightm, inputClasses.singleSelect, !fundingStage && isSent ? inputClasses.singleSelectError: ""].join(' ')}
            style={{ minWidth: "305px" }}
            value={fundingStage}
            onChange={e => setFundingStage(e.target.value)}
            MenuProps={{ variant: "menu", style: { marginTop: "44px" }, disableScrollLock: true }}>
            {selectData.ENUM_funding_stage.map((x, index) => (<MenuItem key={index} value={x.value}>{x.description}</MenuItem>))}
          </Select>
        </div>
      </div>

      <div className={[dimensionClasses.marginTop24, dimensionClasses.width100Percent, alignmentClasses.flexCenterSpaceBetween].join(' ')} style={{ alignItems: "flex-start" }}>
        <div style={{ width: "305px" }}>
          <p className={[typografyClasses.fontSize14, dimensionClasses.marginTop0, dimensionClasses.marginBottom8].join(' ')} style={{ color: "#796E6E" }}>Sectors</p>
          <MultipleSelectField
            optionsInput={selectData ? selectData.ENUM_interested_sectors : []}
            inputFieldOptionalStyle={{ maxWidth: "100%" }}
            defaultValues={sectors}
            handleChange={setSectors} />
        </div>
        <div style={{ width: "305px" }}>
          <p className={[typografyClasses.fontSize14, dimensionClasses.marginTop0, dimensionClasses.marginBottom8].join(' ')} style={{ color: "#796E6E" }}>Inovation Enablers</p>
          <MultipleSelectField
            optionsInput={selectData ? selectData.ENUM_innovation_enablers : []}
            inputFieldOptionalStyle={{ maxWidth: "100%" }}
            defaultValues={inovationEnablers}
            handleChange={setInovationEnablers} />
        </div>
        <div style={{ minWidth: "305px" }}>
          <p className={[typografyClasses.fontSize14, dimensionClasses.marginTop0, dimensionClasses.marginBottom8].join(' ')} style={{ color: "#796E6E" }}>Business Model*</p>
          <Select
            error={!bussinessModel && isSent}
            variant="outlined"
            className={[dimensionClasses.mediumHeight, inputClasses.singleSelect, !bussinessModel && isSent ? inputClasses.singleSelectError: ""].join(' ')}
            style={{ minWidth: "305px" }}
            value={bussinessModel}
            onChange={e => setBussinessModel(e.target.value)}
            MenuProps={{ variant: "menu", style: { marginTop: "44px" }, disableScrollLock: true }}>
            {selectData.ENUM_business_model.map((x, index) => (<MenuItem key={index} value={x.value}>{x.description}</MenuItem>))}
          </Select>
        </div>
      </div>

      <div className={[dimensionClasses.marginTop24, dimensionClasses.width100Percent, alignmentClasses.flexCenterSpaceBetween].join(' ')} style={{ alignItems: "flex-start" }}>
        <div style={{ minWidth: "305px" }}>
          <p className={[typografyClasses.fontSize14, dimensionClasses.marginTop0, dimensionClasses.marginBottom8].join(' ')} style={{ color: "#796E6E" }}>Company Stage*</p>
          <Select
            error={!companyStage && isSent}
            variant="outlined"
            className={[dimensionClasses.mediumHeight, inputClasses.singleSelect, !companyStage && isSent ? inputClasses.singleSelectError: ""].join(' ')}
            style={{ minWidth: "305px" }}
            value={companyStage}
            onChange={e => setCompanyStage(e.target.value)}
            MenuProps={{ variant: "menu", style: { marginTop: "44px" }, disableScrollLock: true }}>
            {selectData.ENUM_company_stage.map((x, index) => (<MenuItem key={index} value={x.value}>{x.description}</MenuItem>))}
          </Select>
        </div>
        <TextInput initialValue={lastValuation} error={isSent && !lastValuation} handleChange={setLastValuation} isNumber isMoneySum customStyle={{ minWidth: "305px", height: "60px" }} label="Last Valuation*" style={"display: none;"} />
     
        <TextInput initialValue={entityType} error={isSent && !entityType} handleChange={setEntityType} customStyle={{ minWidth: "305px", height: "60px" }} label="Entity Type" />
      </div>

      <div className={[dimensionClasses.marginTop24, dimensionClasses.width100Percent, alignmentClasses.flexWrap, alignmentClasses.flexAlignCenter].join(' ')}>
        <TextInput initialValue={summary} error={isSent && !summary} handleChange={setSummary} customStyle={{ width: "100%", height: "60px" }} label="Company Summary (max characters 180)" />
      </div>

      <div className={[inputClasses.textAreaContainer, dimensionClasses.marginTop32].join(' ')}>
        <p className={typografyClasses.fontSize14} style={{ color: "#796E6E" }}>Company Description</p>
        <textarea style={{ maxWidth: "100%", fontFamily: 'Roboto' }} className={typografyClasses.fontSize16} value={description} onChange={e => setDescription(e.target.value)} />
      </div>

      <AddFundingHistory initialValue={fundingHistory} error={isSent && !fundingHistory} financingRounds={selectData.ENUM_financing_round} handleNewFundingRound={setFundingHistory} />
      <AddManagement initialValue={management} error={isSent && !management} handleNewManagement={setManagement} />
      <Button variant="contained" color="primary" className={[dimensionClasses.marginTop20, dimensionClasses.smallHeigth, dimensionClasses.marginbottom50, alignmentClasses.floatRight].join(' ')} onClick={handleSaveCompany}>Save Company</Button>
    </div>
  )
});

export default AddCompany;