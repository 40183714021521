import { Button, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close';
import { hasuraErrorHandler } from '../EventEmitter/EventEmitter';
import { handleDownloadPreviewFile, handleDownloadSavedFile } from '../../utils/file-queries';

export const fileTypeOptions = {
  PDF: "application/pdf",
  IMAGE: "image/*",
  ALL: "*",
}

const styles = makeStyles({
  dropzone: {
    textAlign: "center",
    padding: "40px",
    margin: "0px",
    background: "#F9F9F9",
    border: "1px dashed #D8CECE",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  button: {
    height: "30px",
    marginLeft: "8px"
  },
  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "15px",
    color: "#796E6E",
  },
  closeIcon: {
    width: "16px",
    height: "16px",
  },
  removeButtton: {
    marginLeft: "8px",
    padding: "0px",
  },
  filesContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  fileContainer: {
    marginTop: "8px",
    padding: "4px 8px",
    background: "#F4F4F4",
    borderRadius: "2px",
    marginRight: "8px",
    cursor: "pointer",
  },
  fileName: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "11px",
    lineHeight: "13px",
    color: "#796E6E",
  }
})
const UploadComponent = ({ title,  setNewFiles, initialFiles = [], customStyle = {}, multiple=true, disabled, deleteCallback, downloadRoute, label="Drag and drop documents here or", acceptedFileType = fileTypeOptions.ALL}) => {
  const classes = styles();
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleUploadFiles = (files) => {
    if (!files || !files[0]) return;
    const tooLargeFiles = [];
    const filesToUpload = [];
    for(let key of Object.keys(files)) {
      if(files[key].size >= 52428800) {
        tooLargeFiles.push(files[key].name);
      } else {
        filesToUpload.push(files[key])
      }
    }
    if(tooLargeFiles.length > 0) {
      hasuraErrorHandler.printError(`${tooLargeFiles.join(', ')} ${tooLargeFiles.length === 1 ? "is": "are"} too large, upload aborted`)
    }
    const newFiles = multiple ? [...uploadedFiles, ...filesToUpload] : [filesToUpload[0] || null];
    setUploadedFiles(newFiles);
    if(setNewFiles) {
      setNewFiles(newFiles);
    }
  }

  const handleRemoveFile = (index) => {
    if(index >= 0 && index < uploadedFiles.length) {
      setUploadedFiles(uploadedFiles.filter((x, no) => no !== index));
    }
    if(setNewFiles) {
      setNewFiles(uploadedFiles.filter((x, no) => no !== index));
    }
    const fileIdToDelete = uploadedFiles.filter((file, no) => no === index && !(file instanceof File))[0]?.id;
    if(fileIdToDelete && deleteCallback) {
      deleteCallback(fileIdToDelete);
    }
  } 

  useEffect(() => {
    if(initialFiles.length > 0) {
      setUploadedFiles(multiple ? initialFiles : [initialFiles[0]])
    }
  }, [initialFiles, multiple])

  const handleDownloadFile = (file) => {
    if(downloadRoute) {
        if(file instanceof File) {
          handleDownloadPreviewFile(file) 
        } else {
          handleDownloadSavedFile(`${downloadRoute}/documents/${file.id}`, file.name)
        } 
    }
  }
  return (
    <div style={customStyle}>
      <p>{title}</p>
      <div >
        <Dropzone noClick onDrop={acceptedFiles => handleUploadFiles(acceptedFiles)} multiple={multiple} disabled={disabled} accept={acceptedFileType}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p className={classes.dropzone}>
                  <span className={classes.text}>{label}</span>
                  <Button variant="contained" color="primary" className={classes.button} component="label"  disabled={disabled}>
                    <input type="file" hidden multiple={multiple} onChange={file => handleUploadFiles(file.target.files)} accept={acceptedFileType}/>Upload
                  </Button>
                </p>
              </div>
            </section>
          )}
        </Dropzone>
        <div className={classes.filesContainer}>
          {uploadedFiles.map((x, index) =>
            <div key={index} className={classes.fileContainer} onClick={() => handleDownloadFile(x)}>
              <span className={classes.fileName}>{x.name}</span>
              <IconButton className={classes.removeButtton} onClick={(e) => {
                e.stopPropagation();
                handleRemoveFile(index)
                }}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UploadComponent;