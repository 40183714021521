import { useQuery } from '@apollo/client';
import React, { Fragment } from 'react';
import { generalStyleClasses } from '../../../../../utils/general-styles';
import ManagementLabeledText from './ManagementLabeledText';
import { GET_COMPANY_MANAGEMENT_BY_ID } from '../../../../queries/preview-queries';

const CompanyManagement = ({ companyId }) => {
  const generalClasses = generalStyleClasses();
  const { data, loading } = useQuery(GET_COMPANY_MANAGEMENT_BY_ID, { variables: { id: companyId } });

  if (loading) return <div />

  return (
    <Fragment>
      <p className={[generalClasses.marginTop64, generalClasses.fontSize18, generalClasses.fontColorDark, generalClasses.marginBottom0].join(' ')}>Management</p>
      <div className={generalClasses.flexWrap}>
        {
          data.Company_by_pk.company_managements.map((x, index) => <ManagementLabeledText key={index} label={x.name} text={x.position} customStyles={{width: "33%", marginTop: "24px"}}/>)
        }
      </div>
    </Fragment>
  )
}

export default CompanyManagement;