import React, { Fragment, useEffect, useState } from 'react';
import CalendarTodaySharpIcon from '@material-ui/icons/CalendarTodaySharp';
import { makeStyles } from '@material-ui/core';
import { generalStyleClasses } from '../../../../../../utils/general-styles';
import { useQuery } from '@apollo/client';
import { GET_DEAL_TITLE_SECTION } from '../../../../../../utils/deal-queries';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { formatDateDayMonthYear, formatDateMonthYear, formatValue } from '../../../../../../utils/formats';
import { useSelector } from 'react-redux';
import { fileDownloader } from '../../../../../../utils/file-queries';
import Loading from '../../../../../../shared/Loading/Loading';

const styles = makeStyles({
  logoImage: {
    width: "60px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
  },
  container: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    padding: "40px",
  },
  companyTitleContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "16px",
    justifyContent: "space-around",
    flexGrow: "1",
  },
  dealStatusSpan: {
    borderRadius: "24px",
    color: "#FFFFFF",
    padding: "3px 6px",
    marginLeft: "12px",
  },
  prospectiveDealBackground: {
    background: "#86B4CE",
  },
  activeDealBackground: {
    background: "#9FBF85",
  },
  closedDealBackground: {
    background: "#C16F6F",
  },
  companyDetails: {
    margin: "0",
    "span:last-of-type": {
      marginLeft: "16px",
    }
  }
})

const CompanyTitleSection = ({ opportunityId }) => {
  const classes = styles();
  const generalClasses = generalStyleClasses();
  const [companyLogo, setCompanyLogo] = useState("");
  const { data, loading } = useQuery(GET_DEAL_TITLE_SECTION, { variables: { id: opportunityId } })
  const [imageLoading, setImageLoading] = useState(true)
  useEffect(() => {
    if(data && data.Deal_by_pk && data.Deal_by_pk.company && data.Deal_by_pk.company.logo) {
      const image = fileDownloader(`company/logo/${data.Deal_by_pk.company.logo}`);
      image.then(response => {
        setImageLoading(false);
        setCompanyLogo(URL.createObjectURL(response))
      });
    }
  }, [data])
  if (loading || !data || !data.Deal_by_pk) return <div />

  return (
    <div className={generalClasses.flex}>
      {
          imageLoading
          ? <Loading customStyle={{position: "relative",height: "52px", width: "60px",  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)", transform: "scale(0.5)"}}/>
          : <img src={companyLogo || `${process.env.PUBLIC_URL}/images/no-image.png`} alt="logo" className={classes.logoImage} />
        }
       
      
      <div className={classes.companyTitleContainer}>
        <div >
          <span className={[generalClasses.fontSize20, generalClasses.fontBold].join(' ')}>{data.Deal_by_pk.company.name} {data.Deal_by_pk.title ? ` - ${data.Deal_by_pk.title}` : ""}</span>
          <span className={[
            classes.dealStatusSpan,
            generalClasses.fontSize14,
            data.Deal_by_pk.status === 'ACTIVE' ? classes.activeDealBackground :
              data.Deal_by_pk.status === 'PROSPECTIVE' ? classes.prospectiveDealBackground :
                classes.closedDealBackground].join(' ')}>
            {data.Deal_by_pk.status === 'ACTIVE' ? "Open" : data.Deal_by_pk.status === 'PROSPECTIVE' ? "Prospective" : "Closed"}
          </span>
        </div>
        <p className={classes.companyDetails}>
          <span>
            {`${data.Deal_by_pk.company.hq_city} ${data.Deal_by_pk.company.hq_state} ${data.Deal_by_pk.company.hq_country}`}
          </span>
          <span style={{ marginLeft: "16px" }}>
            Founded in {data.Deal_by_pk.company.founding_year}
          </span>
        </p>
      </div>
      {
        data.Deal_by_pk.status === 'PROSPECTIVE'
          ? (
            <div className={[generalClasses.flexAlignCenter, generalClasses.fontInputColor].join(' ')}>
              <CalendarTodaySharpIcon />
              <span style={{ marginLeft: "16px", whiteSpace: "pre" }}>
                {
                  data.Deal_by_pk.opening_date 
                  ? `Opens in ${formatDateMonthYear(new Date(data.Deal_by_pk.opening_date))}`
                  : "Date unavalabile"
             
              }</span>
            </div>
          )
          : data.Deal_by_pk.status === 'ACTIVE'
            ? (
              <Fragment>
                <div className={generalClasses.flexAlignCenter} style={{color: '#518149'}}>
                  <AttachMoneyIcon />
                  <span style={{ marginLeft: "12px", whiteSpace: "pre" }}>
                    {
                      data.Deal_by_pk.investment_amount_available
                      ? `${formatValue(data.Deal_by_pk.investment_amount_available)} Available`
                      : (
                        data.Deal_by_pk.investment_value
                        ? `${formatValue(data.Deal_by_pk.investment_value-data.Deal_by_pk.Investments.map(x => x.value).reduce((a, b) => a+b, 0))} Available`
                        : "Amount unavalabile"
                      )
                    }
                  </span>
                </div>
                <div className={generalClasses.flexAlignCenter} style={{marginLeft: "28px", color: "#9D5050"}}>
                  <CalendarTodaySharpIcon />
                  <span style={{ marginLeft: "12px", whiteSpace: "pre", color: "#9D5050" }}>
                    {
                      data.Deal_by_pk.opening_date 
                      ? `Closes on ${formatDateDayMonthYear(new Date(data.Deal_by_pk.opening_date))}`
                      : "Date unavalabile"
                    }</span>
                </div>
              </Fragment>
            )
            : <div className={generalClasses.flexAlignCenter} style={{marginLeft: "28px", color: "#9D5050"}}>
            <CalendarTodaySharpIcon />
            <span style={{ marginLeft: "12px", whiteSpace: "pre", color: "#9D5050" }}>
              {
                data.Deal_by_pk.opening_date 
                ? `Closed in ${formatDateMonthYear(data.Deal_by_pk.opening_date)}`
                : "Date unavalabile"
              }</span>
          </div>
      }

    </div>
  )
}

export default CompanyTitleSection;