import React, {  useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = makeStyles({
    disableAdorament: {
        "& .MuiAutocomplete-clearIndicator": {
            visibility: "hidden !important",
        },
    },
    showAdorament: {
        "& .MuiAutocomplete-clearIndicator": {
            visibility: "visible !important",
        },
    }
})

export default function MultipleSelectInputField({ label = "", options = [], selectNewState, optionalStyle = {}, isDynamic = false }) {
    const [value, setValue] = useState('');
    const classes = styles();

    return (
        <Autocomplete
            style={{ height: '40px', background: '#ffffff', borderRadius: "4px", ...optionalStyle }}
            filterOptions={(options, params) => {
                if (params.inputValue !== '' && isDynamic) {
                    options.push({
                      inputValue: params.inputValue,
                      description: params.inputValue,
                      value: params.inputValue,
                    });
                  }
                return options.filter(x => x.description.toLowerCase().indexOf(params.inputValue.toLowerCase()) !== -1)
            }}
            clearOnEscape
            inputValue={value}
            options={options}
            onChange={(_, value) => {
                if(value) {
                    selectNewState(value);
                    setValue("")
                }

            }}
            closeIcon={<CloseIcon fontSize="small" onClick={() => setValue("")}/>}
            onInputCapture={event => {
                if (!(event instanceof Event)) {
                    // options.push({description: `${event.target.value}`, value: event.target.value})
                    setValue(event.target.value)
                } else {
                    setValue("")
                }
            }}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                if (option.inputValue) {
                    return option.inputValue;
                }
                return option.description;
            }}
            renderOption={
                option =>
                    <div
                        style={{ width: '100%', height: '100%', padding: '15px 20px' }}
                        onClick={() => selectNewState(option)}>
                        {option.description}
                    </div>
            }
            renderInput={(params) => (
                <TextField {...params}
                    placeholder={label}
                    variant="outlined"
                    className={value ? classes.showAdorament : classes.disableAdorament}
                    style={{ border: '1px solid #D8CECE !important' }} />
            )}
        />
    );
}