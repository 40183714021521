import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generalStyleClasses } from '../../utils/general-styles';
import RadioButtonsGroup from '../RadioButtonsGroup/RadioButtonsGroup';
import { investmentStatus } from './Options/InvestmentOptions';

const InvestmentStatus = ({ handleChange, rootClasses = {} }) => {
  const classes = generalStyleClasses();
  const defaultValue = useSelector(store => store.investorProfile.investment_status);
 
  useEffect(() => {
    if(defaultValue && defaultValue > 0) {
      handleChange(defaultValue);
    }
  }, [])

  return (
    <div className={rootClasses}>
      <span className={classes.defaultFont}>Investment Status</span>
      <div className={classes.marginTop16}>
        <RadioButtonsGroup options={investmentStatus} handleChange={handleChange} defaultValue={defaultValue}/>
      </div>
    </div>
  )
}

export default InvestmentStatus;