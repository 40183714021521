import { makeStyles } from '@material-ui/core/styles';

export const opportunityCardStyles = makeStyles({
  cardContainer: {
    background: '#FFFFFF',
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    width: '890px',
    marginBottom: '25px',
    padding: '24px',
    boxSizing: 'border-box'
  },
  cardTitleImage: {  
      width: '60px',
  },
  companyTitle: {
    flexGrow: '1',
    height: '60px',
    marginLeft: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    "& p": {
      "&:first-of-type": {
        display: 'flex',      
        "& span:last-of-type": {
          padding: '3px 6px',                   
          borderRadius: '24px',
          color: '#FFFFFF',
          marginLeft: '10px',
        }
      },    
    }
  },
  openSpanBackground: {
    background: '#9FBF85',
  },
  prospectiveSpanBackground: {
    background: '#86B4CE',
  },
  closedSpanBackground: {
    background: '#C16F6F',
  },
  cardBodyParagraphColor: {
      color: '#484642',    
  },
  addToWatchList: {   
    textTransform: 'none',
    "& svg": {
      marginLeft: '16px',
    }
  },
  cardBodyInfos: {
    display: 'flex',
  },
  notableInvestorsText: {
    width: '480px',
  },
  proposedValuationText: {
    width: '180px',
  },
  avalabileSumInfo: {
    color: '#518149',
    display: 'flex',
    "& svg": {
      fill: "#76A06F",
      marginRight: '12px',
    }
  },
  dateInfo: {
    display: 'flex',
    color: '#9D5050',
    flexGrow: '1',
    marginLeft: '28px',
    "& svg": {
      color: "#C16F6F",
      marginRight: '12px',
    }
  },
  prospectiveDateInfo: {
    display: 'flex',
    color: '#505154',
    flexGrow: '1',
    "& svg": {
      color: "#796E6E",
      marginRight: '12px',
    }
  }
})