import { useQuery } from '@apollo/client';
import React, { Fragment, useEffect } from 'react';
import { GET_ALL_COMPANIES, GET_ALL_FLOWS } from '../../queries/admin-queries';
import { adminAlignmentStyles, adminDimensionsStyle, adminInputStyles, adminTypografyStyles } from '../../AdminStyles';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Select } from '@material-ui/core';
import Loading from '../../../shared/Loading/Loading';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { adminOpportunityActions } from '../../../store/slices/adminOpportunity';

const AddOpportunitySections = (props, ref) => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const typografyClasses = adminTypografyStyles();
  const inputClasses = adminInputStyles();
  const { data, loading } = useQuery(GET_ALL_COMPANIES);
  const flowData = useQuery(GET_ALL_FLOWS);
  const disaptch = useDispatch();
  const { selectedOpportunity, resultingOpportunity } = useSelector(state => state.adminOpportunity);
  const {
    companyId: selectedCompanyId,
    type: opportunityType,
    flowId: selectedFlowId,
    isSent,
  } = resultingOpportunity || {};
  const {
    id: opportunityId
  } = selectedOpportunity || {};

  const handleChangeType = (type) => {
    disaptch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({ type: type }));
  }

  const handleSelectNewCompany = (companyId) => {
    disaptch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({ companyId: companyId }));
  }

  const handleSelectNewFlow = (flowId, flowName) => {
    disaptch(adminOpportunityActions.UPDATE_OPPORTUNITY_FIELDS({ flowId: flowId, flowName: flowName }));
  }

  if (loading || !data) {
    return <Loading fullWidth />
  }

  return (
    <Fragment>
      <div className={[dimensionClasses.containerWidth, alignmentClasses.flex, dimensionClasses.headerMarginFix].join(' ')} style={{ alignItems: "flex-end", borderBottom: "1px solid #EBEBEB", paddingBottom: "32px" }}>
        <div>
          <p className={[typografyClasses.fontSize14, dimensionClasses.marginBottom8, dimensionClasses.marginTop8].join(' ')} style={{ color: "#333333" }}>Select company*</p>
          <FormControl variant="outlined" >
            <Select
              error={isSent && !selectedCompanyId}
              disabled={props.disableSelectCompany}
              className={[dimensionClasses.mediumHeight, inputClasses.singleSelect].join(' ')}
              style={{ width: "280px" }}
              color="primary"
              value={selectedCompanyId}
              onChange={e => handleSelectNewCompany(e.target.value)}
              MenuProps={{ variant: "menu", disableScrollLock: true }}>
              {data.Company.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        <div style={{ marginLeft: "20px" }}>
          <p className={[typografyClasses.fontSize14, dimensionClasses.marginBottom0, dimensionClasses.marginTop8].join(' ')} style={{ color: "#333333" }}>
            Select investment flow*
            <IconButton disabled={!selectedFlowId || !opportunityId} className={[dimensionClasses.padding0, dimensionClasses.marginLeft16].join(' ')} component={Link} to={`/admin/investment/edit-deal-flow/${selectedFlowId }/${opportunityId}`}>
              <EditIcon />
            </IconButton>
          </p>
          <FormControl variant="outlined" >
            <Select
              error={isSent && !selectedCompanyId}
              className={[dimensionClasses.mediumHeight, inputClasses.singleSelect].join(' ')}
              style={{ width: "280px" }}
              color="primary"
              value={selectedFlowId}
              onChange={e => handleSelectNewFlow(e.target.value, e.currentTarget.dataset.name)}
              MenuProps={{ variant: "menu", disableScrollLock: true }}>
              {
                flowData.data
                  ? flowData.data.Flow_node.map(x => <MenuItem key={x.id} data-name={x.name} value={x.id}>{x.name}</MenuItem>)
                  : ""
              }
            </Select>
          </FormControl>
        </div>
        <div className={dimensionClasses.marginLeft40} style={{ marginBottom: "-6px" }}>
        <p className={[typografyClasses.fontSize14].join(' ')} style={{ color: "#333333", marginBottom: "2px" }}>Status*</p>
          <RadioGroup row aria-label="position" name="position" defaultValue="top" value={opportunityType} onChange={e => handleChangeType(e.target.value)}>
            <FormControlLabel
              value="PROSPECTIVE"
              control={<Radio color="primary" style={{ color: isSent && !opportunityType ? "#f44336" : "" }} />}
              label="Prospective"
            />
            <FormControlLabel
              value="ACTIVE"
              control={<Radio color="primary" style={{ color: isSent && !opportunityType ? "#f44336" : "" }} />}
              label="Active"
            />
            <FormControlLabel
              value="CLOSED"
              control={<Radio color="primary" style={{ color: isSent && !opportunityType ? "#f44336" : "" }} />}
              label="Closed"
            />
          </RadioGroup>
        </div>
      </div>

    </Fragment>
  )
}

export default AddOpportunitySections;