export const IndustrySectorOptions = [
  {text: "Not Relevant", value: "Not Relevant"},
  {text: "Small market", value: "Small market"},
  {text: "Limited growth prospect", value: "Limited growth prospect"},
  {text: "Intense competition", value: "Intense competition"},
  {text: "Other", value: "Other"},
]

export const CompanyOptions = [
  {text: "To early stage", value: "To early stage"},
  {text: "Unclear competitive advantage", value: "Unclear competitive advantage"},
  {text: "Uncompelling business model", value: "Uncompelling business model"},
  {text: "Limited ability to scale", value: "Limited ability to scale"},
  {text: "Management team", value: "Management team"},
  {text: "Other", value: "Other"},
]

export const InvestmentOptions = [
  {text: "Unattractive valuation", value: "Unattractive valuation"},
  {text: "Types of securities", value: "Types of securities"},
  {text: "Short timeline", value: "Short timeline"},
  {text: "High Fees", value: "High Fees"},
  {text: "Other", value: "Other"},
]