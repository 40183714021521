import { useQuery } from '@apollo/client'
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { GET_NOT_INTERESTED_FEEDBACK } from '../queries/admin-queries';
import { mapFeedback } from './utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from '../AdminStyles';
import BarChart from '../../shared/BarChart/BarChart';
import AnalyticsGraph from './AnalyticsGraph';

const customStyles = makeStyles({
  spacer: {
    borderLeft: "1px solid #ebebeb",
    width: "1px",   
  },
  feedbackSpacer: {
    borderLeft: "1px solid #ebebeb",
    width: "1px",
    margin: "0px 24px",
  }

})

const FeedbackPanel = () => {
  const { data } = useQuery(GET_NOT_INTERESTED_FEEDBACK, { fetchPolicy: 'network-only' });
  const [mappedData, setMappeData] = useState([])
  const typografyClasses = adminTypografyStyles();
  const customClasses = customStyles();

  useEffect(() => {
    if (data) {
      const mapped = mapFeedback(data.Company);
      setMappeData(mapped)
    }
  }, [data])

  return (
    <div>
      {
        mappedData.map((company, companyIndex) => (
          <Accordion key={companyIndex}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ margin: 0, padding: 0, paddingLeft: "4px", minHeight: "none" }}>
              <p style={{ margin: 0 }} className={[typografyClasses.fontSize14, typografyClasses.fontColor5B5B5B].join(' ')}>Company: {company.companyName}</p>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0, display: "flex", flexDirection: "column" }}>
              {
                company.deals.map((deal, dealIndex) => (
                  <Accordion key={dealIndex}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ margin: 0, minHeight: "none", paddingRight: 0 }}>
                      <p style={{ margin: 0 }} className={[typografyClasses.fontSize14, typografyClasses.fontColor5B5B5B].join(' ')}>Deal: {deal.dealName}</p>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexWrap: "wrap", justifyContent: "space-between" }}>
                      <AnalyticsGraph isFull label="Company" data={deal.feedbacks.company} />
                      <div className={customClasses.spacer}/>
                      <AnalyticsGraph isFull label="Industry" data={deal.feedbacks.industry} />
                      <div className={customClasses.spacer}/>
                      <AnalyticsGraph isFull label="Investment" data={deal.feedbacks.investment} />
                      {
                        deal.feedbacks.other.filter(x => x).length > 0 && (
                          <>
                            <p className={[typografyClasses.fontbold, typografyClasses.fontSize16].join(' ')} style={{ width: "100%", color: "#494949" }}>Aditional info</p>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {deal.feedbacks.other.filter(x => x).map((x, index) => <div style={{ maxWidth: "30%", display: "flex" }} className={[typografyClasses.fontSize14, typografyClasses.fontColor505154].join(' ')}>
                                {index !== 0 && <div className={customClasses.feedbackSpacer}/>}
                                {x}
                              </div>)}
                            </div>
                          </>
                        )
                      }
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </AccordionDetails>
          </Accordion>
        ))
      }

    </div >
  )
}

export default FeedbackPanel