import { makeStyles } from '@material-ui/core/styles';

export const adminStyles = makeStyles({
  headerContainer: {
    background: "#F4F4F4",
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    zIndex: "100"
  },
  header: {
    width: "980px",
    display: "flex",
    boxSizing: "border-box",
    justifyContent: "space-between",
    padding: "10px 0px",
  },
  navLinkButton: {
    background: "white",
    margin: "0px 8px",
    textTransform: "none",
    padding: "0px 16px",
    borderRadius: "5px",
  },
  navLinkActiveButton: {
    background: "#525252",
    color: "white !important",
  },
  searchField: {
    height: "34px",
    background: "white",
  },
  contentContainer: {
  },

});

export const adminTypografyStyles = makeStyles({
  fontSize11: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "11px",
    lineHeight: "13px",
  },
  fontSize12: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
  },
  fontSize13: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "15px",
  },
  fontSize14: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
  },
  fontSize15: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
  fontSize16: {
    fontfamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
  },
  fontColor5B5B5B: {
    color: "#5B5B5B",
  },
  fontColor505154: {
    color: "#505154",
  },
  fontColor78756F: {
    color: "#78756F",
  },
  fontColor78756F: {
    color: "#78756F",
  },
  fontColor7B7B7B: {
    color: "#7B7B7B",
  },
  fontColor747474: {
    color: "#747474"
  },
  fontColor4A4A4A: {
    color: "#4A4A4A"
  },
  fontColor0258A8: {
    color: "#0258A8",
  },
  fontColor373737: {
    color: "#373737"
  },
  fontbold: {
    fontWeight: "500 !important",
  },
  noTrasnform: {
    textTransform: "none",
  }
})
export const adminDimensionsStyle = makeStyles({
  hideOverflow: {
    overflowY: "hidden",
  },
  containerWidth: {
    maxWidth: "980px",
    width: "100%",
  },
  width50: {
    width: "50px",
  },
  width100: {
    width: "100px",
  },
  width150: {
    width: "150px",
  },
  width200: {
    width: "200px",
  },
  width250: {
    width: "250px",
  },
  width100Percent: {
    width: "100%"
  },
  widthGrow: {
    flexGrow: "1",
  },
  marginTop0: {
    marginTop: '0px',
  },
  marginTop8: {
    marginTop: '8px',
  },
  marginTop12: {
    marginTop: '12px',
  },
  marginTop20: {
    marginTop: '20px',
  },
  marginTop16: {
    marginTop: '16px',
  },
  marginTop24: {
    marginTop: '24px',
  },
  marginTop32: {
    marginTop: '32px !important',
  },
  marginTop40: {
    marginTop: '40px',
  },
  marginBottom0: {
    marginBottom: '0px',
  },
  marginBottom4: {
    marginBottom: '4px',
  },
  marginBottom8: {
    marginBottom: '8px',
  },
  marginbottom16: {
    marginBottom: '16px',
  },
  marginbottom24: {
    marginBottom: '24px',
  },
  marginbottom50: {
    marginBottom: '50px',
  },
  marginLeft16: {
    marginLeft: "16px",
  },
  marginLeft20: {
    marginLeft: "20px",
  },
  marginLeft24: {
    marginLeft: "24px",
  },
  marginLeft32: {
    marginLeft: "32px",
  },
  marginLeft40: {
    marginLeft: "40px",
  },
  marginRight50: {
    marginRight: "50px !important",
  },
  marginRight32: {
    marginRight: "32px",
  },
  marginRight12: {
    marginRight: "12px",
  },
  marginRight8: {
    marginRight: "8px",
  },
  padding0: {
    padding: "0",
  },
  padding8: {
    padding: "8",
  },
  paddingBottom16: {
    paddingBottom: "16px !important",
  },
  smallHeigth: {
    height: "32px",
  },
  mediumHeight: {
    height: "36px",
  },
  bigHeigth: {
    height: "40px",
  },  
  headerMarginFix: {
    marginTop: "64px",
  }
});
export const adminAlignmentStyles = makeStyles({
  floatRight: {
    float: "right",
  },
  flex: {
    display: "flex",
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  flexJustifyStart: {
    display: "flex",
    justifyContent: "flex-start",
  },
  flexCenterSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center"
  },
  flexAlignCenterOncolumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalTitle: {
    "& h2": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& button": {
        marginRight: "-10px",
      }
    }
  },
});
export const adminTablesStyle = makeStyles({
  tableHeader: {
    paddingBottom: "8px",
    borderBottom: "1px solid #CFCFCF",
    color: "#7B7B7B",
    "& p": {
      margin: "0",
    }
  },
  row: {
    width: "980px",
    color: "#5B5B5B",
    borderBottom: "1px solid #EBEBEB",
    "& p": {
      margin: "12px 0px",
    }
  }
});
export const adminInputStyles = makeStyles({
  select: {
    padding: "0px 12px",
    color: "#959595",
    border: "1px solid #D4D4D4",
    borderRadius: "6px",
    "&:before": {
      border: "none !important",
    },
    "&:hover": {
      borderColor: "black",
    },
    "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiInput-input": {
      background: "none",
    }
  },

  singleSelect: {
    "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
      padding: "9px 12px",
    },
   
  },

  singleSelectError: {
    "& fieldset.PrivateNotchedOutline-root-125.MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important",
    },
  },
  
  whiteButton: {
    border: "1px solid #C9C9C9",
    background: "white",
  },
  textAreaContainer: {
    width: "100%",
    "& p": {
      color: "#333333",
    },
    "& textarea": {
      maxWidht: "100%",
      minHeight: "100px",
      width: "100%",
      border: "1px solid #D4D4D4",
      borderRadius: "6px",
    }
  },
  buttonGroup: {
    "& button.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.Mui-selected": {
      background: "#434448",
      color: "#fff"
    }
  },
  input: {
    background: "#FFFFFF",
    border: "1px solid #D4D4D4",
    boxSizing: "border-box",
    borderRadius: "6px",
    height: "36px",
    padding: "0px 12px",
  }
})



