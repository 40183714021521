import React, { useState, Fragment } from 'react';
import TextInput from '../../../shared/TextInput/TextInput';
import { generalStyleClasses } from '../../../utils/general-styles';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { SET_USER_ACCOUNT } from '../../../store/utils/action-types';
import { UPDATE_ACCOUNT_DETAILS } from '../../../utils/account-queries';
import { useMutation, useQuery } from "@apollo/client";
import firebase from 'firebase';
import { EventThrower, Events } from '../../../shared/EventEmitter/EventEmitter';
import './account.scss';
import { isAlpha, isPhone } from '../../../utils/validators';
import { MenuItem, Select } from '@material-ui/core';
import { COUNTRIES } from '../../../utils/enum-queries';

const PersonalInformation = () => {
  const dispatch = useDispatch();
  const [updatePersonalInformation] = useMutation(UPDATE_ACCOUNT_DETAILS);
  const countriesData = useQuery(COUNTRIES).data;
  const user = useSelector(state => state.account.user);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.email);
  const [country, setCountry] = useState(user.country);
  const [city, setCity] = useState(user.city);
  const [phone, setPhone] = useState(user.phone_number);
  const [phoneCountry, setPhoneCountry] = useState('US');
  const classes = generalStyleClasses();

  const handleSaveChanges = () => {
    if (city === "" || !isAlpha(city)) {
      EventThrower.throwClientSideError(Events.FieldValidationError("city"));
      return;
    }
    if (firstName === "" || !isAlpha(firstName)) {
      EventThrower.throwClientSideError(Events.FieldValidationError("firstName"));
      return;
    }
    if (lastName === "" || !isAlpha(lastName)) {
      EventThrower.throwClientSideError(Events.FieldValidationError("lastName"));
      return;
    }
    if (email === "") {
      EventThrower.throwClientSideError(Events.FieldValidationError("email"));
      return;
    }
    if (country === "" || !isAlpha(country)) {
      EventThrower.throwClientSideError(Events.FieldValidationError("country"));
      return;
    }
    if (phone === "" || !isPhone(phone)) {
      EventThrower.throwClientSideError(Events.FieldValidationError("phone"));
      return;
    }

    updatePersonalInformation({
      variables: {
        userId: user.firebase_id,
        city: city,
        country: country,
        first_name: firstName,
        last_name: lastName,
        phone_number: phone.toString(),
      }
    }).then(result => {
      dispatch({ type: SET_USER_ACCOUNT, payload: result.data.update_Account_by_pk })
      EventThrower.throwClientSideMessage(Events.InformationChangedMessage);
    }).catch(error => {
      console.error(error);
    })
  }

  const sendPasswordResetEmail = () => {
    firebase.auth().sendPasswordResetEmail(user.email).then(function () {
      EventThrower.throwClientSideMessage(Events.PasswordResetEmailSent(user.email));
    }).catch(() => {
      EventThrower.throwClientSideError(Events.PasswordResetError(user.email));
    });
  }

  return (
    <Fragment>
      <p className={[classes.fontSize16, classes.fontColorDark, classes.marginBottom0, classes.marginTop0].join(' ')}>Personal information</p>
      <div className="information-input-group">
        <TextInput error={firstName === "" || !firstName} label="First name" initialValue={firstName} handleChange={value => setFirstName(value)} customStyle={{ width: '310px' }} className={classes.marginTop24} />
        <TextInput error={lastName === "" || !lastName} label="Last name" initialValue={lastName} handleChange={value => setLastName(value)} customStyle={{ width: '310px' }} className={classes.marginTop24} />
        <TextInput error={email === "" || !email} label="Email" initialValue={email} handleChange={value => setEmail(value)} customStyle={{ width: '310px' }} className={classes.marginTop24} />
        <div style={{
          display: "flex",
          width: "310px",
          flexWrap: "wrap",
          alignItems: "center",
        }} className={classes.marginTop24}>
          <p className={[classes.marginBottom8, classes.marginTop0, classes.fontColorLight, classes.fontSize14].join(' ')} style={{width: "100%"}}>Phone</p>
          <Select
            variant="outlined"
            className={classes.simpleDropdown}
            value={phoneCountry}
            style={{ background: "white", height: "36px", width: "60px", marginRight: "8px" }}
            onChange={e => setPhoneCountry(e.target.value)}
            MenuProps={{ variant: "menu", disableScrollLock: true }}>
            {countriesData && countriesData.ENUM_countries ? countriesData.ENUM_countries.map((x, index) => (<MenuItem key={index} value={x.value}>{x.value}</MenuItem>)) : []}
          </Select>
          <TextInput error={phone === "" || !phone} initialValue={phone} isPhone phoneCountry={phoneCountry} handleChange={value => setPhone(value)} customStyle={{ flexGrow: "1", marginTop: "-8px" }}  />
        </div>

        <div className={classes.marginTop24}>
          <p className={[classes.marginBottom8, classes.marginTop0, classes.fontColorLight, classes.fontSize14].join(' ')}>Country</p>
          <Select
            variant="outlined"
            className={classes.simpleDropdown}
            value={country}
            style={{ width: "310px", background: "white", height: "36px" }}
            onChange={e => setCountry(e.target.value)}
            MenuProps={{ variant: "menu", disableScrollLock: true }}>
            {countriesData && countriesData.ENUM_countries ? countriesData.ENUM_countries.map((x, index) => (<MenuItem key={index} value={x.description}>{x.description}</MenuItem>)) : []}
          </Select>
        </div>

        <TextInput error={city === "" || !city} label="City" initialValue={city} handleChange={value => setCity(value)} customStyle={{ width: '310px' }} className={classes.marginTop24} />
      </div>
      <div className={[classes.singleRightButton, classes.marginTop24].join(' ')} style={{ width: '310px' }}>
        <div className={classes.dualLinkButtonsGroup}>
          <Button variant="outlined" color="primary" onClick={sendPasswordResetEmail}>Change password</Button>
          <Button variant="contained" color="primary" onClick={handleSaveChanges}>Save changes</Button>
        </div>
      </div>
    </Fragment>

  )
}

export default PersonalInformation;