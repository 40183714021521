import { makeStyles } from '@material-ui/core/styles';

export const topsideMenuStyles = makeStyles({
  continer: {
    display: 'flex',
    alignItems: 'center',
  },
  menu: {
    display: 'flex',
    marginLeft: '170px',
  },
  menuItem: {
    padding: "20px 30px 16px 30px",  
    color: "#C4C4C4",
    margin: 0,
    
  },
  activeMenuItem: {
    borderBottom: "1px solid #434448",
  }
})