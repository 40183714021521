import { Button, ButtonGroup, LinearProgress, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { adminDimensionsStyle, adminAlignmentStyles, adminTablesStyle, adminTypografyStyles } from '../AdminStyles';

const barStyle = makeStyles({
    bar: {
      background: "#E9E9E9",
      "& div:first-of-type": {
        background: "#5C5C5C"
      }
    }
})

const AnalyticsGraph = ({label, data, isFull = false}) => {
  const dimenstionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const tableClasses = adminTablesStyle();
  const typografyClasses = adminTypografyStyles();
  const barClasses = barStyle();
  const [maxValue, setMaxValue] = useState();
  const [expand, setExpand] = useState(false);
  const [viewMore, setViewMoreValue] = useState(5);

  useEffect(() => {
    setMaxValue(Object.keys(data).filter(x => x !== "maxCount").map(x => data[x].count).reduce((a, b) => Math.max(a, b), 0));
    setViewMoreValue(Math.min(5, Object.keys(data).filter(x => x !== "maxCount").filter(x => data[x].count).length))
  }, [])

  return (
    <div>
      <p className={[typografyClasses.fontbold, typografyClasses.fontSize16].join(' ')} style={{color: "#494949"}}>{label}</p>
      {
        data.maxCount !== 0
        ? Object.keys(data)
        .filter(x => x !== "maxCount")
        .sort((a, b) => -(data[a].count-data[b].count))
        .map((key, index) => {
          const keyData = data[key];
          if(expand || index < viewMore)
            return (
              <div key={index} className={[tableClasses.row,alignmentClasses.flexAlignCenter].join(' ')} style={{width: isFull ? "340px" : "460px"}}>
                <p className={[dimenstionClasses.marginBottom8, dimenstionClasses.marginTop8, dimenstionClasses.width200, typografyClasses.fontSize14].join(' ')}>{keyData.name}</p>
                <p className={[dimenstionClasses.marginBottom8, dimenstionClasses.marginTop8, typografyClasses.fontSize14].join(' ')} style={{width: "20px"}}>{keyData.count}</p>
                <LinearProgress variant="determinate" value={keyData.count * 100 / (isFull ? maxValue : data.maxCount)} className={[dimenstionClasses.widthGrow, barClasses.bar].join(' ')} style={{height: "12px"}} />
              </div>
            )
          return ""
        })
        : "-"
      }
      {
         data.maxCount !== 0 && Object.keys(data).filter(x => x !== "maxCount").length > viewMore 
           && <Button variant="outlined" color="primary" className={[dimenstionClasses.smallHeigth, dimenstionClasses.marginTop20].join(' ')} onClick={() => setExpand(!expand)}>{expand ? "View less" : "View all"}</Button>
      }
    </div>
  )
}

export default AnalyticsGraph;