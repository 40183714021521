const sectionTypes = {
  DOCUSIGN: "DOCUSIGN",
  TEXT: "TEXT",
  UPLOAD: "UPLOAD",
  DOWNLOAD: "DOWNLOAD",
}


export const flowEmptyMock = {
  title: "",
  steps: [],
  id: null,
  investmentCompleted: false,
};

export const flowMock = 
{
    title: "",
    id: null,
    steps: []
}