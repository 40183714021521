import { Button, ClickAwayListener, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import firebase from 'firebase';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { styles } from './AdminNavbarStyle';
import { LOGOUT } from '../../store/utils/action-types';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_ACCOUNT_NAME, GET_NEW_MESSAGES } from '../queries/admin-queries';
import { utilStoreActions } from '../../store/slices/utils';
import { permissionAsignmentsActions } from '../../store/slices/permissions';

const AdminNavbar = () => {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const firebaseId = useSelector(store => store.account.user.firebase_id); 
  const newMessageNumber = useSelector(store => store.utils.newMessageNumber);
  const {data: newMessages} = useSubscription(GET_NEW_MESSAGES, {variables: {employee_id: firebaseId}});
  const {data: accountName} = useQuery(GET_ACCOUNT_NAME, {variables: {firebase_id: firebaseId}, fetchPolicy: "network-only"});
  const [accountSubmenuOpen, setAccountSubmenuOpen] = useState(false);
  const accountAnchorRef = useRef(null);

  const logout = () => {
    firebase.auth().signOut().then(() => {
      dispatch({ type: LOGOUT });
      dispatch(permissionAsignmentsActions.RESET_PERMISSIONS());
      history.push('/admin');
    })
  }

  useEffect(() => {
    if(newMessages) {
      dispatch(utilStoreActions.SET_UTIL_VALUE({name: 'newMessageNumber', value: newMessages.Deal_chat_message.length}));
    }
  }, [newMessages])
  return (
    <div className={classes.root}>
        <img src={`${process.env.PUBLIC_URL}/images/logos/${localStorage.getItem("companyLogo")}`} alt="company logo"/>
        <div className={classes.menuContainer}>
          <NavLink to="/admin/opportunities" className={classes.menuItem} activeClassName={classes.menuItemActive}>Opportunities</NavLink>
          <NavLink to="/admin/clients" className={classes.menuItem} activeClassName={classes.menuItemActive}>Clients</NavLink>
          <NavLink to="/admin/analytics" className={classes.menuItem} activeClassName={classes.menuItemActive}>Analytics</NavLink>
          <NavLink to="/admin/admin" className={classes.menuItem} activeClassName={classes.menuItemActive}>Admin</NavLink>
          <NavLink to="/admin/investment" className={classes.menuItem} activeClassName={classes.menuItemActive}>Investments</NavLink>
          <NavLink to="/admin/chat" className={classes.menuItem} activeClassName={classes.menuItemActive}>
            Chat
            {
              newMessageNumber > 0 && (
                <span style={{background: "#000000", borderRadius: "10px", color: "#ffffff", marginLeft: "8px", padding: "0px 4px"}}>{newMessageNumber}</span>
              )
            }
            </NavLink>
        </div>
        <Button ref={accountAnchorRef} aria-haspopup="true" onClick={() => setAccountSubmenuOpen(!accountSubmenuOpen)}>
            {accountName && accountName.Account_by_pk && `${accountName.Account_by_pk.first_name} ${accountName.Account_by_pk.last_name}`}
            <img src={`${process.env.PUBLIC_URL}/images/account-icon.svg`} alt="account" style={{marginLeft: "16px"}}/>
          </Button>
        <Popper open={accountSubmenuOpen} style={{zIndex: 1000}} anchorEl={accountAnchorRef.current} transition placement="bottom-end">
            <Paper>
              <ClickAwayListener onClickAway={() => setAccountSubmenuOpen(false)}>
                <MenuList autoFocusItem={accountSubmenuOpen} id="menu-list-grow">
                  <MenuItem onClick={() => setAccountSubmenuOpen(false)} component={Link} to="/admin/account">Account & Settings</MenuItem>
                  <MenuItem onClick={() => logout()}>Sign out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
    </div>
  )
}

export default AdminNavbar;