import React, { useEffect, useState } from 'react';
import { adminAlignmentStyles, adminDimensionsStyle, adminTypografyStyles } from '../AdminStyles';
import { useQuery } from '@apollo/client';
import { GET_INVESTOR_GENERAL_ANALYTICS } from '../queries/admin-queries';
import { mapToStatistics } from './utils';
import AnalyticsGraph from './AnalyticsGraph';


const SectorAnalytics = () => {
  const dimensionClasses = adminDimensionsStyle();
  const typographyClasses = adminTypografyStyles();
  const alignmentClasses = adminAlignmentStyles();
  const { data } = useQuery(GET_INVESTOR_GENERAL_ANALYTICS, { fetchPolicy: "network-only" });
  const [statistics, setStatistics] = useState({});


  useEffect(() => {
    if (data && data.Account) {
      setStatistics(mapToStatistics(data))
    }
  }, [data])

  return (
    <div className={[dimensionClasses.headerMarginFix, dimensionClasses.containerWidth].join(' ')}>
       
       <p className={[typographyClasses.fontbold, typographyClasses.fontSize15, dimensionClasses.marginTop24, dimensionClasses.marginBottom0].join(' ')}>Sector and Innovation Preferred:</p>
  
      <div className={[alignmentClasses.flexCenterSpaceBetween, dimensionClasses.marginTop16].join(' ')} style={{ alignItems: "flex-start" }}>
        {
          statistics.preferedSectors
            ? <AnalyticsGraph label="Preferred sectors" data={statistics.preferedSectors} />
            : ""
        }
        {
          statistics.leastPreferedSectors
            ? <AnalyticsGraph label="Least preferred sectors" data={statistics.leastPreferedSectors} />
            : ""
        }
      </div>
      <div className={[alignmentClasses.flexCenterSpaceBetween, dimensionClasses.marginTop40].join(' ')} style={{ alignItems: "flex-start" }}>
        {
          statistics.preferedInovations
            ? <AnalyticsGraph label="Preferred innovation enablers" data={statistics.preferedInovations} />
            : ""
        }
        {
          statistics.leastPreferedInovations
            ? <AnalyticsGraph label="Least preferred innovation enablers" data={statistics.leastPreferedInovations} />
            : ""
        }
      </div>
    </div>
  )
}

export default SectorAnalytics;