import { makeStyles } from '@material-ui/core/styles';


export const oportunitySectionStyle = makeStyles({
  container: {
    background: "#FFFFFF",
    border: "1px solid #E6E6E6",
    boxsizing: "border-box",
    borderRadius: "4px",
    width: "980px",
    marginTop: "16px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E5E5E5",
    padding: "8px 0px",
  },
  headerTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#000000",
  },
  headerSection: {
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "72px",
    height: "34px",
    marginLeft: "10px",
    "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
      padding: "6px 12px",
    }
  },
  content: {
    display: "flex",
    padding: "20px 40px 15px 20px",
  },

  quillEditor: {
    padding: "0px 20px 20px 20px",
    "& .ql-toolbar.ql-snow": {
      border: 'none',
    },
    "& .ql-container.ql-snow": {
      minHeight: "120px",
      border: "1px solid #D4D4D4 !important",
      borderRadius: "6px",
      "& .ql-editor": {
        minHeight: "120px",
      }
    },
    "& .ql-container": {
      fontSize: "16px",
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    }
  },
  managementSection: {
    minWidth: "160px",
    marginRight: "40px",
    maxWidth: "200px"
  },
  noSelectedCompany: {
    paddingTop: "20px",
    paddingBottom: "100px",
    borderBottom: "1px solid #E5E5E5",
    width: "1080px",
    textAlign: "center",
    maxWidth: "100%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#333333",
  }
});