import React from 'react';
import { generalStyleClasses } from '../../../utils/general-styles';
import PersonalInformations from './PersonalInformation';
import EmailPreferances from './EmailPreferances';
import './account.scss';

const Account = () => {
  const classes = generalStyleClasses();

  return (
    <div className="account-root">
      <p className={[classes.fontSize16, classes.fontColorDark].join(' ')}>Account & Settings</p>
      <div className="account-container">
        <PersonalInformations />
        <div className={["breaking-line", classes.marginTop24].join(' ')} />
        <EmailPreferances />
      </div>
    </div>

  )
}

export default Account;