import { gql, useQuery } from '@apollo/client';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import Loading from '../../../../shared/Loading/Loading';
import { SET_DEAL_FILTERS } from '../../../../store/utils/action-types';
import { getDealSummaryQuery } from '../../../../utils/deal-queries';
import { Filters } from './Filters/filterHolder';
import OpportunityCard from './OpportunityCard/OpportunityCard';

const AllOpportuniriesPage = ({handleOpportunitiesNumber = (value) => null}) => {
  const filterString = useSelector(store => store.dealFilter.dealFilterQuery);
  const firebase_id = useSelector(store => store.account.user.firebase_id)
  const { data, loading } = useQuery(gql`${getDealSummaryQuery(filterString)}`, {variables: {firebase_id: firebase_id}, fetchPolicy: "network-only"}); 
  const dispatch = useDispatch();
  const params = useRouteMatch().params;

  useEffect(() => {
    Filters.setDealType(params.type === "all" ? "" : params.type.toUpperCase())
    dispatch({type: SET_DEAL_FILTERS, payload: Filters.getContent()});
  }, [params.type])

  useEffect(() => {
    if(data) {
      handleOpportunitiesNumber(data.Deal.filter(deal => deal.status !== "CANCELED").length)
    }
  }, [data])

  if (loading) {
    return (
      <div style={{display: "flex", justifyContent: "center"}}>
        <Loading />
      </div>
    )
  }

  if (!data || !data.Deal || data.Deal.length === 0) {
    return <p>No deals avalabile of this type</p>
  }

  return (
    <Fragment>
      {data.Deal.filter(deal => deal.status !== "CANCELED" && deal.status !== "VOIDED").map(x => <OpportunityCard key={x.id} deal={x} />)}
    </Fragment>
  )
}

export default AllOpportuniriesPage;