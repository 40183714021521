import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { capitalize, formatValue } from '../../../../../utils/formats';
import { generalStyleClasses } from '../../../../../utils/general-styles';
import { GET_COMPANY_FUNDING_HISTORY } from '../../../../queries/preview-queries';

const styles = makeStyles({
  tableHeader: {
    display: "flex",
    marginTop: "24px",
    padding: "0px 8px 16px 8px",
    borderBottom: "1px solid #EBEBEB",
    justifyContent: "space-between"
  },
  tableRow: {
    display: "flex",
    padding: "16px 8px",
    borderBottom: "1px solid #EBEBEB",
    justifyContent: "space-between",
    "&:nth-of-type(odd)": {
      background: "#F9F9F9"
    }
  }
})
const CompanyFundingHistory = ({ companyId }) => {
  const generalClasses = generalStyleClasses();
  const classes = styles();
  const { data, loading } = useQuery(GET_COMPANY_FUNDING_HISTORY, { variables: { id: companyId } })

  if (loading || !data || !data.Company_by_pk) return <div />

  return (
    <Fragment>
      <p className={[generalClasses.marginTop64, generalClasses.fontSize18, generalClasses.fontColorDark, generalClasses.marginBottom24].join(' ')}>Funding History</p>

      <div>
        <div className={[generalClasses.fontSize13, generalClasses.fontInputColor, classes.tableHeader].join(' ')}>
          <div style={{ width: "150px" }}>Financing Round</div>
          <div style={{ width: "75px" }}>Date</div>
          <div style={{ width: "150px" }}>Pre-money Valuation</div>
          <div style={{ width: "150px" }}>Amount Raised</div>
          <div style={{ width: "150px" }}>Investor Name</div>
        </div>
        {
          data.Company_by_pk.funding_histories.map((x, index) => (
            <div key={index} className={[generalClasses.fontSize13, generalClasses.fontInputColor, classes.tableRow].join(' ')}>
              <div style={{ width: "150px" }}>{x.financing_round.split('_').map(capitalize).join(' ')}</div>
              <div style={{ width: "75px" }}>{new Date(x.date).toLocaleDateString()}</div>
              <div style={{ width: "150px" }}>{`${formatValue(x.pre_money_valuation, true)} ${x.pre_money_valuation_currency}`}</div>
              <div style={{ width: "150px" }}>{`${formatValue(x.amount_raised, true)} ${x.amount_raised_curency}`}</div>
              <div style={{ width: "150px" }}>{x.notable_investors.map(x => x.notable_investor_name).join(', ')}</div>
            </div>
          ))
        }
      </div>
    </Fragment>
  );
}


export default CompanyFundingHistory;