import React from 'react';
import { adminAlignmentStyles, adminDimensionsStyle } from './AdminStyles';
import Account from '../pages/MainApp/Account/Account';

const AdminAccount = () => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  return (
    <div className={[dimensionClasses.containerWidth, dimensionClasses.headerMarginFix, alignmentClasses.flex].join(' ')} style={{justifyContent: "center", maxWidth: "none"}}>
      <Account />
    </div>
  )
}

export default AdminAccount;