import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const labeledMultipleFieldsStyle = makeStyles({
  root: {},
  label: {
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    margin: '0 !important',
    color: '#6A6A6A !important',
  },
  textContainer: {
    maxWidth: "400px",
    display: "flex",
    flexWrap: "wrap",
    margin: "0",
  },
  text: {
    border: "1px solid #ECDFDF",
    borderRadius: "25px",
    padding: "4px 6px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    marginRight: "8px",
    marginTop: "4px",
    color: "#484642",
  }
})
const LabeledMultipleFields = ({ label, fields = [], customStyles = {} }) => {
  const classes = labeledMultipleFieldsStyle();

  return (
    <div className={classes.root} style={customStyles}>
      <p className={classes.label}>{label}</p>
      <p className={classes.textContainer}>
        {
          fields.map(x => <span className={classes.text}>{x}</span>)
        }
      </p>
    </div>
  )
}

export default LabeledMultipleFields;