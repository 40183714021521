export const signupSteps = {
  FORGOT_PERSONAL_INFORMATIONS: "FORGOT_PERSONAL_INFORMATIONS",
  ACCREDITED_CLIENT_AND_INVESTOR: "ACCREDITED_CLIENT_AND_INVESTOR",
  ONBOARDING: "ONBOARDING",
  CONFIDENTIALITY_AGREEMENT: "CONFIDENTIALITY_AGREEMENT",
  INVESTOR_PREFERANCES: "INVESTOR_PREFERANCES",
  ACCESS_APP: "ACCESS_APP",
  AWAIT_APPROVAL: "AWAIT_APPROVAL", 
}

const goToForgotPersonalInfosCheck = (data) => {
  return !(data.city && data.country && data.first_name && data.last_name && data.phone_number)
}

const goToOnboardingCheck = (data) => {
  return data.Onboarding_flow_section_edges.filter(x => !x.completed).length > 0;
}

export const mapSignupDataToSignupState = (data, defaultFlowData = null, userId = null) => {
  if(!data.whitelist_account.approved_at) {
    if(goToForgotPersonalInfosCheck(data)) {
      return signupSteps.FORGOT_PERSONAL_INFORMATIONS
    }
    if(data.Onboarding_flow_section_edges.length > 0 && data.Onboarding_flow_section_edges.filter(x => !x.completed).length === 0) {
      return signupSteps.AWAIT_APPROVAL;
    }
    if(!goToOnboardingCheck(data) && defaultFlowData.length > 0 && userId) {
        return signupSteps.ONBOARDING;
    }
    if(goToOnboardingCheck(data) ){
      return signupSteps.ONBOARDING;
    }
    return signupSteps.AWAIT_APPROVAL;
  } else {
    if(!data.accepted_confidentiality) {
      return signupSteps.CONFIDENTIALITY_AGREEMENT;
    }
    if(!data.investor_profile[0] || !data.investor_profile[0].first_time_viewed ) {
      return signupSteps.INVESTOR_PREFERANCES;
    }
    return signupSteps.ACCESS_APP;
  }
}