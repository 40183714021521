import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useOnboardingStyles } from './OnboardingStyles';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import CreateAccount from './StepPages/CreateAccount';
import PersonalInformation from './StepPages/PersonalInformation';
import LastSteps from './StepPages/LastStep';
import SignupSuccessfulContained from './StepPages/SignupSuccessfulContained';

const Onboarding = () => {
    const store = useSelector(store => store);
    const classes = useOnboardingStyles();
    const { url } = useRouteMatch();

    return (
        <Fragment>
            <Navbar />
            <div className={classes.routerContainer} >
                <div className={classes.onboardingContainer}>
                    <Switch>
                        <Route path={`${url}/create-account`} >
                            <CreateAccount />
                        </Route>
                        <Route path={`${url}/personal-information`}>
                            <PersonalInformation />
                        </Route>
                        <Route path={`${url}/last-steps`}>
                            <LastSteps />
                        </Route>
                        <Route path={`${url}/signup-successful`} >
                            <SignupSuccessfulContained/>
                        </Route>
                    </Switch>
                </div>
                </div>
        </Fragment>
    )
}

export default Onboarding;