import { makeStyles } from "@material-ui/core";

export const leftsideMenuStyless = makeStyles({
  card: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    padding: "16px",
    width: "254px",
    display: "flex",
    boxSizing: "border-box",
  },
  image: {
    width: "40px",
    height: "45px",
    marginRight: "16px",
  },
  text: {
    color: "rgb(111, 112, 116)",
    margin: "0",
    marginBottom: "24px",
  },
  button: {
    textTransform: "none",
    padding: "0",
  },
  sideNav: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "36px",
    marginLeft: "20px",
  },
  linkColor: {
    color: "#47567B"
  },
  linkButton: {
    "& span" :{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "230px",
      display: "block",
      minWidth: "auto"
    }
  }
})