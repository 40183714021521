import React, { useState } from 'react';
import { Fragment } from 'react';
import Navbar from '../../onboarding/Navbar/Navbar';
import TextInput from '../../shared/TextInput/TextInput';
import { Button, makeStyles } from '@material-ui/core';
import { generalStyleClasses } from '../../utils/general-styles';
import firebase from 'firebase';

const contentStyles = makeStyles({
  container: {
    width: "560px",
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    padding: "40px",
    boxSizing: "border-box",
    marginTop: "200px",
    zIndex: "1",
  }
})

const ResetPasswordFirstStep = () => {
  const classes = generalStyleClasses();
  const containerClasses = contentStyles();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const handleResetPassword = () => {
    firebase.auth().sendPasswordResetEmail(email, {
        url: "http://localhost:3000/",
    }).then(() => {
      setEmailSent(true);
    }).catch(console.error);
}

  return (
    <div className={classes.flexAlignCenterOnColumn}>
      <Navbar style={{ background: "#F4F4F4", width: "100%" }} />
      <div className={containerClasses.container}>
        {
          !emailSent
            ? (
              <Fragment>
                <p className={[classes.marginTop0, classes.marginBottom0, classes.fontSize20, classes.fontColorDark].join(' ')}>Reset Password</p>
                <p className={[classes.marginTop12, classes.marginBottom0, classes.fontSize16, classes.fontColorLight].join(' ')}>Enter your account email</p>
                <div className={classes.marginTop32}>
                  <TextInput label="Email" handleChange={value => setEmail(value)} />
                </div>
                <Button
                  className={[classes.marginTop32, classes.singleFullWidthLinkButton].join(' ')}
                  variant="contained"
                  color="primary"
                  onClick={() => handleResetPassword()}>
                  Reset Password
                </Button>
              </Fragment>
            )
            : (
              <Fragment>
                <p className={[classes.marginTop0, classes.marginBottom0, classes.fontSize20, classes.fontColorDark].join(' ')}>Reset Password</p>
                <p className={[classes.marginTop12, classes.marginBottom0, classes.fontSize16, classes.fontColorLight].join(' ')}>An email has been sent. Open the link from your email to reset your password.</p>
              </Fragment>
            )
        }
      </div>
    </div>
  )
}

export default ResetPasswordFirstStep;