import { makeStyles } from '@material-ui/core/styles';

export const detailedOpportunityPageStyles = makeStyles({
  topsideMenu: {
    width: '100%',
    height: '72px',
    background: '#F4F4F4',
  },
  contentContainer: {
    display: 'flex',
    marginTop: '-8px',
    width: "100%",
    maxWidth: "1180px",
  },
  filters: {
    width: '266px',
    marginRight: '24px',
  },
  content: {
    minWidth: '890px'
  }
})