import React, { useRef, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useRouteMatch, useHistory, Link } from 'react-router-dom';
import { useInvestorPreferencesStyles } from './InvestorPreferencesStyle';
import { generalStyleClasses } from '../utils/general-styles'
import Stepper from '../shared/Stepper/Stepper';
import Company from './StepPages/Company';
import Industry from './StepPages/Industry';
import Investment from './StepPages/Investment';
import { useMutation } from '@apollo/client';
import { CREATE_INVESTOR_PROFILE, SET_INVESTOR_PROFILE_VIEWED as SET_INVESTOR_PROFILE_VIEWED_QUERY } from '../utils/investor-preferances-queries';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../shared/Loading/Loading';
import { SET_INVESTOR_PROFILE_VIEWED, SET_INVESTOR_PROFILE_ID } from '../store/utils/action-types';
import { IconButton } from '@material-ui/core';

const InvestorPreferences = () => {
    const classes = useInvestorPreferencesStyles();
    const genericClasses = generalStyleClasses();
    const investorProfileId = useSelector(store =>
        store.account.user?.investor_profile && store.account.user?.investor_profile[0]?.id ||
        store.account.investorProfileId ||
        null);
    const stepperRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const { url } = useRouteMatch();
    const [createInvestorProfile] = useMutation(CREATE_INVESTOR_PROFILE);
    const [setInvestorProfileViewed] = useMutation(SET_INVESTOR_PROFILE_VIEWED_QUERY);
    const [createdProfile, setCreatedProfile] = useState(false);
    const steps = ["Industry", "Company", "Investment"];
    const account = useSelector(store => store.account.user);

    const setStepperPosition = (path) => {
        if (stepperRef && stepperRef.current) {
            if (path.indexOf("industry") !== -1) {
                stepperRef.current.setStep(0);
            } else if (path.indexOf("company") !== -1) {
                stepperRef.current.setStep(1);
            } else if (path.indexOf("investment") !== -1) {
                stepperRef.current.setStep(2);
            }
        }
    }

    const handleSelectNewStep = (step) => {
        stepperRef.current.setStep(step);
        switch (step) {
            case 0:
                history.push(`${url}/industry`)
                break;
            case 1:
                history.push(`${url}/company`)
                break;
            case 2:
                history.push(`${url}/investment`)
                break;
            default:
                break;
        }
    }

    const handleClose = () => {
        setInvestorProfileViewed({ variables: { id: investorProfileId } }).then(result => {
            dispatch({ type: SET_INVESTOR_PROFILE_VIEWED, payload: result.data.update_Investor_profile_by_pk })
        }).catch(console.log);
    }

    useEffect(() => {
        if (!investorProfileId) {
            createInvestorProfile({ variables: { firebase_id: account.firebase_id } }).then(result => {
                dispatch({ type: SET_INVESTOR_PROFILE_ID, payload: result.data.insert_Investor_profile.returning[0].id });
                setCreatedProfile(true);
            }).catch(error => {
                console.error(error);
            })
        } else {
            setCreatedProfile(true);
        }
    }, [])

    useEffect(() => {
        setStepperPosition(history.location.pathname);
        return history.listen((location) => {
            setStepperPosition(location.pathname);
        })
    }, [history])

    if (createdProfile === false) {
        return <Loading fullWidth />
    }
    return (
        <div className={classes.routerContainer} >
            <IconButton onClick={() => handleClose()} className={classes.closeButton}>
                <img src={`${process.env.PUBLIC_URL}/images/close-filled.svg`} alt="company logo" />
            </IconButton>
            <p className={[genericClasses.marginTop32, genericClasses.marginBottom0, genericClasses.fontAlignCenter, genericClasses.fontInputColor, genericClasses.fontSize16].join(' ')} style={{ width: '560px' }}>Please take a moment to tell us about your investment preferences.  As we continue to work on sourcing attractive opportunities, we will strive to provide access to investments that meet your criteria.</p>
            <Stepper steps={steps} ref={stepperRef} style={{ width: '560px', marginTop: '36px' }} onStepSelect={step => handleSelectNewStep(step)} />
            <div className={classes.investmentPreferencesContainer}>
                <Switch>
                    <Route path={`${url}/industry`} >
                        <Industry nextStep={() => stepperRef.current.increaseStep()} handleSkip={() => handleClose()} />
                    </Route>
                    <Route path={`${url}/company`} >
                        <Company nextStep={() => stepperRef.current.increaseStep()} handleSkip={() => handleClose()} />
                    </Route>
                    <Route path={`${url}/investment`} >
                        <Investment nextStep={() => handleClose()} handleSkip={() => handleClose()} />
                    </Route>
                    <Route path="/investor-preferences/" >
                        <Redirect to="/investor-preferences/industry" />
                    </Route>
                </Switch>
            </div>
        </div>
    )
}

export default InvestorPreferences;