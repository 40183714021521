import React, { useEffect } from 'react';
import { generalStyleClasses } from '../../utils/general-styles';
import MultipleSelect from '../MultipleSelect/MultipleSelectField';
import { useQuery } from '@apollo/client'
import { SECTOR_ENABLERS } from '../../utils/enum-queries';
import { useSelector } from 'react-redux';

const InovationEnablers = ({ handleChange, rootClasses = {}, fullWidth = false }) => {
  const classes = generalStyleClasses();
  const sectorEnablerOptions = useQuery(SECTOR_ENABLERS).data;
  const defaultValues = useSelector(store => store.investorProfile.innovation_enablers);

  useEffect(() => {
    handleChange(defaultValues);
  }, [defaultValues]);

  return (
    <div className={rootClasses}>
      <span className={[classes.fontSize14, classes.fontInputColor].join(' ')}>Innovation enablers I'm interested in</span>
      <div className={classes.marginTop8}>
        <MultipleSelect optionsInput={sectorEnablerOptions ? sectorEnablerOptions.ENUM_innovation_enablers : []} defaultValues={defaultValues} handleChange={handleChange} inputFieldOptionalStyle={fullWidth ? {width: "100%"} : {width: '300px'}}/>
      </div>
    </div>
  )
}

export default InovationEnablers;