import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Tabs, Tab, Box, makeStyles, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { mapServerFlow } from "./flowMapper";
import { GET_ONBOARDING_FLOW, GET_ONBOARDING_FLOW_ITEMS_BY_EDGE_ID, INSERT_FLOW_INPUT_FIELD, UPDATE_FLOW_INPUT_FILED, GET_DEFAULT_ONBOARDING_FLOW, GET_ASSIGNED_ONBOARDING_OR_DEFAULT } from "../utils/account-queries";
import DownloadComponent from "../shared/DocumentHandler/DownloadComponent";
import UploadComponent from "../shared/DocumentHandler/UploadComponent";
import { onboardingSectionTypes } from "../store/slices/adminOnboardingFlow";
import TextInput from "../shared/TextInput/TextInput";
import { addDocumentsForOnboardingFlowEdge, deleteDocumentsForOnboardingFlowEdge } from "../utils/file-queries";
import { generalStyleClasses } from "../utils/general-styles";
import { Events, EventThrower, hasuraErrorHandler } from "../shared/EventEmitter/EventEmitter";
import firebase from 'firebase';
import { LOGOUT, SET_USER_AUTH } from "../store/utils/action-types";
import { permissionAsignmentsActions } from "../store/slices/permissions";
import { cloneOnboardingFlowForInvestor } from "../admin/queries/admin-http-calls";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const styles = makeStyles({
  container: {
    position: "absolute",
    top: "0",
    zIndex: "1000",
    background: "#e5e5e5",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tabContainer: {
    width: "560px",
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "32px",
  },
});

const CustomOnboardingProcess = () => {
  const [selectedTab, setSelectedTab] = useState(null);
  const classes = styles();
  const params = useRouteMatch().params;
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const canAccessApp = useSelector(store => store.permissionAsignments.app_content_access);
  const [getAssignedFlow, { data }] = useLazyQuery(GET_DEFAULT_ONBOARDING_FLOW, {variables: {firebase_id: firebaseId}, fetchPolicy: "network-only"});
  const [getEdgeItems, getEdgeItemsResult] = useLazyQuery(GET_ONBOARDING_FLOW_ITEMS_BY_EDGE_ID, {fetchPolicy: "network-only"});
  const {data: assignedOrDefaultFlow} = useQuery(GET_ASSIGNED_ONBOARDING_OR_DEFAULT, {variables: {firebase_id: firebaseId}, fetchPolicy: "network-only"});
  const [insertInputField] = useMutation(INSERT_FLOW_INPUT_FIELD);
  const [updateInputField] = useMutation(UPDATE_FLOW_INPUT_FILED);
  const [flow, setFlow] = useState([]);
  const [name, setName] = useState("");
  const [tabsNumber, setTabsNumber] = useState(0);
  const [isCompelted, setIsCompelted] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const createFlow = async () => {
    const cloneResult = await cloneOnboardingFlowForInvestor(assignedOrDefaultFlow.Onboarding_flow_section_edge[0]?.Flow_node.id, firebaseId);
    console.log("clone flow >>", await cloneResult.json());
    getAssignedFlow();
  }

  useEffect(() => {
    console.log(assignedOrDefaultFlow);
    if(assignedOrDefaultFlow) {
      if(!assignedOrDefaultFlow.Account[0]?.Onboarding_flow_section_edges[0]?.Flow_node.id) {
        console.log("create flow");
        EventThrower.throwClientSideMessage(Events.SuccessMessage("Please wait for the onboarding"));
        createFlow();
      }
      else {
        getAssignedFlow();
      }
    }
  }, [assignedOrDefaultFlow])

  console.log(assignedOrDefaultFlow);
  useEffect(() => {
    if (data) {
      const onboardingData = data.Onboarding_flow_section_edge[0]?.Flow_node;
      if(onboardingData) {
        try {
          setTabsNumber(onboardingData.Onboarding_flow_section_edges.length || 0);
          setName(onboardingData.name || "");
          const mappedData = mapServerFlow(onboardingData);
          setFlow(mappedData.flow);
          if(selectedTab === null) {
            setSelectedTab(Math.max(0, mappedData.current));
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        hasuraErrorHandler.printError("Missing onboarding flow");
        createFlow();
      }
    }
  }, [data]);

  const findNextStep = () => {
    for (let i = selectedTab + 1; i < flow.length; i++) {
      if (!flow[i].locked) {
        return i;
      }
    }
  };

  const findLastStep = () => {
    for (let i = flow.length - 1; i >= 0; i--) {
      if (!flow[i].locked) {
        return i;
      }
    }
  };

  const handleGoToNextStep = () => {
    if (selectedTab != findLastStep()) {
      setSelectedTab(findNextStep());
    } else {
      history.push(canAccessApp ? '/investor-preferences/' : `/signup-successful`);
    }
  };

  const handleAddFile = (files, edgeId) => {
    const filesToUpload = files.filter(x => x instanceof File);
    if(filesToUpload.length > 0) {
      addDocumentsForOnboardingFlowEdge(filesToUpload, firebaseId, edgeId)
        .then(() => getEdgeItems({variables: {id: edgeId}}))
        .catch((err) => console.log(err));
    }
  };

  const handleDeleteFile = (fileId) => {
    deleteDocumentsForOnboardingFlowEdge(fileId).then(console.log).catch(console.log);
  };

  const setInputFieldText = (edgeId, newText) => {
    setFlow(flow.map((flowSection, index) => {
      if(index === selectedTab) {
        const itemIndex = flowSection.components.findIndex(x => x.edgeId === edgeId);
        if(itemIndex !== -1) {
          flowSection.components[itemIndex].text = newText;
        }
      }
      return flowSection;
    }))
  }

  const handleCancelButton = () => {
      firebase.auth().signOut().then(() => {
          dispatch({ type: SET_USER_AUTH, payload: { jwtToken: '' } });
          dispatch({ type: LOGOUT });
          dispatch(permissionAsignmentsActions.RESET_PERMISSIONS());
          history.push('/');
      })
  }

  const handleSaveInputField = async (item) => {
    if(!item.inputField || !item.inputField.id) {
      const insertResult = await insertInputField({variables: {edge_id: item.edgeId, text: item.text}});
      if(insertResult.data?.insert_Onboarding_flow_item_input_field?.affected_rows > 0)
      setFlow(flow.map(flowSection => {
        const itemIndex = flowSection.components.findIndex(x => x.edgeId === item.edgeId);
        if(itemIndex !== -1) {
          flowSection.components[itemIndex].inputField = insertResult.data.insert_Onboarding_flow_item_input_field.returning[0];
          EventThrower.throwClientSideMessage(Events.SuccessMessage(`${item.label} field updated`));
        }
        return flowSection;
      }))
    } else {
      const updateResult = await updateInputField({variables: {id: item.inputField.id, text: item.text}});
      if(updateResult.data) {
        EventThrower.throwClientSideMessage(Events.SuccessMessage(`${item.label} field updated`));
      }
    }
  } 

  useEffect(() => {
    if(getEdgeItemsResult.data) {
      setFlow(flow.map(flowStep => {
        const flowItemIndex = flowStep.components.findIndex(item => item.edgeId === getEdgeItemsResult.data.Onboarding_section_item_edge_by_pk.id);
        if(flowItemIndex !== -1) {
          flowStep.components[flowItemIndex].files = getEdgeItemsResult.data.Onboarding_section_item_edge_by_pk.Files_metadata;
        }
        return flowStep;
      }))
    }
  }, [getEdgeItemsResult]);

  return (
    <div className={classes.container}>
      <p>{name}</p>
      <div style={{ width: "560px" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {flow
            ? flow.map((x) => <Tab disabled={x.locked} label={x.tabName} style={{maxWidth: "none", flexGrow: "1"}}/>)
            : ""}
        </Tabs>
        <div className={classes.tabContainer}>
          {flow
            ? flow.map((x, index) => (
                <TabPanel key={index} value={selectedTab} index={index}>
                  {x.components.map((comp, compIndex) => {
                    switch (comp.type) {
                      case onboardingSectionTypes.DOWNLOAD:
                        return (
                          <DownloadComponent key={compIndex} title="download documents: " files={comp.files} route="onboarding" />
                        );
                    
                      case onboardingSectionTypes.TEXT: 
                        return <p key={compIndex}>{comp.text}</p>;

                      case onboardingSectionTypes.UPLOAD: 
                        return <UploadComponent disabled={isCompelted} deleteCallback={(fileId) => handleDeleteFile(fileId)} downloadRoute="onboarding" initialFiles={comp.files} setNewFiles={(files) => handleAddFile(files, comp.edgeId) } key={compIndex} title={comp.label}/>

                      case onboardingSectionTypes.DOCUSIGN:
                        return <p key={compIndex}>docusign</p>;

                      case onboardingSectionTypes.INPUT_FIELD: 
                          return (
                          <div style={{display: "flex", alignItems: "flex-end"}}>
                            <TextInput label={comp.label} handleChange={value => setInputFieldText(comp.edgeId, value)} initialValue={comp.inputField?.text || ""} customStyle={{marginTop: "32px", flexGrow: "1"}}/>
                            <Button variant="contained" color="primary" style={{height: "40px", width: "100px", marginLeft: "12px"}}
                                    onClick={() => handleSaveInputField(comp)} >{comp.inputField ? "Update" : "Insert"}</Button>
                          </div>
                          )
                      default:
                        break;
                    }
                    
                  })}
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ width: "248px" }}
                      onClick={() => handleCancelButton()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: "248px" }}
                      onClick={() => handleGoToNextStep()}
                    >
                      {selectedTab === findLastStep() ? "Finish" : "Continue"}
                    </Button>
                  </div>
                </TabPanel>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default CustomOnboardingProcess;
