import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { fileDownloader } from '../../utils/file-queries';
import { hasuraErrorHandler } from '../EventEmitter/EventEmitter';

const styles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  fileButton: {
    padding: "4px 12px",
    marginRight: "8px",
    marginTop: "8px",
  },
  fileName: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "15px",
    color: "#505154",
  },
  icon: {
    height: "16px",
    marginRight: "8px",
    fill: "#555f6f",
  }
})

const handleDownloadFile = (url, name) => {
  fileDownloader(url, name)
  .catch(() => hasuraErrorHandler.printError("Document download failed"))
}

const DownloadComponent = ({ title, files, route = "investment" }) => {
  const classes = styles();

  return (
    <div>
      <p>{title}</p>
      <div >
        {files.map(x =>
          <Button variant="outlined" className={classes.fileButton} onClick={() => handleDownloadFile(`${route}/documents/${x.id}`, x.name)}>
            <GetAppIcon className={classes.icon}/>
            <span className={classes.fileName}>{x.name}</span>
          </Button>
        )}
      </div>
    </div>
  )
}

export default DownloadComponent;