import { useQuery } from '@apollo/client';
import React, { Fragment, useState, forwardRef, useImperativeHandle,  useRef } from 'react';
import { GET_ALL_COMPANIES } from '../../queries/admin-queries';
import Section from './OpportunitySections/Section';
import { useDispatch, useSelector } from 'react-redux';
import { addOportunityActions } from '../../../store/utils/action-types';
import Loading from '../../../shared/Loading/Loading';
import { oportunitySectionStyle } from './OpportunitySections/OportunitySectionStyles';
import { DealSectionType } from '../../../utils/enums';
import OverviewSection from './OpportunitySections/CompanyOverview';
import FilesSection from './OpportunitySections/Files';
import ImagesSection from './OpportunitySections/Images';
import ManagementSection from './OpportunitySections/CompanyManagement';
import DescriptionSection from './OpportunitySections/CompanyDescription';
import RichTextArea from './OpportunitySections/RichTextArea';
import CompanyFundingHistory from './OpportunitySections/CompanyFundingHistory';

const PickSection = ({section}) => {
  switch(section.type){
    case DealSectionType.OVERVIEW: return (<OverviewSection section={section} />)
    case DealSectionType.FILES: return (<FilesSection section={section} />)
    case DealSectionType.FUNDING: return (<CompanyFundingHistory section={section} />)
    case DealSectionType.IMAGES: return (<ImagesSection section={section} />)
    case DealSectionType.MANAGEMENT: return (<ManagementSection section={section} />)
    case DealSectionType.DESCRIPTION: return (<DescriptionSection section={section} />)
    case DealSectionType.RICH_TEXT_AREA: return (<RichTextArea section={section} />)
    default: {
      return null;
    }
  }
}

const AddOportunitySections = (props) => {
  const { data, loading } = useQuery(GET_ALL_COMPANIES);
  const classes = oportunitySectionStyle();
  const {resultingOpportunity} = useSelector(state => state.adminOpportunity);
  const {
    companyId: selectedCompanyId,
    sections,
  } = resultingOpportunity;


  if (loading) {
    return <Loading fullWidth />
  }

  if (!data) {
    return <div className={classes.noSelectedCompany}>Select a company to add more detailse</div>
  }
  return (
    <Fragment>
      {!selectedCompanyId && <div className={classes.noSelectedCompany}>Select a company to add more details</div>}
      {!!selectedCompanyId && sections.map((section) => {
        return (
          <Section 
            key={`section-${selectedCompanyId}-${section.id}-${section.type}`} 
            sectionsCount={sections.length}
            section={section}
            disableDeleteButton={section.type === DealSectionType.OVERVIEW}
            >
            <PickSection section={section} />
          </Section>
        )})}
    </Fragment>
  )
}

export default AddOportunitySections;