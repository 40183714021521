import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const managementLabeledTextStyle = makeStyles({
  label: {
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
    fontSize: '16px !important',
    lineHeight: '19px !important',
    margin: '0 !important',
    color: '#796E6E !important',
  },
  text: {
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    margin: '0 !important',
    color: '#505154 !important',
    marginTop: '4px !important',
  },
  bio: {
    marginTop: "8px",
    marginRight: "16px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#505154",
    display: 'flex',
    flexDirection: 'column'
  },
  showMore: {
    cursor: "pointer",
    color: "#1baaff",
  },
  container: {
    width: "calc(33% - 8px)",
    marginRight: "16px",
    "&:nth-of-type(3n)":{
      marginRight: "0px",
    }
  }
})
const ManagementLabeledText = ({ text, label, bio, customStyles = {}, labelStyle = {} }) => {
  const classes = managementLabeledTextStyle();
  const [expanded, setExpaded] = useState(false);
  return (
    <div style={customStyles} className={classes.container}>
      <p className={classes.label} style={labelStyle}>{label}</p>
      <p className={classes.text}>{text}</p>
      <p className={classes.bio}>
        {
          expanded
          ? bio
          : <>
          {bio.substring(0, 115)}...
          </>
        }
        <span className={classes.showMore} onClick={() => setExpaded(!expanded)}> Show {expanded ? "less" : "more"}</span>
      </p>
    </div>
  )
}

export default ManagementLabeledText;