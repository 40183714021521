import React, { Fragment, useState } from 'react';
import { generalStyleClasses } from "../../../../utils/general-styles";
import { Button } from '@material-ui/core';
import BussinessModels from '../../../../shared/InvestorPreferancesInputs/BussinessModels';
import CountryOfCompany from '../../../../shared/InvestorPreferancesInputs/CountryOfCompany';
import DevelopmentStage from '../../../../shared/InvestorPreferancesInputs/DevelopmentStage';
import FundingStage from '../../../../shared/InvestorPreferancesInputs/FundingStage';
import AdditionalInformations from '../../../../shared/InvestorPreferancesInputs/AdditionalInformation';
import { Events, EventThrower } from '../../../../shared/EventEmitter/EventEmitter';
import { useMutation } from "@apollo/client";
import { UPDATE_COMPANY_INVESTOR_PREFERANCES } from '../../../../utils/investor-preferances-queries';
import { useDispatch, useSelector } from 'react-redux';
import { SET_INVESTOR_PROFILE } from '../../../../store/utils/action-types';

const Company = () => {
  const generalStyles = generalStyleClasses();
  const dispatch = useDispatch();
  const [ bussinessModels, setBussinessModels] = useState([]);
  const [ countries, setCountries] = useState([]);
  const [ developmentStage, setDevelopmentStage] = useState([]);
  const [ fundingStage, setFundingState] = useState([]);
  const [additionalInformations, setAdditionalInformations] = useState('');
  const [ insertInvestorPreferances ] = useMutation(UPDATE_COMPANY_INVESTOR_PREFERANCES);
  const account = useSelector(store => store.account.user);
  const investorProfileId = useSelector(store => store.account.investorProfileId);
  const additionalInfosValue = useSelector(store => store.investorProfile.company_additional_information);
 
  const handleSaveChanges = () => {
    const variables = {
      firebase_id: account.firebase_id,
      company_additional_information: additionalInformations,
      company_business_model_B2B: bussinessModels.filter(x => x.value === 1).length > 0,
      company_business_model_B2B2C: bussinessModels.filter(x => x.value === 3).length > 0,
      company_business_model_B2C: bussinessModels.filter(x => x.value === 2).length > 0,
      company_development_stage_build_product: developmentStage.filter(x => x.value === 1).length > 0,
      company_development_stage_generates_revenue: developmentStage.filter(x => x.value === 3).length > 0,
      company_development_stage_has_product: developmentStage.filter(x => x.value === 2).length > 0,
      company_development_stage_is_profitable: developmentStage.filter(x => x.value === 4).length > 0,
      company_funding_stage_any_stage: fundingStage.filter(x => x.value ===1).length > 0,
      company_funding_stage_post_seed: fundingStage.filter(x => x.value ===2).length > 0,
      company_funding_stage_pre_ipo: fundingStage.filter(x => x.value ===3).length > 0,
      company_funding_stage_seed: fundingStage.filter(x => x.value ===4).length > 0,
      company_funding_stage_series_a: fundingStage.filter(x => x.value ===5).length > 0,
      company_funding_stage_series_b: fundingStage.filter(x => x.value ===6).length > 0,
      company_funding_stage_series_c: fundingStage.filter(x => x.value ===7).length > 0,
      company_funding_stage_series_d: fundingStage.filter(x => x.value ===8).length > 0,
      company_funding_stage_series_f: fundingStage.filter(x => x.value ===9).length > 0,
      company_funding_stage_series_e: fundingStage.filter(x => x.value ===10).length > 0,
      company_funding_stage_convertible_note: fundingStage.filter(x => x.value ===11).length > 0,
      company_funding_stage_other: fundingStage.filter(x => x.value ===12).length > 0,
      company_funding_stage_pre_seed: fundingStage.filter(x => x.value ===13).length > 0,
      company_funding_stage_series_g: fundingStage.filter(x => x.value ===14).length > 0,
      company_funding_stage_series_h: fundingStage.filter(x => x.value ===15).length > 0,
      company_funding_stage_series_i: fundingStage.filter(x => x.value ===16).length > 0,
      company_funding_stage_series_j: fundingStage.filter(x => x.value ===17).length > 0,
      company_funding_stage_series_k: fundingStage.filter(x => x.value ===18).length > 0,   
      countries: countries.map(x => ({country: x.value, profile_id: investorProfileId}))
    }
    insertInvestorPreferances({variables: variables}).then(result => {
        dispatch({type: SET_INVESTOR_PROFILE, payload: result.data.update_Investor_profile.returning[0]})
        EventThrower.throwClientSideError(Events.InformationChangedMessage);
    }).catch(error => {
      EventThrower.throwClientSideError(Events.InformationChangedError)});
  }
  
  return (
    <Fragment>
      <BussinessModels handleChange={value => setBussinessModels(value)} />
      <CountryOfCompany handleChange={value =>  setCountries(value)} rootClasses={generalStyles.marginTop32} />
      <DevelopmentStage handleChange={value => setDevelopmentStage(value)} rootClasses={generalStyles.marginTop32} />
      <FundingStage optionalStyle={{ width: "140px", marginRight: "0px" }} values={fundingStage} enableSelectAll handleChange={value => setFundingState(value)} rootClasses={generalStyles.marginTop32} />
      <AdditionalInformations handleChange={setAdditionalInformations} rootClasses={generalStyles.marginTop32} defaultValue={additionalInfosValue} />
      <Button variant="contained" color="primary" className={[generalStyles.floatRight, generalStyles.marginTop32].join(' ')} onClick={handleSaveChanges}>Save changes</Button>
    </Fragment>
  )
}

export default Company;