import { LOGOUT, SET_USER_ACCOUNT, USER_NOT_LOGGEDIN, SET_INVESTOR_PROFILE_ID, SET_USER_ADMIN, SET_USER_NOT_ADMIN, SET_USER_AUTH, SET_ACCOUNT_PERSONAL_INFOS } from "../utils/action-types";

const initialState = {
  loggedIn: false,
  user: null,
  investorProfileId: 0,
  checkedForLoggedUser: false,
  isAdmin: false,
  jwtToken: '',
}

const updateAccountState = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_AUTH: {
      return {
        ...state,
        jwtToken: action.payload.jwtToken
      }
    }
    case USER_NOT_LOGGEDIN: {
      return {
        ...state,
        checkedForLoggedUser: true,
        isAdmin: false,
      }
    }
    case SET_USER_ACCOUNT:
      return {
        ...state,
        loggedIn: true,
        checkedForLoggedUser: true,
        isAdmin: action.payload.permissions && action.payload.permissions.filter(x => x.permission?.role_type === "EMPLOYEE" ||  x.permission?.role_type === "DEAL_EMPLOYEE" || x.permission?.role_type === "DELEGATE" ).length > 0 ? true : (state.isAdmin ? state.isAdmin : false),
        user: {
          ...state.user,
          ...action.payload,
        },
        investorProfileViewed: action.payload.investor_profile > 0 && action.payload.investor_profile[0] && action.payload.investor_profile[0].first_time_viewed,
        investorProfileId: action.payload.investor_profile && action.payload.investor_profile[0] ? action.payload.investor_profile[0].id : 0,
      }
    case SET_ACCOUNT_PERSONAL_INFOS:
      return {
        ...state,
        user: {
          ...state.user,
          city: action.payload.city,
          country:  action.payload.country,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          phone_number: action.payload.phone_number,
        }
      }
    case SET_INVESTOR_PROFILE_ID:
      return {
        ...state,
        investorProfileId: action.payload
      }
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
        user: null,
        isAdmin: false,
        investorProfileId: '',
        jwtToken: '',
      }
    case SET_USER_ADMIN:
      return {
        ...state,
        isAdmin: true,
      }
    case SET_USER_NOT_ADMIN:
      return {
        ...state,
        isAdmin: false,
      }
    default:
      return state;
  }
}

export default updateAccountState;