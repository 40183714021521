export const formatValue = (value, extended = false) => {
  return numberWithCommas(value)
  if(!value || value === 0) return "None";
  if (value / 1000000000 > 0.1) return `${(value / 1000000000).toFixed(2)}${extended ? " Bilion" : "B"}`;
  if (value / 1000000 > 0.1) return `${(value / 1000000).toFixed(2)}${extended ? " Milion" : "M"}`;
  return value;
}

export const formatDate = (date) => {
  const day = date.getDate();
  const month = date.toLocaleString('en-us', { month: 'long' });
  return `${month} ${day}`;
}

export const formatDateMonthYear = (date) => {
  return new Date(date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })
}

export const formatDateDayMonthYear = (date) => {
  return new Date(date).toLocaleDateString('en-US', {day:'numeric', month: 'long', year: 'numeric' })
}


export const capitalize = (input) => {
  if (!input) return "none";
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
}

export const formatUnderlineSeparatedName = (name) => {
  return name.split('_').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')
}

export const formatMessageDate = (date, includeTime = false) => {
  const dayTime = 86400000;
  const current = Date.now();
  const dateDif = Math.abs(date - current);
  if(dateDif/dayTime <= 1) return `Today ${includeTime ? new Date(date).toLocaleTimeString(): ""}`
  if(dateDif/dayTime <= 4) return `${(dateDif/dayTime).toFixed(0)} days ago ${includeTime ? new Date(date).toLocaleTimeString(): ""}`
  return new Date(date).toLocaleDateString();
}

export const numberWithCommas = (x) => {
  if(!x) return null;
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const getRoundedCeilValue = (x) => {
  const minNumber = Math.round(x).toString().split('').length-1;
  return Math.ceil(Math.round(x)/Math.pow(10, minNumber))*Math.pow(10, minNumber);
}

export const getRoundedFloorValue = (x) => {
  const minNumber = Math.round(x).toString().split('').length-1;
  return Math.floor(Math.round(x)/Math.pow(10, minNumber))*Math.pow(10, minNumber);
}

export const formatMiliseconds = (value) => {
  const seconds = Math.floor(value)/1000;
  const hours = Math.floor(seconds/360);
  const minutes = Math.floor((seconds - hours*360)/60)
  const secondsLeft = Math.floor(seconds-hours*360-minutes*60);
  return `${hours !== 0 && `${hours}h ` || ''}${minutes !== 0  && `${minutes}m ` || ''}${secondsLeft !== 0 && `${secondsLeft}s` || ''}`
}