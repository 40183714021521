import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  label: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "15px",
    color: "#796E6E",
    marginTop: 0,
    marginBottom: "8px",
  },
  textarea: {
    background: "#FFFFFF",
    border: "1px solid #D8CECE",
    boxSizing: "border-box",
    borderRadius: "4px",
    minHeight: "90px",
    width: "100%",
    maxWidth: "100%",
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: "13px",
    lineHeight: "15px",
  }
})
const AdditionalInformations = ({handleChange, rootClasses = {}, defaultValue=""}) => {
  const classes = useStyles();
  const [value, setValue] = useState('')

  const valueChanges = (value) => {
    setValue(value);
    if(handleChange) {
      handleChange(value);
    }
  }

  useEffect(() => {
    setValue(defaultValue);
    handleChange(defaultValue);
  }, [defaultValue, handleChange])
  
  return (
    <div className={[classes.root, rootClasses].join(' ')}>
      <p className={classes.label}>Additional information </p>
      <textarea className={classes.textarea} onChange={e => valueChanges(e.target.value)} value={value}/>
    </div>
  )
}

export default AdditionalInformations;