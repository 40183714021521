import { useQuery } from '@apollo/client';
import React, { Fragment } from 'react';
import { generalStyleClasses } from '../../../../../utils/general-styles';
import { GET_COMPANY_DESCRIPTION_BY_ID } from '../../../../queries/preview-queries';

const CompanyDescription = ({ companyId }) => {
  const generalClasses = generalStyleClasses();
  const { data, loading } = useQuery(GET_COMPANY_DESCRIPTION_BY_ID, { variables: { id: companyId } });

  if (loading) return <div />

  return (
    <Fragment>
      <p className={[generalClasses.marginTop64, generalClasses.fontSize18, generalClasses.fontColorDark].join(' ')}>Company Description</p>
      <p className={[generalClasses.marginTop24, generalClasses.fontSize16, generalClasses.fontInputColor, generalClasses.marginBottom0].join(' ')}>
      {data.Company_by_pk.description}
      </p>
    </Fragment>
  )
}

export default CompanyDescription;