import { useMutation, useQuery } from '@apollo/client';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useInvestorPreferencesStyles } from '../../../investor-preferences/InvestorPreferencesStyle';
import Stepper from '../../../shared/Stepper/Stepper';
import { generalStyleClasses } from '../../../utils/general-styles';
import { ADD_DEAL_FEEDBACK, GET_DEAL_COMPANY_SECTORS, GET_DEAL_FEEDBACK_BY_USER_ID, UPDATE_DEAL_FEEDBACK } from '../../../utils/deal-queries';
import SmallButtonGroup from '../../../shared/SmallButtonsGroup/SmallButttonsGroup';
import { useHistory, useRouteMatch } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import { CompanyOptions, IndustrySectorOptions, InvestmentOptions } from './button-options';
import { Fragment } from 'react';
import Loading from '../../../shared/Loading/Loading';
import SuccessMessage from '../../../shared/SuccessMessage/SuccessMessage';
import { useSelector } from 'react-redux';

const customStyles = makeStyles({
  textArea: {
    width: "100%",
    border: "1px solid #D8CECE",
    borderRadius: "4px",
    minHeight: "90px",
  },
  headerOverlay: {
    position: "absolute",
    top: "0px",
    zIndex: "200",
    background: "#e5e5e5",
  },
  closeIcon: {
    position: "absolute",
    right: "20px",
    top: "20px",
  }
})

const NotInterestedFeedback = () => {
  const classes = useInvestorPreferencesStyles();
  const genericClasses = generalStyleClasses();
  const customClasses = customStyles();
  const steps = ["", "", "", ""];
  const stepperRef = useRef();
  const history = useHistory();
  const [addFeadback] = useMutation(ADD_DEAL_FEEDBACK);
  const [updateFeadback] = useMutation(UPDATE_DEAL_FEEDBACK);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [industrySector, setIndustrySector] = useState([]);
  const [company, setCompany] = useState([]);
  const [investment, setInvestment] = useState([]);
  const [additionalInfos, setAdditionalInfos] = useState("");
  const [setFields, setSetFields] = useState([]);
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const dealId = useRouteMatch().params.id;
  const sectors = useQuery(GET_DEAL_COMPANY_SECTORS, { variables: { deal_id: useRouteMatch().params.id } }).data;
  const { data: existingFeedback } = useQuery(GET_DEAL_FEEDBACK_BY_USER_ID, { variables: { firebase_id: firebaseId, deal_id: dealId }, fetchPolicy: "network-only" });

  const increseStep = () => {
    stepperRef.current.setStep(currentStep + 1);
    setCurrentStep(currentStep + 1)
  }

  const handleSelectNewFeedback = (feedback, value, setter, fieldName) => {
    if (value.length === 0 && setFields.indexOf(fieldName) === -1) {
      increseStep();
      setSetFields([...setFields, fieldName]);
    }
    setter(feedback);
  }

  const handleSetAdditionalInfos = (text) => {
    if (!additionalInfos && currentStep < steps.length) {
      increseStep();
    }
    setAdditionalInfos(text)
  }

  const buttonIsValid = () => {
    return currentStep != 0 && company.length !== 0 && investment.length !== 0 && investment.length !== 0;
  }

  const handleSendFeedback = () => {
    const variables = {
      company: company.join(','),
      industry: industrySector.join(','),
      investment: investment.join(','),
      other: additionalInfos,
      deal_id: dealId,
      firebase_id: firebaseId
    }
    if(existingFeedback.Interest_feedback.length === 0) {
      addFeadback({ variables: variables }).then(res => {
        setFeedbackSent(true);
        const timeout = setTimeout(function () {
          clearTimeout(timeout);
          history.push(`/app/view-opportunity/${dealId}`);
        }, 2000);
      }).catch(console.error)
    } else {
      updateFeadback({ variables: variables }).then(res => {
        setFeedbackSent(true);
        const timeout = setTimeout(function () {
          clearTimeout(timeout);
          history.push(`/app/view-opportunity/${dealId}`);
        }, 2000);
      }).catch(console.error)
    }
  }

  useEffect(() => {
    if (existingFeedback && existingFeedback.Interest_feedback.length > 0) {
      setCurrentStep(4)
      setCompany(existingFeedback.Interest_feedback[0].company.split(','))
      setInvestment(existingFeedback.Interest_feedback[0].investment.split(','))
      setIndustrySector(existingFeedback.Interest_feedback[0].industry.split(','))
      setAdditionalInfos(existingFeedback.Interest_feedback[0].other)
    }
  }, [existingFeedback, stepperRef])

  useEffect(() => {
    if(stepperRef.current && currentStep === 4) {
      stepperRef.current.setStep(4);
    }
  }, [stepperRef, currentStep])
  if (!sectors) return <Loading fullWidth />

  return (
    <div className={[classes.routerContainer, customClasses.headerOverlay].join(' ')}>
      <IconButton className={customClasses.closeIcon} onClick={() => history.goBack()}>
        <CancelIcon />
      </IconButton>
      <p className={[genericClasses.marginTop40, genericClasses.marginBottom0, genericClasses.fontAlignCenter, genericClasses.fontInputColor, genericClasses.fontSize16].join(' ')} style={{ width: '560px' }}>
        {!feedbackSent
          ? `Why ${sectors.Deal[0].company.name} doesn't interest you?`
          : sectors.Deal[0].company.name
        }
      </p>
      {
        !feedbackSent ? <Stepper steps={steps} ref={stepperRef} style={{ width: '560px', marginTop: '30px' }} /> : ""
      }
      <div className={classes.investmentPreferencesContainer} style={{ padding: "32px", marginTop: !feedbackSent ? "0px" : "38px" }}>
        {
          !feedbackSent
            ? (
              <Fragment>

                <div>
                  <p className={[genericClasses.marginTop0, genericClasses.fontColorDark, genericClasses.fontSize16, genericClasses.marginBottom0].join(' ')}>Industry / Sector</p>
                  {sectors && sectors.Deal && sectors.Deal[0] && sectors.Deal[0].company && sectors.Deal[0].company.sectors
                    ? <p className={[genericClasses.fontColorLight, genericClasses.fontSize16, genericClasses.marginTop8, genericClasses.marginBottom8].join(' ')}>
                      {sectors.Deal[0].company.sectors.map((x, index) => <span key={index}>{`${index !== 0 ? ", " : " "}${x.ENUM_interested_sector.description}`}</span>)}
                    </p>
                    : ""}
                  <SmallButtonGroup allowMultiple defaultSelectedButtons={industrySector} handleChange={x => handleSelectNewFeedback(x, industrySector, setIndustrySector, "industry")} buttonList={IndustrySectorOptions} containerStyle={{ justifyContent: "flex-start" }} buttonStyle={{ padding: "8px 20px", width: "auto", marginRight: "8px" }} />
                </div>
                {
                  currentStep >= 1
                    ? (
                      <div>
                        <p className={[genericClasses.marginTop32, genericClasses.fontColorDark, genericClasses.fontSize16, genericClasses.marginBottom8].join(' ')}>Company</p>
                        <SmallButtonGroup allowMultiple defaultSelectedButtons={company} handleChange={x => handleSelectNewFeedback(x, company, setCompany, "company")} buttonList={CompanyOptions} containerStyle={{ justifyContent: "flex-start" }} buttonStyle={{ padding: "8px 20px", width: "auto", marginRight: "8px" }} />
                      </div>
                    )
                    : ""
                }
                {
                  currentStep >= 2
                    ? (
                      <Fragment>
                        <div>
                          <p className={[genericClasses.marginTop32, genericClasses.fontColorDark, genericClasses.fontSize16, genericClasses.marginBottom8].join(' ')}>Investment</p>
                          <SmallButtonGroup allowMultiple defaultSelectedButtons={investment} handleChange={x => handleSelectNewFeedback(x, investment, setInvestment, "investment")} buttonList={InvestmentOptions} containerStyle={{ justifyContent: "flex-start" }} buttonStyle={{ padding: "8px 20px", width: "auto", marginRight: "8px" }} />
                        </div>
                        <div>
                          <p className={[genericClasses.fontSize14, genericClasses.fontColorLight, genericClasses.marginTop24, genericClasses.marginBottom8].join(' ')}>Addition information (optional)</p>
                          <textarea className={customClasses.textArea} value={additionalInfos} onChange={e => handleSetAdditionalInfos(e.target.value)}></textarea>
                        </div>
                        <Button variant="contained" color="primary" disabled={!buttonIsValid()} className={[genericClasses.singleRightButton, genericClasses.marginTop32].join(' ')} style={{ width: "176px" }} onClick={() => handleSendFeedback()}>
                          {
                            existingFeedback.Interest_feedback.length !== 0 ? "Update feedback" : "Submit feedback"
                          }
                          </Button>
                      </Fragment>
                    )
                    : ""
                }
              </Fragment>
            )
            : <SuccessMessage message="Thank you for the feedback." />
        }
      </div>
    </div>
  )
}

export default NotInterestedFeedback;