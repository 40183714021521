import { Button } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_ALL_COMPANY_FUNDING_STAGE } from '../../store/utils/action-types';
import { generalStyleClasses } from '../../utils/general-styles';
import CheckboxesGroup from '../CheckboxesGroup/CheckboxesGroup';
import { fundingStage } from './Options/CompanyOptions';

const FundingStage = ({ handleChange, optionalStyle, containerStyle, rootClasses = {}, values=[], enableSelectAll = false, overwriteDefaultValues}) => {
  const classes = generalStyleClasses();
  const dispatch = useDispatch();
  const checkboxesRef = useRef();
  const storeValues = useSelector(store => {
    let selectedValues = [];
    if(store.investorProfile.company_funding_stage_any_stage) {
      selectedValues.push({value: 1});
    }
    if(store.investorProfile.company_funding_stage_post_seed) {
      selectedValues.push({value: 2});
    }
    if(store.investorProfile.company_funding_stage_pre_ipo) {
      selectedValues.push({value: 3});
    }
    if(store.investorProfile.company_funding_stage_seed) {
      selectedValues.push({value: 4});
    }
    if(store.investorProfile.company_funding_stage_series_a) {
      selectedValues.push({value: 5});
    }
    if(store.investorProfile.company_funding_stage_series_b) {
      selectedValues.push({value: 6});
    }
    if(store.investorProfile.company_funding_stage_series_c) {
      selectedValues.push({value: 7});
    }
    if(store.investorProfile.company_funding_stage_series_d) {
      selectedValues.push({value: 8});
    }
    if(store.investorProfile.company_funding_stage_series_f) {
      selectedValues.push({value: 9});
    }
    if(store.investorProfile.company_funding_stage_series_e) {
      selectedValues.push({value: 10});
    }
    if(store.investorProfile.company_funding_stage_convertible_note) {
      selectedValues.push({value: 11});
    }
    if(store.investorProfile.company_funding_stage_other) {
      selectedValues.push({value: 12});
    }
    if(store.investorProfile.company_funding_stage_pre_seed) {
      selectedValues.push({value: 13});
    }
    if(store.investorProfile.company_funding_stage_series_g) {
      selectedValues.push({value: 14});
    }
    if(store.investorProfile.company_funding_stage_series_h) {
      selectedValues.push({value: 15});
    }
    if(store.investorProfile.company_funding_stage_series_i) {
      selectedValues.push({value: 16});
    }
    if(store.investorProfile.company_funding_stage_series_j) {
      selectedValues.push({value: 17});
    }
    if(store.investorProfile.company_funding_stage_series_k) {
      selectedValues.push({value: 18})
    }
    return selectedValues;
  })
  const [defaultValues] = useState(overwriteDefaultValues || storeValues);
  
  const everythingIsSelected = () => {
    return values.length === 18;
  }

  const handleSelectAllButton = () => {
    if(enableSelectAll && checkboxesRef) {
        checkboxesRef.current.setAllValues(!everythingIsSelected())
    }
  }

  useEffect(() => {
    if(defaultValues && defaultValues.length > 0) {
      handleChange(defaultValues);
    }
  }, [defaultValues])

  return (
    <div className={rootClasses}>
    <span className={[classes.fontSize14, classes.fontInputColor].join(' ')}>Financing Round</span>
    {
      enableSelectAll && <Button variant="outlined" color="primary" style={{height: "32px", marginLeft: "16px"}} onClick={() => handleSelectAllButton()}>{everythingIsSelected() ? 'Deselect' : 'Select'} all</Button>
    }
    <div className={classes.marginTop16}>
        <CheckboxesGroup ref={checkboxesRef} optionsInput={fundingStage} optionsStyle={optionalStyle} containerStyle={containerStyle} handleChange={handleChange} defaultValues={defaultValues}/>
    </div>
</div>
  )
}

export default FundingStage;