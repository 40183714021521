// all filters have a resolver and a template. The template should be used to create appropriate inputs for every filter and the resolver is called when the filtering query is built
const filters = {
  _options: {
    valuation: {
      1: { _lte: 1000000 },
      2: { _gt: 1000000, _lte: 5000000 },
      3: { _gt: 5000000, _lte: 2000000000 },
      4: { _gt: 2000000000 },
      __resolve: function (input) {
        let toReturn = Object.keys(input || this.__getTemplate())
          .filter((k) => !!input[k])
          .map((k) => ({ company: { valuation: this[k] } }));
        if (toReturn.length) return toReturn;
        return null;
      },
      __getTemplate: function () {
        return Object.keys(this)
          .filter((k) => !k.startsWith("__"))
          .reduce((acc, k) => ({ ...acc, [k]: false }), {});
      },
    },
    company_age: {
      1: { _gte: new Date().getFullYear() - 5 },
      2: {
        _lt: new Date().getFullYear() - 5,
        _gte: new Date().getFullYear() - 8,
      },
      3: {
        _lt: new Date().getFullYear() - 8,
        _gte: new Date().getFullYear() - 10,
      },
      4: { _lt: new Date().getFullYear() - 10 },
      __resolve: function (input) {
        let toReturn = Object.keys(input || this.__getTemplate())
          .filter((k) => !!input[k])
          .map((k) => ({ company: { founding_year: this[k] } }));
        if (toReturn.length) return toReturn;
        return null;
      },
      __getTemplate: function () {
        return Object.keys(this)
          .filter((k) => !k.startsWith("__"))
          .reduce((acc, k) => ({ ...acc, [k]: false }), {});
      },
    },
    sectors: {
      __resolve: function (input) {
        if (!input || !input.length) return null;
        return {
          company: { sectors: { sector: { _in: input } } },
        };
      },
      __getTemplate: () => [],
    },
    innovation_enablers: {
      __resolve: function (input) {
        if (!input || !input.length) return null;
        return {
          company: {
            innovation_enablers: { innovation_enabler: { _in: input } },
          },
        };
      },
      __getTemplate: () => [],
    },
    status: {
      __resolve: function (input) {
        if (!input) return null;
        return {
          status: {
            _eq: input,
          },
        };
      },
      __getTemplate: () => "",
    },
    funding_stage: {
      __resolve: function (input) {
        if (!input) return null;
        return {
          company: {
            funding_stage: {
              _eq: input,
            },
          },
        };
      },
      __getTemplate: () => "",
    },
    text: {
      __resolve: function (input) {
        if (!input || input === "") return null;
        let ilike = `%${input
          .replace(/\\/, "\\\\")
          .replace(/\%/, "\\%")
          .replace(/\_/, "\\_")}%`;
        return {
          _or: [
            { ENUM_financing_round: { description: { _ilike: ilike } } },
            {
              section_text_areas: {
                _or: {
                  body: { _ilike: ilike },
                  title: { _ilike: ilike },
                },
              }
            },
            {title: {_ilike: ilike}},
            {
              company: {
                _or: [
                  {funding_histories: {notable_investors: {notable_investor_name: {_ilike: ilike}}}},
                  {
                    company_managements: {
                      _or: { bio: { _ilike: ilike }, name: { _ilike: ilike } },
                    },
                  },
                  { ENUM_business_model: { value: { _ilike: ilike } } },
                  { name: { _ilike: ilike } },
                  { description: { _ilike: ilike } },
                  { blurp: { _ilike: ilike } },
                  { hq_city: { _ilike: ilike } },
                  { hq_state: { _ilike: ilike } },
                  { ENUM_country: { description: { _ilike: ilike } } },
                  {
                    innovation_enablers: {
                      ENUM_innovation_enabler: { description: { _ilike: ilike } },
                    }
                  },
                  {
                    sectors: {
                      ENUM_interested_sector: { description: { _ilike: ilike } },
                    }
                  },
                ],
        },
            }
          ],
        };
      },
__getTemplate: () => "",
    },
  },
};

const order_by = {
  recent_asc: { published_at: "desc" },
  recent_desc: { published_at: "asc" },
  ending_asc: { investment_deadline: "asc" },
  ending_desc: { investment_deadline: "desc" },
  name_asc: { company: { name: "asc" } },
  name_desc: { company: { name: "desc" } },
  valuation_asc: { valuation: "asc" },
  valuation_desc: { valuation: "desc" },
  offering_asc: {investment_amount_available: "asc"},
  offering_desc: {investment_amount_available: "desc"},
  __resolve: function (input) {
    let toReturn = Object.keys(input)
      .filter((k) => !!input[k])
      .map((k) => this[k]);
    return !!toReturn.length ? toReturn : null;
  },
  __getTemplate: function () {
    return Object.keys(this)
      .filter((k) => !k.startsWith("__"))
      .reduce((acc, k) => ({ ...acc, [k]: false }), {});
  },
};

export const templateFilter = () => {
  // const templateFilter = () => {
  return {
    valuation: filters._options.valuation.__getTemplate(),
    company_age: filters._options.company_age.__getTemplate(),
    sectors: filters._options.sectors.__getTemplate(),
    innovation_enablers: filters._options.innovation_enablers.__getTemplate(),
    status: filters._options.status.__getTemplate(),
    funding_stage: filters._options.status.__getTemplate(),
    text: filters._options.text.__getTemplate(),
    order_by: order_by.__getTemplate(),
  };
};

// const appliedFilter = [
//   { last_valuation: { "< 1000": true, "> 20000": false } },
//   { industry_types: ["active", "inactive"] },
// ];

export const filterBuilder = (inputs, sorter, limit, offset, options = {}) => {
  // const filterBuilder = (inputs, sorter, limit, offset, options = {}) => {
  // options = {}; // default and for overall; default or for group
  const overall = (overallOr, value) =>
    !value || !value.length
      ? null
      : overallOr
        ? { _or: value }
        : { _and: value };
  const group = (groupAnd, value) =>
    !value ? null : groupAnd ? { _and: value } : { _or: value };

  let value = Object.keys(inputs)
    .map((key) => {
      if (key !== "order_by") {
        let groupValue = group(
          options.groupAnd,
          filters._options[key].__resolve(inputs[key])
        );
        return groupValue;
      }
    })
    .filter((group) => group);
  value.push({ published_at: { _neq: 0 } });
  var condition = {
    where: overall(options.overallOr, value),
    order_by: order_by.__resolve(sorter),
    limit,
    offset,
  };
  return JSON.stringify(condition)
    .replace(/"([^"]+)":/g, "$1:")
    .replace(/eq:"([^"]+)"/g, "eq:$1")
    .replace(/\["([^"]+)"/g, "[$1")
    .replace(/,"([^"]+)"/g, ",$1")
    .replace(/:"asc"/g, ":asc")
    .replace(/:"desc"/g, ":desc");
};

// const testFlow = () => {
//   let templateExample = templateFilter();
//   console.log("templateExample", templateExample);

//   templateExample.industry_types.push("SOFTWARE");
//   templateExample.industry_types.push("CONSUMER_INTERNET");
//   // templateExample.text = "ayy lmao";
//   templateExample.funding_stage = "LATE";
//   // templateExample.last_valuation["1"] = true;
//   // templateExample.last_valuation["2"] = true;
//   let order_by = templateExample.order_by;
//   delete templateExample.order_by;
//   // order_by.recent_asc = true;
//   // order_by.name_desc = true;
//   console.log("inputs", templateExample);
//   console.log("order_by", order_by);
//   console.log(filterBuilder(templateExample, order_by));
// };

// testFlow();
