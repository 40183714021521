import { gql } from "@apollo/client";
import store from "../store/store";

export const GET_ALL_DEALS_SUMARY = gql`
  query GetDealsSumary($filter: [ENUM_deal_status_enum!]!) {
    Deal(where: { status: { _in: $filter } }) {
      Investments {
        value
      }
      investment_value
      company {
        name
        hq_city
        hq_country
        hq_state
        funding_stage
        proposed_valuation
        funding_histories {
          notable_investors(where: {notable_investor_is_summary: {_eq: true}}) {
            notable_investor_name
            notable_investor_is_summary
          }
        }
        blurp
      }
      id
      status
      opening_date
      security_type
      valuation
      investment_deadline
      investment_amount_available
      watchlist {
        id
      }
    }
  }
`;

export const getDealSummaryQuery = (query) => {
  return `
  query GetDealsSumary($firebase_id: String!) {
    Deal(${query}) {
      Investments {
        value
      }
      investment_value
      company {
        name
        logo
        id
        hq_city
        hq_country
        hq_state
        funding_stage
        proposed_valuation
        funding_histories {
          notable_investors(where: {notable_investor_is_summary: {_eq: true}}) {
            notable_investor_name
            notable_investor_is_summary
          }
        }
        blurp
                }
      title
      id
      status
      opening_date
      security_type
      valuation
      investment_deadline
      investment_amount_available
      watchlist(where: {firebase_id: {_eq: $firebase_id}}) {
        id
      }
    }
  }`;
};

export const GET_WATCHLISTED_DEALS_SUMARY = (firebaseId, companyId, orderString = "", nameFilter) => {
  return  gql`
  query GetDealsSumary {
    Deal(
      where: {
        watchlist: { firebase_id: { _eq: "${firebaseId}" } }
        company_id: { _eq: ${companyId} }
        ${nameFilter && `
          _or: [
            { ENUM_financing_round: { description: { _ilike: "%${nameFilter}%" } } },
                {
                  section_text_areas: {
                    _or: {
                      body: { _ilike: "%${nameFilter}%" },
                      title: { _ilike: "%${nameFilter}%" },
                    },
                  }
                },
                {title: {_ilike: "%${nameFilter}%"}},
                {
                  company: {
                    _or: [
                      {funding_histories: {notable_investors: {notable_investor_name: {_ilike: "%${nameFilter}%"}}}},
                      {
                        company_managements: {
                          _or: { bio: { _ilike: "%${nameFilter}%" }, name: { _ilike: "%${nameFilter}%" } },
                        },
                      },
                      { ENUM_business_model: { value: { _ilike: "%${nameFilter}%" } } },
                      { name: { _ilike: "%${nameFilter}%" } },
                      { description: { _ilike: "%${nameFilter}%" } },
                      { blurp: { _ilike: "%${nameFilter}%" } },
                      { hq_city: { _ilike: "%${nameFilter}%" } },
                      { hq_state: { _ilike: "%${nameFilter}%" } },
                      { ENUM_country: { description: { _ilike: "%${nameFilter}%" } } },
                      {
                        innovation_enablers: {
                          ENUM_innovation_enabler: { description: { _ilike: "%${nameFilter}%" } },
                        }
                      },
                      {
                        sectors: {
                          ENUM_interested_sector: { description: { _ilike: "%${nameFilter}%" } },
                        }
                      },
                    ],
            },
                }
          ]
        ` || ''
      }
    }
      order_by: {${orderString}}
    ) {
      Investments {
        value
      }
      investment_value
      company {
        name
        id
        hq_city
        hq_country
        hq_state
        logo
        funding_stage
        proposed_valuation
        funding_histories {
          notable_investors(where: {notable_investor_is_summary: {_eq: true}}) {
            notable_investor_name
            notable_investor_is_summary
          }
        }
        blurp
      }
      title
      id
      status
      updated_at
      opening_date
      security_type
      valuation
      investment_deadline
      investment_amount_available
      watchlist {
        id
      }
    }
  }
`;
}

export const GET_DEAL_INVESTMENTS_SUMARY = (isCompleted = false, isInProgress = false,  orderString = '', nameFilter = null,) => {
  return gql`query GetDealsSumary($firebase_id: String = "") {
  Deal(where: {
    _and: [
      {
        _or: [
          {Investments: {firebase_id: {_eq: $firebase_id}, deleted_at: {_is_null: true}}}, 
          {Interests: {firebase_id: {_eq: $firebase_id}}}
        ]
      },
      ${isCompleted && "{Investments: {firebase_id: {_eq: $firebase_id}, completed: {_neq: 0}}}," || ""}
      ${isInProgress && "{Investments: {firebase_id: {_eq: $firebase_id}, completed: {_eq: 0}}}," || ""}
      ${nameFilter && `{
        _or: [
          { ENUM_financing_round: { description: { _ilike: "%${nameFilter}%" } } },
              {
                section_text_areas: {
                  _or: {
                    body: { _ilike: "%${nameFilter}%" },
                    title: { _ilike: "%${nameFilter}%" },
                  },
                }
              },
              {title: {_ilike: "%${nameFilter}%"}},
              {
                company: {
                  _or: [
                    {funding_histories: {notable_investors: {notable_investor_name:{_ilike: "%${nameFilter}%"}}}},
                    {
                      company_managements: {
                        _or: { bio: { _ilike: "%${nameFilter}%" }, name: { _ilike: "%${nameFilter}%" } },
                      },
                    },
                    { ENUM_business_model: { value: { _ilike: "%${nameFilter}%" } } },
                    { name: { _ilike: "%${nameFilter}%" } },
                    { description: { _ilike: "%${nameFilter}%" } },
                    { blurp: { _ilike: "%${nameFilter}%" } },
                    { hq_city: { _ilike: "%${nameFilter}%" } },
                    { hq_state: { _ilike: "%${nameFilter}%" } },
                    { ENUM_country: { description: { _ilike: "%${nameFilter}%" } } },
                    {
                      innovation_enablers: {
                        ENUM_innovation_enabler: { description: { _ilike: "%${nameFilter}%" } },
                      }
                    },
                    {
                      sectors: {
                        ENUM_interested_sector: { description: { _ilike: "%${nameFilter}%" } },
                      }
                    },
                  ],
          },
              }
        ]
      }` || ''}
    ]
}
order_by: {${orderString}}
) {
  investment_value
    company {
      name
      id
      logo
      hq_city
      hq_country
      hq_state
      funding_stage
      proposed_valuation
      funding_histories {
        notable_investors(where: {notable_investor_is_summary: {_eq: true}}) {
          notable_investor_name
          notable_investor_is_summary
        }
      }
      blurp
    }
    title
    id
    status
    opening_date
    security_type
    valuation
    investment_deadline
    investment_amount_available
    watchlist {
      id
    }
    Interests(where: {firebase_id: {_eq: $firebase_id}}) {
      ammount
    }
    Investments(where: {firebase_id: {_eq: $firebase_id}, deleted_at: {_is_null: true}}) {
      id
      value
      created_at
      completed
    }
  }
}
`;
}

export const GET_DEAL_SECTION_ORDER_BY_ID = gql`
  query GetDealSectionOrder($id: bigint!) {
    Deal_by_pk(id: $id) {
      section_text_areas {
        id
        order
        title
        body
      }
      status
      company_description_order
      company_management_order
      funding_history_order
      section_images {
        id
        order
        file_id
        title
      }
      section_files {
        title
        id
        order
        file(where: {deleted: {_is_null: true}}) {
          id
          name
        }
      }
      company_id
    }
  }
`;

export const DEAL_COMPANY_MANAGEMENT_BY_ID = gql`
  query GetDealCompanyManagement($id: bigint!) {
    Deal_by_pk(id: $id) {
      company {
        company_managements {
          name
          position
          bio
        }
      }
    }
  }
`;

export const DEAL_COMPANY_DESCRIPTION_BY_ID = gql`
  query GetDealCompanyDescription($id: bigint!) {
    Deal_by_pk(id: $id) {
      company {
        description
      }
    }
  }
`;

export const ADD_TO_WATCHLIST = gql`
  mutation AddToWatchlist($deal_id: bigint!) {
    insert_Watchlist(objects: { deal_id: $deal_id }) {
      affected_rows
    }
  }
`;

export const DELETE_FROM_WATCHLIST = gql`
  mutation DeleteWatchlist($deal_id: bigint!) {
    delete_Watchlist(where: { deal_id: { _eq: $deal_id } }) {
      affected_rows
    }
  }
`;

export const GET_DEAL_TITLE_SECTION = gql`
  query MyQuery($id: bigint!) {
    Deal_by_pk(id: $id) {
      Investments {
        value
      }
      investment_value
      company {
        name
        hq_city
        hq_country
        hq_state
        founding_year
        logo
      }
      title
      opening_date
      status
      investment_amount_available
    }
  }
`;

export const GET_DEAL_OVERVIEW_SECTION = gql`
query MyQuery($deal_id: bigint = "") {
  Deal(where: {id: {_eq: $deal_id}}) {
    status
    offering_type
    valuation
    investment_value
    fees
    company  {
      sectors {
        ENUM_interested_sector {
          description
        }
      }
      innovation_enablers {
        ENUM_innovation_enabler {
          description
        }
      }
      funding_stage
      entity_type
      last_valuation
    }
  }
}

`;

export const GET_DEAL_FUNDING_HISTORY = gql`
query MyQuery($id: bigint!) {
  Deal_by_pk(id: $id) {
    company {
      funding_histories {
        financing_round
        date
        notable_investors {
          notable_investor_name
        }
        pre_money_valuation
        pre_money_valuation_currency
        amount_raised
        amount_raised_curency
      }
    }
  }
}
`;

export const GET_DEAL_COUNT = gql`
query MyQuery {
  Deal_aggregate(where: {published_at: {_neq: "0"}, status: {_nin: [CANCELED, VOIDED]}}) {
    aggregate {
      count
    }
  }
}

`;

export const GET_DEAL_COMPANY_SECTORS = gql`
  query MyQuery($deal_id: bigint = "") {
    Deal(where: { id: { _eq: $deal_id } }) {
      company {
        name
        sectors {
          ENUM_interested_sector {
            description
          }
        }
      }
    }
  }
`;

export const GET_DEAL_COMPANY_NAME = gql`
query GetDealCompanyName($id: bigint!, $firebase_id: String = "") {
  Deal_by_pk(id: $id) {
    company {
      name
    }
    investment_max
    investment_min
    Investments(where: {firebase_id: {_eq: $firebase_id}, deleted_at: { _is_null: true}}) {
      value
    }
  }
}
`;

export const GET_DEAL_SECTIONS = gql`
  query MyQuery($id: bigint!) {
    Deal_by_pk(id: $id) {
      section_files {
        id
        order
        title
      }
      section_links {
        id
        order
        title
      }
      section_images {
        id
        order
        title
      }
      section_text_areas {
        id
        order
        title
      }
      company_description_order
      company_management_order
      funding_history_order
    }
  }
`;

export const ADD_DEAL_FEEDBACK = gql`
mutation MyMutation($company: String = "", $deal_id: bigint = "", $firebase_id: String = "", $industry: String = "", $investment: String = "", $other: String = "") {
  insert_Interest_feedback(objects: {company: $company, deal_id: $deal_id, firebase_id: $firebase_id, industry: $industry, investment: $investment, other: $other}, on_conflict: {constraint: Interest_feedback_firebase_id_deal_id_key, update_columns: company}) {
    affected_rows
  }
}
`;

export const UPDATE_DEAL_FEEDBACK = gql`
mutation MyMutation($company: String = "", $deal_id: bigint = "", $firebase_id: String = "", $industry: String = "", $investment: String = "", $other: String = "") {
  update_Interest_feedback(where: {firebase_id: {_eq: $firebase_id}, deal_id: {_eq: $deal_id}}, _set: {company: $company, industry: $industry, investment: $investment, other: $other}) {
    affected_rows
  }
}
`;

export const GET_DEAL_SUBMENU_DATA = gql`
query MyQuery($deal_id: bigint!, $firebase_id: String!) {
  Interest_feedback(where: {deal_id: {_eq: $deal_id}, firebase_id: {_eq: $firebase_id}}) {
    id
  }
  Interest(where: {deal_id: {_eq: $deal_id}, firebase_id: {_eq: $firebase_id}}) {
    id
    ammount
  }
  Deal_by_pk(id: $deal_id) {
    status
    Investments(where: {firebase_id: {_eq: $firebase_id}, deleted_at: {_is_null: true}}) {
      id
      value
      completed
    }
    company_id
  }
  Watchlist(where: {deal_id: {_eq: $deal_id}, firebase_id: {_eq: $firebase_id}}) {
    id
  }
}
`;

export const GET_INVESTMENT_FLOW_BY_ID = gql`
query MyQuery($id: bigint = "") {
  Investment(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}) {
    Investment_flow_section_edges(limit: 1) {
      Flow_node {
        name
        Investment_flow_section_edges(where: {investment_id: {_eq: $id}}) {
          current
          completed
          order
          shown
          id
          Investment_section_item_edge(where: {investment_id: {_eq: $id}}) {
            order
            id
            Flow_item_node {
              name
              text
              type
            }
            Files_metadata(where: {deleted: {_is_null: true}}) {
              name
              id
            }
          }
          Flow_section_node {
            name
          }
        }
      }
    }
  }
}
`;

export const GET_INVESTMENT_FLOW_ITEMS_BY_EDGE_ID = gql`
query MyQuery($id: bigint!) {
  Investment_section_item_edge_by_pk(id: $id) {
    Files_metadata(where: {deleted: {_is_null: true}}) {
      name
      id
    }
    id
  }
}
`;
export const getDealInvestmentFlow = (dealId, value) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/investment/insert`, {
    method: "POST",
    body: JSON.stringify({
      dealId,
      value,
    }),
    headers: {
      Authorization: `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json",
    },
  });
};

export const GET_COMPANY_NAMES = gql`
  query MyQuery($firebase_id: String = "") {
    Watchlist(where: { firebase_id: { _eq: $firebase_id } }) {
      deal {
        company {
          id
          name
        }
      }
    }
  }
`;

export const GET_AVALABILE_CHAT_ADMINS = gql`
query MyQuery($investor_id: String = "", $deal_id: bigint = "") {
  deal_leaders(where: {deal_id: {_eq: $deal_id}}) {
    firebase_id
    first_name
    last_name
    __typename
  }
  relationship_managers(where: {investor_firebase_id: {_eq: $investor_id}}) {
    firebase_id
    first_name
    last_name
    __typename
  }
  Deal_chat(where: {investor_id: {_eq: $investor_id}, deal_id: {_eq: $deal_id}}) {
    employee_id
    id
    __typename
  }
}
`;

export const GET_GENERAL_CHAT_ADMINS = gql`
query MyQuery($investor_id: String = "") {
  relationship_managers(where: {investor_firebase_id: {_eq: $investor_id}}) {
    firebase_id
    first_name
    last_name
    __typename
  }
  account_basic_info(where: {permissions: {permission: {id: {_eq: 3}}}}) {
    firebase_id
    first_name
    last_name
  }
  General_chat(where: {investor_id: {_eq: $investor_id}}) {
    employee_id
    id
    __typename
  }
}`;

export const GET_COMPANY_NAME = gql`
query MyQuery($id: bigint!) {
  Deal_by_pk(id: $id) {
    status
    company {
      name
    }
  }
}
`;

export const GET_DEAL_CHAT_CONVERSATIONS = gql`
query MyQuery($investor_id: String = "", $deal_id: bigint = "") {
  Deal_chat(where: {investor_id: {_eq: $investor_id}, deal_id: {_eq: $deal_id}}) {
    employee_id
    id
  }
}
`;

export const CREATE_DEAL_CHAT = gql`
mutation MyMutation($employee_id: String = "", $investor_id: String = "", $deal_id: bigint = "") {
  insert_Deal_chat(objects: {employee_id: $employee_id, investor_id: $investor_id, deal_id: $deal_id}) {
    affected_rows
    returning {
      id
    }
  }
}
`;

export const SEND_DEAL_MESSAGE = gql`
mutation MyMutation($message: String = "", $firebase_id: String = "", $deal_chat_id: bigint = "") {
  insert_Deal_chat_message(objects: {message: $message, firebase_id: $firebase_id, deal_chat_id: $deal_chat_id, type: "TEXT"}) {
    affected_rows
  }
}
`;

export const GET_DEAL_CHAT_MESSAGES_BY_CHAT_ID = gql`
subscription MySubscription($deal_chat_id: bigint = "") {
  Deal_chat_message(where: {deal_chat_id: {_eq: $deal_chat_id}}, order_by: {created_at: asc}) {
    message
    type
    id
    firebase_id
    created_at
  }
}

`;

export const GET_CREATED_CHATS_PEOPLE = gql`
query MyQuery($investor_id: String = "") {
  Deal_chat(where: {investor_id: {_eq: $investor_id}}) {
    deal_id
    Deal {
      title
      company {
        name
      }
    }
    account_basic_info_employee {
      first_name
      last_name
    }
    Deal_chat_messages(limit: 1, order_by: {created_at: asc}) {
      created_at
    }
    id
  }
}
`
export const GET_DEAL_FEEDBACK_BY_USER_ID = gql`
query MyQuery($firebase_id: String = "", $deal_id: bigint = "") {
  Interest_feedback(where: {firebase_id: {_eq: $firebase_id}, deal_id: {_eq: $deal_id}}) {
    industry
    investment
    other
    company
    id
  }
}
`;

export const GET_ALL_INVESTMENTS = gql`
query MyQuery($firebase_id: String!) {
  Account_by_pk(firebase_id: $firebase_id) {
    Investments(where: {deal_id: {_is_null: false}, deleted_at: {_is_null: true}}) {
      deal_id
      updated_at
      id
      deal {
        title
        company {
          name
        }
      }
      value
      completed
      Investment_flow_section_edges(where: {flow_id: {_is_null: false}}, limit: 1) {
        flow_id
      }
    }
  }
}
`;

export const GET_AMMOUNT_AVAILABLE = gql`
query MyQuery($id: bigint!, $firebase_id: String = "") {
  Deal_by_pk(id: $id) {
    status
    investment_max
    investment_min
    Investments(where: {firebase_id: {_eq: $firebase_id}, deleted_at: {_is_null: true}}) {
      value
      id
    }
  }
}
`;

export const INSERT_INTEREST = gql`
mutation MyMutation($ammount: bigint!, $firebase_id: String! $deal_id: bigint!) {
  insert_Interest(objects: {ammount: $ammount, firebase_id: $firebase_id, deal_id: $deal_id}) {
    affected_rows
  }
}
`;

export const UPDATE_INTEREST = gql`
mutation MyMutation($deal_id: bigint!, $firebase_id: String!, $ammount: bigint!) {
  update_Interest(where: {deal_id: {_eq: $deal_id}, firebase_id: {_eq: $firebase_id}}, _set: {ammount: $ammount}) {
    affected_rows
  }
}
`

export const GET_INTEREST = gql`
query MyQuery($deal_id: bigint = "", $firebase_id: String = "") {
  Interest(where: {deal_id: {_eq: $deal_id}, firebase_id: {_eq: $firebase_id}}) {
    ammount
  }
}
`;

export const cancelDealInvestment = (investmentId) => {
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/investment/cancel`, {
      method: "POST",
      body: JSON.stringify({
        investmentId,
      }),
      headers: {
        Authorization: `Bearer ${store.getState().account.jwtToken}`,
        "Content-Type": "application/json",
      },
    });
};

export const GET_INVESTMENT_VALUE = gql`
query MyQuery($id: bigint!) {
  Investment_by_pk(id: $id) {
    value
  }
}
`;

export const GET_INVESTMENT_BY_USER_AND_DEAL_ID = gql`
query MyQuery($deal_id: bigint = "", $firebase_id: String = "") {
  Investment(where: {deal_id: {_eq: $deal_id}, firebase_id: {_eq: $firebase_id}}) {
    id
  }
}
`;
