import { useQuery } from '@apollo/client';
import { Avatar, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GET_CREATED_CHATS_PEOPLE } from '../../../utils/deal-queries';
import { formatMessageDate } from '../../../utils/formats';
import { generalStyleClasses } from '../../../utils/general-styles';

const customClasses = makeStyles({
  personContainer: {
    cursor: "pointer",
    display: "flex",
    background: "#F4F4F4",
    padding: "16px",
    "&:hover": {
      background: "#eaeaea"
    }
  },
  avatar: {
    color: "black",
    background: "#F0E2E2",
    marginRight: "12px"
  },
  allPersonsContainer: {
    top: "112px",
    bottom: "0",
    borderRight: "1px solid rgb(231, 231, 231)",
    marginTop: "16px"
  }
})

const PersonComponent = ({person, isActive = false, handleOnClick = () => null}) => {
  const classes = customClasses();
  const generalClasses = generalStyleClasses();

  return (
    <div className={classes.personContainer} onClick={() => handleOnClick()} style={{background: isActive && "#d2d2d2"}}>
      <Avatar className={classes.avatar}>{`${person.firstName.toUpperCase()[0] || ''}${person.lastName.toUpperCase()[0] || ''}`}</Avatar>
      <div>
      <p className={[generalClasses.fontSize13, generalClasses.marginTop0, generalClasses.marginBottom8].join(' ')} style={{color: "rgb(80 81 84)"}}>{person.title}</p>
        <p className={[generalClasses.fontSize14, generalClasses.marginTop0, generalClasses.marginBottom8].join(' ')} style={{color: "#000000"}}>{`${person.firstName || ''} ${person.lastName || ''}`}</p>
        <p className={[generalClasses.fontSize12, generalClasses.marginTop0, generalClasses.marginBottom8].join(' ')} style={{color: "#959595"}}>
          { formatMessageDate(person.lastMessageDate)}
        </p>
      </div>
    </div>
  )
}

const ChatPeople = ({handleSetAdmin}) => {
  const classes = customClasses();
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const {data} = useQuery(GET_CREATED_CHATS_PEOPLE, {variables: {investor_id: firebaseId}});
  const [people, setPeople] = useState([]);
  const [selectedPersonId, setSelectedPersonId] = useState(0);

  const handleSelectNewAdmin = (admin) => {
    setSelectedPersonId(admin.chatId);
    if(handleSetAdmin) {
      handleSetAdmin(admin);
    }
  }

  useEffect(() => {
    if(data && data.Deal_chat) {
      setPeople(data.Deal_chat.map(chat => ({
        title: `${chat.Deal.company.name}${chat.Deal.title && ` - ${chat.Deal.title}` || ''}`,
        chatId: chat.id,
        dealId: chat.deal_id,
        firstName: chat.account_basic_info_employee?.first_name || 'Name',
        lastName: chat.account_basic_info_employee?.last_name || 'not available',
        newMessages: false,
        lastMessageDate: new Date(chat.Deal_chat_messages[0]?.created_at || Date.now()).valueOf()
      })))
    }
  }, [data])

  useEffect(() => {
    if(!selectedPersonId && people.length > 0) {
      handleSetAdmin(people[0]);
      setSelectedPersonId(people[0].chatId)
    }
  }, [selectedPersonId, people])

  return (
    <div className={classes.allPersonsContainer}>
      {people.map((person, index) => <PersonComponent key={index} person={person} handleOnClick={() => handleSelectNewAdmin(person)} isActive={person.chatId === selectedPersonId}/>)}
    </div>
  )
}

export default ChatPeople;