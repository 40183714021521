import { gql } from "@apollo/client";

export const GET_ALL_ACCOUNTS_FROM_WHITELISTED_WITH_ROLE = gql`
query GetAccounts(
  $roleId: bigint!
  $name: order_by = null
  $email: order_by = null
  $updated_at: order_by = null
  $created_at: order_by = null
) {
  Whitelist(
    where: { role_id: { _eq: $roleId }}
    order_by: {
      account: { first_name: $name, last_name: $name }
      email: $email
      updated_at: $updated_at
      created_at: $created_at
    }
  ) {
    email
    created_at
    updated_at
    status
    role_id
    account {
      disabled
      Onboarding_flow_section_edges(limit: 1) {
        flow_id
      }
      first_name
      last_name
      firebase_id
      email
    }
  }
}
`;

export const GET_ALL_ACCOUNTS = gql`
query GetAccounts($name: order_by = null, $account_created_at: order_by = null, $approved_date: order_by = null, $email: order_by = null) {
  Whitelist(where: {account: {permissions: {permission: {role_type: {_eq: INVESTOR}}}}}, order_by: {account: {created_at: $account_created_at, first_name: $name, last_name: $name}, approved_at: $approved_date, email: $email}) {
    email
    created_at
    approved_at
    status
    role_id
    firebase_id
    account {
      first_name
      last_name
      email
      disabled
      accepted_confidentiality
      investor_profile {
        created_at
      }
      last_name
      phone_number
      city
      country
      first_name
      permissions(where: {deal_id: {_is_null: true}}) {
        role_id
        permission {
          role
        }
      }
      Relationship_managers {
        firebase_id
        id
      }
    }
  }
}
`;

export const GET_NOT_APPROVED_EMAILS = gql`
  query GetAccounts {
    Whitelist(where: { role_id: { _neq: "1" } }) {
      email
    }
  }
`;

export const GET_ACCOUNT_BY_ID = gql`
  query GetAccountById($userId: String!) {
    Account_by_pk(firebase_id: $userId) {
      accepted_confidentiality
      city
      country
      email
      firebase_id
      first_name
      phone_number
      last_name
      email_preference_frequency
      email_preference_investment_opportunities
      email_preference_investment_tips
      email_preference_personalised_deals
      email_preference_watchlist
      investor_profile {
        id
      }
      permissions(where: { deal_id: { _is_null: true } }) {
        role_id
      }
    }
  }
`;

export const GET_ALL_PERMISSIONS = gql`
  query GetAllPermissions {
    Permissions(where: {role_type: {_in: [EMPLOYEE, DELEGATE]}, id: {_neq: "10"}}) {
      id
      role
    }
  }
`;

export const GET_PERMISSIONS_DETAILS_FOR_EMPLOYEE = gql`
query GetAllEmployeesPermissions($firebase_id: String!) {
  Account(where: {firebase_id: {_eq: $firebase_id}}) {
    first_name
    last_name
    dealPermissions: permissions(where: {deal_id: {_is_null: false}}) {
      role_id
      deal {
        id
        title
        company {
          name
        }
      }
    }
    investorPermissions: permissions(where: {investor_firebase_id: {_is_null: false}}) {
      role_id
      Account {
        firebase_id
        first_name
        last_name
      }
    }
  }
}
`

export const GET_ALL_EMPLOYEES_PERMISSIONS = gql`
  query GetAllEmployeesPermissions(
    $name: order_by = null
    $email: order_by = null
  ) {
    Account(
      order_by: { first_name: $name, last_name: $name, email: $email }
      where: {
        permissions: {
          permission: { role_type: {_in: [EMPLOYEE, DELEGATE]}, id: {_neq: "10"} } 
        }
      }
    ) {
      first_name
      last_name
      email
      firebase_id
      permissions(where: { deal_id: { _is_null: true } }) {
        role_id
      }
    }
  }
`;

export const UPDATE_PERMISSIONS_BY_ID = gql`
  mutation UpdatePermissionsById(
    $firebaseId: String!
    $permissions: [Permissions_assignment_insert_input!]!
  ) {
    delete_Permissions_assignment(
      where: { firebase_id: { _eq: $firebaseId }, deal_id: { _is_null: true } }
    ) {
      affected_rows
    }
    insert_Permissions_assignment(objects: $permissions) {
      affected_rows
    }
  }
`;

export const ADD_GENERAL_PERMISSION = gql`
mutation MyMutation($role_id: bigint = "", $firebase_id: String = "") {
  insert_Permissions_assignment_one(object: {role_id: $role_id, firebase_id: $firebase_id}) {
    role_id
    id
  }
}
`
export const DELETE_GENERAL_PERMISSION = gql`
mutation MyMutation($role_id: [bigint!]!, $firebase_id: String = "") {
  delete_Permissions_assignment(where: {firebase_id: {_eq: $firebase_id}, role_id: {_in: $role_id}}) {
    affected_rows
  }
}
` 

export const ADD_COMPANY = gql`
mutation MyMutation($name: String = "", $founding_year: bigint = "", $hq_country: ENUM_countries_enum = AD, $hq_state: String = "", $hq_city: String = "", $funding_stage: ENUM_funding_stage_enum = EARLY, $sectors: [Company_sectors_insert_input!]!, $innovation_enablers: [Company_innovation_enablers_insert_input!]!, $business_model: ENUM_business_model_enum = B2B, $description: String = "", $blurp: String = "", $entity_type: String = "", $funding_history: [Company_funding_history_insert_input!]!, $company_management: [Company_management_insert_input!]!, $stage: ENUM_company_stage_enum!, $company_security: String = "", $business_status: String = "", $last_valuation: bigint!, $estimated_opening: String = "", $proposed_valuation: String = "", $investment_deadline: String = "", $available_to_invest: String = "", $notable_investor_names: [ENUM_notable_investors_insert_input!]!) {
  insert_ENUM_notable_investors(objects: $notable_investor_names, on_conflict: {constraint: ENUM_notable_investors_pkey, update_columns: value}) {
    affected_rows
    __typename
  }
  insert_Company(objects: {name: $name, founding_year: $founding_year, hq_country: $hq_country, hq_state: $hq_state, hq_city: $hq_city, funding_stage: $funding_stage, sectors: {data: $sectors}, innovation_enablers: {data: $innovation_enablers}, business_model: $business_model, description: $description, blurp: $blurp, entity_type: $entity_type, funding_histories: {data: $funding_history}, company_managements: {data: $company_management}, stage: $stage, company_security: $company_security, business_status: $business_status, last_valuation: $last_valuation, estimated_opening: $estimated_opening, proposed_valuation: $proposed_valuation, investment_deadline: $investment_deadline, available_to_invest: $available_to_invest}) {
    affected_rows
    returning {
      id
      __typename
    }
    __typename
  }
}
`;

export const GET_ALL_COMPANIES = gql`
query GetCompanies($created_at: order_by = null, $updated_at: order_by = null, $name: order_by = null, $nameFilter: String = "%%", $countOrder: order_by = asc) {
  Company(order_by: {created_at: $created_at, updated_at: $updated_at, name: $name, Deals_aggregate: {count: $countOrder}}, where: {name: {_ilike: $nameFilter}}) {
    id
    name
    created_at
    updated_at
    unpublished_at
    Deals_aggregate(where: {published_at: {_neq: "0"}}) {
      aggregate {
        count
      }
    }
  }
}
`;

export const DELETE_COMPANY_BY_ID = gql`
  mutation DeleteCompany($id: bigint!) {
    delete_Company_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_COMPANY_OVERVIEW = gql`
query MyQuery($id: bigint!) {
  Company_by_pk(id: $id)  {
    entity_type
    stage
    available_to_invest
    last_valuation
    business_status
    funding_stage
    sectors {
      ENUM_interested_sector {
        description
      }
    }
    innovation_enablers {
      ENUM_innovation_enabler {
        description
      }
    }
}
}
`;

export const GET_COMPANY_DETAILS_BY_ID = gql`
query MyQuery($id: bigint!) {
  Company_by_pk(id: $id) {
    name
    hq_city
    hq_country
    hq_state
    founding_year
    last_valuation
    funding_stage
    sectors {
      ENUM_interested_sector {
        description
        value
      }
    }
    innovation_enablers {
      ENUM_innovation_enabler {
        description
        value
      }
    }
    business_model
    stage
    entity_type
    description
    blurp
    funding_histories {
      pre_money_valuation
      financing_round
      date
      pre_money_valuation_currency
      notable_investors {
        notable_investor_name
        notable_investor_is_summary
      }
      amount_raised
      amount_raised_curency
    }
    company_managements {
      name
      position
      bio
    }
  }
}
`;

export const UPDATE_COMPANY_BY_ID = gql`
mutation MyMutation($company_id: bigint!
   $name: String = "",
    $hq_state: String = "",
    $hq_country: ENUM_countries_enum = AD,
    $hq_city: String = "",
    $founding_year: bigint = "",
    $entity_type: String = "",
    $business_model: ENUM_business_model_enum = B2B,
    $blurp: String = "",
    $description: String = "",
    $stage: ENUM_company_stage_enum = GENERATES_REVENUE,
    $sectors: [Company_sectors_insert_input!]!,
    $innovation_enablers: [Company_innovation_enablers_insert_input!]!,
    $notable_investors: [ENUM_notable_investors_insert_input!]!,
    $funding_history: [Company_funding_history_insert_input!]!,
    $company_management: [Company_management_insert_input!]!,
    $funding_stage: ENUM_funding_stage_enum = EARLY,
    $last_valuation: bigint = "") {
  update_Company_by_pk(_set: {name: $name, hq_state: $hq_state, hq_country: $hq_country, hq_city: $hq_city, founding_year: $founding_year, entity_type: $entity_type, business_model: $business_model, blurp: $blurp, description: $description, stage: $stage, funding_stage: $funding_stage, last_valuation: $last_valuation}, pk_columns: {id: $company_id}) {
    updated_at
  }
  delete_Company_sectors(where: {company_id: {_eq: $company_id}}) {
    affected_rows
  }
  insert_Company_sectors(objects: $sectors) {
    affected_rows
  }
  delete_Company_innovation_enablers(where: {company_id: {_eq: $company_id}}) {
    affected_rows
  }
  insert_Company_innovation_enablers(objects: $innovation_enablers) {
    affected_rows
  }
  insert_ENUM_notable_investors(objects: $notable_investors, on_conflict: {constraint: ENUM_notable_investors_pkey, update_columns: value}) {
    affected_rows
  }
  delete_Company_funding_history(where: {company_id: {_eq: $company_id}}) {
    affected_rows
  }
  insert_Company_funding_history(objects: $funding_history) {
    affected_rows
  }
  delete_Company_management(where: {Company: {id: {_eq: $company_id}}}) {
    affected_rows
  }
  insert_Company_management(objects: $company_management) {
    affected_rows
  }
}

`;

export const GET_INVESTOR_DETAILS = gql`
  query MyQuery($firebase_id: String!) {
    Account_by_pk(firebase_id: $firebase_id) {
      email
      created_at
      first_name
      last_name
    }
  }
`;

export const GET_INVESTOR_PROFILE_BY_ID = gql`
  query MyQuery($firebase_id: String = "") {
    Investor_profile(
      where: { account: { firebase_id: { _eq: $firebase_id } } }
    ) {
      company_business_model_B2B
      company_business_model_B2B2C
      company_business_model_B2C
      company_development_stage_build_product
      company_development_stage_generates_revenue
      company_development_stage_has_product
      company_development_stage_is_profitable
      company_funding_stage_seed
      company_funding_stage_post_seed
      company_funding_stage_series_a
      company_funding_stage_series_b
      company_funding_stage_series_c
      company_funding_stage_series_d
      company_funding_stage_series_e
      company_funding_stage_series_f
      company_funding_stage_pre_ipo
      company_funding_stage_any_stage
      company_funding_stage_convertible_note
      company_funding_stage_other
      company_funding_stage_pre_seed
      company_funding_stage_series_g
      company_funding_stage_series_h
      company_funding_stage_series_i
      company_funding_stage_series_j
      company_funding_stage_series_k
      countries {
        ENUM_country {
          description
        }
      }
      industry_innovation_enablers
      industry_interested_sectors
      innovation_enablers {
        ENUM_innovation_enabler {
          description
        }
      }
      interested_sectors {
        ENUM_interested_sector {
          description
        }
      }
      investment_execution_timeline
      investment_security_type
      investment_size_high
      investment_size_low
      investment_status
      investment_type
      investor_profile_innovation_enablers_lesses {
        ENUM_innovation_enabler {
          description
        }
      }
      investor_profile_interested_sectors_lesses {
        ENUM_interested_sector {
          description
        }
      }
      company_additional_information
      industry_additional_information
      investment_additional_information
    }
    Account(where: { firebase_id: { _eq: $firebase_id } }) {
      city
      country
      email
      first_name
      last_name
      permissions {
        role_id
      }
      phone_number
      accredited_investor
      app_client
    }
  }
`;

export const GET_COMPANY_ENUMS = gql`
  query MyQuery {
    ENUM_company_stage {
      description
      value
    }
    ENUM_business_model {
      description
      value
    }
    ENUM_funding_stage {
      description
      value
    }
    ENUM_interested_sectors {
      description
      value
    }
    ENUM_innovation_enablers {
      description
      value
    }
    ENUM_countries {
      description
      value
    }
    ENUM_financing_round {
      description
      value
    }
  }
`;

export const GET_SECURITY_TYPE = gql`
  query MyQuery {
    ENUM_financing_round {
      description
      value
    }
  }
`;

export const CREATE_OPPORTUNITY = gql`
mutation MyMutation($company_id: bigint!, $documents: [Deal_section_files_insert_input!]!, $section_text_areas: [Deal_section_text_area_insert_input!]!, $company_management_order: Int!, $company_description_order: Int!, $investment_deadline: bigint = "", $investment_curency: String = "", $fees: String = "", $valuation: bigint = "", $status: ENUM_deal_status_enum!, $investment_value: bigint = "", $opening_date: bigint = "", $security_type: ENUM_financing_round_enum!, $offering_type: ENUM_offering_type_enum = COMBINATION, $firebase_id: String = "", $funding_history_order: Int!, $image_sections: [Deal_section_image_insert_input!]!, $title: String = "", $investment_min: bigint = "1", $investment_max: bigint = "") {
  insert_Deal(objects: {permissions_assignments: {data: {firebase_id: $firebase_id, role_id: "4"}}, company_id: $company_id, company_description_order: $company_description_order, company_management_order: $company_management_order, investment_curency: $investment_curency, fees: $fees, valuation: $valuation, status: $status, investment_value: $investment_value, investment_deadline: $investment_deadline, opening_date: $opening_date, section_text_areas: {data: $section_text_areas}, security_type: $security_type, investment_min: $investment_min, investment_max: $investment_max, offering_type: $offering_type, funding_history_order: $funding_history_order, section_files: {data: $documents}, section_images: {data: $image_sections}, title: $title, investment_amount_available: $investment_value}) {
    affected_rows
    returning {
      id
      section_images {
        id
        order
      }
      section_files {
        id
        order
      }
    }
  }
}
`;

// investment_amount_available: $new_ammount_available
export const UPDATE_DEAL = gql`
  mutation MyMutation(
    $id: bigint!
    $company_management_order: Int!
    $company_description_order: Int!
    $new_ammount_available: bigint!
    $funding_history_order: Int!
    $investment_deadline: bigint!
    $fees: String!
    $valuation: bigint!
    $status: ENUM_deal_status_enum!
    $investment_value: bigint!
    $opening_date: bigint!
    $security_type: ENUM_financing_round_enum!
    $offering_type: ENUM_offering_type_enum!
    $section_text_areas: [Deal_section_text_area_insert_input!]!
    $section_documents: [Deal_section_files_insert_input!]!
    $section_images: [Deal_section_image_insert_input!]!
    $title: String = ""
  ) {
    update_Deal_by_pk(
      pk_columns: { id: $id }
      _set: {
        company_management_order: $company_management_order
        company_description_order: $company_description_order
        funding_history_order: $funding_history_order
        investment_deadline: $investment_deadline
        fees: $fees
        valuation: $valuation
        status: $status
        investment_value: $investment_value
        opening_date: $opening_date
        security_type: $security_type
        offering_type: $offering_type
        title: $title
      }
    ) {
      updated_at
    }
    delete_Deal_section_text_area(where: { deal_id: { _eq: $id } }) {
      affected_rows
    }
    insert_Deal_section_text_area(objects: $section_text_areas) {
      affected_rows
    }
    delete_Deal_section_files(where: { deal_id: { _eq: $id } }) {
      affected_rows
    }
    insert_Deal_section_files(objects: $section_documents) {
      affected_rows
      returning {
        id
        order
      }
    }
    delete_Deal_section_image(where: { deal_id: { _eq: $id } }) {
      affected_rows
    }
    insert_Deal_section_image(objects: $section_images) {
      affected_rows
      returning {
        id
        order
      }
    }
  }
`;

export const GET_DEALS_SUMMARY = gql`
query GET_DEALS_SUMMARY($name: String = "", $firebase_id: String = "", $nameOrder: order_by = null, $stage: order_by = null, $investment_deadline: order_by = null, $published_at: order_by = null, $watchlist: order_by = null, $investment_amount_available: order_by = null, $valuationOrder: order_by = null) {
  Permissions_assignment(where: {_and: {firebase_id: {_eq:$firebase_id}, deal_id:{_is_null: true}, investor_firebase_id:{_is_null: true}, permission:{_or: {edit_opportunity: {_eq: true}}}}}){
    id,
    permission{
      id,
      edit_opportunity
    }
  }
  Deal(where: {_and: {company: {name: {_ilike: $name}}}}, order_by: {company: {name: $nameOrder}, investment_deadline: $investment_deadline, published_at: $published_at, watchlist_aggregate: {count: $watchlist}, investment_amount_available: $investment_amount_available, status: $stage, valuation: $valuationOrder}) {
    company {
      name
    }
    id
    title
    status
    investment_value
    published_at
    investment_amount_available
    investment_deadline
    valuation
    watchlist {
      created_at
      account {
        last_name
        first_name
        firebase_id
      }
    }
    permissions_assignments(where: {firebase_id: {_eq: $firebase_id}, permission: {edit_opportunity: {_eq: true}}}) {
      id
    }
  }
}
`;

export const GET_DEAL_DETAILS_BY_ID = gql`
query MyQuery($id: bigint!) {
  Deal_by_pk(id: $id) {
    id
    company_description_order
    company_id
    company_management_order
    fees
    funding_history_order
    investment_curency
    investment_deadline
    investment_value
    offering_type
    investment_amount_available
    opening_date
    title
    investment_min
    investment_max
    published_at
    security_type
    status
    valuation
    section_files {
      id
      order
      title
      file(where: {deleted: {_is_null: true}}) {
        name
        deleted
        id
      }
    }
    section_images {
      id
      order
      title
      files_metadata {
        name
        deleted
        id
      }
    }
    section_text_areas {
      id
      order
      title
      body
    }
    Investment_flow_section_edges(where: {investment_id: {_is_null: true}}, limit: 1) {
      Flow_node {
        name
        id
      }
    }
  }
}
`;

export const ADMIN_CHECK = gql`
query MyQuery($firebase_id: String = "") {
  Permissions_assignment(where: {firebase_id: {_eq: $firebase_id}, permission: {role_type: {_in: [DEAL_EMPLOYEE, EMPLOYEE]}}}) {
    role_id
  }
}
`;

export const GET_DEAL_IS_PUBLISHED = gql`
  query MyQuery($id: bigint!) {
    Deal_by_pk(id: $id) {
      published_at
    }
  }
`;

export const GET_ACCOUNT_STATUS = gql`
  query MyQuery($firebase_id: String!) {
    Account_by_pk(firebase_id: $firebase_id) {
      accepted_confidentiality
      investor_profile {
        created_at
      }
      last_name
      phone_number
      city
      country
      first_name
      whitelist_account {
        created_at
      }
      permissions(where: { deal_id: { _is_null: true } }) {
        role_id
      }
    }
  }
`;

export const GET_INVESTOR_GENERAL_ANALYTICS = gql`
  query MyQuery {
    Account {
      investor_profile {
        innovation_enablers {
          ENUM_innovation_enabler {
            description
            value
          }
        }
        interested_sectors {
          ENUM_interested_sector {
            description
            value
          }
        }
        investor_profile_innovation_enablers_lesses {
          ENUM_innovation_enabler {
            description
            value
          }
        }
        investor_profile_interested_sectors_lesses {
          ENUM_interested_sector {
            description
            value
          }
        }
      }
    }
  }
`;

export const GET_ALL_FLOWS = gql`
query MyQuery {
  Flow_node(where: {Investment_flow_section_edges: {deal_id: {_is_null: true}, investment_id: {_is_null: true}}}) {
    name
    updated_at
    created_at
    id
  }
}
`;

export const GET_GENERAL_FLOW_TEMPLATE_BY_ID = gql`
  query MyQuery(
    $id: bigint!
    $deal_is_null: Boolean = true
    $investment_is_null: Boolean = true
  ) {
    Flow_node_by_pk(id: $id) {
      name
      id
      Investment_flow_section_edges(
        where: {
          deal_id: { _is_null: $deal_is_null }
          investment_id: { _is_null: $investment_is_null }
        }
      ) {
        id
        Flow_section_node {
          name
          id
          Investment_section_item_edges(
            where: {
              deal_id: { _is_null: $deal_is_null }
              investment_id: { _is_null: $investment_is_null }
            }
          ) {
            id
            item_id
            Flow_item_node {
              name
              type
              id
              text
            }
            order
            Files_metadata(where: {deleted: {_is_null: true}}) {
              name
              id
            }
          }
        }
        order
      }
    }
  }
`;

export const GET_DEAL_FLOW_BY_ID = gql`
  query MyQuery(
    $id: bigint!
    $deal_id: bigint!
    $investment_is_null: Boolean = true
  ) {
    Flow_node_by_pk(id: $id) {
      name
      id
      Investment_flow_section_edges(
        where: {
          deal_id: { _eq: $deal_id }
          investment_id: { _is_null: $investment_is_null }
        }
      ) {
        id
        deal_id
        Flow_section_node {
          name
          id
          Investment_section_item_edges(
            where: {
              deal_id: { _eq: $deal_id }
              investment_id: { _is_null: $investment_is_null }
            }
          ) {
            id
            item_id
            Flow_item_node {
              name
              type
              id
              text
            }
            order
            Files_metadata(where: {deleted: {_is_null: true}}) {
              name
              id
            }
          }
        }
        order
      }
    }
  }
`;

export const GET_INVESTMENT_FLOW_BY_ID = gql`
  query MyQuery($id: bigint!, $deal_id: bigint!, $investment_id: bigint!) {
  Flow_node_by_pk(id: $id) {
    name
    id
    Investment_flow_section_edges(where: {deal_id: {_eq: $deal_id}, investment_id: {_eq: $investment_id}}) {
      id
      deal_id
      completed
      current
      Flow_section_node {
        name
        id
        Investment_section_item_edges(where: {deal_id: {_eq: $deal_id}, investment_id: {_eq: $investment_id}}, order_by: {order: asc}) {
          id
          item_id
          Flow_item_node {
            name
            type
            id
            text
          }
          order
          Files_metadata(where: {deleted: {_is_null: true}}) {
            name
            id
          }
        }
      }
      order
    }
  }
}

`;

export const UPDATE_SECTION_ORDER_BY_EDGE = gql`
  mutation MyMutation(
    $id: bigint!
    $order: Int!
    $completed: Boolean = false
    $current: Boolean = false
  ) {
    update_Investment_flow_section_edge(
      where: { id: { _eq: $id } }
      _set: { order: $order, completed: $completed, current: $current }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_ONBOARDING_SECTION_ORDER_BY_EDGE = gql`
  mutation MyMutation(
    $id: bigint!
    $order: Int!
    $completed: Boolean!
    $current: Boolean!
  ) {
    update_Onboarding_flow_section_edge(
      where: { id: { _eq: $id } }
      _set: { order: $order, completed: $completed, current: $current }
    ) {
      affected_rows
    }
  }
`;


export const UPDATE_ITEM_ORDER_BY_EDGE = gql`
  mutation MyMutation($id: bigint!, $order: Int!) {
    update_Investment_section_item_edge(
      where: { id: { _eq: $id } }
      _set: { order: $order }
    ) {
      affected_rows
    }
  }
`;

export const MARK_INVESTMENT_COMPLETED = gql`
  mutation MyMutation($id: bigint!, $completed: bigint!) {
    update_Investment_by_pk(
      pk_columns: { id: $id }
      _set: { completed: $completed }
    ) {
      id
    }
  }
`;

export const GET_DEAL_FLOW_SECTIONS_IDS = gql`
  query MyQuery($deal_id: bigint = "") {
    Investment_flow_section_edge(
      where: { deal_id: { _eq: $deal_id }, investment_id: { _is_null: true } }
    ) {
      id
    }
    Investment_section_item_edge(
      where: { deal_id: { _eq: $deal_id }, investment_id: { _is_null: true } }
    ) {
      id
    }
  }
`;
export const GET_ALL_INVESTMENTS = gql`
query MyQuery($firebase_id: String = "") {
  Deal(where: {Investments: {firebase_id: {_neq: $firebase_id}, deleted_at: {_is_null: true}}}) {
    company {
      name
    }
    investment_deadline
    title
    Investments_aggregate(where: {deleted_at: {_is_null: true}}) {
      aggregate {
        count
      }
    }
    Investments(where: {deleted_at: {_is_null: true}}) {
      account {
        first_name
        last_name
      }
      updated_at
      value
      deal_id
      completed
      activeStep: Investment_flow_section_edges(where: {_and: [{current: {_eq: true}}, {completed: {_eq: false}}] }, limit: 1, order_by: {order: asc}) {
        flow_id
        deal_id
        investment_id
        Flow_section_node {
          name
        }
      }
      currentUncompletedStep: Investment_flow_section_edges(where: {completed: {_eq: false}}, limit: 1, order_by: {order: asc}) {
        flow_id
        deal_id
        investment_id
        Flow_section_node {
          name
        }
      }
      details:  Investment_flow_section_edges(limit: 1, order_by: {order: asc}) {
        flow_id
        deal_id
        investment_id
      }
    }
  }
}
`;

export const GET_INVESTMENT_COMPLETED = gql`
  query MyQuery($id: bigint!) {
    Investment_by_pk(id: $id) {
      completed
    }
  }
`;

export const CANCEL_DEAL = gql`
mutation MyMutation($id: bigint!) {
  update_Deal_by_pk(pk_columns: { id: $id }, _set: { status: CANCELED }) {
    status
    id
  }
}
`;

export const GET_FLOW_SECTION_IDS = gql`
query MyQuery($flow_id: bigint = "") {
  Investment_flow_section_edge(where: {flow_id: {_eq: $flow_id}, deal_id: {_is_null: true}, investment_id: {_is_null: true}}) {
    id
    Investment_section_item_edge(where: {deal_id: {_is_null: true}, investment_id: {_is_null: true}}) {
      id
    }
  }
}
`;

export const GET_ONBOARDING_FLOW_SECTION_IDS = gql`
query MyQuery($flow_id: bigint = "") {
  Onboarding_flow_section_edge(where: {flow_id: {_eq: $flow_id}, firebase_id: {_is_null: true}}) {
    id
    Onboarding_section_item_edge(where: {firebase_id: {_is_null: true}}) {
      id
    }
  }
}
`;
export const GET_FLOW_FOR_CLONE = gql`
query GET_FLOW_FOR_CLONE($id: bigint!) {
  Flow_node_by_pk(id: $id) {
    name
    Investment_flow_section_edges(where: {investment_id: {_is_null: true}, deal_id: {_is_null: true}}) {
      order
      completed
      current
      shown
      Flow_section_node {
        name
        Investment_section_item_edges(where: {investment_id: {_is_null: true}, deal_id: {_is_null: true}}) {
          order
          Flow_item_node {
            name
            text
            type
          }
        }
      }
    }
  }
}
`;

export const GET_ONBOARDING_FLOW_FOR_CLONE = gql`
query MyQuery($id: bigint!) {
  Flow_node_by_pk(id: $id) {
    name
    Onboarding_flow_section_edges(where: {firebase_id: {_is_null: true}}) {
      order
      completed
      current
      shown
      Flow_section_node {
        name
        Onboarding_section_item_edges(where: {firebase_id: {_is_null: true}}) {
          order
          Flow_item_node {
            name
            text
            type
          }
        }
      }
    }
  }
}
`;

export const GET_ALL_ONBOARDING_FLOWS = gql`
query MyQuery($filter: String = "%%") {
  Flow_node(where: {Onboarding_flow_section_edges: {Flow_node: {name: {_ilike: $filter}}}}) {
    name
    updated_at
    created_at
    id
  }
}
`;

export const GET_DEFAULT_FLOW = gql`
query MyQuery {
  Onboarding_flow_section_edge(where: {is_default: {_eq: true}}, limit: 1) {
    Flow_node {
      id
    }
  }
}
`;

export const GET_GENERAL_ONBOARDING_FLOW_BY_ID = gql`
query MyQuery($id: bigint!) {
  Flow_node_by_pk(id: $id) {
    name
    id
    Onboarding_flow_section_edges(where: {firebase_id: {_is_null: true}}) {
      id
      current
      completed
      Flow_section_node {
        name
        id
        Onboarding_section_item_edges(where: {firebase_id: {_is_null: true}}) {
          id
          item_id
          Flow_item_node {
            name
            type
            id
            text
          }
          order
          Files_metadata(where: {deleted: {_is_null: true}}) {
            name
            id
          }
          Onboarding_flow_item_input_field {
            text
          }
        }
      }
      order
    }
  }
}
`;

export const GET_ONBOARDING_FLOW_FOR_CLIENT = gql`
query MyQuery($id: bigint!, $firebase_id: String = "") {
  Flow_node_by_pk(id: $id) {
    name
    id
    Onboarding_flow_section_edges(where: {firebase_id: {_eq: $firebase_id}}) {
      id
      current
      completed
      Flow_section_node {
        name
        id
        Onboarding_section_item_edges(where: {firebase_id: {_eq: $firebase_id}}) {
          id
          item_id
          Flow_item_node {
            name
            type
            id
            text
          }
          order
          firebase_id
          Files_metadata(where: {deleted: {_is_null: true}}) {
            name
            id
          }
          Onboarding_flow_item_input_field {
            text
          }
        }
      }
      order
      firebase_id
    }
  }
}
`;

export const GET_ONBOARDING_FLOW_EDGES = gql`
mutation MyQuery($firebase_id: String = "") {
  update_Onboarding_flow_section_edge(where: {firebase_id: {_eq: $firebase_id}}, _set: {shown: true}) {
    returning {
      id
      flow_id
      Onboarding_section_item_edge(where: {firebase_id: {_eq: $firebase_id}}) {
        id
      }
    }
  }
}
`

export const SET_ALL_FLOWS_NOT_DEFAULT = gql`
mutation MyMutation {
  update_Onboarding_flow_section_edge(where: {}, _set: {is_default: false}) {
    affected_rows
  }
  update_Onboarding_section_item_edge(where: {}, _set: {is_default: false}) {
    affected_rows
  }
}
`;

export const SET_FLOW_DEFAULT = gql`
mutation MyMutation($flow_section_edges_id: [bigint!]!, $flow_item_edge_id: [bigint!] = "") {
  update_Onboarding_flow_section_edge(where: {id: {_in: $flow_section_edges_id}}, _set: {is_default: true}) {
    affected_rows
  }
  update_Onboarding_section_item_edge(where: {id: {_in: $flow_item_edge_id}}, _set: {is_default: true}) {
    affected_rows
  }
}
`;

export const GET_ALL_CONVERSATIONS = gql`
subscription MyQuery($employee_id: String = "") {
  Deal_chat(where: {employee_id: {_eq: $employee_id}}) {
    id
    accountByInvestorId {
      first_name
      last_name
    }
    Deal_chat_messages(limit: 1, order_by: {created_at: asc}) {
      created_at
    }
  }
}
`;

export const GET_CHAT_MESSAGES = gql`
subscription MySubscription($deal_chat_id: bigint = "") {
  Deal_chat_message(where: {deal_chat_id: {_eq: $deal_chat_id}}) {
    message
    firebase_id
    type
    created_at
  }
}
`;

export const GET_NEW_MESSAGES = gql`
subscription MyQuery($employee_id: String = "") {
  Deal_chat_message(where: {seen: {_eq: "0"}, Deal_chat: {employee_id: {_eq: $employee_id}}}, distinct_on: deal_chat_id) {
    id
  }
}
`;

export const GET_DEAL_NAME_BY_CHAT_ID = gql`query MyQuery($deal_chat_id: bigint = "") {
  Deal(where: {Deal_chats: {id: {_eq: $deal_chat_id}}}) {
    title
    company {
      name
    }
  }
}
`

export const SET_MESSAGES_SEEN = gql`
mutation MyMutation($deal_chat_id: bigint = "", $seen: bigint = ${Date.now()}) {
  update_Deal_chat_message(where: {deal_chat_id: {_eq: $deal_chat_id}, seen: {_eq: "0"}}, _set: {seen: $seen}) {
    affected_rows
  }
}
`

export const CHECK_FOR_NEW_MESSAGES = gql`
subscription MyQuery($deal_chat_id: bigint!) {
  Deal_chat_message(where: {deal_chat_id: {_eq: $deal_chat_id}, seen: {_eq: "0"}}, distinct_on: seen) {
    seen
  }
}
`;

export const GET_INVESTMENT_HEADER_DATA = gql`
query MyQuery($id: bigint!) {
  Investment_by_pk(id: $id) {
    account {
      first_name
      last_name
    }
    deal {
      company {
        name
      }
    }
  }
}
`;

export const GET_RELATIONSHIP_MANAGERS_AND_ADMINS = gql`
query MyQuery {
  Account(where: {permissions: {role_id: {_eq: 7}}}) {
    first_name
    last_name
    firebase_id
  }
}
`;

export const REPLACE_RELATIONSHIP_MANAGER = gql`
  mutation MyMutation(
    $firebase_id: String = ""
    $investor_firebase_id: String = ""
    $id: bigint!
  ) {
    update_Permissions_assignment_by_pk(
      pk_columns: { id: $id }
      _set: { firebase_id: $firebase_id }
    ) {
      id
    }
  }
`;

export const ASSIGN_RELATIONSHIP_MANAGER = gql`
mutation MyMutation($firebase_id: String = "", $investor_firebase_id: String = "") {
  insert_Permissions_assignment_one(object: {firebase_id: $firebase_id, investor_firebase_id: $investor_firebase_id, role_id: "10"}) {
    id
  }
}

`;

export const GET_COMPANY_ANALYTICS = gql`
  query MyQuery(
    $time1: timestamp = ""
    $time2: timestamp = ""
    $time3: timestamptz = ""
    $time4: timestamptz = ""
    $messageOrder: order_by = null
    $declaredOrder: order_by = null
    $completedOrder: order_by = null
    $commitedOrder: order_by = null
    $nameOrder: order_by = null
    $averageSessionOrder: order_by = null
    $interestFeedbackOrder: order_by = null
    $viewMoreOrder: order_by = null
    $watchlistOrder: order_by = null
    $availableAmmountOrder: order_by = null
    $interestedClickOrder: order_by = null
    $investmentCounterOrder: order_by = null
  ) {
    Company(
      order_by: {
        name: $nameOrder
        available_amount_aggregate: { sum: { amount: $availableAmmountOrder } }
        committed_aggregate: { sum: { value: $commitedOrder } }
        interest_feedback_counter_aggregate: {
          sum: { counter: $interestFeedbackOrder }
        }
        deal_view_average_session_aggregate: {
          sum: { average: $averageSessionOrder }
        }
        investment_completed_counter_aggregate: {
          sum: { counter: $completedOrder }
        }
        investment_declared_aggregate: { sum: { value: $declaredOrder } }
        message_counter_aggregate: { sum: { counter: $messageOrder } }
        deal_view_more_counter_total_aggregate: {
          sum: { counter: $viewMoreOrder }
        }
        interested_click_total_aggregate: {
          sum: { counter: $interestedClickOrder }
        }
        watchlist_counter_aggregate: { sum: { counter: $watchlistOrder } }
      }
    ) {
      id
      name
      available: available_amount {
        amount
      }
      committed: committed_aggregate(
        where: {
          _and: [{ time: { _gte: $time1 } }, { time: { _lte: $time2 } }]
        }
      ) {
        aggregate {
          sum {
            value
          }
        }
      }
      not_interested: interest_feedback_counter_aggregate(
        where: {
          _and: [{ time: { _gte: $time1 } }, { time: { _lte: $time2 } }]
        }
      ) {
        aggregate {
          sum {
            counter
          }
        }
      }
      avge_session_time: deal_view_average_session_aggregate(
        where: {
          _and: [{ time: { _gte: $time1 } }, { time: { _lte: $time2 } }]
        }
      ) {
        aggregate {
          avg {
            average
          }
        }
      }
      completed: investments_completed_aggregate(
        where: {
          _and: [{ time: { _gte: $time1 } }, { time: { _lte: $time2 } }]
        }
      ) {
        aggregate {
          sum {
            counter
          }
        }
      }
      declared: investment_declared_aggregate(
        where: {
          _and: [{ time: { _gte: $time1 } }, { time: { _lte: $time2 } }]
        }
      ) {
        aggregate {
          sum {
            value
          }
        }
      }
      messages: message_counter_aggregate(
        where: {
          _and: [{ time: { _gte: $time1 } }, { time: { _lte: $time2 } }]
        }
      ) {
        aggregate {
          sum {
            counter
          }
        }
      }
      views_total: deal_view_more_counter_total_aggregate(
        where: {
          _and: [{ time: { _gte: $time1 } }, { time: { _lte: $time2 } }]
        }
      ) {
        aggregate {
          sum {
            counter
          }
        }
      }
      views_unique: Analytics_aggregate(
        where: {
          _and: {
            event: { _eq: DEAL_VIEW_MORE_CLICK }
            _or: [
              { created_at: { _gte: $time3 } }
              { created_at: { _lte: $time4 } }
            ]
          }
        }
      ) {
        aggregate {
          count(columns: firebase_id, distinct: true)
        }
      }
      interested: interested_click_total_aggregate(
        where: {
          _and: [{ time: { _gte: $time1 } }, { time: { _lte: $time2 } }]
        }
      ) {
        aggregate {
          sum {
            counter
          }
        }
      }
      interested_unique: Analytics_aggregate(
        where: {
          _and: {
            event: { _eq: DEAL_INTEREST_CLICK }
            _or: [
              { created_at: { _gte: $time3 } }
              { created_at: { _lte: $time4 } }
            ]
          }
        }
      ) {
        aggregate {
          count(columns: firebase_id, distinct: true)
        }
      }
      watchlist: watchlist_counter_aggregate(
        where: {
          _and: [{ time: { _gte: $time1 } }, { time: { _lte: $time2 } }]
        }
      ) {
        aggregate {
          sum {
            counter
          }
        }
      }
    }
  }
`;

export const GET_ALL_COUNTRIES = gql`
query MyQuery {
  ENUM_countries {
    description
    value
  }
}
`;

export const GET_NOT_INTERESTED_FEEDBACK = gql`
query MyQuery {
  Company {
    name
    Deals {
      title
      Interest_feedbacks {
        industry
        investment
        company
        other
      }
    }
  }
}

`;

export const GET_CLIENT_ACTIVITY = gql`
query MyQuery2($firebase_id: String = "") {
  Account(where: {firebase_id: {_eq: $firebase_id}}) {
    Investments(where: {deleted_at: {_is_null: true}}) {
      completed
      value
      deal {
        company{name}
        id
        title
        views: Analytics_aggregate(where: {event: {_eq: DEAL_VIEW_MORE_CLICK}, firebase_id: {_eq: $firebase_id}}) {
          aggregate {
            count(columns: id)
          }
        }
        session: Analytics_aggregate(where: {event: {_eq: DEAL_DETAILED_VIEW_SESSION}, firebase_id: {_eq: $firebase_id}}) {
          aggregate {
            avg {
              value
            }
          }
        }
      }
    }
    watchlist {
      id
      deal {
        company{name}
        id
        title
        views: Analytics_aggregate(where: {event: {_eq: DEAL_VIEW_MORE_CLICK}, firebase_id: {_eq: $firebase_id}}) {
          aggregate {
            count(columns: id)
          }
        }
        session: Analytics_aggregate(where: {event: {_eq: DEAL_DETAILED_VIEW_SESSION}, firebase_id: {_eq: $firebase_id}}) {
          aggregate {
            avg {
              value
            }
          }
        }
      }
    }
    analytics(distinct_on: deal_id) {
      Deal {
        company{name}
        id
        title
        views: Analytics_aggregate(where: {event: {_eq: DEAL_VIEW_MORE_CLICK}, firebase_id: {_eq: $firebase_id}}) {
          aggregate {
            count(columns: id)
          }
        }
        session: Analytics_aggregate(where: {event: {_eq: DEAL_DETAILED_VIEW_SESSION}, firebase_id: {_eq: $firebase_id}}) {
          aggregate {
            avg {
              value
            }
          }
        }
      }
    }
  }
}
`;

export const GET_ASSIGNED_DEAL_MEMBERS = gql`
query MyQuery($id: bigint!) {
  Deal_by_pk(id: $id) {
    title
    company {
      name
    }
    permissions_assignments(where: {role_id: {_eq: "8"}}) {
      firebase_id
      account {
        last_name
        first_name
      }
    }
  }
}
`;

export const GET_ALL_AVAILABLE_EMPLOYEES = gql`
query MyQuery {
  Account(where: {permissions: {permission: {role_type: {_in: [DEAL_EMPLOYEE, EMPLOYEE]}}}}) {
    last_name
    first_name
    firebase_id
  }
}
`;

export const UPDATE_DEAL_EMPLOYEE_PERMISSIONS = gql`
mutation MyMutation($newEmployees: [Permissions_assignment_insert_input!]!, $deal_id: bigint!, $firebase_id: [String!]!) {
  delete_Permissions_assignment(where: {deal_id: {_eq: $deal_id}, firebase_id: {_in: $firebase_id}, role_id: {_eq: "8"}}) {
    affected_rows
  }
  insert_Permissions_assignment(objects: $newEmployees) {
    affected_rows
  }
}
`;

export const GET_ALL_NOTABLE_INVESTORS = gql`
query MyQuery {
  ENUM_notable_investors {
    value
  }
}
`

export const GET_ACCOUNT_NAME = gql`
query MyQuery($firebase_id: String!) {
  Account_by_pk(firebase_id: $firebase_id) {
    first_name
    last_name
  }
}
`;

export const PUBLISH_COMPANY = gql`
mutation MyMutation($id: bigint!) {
  update_Company_by_pk(pk_columns: {id: $id}, _set: {unpublished_at: 0}) {
    unpublished_at
  }
}
`;