import React, { useEffect, useState } from "react";
import {
  adminAlignmentStyles,
  adminDimensionsStyle,
  adminInputStyles,
  adminTypografyStyles,
} from "../../AdminStyles";
import { oportunitySectionStyle } from "../../OpportunitiesPage/AddOportunity/OpportunitySections/OportunitySectionStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import UploadComponent from "../../../shared/DocumentHandler/UploadComponent";
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "@material-ui/core";
import DownloadComponent from "../../../shared/DocumentHandler/DownloadComponent";
import { deleteDocumentsForOnboardingFlowEdge} from "../../../utils/file-queries";
import { adminOnboardingFlowActions, onboardingSectionTypes } from "../../../store/slices/adminOnboardingFlow";

const customStyles = makeStyles({
  footer: {
    padding: "16px",
    background: "#F9F9F9",
  },
  content: {
    padding: "16px",
  },
  section: {
    borderLeft: "1px solid #E5E5E5",
    paddingLeft: "12px",
  },
});



const ClientOnboardingStep = ({ stepData, isClient }) => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const inputClasses = adminInputStyles();
  const typografyClasses = adminTypografyStyles();
  const stepClasses = oportunitySectionStyle();
  const customClasses = customStyles();
  const [order, setOrder] = useState(stepData.order);
  const stepNumber = useSelector(store => store.adminOnboardingFlow.steps.length);
  const dispatch = useDispatch();
  const [extended, setExtended] = useState(stepData.extended);
  const [sections, setSections] = useState([]);
  const [title, setTitle] = useState(stepData.title);
  const [userDocuments, setUserDocuments] = useState([]);
  const [userTextResponses, setUserTextResponses] = useState([]);
  const [completed, setCompleted] = useState(!!stepData.completed);
  const [active, setActive] = useState(!!stepData.current);
  const flowCompleted = useSelector(store => store.adminAddInvestorFlowState.investmentCompleted);

  useEffect(() => {
    if (isClient) {
      setUserDocuments(
        [].concat.apply(
          [],
          [
            ...[...stepData.parts.filter((x) => x.type === onboardingSectionTypes.UPLOAD)].map(
              (x) => x.data?.files || []
            ),
          ]
        )
      );
      setUserTextResponses(
        [].concat.apply(
          [],
          [...stepData.parts.filter((x) => x.type === onboardingSectionTypes.INPUT_FIELD)].map(
            (x) => x.data ? { label: x.data.label, text: x.data.text } : null,
          ),
        )
      );
    }
  }, [isClient, stepData]);

  const addSection = (type) => {
    dispatch(adminOnboardingFlowActions.CREATE_STEP_ITEM({ sectionId: stepData.id, type }));
  };

  const removeSection = (section) => {
    dispatch(adminOnboardingFlowActions.DELETE_STEP_ITEM({ sectionId: stepData.id, itemId: section.id }));
  };

  const setSectionData = (sectionId, data) => {
    dispatch(adminOnboardingFlowActions.UPDATE_STEP_ITEM({ sectionId: stepData.id, itemId: sectionId, data, uploadFile: true }));
  };

  const handleChangeStepTitle = (name) => {
    dispatch(adminOnboardingFlowActions.UPDATE_STEP({ id: stepData.id, title: name }));
  };

  const getSectionData = (sectionId) => {
    return stepData.parts.filter((x) => x.id === sectionId)[0]?.data || null;
  };

  const handleReorderSections = (newOrder) => {
    dispatch(adminOnboardingFlowActions.REORDER_SECTIONS({ order: stepData.order, newOrder }));
  };

  const handleChangeExtended = () => {
    dispatch(adminOnboardingFlowActions.UPDATE_STEP({ id: stepData.id, extended: !extended }));
  };

  const handleDeleteStep = () => {
    dispatch(adminOnboardingFlowActions.DELETE_STEP({ id: stepData.id }));
  };

  useEffect(() => { setOrder(stepData.order) }, [stepData.order]);

  useEffect(() => { setTitle(stepData.title) }, [stepData.title]);

  useEffect(() => { setSections(stepData.parts) }, [stepData.parts]);

  useEffect(() => { setExtended(stepData.extended) }, [stepData.extended]);

  useEffect(() => { setCompleted(!!stepData.completed) }, [stepData.completed]);

  useEffect(() => { setActive(!!stepData.current) }, [stepData.current]);

  const handleChangeCompletedStatus = () => {
    dispatch(adminOnboardingFlowActions.SET_STEP_COMPLETED({ id: stepData.id }));
  };

  const handleSetActive = () => {
    dispatch(adminOnboardingFlowActions.SET_STEP_ACTIVE({ id: stepData.id }));
  };

  return (
    <div className={stepClasses.container}>
      <div className={stepClasses.header}>
        <div className={stepClasses.headerSection} style={{ flexGrow: "1" }}>
          <IconButton onClick={() => handleChangeExtended()}>
            {extended ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <span>Step Title</span>
          <input placeholder="eg. Deal terms" disabled={stepData.id>0} value={title} onChange={(e) => handleChangeStepTitle(e.target.value)} className={[inputClasses.input, typografyClasses.fontSize14].join(" ")}
            style={{ height: "32px", maxWidth: "580px", flexGrow: "1", marginLeft: "12px" }}
          />
        </div>
        <div className={stepClasses.headerSection}>
          <span className={stepClasses.headerTitle}>Order</span>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <Select
              MenuProps={{ variant: "menu", disableScrollLock: true }}
              className={stepClasses.select}
              disabled={stepData.id>0}
              value={order}
              onChange={(e) => handleReorderSections(e.target.value)}
            >
              {Array.from({ length: stepNumber }, (_, i) => i).map(
                (x, index) => (
                  <MenuItem key={index} value={x}>
                    {x + 1}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <IconButton disabled={flowCompleted} onClick={() => handleDeleteStep()} >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      {extended ? (
        <div className={customClasses.content}>
          {sections.map((section, index) => {
            switch (section.type) {
              case onboardingSectionTypes.DOCUSIGN:
                return (
                  <div key={index}
                    className={[dimensionClasses.width100Percent, customClasses.section, dimensionClasses.marginbottom16, alignmentClasses.flexAlignCenter, dimensionClasses.smallHeigth].join(" ")} >
                    <p className={[dimensionClasses.marginBottom0, dimensionClasses.widthGrow, typografyClasses.fontSize14, dimensionClasses.marginTop0].join(" ")} style={{ color: "#333333" }}>
                      Docusign (visible on the client)
                    </p>
                    <IconButton disabled={flowCompleted} onClick={() => removeSection(section)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );

              case onboardingSectionTypes.DOWNLOAD:
                return (
                  <div key={index}
                    className={[dimensionClasses.width100Percent, customClasses.section, dimensionClasses.marginbottom16, alignmentClasses.flexWrap].join(" ")}>
                    <p className={[dimensionClasses.marginBottom0, typografyClasses.fontSize14, dimensionClasses.marginTop0,].join(" ")} style={{ color: "#333333" }}>
                      Add documents (downloadable for the client)
                      {!section.edgeId
                        ? " - Please save the changes first in order to upload files"
                        : ""}
                    </p>
                      <UploadComponent
                        deleteCallback={(fileId) =>
                          deleteDocumentsForOnboardingFlowEdge(fileId)
                        }
                        downloadRoute="onboarding"
                        initialFiles={section.data || []}
                        customStyle={{ width: "calc(100% - 48px)" }}
                        disabled={!section.edgeId || flowCompleted}
                        setNewFiles={(files) =>
                          setSectionData(section.id, files)
                        }
                      />
                    <IconButton disabled={flowCompleted} style={{ height: "56px"}} onClick={() => removeSection(section)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );

              case onboardingSectionTypes.TEXT:
                return (
                  <div key={index}
                    className={[dimensionClasses.width100Percent, customClasses.section, dimensionClasses.marginbottom16, alignmentClasses.flexWrap, alignmentClasses.flexAlignCenter,].join(" ")} >
                    <p className={[dimensionClasses.marginBottom0, dimensionClasses.width100Percent, typografyClasses.fontSize14, dimensionClasses.marginTop0,].join(" ")} style={{ color: "#333333" }} >
                      Text
                    </p>
                    <input onChange={(x) => setSectionData(section.id, { text: x.target.value })} value={getSectionData(section.id)?.text || ""} placeholder="eg. Deal terms" className={[inputClasses.input, typografyClasses.fontSize14, dimensionClasses.widthGrow].join(" ")} style={{ height: "32px" }} />
                    <IconButton disabled={flowCompleted} onClick={() => removeSection(section)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );

              case onboardingSectionTypes.UPLOAD:
                return (
                  <div key={index}
                    className={[dimensionClasses.width100Percent, customClasses.section, dimensionClasses.marginbottom16, alignmentClasses.flexAlignCenter, dimensionClasses.smallHeigth].join(" ")}>
                    <p className={[dimensionClasses.marginBottom0, dimensionClasses.widthGrow, typografyClasses.fontSize14, dimensionClasses.marginTop0].join(" ")} style={{ color: "#333333" }} >
                      Documents (client document uploader)
                    </p>
                    <IconButton disabled={flowCompleted} onClick={() => removeSection(section)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );

              case onboardingSectionTypes.INPUT_FIELD:
                return (
                  <div key={index}
                    className={[dimensionClasses.width100Percent, customClasses.section, dimensionClasses.marginbottom16, alignmentClasses.flexWrap, alignmentClasses.flexAlignCenter,].join(" ")} >
                    <p className={[dimensionClasses.marginBottom0, dimensionClasses.width100Percent, typografyClasses.fontSize14, dimensionClasses.marginTop0,].join(" ")} style={{ color: "#333333" }} >
                      Text input label (client text input)
                      </p>
                    <input onChange={(x) => setSectionData(section.id, { label: x.target.value })} value={getSectionData(section.id)?.label || ""} placeholder="eg. Year of graduation" className={[inputClasses.input, typografyClasses.fontSize14, dimensionClasses.widthGrow].join(" ")} style={{ height: "32px" }} />
                    <IconButton disabled={flowCompleted} onClick={() => removeSection(section)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );

              default:
                break;
            }
          })}
          <div className={[alignmentClasses.flex].join(" ")}>
            <Button variant="outlined" color="primary" className={[dimensionClasses.marginRight12, dimensionClasses.smallHeigth].join(" ")} onClick={() => addSection(onboardingSectionTypes.TEXT)} disabled={flowCompleted} >
              <AddIcon style={{ marginRight: "8px" }} />
              Text
            </Button>
            <Button variant="outlined" color="primary" className={[dimensionClasses.marginRight12, dimensionClasses.smallHeigth].join(" ")} onClick={() => addSection(onboardingSectionTypes.UPLOAD)} disabled={flowCompleted} >
              <AddIcon style={{ marginRight: "8px" }} />
              Request Documents
            </Button>
            <Button variant="outlined" color="primary" className={[dimensionClasses.marginRight12, dimensionClasses.smallHeigth].join(" ")} onClick={() => addSection(onboardingSectionTypes.DOCUSIGN)} disabled={flowCompleted} >
              <AddIcon style={{ marginRight: "8px" }} />
              Docusign
            </Button>
            <Button variant="outlined" color="primary" className={[dimensionClasses.marginRight12, dimensionClasses.smallHeigth].join(" ")} onClick={() => addSection(onboardingSectionTypes.DOWNLOAD)} disabled={flowCompleted} >
              <AddIcon style={{ marginRight: "8px" }} />
              Send Documents
            </Button>
            <Button variant="outlined" color="primary" className={[dimensionClasses.marginRight12, dimensionClasses.smallHeigth].join(" ")} onClick={() => addSection(onboardingSectionTypes.INPUT_FIELD)} disabled={flowCompleted} >
              <AddIcon style={{ marginRight: "8px" }} />
              Input field
            </Button>
          </div>
        </div>
      ) : (
          ""
        )}
      {isClient ? (
        <div
          style={{
            padding: "16px",
            background: "#F9F9F9",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <FormControlLabel
            disabled={flowCompleted}
            value="active"
            control={
              <Radio checked={active} onClick={() => handleSetActive()} />
            }
            label="Active"
          />
          <div>
            <span>Status: </span>
            <FormControlLabel
              disabled={flowCompleted}
              value="completed"
              control={
                <Checkbox
                  color="primary"
                  checked={completed}
                  onClick={() => handleChangeCompletedStatus()}
                />
              }
              label="Completed"
              labelPlacement="end"
            />
          </div>
          {
            userTextResponses.filter(x => x).length > 0 && (
              <div style={{ width: "100%" }}>
                <p className={[dimensionClasses.marginTop8, dimensionClasses.marginBottom8, typografyClasses.fontSize16].join(' ')}>User text responses:</p>
                {
                  userTextResponses.filter(x => x).map(x => <p className={[dimensionClasses.marginBottom0, dimensionClasses.marginTop8, typografyClasses.fontSize15].join(' ')}><span>{x.label}:</span> <span>{x.text}</span></p>)
                }
              </div>
            )
          }
          {
            userDocuments.length > 0 && (
              <div style={{ width: "100%" }}>
                <DownloadComponent title={""} files={userDocuments} route="onboarding" />
              </div>
            )
          }
        </div>
      ) : null}
    </div>
  );
};

export default ClientOnboardingStep;
