import React, { useEffect, useState } from 'react';
import { adminAlignmentStyles, adminDimensionsStyle, adminInputStyles, adminTypografyStyles } from '../../AdminStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import ClientOnboardingStep from './ClientOnboardingStep';
import { adminOnboardingFlowActions } from '../../../store/slices/adminOnboardingFlow';
import { useLazyQuery } from '@apollo/client';
import { GET_GENERAL_ONBOARDING_FLOW_BY_ID, GET_ONBOARDING_FLOW_FOR_CLIENT } from '../../queries/admin-queries';
import { mappServerData } from './onboardingMapper';

export const AddOnboardingFlow = () => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const inputClasses = adminInputStyles();
  const typografyClasses = adminTypografyStyles();
  const canAccess = useSelector(store => store.permissionAsignments.handle_onboarding)
  const dispatch = useDispatch();
  const [getGeneralOnboardingFlow, getGeneralOnboardingFlowResult] = useLazyQuery(GET_GENERAL_ONBOARDING_FLOW_BY_ID, {fetchPolicy: "network-only"});
  const [getClientOnboardingFlow, getClientOnboardingFlowResult] = useLazyQuery(GET_ONBOARDING_FLOW_FOR_CLIENT, {fetchPolicy: "network-only"});
  const flowTitle = useSelector(store => store.adminOnboardingFlow.title);
  const steps = useSelector(store => store.adminOnboardingFlow.steps);
  const routeParams = useRouteMatch().params;
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    if(routeParams.flowId && !routeParams.clientFirebaseId) {
      getGeneralOnboardingFlow({variables: {id: routeParams.flowId}})
    } else if(routeParams.flowId && routeParams.clientFirebaseId) {
      getClientOnboardingFlow({variables: {id: routeParams.flowId, firebase_id: routeParams.clientFirebaseId}})
      setIsClient(true);
    } else  {
      dispatch(adminOnboardingFlowActions.CREATE_ONBOARDING_FLOW());
    }
  }, [routeParams])

  useEffect(() => {
    if(getGeneralOnboardingFlowResult.data && getGeneralOnboardingFlowResult.data.Flow_node_by_pk) {
      const mappedData = mappServerData(getGeneralOnboardingFlowResult.data.Flow_node_by_pk);
      dispatch(adminOnboardingFlowActions.EDIT_ONBOARDING_FLOW(mappedData));
    }
  }, [getGeneralOnboardingFlowResult.data]);

  useEffect(() => {
    if(getClientOnboardingFlowResult.data && getClientOnboardingFlowResult.data.Flow_node_by_pk) {
      const mappedData = mappServerData(getClientOnboardingFlowResult.data.Flow_node_by_pk);
      dispatch(adminOnboardingFlowActions.EDIT_ONBOARDING_FLOW({...mappedData, firebaseId: routeParams.clientFirebaseId}));
    }
  }, [getClientOnboardingFlowResult.data]);

  const handleSetTitle = (title) => {
    dispatch(adminOnboardingFlowActions.SET_ONBOARDING_TITLE({title: title}));
  }

  if(!canAccess) return <div style={{marginTop: "80px", textAlign: "center"}}>You do not have permission to view this onboarding tab</div>

  return (
    <div className={[dimensionClasses.containerWidth, dimensionClasses.headerMarginFix].join(' ')}>
      <p className={[dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')} style={{color: "#333333"}}>Title</p>
      <div className={[dimensionClasses.width100Percent, alignmentClasses.flex].join(' ')}>
      <input 
        placeholder="eg. US Clients Onboarding Flow"
        className={[inputClasses.input, typografyClasses.fontSize14, dimensionClasses.widthGrow, dimensionClasses.mediumHeight].join(' ')}
        value={flowTitle}
        onChange={e => handleSetTitle(e.target.value)}
        disabled={routeParams.clientFirebaseId || routeParams.flowId}
        />
      </div>
      {
        steps.map((step, index) => <ClientOnboardingStep key={index} stepData={step} isClient={isClient}/> )
      }
    </div>
  )
}

export default AddOnboardingFlow;