import React, { Fragment, useEffect, useState } from 'react';
import CalendarTodaySharpIcon from '@material-ui/icons/CalendarTodaySharp';
import { makeStyles } from '@material-ui/core';
import { generalStyleClasses } from '../../../../../utils/general-styles';
import { useQuery } from '@apollo/client';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { formatDateMonthYear, formatValue, formatDateDayMonthYear } from '../../../../../utils/formats';
import { fileDownloader } from '../../../../../utils/file-queries';
import { GET_COMPANY_DEAL_TITLE } from '../../../../queries/preview-queries';
import { useSelector } from 'react-redux';

const styles = makeStyles({
  logoImage: {
    width: "60px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
  },
  container: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    padding: "40px",
  },
  companyTitleContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "16px",
    justifyContent: "space-around",
    flexGrow: "1",
  },
  dealStatusSpan: {
    borderRadius: "24px",
    color: "#FFFFFF",
    padding: "3px 6px",
    marginLeft: "12px",
  },
  prospectiveDealBackground: {
    background: "#86B4CE",
  },
  activeDealBackground: {
    background: "#9FBF85",
  },
  closedDealBackground: {
    background: "#C16F6F",
  },
  companyDetails: {
    margin: "0",
    "span:last-of-type": {
      marginLeft: "16px",
    }
  }
})

const CompanyTitleSection = ({ companyId }) => {
  const classes = styles();
  const generalClasses = generalStyleClasses();
  const [companyLogo, setCompanyLogo] = useState("");
  const { data, loading } = useQuery(GET_COMPANY_DEAL_TITLE, { variables: { id: companyId } })
  const title = useSelector(store => store.adminOpportunity.resultingOpportunity.title)
  const status = useSelector(store => store.adminOpportunity.resultingOpportunity.type)
  const amountAvailable = useSelector(store => store.adminOpportunity.resultingOpportunity.amountAvailable);
  const date = useSelector(store => store.adminOpportunity.resultingOpportunity.deadline);
  useEffect(() => {
    if(data && data.Company_by_pk && data.Company_by_pk.logo) {
      const image = fileDownloader(`company/logo/${data.Company_by_pk.logo}`);
      image.then(response => setCompanyLogo(URL.createObjectURL(response)));
    }
  }, [data])

  if (loading || !data || !data.Company_by_pk) return <div />

  return (
    <div className={generalClasses.flex}>
      <img src={companyLogo || `${process.env.PUBLIC_URL}/images/no-image.png`} alt="logo" className={classes.logoImage} />
      <div className={classes.companyTitleContainer}>
        <div >
          <span className={[generalClasses.fontSize20, generalClasses.fontBold].join(' ')}>{data.Company_by_pk.name} {title ? ` - ${title}` : ""}</span>
          <span className={[
            classes.dealStatusSpan,
            generalClasses.fontSize14,
            status === 'ACTIVE' ? classes.activeDealBackground :
              status === 'PROSPECTIVE' ? classes.prospectiveDealBackground :
                classes.closedDealBackground].join(' ')}>
            {status === 'ACTIVE' ? "Open" : status === 'PROSPECTIVE' ? "Prospective" : "Closed"}
          </span>
        </div>
        <p className={classes.companyDetails}>
          <span>
            {`${data.Company_by_pk.hq_city} ${data.Company_by_pk.hq_state} ${data.Company_by_pk.hq_country}`}
          </span>
          <span style={{ marginLeft: "16px" }}>
            Founded in {data.Company_by_pk.founding_year}
          </span>
        </p>
      </div>
      {
        status === 'PROSPECTIVE'
          ? (
            <div className={[generalClasses.flexAlignCenter, generalClasses.fontInputColor].join(' ')}>
              <CalendarTodaySharpIcon />
              <span style={{ marginLeft: "16px", whiteSpace: "pre" }}>
                {
                  date 
                  ? `Opens in ${formatDateMonthYear(date)}`
                  : "Date unavalabile"
             
              }</span>
            </div>
          )
          : status === 'ACTIVE'
            ? (
              <Fragment>
                <div className={generalClasses.flexAlignCenter} style={{color: '#518149'}}>
                  <AttachMoneyIcon />
                  <span style={{ marginLeft: "12px", whiteSpace: "pre" }}>
                    {
                      amountAvailable
                      ? `${formatValue(amountAvailable)} Available`
                      : "Amount unavalabile"
                    }
                  </span>
                </div>
                <div className={generalClasses.flexAlignCenter} style={{marginLeft: "28px", color: "#9D5050"}}>
                  <CalendarTodaySharpIcon />
                  <span style={{ marginLeft: "12px", whiteSpace: "pre", color: "#9D5050" }}>
                    {
                      date 
                      ? `Closes on ${formatDateDayMonthYear(date)}`
                      : "Date unavalabile"
                    }</span>
                </div>
              </Fragment>
            )
            : (
              <div className={generalClasses.flexAlignCenter} style={{marginLeft: "28px", color: "#9D5050"}}>
                <CalendarTodaySharpIcon />
                <span style={{ marginLeft: "12px", whiteSpace: "pre", color: "#9D5050" }}>
                  {
                    date 
                    ? `Closed in ${formatDateMonthYear(date)}`
                    : "Date unavalabile"
                  }</span>
              </div>
            )
      }

    </div>
  )
}

export default CompanyTitleSection;