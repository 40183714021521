import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const labeledTextStyle = makeStyles({
  root:{},
  label: {
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
    fontSize: '12px',
    lineHeight: '14px !important',
    margin: '0 !important',
    color: '#796E6E !important',
   },
  text: {
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
    fontSize: '15px !important',
    lineHeight: '18px !important',
    margin: '0 !important',
    color: '#505154 !important',
    marginTop: '4px !important',
  }
})
const LabeledText = ({text, label, customStyles={}}) => {
  const classes = labeledTextStyle();

  return (
    <div className={classes.root} style={customStyles}>
      <p className={classes.label}>{label}</p>
      <p className={classes.text}>{text}</p>
    </div>
  )
}

export default LabeledText;