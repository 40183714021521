export const lastValuationFilter  = [
  {text: "< $100M", value: 1},
  {text: "$100M -$500M", value: 2},
  {text: "$500M - $2B", value: 3},
  {text: "> $2B", value: 4},
]

export const companyAge = [
  {text: "< 5 years", value: 1},
  {text: "5 - 8 years", value: 2},
  {text: "8 - 12 years", value: 3},
  {text: "> 12 years", value: 4},
]