import { addInvestmentFlowActions } from "../utils/action-types";

const initialState = {
  title: "",
  deletedSectionEdges: [],
  id: null,
  steps: [],
  investmentCompleted: false,
};

const reapplyOrder = (sections) => sections.map((section, index) => { section.order = index; return section });
const adminAddInvestorFlowState = (state = initialState, action) => {
  switch (action.type) {
    case addInvestmentFlowActions.SET_DATA:
      return {
        ...state,
        deletedSectionEdges: [],
        dealId: action.dealId || null,
        investmentId: action.investmentId || null,
        ...action.payload,
      };

    case addInvestmentFlowActions.CHANGE_STEP_COMPLETED:
      return {
        ...state,
        steps: state.steps.map((x) => {
          if (x.id === action.payload.id) {
            x.completed = !x.completed;
          }
          return x;
        }),
      };

    case addInvestmentFlowActions.SET_ACTIVE_STEP:
      return {
        ...state,
        steps: state.steps.map((x) => {
          if (x.id === action.payload.id) {
            x.current = true;
          } else {
            x.current = false;
          }
          return x;
        }),
      };

    case addInvestmentFlowActions.SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };

    case addInvestmentFlowActions.CREATE_STEP:
      return {
        ...state,
        steps: [
          ...state.steps,
          {
            id: state.steps.length > 0 ? Math.max(...state.steps.map(x => x.id)) + 1 : 1,
            title: "",
            order: state.steps.length,
            parts: [],
            extended: true,
            disableTitleUpdate: false,
          },
        ],
      };

    case addInvestmentFlowActions.DELETE_STEP:
      let newDeletedEdges = state.deletedSectionEdges;
      if (action.payload.edgeId) {
        newDeletedEdges.push(action.payload.edgeId);
      }
      let newSteps = state.steps.filter((x) => x.id !== action.payload.id);
      newSteps = reapplyOrder(newSteps);
      return {
        ...state,
        deletedSectionEdges: newDeletedEdges,
        updateDeletedSteps: true,
        steps: newSteps,
      };

    case addInvestmentFlowActions.REORDER_STEPS:
      let steps = state.steps;
      const stepToReorder = state.steps.splice(action.payload.oldOrder, 1)[0];
      steps.splice(action.payload.newOrder, 0, stepToReorder);
      steps = reapplyOrder(steps);
      return {
        ...state,
        steps: steps,
      };

    case addInvestmentFlowActions.SET_STEP_TITLE:
      return {
        ...state,
        steps: state.steps.map((x) => {
          if (x.id === action.payload.id) {
            x.title = action.payload.name;
          }
          return x;
        }),
      };

    case addInvestmentFlowActions.SET_STEP_DATA:
      return {
        ...state,
        steps: state.steps.map((x) => {
          if (x.id === action.payload.id) {
            x.parts = action.payload.parts;
          }
          return x;
        }),
      };

    case addInvestmentFlowActions.SET_STEP_EXPANDED:
      return {
        ...state,
        steps: state.steps.map((x) => {
          if (x.id === action.payload.id) {
            x.extended = !x.extended;
          }
          return x;
        }),
      };

    case addInvestmentFlowActions.DELETE_STEP_PART:
      return {
        ...state,
        steps: state.steps.map((x) => {
          if (x.id === action.payload.sectionId) {
            x.parts = x.parts.filter(x => x.id !== action.payload.partId);
            x.parts = reapplyOrder(x.parts);
          }
          return x;
        }),
      };

    case addInvestmentFlowActions.REORDER_STEP_PARTS:
      return {
        ...state,
        steps: state.steps.map((x) => {
          if (x.id === action.payload.sectionId) { 
            const partToReorder = x.parts.splice(action.payload.oldOrder, 1)[0]; 
            x.parts.splice(action.payload.newOrder, 0, partToReorder);
            x.parts = reapplyOrder(x.parts); 
          }
          return x;
        }),
      };
    case addInvestmentFlowActions.SET_COMPLETED:
      return {
        ...state,
        investmentCompleted: action.payload,
      };

    default:
      return state;
  }
}

export default adminAddInvestorFlowState; 