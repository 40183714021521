import { Button, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { generalStyleClasses } from "../../../../../utils/general-styles";
import { useRouteMatch } from "react-router-dom";
import { leftsideMenuStyless } from "./LeftsideMenuStyle";
import { useQuery } from "@apollo/client";
import { GET_DEAL_SECTIONS } from "../../../../../utils/deal-queries";
import StickyBox from "react-sticky-box";

const LeftSideMenu = ({ handleShowMenu = () => null }) => {
  const generalClasses = generalStyleClasses();
  const classes = leftsideMenuStyless();
  const { params } = useRouteMatch();
  const [pageLinks, setPageLinks] = useState([]);
  const { data, loading } = useQuery(GET_DEAL_SECTIONS, {
    variables: { id: params.id },
  });

  const handleNavButton = (sectionId) => {
    if (sectionId === 1) {
      window.scrollTo(0, 0);
      return;
    }
    const el = document.getElementById(`section${sectionId}`);
    if (el) {
      el.scrollIntoView();
      window.scrollTo(0, window.scrollY - 156);
    }
  };

  useEffect(() => {
    let pageSections = [{ text: "Overview", order: 1 }];
    if (data && data.Deal_by_pk) {
      if (data.Deal_by_pk.company_description_order !== -1) {
        pageSections.push({
          text: "Company Description",
          order: data.Deal_by_pk.company_description_order + 1,
        });
      }
      if (data.Deal_by_pk.company_management_order !== -1) {
        pageSections.push({
          text: "Company Management",
          order: data.Deal_by_pk.company_management_order + 1,
        });
      }
      if (data.Deal_by_pk.funding_history_order !== -1) {
        pageSections.push({
          text: "Funding History",
          order: data.Deal_by_pk.funding_history_order + 1,
        });
      }
      data.Deal_by_pk.section_files.map((x) =>
        pageSections.push({
          text: x.title || "Files Section",
          order: x.order + 1,
        })
      );
      data.Deal_by_pk.section_images
        .filter((x) => x.title)
        .map((x) =>
          pageSections.push({
            text: x.title || "Image Section",
            order: x.order + 1,
          })
        );
      data.Deal_by_pk.section_links.map((x) =>
        pageSections.push({
          text: x.title || "Links section",
          order: x.order + 1,
        })
      );
      data.Deal_by_pk.section_text_areas.map((x) =>
        pageSections.push({
          text: x.title || "Text section",
          order: x.order + 1,
        })
      );
      setPageLinks(pageSections.sort((a, b) => a.order - b.order));
    }
  }, [data]);

  return (
    <StickyBox offsetTop={148}>
      <div className={classes.card}>
        <img
          src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
          alt="profile"
          className={classes.image}
        />
        <div>
          <p
            className={[generalClasses.fontSize14, classes.text].join(" ")}
            style={{ color: "#6F7074" }}
          >
            Do you have a question? Do not hesitate to contact us.
          </p>
          <Button className={classes.button} onClick={() => handleShowMenu()}>
            <ChatBubbleOutlineIcon
              style={{ width: "16px", height: "16px", marginRight: "12px" }}
            />
            <span
              className={[generalClasses.fontSize12, classes.linkColor].join(
                " "
              )}
            >
              Send a message
            </span>
          </Button>
        </div>
      </div>
      <div className={classes.sideNav}>
        {pageLinks.map((x, index) =>
          x.text.trim().length > 30 ? (
            <Tooltip title={x.text.trim()} arrow>
              <Button
                key={index}
                onClick={() => handleNavButton(x.order)}
                className={[
                  classes.button,
                  classes.linkColor,
                  generalClasses.fontSize14,
                  generalClasses.marginBottom8,
                  classes.linkButton,
                ].join(" ")}
              >
                {x.text.trim()}
              </Button>
            </Tooltip>
          ) : (
            <Button
              key={index}
              onClick={() => handleNavButton(x.order)}
              className={[
                classes.button,
                classes.linkColor,
                generalClasses.fontSize14,
                generalClasses.marginBottom8,
              ].join(" ")}
            >
              {x.text.trim()}
            </Button>
          )
        )}
      </div>
    </StickyBox>
  );
};

export default LeftSideMenu;
