import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  phoneZone: "",
  country: "",
  city: "",
  isClient: "Yes",
  isAccredited: "Yes",
  relationshipManagerId: "",
  accountId: "",
  investorProfileId: 0,
};

const adminCreateAccount = createSlice({
  initialState: initialState,
  name: "adminCreateAccount",
  reducers: {
    RESET: (state) => {
      state.email = "";
      state.firstName = "";
      state.lastName = "";
      state.phoneNumber = "";
      state.phoneZone = "";
      state.country = "";
      state.city = "";
      state.isClient = "Yes";
      state.isAccredited = "Yes";
      state.relationshipManagerId = "";
      state.accountId = "";
      state.investorProfileId = 0;
    },
    SET_EMAIL: (state, action) => {
      state.email = action.payload;
    },
    SET_FIRST_NAME: (state, action) => {
      state.firstName = action.payload;
    },
    SET_LAST_NAME: (state, action) => {
      state.lastName = action.payload;
    },
    SET_PHONE_NUMBER: (state, action) => {
      state.phoneNumber = action.payload;
    },
    SET_PHONE_ZONE: (state, action) => {
      state.phoneZone = action.payload;
    },
    SET_COUNTRY: (state, action) => {
      state.country = action.payload;
    },
    SET_CITY: (state, action) => {
      state.city = action.payload;
    },
    SET_IS_CLINET: (state, action) => {
      state.isClient = action.payload;
    },
    SET_IS_ACCREDITED: (state, action) => {
      state.isAccredited = action.payload;
    },
    SET_RM: (state, action) => {
      state.relationshipManagerId = action.payload;
    },
    SET_ACCOUNT_ID: (state, action) => {
      state.accountId = action.payload;
    },
    SET_INVESTOR_PROFILE_ID: (state, action) => {
      state.investorProfileId = action.payload;
    },
  }
})

export const adminCreateAccountActions = adminCreateAccount.actions;
export const adminCreateAccountReducer = adminCreateAccount.reducer;