import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { IconButton, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
  },
  page: {
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
    border: "1px solid #EBEBEB",
    boxSizing: "border-box",
    cursor: "pointer",
    margin: "0px 4px",
    fontfamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#796E6E",
  },
  activePage: {
    border: "none",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
  }
})
const Pagination = ({ defaultPage, allPagesNumber, handleChange }) => {
  const classes = styles();
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [showPages, setShowPages] = useState([]);

  useEffect(() => {
    if (allPagesNumber === 2) {
      setShowPages([1, 2])
    } else {
      if (currentPage > 1 && currentPage < allPagesNumber - 1) {
        setShowPages([currentPage - 1, currentPage, currentPage + 1])
      } else {
        if (currentPage <= 1) {
          setShowPages([1, 2, 3]);
        } else {
          setShowPages([allPagesNumber - 2, allPagesNumber - 1, allPagesNumber]);
        }
      }
    }
  }, [defaultPage, currentPage, allPagesNumber]);

  useEffect(() => {
    setCurrentPage(defaultPage)
  }, [defaultPage])

  useEffect(() => {
    if (handleChange) {
      handleChange(currentPage)
    }
  }, [currentPage])

if (!allPagesNumber || allPagesNumber <= 1) return <div />

return (
  <div className={classes.root}>
    <IconButton disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
      <KeyboardArrowLeftIcon style={{ fill: currentPage === 1 ? "#A9A9A9" : "#796E6E" }} />
    </IconButton>
    {showPages.map((x, index) => <div key={index} className={[classes.page, currentPage === x ? classes.activePage : ""].join(' ')} onClick={() => setCurrentPage(x)}>{x}</div>)}
    <IconButton disabled={currentPage === allPagesNumber} onClick={() => setCurrentPage(currentPage + 1)}>
      <KeyboardArrowRightIcon style={{ fill: currentPage === 1 ? "#A9A9A9" : "#796E6E" }} />
    </IconButton>
  </div>
)
}

export default Pagination;