import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import TextInput from '../../../shared/TextInput/TextInput';
import { adminCreateAccountActions } from '../../../store/slices/rmCreatedAccount';
import { adminDimensionsStyle, adminTypografyStyles } from '../../AdminStyles';

const CreateAccountStep = () => {
    const dimensionClasses = adminDimensionsStyle();
    const typografyClasses = adminTypografyStyles();
    const dispatch = useDispatch();

    const handleSetEmail = (value) => {
        dispatch(adminCreateAccountActions.SET_EMAIL(value))
    }
    return (
        <Fragment>
            <h4 className={[dimensionClasses.marginBottom0, dimensionClasses.marginTop12, typografyClasses.fontSize16, typografyClasses.fontColor505154].join(' ')}>Enter email to get started</h4>
            <div className={dimensionClasses.marginTop24}>
                <TextInput label="Email" handleChange={value => handleSetEmail(value)} />
            </div>
        </Fragment>
    )
}

export default CreateAccountStep;