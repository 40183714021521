export default ["Donec dictum accumsan facilisis sociosqu mollis adipiscing vehicula, ornare fusce elementum bibendum sagittis pretium tincidunt, convallis placerat lobortis sit ultricies non. Montes urna litora aenean habitant lacinia a, netus condimentum dictumst natoque nec, hendrerit ultrices lectus aliquam diam. A blandit senectus in ad sit dolor dapibus cubilia suspendisse congue, convallis orci gravida elementum euismod aenean torquent dictumst vehicula pharetra est, justo vivamus per auctor velit varius natoque mus semper. Taciti hendrerit fermentum semper ipsum sit amet euismod, nisl malesuada curae metus feugiat ac. Ligula consequat nam ornare tortor sollicitudin diam gravida consectetur, conubia quis massa cursus tristique aliquam himenaeos maecenas, posuere pulvinar semper odio nulla justo per.",
"Tellus augue ultrices faucibus euismod semper enim leo ut diam, odio orci primis dolor metus dui torquent neque blandit, per maximus pretium ornare vivamus himenaeos mus porttitor.",
"Potenti blandit penatibus ultricies mollis interdum lacinia habitant cras, dis orci efficitur maecenas mattis fusce. Natoque dui lacinia inceptos lobortis aliquet ornare sociosqu morbi vulputate, condimentum urna aptent pellentesque eget sodales varius feugiat. Convallis posuere cursus nulla dictum lacus ullamcorper sit id nullam lacinia, auctor congue suscipit enim integer potenti fames magnis efficitur nam scelerisque, velit pulvinar porta finibus sapien turpis justo rutrum consequat. Montes eget facilisi fusce nulla turpis sapien, commodo volutpat imperdiet primis netus, torquent adipiscing quam tristique velit.",
"Ad fusce maximus eros cras lectus venenatis arcu neque aliquam justo, aptent natoque taciti habitant pulvinar velit est etiam nec, viverra platea elit dignissim hac tempor sapien laoreet inceptos. Velit per primis viverra dapibus montes maximus magna dolor, senectus ut magnis vel est natoque id fusce, vulputate odio dui mi interdum elit faucibus. Penatibus a conubia venenatis gravida nascetur maecenas curabitur euismod tempor pharetra, egestas tortor non condimentum platea vestibulum tincidunt luctus. Natoque tempus finibus diam lectus accumsan sociosqu pharetra blandit hac vulputate, nam tempor venenatis ultrices rutrum posuere quis erat per faucibus pulvinar, eleifend phasellus himenaeos mollis a fermentum maecenas in adipiscing. Arcu lacus gravida sollicitudin libero dictum ligula senectus bibendum pellentesque, habitant odio congue platea amet metus porta nisl magnis, nam erat eget mi malesuada sociosqu montes facilisis.",
"Potenti efficitur condimentum interdum maximus leo conubia finibus eu auctor, molestie class volutpat tellus porttitor mattis platea etiam, dolor ut faucibus consequat arcu hac vestibulum tempor. Dui dignissim urna lacinia aptent primis adipiscing, arcu inceptos conubia eu dis tincidunt lobortis, turpis congue massa fringilla fames. Duis sem maecenas hac non justo velit nibh et fringilla, mollis sapien ridiculus torquent pharetra eros suspendisse eget laoreet, dictumst sagittis vulputate posuere curabitur venenatis odio faucibus. Ligula turpis fermentum sit lobortis himenaeos tristique, bibendum curae sodales eu posuere arcu, non varius id vitae ",
"Consequat lacus enim class montes dis faucibus neque lectus, praesent nullam torquent bibendum viverra elit himenaeos ridiculus curae, iaculis vel penatibus dolor urna dapibus hendrerit.",
"Lorem consectetur urna scelerisque torquent cras vestibulum a arcu finibus, amet eleifend sociosqu per sodales cubilia porta pharetra, aliquam natoque turpis integer habitasse malesuada tellus ultricies. Montes ad in vehicula nam eget magnis aliquet purus nascetur parturient, risus eu phasellus odio ridiculus finibus luctus sociosqu ante. Eros justo hendrerit habitasse netus vitae quisque consequat nostra aenean parturient, faucibus dolor habitant diam tortor nisi donec vel eu ullamcorper, quis ultricies finibus aliquet dapibus imperdiet porttitor varius torquent. Gravida vestibulum viverra turpis luctus urna porta consequat dapibus enim, parturient nec vitae ultricies mauris ad nullam quam, eros elementum accumsan etiam ullamcorper iaculis netus habitasse. Mus maximus nisi sociosqu porttitor iaculis tempor habitasse curabitur diam gravida ornare, tristique arcu efficitur consequat tempus quis adipiscing laoreet justo congue. ",
"Aenean vulputate iaculis lobortis volutpat auctor leo maximus, bibendum massa laoreet parturient ac conubia vel, sed aliquam sociosqu viverra montes lacinia. Parturient purus sociosqu congue aliquam integer rhoncus luctus praesent, lorem fames fermentum pellentesque tempor porttitor malesuada nisl, lobortis curabitur magna pulvinar ridiculus molestie sem dis, lectus mus mi semper sit imperdiet quisque. Mi ac libero enim vitae feugiat nibh consequat nulla per tempor, ridiculus sollicitudin ad maecenas hendrerit posuere finibus leo volutpat.",
"Natoque elementum molestie pulvinar gravida rutrum commodo fringilla himenaeos lorem vivamus montes, nec class ante ornare blandit nullam per luctus vulputate vestibulum.",
"Class dolor parturient posuere cras justo metus conubia, interdum vitae torquent diam nibh eu, ultricies inceptos integer vehicula dignissim vel. Felis ad rutrum imperdiet commodo pretium urna viverra elementum dis mauris lacus justo, metus nunc integer turpis aenean ante euismod dictumst nulla eu accumsan molestie, tristique elit auctor nam libero nullam interdum suspendisse sagittis aptent penatibus. Purus cubilia felis elementum velit dignissim dis dapibus fames, ante pulvinar fringilla scelerisque justo conubia litora sodales, rhoncus mus neque pharetra diam dui egestas.",
"Sodales curae integer mollis sollicitudin duis tempor sociosqu enim sed morbi ex condimentum, dignissim facilisis nam aliquet nunc placerat eget nulla imperdiet pharetra nostra, facilisi porta per ad congue quam varius suscipit cras bibendum dui."]