import React, { useState } from 'react';
import { adminAlignmentStyles, adminDimensionsStyle, adminTypografyStyles } from '../AdminStyles';
import SmallButtonGroup from '../../shared/SmallButtonsGroup/SmallButttonsGroup';
import CompanyClickTable from './CompanyTable';
import DealsTable from './DealsTable';
import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core';

const timePeriodButtons = [
  { text: "24 hours", value: Date.now() - 1000 * 60 * 60 * 24 },
  { text: "7 days", value: Date.now() - 1000 * 60 * 60 * 24 * 7 },
  { text: "30 days", value: Date.now() - 1000 * 60 * 60 * 24 * 30 },
  { text: "All time", value: 0 },
]

const customButtonStyle = makeStyles({
  button: {
    width: '129px',
    height: '32px',
    background: '#FFFFFF',
    border: '1px solid #EBEBEB',
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    marginTop: '8px',
    color: '#796E6E',
    "&:hover": {
      border: '1px solid rgba(67, 68, 72, 0.04)',
      backgroundColor: '#777777',
      color: "#ffffff",
    },
  },
})
const CompanyAnalytics = () => {
  const customButtonClasses = customButtonStyle();
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const typographyClasses = adminTypografyStyles();
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(new Date())

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenCustomTime = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewDateSelected = (date) => {
    setStartDate(date)
    setEndDate(new Date())
  }

  return (
    <div className={[dimensionClasses.headerMarginFix, dimensionClasses.containerWidth].join(' ')}>
      <div className={alignmentClasses.flexAlignCenter}>
        <span className={[typographyClasses.fontSize14, typographyClasses.fontbold, dimensionClasses.marginRight12].join(' ')}>Period of time</span>
        <SmallButtonGroup buttonList={timePeriodButtons} handleChange={value => handleNewDateSelected(value.value)} buttonStyle={{ marginRight: "16px", marginTop: "0px" }} />
        <Button onClick={handleOpenCustomTime} variant='outlined' color="primary" className={customButtonClasses.button} style={{marginTop: "0px"}}>
          Custom
      </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{marginTop: "40px"}}
          disableScrollLock={true}
        >
          <MenuItem className={alignmentClasses.flexCenterSpaceBetween}><span>Start time</span><input type="date" onChange={e => setStartDate(e.target.value)} style={{marginLeft: "12px"}}/></MenuItem>
          <MenuItem className={alignmentClasses.flexCenterSpaceBetween}><span>End time</span><input type="date" onChange={e => setStartDate(e.target.value)} style={{marginLeft: "12px"}}/></MenuItem>
        </Menu>
      </div>
      <p className={[typographyClasses.fontbold, typographyClasses.fontSize15, dimensionClasses.marginTop24, dimensionClasses.marginBottom0].join(' ')}>Company interests:</p>
      <CompanyClickTable startTime={new Date(startDate)} endTime={new Date(endDate)} />
      <p className={[typographyClasses.fontbold, typographyClasses.fontSize15, dimensionClasses.marginTop24, dimensionClasses.marginBottom0].join(' ')}>Deal interests:</p>
      <DealsTable startTime={new Date(startDate)} endTime={new Date(endDate)}/>
    </div>
  )
}

export default CompanyAnalytics;