import React, { Fragment, useEffect } from 'react';
import Navbar from './Navbar/Navbar';
import Intercom from 'intercom-react';
import { BrowserRouter as Router, Switch, Route, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import Account from './Account/Account';
import OpportunitiesPage from './MainPages/OpportunitiesPage/OpportunitiesPage';
import PreferancesPage from './MainPages/PreferancesPage/PreferancesPage';
import DetailedOpportunityPage from './MainPages/DetailedOpportunityPage/DetailedOpportunityPage';
import WatchlistPage from './MainPages/ManagePage/Watchlist/WatchlistPage';
import NotInterestedFeedback from './NotInterestedFeedback/NotInterestedFeedback';
import InvestmentsPage from './MainPages/ManagePage/Investments/InvestmentsPage';
import IndicateInterest from './IndicateInterest/IndicateInterest';
import Messages from './Messages/Messages';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { CAN_ENTER_APP } from '../../utils/account-queries';
import { hasuraErrorHandler } from '../../shared/EventEmitter/EventEmitter';
import Loading from '../../shared/Loading/Loading';
import firebase from 'firebase';
import { LOGOUT, SET_USER_AUTH } from '../../store/utils/action-types';
import { permissionAsignmentsActions } from '../../store/slices/permissions';
import DocumentsPage from './Documents/Documents';
import InvestmentFlow from './InvestmentFlow/InvestmentFlow';
import ModifyInvestment from './IndicateInterest/ModifyInvestment';

const MainApp = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const { data: canAccessApp } = useQuery(CAN_ENTER_APP, { variables: { firebase_id: firebaseId }, fetchPolicy: "network-only" });
  const dispatch = useDispatch();

  useEffect(() => {
    if (canAccessApp) {
      if (canAccessApp.Permissions_assignment.length === 0) {
        firebase.auth().signOut().then(() => {
          hasuraErrorHandler.printError("You are not approved to use the app yet");
          dispatch({ type: SET_USER_AUTH, payload: { jwtToken: '' } });
          dispatch({ type: LOGOUT });
          dispatch(permissionAsignmentsActions.RESET_PERMISSIONS());
          history.push('/');
        })
      }
    }
  }, [canAccessApp]);

  if (!canAccessApp) {
    return <Loading fullWidth />
  }
  return (
    <Fragment>
      <Router>
        <Navbar />
        <div className="app-router-container" style={{ display: 'flex', justifyContent: 'center', marginTop: "56px" }}>
          <Switch>
            <Route path={`${url}/account`}>
              <Account />
            </Route>
            <Route path={`${url}/preferences`}>
              <PreferancesPage />
            </Route>
            <Route path={`${url}/opportunities`}>
              <OpportunitiesPage />
            </Route>
            <Route path={`${url}/watchlist`}>
              <WatchlistPage />
            </Route>
            <Route path={`${url}/investments`}>
              <InvestmentsPage />
            </Route>
            <Route path={`${url}/view-opportunity/:id`} >
              <DetailedOpportunityPage />
            </Route>
            <Route path={`${url}/document-handler/:dealId/:investmentId`}>
              <InvestmentFlow />
            </Route>
            <Route path={`${url}/not-interested/:id`}>
              <NotInterestedFeedback />
            </Route>
            <Route path={`${url}/indicate-interest/:id/:predefinedAmmount?`}>
              <IndicateInterest />
            </Route>
            <Route path={`${url}/modify-ammount/:id/:predefinedAmmount?`}>
              <ModifyInvestment />
            </Route>
            <Route path={`${url}/invest/:id/:predefinedAmmount?`}>
              <IndicateInterest invest />
            </Route>
            <Route path={`${url}/documents`}>
              <DocumentsPage />
            </Route>
            {/* TODO: look over permissions to get general chat */}
            <Route path={`${url}/messages/`} >
              <Messages isGeneral={false}/>
            </Route>
            <Route exact path={`${url}*`} component={() => <Redirect to={`${url}/opportunities`} />} />
          </Switch>
        </div>
      </Router>
      {/* <Intercom
        appId="xy4fas2n"
        user={{
          user_id: '9876',
          email: 'john.doe@example.com',
          created_at: 1234567890,
          name: 'John Doe',
        }}
      /> */}
    </Fragment>)
}

export default MainApp;