import React, { useState } from 'react';
import { generalStyleClasses } from '../../../utils/general-styles';
import { detailedOpportunityPageStyles } from '../MainPages/DetailedOpportunityPage/DetailedOpportunityPageStyle';
import ChatContainer from './ChatContainer';
import ChatPeople from './ChatPeople';

const Messages = ({isGeneral = false}) => {
  const generalStyles = generalStyleClasses();
  const classes = detailedOpportunityPageStyles();
  const [admin, setAdmin] = useState(null);

  return (
    <div className={[generalStyles.fullViewWidth, generalStyles.flexAlignCenterOnColumn].join(' ')}>
      <div className={[classes.topsideMenu, generalStyles.flexAlignCenter].join(' ')}>
        <span className={[generalStyles.fontSize15, generalStyles.fontColorDark, generalStyles.fontSize15].join(' ')} style={{marginLeft: "-480px"}}>
          Messages
        </span>
      </div>
      <div className={classes.contentContainer}>
        <div style={{width: "304px"}}>
          <ChatPeople handleSetAdmin={admin => setAdmin(admin)}/>
        </div>
          <ChatContainer dealId={185} defaultAdmin={admin} isGeneral={isGeneral}/>
      </div>
    </div>
  )
}

export default Messages;