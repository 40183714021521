import { SET_REGISTER_STEP_PERSONAL_INFOS, SET_REGISTER_STEP_LAST_STEP, SET_REGISTRATION_USER_ID } from "../utils/action-types";

const initialState = {
  registerState: 0,
  registrationUserId: '',
}

const updateRegistrationState = (state = initialState, action) => {
  switch (action.type) {
    case SET_REGISTRATION_USER_ID:
      return {
        ...state,
        registrationUserId: action.payload.value
      }
    case SET_REGISTER_STEP_LAST_STEP:
      return {
        ...state,
        registerState: SET_REGISTER_STEP_LAST_STEP
      }
    case SET_REGISTER_STEP_PERSONAL_INFOS:
      return {
        ...state,
        registerState: SET_REGISTER_STEP_PERSONAL_INFOS
      }
    default:
      return state;
  }
}

export default updateRegistrationState;