import { useQuery } from '@apollo/client';
import React, { Fragment, useState} from 'react';
import OrderButtons from '../../shared/OrderButtons/OrderButtons';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from '../AdminStyles';
import { GET_COMPANY_ANALYTICS } from "../queries/admin-queries";
import { formatMiliseconds, numberWithCommas } from '../../utils/formats';
import Tooltip from '@material-ui/core/Tooltip'
const today = new Date();
const CompanyTable = ({ startTime = new Date(0), endTime = today }) => {
  const dimensionClasses = adminDimensionsStyle();
  const tableClasses = adminTablesStyle();
  const typografyClasses = adminTypografyStyles();
  const alignmentClasses = adminAlignmentStyles();
  const [nameOrder, setNameOrder] = useState("desc_nulls_last");
  const [interestedClickOrder, setInterestedClickOrder] = useState(null);
  const [investmentCounterOrder, setInvestmentCounterOrder] = useState(null);
  const [availableAmmountOrder, setAvailableAmmountOrder] = useState(null);
  const [watchlistOrder, setWatchlistOrder] = useState(null);
  const [viewMoreOrder, setViewMoreOrder] = useState(null);
  const [interestFeedbackOrder, setInterestFeedbackOrder] = useState(null);
  const [averageSessionOrder, setAverageSessionOrder] = useState(null);
  const [notInterestedOrder, setNotInterestedOrder] = useState(null);
  const [availableOrder, setAvailableOrder] = useState(null);
  const [showDeals, setShowDeals] = useState([]);
  const [messages, setMessagesOrder] = useState(null);

  const { data } = useQuery(GET_COMPANY_ANALYTICS, {
    variables: {
      time1: startTime.toISOString(),
      time3: startTime.toISOString(),
      time2: endTime.toISOString(),
      time4: endTime.toISOString(),
      messageOrder: messages,
      declaredOrder: investmentCounterOrder,
      completedOrder: notInterestedOrder,
      commitedOrder: availableOrder,
      nameOrder: nameOrder,
      averageSessionOrder: averageSessionOrder,
      interestFeedbackOrder: interestFeedbackOrder,
      viewMoreOrder: viewMoreOrder,
      watchlistOrder: watchlistOrder,
      availableAmmountOrder: availableAmmountOrder,
      interestedClickOrder: interestedClickOrder,
      investmentCounterOrder: investmentCounterOrder,
    },
    fetchPolicy: "network-only",
  });

  console.log(data);
  const sortables = [setNameOrder, setMessagesOrder, setInterestedClickOrder, setNotInterestedOrder, setAvailableOrder, setInvestmentCounterOrder, setAvailableAmmountOrder, setWatchlistOrder, setViewMoreOrder, setInterestFeedbackOrder, setAverageSessionOrder];
  return (
    <Fragment>
      <div className={[dimensionClasses.containerWidth].join(' ')} style={{ display: "flex", maxWidth: "none" }}>
        <div >
          <div className={[tableClasses.tableHeader, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14, dimensionClasses.marginTop24].join(' ')}>
            <Tooltip arrow placement="top" title="Name of the company">
              <div className={[[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')].join(' ')}>  
                Company
            <OrderButtons value={nameOrder} orderFunctions={sortables} specificFunction={value => setNameOrder(value)} />
              </div>
            </Tooltip>
          </div>
          {
            data && data.Company
            && data.Company.map((x, index) => (
              <Fragment>
                <div key={index} className={[tableClasses.row, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14].join(' ')}
                  style={{ width: "auto", marginBottom: showDeals.filter(deal => deal.id === x.id)[0]?.height || "0px" }}>
                  <p className={[[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')].join(' ')}>
                    {x.name}
                  </p>
                </div>
              </Fragment>
            ))
          }
        </div>
        <div style={{ overflow: "auto" }}>
          <div className={[tableClasses.tableHeader, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14, dimensionClasses.marginTop24].join(' ')} style={{ width: "max-content" }}>
            <Tooltip arrow placement="top" title="Number of recorded additions to users' watchlist">
              <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                Watchlist
                <OrderButtons value={watchlistOrder} orderFunctions={sortables} specificFunction={value => setWatchlistOrder(value)} />
              </div>
            </Tooltip>
            <Tooltip arrow placement="top" title="Number of combined views (across all deals related to the company) recorded when a user clicks 'Learn more' in the deal page">
              <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                Views
          <OrderButtons value={viewMoreOrder} orderFunctions={sortables} specificFunction={value => setViewMoreOrder(value)} />
              </div>
            </Tooltip>
            <Tooltip arrow placement="top" title="Number of uniquely recorded expressions of interest (across all deals related to the company), when a user clicks 'Invest' or 'Indicate Interest' in the deal page">
              <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                Interested
          <OrderButtons value={interestedClickOrder} orderFunctions={sortables} specificFunction={value => setInterestedClickOrder(value)} />
              </div>
            </Tooltip>
            <Tooltip arrow placement="top" title="Number of recorded completed investments by investors (across all deals related to the company)">
              <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                Completed
          <OrderButtons value={notInterestedOrder} orderFunctions={sortables} specificFunction={value => setNotInterestedOrder(value)} />
              </div>
            </Tooltip>
            <Tooltip arrow placement="top" title="Number of recorded feedbacks from users when they communicate why they're not interested in the investment opportunity (e.g. if the complete the section when they click 'If not interested, tell us why') - statistic across all deals related to the company">
              <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                Not interested
          <OrderButtons value={interestFeedbackOrder} orderFunctions={sortables} specificFunction={value => setInterestFeedbackOrder(value)} />
              </div>
            </Tooltip>
            <Tooltip arrow placement="top" title="Average time spent on the detailed page (across all deals related to the company)">
              <div className={[dimensionClasses.width200, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                Avge session time
          <OrderButtons value={averageSessionOrder} orderFunctions={sortables} specificFunction={value => setAverageSessionOrder(value)} />
              </div>
            </Tooltip>
            <Tooltip arrow placement="top" title="Total amount of recorded interest in the opportunity when the user clicks 'Invest' or 'Indicate Interest' and communicate an investment amount - across all deals related to the company">
              <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                Declared
          <OrderButtons value={investmentCounterOrder} orderFunctions={sortables} specificFunction={value => setInvestmentCounterOrder(value)} />
              </div>
            </Tooltip>
            <Tooltip title="Total amount of recorded binding commitment that has gone through the process all the way to signing investment documents and wiring funds - across all deals related to the company">
              <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                Committed
          <OrderButtons value={availableOrder} orderFunctions={sortables} specificFunction={value => setAvailableOrder(value)} />
              </div>
            </Tooltip>
            <Tooltip arrow placement="top" title="Total amount available for the deal - across all deals related to the company">
              <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                Available
          <OrderButtons value={availableAmmountOrder} orderFunctions={sortables} specificFunction={value => setAvailableAmmountOrder(value)} />
              </div>
            </Tooltip>
            <Tooltip arrow placement="top" title="Number of messages users send for all the deals of this company">
              <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                Messages
          <OrderButtons value={messages} orderFunctions={sortables} specificFunction={value => setMessagesOrder(value)} />
              </div>
            </Tooltip>
          </div>
          {
            data && data.Company
            && data.Company.map((x, index) => (
              <div key={index}>
                <div key={index} className={[tableClasses.row, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14].join(' ')} style={{ width: "max-content" }}>
                  <p className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                    {x.watchlist.aggregate?.sum?.counter || "-"}
                  </p>
                  <p className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                    {x.views_total.aggregate?.sum?.counter || "-"}
                  </p>
                  <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                    {x.interested?.aggregate?.sum?.counter || "-"}
                  </p>
                  <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                    {x.completed.aggregate?.sum?.counter || "-"}
                  </p>
                  <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                    {x.not_interested.aggregate?.sum?.counter || "-"}
                  </p>
                  <p className={[dimensionClasses.width200, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                    {formatMiliseconds(x.avge_session_time.aggregate?.avg?.average) || "-"}
                  </p>
                  <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                    {x.declared.aggregate?.sum?.value && `$${numberWithCommas(x.declared.aggregate?.sum?.value)}` || "-"}
                  </p>
                  <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                    {x.committed.aggregate?.sum?.value && `$${numberWithCommas(x.committed.aggregate?.sum?.value)}` || "-"}
                  </p>
                  <p className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                    {x.available[0]?.amount && `$${numberWithCommas(x.available[0]?.amount)}` || "-"}
                  </p>
                  <p className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
                    {x.messages.aggregate?.sum?.counter || "-"}
                  </p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Fragment>
  )
}

export default CompanyTable;