import { useMutation, useQuery } from '@apollo/client';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useInvestorPreferencesStyles } from '../../../investor-preferences/InvestorPreferencesStyle';
import { generalStyleClasses } from '../../../utils/general-styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import { Fragment } from 'react';
import Loading from '../../../shared/Loading/Loading';
import SuccessMessage from '../../../shared/SuccessMessage/SuccessMessage';
import { useSelector } from 'react-redux';
import TextInput from '../../../shared/TextInput/TextInput';
import SmallButtonGroup from '../../../shared/SmallButtonsGroup/SmallButttonsGroup';
import { getDealInvestmentFlow, GET_DEAL_COMPANY_NAME, GET_INTEREST, INSERT_INTEREST, UPDATE_INTEREST } from '../../../utils/deal-queries';
import { adminDimensionsStyle, adminTypografyStyles, adminAlignmentStyles } from '../../../admin/AdminStyles';
import { hasuraErrorHandler } from '../../../shared/EventEmitter/EventEmitter';
import { getRoundedCeilValue, getRoundedFloorValue, numberWithCommas } from '../../../utils/formats';

const customStyles = makeStyles({
  headerOverlay: {
    position: "absolute",
    top: "0px",
    zIndex: "200",
    background: "#e5e5e5",
  },
  closeIcon: {
    position: "absolute",
    right: "20px",
    top: "20px",
  }
})

const IndicateInterest = ({ invest }) => {
  const classes = useInvestorPreferencesStyles();
  const genericClasses = generalStyleClasses();
  const customClasses = customStyles();
  const typografyClasses = adminTypografyStyles();
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const history = useHistory();
  const [amountSent, setAmountSent] = useState(false);
  const dealId = useRouteMatch().params.id;
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const [createInterest] = useMutation(INSERT_INTEREST);
  const [updateInterest] = useMutation(UPDATE_INTEREST);
  const {data: predefinedAmmountData} = useQuery(GET_INTEREST, {variables: {deal_id: dealId, firebase_id: firebaseId}, fetchPolicy: "network-only"});
  const [amount, setAmount] = useState(useRouteMatch().params.predefinedAmmount);
  const [buttonOptions, setButtonOptions] = useState([
    { text: "$0", value: 0 },
    { text: "$0", value: 0 },
    { text: "$0", value: 0 },
    { text: "$0", value: 0 }
  ]);
  const companyData = useQuery(GET_DEAL_COMPANY_NAME, { variables: { id: dealId } }).data;

  const handleInvestButton = () => {
    if (!invest) {
      const params = {
        variables: {
          ammount: amount,
          firebase_id: firebaseId,
          deal_id: dealId,
        }
      }
      if(predefinedAmmountData && predefinedAmmountData.Interest[0]) {
        updateInterest(params).then(() => setAmountSent(true))
      } else {
        createInterest(params).then(() => setAmountSent(true))
      }
    } else {
      getDealInvestmentFlow(Number(dealId), amount)
        .then(res => res.json())
        .then(res => {
          if (res.status === "OK") {
            history.push(`/app/document-handler/${dealId}/${res.result.investmentId}`)
          } else {
            hasuraErrorHandler.printError(res.result.message)
          }
        })
        .catch(console.log);
    }
  }

  useEffect(() => {
    if (amountSent === true && !invest) {
      const timeout = setTimeout(function () {
        clearTimeout(timeout);
        history.goBack();
      }, 2000);
    }
  }, [amountSent]);

  useEffect(() => {
    if(predefinedAmmountData && predefinedAmmountData.Interest[0] && !amount){
      setAmount(predefinedAmmountData.Interest[0].ammount)
    }
  }, [predefinedAmmountData])

  useEffect(() => {
    if(companyData) {
      const min = getRoundedCeilValue(companyData.Deal_by_pk.investment_min)
      const max = getRoundedFloorValue(companyData.Deal_by_pk.investment_max);
      const first = getRoundedCeilValue((min+max)/3);
      const second = getRoundedFloorValue((min+max)*2/3);
      setButtonOptions([
        { text: numberWithCommas(`$${min}`), value: min },
        { text: numberWithCommas(`$${first}`), value: first },
        { text: numberWithCommas(`$${second}`), value: second },
        { text: numberWithCommas(`$${max}`), value: max },
      ])
    }
  }, [companyData])
  if (!companyData) return <Loading fullWidth />

  return (
    <div className={[classes.routerContainer, customClasses.headerOverlay].join(' ')}>
      <IconButton className={customClasses.closeIcon} onClick={() => history.goBack()}>
        <CancelIcon />
      </IconButton>
      <p className={[genericClasses.marginTop40, genericClasses.marginBottom0, genericClasses.fontAlignCenter, genericClasses.fontInputColor, genericClasses.fontSize16].join(' ')} style={{ width: '560px' }}>
        {!amountSent
          ? `What amount would you like to invest in ${companyData.Deal_by_pk.company.name}?`
          : companyData.Deal_by_pk.company.name
        }
      </p>
      <div className={classes.investmentPreferencesContainer} style={{ padding: "32px", marginTop: "38px" }}>
        {
          !amountSent
            ? (
              <div className={alignmentClasses.flexAlignCenterOncolumn}>
                <p className={[typografyClasses.fontColor505154, typografyClasses.fontSize15, dimensionClasses.marginBottom0, dimensionClasses.marginTop0]} style={{ textAlign: "center" }}>
                  This stage of investment is {!invest && "non-" || ""}binding.
                  </p>
                <div className={genericClasses.flexAlignCenter}>
                  <span style={{ marginRight: "20px", marginTop: "4px" }} className={[genericClasses.fontSize14, genericClasses.fontInputColor].join(' ')}>Min: ${numberWithCommas(companyData.Deal_by_pk.investment_min)}</span>
                  <TextInput inputStyle={{ padding: "12px" }} customStyle={{ width: "266px" }} placeholder="e.g. $500,000" isNumber isMoneySum initialValue={amount} handleChange={value => setAmount(value)} />
                  <span style={{ marginLeft: "20px", marginTop: "4px" }} className={[genericClasses.fontSize14, genericClasses.fontInputColor].join(' ')}>Max: ${numberWithCommas(companyData.Deal_by_pk.investment_max)}</span>
                </div>
                <SmallButtonGroup containerStyle={{ width: "100%", marginTop: "12px" }} buttonStyle={{ width: "100px" }} buttonList={buttonOptions} handleChange={amount => setAmount(amount.value)} />
                <div className={[alignmentClasses.flexCenterSpaceBetween, dimensionClasses.width100Percent, dimensionClasses.marginTop40].join(' ')}>
                  <Button className={dimensionClasses.width100Percent} variant="outlined" color="primary" onClick={() => history.goBack()}>Cancel</Button>
                  <Button disabled={!amount} className={[dimensionClasses.width100Percent, dimensionClasses.marginLeft20].join(' ')} variant="contained" color="primary" onClick={() => handleInvestButton()}>
                    {invest ? "Invest" : predefinedAmmountData && predefinedAmmountData.Interest[0] ? "Update Interest" : "Indicate Interest"}
                  </Button>
                </div>
              </div>
            )
            : <SuccessMessage message="Your investment interest has been updated" />
        }
      </div>
    </div>
  )
}

export default IndicateInterest;