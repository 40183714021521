import { Button } from "@material-ui/core";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  adminDimensionsStyle,
  adminTypografyStyles,
} from "../../AdminStyles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddIcon from "@material-ui/icons/Add";
import store from "../../../store/store";
import { mapNewFlow } from "./onboardingMapper";
import {
  deleteOnboardingEdges,
  saveOnboardingFlowTemplate,
} from "../../queries/admin-http-calls";
import { useMutation } from "@apollo/client";
import {
  UPDATE_ONBOARDING_SECTION_ORDER_BY_EDGE,
} from "../../queries/admin-queries";
import { addDocumentsForOnboardingFlowEdge } from "../../../utils/file-queries";
import { useDispatch, useSelector } from "react-redux";
import {
  Events,
  EventThrower,
} from "../../../shared/EventEmitter/EventEmitter";
import { adminOnboardingFlowActions } from "../../../store/slices/adminOnboardingFlow";

export const AddOnboardingFlowHeader = () => {
  const dimensionClasses = adminDimensionsStyle();
  const typografyclasses = adminTypografyStyles();
  const disableSaveButton = useSelector(store => store.adminOnboardingFlow.steps.length === 0)
  const updateSectionOrder = useMutation(UPDATE_ONBOARDING_SECTION_ORDER_BY_EDGE)[0];
  const history = useHistory();
  const dispatch = useDispatch();

  const handleAddStep = () => {
    dispatch(adminOnboardingFlowActions.CREATE_STEP());
  };

  const handleSaveFlow = async () => {
    const flow = store.getState().adminOnboardingFlow;
    try {
      const mappedFlow = mapNewFlow(flow);
       await deleteOnboardingEdges(flow.stepsToDelete.map(x => x.edgeId), flow.itemsToDelete.map(x => x.edgeId));
       await saveOnboardingFlowTemplate(mappedFlow);
      if (flow.steps.filter((step) => step.edgeId).length > 0) {
        await Promise.all([
          flow.steps
          .filter((step) => step.edgeId)
          .reduce((previousValue, currentValue) => {
            return previousValue.then(() => updateSectionOrder({variables: { id: currentValue.edgeId, order: currentValue.order, current: currentValue.current, completed: currentValue.completed } }))
          }, Promise.resolve(true)),
        ]);
      }
      if(flow.filesToUpload.length > 0) {
        await Promise.all([
          flow.filesToUpload
          .filter((fileSection) => fileSection.edgeId)
          .reduce((previousValue, currentValue) => {
            return previousValue.then(() => addDocumentsForOnboardingFlowEdge(currentValue.files, currentValue.firebaseId || null, currentValue.edgeId))
          }, Promise.resolve(true)),
        ]);
      } 
      EventThrower.throwClientSideMessage(
        Events.SuccessMessage("Investment updated")
      );
      history.goBack();
    }
    catch(error) {
      console.log(error);
    }
          
  };

  return (
    <Fragment>
      <div>
        <Button
          onClick={() => history.goBack()}
          className={[
            dimensionClasses.smallHeigth,
            typografyclasses.fontSize16,
            typografyclasses.noTrasnform,
            typografyclasses.fontColor78756F,
          ].join(' ')}
        >
          <KeyboardBackspaceIcon />
          Back
        </Button>
        <span>Onboarding Flow</span>
      </div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          className={dimensionClasses.smallHeigth}
          onClick={() => handleAddStep()}
        >
          <AddIcon style={{ marginRight: "8px" }} />
          Add step
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={[
            dimensionClasses.smallHeigth,
            dimensionClasses.marginLeft16,
          ].join(" ")}
          disabled={disableSaveButton}
          onClick={() => handleSaveFlow()}
        >
          Save changes
        </Button>
      </div>
    </Fragment>
  );
};
