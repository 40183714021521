import { gql } from "@apollo/client";
import store from "../store/store";

export const insertAnalytics = ({
  companyId,
  dealId,
  event,
  firebaseId,
  payload,
  value,
}, keepalive = false) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/analytics/insert/`, {
    method: "POST",
    body: JSON.stringify({
      companyId,
      dealId,
      event,
      firebaseId,
      payload,
      value,
    }),
    headers: {
      Authorization: `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json",
    },
    keepalive,
  });
};

export const GET_DEAL_ANALYTICS_GENERAL_INFO = gql`
query MyQuery($time1: timestamp = "", $time2: timestamp = "", $time3: timestamptz = "", $time4: timestamptz = "", $investmentAmountAvailableOrder: order_by = null, $investmentDeadlineOrder: order_by = null, $statusOrder: order_by = null, $titleOrder: order_by = null, $messageOrder: order_by = null, $declaredOrder: order_by = null, $completedOrder: order_by = null, $commitedOrder: order_by = null, $averageSessionOrder: order_by = null, $interestFeedbackOrder: order_by = null, $viewMoreOrder: order_by = null, $watchlistOrder: order_by = null, $interestedClickOrder: order_by = null, $company_id: bigint = "") {
  Deal(
    order_by: {
  company: {name: $titleOrder}, 
  title: $titleOrder, 
  status: $statusOrder, 
  investment_deadline: $investmentDeadlineOrder,
  view_more_counter_total_aggregate: 
  { 
    sum: { counter: $viewMoreOrder }, 
  },
  interested_click_total_aggregate: {
    sum: { counter: $interestedClickOrder },
  },
  investment_completed_counter_aggregate: {
    sum: { counter: $completedOrder },
  },
  interest_feedback_counter_aggregate: {
    sum: { counter: $interestFeedbackOrder },
  },
  view_average_session_aggregate: {
    sum: { average: $averageSessionOrder },
  },
  investment_declared_aggregate: {
    sum: { value: $declaredOrder },
  },
  committed_aggregate:{sum:{value:$commitedOrder}},
  investment_amount_available: $investmentAmountAvailableOrder, 
  message_counter_aggregate: {
    sum: { counter: $messageOrder },
  },
  watchlist_counter_aggregate: { sum: { counter: $watchlistOrder } },
}) {
    company {
      name
    }
    title
    status
    investment_deadline
    views_total: view_more_counter_total_aggregate(where: {_and: [{time: {_gte: $time1}}, {time: {_lte: $time2}}]}) {
      aggregate {
        sum {
          counter
        }
      }
    }
    views_unique: Analytics_aggregate(where: {_and: {event: {_eq: DEAL_VIEW_MORE_CLICK}, _or: [{created_at: {_gte: $time3}}, {created_at: {_lte: $time4}}]}}) {
      aggregate {
        count(columns: firebase_id, distinct: true)
      }
    }
    interested: interested_click_total_aggregate(where: {_and: [{time: {_gte: $time1}}, {time: {_lte: $time2}}]}) {
      aggregate {
        sum {
          counter
        }
      }
    }
    interested_unique: Analytics_aggregate(where: {_and: {event: {_eq: DEAL_INTEREST_CLICK}, _or: [{created_at: {_gte: $time3}}, {created_at: {_lte: $time4}}]}}) {
      aggregate {
        count(columns: firebase_id, distinct: true)
      }
    }
    completed: investment_completed_counter_aggregate(where: {_and: [{time: {_gte: $time1}}, {time: {_lte: $time2}}]}) {
      aggregate {
        sum {
          counter
        }
      }
    }
    not_interested: interest_feedback_counter_aggregate(where: {_and: [{time: {_gte: $time1}}, {time: {_lte: $time2}}]}) {
      aggregate {
        sum {
          counter
        }
      }
    }
    avge_session_time: view_average_session_aggregate(where: {_and: [{time: {_gte: $time1}}, {time: {_lte: $time2}}]}) {
      aggregate {
        avg {
          average
        }
      }
    }
    declared: investment_declared_aggregate(where: {_and: [{time: {_gte: $time1}}, {time: {_lte: $time2}}]}) {
      aggregate {
        sum {
          value
        }
      }
    }
    committed: committed_aggregate(where: {_and: [{time: {_gte: $time1}}, {time: {_lte: $time2}}]}) {
      aggregate {
        sum {
          value
        }
      }
    }
    available: investment_amount_available
    messages: message_counter_aggregate(where: {_and: [{time: {_gte: $time1}}, {time: {_lte: $time2}}]}) {
      aggregate {
        sum {
          counter
        }
      }
    }
    watchlist: watchlist_counter_aggregate(where: {_and: [{time: {_gte: $time1}}, {time: {_lte: $time2}}]}) {
      aggregate {
        sum {
          counter
        }
      }
    }
  }
}
`;

export const GET_COMPANY_ANALYTICS_GENERAL_INFO = gql`
query MyQuery($nameOrder: order_by = null, $averageSessionOrder: order_by = null, $interestFeedbackOrder: order_by = null, $viewMoreOrder: order_by = null, $watchlistOrder: order_by = null, $availableAmmountOrder: order_by = null, $interestedClickOrder: order_by = null, $investmentCounterOrder: order_by = null) {
  Company(
    order_by: {name: $nameOrder, interested_click_aggregate: {sum: {counter: $interestedClickOrder}}, investment_counter_aggregate: {sum: {counter: $investmentCounterOrder}}, available_amount_aggregate: {sum: {amount: $availableAmmountOrder }}, watchlist_counter_aggregate: {sum: {counter: $watchlistOrder}}, deal_view_more_counter_aggregate: {sum: {counter: $viewMoreOrder}}, interest_feedback_counter_aggregate: {sum: {counter: $interestFeedbackOrder}}, deal_view_average_session_aggregate: {sum: {average: $averageSessionOrder}}}
  ) {
    id
    name
    interested_click {
      counter
    }
    investment_counter {
      counter
    }
    available_amount {
      amount
    }
    watchlist_counter {
      counter
    }
    deal_view_more_counter {
      counter
    }
    interest_feedback_counter {
      counter
    }
    deal_view_average_session {
      average
    }
  }
}

`;

export const GET_TIMED_ANALYTICS = gql`
  query MyQuery($time: timestamp = "") {
    Company {
      id
      name
      committed(where: { time: { _gte: $time } }) {
        time
        value
      }
      interested_click_total(where: { time: { _gte: $time } }) {
        counter
        time
      }
      deal_view_average_session(where: { time: { _gte: $time } }) {
        average
        time
      }
      deal_view_more_counter_total(where: { time: { _gte: $time } }) {
        counter
        time
      }
      deal_view_more_counter_unique(where: { time: { _gte: $time } }) {
        counter
        time
      }
      interested_click_unique(where: { time: { _gte: $time } }) {
        counter
        time
      }
      investment_completed_counter(where: { time: { _gte: $time } }) {
        counter
        time
      }
      investment_counter {
        counter
      }
      investment_declared(where: { time: { _gte: $time } }) {
        time
        value
      }
      message_counter(where: { time: { _gte: $time } }) {
        counter
        time
      }
    }
  }
`;