import React, { useEffect, useState } from 'react';
import { generalStyleClasses } from '../../utils/general-styles';
import MultipleSelect from '../MultipleSelect/MultipleSelectField';
import { useQuery } from '@apollo/client'
import { COUNTRIES } from '../../utils/enum-queries';
import { useSelector } from 'react-redux';
const CountryOfCompany = ({ handleChange, rootClasses={}, overwriteDefaultValues }) => {
  const classes = generalStyleClasses();
  const countriesData = useQuery(COUNTRIES).data;
  const storeValues = useSelector(store => store.investorProfile.countries);
  const [defaultValues] = useState(overwriteDefaultValues || storeValues);

  useEffect(() => {
    handleChange(defaultValues);
  }, [defaultValues]);


  return (
    <div className={rootClasses}>
      <span className={[classes.fontSize14, classes.fontInputColor].join(' ')}>Country of incorporation</span>
      <div className={classes.marginTop16} style={{ width: '300px' }}>
        <MultipleSelect optionsInput={countriesData ? countriesData.ENUM_countries : []} defaultValues={defaultValues} label="e.g. United States, South Korea..." handleChange={handleChange}/>
      </div>
    </div>
  )
}

export default CountryOfCompany;