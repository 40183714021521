import React from 'react'
import Button from '@material-ui/core/Button';
import './navbar.scss';

const LandingPageNavbar = () => {
    return (
        <div className="navbar">
             <img src={`${process.env.PUBLIC_URL}/images/logos/${localStorage.getItem("companyLogo")}`} alt="company logo" />
            <Button href="/" color="primary" style={{textTransform: 'none'}}>About us</Button>
        </div>
    )
}

export default LandingPageNavbar;