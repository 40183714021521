import { makeStyles } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { generalStyleClasses } from '../../../../../utils/general-styles';
import { handleDownloadPreviewFile, handleDownloadSavedFile } from '../../../../../utils/file-queries';

const styles = makeStyles({
  documentContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #EBEBEB",
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "12px 8px 12px 12px",
    marginRight: "8px",
    marginBottom: "8px"
  }
})

const DocumentSection = ({ title, files }) => {
  const generalClasses = generalStyleClasses();
  const classes = styles();
  const [documents, setDocuments] = useState([]);
  
  useEffect(() => {
    if(files.length > 0) {
      setDocuments(files.map(x => ({ 
        name: x.name, 
        action: x instanceof File 
        ? () =>  handleDownloadPreviewFile(x) 
        : () => handleDownloadSavedFile(`deal/section/documents/${x.id}`, x.name)
      })
        ))
    }
  }, [files]);

  return (
    <Fragment>
      <p className={[generalClasses.marginTop64, generalClasses.fontSize18, generalClasses.fontColorDark].join(' ')}>{title}</p>
      <div className={generalClasses.flexWrap} style={{width: "calc(100% + 8px)"}}>
          {
            documents.map((x, index) => (
              <button key={index} onClick={() => x.action()} className={classes.documentContainer}>
                <img src={`${process.env.PUBLIC_URL}/images/document-icon.svg`} alt="document image" />
                <span className={[generalClasses.fontSize13, generalClasses.fontColorLight].join(' ')} style={{marginLeft: "12px"}}>{x.name}</span>
              </button>
            ))
          }
      </div>
    </Fragment>
  )
}

export default DocumentSection;