import { sortActions } from "../utils/action-types";

const initialState = {
  mostRecent: false,
  endingSoon: false,
  name: false,
  highestValuation: false,
  highestOffering: false,
}

const opportunitiesSortState = (state = initialState, action) => {
  switch (action.type) {
    case sortActions.SET_ENDING_SOON_FILTER:
      return {
        ...state,
        endingSoon: action.payload
      }
    case sortActions.SET_MOST_RECENT_FILTER:
      return {
        ...state,
        mostRecent: action.payload
      }
    case sortActions.SET_NAME_FILTER:
      return {
        ...state,
        name: action.payload
      }
    case sortActions.SET_HIGHEST_VALUATION_FILTER:
      return {
        ...state,
        highestValuation: action.payload
      }
    case sortActions.SET_HIGHEST_OFFERING_FILTER:
      return {
        ...state,
        highestOffering: action.payload
      }
    default:
      return state;
  }
}

export default opportunitiesSortState;