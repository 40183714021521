import { deleteNulls } from "../admin/ClientsPage/utils";
import { hasuraErrorHandler } from "../shared/EventEmitter/EventEmitter";
import store from "../store/store";

async function get(url = "") {
  // Default options are marked with *
  return await fetch(url, {
    method: "GET",
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().account.jwtToken}`,
    },
  })
  .then(response =>{
    if(response.status !== 200) {
      throw response;
    }
    return response.blob()
  })
  .catch((error) => {
    console.error("Error:", error);
    throw error;
  });
}

const completeHandler = (event) => {
  console.log("completed");
};
const errorHandler = (event) => {
  console.log("error");
};
const abortHandler = (event) => {
  console.log("aborted");
};

const fileUploader = (
  route,
  files = [],
  {
    dealId,
    dealDocumentSectionId,
    dealImageSectionId,
    investmentSectionNodeEdgeId,
    investmentId,
    investmentStep,
    firebaseId,
    onboardingSectionNodeEdgeId,
    companyId,
  },
  onSuccess,
  onError,
  onAbort
) => {
  if (!Array.isArray(files)) {
    files = [files];
  }
  // console.log(
  //   "fileUploader",
  //   dealId,
  //   dealDocumentSectionId,
  //   dealImageSectionId,
  //   investmentSectionNodeEdgeId,
  //   investmentId,
  //   investmentStep,
  //   companyId
  // );
  const futureRequest = new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("dealId", dealId);
    data.append("dealDocumentSectionId", dealDocumentSectionId);
    data.append("dealImageSectionId", dealImageSectionId);
    data.append("investmentSectionNodeEdgeId", investmentSectionNodeEdgeId);
    data.append("investmentId", investmentId);
    data.append("investmentStep", investmentStep);
    data.append("firebaseId", firebaseId);
    data.append("onboardingSectionNodeEdgeId", onboardingSectionNodeEdgeId);
    data.append("companyId", companyId);
    files.forEach(file => data.append("file", file));
    var ajax = new XMLHttpRequest();

    ajax.addEventListener("load", () => {
      if (ajax.readyState === 4 && ajax.status === 200) {
        (onSuccess || completeHandler)()
        resolve(ajax.responseText);
      } else {
        reject(ajax.responseText);
      }
    }, false);
    ajax.addEventListener("error", onError || errorHandler, false);
    ajax.addEventListener("abort", onAbort || abortHandler, false);
    ajax.open(
      "POST",
      `${process.env.REACT_APP_API_ENDPOINT}/api/upload/${route}`
    );
    ajax.setRequestHeader(
      "Authorization",
      `Bearer ${store.getState().account.jwtToken}`
    );
    ajax.send(deleteNulls(data));
  });
  return futureRequest;
};

export const fileDownloader = async (route, filename) => {
  if (!filename) {
    return await get(
      `${process.env.REACT_APP_API_ENDPOINT}/api/download/${route}`
    );
  } else {
    const document = await get(
      `${process.env.REACT_APP_API_ENDPOINT}/api/download/${route}`
    );
    var csvURL = URL.createObjectURL(document);
    const tempLink = window.document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", filename);
    tempLink.click();
    return true;
  }
};

export const fileDeleter = (route, fileId) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/delete/${route}`,  {
    method: "POST",
    body: JSON.stringify({ fileId }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const handleDownloadPreviewFile = (file) => {
  var csvURL = URL.createObjectURL(file);
  const tempLink = window.document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", file.name);
  tempLink.click();
}

export const handleDownloadSavedFile = (url, name) => {
  fileDownloader(url, name)
  .catch(() => hasuraErrorHandler.printError("Document download failed"))
}
export const addCompanyLogo = (companyLogo, companyId, onSuccess = null, onError = null, onAbort = null) => {
  return fileUploader("company/logo", companyLogo, { companyId }, onSuccess, onError, onAbort);
};

export const addImageForSection = (sectionImage, dealId, dealImageSectionId, onSuccess = null, onError = null, onAbort = null) => {
  return fileUploader("deal/section/image", sectionImage, { dealId, dealImageSectionId }, onSuccess, onError, onAbort);
};

export const addDocumentsForSection = (sectionDocuments, dealId, dealDocumentSectionId, onSuccess = null, onError = null, onAbort = null) => {
  return fileUploader("deal/section/documents", sectionDocuments, { dealId, dealDocumentSectionId }, onSuccess, onError, onAbort);
};

export const addDocumentsForFlowEdge = (documents, investmentId, investmentSectionNodeEdgeId, dealId=null, onSuccess = null, onError = null, onAbort = null) => {
  return fileUploader("investment/documents", documents, {investmentId, investmentSectionNodeEdgeId, dealId}, onSuccess, onError, onAbort);
};


export const addDocumentsForOnboardingFlowEdge = (documents, firebaseId, onboardingSectionNodeEdgeId, onSuccess = null, onError = null, onAbort = null) => {
  return fileUploader("onboarding/documents", documents, {firebaseId, onboardingSectionNodeEdgeId }, onSuccess, onError, onAbort);
};

export const deleteDocumentsForOnboardingFlowEdge = (documentId) => {
  return fileDeleter('onboarding/documents', documentId);  
};

export const deleteDocumentsForFlowEdge = (documentId) => {
  return fileDeleter('investment/documents', documentId);  
};