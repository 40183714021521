import { makeStyles } from '@material-ui/core/styles';

export const useOnboardingStyles = makeStyles({
    routerContainer: {
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
    },
    onboardingContainer: {
        position: 'absolute',
        width: '480px',
        marginTop: '64px',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        padding: '40px',
        background: 'white',
        borderRadius: '4px',
        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.08)',
    }
})

