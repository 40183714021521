import React, { useState } from 'react';
import ConfidentialityAgreementText from './ConfidentialityAgreementText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { generalStyleClasses } from '../../utils/general-styles';
import { useMutation } from '@apollo/client';
import { ACCEPT_CONFIDENTIALITY_AGREEMENT } from "../../utils/account-queries";
import { SET_USER_ACCOUNT } from '../../store/utils/action-types';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const styleClasses = makeStyles({
    container: {
        width: '720px',
        padding: '40px',
        background: '#FFFFFF',
        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.08)',
        borderRadius: '4px'
    },
    bottomContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
})

const ConfidentialityAgreement = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(store => store.account.user || '');
    const appContentAccessPermission = useSelector(store => store.permissionAsignments.app_content_access)
    const [ acceptConfidentialityTerms ] = useMutation(ACCEPT_CONFIDENTIALITY_AGREEMENT);
    const [ checked, setChecked ] = useState(false);
    const classes = styleClasses();
    const generalClasses = generalStyleClasses();

    useEffect(()=> {
        if(!appContentAccessPermission) {
            history.push('/');
        }
    }, []);
    
    const handleContinueButton = () => {
        acceptConfidentialityTerms({variables: { userId: user.firebase_id }}).then(response => {
            if(response && response.data.update_Account_by_pk && response.data.update_Account_by_pk.accepted_confidentiality) {
                dispatch({type: SET_USER_ACCOUNT, payload: response.data.update_Account_by_pk});
                // history.push('/investor-preferences/industry');
                // history.push('/onboarding');
            }
        });
    }
    return (
        <div className={generalClasses.flexAlignCenterOnColumn}>
            <p className={[generalClasses.fontAlignCenter, generalClasses.fontSize20, generalClasses.fontColorDark, generalClasses.marginTop32, generalClasses.marginBottom0].join(' ')}>Confidentiality agreement</p>
            <div className={[classes.container, generalClasses.marginTop32].join(' ')}>
                {ConfidentialityAgreementText.map((paragraph, index) =>
                    <p key={index} className={[generalClasses.marginTop0, generalClasses.fontSize14, generalClasses.fontInputColor].join(' ')}>{paragraph}</p>
                )}
                <div className={classes.bottomContainer}>
                    <FormControlLabel
                        className={generalClasses.fontColorLight}
                        control={<Checkbox color="primary" checked={checked} onChange={e => setChecked(e.target.checked)} />}
                        label="I have read the terms of confidentiality agreement"
                    />
                    <Button  variant="contained" color="primary" disabled={!checked} onClick={handleContinueButton.bind(this)}>
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ConfidentialityAgreement;