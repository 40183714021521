import React from 'react';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { generalStyleClasses } from "../../utils/general-styles";
import { useOnboardingStyles } from '../OnboardingStyles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import { useDispatch } from 'react-redux';
import { LOGOUT, SET_USER_AUTH } from '../../store/utils/action-types';
import { permissionAsignmentsActions } from '../../store/slices/permissions';

const SignupSuccessfulFinal = () => {
    const classes = generalStyleClasses();
    const onboardingClasses = useOnboardingStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleBackButton = () => {
        firebase.auth().signOut().then(() => {
            dispatch({ type: SET_USER_AUTH, payload: { jwtToken: '' } });
            dispatch({ type: LOGOUT });
            dispatch(permissionAsignmentsActions.RESET_PERMISSIONS());
            history.push('/');
        })
    }
    return (
        <div className={onboardingClasses.routerContainer} >
            <div className={onboardingClasses.onboardingContainer}>
                <div className={classes.flexAlignCenterOnColumn}>
                    <MailOutlineIcon style={{
                        color: 'darkgrey',
                        transform: 'scale3d(1.4, 1.2, 1)',
                    }} />
                    <p className={[classes.marginTop32, classes.fontAlignCenter, classes.fontSize16, classes.fontInputColor].join(' ')}>
                        Thank you for your interest. We have received your message and we will contact you back.
                        </p>
                    <Button variant="contained" color="primary" onClick={() => handleBackButton()} style={{ width: "100%" }}>Back</Button>
                </div>
            </div>
        </div>
    )
}

export default SignupSuccessfulFinal;