import { useQuery } from '@apollo/client';
import { Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import SmallButtonGroup from '../../../shared/SmallButtonsGroup/SmallButttonsGroup';
import { GET_ALL_INVESTMENTS } from '../../../utils/deal-queries';
import { generalStyleClasses } from '../../../utils/general-styles';
import { opportunitiesPageStyles } from '../MainPages/OpportunitiesPage/OpportunitiesPageStyle';
import TopsideMenu from './TopsideMenu/TopsideMenu';
import { GET_ONBOARDING_FLOW_EDGES_IDS } from "../../../utils/account-queries";
const buttonFilters = [
  { text: "All documents", value: 0 },
  { text: "Ongoning", value: 1 },
  { text: "Finalized", value: 2 },
];

const sectionStyles = makeStyles({
  container: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    padding: "24px",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "19px",
    lineHeight: "22px",
    color: "#505154",
    marginBottom: "8px",
    marginTop: "0px",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#796E6E",
    marginBottom: "0px",
    marginTop: "0px",
  }
})
const DocumentsSection = ({ title, subTitle, link }) => {
  const classes = sectionStyles();

  return (
    <div className={classes.container}>
      <div>
        <p className={classes.title}>{title}</p>
        <p className={classes.subtitle}>{subTitle}</p>
      </div>
      <Button variant="outlined" color="primary" component={Link} to={link}>Access documents</Button>
    </div>
  )
}

const DocumentsPage = () => {
  const generalClasses = generalStyleClasses();
  const classes = opportunitiesPageStyles();
  const [filterType, setFilterType] = useState(0);
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const {data: hasOnboardingData} = useQuery(GET_ONBOARDING_FLOW_EDGES_IDS , {variables: {firebase_id: firebaseId}})
  const { data } = useQuery(GET_ALL_INVESTMENTS, { variables: { firebase_id: firebaseId }, fetchPolicy: "network-only" });

  return (
    <div className={[generalClasses.fullViewWidth, generalClasses.flexAlignCenterOnColumn].join(' ')}>
      <div className={[classes.topsideMenu, generalClasses.flexAlignCenter].join(' ')}>
        <TopsideMenu />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.filters}>
          <p className={[generalClasses.marginTop8, generalClasses.marginBottom0, generalClasses.fontSize13, generalClasses.fontColorLight].join(' ')}>View</p>
          <SmallButtonGroup buttonList={buttonFilters} handleChange={elem => setFilterType(elem.value)} buttonStyle={{ width: "100%" }} />
        </div>
        <div className={classes.content}>
          {
            filterType !== 1 && hasOnboardingData && hasOnboardingData.Onboarding_flow_section_edge.length > 0 && <DocumentsSection link="" subTitle="Descriptions of what documents are here..." title="Onboarding documents" />
          }
          {
            data && data.Account_by_pk && data.Account_by_pk.Investments.filter(investment => {
              if(filterType === 0) return true;
              if(filterType === 1) return !investment.completed;
              return investment.completed !== 0;
            }).map((x, index) =>
              <DocumentsSection key={index}
                link= {{pathname: `/app/document-handler/${x.deal_id}/${x.id}`, state: { lastPage: "/app/documents" }}}
                subTitle={`${x.completed ? 'Finalized:' : 'Last update:'}  ${new Date(x.updated_at).toDateString()}`}
                title={`${x.deal.company.name} ${x.deal.title ? '-' : ''} ${x.deal.title}`} />)
          }

        </div>
      </div>
    </div>

  )
}

export default DocumentsPage;