import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import { generalStyleClasses } from '../../utils/general-styles';
import InterestedSectors from '../../shared/InvestorPreferancesInputs/InterestedSectors';
import InovationEnablers from '../../shared/InvestorPreferancesInputs/InovationEnablers';
import { useMutation } from '@apollo/client';
import { UPDATE_INVESTOR_PROFILE_INDUSTRY } from '../../utils/investor-preferances-queries';
import { useDispatch, useSelector } from 'react-redux';
import { EventThrower, Events } from '../../shared/EventEmitter/EventEmitter';
import LessInterestedSectors from '../../shared/InvestorPreferancesInputs/LessInterestedSectors';
import LessInterestedInovationEnablers from '../../shared/InvestorPreferancesInputs/LessInterestedInovationEnablers';
import AdditionalInformations from '../../shared/InvestorPreferancesInputs/AdditionalInformation';
import { SET_INVESTOR_PROFILE_INDUSTRY } from '../../store/utils/action-types';

const Industry = ({ nextStep, handleSkip }) => {
    const classes = generalStyleClasses();
    const history = useHistory();
    const [additionalInformations, setAdditionalInformations] = useState("");
    const [interestedSectors, setInterestedSectors] = useState([]);
    const [inovationEnablers, setInovationEnablers] = useState([]);
    const [lessInterestedSectors, setLessInterestedSectors] = useState([]);
    const [lessInovationEnablers, setLessInovationEnablers] = useState([]);
    const [updateIndustryPreferences] = useMutation(UPDATE_INVESTOR_PROFILE_INDUSTRY);
    const account = useSelector(store => store.account.user);
    const investorProfileId = useSelector(store => store.account.investorProfileId || store.account.user?.investor_profile && store.account.user?.investor_profile[0]?.id);
    const dispatch = useDispatch();
    const handleSaveChanges = () => {
        const industryPreferencesData = {
            firebase_id: account.firebase_id,
            sectors: interestedSectors.map(x => ({ sector: x.value, profile_id: investorProfileId })),
            enablers: inovationEnablers.map(x => ({ enabler: x.value, profile_id: investorProfileId })),
            industry_additional_information: additionalInformations,
            sectors_less: lessInterestedSectors.map(x => ({ sector: x.value, profile_id: investorProfileId })),
            enablers_less: lessInovationEnablers.map(x => ({ enabler: x.value, profile_id: investorProfileId })),
        }
        updateIndustryPreferences({ variables: industryPreferencesData })
            .then(() => {
                dispatch({
                    type: SET_INVESTOR_PROFILE_INDUSTRY, payload: {
                        innovationEnablers: inovationEnablers,
                        interestedSectors: interestedSectors,
                        innovationEnablersLess: lessInovationEnablers,
                        interestedSectorsLess: lessInterestedSectors,
                    }
                })
                nextStep();
                history.push("/investor-preferences/company");
            })
            .catch((error) => {
                console.log(error);
                EventThrower.throwClientSideError(Events.InformationChangedError)
            } );
    }

    return (
        <Fragment>
            <InterestedSectors handleChange={value => setInterestedSectors(value)} fullWidth={true} />
            <InovationEnablers handleChange={value => setInovationEnablers(value)} rootClasses={classes.marginTop32} fullWidth={true} />
            <LessInterestedSectors handleChange={value => setLessInterestedSectors(value)} rootClasses={classes.marginTop32} fullWidth={true} />
            <LessInterestedInovationEnablers handleChange={value => setLessInovationEnablers(value)} rootClasses={classes.marginTop32} fullWidth={true} />
            <AdditionalInformations handleChange={setAdditionalInformations} rootClasses={classes.marginTop32} />
            <div className={[classes.dualLinkButtonsGroup, classes.marginTop32].join(' ')}>
                <Button variant="outlined" color="primary" onClick={() => handleSkip()}> Skip </Button>
                <Button variant="contained" color="primary" onClick={handleSaveChanges} > Continue </Button>
            </div>
        </Fragment >
    )
}

export default Industry;