import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { generalStyleClasses } from "../../../../utils/general-styles";
import ChatContainer from '../../Messages/ChatContainer';
import { detailedOpportunityPageStyles } from './DetailedOpportunityPageStyle';
import LeftSideMenu from './LeftsideMenu/LeftsideMenu';
import OpportunityContainer from './OpportunityContainer/OpportunityContainer';
import TopsideMenu from './TopsideMenu/TopsideMenu';


const overlapStyles = makeStyles({
  header:
  {
    position: "fixed",
    top: "56px",
    zIndex: "100"
  },
  bodyOverlap: {
    marginTop: "64px",
  },
  messageContainer: {
    position: "fixed",
    right: "20px",
    bottom: "20px",
    width: "360px",
    height: "400px",
  }
})

const DetailedOpportunityPage = () => {
  const classes = detailedOpportunityPageStyles();
  const overlapClasses = overlapStyles();
  const generalStyles = generalStyleClasses();
  const params = useRouteMatch().params;
  const [overlap, setOverlap] = useState(false);
  const [showChat, setShowChat] = useState(false);
  useEffect(() => {
    function updatePosition() {
      setOverlap(window.scrollY > 0)
    }
    window.addEventListener('scroll', updatePosition);
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return (
    <div>
      <div className={[generalStyles.fullViewWidth, generalStyles.flexAlignCenterOnColumn].join(' ')}>
        <div className={[classes.topsideMenu, generalStyles.flexAlignCenter, overlap ? overlapClasses.header : ""].join(' ')}>
          <TopsideMenu />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.filters}>
            <LeftSideMenu handleShowMenu={() => setShowChat(true)}/>
          </div>
          <div className={[classes.content, overlap ? overlapClasses.bodyOverlap : ""].join(' ')}>
            <OpportunityContainer oportunityId={params.id} />
          </div>
        </div>
      </div>
      {
        showChat && (
          <div className={overlapClasses.messageContainer}>
            <ChatContainer isMinimal handleClose={() => setShowChat(false)} dealId={params.id}/>
          </div>
        )
      }
    </div>
  );
}

export default DetailedOpportunityPage