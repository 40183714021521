import { Button, FormControl, IconButton, MenuItem, Select } from '@material-ui/core';
import { Autocomplete, createFilterOptions, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { Fragment, useState } from 'react';
import TextInput from '../../../shared/TextInput/TextInput';
import { adminAlignmentStyles, adminDimensionsStyle, adminInputStyles, adminTypografyStyles } from '../../AdminStyles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useEffect } from 'react';
import { curencies } from '../../../utils/curencies';
import { useQuery } from '@apollo/client';
import { GET_ALL_NOTABLE_INVESTORS } from '../../queries/admin-queries';
import { numberWithCommas } from '../../../utils/formats';

const FundingCard = ({ funding, handleDelete, handleEdit }) => {
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const typografyClasses = adminTypografyStyles();
  return (
    <div className={alignmentClasses.flexWrap} style={{ justifyContent: "space-between", marginBottom: "8px" }}>
      <div className={dimensionClasses.width150}>
        <span className={typografyClasses.fontSize14} style={{ color: "#A0A0A0" }}>Stage </span>
        <span className={[typografyClasses.fontSize14, typografyClasses.fontColor373737].join(' ')}>{funding.stage}</span>
      </div>
      <div className={dimensionClasses.width150}>
        <span className={typografyClasses.fontSize14} style={{ color: "#A0A0A0" }}>Date </span>
        <span className={[typografyClasses.fontSize14, typografyClasses.fontColor373737].join(' ')}>{new Date(funding.date).toLocaleDateString()}</span>
      </div>
      <div className={dimensionClasses.width200}>
        <span className={typografyClasses.fontSize14} style={{ color: "#A0A0A0" }}>Valuation </span>
        <span className={[typografyClasses.fontSize14, typografyClasses.fontColor373737].join(' ')}>{`${funding.valuation && funding.currency || ''} ${numberWithCommas(funding.valuation) || ''}`}</span>
      </div>
      <div className={dimensionClasses.width200}>
        <span className={typografyClasses.fontSize14} style={{ color: "#A0A0A0" }}>Raised </span>
        <span className={[typografyClasses.fontSize14, typografyClasses.fontColor373737].join(' ')}>{`${funding.amountRaised && funding.currency || ''} ${numberWithCommas(funding.amountRaised) || ''}`}</span>
      </div>
      <div className={[dimensionClasses.width200, alignmentClasses.flex].join(' ')}>
        <span className={typografyClasses.fontSize14} style={{ color: "#A0A0A0" }}>Investors </span>
        <div style={{display: "flex", flexDirection: "column", marginLeft: "4px"}}>
        {funding.investors.map(x => <span className={[typografyClasses.fontSize14, typografyClasses.fontColor373737].join(' ')}>{x.name}</span>)}
        </div>
        
      </div>
      <IconButton onClick={() => handleEdit(funding)} className={dimensionClasses.padding0}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleDelete} className={dimensionClasses.padding0}>
        <DeleteIcon />
      </IconButton>
    </div>)
}

const AddFundingHistory = ({ initialValue = [], financingRounds = [], handleNewFundingRound }) => {
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const typografyClasses = adminTypografyStyles();
  const inputClasses = adminInputStyles();
  const [fundingHistory, setFundingHistory] = useState([]);

  const [financingRound, setFinancingRound] = useState("");
  const [currency, setCurrency] = useState("");
  const [date, setDate] = useState("");
  const [preValuation, setPreValuation] = useState(null);
  const [amountRaised, setAmountRaised] = useState(null);
  const [isEdit, setIsEdit] = useState(-1);
  const [isSummary, setIsSummary] = useState(true);
  const [investorName, setInvestorName] = useState("");
  const [investors, setInvestors] = useState([]);
  const [notableInvestors, setNotableInvestors] = useState([]);
  const { data: notableInvestorsData } = useQuery(GET_ALL_NOTABLE_INVESTORS, { fetchPolicy: 'network-only' })

  const addFunding = () => {
    if (financingRound && date && notableInvestors) {
      if (isEdit !== -1) {
        setFundingHistory(fundingHistory.map((funding, index) => {
          if (index === isEdit) {
            return {
              stage: financingRound,
              currency: currency,
              date: date,
              valuation: preValuation,
              amountRaised: amountRaised,
              investors: notableInvestors
            }
          }
          return funding;
        }))
      } else {
        setFundingHistory([...fundingHistory, {
          stage: financingRound,
          currency: currency,
          date: date,
          valuation: preValuation,
          amountRaised: amountRaised,
          investors: notableInvestors
        }])
      }
      setFinancingRound("");
      setCurrency("");
      setDate("");
      setPreValuation(undefined);
      setAmountRaised(undefined);
      setInvestorName("");
      setNotableInvestors([]);
      setIsSummary(false);
      setIsEdit(-1);
    }
  }

  const deleteFunding = (position) => {
    setFundingHistory(fundingHistory.filter((x, index) => index !== position));
  }

  const editFunding = (data, index) => {
    setFinancingRound(data.stage);
    setCurrency(data.currency);
    setDate(data.date);
    setPreValuation(data.valuation);
    setAmountRaised(data.amountRaised);
    setNotableInvestors(data.investors);
    setIsEdit(index);
  }

  const handleAddInvestor = () => {
    if (notableInvestors.filter(x => x.name === investorName.value).length === 0) {
      setNotableInvestors([...notableInvestors, { name: investorName.value, isSummary: isSummary }]);
      setInvestorName("");
    }
  }

  useEffect(() => {
    if (handleNewFundingRound) {
      handleNewFundingRound(fundingHistory);
    }
  }, [fundingHistory])

  useEffect(() => {
    if (initialValue && initialValue.length > 0)
      setFundingHistory(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (notableInvestorsData) {
      setInvestors(notableInvestorsData.ENUM_notable_investors.map(x => ({ title: x.value, value: x.value })))
    }
  }, [notableInvestorsData])

  return (
    <Fragment>
      <div className={[alignmentClasses.flexWrap, alignmentClasses.flexAlignCenter, dimensionClasses.marginTop24].join(' ')}>
        <span className={[typografyClasses.fontSize14, typografyClasses.fontbold].join(' ')} style={{ color: "#929292" }}>Funding History</span>
        <p className={[dimensionClasses.widthGrow, dimensionClasses.marginLeft16, dimensionClasses.marginTop0, dimensionClasses.marginBottom0].join(' ')} style={{ border: "1px solid #EBEBEB" }} ></p>
      </div>

      <div className={[dimensionClasses.marginTop24, dimensionClasses.width100Percent, alignmentClasses.flexCenterSpaceBetween].join(' ')} style={{ alignItems: "flex-start" }}>
        <div>
          <p className={[typografyClasses.fontSize14, dimensionClasses.marginTop0, dimensionClasses.marginBottom8].join(' ')} style={{ color: "#796E6E" }}>Financing Round*</p>
          <Select
            variant="outlined"
            className={[dimensionClasses.mediumHeight, inputClasses.singleSelect].join(' ')}
            value={financingRound}
            onChange={e => setFinancingRound(e.target.value)}
            style={{ minWidth: "180px" }}
            MenuProps={{ variant: "menu", style: { marginTop: "44px" }, disableScrollLock: true }}>
            {financingRounds.map((x, index) => (<MenuItem key={index} value={x.value}>{x.description}</MenuItem>))}
          </Select>
        </div>
        <div style={{ marginLeft: "12px" }}>
          <p className={[typografyClasses.fontSize14, dimensionClasses.marginBottom8, dimensionClasses.marginTop0].join(' ')} style={{ color: "#796E6E" }}>Currency</p>
          <FormControl variant="outlined" >
            <Select
              className={[dimensionClasses.mediumHeight, inputClasses.singleSelect].join(' ')}
              style={{ width: "160px" }}
              color="primary"
              value={currency}
              onChange={e => setCurrency(e.target.value)}
              MenuProps={{ variant: "menu", disableScrollLock: true }}>
              {curencies.map((x, index) => <MenuItem key={index} value={x}>{x}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        <TextInput className="small36-input-field" label="Date of Financing Round*" isDate
          handleChange={e => setDate(new Date(e).toISOString().split('T')[0])} initialValue={date} customStyle={{ width: "160px", marginLeft: "12px" }} />
        <TextInput className="small36-input-field" label="Pre-money Valuation" isNumber isMoneySum
          handleChange={setPreValuation} initialValue={preValuation} customStyle={{ width: "200px", marginLeft: "12px" }} />
        <TextInput className="small36-input-field" label="Amount Raised" isNumber isMoneySum
          handleChange={setAmountRaised} initialValue={amountRaised} customStyle={{ width: "200px", marginLeft: "12px" }} />
      </div>
      <div className={[dimensionClasses.marginTop24, dimensionClasses.width100Percent, alignmentClasses.flexWrap].join(' ')} style={{ alignItems: "flex-end" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p className={[dimensionClasses.marginTop0, dimensionClasses.marginBottom8, typografyClasses.fontSize14].join(' ')} style={{ color: "rgb(121, 110, 110)" }}>Notable Investor*</p>
          <Autocomplete
            value={investorName}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setInvestorName({ title: newValue, value: newValue })
              } else if (newValue && newValue.inputValue) {
                setInvestorName({ title: newValue.inputValue, value: newValue.inputValue });
              } else {
                setInvestorName(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = createFilterOptions()(options, params);
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }
              return filtered.filter(x => x !== investorName);
            }}
            options={investors}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.title;
            }}
            renderOption={(option) => option.title}
            style={{ width: 305 }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input type="text" {...params.inputProps} style={{ background: "#FFFFFF", width: "305px", border: "1px solid #D8CECE", boxSizing: "border-box", borderRadius: "4px", color: "#505154", height: "36px" }} />
              </div>
            )}
          />
        </div>
        <ToggleButtonGroup
          color="primary"
          value={isSummary}
          exclusive
          onChange={(event, newValue) => (newValue === true || newValue === false) && setIsSummary(newValue)}
          style={{ marginLeft: "16px" }}
          className={[dimensionClasses.mediumHeight, inputClasses.buttonGroup].join(' ')}>
          <ToggleButton value={true} color="primary">
            Everywhere
          </ToggleButton>
          <ToggleButton value={false} color="primary">
            Details
          </ToggleButton>
        </ToggleButtonGroup>
        <Button variant="contained" color="primary" className={[dimensionClasses.mediumHeight, dimensionClasses.marginLeft16].join(' ')} onClick={() => handleAddInvestor()}>Add Investor</Button>
      </div>
      <div>
        {notableInvestors.map(investor => (
          <div style={{display: "flex", width: "500px", justifyContent: "space-between"}}>
            <p style={{marginTop: "8px", marginBottom: "8px"}}>Investor name: <strong>{investor.name}</strong>
              {
               investor.isSummary && ", investor is summary" || ''
              }
            </p>
            <IconButton className={dimensionClasses.padding0} onClick={() => setNotableInvestors(notableInvestors.filter(x => x.name !== investor.name))}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>
      <div className={alignmentClasses.flex} style={{ justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" style={{ marginLeft: "16px" }} className={dimensionClasses.mediumHeight} onClick={() => addFunding()}>
          <AddIcon />
          {isEdit !== -1 ? 'Update' : 'Add'} funding
          </Button>
      </div>
      <div className={dimensionClasses.marginTop24}>
        {fundingHistory.map((x, index) => <FundingCard key={index} funding={x} handleDelete={() => deleteFunding(index)} handleEdit={value => editFunding(value, index)} />)}
      </div>
    </Fragment>
  )
}

export default AddFundingHistory;