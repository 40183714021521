import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { adminDimensionsStyle, adminAlignmentStyles, adminTypografyStyles } from '../../AdminStyles';
import { GET_ALL_COUNTRIES } from '../../queries/admin-queries';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextInput from '../../../shared/TextInput/TextInput';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const OpportunityPermissions = () => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const typografyClasses = adminTypografyStyles();
  const {data: countries} = useQuery(GET_ALL_COUNTRIES);
  const [value, setValue] = useState(0);
  const [nameFilter, setNameFilter] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={[dimensionClasses.headerMarginFix, dimensionClasses.containerWidth].join(' ')}>
      <div className={alignmentClasses.flexCenterSpaceBetween}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {
            countries && countries.ENUM_countries.filter(x => x.description.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1).map(x => <Tab label={x.description}  />)
          }
        </Tabs>
        <TextInput placeholder="country filter" handleChange={value => setNameFilter(value)}/>
      </div>
      <TabPanel value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel>
    </div>
  )
}

export default OpportunityPermissions;