import React from 'react';
import { generalStyleClasses } from '../../utils/general-styles';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const ErrorLabel = ({label, active}) => {
  const classes = generalStyleClasses();
  return (
    <div className={[classes.marginTop0, classes.flexCenterElements].join(' ')}>
      {
      active 
      ? <CloseIcon style={{fill: "#796E6E", width: "20px", height: "20px"}}/>
      : <CheckIcon style={{fill: "#76A06F", width: "20px", height: "20px"}}/>
      }
      <span className={[classes.fontSize12, classes.fontColorLight].join(' ')} style={{marginLeft: "8px"}}>{label}</span>
    </div>
  )
}

export default ErrorLabel;