import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const DarkCheckbox = withStyles({
  root: {
    color: '#796E6E',
    '&$checked': {
      color: "#434448 !important",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default DarkCheckbox;