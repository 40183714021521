import React, { Fragment, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useQuery } from "@apollo/client";
import { COUNTRIES } from '../../../utils/enum-queries';
import { MenuItem, Select } from '@material-ui/core';
import TextInput from '../../../shared/TextInput/TextInput';
import { adminAlignmentStyles, adminDimensionsStyle, adminInputStyles, adminTypografyStyles } from '../../AdminStyles';
import { useDispatch } from 'react-redux';
import { adminCreateAccountActions } from '../../../store/slices/rmCreatedAccount';

//TODO: fix phone number format
const PersonalInformationStep = () => {
    const dimensionClasses = adminDimensionsStyle();
    const typografyClasses = adminTypografyStyles();
    const alignmentClasses = adminAlignmentStyles();
    const inputClasses = adminInputStyles();
    const countriesData = useQuery(COUNTRIES).data;
    const dispatch = useDispatch();

    const setFirstName = (value) => {
        dispatch(adminCreateAccountActions.SET_FIRST_NAME(value));
    }
    const setLastName = (value) => {
        dispatch(adminCreateAccountActions.SET_LAST_NAME(value));
    }
    const setPhoneNumber = (value) => {
        dispatch(adminCreateAccountActions.SET_PHONE_NUMBER(value));
    }
    const setCountry = (value) => {
        dispatch(adminCreateAccountActions.SET_COUNTRY(value));
    }
    const setCity = (value) => {
        dispatch(adminCreateAccountActions.SET_CITY(value));
    }
    const setPhoneZone = (value) => {
        dispatch(adminCreateAccountActions.SET_PHONE_ZONE(value));
    }
    
    return (
        <Fragment>
            <h4 className={[dimensionClasses.marginTop12, dimensionClasses.marginBottom0, typografyClasses.fontColor505154, typografyClasses.fontSize16].join(' ')}>Personal information</h4>
            <div className={dimensionClasses.marginTop24}>
                <TextInput label="First Name" handleChange={value => setFirstName(value)}  />
            </div>
            <div className={dimensionClasses.marginTop24}>
                <TextInput label="Last Name" handleChange={value => setLastName(value)}  />
            </div>
            <div className={[dimensionClasses.marginTop24, alignmentClasses.flexWrap].join(' ')}>
                <p className={[dimensionClasses.marginBottom8, dimensionClasses.marginTop0, typografyClasses.fontColor505154, typografyClasses.fontSize14].join(' ')} style={{ width: "100%" }}>Phone Number</p>
                <Select
                    variant="outlined"
                    className={inputClasses.singleSelect}
                    style={{ background: "white", height: "36px", width: "60px", marginRight: "8px" }}
                    MenuProps={{ variant: "menu", disableScrollLock: true }}
                    onChange={e => setPhoneZone(e.target.value)}>
                    {countriesData && countriesData.ENUM_countries ? countriesData.ENUM_countries.map((x, index) => (<MenuItem key={index} value={x.value}>{x.value}</MenuItem>)) : []}
                </Select>
                <TextInput isNumber handleChange={value => setPhoneNumber(value)} customStyle={{ flexGrow: "1", marginTop: "-8px" }} />


            </div>
            <div className={dimensionClasses.marginTop24}>
                <p className={[dimensionClasses.marginBottom8, dimensionClasses.marginTop0, typografyClasses.fontColor505154, typografyClasses.fontSize14].join(' ')}>Country</p>
                <Select
                    variant="outlined"
                    className={inputClasses.singleSelect}
                    style={{ width: "100%", background: "white", height: "36px" }}
                    onChange={e => setCountry(e.target.value)}
                    MenuProps={{ variant: "menu", disableScrollLock: true }}>
                    {countriesData && countriesData.ENUM_countries ? countriesData.ENUM_countries.map((x, index) => (<MenuItem key={index} value={x.description}>{x.description}</MenuItem>)) : []}
                </Select>
            </div>
            <div className={dimensionClasses.marginTop24}>
                <TextInput label="City" handleChange={value => setCity(value)} />
            </div>
        </Fragment>
    )
}

export default PersonalInformationStep;