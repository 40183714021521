import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ButtonGroup from "../../shared/ButtonGroup/ButtonGroup";
import { generalStyleClasses } from '../../utils/general-styles';
import HelpDialog from './HelpDialog/HelpDialog';
import { SET_REGISTER_STEP_PERSONAL_INFOS, SET_USER_ACCOUNT } from "../../store/utils/action-types";
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ASSIGNED_ONBOARDING_OR_DEFAULT, SET_ACCOUNT_LAST_STEP_INFOS } from '../../utils/account-queries';
import { hasuraErrorHandler } from '../../shared/EventEmitter/EventEmitter';
import { cloneOnboardingFlowForInvestor } from '../../admin/queries/admin-http-calls';

const CreateAccount = ({isForgot = false}) => {
    const classes = generalStyleClasses();
    const history = useHistory();
    const dispatch = useDispatch();
    const [openHelp, setOpenHelp] = useState(false);
    const [isClient, setIsClient] = useState(-1);
    const [isAccreditedInvestor, setIsAccreditedInvestor] = useState(-1);
    const [valid, setValid] = useState(false);
    const firebaseId = useSelector(store => store.account.user?.firebase_id || store.registration.registrationUserId);
    const canAccessApp = useSelector(store => store.permissionAsignments.app_content_access);
    const [setInfos] = useMutation(SET_ACCOUNT_LAST_STEP_INFOS);
    const {data: assignedOrDefaultFlow} = useQuery(GET_ASSIGNED_ONBOARDING_OR_DEFAULT, {variables: {firebase_id: firebaseId}, fetchPolicy: "network-only"});
    const existingClientButtonOptions = [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" }
    ];
    const accreditedInvestorButtonOptions = [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
        { text: "Not sure", value: "Not sure" }
    ];

    const checkIfValid = (isClient, isAccreditedInvestor) => {
        setIsClient(isClient);
        setIsAccreditedInvestor(isAccreditedInvestor);
        if (isClient === -1 || isAccreditedInvestor === -1) {
            setValid(false);
            return;
        }
        setValid(true);
    }

    const handleBackButton = () => {
        dispatch({type: SET_REGISTER_STEP_PERSONAL_INFOS});
        history.goBack();
    }

    const handleContinueButton = () => {
        setInfos({variables: {firebase_id: firebaseId, app_client: isClient, accredited_investor: isAccreditedInvestor}})
        .then(async () => {
            if(isClient === "No") {
                if(!assignedOrDefaultFlow.Account[0]?.Onboarding_flow_section_edges[0]?.Flow_node.id) {
                    const cloneResult = await cloneOnboardingFlowForInvestor(assignedOrDefaultFlow.Onboarding_flow_section_edge[0]?.Flow_node.id, firebaseId);
                    if(cloneResult.status === 200) {
                        dispatch({type: SET_USER_ACCOUNT, payload: {accredited_investor: isAccreditedInvestor, app_client: isClient}});
                        history.push('/custom-onboarding')
                    } else {
                        hasuraErrorHandler.printError("No default onboarding flow exists")
                        history.push(isForgot ? (canAccessApp ? "investor-preferences" : "signup-successful") : "/onboarding/signup-successful")
                    }
                } else {
                    dispatch({type: SET_USER_ACCOUNT, payload: {accredited_investor: isAccreditedInvestor, app_client: isClient}});
                    history.push('/custom-onboarding')
                }
            } else {
                history.push(isForgot ? (canAccessApp ? "investor-preferences" : "signup-successful") : "/onboarding/signup-successful");
            }
        })
        .catch(() => hasuraErrorHandler.printError("data saving error") );
    }

    useEffect(() => {
        if(assignedOrDefaultFlow) {
            if(assignedOrDefaultFlow.Account[0].app_client) {
                setIsClient(assignedOrDefaultFlow.Account[0].app_client)
            } 
            if(assignedOrDefaultFlow.Account[0].accredited_investor) {
                setIsAccreditedInvestor(assignedOrDefaultFlow.Account[0].accredited_investor)
            } 
        }
    }, [assignedOrDefaultFlow])
 
    return (
        <Fragment>
            <p className={[classes.fontSize16, classes.fontColorDark].join(' ')}>Are you  a client of {localStorage.getItem("companyName")}?</p>
            <ButtonGroup buttonList={existingClientButtonOptions} defaultSelectedIndex={existingClientButtonOptions.findIndex(x => x.value === isClient)} handleChange={data => checkIfValid(data.value, isAccreditedInvestor)} />
            <p className={[classes.fontSize16, classes.fontColorDark, classes.paragraphWithQuestion].join(' ')}>
                Are you an Accredited Investor?
                <img src={process.env.PUBLIC_URL + "/images/question-circle.svg"} onClick={() => setOpenHelp(!openHelp)} />
            </p>
            <ButtonGroup buttonList={accreditedInvestorButtonOptions} defaultSelectedIndex={accreditedInvestorButtonOptions.findIndex(x => x.value === isAccreditedInvestor)} handleChange={data => checkIfValid(isClient, data.value)} />
            <div className={[classes.marginTop32, classes.dualLinkButtonsGroup].join(' ')}>
                <Button variant="outlined" color="primary" onClick={handleBackButton}> Back </Button>
                <Button variant="contained" color="primary" disabled={!valid && !assignedOrDefaultFlow} onClick={() => handleContinueButton()}> Continue </Button>
            </div>
            <HelpDialog open={openHelp} onClose={() => setOpenHelp(false)} />
        </Fragment>
    )
}

export default CreateAccount;