import { SET_DEAL_FILTERS } from "../utils/action-types";

const initialState = {
  paginationOffset: 0,
  paginationLimit: 50,
  sort: {},
  filters: {},
  dealFilterQuery: "where: {}"
}

const dealFiltersState = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEAL_FILTERS:
      return {
        dealFilterQuery: action.payload
      }
    
    default:
      return state;
  }
}

export default dealFiltersState;