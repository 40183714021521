import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { generalStyleClasses } from '../../../../../utils/general-styles';

const styles = makeStyles({
  container: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "140px",
  }
})
const NoInvestmentsPage = () => {
  const classes = styles(); 
  const generalClasses = generalStyleClasses();
  return (
    <div className={classes.container}>
      <img src={`${process.env.PUBLIC_URL}/images/investment-file.svg`} alt="company logo" />
      <p className={[generalClasses.marginTop40, generalClasses.fontSize16, generalClasses.fontInputColor, generalClasses.marginbottom40].join(' ')}>Go to Opportunities page to explore active and prospective deals</p>
      <Button variant="contained" color="primary" component={Link} to="/app/opportunities/all">Find Investment Oportunities</Button>
    </div>
  ) 
}

export default NoInvestmentsPage;