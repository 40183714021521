import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';

const ButtonGroup = ({buttonList, handleChange, defaultSelectedIndex = -1 }) => {
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(defaultSelectedIndex);
    const buttonIsSelected = (buttonIndex) => {
        return selectedButtonIndex === buttonIndex;
    }
    const selectButton = (button, index) => {
        setSelectedButtonIndex(index);
        handleChange(button);
    }

    useEffect(() => {
        setSelectedButtonIndex(defaultSelectedIndex);
    }, [defaultSelectedIndex]);
    
    const buttons = buttonList.map((button, index) =>
        <Button variant={buttonIsSelected(index) ? 'contained' : 'outlined'}
            color="secondary"
            key={index}
            onClick={selectButton.bind(this, button, index)}
            style={{ marginRight: '16px', padding: '12px 24px' }}>
            {button.text}
        </Button>)
    return (
        <div>
            {buttons}
        </div>
    )
}

export default ButtonGroup;