import React from 'react';
import "./loading.scss";
const Loading = ({fullWidth = false, customStyle = {}}) => {
  return (
      <div className="loading-container" style={{width: fullWidth ? '100vw': 'auto', ...customStyle}}>
        <img src={`${process.env.PUBLIC_URL}/images/spinner.gif`} alt="company logo" />
      </div>
    )
}

export default Loading;