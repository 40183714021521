import React from 'react';
import { adminDimensionsStyle, adminTypografyStyles } from '../AdminStyles';
import FeedbackPanel from './FeedbackPanel';

const FeedbackAnalytics = () => {
  const dimensionClasses = adminDimensionsStyle();
  const typographyClasses = adminTypografyStyles();
 

  return (
    <div className={[dimensionClasses.headerMarginFix, dimensionClasses.containerWidth].join(' ')} style={{maxWidth: "1080px"}}>
      <p className={[typographyClasses.fontbold, typographyClasses.fontSize15, dimensionClasses.marginTop24, dimensionClasses.marginBottom16].join(' ')}>Feedback from investors:</p>
      <FeedbackPanel />
    </div>
  )
}

export default FeedbackAnalytics;