import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import { generalStyleClasses } from '../../utils/general-styles';
import { AsYouType } from 'libphonenumber-js';
import "./text-input.scss";

const TextInput = (props) => {
    const {
        label,
        handleChange,
        disabled = false,
        isPassword = false,
        isPhone = false,
        phoneCountry = 'US',
        isNumber = false,
        isMoneySum = false,
        isDate = false,
        error = false,
        customStyle = {},
        inputStyle = {},
        className = {},
        initialValue = "",
        placeholder = "",
        minNumber = 0,
        minValue = null,
    } = props;

    const classes = generalStyleClasses()
    const [value, setValue] = useState("");

    const changeValue = (newValue) => {
        if(isPhone) {
            newValue = new AsYouType(phoneCountry).input(newValue?.toString() || "");
        }
        if(newValue !== value) {
            setValue(newValue);
            if (handleChange) {
                handleChange(newValue);
            }

        }
    }

    useEffect(() => {
        setValue(new AsYouType(phoneCountry).input(value?.toString() || ""));
    }, [phoneCountry]);

    useEffect(() => {
        if(value !== initialValue && initialValue !== null) {
            setValue(initialValue)
        }
    }, [initialValue])

    const moneySumProps = isMoneySum
        ? { allowNegative: false, thousandSeparator: ',', decimalSeparator: '.' }
        : {}

    return (
        <div className={`text-input-container ${className}`} style={customStyle}>
            <label htmlFor="input" className={[classes.fontColorLight, classes.fontSize14, error ? classes.inputError : ''].join(' ')}>{label}</label>
            {
                isNumber
                    ? <NumberFormat min={minNumber} style={inputStyle} {...moneySumProps} className={[classes.input, classes.fontSize16, error ? classes.inputError : ''].join(' ')} placeholder={placeholder} value={value} onValueChange={valueObject => changeValue(valueObject.floatValue)} />
                    : <input style={inputStyle} disabled={disabled} autoComplete={isPassword ? "new-password" : "off"} min={minValue} type={isPassword ? "password" : isDate ? "date" : "text"} name="input" placeholder={placeholder} value={value} className={[classes.input, classes.fontSize16, error ? classes.inputError : ''].join(' ')} onChange={(e) => changeValue(e.target.value)} />

            }
        </div>
    )
}

export default TextInput