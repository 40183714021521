export const LOGOUT = "LOGOUT";
export const ACCEPTED_CONFIDENTIALITY = "ACCEPTED_CONFIDENTIALITY" 
export const SET_REGISTRATION_USER_ID = "SET_REGISTRATION_USER_ID"; 
export const SET_REGISTER_STEP_PERSONAL_INFOS = "SET_REGISTER_STEP_PERSONAL_INFOS";
export const SET_REGISTER_STEP_LAST_STEP = "SET_REGISTER_STEP_LAST_STEP";
export const SET_USER_ACCOUNT = "SET_USER_ACCOUNT"; 
export const USER_NOT_LOGGEDIN = "USER_NOT_LOGGEDIN";
export const SET_INVESTOR_PROFILE_ID = "SET_INVESTOR_PROFILE_ID";
export const SET_INVESTOR_PROFILE = "SET_INVESTOR_PROFILE";
export const SET_USER_ADMIN = "SET_USER_ADMIN";
export const SET_USER_NOT_ADMIN = "SET_USER_NOT_ADMIN";
export const SET_USER_AUTH = "SET_USER_AUTH";
export const SET_DEAL_FILTERS = "SET_DEAL_FILTERS";
export const SET_ACCOUNT_PERSONAL_INFOS = "SET_ACCOUNT_PERSONAL_INFOS";
export const SET_INVESTOR_PROFILE_VIEWED = "SET_INVESTOR_PROFILE_VIEWED"
export const SET_INVESTOR_PROFILE_INDUSTRY = "SET_INVESTOR_PROFILE_INDUSTRY";
export const SET_INVESTOR_PROFILE_COMPANY = "SET_INVESTOR_PROFILE_COMPANY";
export const SET_INVESTOR_PROFILE_INVESTMENT = "SET_INVESTOR_PROFILE_INVESTMENT";
export const SET_ALL_COMPANY_FUNDING_STAGE = "SET_ALL_COMPANY_FUNDING_STAGE";

export const sortActions = {
  SET_MOST_RECENT_FILTER: "SET_MOST_RECENT_FILTER", 
  SET_ENDING_SOON_FILTER: "SET_ENDING_SOON_FILTER",
  SET_NAME_FILTER: "SET_NAME_FILTER",
  SET_HIGHEST_VALUATION_FILTER: "SET_HIGHEST_VALUATION_FILTER",
  SET_HIGHEST_OFFERING_FILTER: "SET_HIGHEST_OFFERING_FILTER",
}

export const addOportunityActions = {
  CREATE_SECTION: "CREATE_SECTION",
  DELETE_SECTION: "DELETE_SECTION",
  SET_SECTION_DATA: "SET_SECTION_DATA",
  REORDER_SECTIONS: "REORDER_SECTIONS",
  SET_EDIT_DEAL_DATA: "SET_EDIT_DEAL_DATA",  
  SET_COMPANY_ID: "SET_COMPANY_ID",
  SET_FLOW_ID: "SET_FLOW_ID",
  SET_SECTION_EXPANDED: "SET_SECTION_EXPANDED",
  SET_TYPE: 'SET_TYPE',
  SET_DEAL_DETAIS: 'SET_DEAL_DETAIS',
  RESET_FIELDS: "RESET_FIELDS"  
}

export const addInvestmentFlowActions = {
  SET_DATA: "SET_DATA",
  SET_TITLE: "SET_TITLE",
  SET_COMPLETED: "SET_COMPLETED",
  CREATE_STEP: "CREATE_STEP",
  DELETE_STEP: "DELETE_STEP",
  SET_STEP_TITLE: "SET_STEP_TITLE",
  REORDER_STEPS: "REORDER_STEPS",
  SET_STEP_DATA: "SET_STEP_DATA",
  SET_STEP_EXPANDED: "SET_STEP_EXPANDED",
  CHANGE_STEP_COMPLETED: "CHANGE_STEP_COMPLETED",
  SET_ACTIVE_STEP: "SET_ACTIVE_STEP",
  REORDER_STEP_PARTS: "REORDER_STEP_PARTS",
  DELETE_STEP_PART: "DELETE_STEP_PART",
};