import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { GET_COMPANY_DETAILS_BY_ID, GET_COMPANY_OVERVIEW } from '../../../queries/admin-queries';
import LabeledText from '../../../../shared/LabeledText/LabeledText';
import { oportunitySectionStyle } from "./OportunitySectionStyles";
import { useSelector } from 'react-redux';
import { capitalize, formatValue } from '../../../../utils/formats';
import { adminAlignmentStyles, adminDimensionsStyle } from '../../../AdminStyles';
import LabeledMultipleFields from '../../../../shared/LabeledMultipleFields/LabeledMultipleFields';

export const OverviewSection = () => {
  const classes = oportunitySectionStyle();
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const { companyId } = useSelector(state => state.adminOpportunity.resultingOpportunity ) || {};
  const [getCompanyDetails, { data }] = useLazyQuery(GET_COMPANY_OVERVIEW, { variables: { id: companyId } });
  const offering_type = useSelector(store => capitalize(store.adminOpportunity.resultingOpportunity.offeringType))
  const fees = useSelector(store => store.adminOpportunity.resultingOpportunity.fees)
  const status = useSelector(store => store.adminOpportunity.resultingOpportunity.type)
  const totalOfferedAmount = useSelector(store => store.adminOpportunity.resultingOpportunity.totalOfferedAmount)
  const valuation = useSelector(store => store.adminOpportunity.resultingOpportunity.valuation)

  useEffect(() => {
    companyId && getCompanyDetails && getCompanyDetails({variables: {id: companyId}});
  }, [companyId, getCompanyDetails]);


  if (!data || !data.Company_by_pk) {
    return <div className={classes.content}>Company overview loading </div>
  }

  return (
    <div className={classes.content} style={{ justifyContent: "space-between" }} style={{flexDirection: "column"}}>
      <div className={[alignmentClasses.flex].join(' ')} >
          <LabeledMultipleFields label="Sectors" fields={data.Company_by_pk.sectors.map(x => x.ENUM_interested_sector.description)} />
          <LabeledMultipleFields label="Innovation Enablers" fields={data.Company_by_pk.innovation_enablers.map(x => x.ENUM_innovation_enabler.description)} customStyles={{ marginLeft: "80px" }} />
        </div>
        <div className={[alignmentClasses.flex, dimensionClasses.marginTop24].join(' ')}>
          {status !== "CLOSED" && <LabeledText label="Financing Stage" text={data.Company_by_pk.funding_stage} customStyles={{ marginRight: "80px" }} />}
          {status === "ACTIVE" && <LabeledText label="Offering Type" text={offering_type === "COMBINATION" ? "Combination of primary and secondary shares" : capitalize(offering_type)} customStyles={{ marginRight: "80px" }} />}
          {status !== "PROSPECTIVE" && <LabeledText label="Proposed Valuation " text={`$${formatValue(valuation)}`} customStyles={{ marginRight: "80px" }} />}
          {status !== "PROSPECTIVE" && <LabeledText label="Offered Amount" text={`$${formatValue(totalOfferedAmount)}`} customStyles={{ marginRight: "80px" }} />}
          {status !== "PROSPECTIVE" && <LabeledText label="Entity Type" text={data.Company_by_pk.entity_type} />}
          {status === "PROSPECTIVE" && <LabeledText label="Last Valuation" text={`$${formatValue(data.Company_by_pk.last_valuation)}`} />}

        </div>
        {
          fees && status !== "PROSPECTIVE" &&
          (
            <div className={[alignmentClasses.flex, dimensionClasses.marginTop24].join(' ')}>
              <LabeledText label="Fees" text={fees} />
            </div>
          )
        }

    </div>
  )
}

export const companyOverviewAreaSection = (companyId) => ({
  component: <OverviewSection />,
  title: (<span>Overview (this section is editable in the company page)</span>),
  params: {
    disableDeleteButton: true,
  }
})

export default OverviewSection;