import React, { useEffect, useRef, useState } from 'react';
import TopsideMenu from './TopsideMenu/TopsideMenu';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import AllOpportunitiesPage from './AllOpportunitiesPage';
import OpportunitiesFilters from './Filters/OpportunitiesFilters';
import { generalStyleClasses } from "../../../../utils/general-styles";
import { opportunitiesPageStyles } from "./OpportunitiesPageStyle";
import Pagination from '../../../../shared/Pagination/Pagination';
import { useDispatch } from 'react-redux';
import { Filters } from './Filters/filterHolder';
import { SET_DEAL_FILTERS } from '../../../../store/utils/action-types';
import { useQuery } from '@apollo/client';
import { GET_DEAL_COUNT } from '../../../../utils/deal-queries';

const OpportunitiesPage = () => {
  const classes = opportunitiesPageStyles();
  const generalStyles = generalStyleClasses();
  const { data } = useQuery(GET_DEAL_COUNT);
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const numberOfPages = data && Math.ceil(data.Deal_aggregate.aggregate.count / Filters.dealsPerPage);
  const [opNumber, setOpNumber] = useState(0);
  const contentRef = useRef(null);
  const handlePageChanged = (value) => {
    Filters.page = value - 1;
    dispatch({ type: SET_DEAL_FILTERS, payload: Filters.getContent() });
  }

  const listenScrollEvent = (event) => {
    if (window.scrollY > 0) {
      contentRef.current.style.zIndex = 50;
    } else {
      contentRef.current.style.zIndex = 101;
    }
  }
  
  useEffect(() => {
    listenScrollEvent(null);
    window.addEventListener('scroll', listenScrollEvent);
  
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
      <div className={[generalStyles.fullViewWidth, generalStyles.flexAlignCenterOnColumn].join(' ')}>
        <div className={[classes.topsideMenu, generalStyles.flexAlignCenter, "header-overlap"].join(' ')}>
          <TopsideMenu />
        </div>
        <div className={classes.contentContainer}>
              <div className={classes.filters} >
                <OpportunitiesFilters />
              </div>
          <div className={classes.content} ref={contentRef}>
            <Switch>
              <Route path={`${url}/:type`} component={() => <AllOpportunitiesPage handleOpportunitiesNumber={value => setOpNumber(value)} />} />
              <Route exact path={`${url}*`} component={() => <Redirect to={`${url}/all`} />} />
            </Switch>
            <div className={[generalStyles.flexSpaceBetweenCenter].join(' ')} >
              <p className={[generalStyles.marginTop0, generalStyles.marginBottom0, generalStyles.fontSize13]} style={{ color: "#A9A9A9", fontSize: "11px", lineHeight: "13px" }}>
                Showing
              {
                  opNumber
                    ? ` ${opNumber} `
                    : " some "
                }
              of
              {
                  data && data.Deal_aggregate
                    ? ` ${data.Deal_aggregate.aggregate.count} `
                    : " all "
                }
              Investments
              </p>
              <Pagination defaultPage={Filters.page + 1} allPagesNumber={numberOfPages} handleChange={value => handlePageChanged(value)} />
            </div>

          </div>
        </div>
      </div>
  );
}

export default OpportunitiesPage