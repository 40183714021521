import { from } from "@apollo/client"
import React, { Fragment } from 'react';
import LabeledText from '../../../shared/LabeledText/LabeledText';
import LabeledMultipleFields from '../../../shared/LabeledMultipleFields/LabeledMultipleFields';
import {
  buildBussinessModelText, buildDevelopmentStageText, buildFoundingStageText,
  getInvestmentType, getSecurityType, getExecutionStats, getInvestmentStatus
} from '../utils';
import { numberWithCommas } from "../../../utils/formats";

export const IndustriesSection = ({ investorProfile }) => {
  return (
    <Fragment>
      {
        investorProfile.interested_sectors.length > 0
          ? <LabeledMultipleFields label="Interested in these sectors:" customStyles={{ width: "400px" }} fields={
            investorProfile.interested_sectors.map(x => x.ENUM_interested_sector.description)
          } />
          : ""
      }
      {
        investorProfile.innovation_enablers.length > 0
          ? <LabeledMultipleFields label="Interested in these innovation enablers" customStyles={{ width: "400px" }} fields={
            investorProfile.innovation_enablers.map(x => x.ENUM_innovation_enabler.description)
          } />
          : ""
      }
      {
        investorProfile.investor_profile_innovation_enablers_lesses.length > 0
          ? <LabeledMultipleFields label="Least interested in these innovation enablers" customStyles={{ width: "400px", marginTop: "32px" }} fields={
            investorProfile.investor_profile_innovation_enablers_lesses.map(x => x.ENUM_innovation_enabler.description)
          } />
          : ""
      }
      {
        investorProfile.investor_profile_interested_sectors_lesses.length > 0
          ? <LabeledMultipleFields label="Least interested in these sectors" customStyles={{ width: "400px", marginTop: "32px" }} fields={
            investorProfile.investor_profile_interested_sectors_lesses.map(x => x.ENUM_interested_sector.description)
          } />
          : ""
      }
      {
        investorProfile.industry_additional_information 
        ? <LabeledText customStyles={{width: "100%", marginTop: "32px"}} label="Additional information" text={investorProfile.industry_additional_information} />
        : ""
      }
    </Fragment>
  )
}

export const CompanySection = ({ investorProfile }) => {
  const developmentStageText = buildDevelopmentStageText(investorProfile);
  const foundingStageText = buildFoundingStageText(investorProfile);
  const bussinessModelText = buildBussinessModelText(investorProfile)
  return (
    <Fragment>
      {
        developmentStageText != ""
          ? <LabeledText label="Development Stage" text={developmentStageText} customStyles={{ width: "400px",  minHeight: "36px", marginBottom: "36px" }} />
          : ""
      }
      {
        foundingStageText != ""
          ? <LabeledText label="Financing Round" text={foundingStageText} customStyles={{ width: "400px",  minHeight: "36px", marginBottom: "36px" }} />
          : ""
      }
      {
        investorProfile.countries.length > 0
          ? <LabeledMultipleFields label="Country of incorporation" customStyles={{ width: "400px" }} fields={
            investorProfile.countries.map(x => x.ENUM_country.description)
          } />
          : ""
      }
      {
        bussinessModelText != ""
          ? <LabeledText label="Business models" text={bussinessModelText} customStyles={{ width: "400px" }} />
          : ""
      }
       {
        investorProfile.company_additional_information 
        ? <LabeledText customStyles={{width: "100%", marginTop: "32px"}} label="Additional information" text={investorProfile.company_additional_information} />
        : ""
      }
    </Fragment>
  )
}


export const InvestmentSection = ({ investorProfile }) => {
  return (
    <Fragment>
      {
        investorProfile.investment_type !== 0
          ? <LabeledText label="Preferred Investment Type" text={getInvestmentType(investorProfile.investment_type)} customStyles={{ width: "400px", minHeight: "36px", marginBottom: "36px"}} />
          : ""
      }
      {
        investorProfile.investment_security_type !== 0
          ? <LabeledText label="Preferred Security Type" text={getSecurityType(investorProfile.investment_security_type)} customStyles={{ width: "400px", minHeight: "36px", marginBottom: "36px"}} />
          : ""
      }
        {
        investorProfile.investment_execution_timeline !== 0
          ? <LabeledText label="Execution Timeline" text={getExecutionStats(investorProfile.investment_execution_timeline)} customStyles={{ width: "400px", minHeight: "36px", marginBottom: "36px"}} />
          : ""
      }
      {
        investorProfile.investment_size_low > 0 && investorProfile.investment_size_high > 0
          ? <LabeledText label="Prefered Investment Size" text={`Between $${numberWithCommas(investorProfile.investment_size_low)} and $${numberWithCommas(investorProfile.investment_size_high)}`} customStyles={{ width: "400px", minHeight: "36px", marginBottom: "36px"}} />
          : ""
      }
      {
        investorProfile.investment_status !== 0 
          ? <LabeledText label="Investment Status" text={getInvestmentStatus(investorProfile.investment_status)} customStyles={{ width: "400px", minHeight: "36px", marginBottom: "36px"}} />
          : ""
      }
       {
        investorProfile.investment_additional_information 
        ? <LabeledText customStyles={{width: "100%"}} label="Additional information" text={investorProfile.investment_additional_information} />
        : ""
      }
    </Fragment>
  )
}