import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { generalStyleClasses } from '../../../../../utils/general-styles';

const styles = makeStyles({
  container: {
    padding: "32px",
    background: "#F9F9F9",
    border: "1px solid #F4F4F4",
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    background: "white",
    margin: "0px 8px",
    textTransform: "none",
    border: "1px solid #EBEBEB",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
  }
})
const DetailedOpportunityFooter = () => {
  const classes = styles();
  const generalClasses = generalStyleClasses();
  return (
    <div className={classes.container}>
      <p className={generalClasses.fontSize14} style={{color: "#484642"}}>
      What amount would you be interested to invest?
      </p>
      <div>
        <Button disableElevation disableRipple variant="contained" className={[classes.button, generalClasses.fontSize14].join(' ')} style={{color: "#78756F"}}>Not interested</Button>
        <Button disableElevation disableRipple variant="contained" className={[classes.button, generalClasses.fontSize14].join(' ')} style={{color: "#78756F"}}>$200,000</Button>
        <Button disableElevation disableRipple variant="contained" className={[classes.button, generalClasses.fontSize14].join(' ')} style={{color: "#78756F"}}>$500,000</Button>
        <Button disableElevation disableRipple variant="contained" className={[classes.button, generalClasses.fontSize14].join(' ')} style={{color: "#78756F"}}>$1000,000</Button>
        <Button disableElevation disableRipple variant="contained" className={[classes.button, generalClasses.fontSize14].join(' ')} style={{color: "#78756F"}}>Custom</Button>
      </div>
    </div>
  )
}

export default DetailedOpportunityFooter;