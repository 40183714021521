import React, { Fragment, useState } from 'react';
import { generalStyleClasses } from "../../../../utils/general-styles";
import { Button } from '@material-ui/core';
import AdditionalInformations from '../../../../shared/InvestorPreferancesInputs/AdditionalInformation';
import PreferedInvestmentType from '../../../../shared/InvestorPreferancesInputs/PreferedInvestmentType';
import PreferredSecurityType from '../../../../shared/InvestorPreferancesInputs/PreferredSecurityType';
import ExecutionTimeline from '../../../../shared/InvestorPreferancesInputs/ExecutionTimeline';
import InvestmentSize from '../../../../shared/InvestorPreferancesInputs/InvestmentSize';
import InvestmentStatus from '../../../../shared/InvestorPreferancesInputs/InvestmentStatus';
import { UPDATE_INVESTMENT_PREFERANCES } from '../../../../utils/investor-preferances-queries';
import { useDispatch, useSelector } from 'react-redux';
import { Events, EventThrower, hasuraErrorHandler } from '../../../../shared/EventEmitter/EventEmitter';
import { useMutation } from '@apollo/client';
import { SET_INVESTOR_PROFILE } from '../../../../store/utils/action-types';

const Investment = () => {
  const generalStyles = generalStyleClasses();
  const account = useSelector(store => store.account.user);
  const dispatch = useDispatch();
  const [preferedInvestmentType, setPreferedInvestmentType] = useState("0");
  const [preferredSecurityType, setPreferredSecurityType] = useState("0");
  const [executionTimeline, setExecutionTimeline] = useState("0");
  const [investmentSizeLow, setInvestmentSizeLow] = useState(0);
  const [investmentSizeHigh, setInvestmentSizeHigh] = useState(0);
  const [investmentStatus, setInvestmentStatus] = useState("0");
  const [additionalInformations, setAdditionalInformations] = useState('');
  const [updateInvestmentPreferances] = useMutation(UPDATE_INVESTMENT_PREFERANCES);
  const additionalInfosValue = useSelector(store => store.investorProfile.investment_additional_information);
  const [currency, setCurrency] = useState("");

  const handleSaveChanges = () => {
    if(investmentSizeLow > investmentSizeHigh) {
      hasuraErrorHandler.printError("Lowest margin of investment size must be lower than the highest margin");
      return;
  }
    const variables = {
        firebase_id: account.firebase_id,
        investment_preffered_currency: currency,
        investment_additional_information: additionalInformations,
        investment_type: preferedInvestmentType.toString(),
        investment_security_type: preferredSecurityType.toString(),
        investment_execution_timeline: executionTimeline.toString(),
        investment_size_low: investmentSizeLow,
        investment_size_high: investmentSizeHigh,
        investment_status: investmentStatus.toString(),
    }
    updateInvestmentPreferances({ variables: variables })
    .then(result => {
      dispatch({type: SET_INVESTOR_PROFILE, payload: result.data.update_Investor_profile.returning[0]});
      EventThrower.throwClientSideMessage(Events.InformationChangedMessage)
    })
    .catch(() => EventThrower.throwClientSideError(Events.InformationChangedError));
}

  return (
    <Fragment>
      <PreferedInvestmentType handleChange={setPreferedInvestmentType} />
      <PreferredSecurityType handleChange={setPreferredSecurityType} rootClasses={generalStyles.marginTop32} />
      <ExecutionTimeline handleChange={setExecutionTimeline} rootClasses={generalStyles.marginTop32} />
      <InvestmentSize changeMinValue={setInvestmentSizeLow} changeMaxValue={setInvestmentSizeHigh} changeCurrency={setCurrency} rootClasses={generalStyles.marginTop16} />
      <InvestmentStatus handleChange={setInvestmentStatus} rootClasses={generalStyles.marginTop32} />
      <AdditionalInformations handleChange={setAdditionalInformations} defaultValue={additionalInfosValue} rootClasses={generalStyles.marginTop32} />
      <Button variant="contained" color="primary" className={[generalStyles.floatRight, generalStyles.marginTop32].join(' ')} onClick={handleSaveChanges}>Save changes</Button>
    </Fragment>
  )
}

export default Investment;