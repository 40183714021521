import { gql } from '@apollo/client';

export const COUNTRIES = gql`
query GET_ALL_COUNTRIES {
  ENUM_countries {
    description
    value
  }
}
`;

export const SECTORS = gql`
query GetAllInterestedSectors {
  ENUM_interested_sectors {
    description
    value
  }
}
`;

export const SECTOR_ENABLERS = gql`
query GetAllISectorEnablers {
  ENUM_innovation_enablers {
    description
    value
  }
}
`;