import { createSlice } from "@reduxjs/toolkit";

let SectionIdGenStore = 0;
const sectionId = () => {
  SectionIdGenStore -= 1;
  return SectionIdGenStore;
}

export const onboardingSectionTypes = {
  DOCUSIGN: "DOCUSIGN",
  TEXT: "TEXT",
  UPLOAD: "UPLOAD",
  DOWNLOAD: "DOWNLOAD",
  INPUT_FIELD: "INPUT_FIELD",
};

const initialState = {
  id: null,
  title: "",
  steps: [],
  firebaseId: "",
  stepsToDelete: [],
  itemsToDelete: [],
  filesToUpload: [],
};

const createStepData = (order) => ({
  id: sectionId(),
  title: "",
  order,
  parts: [],
  extended: true,
})

const reapplyOrder = (sections) => sections.map((section, index) => { section.order = index; return section });
const findSection = (sections, { sectioId = null, order = null }) => sections.findIndex(section => section.id === sectioId || section.order === order);
const adminOnboardingFlow = createSlice({
  initialState: initialState,
  name: "adminOnboarding",
  reducers: {
    CREATE_ONBOARDING_FLOW: (state) => {
      state.id = null;
      state.title = "";
      state.steps = [];
      state.firebaseId = "";
      state.stepsToDelete = [];
      state.itemsToDelete = [];
      state.filesToUpload = [];
    },
    EDIT_ONBOARDING_FLOW: (state, action) => {
      state.id = action.payload.id;
      state.firebaseId = action.payload.firebaseId;
      state.steps = action.payload.steps.sort((a, b) => a.order - b.order);
      state.title = action.payload.title;
      state.stepsToDelete = [];
      state.itemsToDelete = [];
      state.filesToUpload = [];
    },
    SET_ONBOARDING_TITLE: (state, action) => {
      state.title = action.payload.title;
    },
    CREATE_STEP: (state) => {
      state.steps.push(createStepData(state.steps.length))
    },
    DELETE_STEP: (state, action) => {
      const toDeleteIndex = findSection(state.steps, { sectioId: action.payload.id });
      if (toDeleteIndex !== -1) {
        if (state.steps[toDeleteIndex].id > 0) {
          state.stepsToDelete.push(state.steps[toDeleteIndex]);
        }
        state.steps.splice(toDeleteIndex, 1);
        state.steps = reapplyOrder(state.steps);
      }
    },
    SET_STEP_COMPLETED: (state, action) => {
      state.steps = state.steps.map(step => {
        if (step.id === action.payload.id) {
          step.completed = !step.completed;
        }
        return step;
      })
    },
    SET_STEP_ACTIVE: (state, action) => {
      state.steps = state.steps.map(step => {
        if (step.id === action.payload.id) {
          step.current = true;
        } else {
          step.current = false;
        }
        return step;
      })
    },
    UPDATE_STEP: (state, action) => {
      const toUpdateIndex = findSection(state.steps, { sectioId: action.payload.id });
      if (toUpdateIndex !== -1) {
        const stepToUpdate = state.steps[toUpdateIndex];
        state.steps[toUpdateIndex] = {
          ...stepToUpdate,
          ...action.payload,
          id: stepToUpdate.id,
          order: stepToUpdate.order,
          parts: stepToUpdate.parts,
        };
      }
    },
    REORDER_SECTIONS: (state, action) => {
      const toReorderIndex = findSection(state.steps, { order: action.payload.order });
      if (toReorderIndex > -1) {
        const section = state.steps.splice(toReorderIndex, 1)[0] || null;
        state.steps.splice(action.payload.newOrder, 0, section);
        state.steps = reapplyOrder(state.steps);
      }
    },
    CREATE_STEP_ITEM: (state, action) => {
      const sectionIndex = findSection(state.steps, { sectioId: action.payload.sectionId });
      if (sectionIndex !== -1) {
        state.steps[sectionIndex].parts.push({ id: sectionId(), type: action.payload.type, data: null })
      }
    },
    DELETE_STEP_ITEM: (state, action) => {
      const sectionIndex = findSection(state.steps, { sectioId: action.payload.sectionId });
      if (sectionIndex !== -1) {
        const itemIndex = state.steps[sectionIndex].parts.findIndex(x => x.id === action.payload.itemId);
        if (itemIndex !== -1) {
          const itemToDelete = state.steps[sectionIndex].parts[itemIndex];
          if (itemToDelete.id > 0) {
            state.itemsToDelete.push(state.steps[sectionIndex].parts[itemIndex]);
          }
          state.steps[sectionIndex].parts.splice(itemIndex, 1);
        }
      }
    },
    UPDATE_STEP_ITEM: (state, action) => {
      const sectionIndex = findSection(state.steps, { sectioId: action.payload.sectionId });
      if (sectionIndex !== -1) {
        const itemIndex = state.steps[sectionIndex].parts.findIndex(x => x.id === action.payload.itemId);
        if (itemIndex !== -1) {
          const stepItem = state.steps[sectionIndex].parts[itemIndex];
          stepItem.data = {
            ...stepItem.data,
            ...action.payload.data
          }
          if (action.payload.uploadFile) {
            const edgeFilesIndex = state.filesToUpload.findIndex(x => x.edgeId === stepItem.edgeId);
            if (edgeFilesIndex !== -1) {
              state.filesToUpload[edgeFilesIndex].files = action.payload.data;
            } else {
              state.filesToUpload.push({ files: action.payload.data, firebaseId: state.firebaseId, edgeId: stepItem.edgeId });
            }
          }
        }
      }
    }
  }
})

export const adminOnboardingFlowActions = adminOnboardingFlow.actions;
export const adminOnboardingFlowReducer = adminOnboardingFlow.reducer;