import { useQuery, useSubscription } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import Loading from '../../../../../shared/Loading/Loading';
import { GET_WATCHLISTED_DEALS_SUMARY } from '../../../../../utils/deal-queries';
import { generalStyleClasses } from '../../../../../utils/general-styles';
import OpportunityCard from '../../OpportunitiesPage/OpportunityCard/OpportunityCard';

const watchlistClasses = makeStyles({
  updatesContainer: {
    width: "266px",
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    height: "91px",
    marginRight: "24px",
    padding: "24px",
    boxSizing: "border-box",
  }
})

const WatchlistedOpportuniriesPage = ({orderString = "", nameFilter = null}) => {
  const generalClasses = generalStyleClasses();
  const classes = watchlistClasses();
  const firebase_id = useSelector(store => store.account.user.firebase_id);
  const params = useRouteMatch().params;
  const { data, loading } = useQuery(GET_WATCHLISTED_DEALS_SUMARY(firebase_id, params.id || null, orderString, nameFilter), {fetchPolicy: "network-only" });
  const [ dealData, setDealData ] = useState([])

   useEffect(() => {
     if(data && data.Deal.length > 0) {
       setDealData(data.Deal.map((x, index) => ({
         deal: x, 
         index: index 
        })))
     }
   }, [data])

   const handleRemoveDeal = (id) => {
     setDealData(dealData.filter(x => x.deal.id !== id));
   }

   if (loading) {
    return (
      <div style={{display: "flex", justifyContent: "center"}}>
        <Loading />
      </div>
    )
  }
  if (!data || !data.Deal || data.Deal.length === 0 || dealData.length === 0) {
    return <p>No deals avalabile of this type</p>
  }
  return (
    <Fragment>
      {dealData.map(x => (
        <div className={generalClasses.flex} key={x.deal.id}>
          <div className={classes.updatesContainer}>
              <p style={{margin: "0px"}}>Work in progress</p>
            <p className={[generalClasses.marginTop0, generalClasses.marginBottom0, generalClasses.fontSize15, generalClasses.fontInputColor].join(' ')}>Deal update</p>
            <p className={[generalClasses.marginTop8, generalClasses.marginBottom0, generalClasses.fontSize13, generalClasses.fontColorLight].join(' ')}>
            {new Date(x.deal.updated_at).toLocaleString('en-US', { month: 'short', day: 'numeric',year: 'numeric' })} 
            </p>
          </div>
          <OpportunityCard deal={x.deal} isOnWatchlist handleRemoveFromWatchlist={handleRemoveDeal}/>
        </div>
      ) )}
    </Fragment>
  )
}

export default WatchlistedOpportuniriesPage;