import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import InputField from './InputField';
import './multiple-select-field.scss';

const MultipleSelectField = forwardRef((props, ref) => {
    const {
        optionsInput, 
        placeholder="", 
        handleChange, 
        defaultValues = [], 
        isDynamic = false,
        customFieldStyle = {},
        inputFieldOptionalStyle = {}
    } = props;

    const [selectedValues, setSelectedValues] = useState([]);
    const addNewValue = (value) => {
        if(selectedValues.map(x => x.value).indexOf(value.value) === -1) {
            setSelectedValues([...selectedValues, value]);
            if(handleChange) {
                handleChange([...selectedValues, value]);
            }
        }
    }

    useImperativeHandle(ref, () => ({
        reset() {
            setSelectedValues([])
        }
    }));
    
    useEffect(() => {
        if(defaultValues && defaultValues.length > 0) {
            setSelectedValues(defaultValues)
        }
    }, [defaultValues])

    const removeValue = (index) => {
        setSelectedValues(selectedValues.filter((x, i) => i !== index));
        if(handleChange) {
            handleChange(selectedValues.filter((x, i) => i !== index));
        }
    }

    return (
        <div>
            <InputField label={placeholder} options={optionsInput.filter(x => selectedValues.map(opt => opt.value).indexOf(x.value) === -1)} isDynamic={isDynamic} selectNewState={value => addNewValue(value)} optionalStyle={inputFieldOptionalStyle}/>
            <div className="selectedOptionsContainer">
                {selectedValues.map((x, index) =>
                 <div key={index} className="selectedOption"  style={customFieldStyle}>
                     {x.description}
                     <span onClick={() => removeValue(index)}>x</span>                 
                 </div>)}
            </div>
        </div>
    )
})

export default MultipleSelectField;

