import { makeStyles } from '@material-ui/core/styles';

const defaultFontStyle = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
}

const flexAlignCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

export const generalStyleClasses = makeStyles({
    marginTop0: {
        marginTop: '0px',
    },
    marginTop8: {
        marginTop: '8px',
    },
    marginTop12: {
        marginTop: '12px',
    },
    marginTop16: {
        marginTop: '16px',
    },
    marginTop24: {
        marginTop: '24px',
    },
    marginTop32: {
        marginTop: '32px !important',
    },
    marginTop40: {
        marginTop: '40px',
    },
    marginTop64: {
        marginTop: '50px',
    },
    marginBottom0: {
        marginBottom: '0px',
    },
    marginBottom8: {
        marginBottom: '8px',
    },
    marginbottom16: {
        marginBottom: '16px',
    },
    marginbottom24: {
        marginBottom: '24px',
    },
    marginbottom40: {
        marginBottom: '40px',
    },
    fontSize13: {
        ...defaultFontStyle,
        fontSize: "13px",
        lineHeight: "15px",
    },
    fontSize12: {
        ...defaultFontStyle,
        fontSize: '12px',
        lineHeight: '14px',
    },
    fontSize14: {
        ...defaultFontStyle,
        fontSize: '14px',
        lineHeight: '16px',
    },
    fontSize15: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "15px",
        lineHeight: "18px",
    },
    fontSize16: {
        ...defaultFontStyle,
        fontSize: '16px',
        lineHeight: '19px',
    },
    fontSize18: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '19px',
        lineHeight: '22px',
    },
    fontSize19: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '19px',
        lineHeight: '22px',
    },
    fontSize20: {
        ...defaultFontStyle,
        fontSize: '20px',
        lineHeight: '23px',
    },
    fontBold: {
        fontWeight: "500",
    },
    fontColorDark: {
        color: '#434448 !important',
    },
    fontColorLight: {
        color: '#796E6E !important',
    },
    backgroundLight: {
        background: '#796E6E !important',
    },
    fontInputColor: {
        color: '#505154 !important',
    },
    fontAlignCenter: {
        textAlign: 'center',
    },
    singleRightButton: {
        textDecoration: 'none',
        width: '50%',
        alignSelf: 'flex-end',
        "& button": {
            width: '100%',
        }
    },
    dualLinkButtonsGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        "& a": {
            width: 'calc(50% - 8px)',
            textDecoration: 'none',
        },
        "& button": {
            width: 'calc(50% - 8px)',
            textDecoration: 'none',
        }
    },
    singleFullWidthLinkButton: {
        width: '100%',
        textDecoration: 'none',
        textTransform: "none"
    },

    paragraphWithQuestion: {
        display: 'flex',
        alignItems: 'center',
        "& img": {
            cursor: "pointer",
            marginLeft: "16px",
        }
    },
    flex: {
        display: "flex",
    },
    flexWrap: {
        display: "flex",
        flexWrap: "wrap",
    },
    flexJustifyEnd: {
        display: "flex",
        justifyContent: "flex-end",
    },
    flexAlignCenter: {
        ...flexAlignCenter
    },
    flexAlignCenterOnColumn: {
        ...flexAlignCenter,
        flexDirection: 'column',
    },
    flexCenterElements: {
        display: "flex",
        alignItems: "center",
    },
    basicInputField: {
        height: '40px',
        border: '1px solid #D8CECE',
        boxSizing: 'border-box',
        borderRadius: ' 4px',
        padding: '0px 12px'
    },
    inputError: {
        color: 'red',
        borderColor: 'red !important',
    },
    flexSpaceBetweenCenter: {
        display: 'flex !important',
        justifyContent: 'space-between !important',
        alignItems: 'center !important',
    },
    mainPageWidth: {
        width: '100%',
        maxWidth: '1180px',
    },
    fullViewWidth: {
        width: '100vw',
    },
    floatRight: {
        float: "right",
    },
    styledSelect: {
        width: "100%",
        height: "40px",
        "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "10px 12px",
            background: "white",
            border: "1px solid #D8CECE",
        }
    },
    simpleDropdown: {
        "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
          padding: "9px 12px 9px 4px",
        }
      }
})