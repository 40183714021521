import React from 'react';
import { topsideMenuStyles } from './TopsideMenuStyle';
import { Button, FormControl} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { generalStyleClasses } from '../../../../utils/general-styles';

const TopsideMenu = ({handleSearchChange = (value) => null}) => {
  const classes = topsideMenuStyles();
  const generalStyles = generalStyleClasses();

  return (
    <div className={[generalStyles.mainPageWidth, generalStyles.flexSpaceBetweenCenter].join(' ')}>
      <FormControl>
        <InputBase
          id="input-with-icon-adornment"
          inputProps={{ 'aria-label': 'naked' }}
          placeholder="Search..."
          onChange={e => handleSearchChange(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
      <div className={[classes.buttonGroup, generalStyles.flexCenterElements].join(' ')}>
        <span className={[generalStyles.fontSize16, generalStyles.fontColorDark].join(' ')} style={{ marginRight: "16px" }}>Watchlist</span>
      </div>
    </div>
  );
}

export default TopsideMenu