export const mapServerFlow = (flow) => {
  let activeStep = 0;
  let mappedFlow = flow
    .Investment[0]
    .Investment_flow_section_edges[0]
    .Flow_node
    .Investment_flow_section_edges
    .map(x => {
      let data = {
        tabName: x.Flow_section_node.name,
        order: x.order,
        locked: x.completed,
        components: x.Investment_section_item_edge.map(part => {
          let component = {
            edgeId: part.id,
            type: part.Flow_item_node.type.toLowerCase(),
            text: part.Flow_item_node.text,
            label: "",
            files: part.Files_metadata.map(x => ({name: x.name, id: x.id})),
          }
          return component;
        })
      }
      if(activeStep === 0 && x.current && !x.locked) {
        activeStep = x.order;
      }
      return data;
    })
  return {flow: mappedFlow.sort((a, b) => a.order - b.order), current: activeStep};
}