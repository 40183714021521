import React, { forwardRef, useEffect } from 'react';
import { adminAlignmentStyles, adminDimensionsStyle, adminInputStyles, adminTypografyStyles } from '../AdminStyles';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import InvestmentFlowStep from './InvestmentFlowStep';
import { useDispatch, useSelector } from 'react-redux';
import { addInvestmentFlowActions } from '../../store/utils/action-types';
import { flowMock, flowEmptyMock } from './flow-mock';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_DEAL_FLOW_BY_ID,
  GET_GENERAL_FLOW_TEMPLATE_BY_ID,
  GET_INVESTMENT_FLOW_BY_ID,
  UPDATE_SECTION_ORDER_BY_EDGE,
  GET_INVESTMENT_COMPLETED,
} from "../queries/admin-queries";
import { useRouteMatch } from 'react-router-dom';
import { mappServerData } from './flowMapper';

export const AddInvestmentFlow = (props, ref) => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const inputClasses = adminInputStyles();
  const typografyClasses = adminTypografyStyles();
  const dispatch = useDispatch();
  const flowTitle = useSelector(store => store.adminAddInvestorFlowState.title);
  const steps = useSelector(store => store.adminAddInvestorFlowState.steps);
  const [getGeneralFlowData, generalFlowDataResult] = useLazyQuery(GET_GENERAL_FLOW_TEMPLATE_BY_ID, {fetchPolicy: "network-only"});
  const [getDealFlowData, dealFlowDataResult] = useLazyQuery(GET_DEAL_FLOW_BY_ID, {fetchPolicy: "network-only"});
  const [getInvestmentFlowData, dealInvestmentDataResult] = useLazyQuery(GET_INVESTMENT_FLOW_BY_ID, {fetchPolicy: "network-only"});
  const [
    getInvestmentCompleted,
    investmentCompletedDataResult,
  ] = useLazyQuery(GET_INVESTMENT_COMPLETED, { fetchPolicy: "network-only" });
  const routeParams = useRouteMatch().params;
  
  useEffect(() => {
    if (routeParams && routeParams.flowId) {
      if(routeParams.investmentId) {
        getInvestmentFlowData({ variables: { id: routeParams.flowId, deal_id: routeParams.dealId, investment_id: routeParams.investmentId } })
        props.setInvestmentId(routeParams.investmentId)
        getInvestmentCompleted({ variables: { id: routeParams.investmentId } });
      } else if(routeParams.dealId) {
        getDealFlowData({ variables: { id: routeParams.flowId, deal_id: routeParams.dealId, investment_is_null: true } })
      } else {
        getGeneralFlowData({ variables: { id: routeParams.flowId, deal_is_null: true, investment_is_null: true } })
      }
    }
  }, [routeParams, getGeneralFlowData])

  useEffect(() => {
    if(generalFlowDataResult.data && generalFlowDataResult.data.Flow_node_by_pk) {
      const mappedData = mappServerData(generalFlowDataResult.data.Flow_node_by_pk);
      dispatch({type: addInvestmentFlowActions.SET_DATA, payload: mappedData});
    }
  }, [generalFlowDataResult.data]);

  useEffect(() => {
    if(dealFlowDataResult.data && dealFlowDataResult.data.Flow_node_by_pk) {
      const mappedData = mappServerData(dealFlowDataResult.data.Flow_node_by_pk);
      dispatch({type: addInvestmentFlowActions.SET_DATA, payload: mappedData, dealId: Number(routeParams.dealId)});
    }
  }, [dealFlowDataResult.data]);

  useEffect(() => {
    if(dealInvestmentDataResult.data && dealInvestmentDataResult.data.Flow_node_by_pk) {
      const mappedData = mappServerData(dealInvestmentDataResult.data.Flow_node_by_pk);
      dispatch({type: addInvestmentFlowActions.SET_DATA, payload: mappedData, dealId: Number(routeParams.dealId), investmentId: Number(routeParams.investmentId) });
    }
  }, [dealInvestmentDataResult.data]);

  useEffect(() => {
    if (
      investmentCompletedDataResult.data &&
      investmentCompletedDataResult.data.Investment_by_pk
    ) {
      dispatch({
        type: addInvestmentFlowActions.SET_COMPLETED,
        payload: !!investmentCompletedDataResult.data.Investment_by_pk
          .completed,
      });
    }
  }, [investmentCompletedDataResult.data]);

  useEffect(() => {
    if(props.isClient) {
      dispatch({type: addInvestmentFlowActions.SET_DATA, payload: flowMock});
    } else {
      dispatch({type: addInvestmentFlowActions.SET_DATA, payload: flowEmptyMock});
    }
  }, [])


  const handleSetTitle = (title) => {
    dispatch({type: addInvestmentFlowActions.SET_TITLE, payload: title});
  }

  return (
    <div className={[dimensionClasses.containerWidth, dimensionClasses.headerMarginFix].join(' ')}>
      <p className={[dimensionClasses.marginBottom0, typografyClasses.fontSize14].join(' ')} style={{color: "#333333"}}>Title</p>
      <div className={[dimensionClasses.width100Percent, alignmentClasses.flex].join(' ')}>
      <input 
        placeholder="eg. Tesla Invest Flow"
        className={[inputClasses.input, typografyClasses.fontSize14, dimensionClasses.widthGrow, dimensionClasses.mediumHeight].join(' ')}
        value={flowTitle}
        onChange={e => handleSetTitle(e.target.value)}
        disabled={props.isClient || routeParams.flowId}
        />
      </div>
      {
        steps.sort((a, b) => a.order-b.order).map((step, index) => <InvestmentFlowStep key={index} stepData={step} isClient={props.isClient}/>)
      }
    </div>
  )
}

export default AddInvestmentFlow;