import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from '../AdminStyles';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALL_INVESTMENTS } from '../queries/admin-queries';
import { useSelector } from 'react-redux';
import { numberWithCommas } from '../../utils/formats';

const Documents = () => {
  const tableClasses = adminTablesStyle();
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const typographyClasses = adminTypografyStyles();
  const firebaseId = useSelector(store => store.account.user.firebase_id)
  const [investments, setInvestments] = useState([])
  const {data} = useQuery(GET_ALL_INVESTMENTS, {variables: {firebase_id: firebaseId}, fetchPolicy: "network-only"});

  useEffect(() => {
    if(data && data.Deal) {
      const mappedInvestments = data.Deal.map(x => ({
          companyName: `${x.company.name} - ${x.title}`,
          clients: x.Investments_aggregate.aggregate.count,
          deadline: new Date(x.investment_deadline).toLocaleDateString(),
          documents: x.Investments.length > 0 
          ? x.Investments.map(investment => ({
            investorName: `${investment.account.first_name} ${investment.account.last_name}`,
            value: investment.value,
            status: (investment.completed && "Completed") ||
                    investment.activeStep[0]?.Flow_section_node.name || 
                    investment.currentUncompletedStep[0]?.Flow_section_node.name || 
                    "All steps completed, waiting for review",
            lastActivity: investment.updated_at,
            flowId: investment.details[0]?.flow_id || 0,
            dealId: investment.details[0]?.deal_id || 0,
            investmentId: investment.details[0]?.investment_id || 0,
          }))
          : []
      }))
      setInvestments(mappedInvestments)
    }
  }, [data]);

  return (
    <div className={dimensionClasses.marginTop24}>
      <div className={[dimensionClasses.containerWidth, dimensionClasses.headerMarginFix, alignmentClasses.flexAlignCenter, tableClasses.tableHeader, typographyClasses.fontSize14, dimensionClasses.marginTop24].join(' ')}>
        <div className={dimensionClasses.width250}>Name</div>
        <div className={dimensionClasses.width150}>Clients</div>
        <div className={dimensionClasses.width250}>Current Step</div>
        <div className={dimensionClasses.widthGrow} style={{minWidth: "150px"}}>Last activity</div>
        <div className={dimensionClasses.width150}>Deadline</div>
      </div>
      {
        investments.map(x => (
          <Accordion className={dimensionClasses.containerWidth}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <div className={[dimensionClasses.containerWidth, alignmentClasses.flexAlignCenter, typographyClasses.fontSize14, dimensionClasses.marginTop24].join(' ')}>
                <div className={dimensionClasses.width250}>{x.companyName}</div>
                <div className={dimensionClasses.width150}>{x.clients}</div>
                <div className={dimensionClasses.width250}></div>
                <div className={dimensionClasses.widthGrow}>{new Date(Math.max(...x.documents.map(x => new Date(x.lastActivity).valueOf()))).toLocaleDateString()}</div>
                <div className={dimensionClasses.width150} style={{textAlign: "center"}}>{x.deadline}</div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={[alignmentClasses.flexWrap, dimensionClasses.containerWidth].join(' ')}>
              {
                x.documents.map(doc => (
                  <div className={[dimensionClasses.containerWidth, alignmentClasses.flexAlignCenter, tableClasses.row, typographyClasses.fontSize14, dimensionClasses.marginTop8].join(' ')}>
                    <div className={dimensionClasses.width200}>{doc.investorName}</div>
                    <div className={dimensionClasses.width200}>Amount: ${numberWithCommas(doc.value)}</div>
                    <div className={dimensionClasses.width250}>Current step: {doc.status}</div>
                    <div className={dimensionClasses.widthGrow}>{new Date(doc.lastActivity).toLocaleDateString()}</div>
                    <div className={dimensionClasses.width150}>
                      <Button 
                      variant="outlined" 
                      color="primary" 
                      className={dimensionClasses.smallHeigth}
                      component={Link}
                      to={`/admin/investment/client-documents/${doc.dealId}/${doc.flowId}/${doc.investmentId}`}>View documents</Button>
                      </div>
                  </div>
                ))
              }
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </div>
  )
}

export default Documents;