import { createSlice } from "@reduxjs/toolkit";
import { DealSectionType } from "../../utils/enums";

let SectionIdGenStore = 0;
const sectionId = () => {
  SectionIdGenStore -= 1;
  return SectionIdGenStore;
}

const defaultOpportunitySectionData = {
  [DealSectionType.OVERVIEW]: {
    title: 'Overview (this section is editable in the company page)'
  },
  [DealSectionType.DESCRIPTION]: {
    title: 'Company Description (this section is editable in the company page)'
  },
  [DealSectionType.MANAGEMENT]: {
    title: 'Management (this section is editable in the company page)'
  },
  [DealSectionType.FUNDING]: {
    title: 'Funding History (this section is editable in the company page)'
  },
  [DealSectionType.RICH_TEXT_AREA]: {
    title: '',
    editableTitle: true,
  },
  [DealSectionType.IMAGES]: {
    title: '',
    editableTitle: true
  },
  [DealSectionType.FILES]: {
    title: '',
    editableTitle: true
  },
}

export const sectionData = (order, type, optionalData) => ({
  order,
  id: sectionId(),
  extended: true,
  type,
  ...defaultOpportunitySectionData[type],
  ...(optionalData || {})
});

export const defaultOpportunityData = () => ({
  companyId: "",
  flowId: "",
  type: 0,
  offeringType: 0,
  securityType: 0,
  investmentMin: 0,
  investmentMax: 0,
  currency: "USD",
  valuation: null,
  totalOfferedAmount: null,
  deadline: Date.now(),
  fees: "",
  title: "",
  amountAvailable:0,
  flowName:'',
  sections: [
    sectionData(0, DealSectionType.OVERVIEW),
    sectionData(1, DealSectionType.DESCRIPTION),
  ],
});

const initialState = () => ({
  isSent: false,
  saving: false,
  drafting: false,
  publishing: false,
  previewing: false,
  error: null,
  selectedOpportunity: null,
  resultingOpportunity: defaultOpportunityData(),
  sectionsToDelete: [],
  itemsToDelete: [],
});

const reapplyOrder = (sections) => sections.map((section, index) => {section.order = index; return section}); 
const findSection = (sections, payload) => sections.findIndex(section => section.id === payload.id || section.order === payload.order);
const companySectionOrders = {
  [DealSectionType.DESCRIPTION]: 'company_management_order',
  [DealSectionType.FUNDING]: 'company_description_order',
  [DealSectionType.MANAGEMENT]: 'funding_history_order',
}

const adminOpportunitySlice = createSlice({
  initialState: initialState(),
  name:'adminOpportunity',
  reducers: {
    CREATE_OPPORTUNITY: (state, action) => {
      if(state.saving) return state;
      state.selectedOpportunity = null;
      state.resultingOpportunity = action.payload;
      state.sectionsToDelete = [];
    },
    EDIT_OPPORTUNITY: (state, action) => {
      if(state.saving) return state;
      action.payload.sections && action.payload.sections.sort((a, b) => a.order - b.order);
      state.selectedOpportunity = action.payload;
      state.resultingOpportunity = JSON.parse(JSON.stringify(action.payload));
      state.sectionsToDelete = [];
      state.itemsToDelete = [];
    },
    UPDATE_OPPORTUNITY_FIELDS: (state, action) => {
      if(state.saving) return state;
      state.resultingOpportunity = {
        ...state.resultingOpportunity,
        ...action.payload,
      }
    },
    ADD_SECTION: (state, action) => {
      if(state.saving) return state;
      if(defaultOpportunitySectionData[action.payload.type]){
        const newSectionData = sectionData(state.resultingOpportunity.sections.length, action.payload.type);
        state.resultingOpportunity.sections = state.resultingOpportunity.sections.concat([newSectionData])

        if(companySectionOrders[newSectionData.type]){
          state[companySectionOrders[newSectionData.type]] = newSectionData.order;
        }
      }
    },
    SECTION_REORDER: (state, action) => {
      if(state.saving) return state;
      const toReorder = findSection(state.resultingOpportunity.sections, action.payload);
      if(toReorder > -1){
        const sections = state.resultingOpportunity.sections.splice(toReorder, 1);
        state.resultingOpportunity.sections.splice(action.payload.newOrder, 0, sections[0]);
        state.resultingOpportunity.sections = reapplyOrder(state.resultingOpportunity.sections);
      }
    },
    DELETE_SECTION: (state, action) => {
      if(state.saving) return state;
      const toDeleteIndex = findSection(state.resultingOpportunity.sections, action.payload);
      if(toDeleteIndex > -1){
        const toDeleteSection = state.resultingOpportunity.sections.splice(toDeleteIndex, 1)[0];
        if(toDeleteSection.id > -1)
          state.sectionsToDelete = [...state.sectionsToDelete, toDeleteSection];
        if(companySectionOrders[toDeleteSection.type]){
          state[companySectionOrders[toDeleteSection.type]] = -1;
        }
        state.resultingOpportunity.sections = reapplyOrder(state.resultingOpportunity.sections);
      }
    },
    DELETE_FILE: (state, action) => {
      if(state.saving) return state;
      state.itemsToDelete.push(action.payload);
    },
    TOGGLE_SECTION: (state, action) => {
      if(state.saving) return state;
      const sectionToToggle = findSection(state.resultingOpportunity.sections, action.payload);
      if(sectionToToggle > -1){
        state.resultingOpportunity.sections[sectionToToggle] = {
          ...state.resultingOpportunity.sections[sectionToToggle],
          extended: !state.resultingOpportunity.sections[sectionToToggle].extended
        };
      }
    },
    UPDATE_SECTION: (state, action) => {
      if(state.saving) return state;
      const sectionToUpdate = findSection(state.resultingOpportunity.sections, action.payload);
      if(sectionToUpdate > -1){
        state.resultingOpportunity.sections[sectionToUpdate] = {
          ...state.resultingOpportunity.sections[sectionToUpdate],
          ...action.payload
        };
      }
    },
    UPDATE_SECTION_CONTENT_DIFFERENCE: (state, action) => {
      if(state.saving) return state;
      // todo : fix for remove case (mark things to delete)
      const sectionToUpdate = findSection(state.resultingOpportunity.sections, action.payload);
      if(sectionToUpdate > -1){
        const tmpSectionData = state.resultingOpportunity.sections[sectionToUpdate].data;
        // if(tmpSectionData?.content?.length){
        //   tmpSectionData.content.filter(content => {return true;}).forEach((content) => console.log("content filter", content));
        // }
        state.resultingOpportunity.sections[sectionToUpdate] = {
          ...state.resultingOpportunity.sections[sectionToUpdate],
          data: {
            ...(state.resultingOpportunity.sections[sectionToUpdate].data || {}),
            content: action.payload.content
          },
        };
      }
    },
    PUBLISH_DEAL: (state) => {
      state.isSent = false;
      state.saving = true;
      state.drafting = true;
      state.publishing = true;
    },
    SAVE_CHANGES: (state) => {
      state.isSent = false;
      state.saving = true;
      state.drafting = true;
      state.publishing = state.selectedOpportunity?.published_at;
    },
    FINISH_SAVING: (state) => {
      state.isSent = true;
      state.saving = false;
      state.drafting = false;
      state.publishing = false;
    },
    TOGGLE_PREVIEW: (state) => {
      state.previewing = !state.previewing;
    },
    CLEANUP: () => initialState(),
  }
});

export const adminOpportunityActions = adminOpportunitySlice.actions;
export const adminOpportunityReducer = adminOpportunitySlice.reducer;
