import React, { useEffect } from 'react';
import { generalStyleClasses } from '../../utils/general-styles';
import MultipleSelect from '../MultipleSelect/MultipleSelectField';
import { useQuery } from '@apollo/client'
import { SECTORS } from '../../utils/enum-queries';
import { useSelector } from 'react-redux';

const LessInterestedSectors = ({ handleChange, rootClasses = {}, fullWidth = false  }) => {
  const classes = generalStyleClasses();
  const sectorOptions = useQuery(SECTORS).data;
  const defaultValues = useSelector(store => store.investorProfile.investor_profile_interested_sectors_lesses);

  useEffect(() => {
    handleChange(defaultValues);
  }, [defaultValues]);

  return (
    <div className={rootClasses}>
      <span className={[classes.fontSize14, classes.fontInputColor].join(' ')}>Sectors I’m least interested in</span>
      <div className={classes.marginTop8}>
        <MultipleSelect optionsInput={sectorOptions ? sectorOptions.ENUM_interested_sectors : []} defaultValues={defaultValues} handleChange={handleChange} inputFieldOptionalStyle={fullWidth ? {width: "100%"} : {width: '300px'}}/>
      </div>
    </div>
  )
}

export default LessInterestedSectors;