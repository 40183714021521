import store from "../../store/store"

export const sendInvitationEmail = (email, firstName, lastName, roleId = 1) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/invite`,  {
    method: "POST",
    body: JSON.stringify({
      email,
      firstName,
      lastName,
      role: roleId,
    }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const changeAccountStatus = (accountId) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/user/disable`,  {
    method: "POST",
    body: JSON.stringify({
      accountId
    }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}


export const sendProvisoryInvitationEmail = (email, firebaseId) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/investor/invitation/resend`,  {
    method: "POST",
    body: JSON.stringify({
      email,
      firebaseId,
    }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const publishDeal = (dealId) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/deal/publish`,  {
    method: "POST",
    body: JSON.stringify({ dealId: dealId }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const unpublishDeal = (dealId) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/deal/unpublish`,  {
    method: "POST",
    body: JSON.stringify({ dealId: dealId }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const unpublishCompany = (companyId) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/company/unpublish`,  {
    method: "POST",
    body: JSON.stringify({ companyId }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const saveGeneralFlowTemplate = (flowData, defaultRoute = "investment") => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/${defaultRoute}/update`,  {
    method: "POST",
    body: JSON.stringify({ flow: flowData }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const deleteInvestmentSectionItemEdges = (edgeId) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/investment/delete`, {
    method: "POST",
    body: JSON.stringify({ deletedSectionItemEdges: edgeId }),
    headers: {
      Authorization: `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteSectionEdges = (edgeId) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/investment/delete`,  {
    method: "POST",
    body: JSON.stringify({ deletedFlowSectionEdges: edgeId }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const deleteEdges = (flowSectionEdges, flowItemEdges, defaultRoute = "investment") => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/${defaultRoute}/delete`,  {
    method: "POST",
    body: JSON.stringify({ deletedFlowSectionEdges: flowSectionEdges, deletedSectionItemEdges: flowItemEdges }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const cloneFlowForDeal = (flowId, dealId) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/investment/clone`,  {
    method: "POST",
    body: JSON.stringify({ flowId, dealId }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const saveOnboardingFlowTemplate = (flowData) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/onboarding/update`,  {
    method: "POST",
    body: JSON.stringify({ flow: flowData }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const deleteOnboardingEdges = (flowSectionEdges, flowItemEdges) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/onboarding/delete`,  {
    method: "POST",
    body: JSON.stringify({ deletedFlowSectionEdges: flowSectionEdges, deletedSectionItemEdges: flowItemEdges }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const cloneOnboardingFlowForInvestor = (flowId, firebaseId) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/onboarding/clone`,  {
    method: "POST",
    body: JSON.stringify({ flowId, firebaseId }),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}

export const createInvestorAccount = (data) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/investor/create`,  {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Authorization": `Bearer ${store.getState().account.jwtToken}`,
      "Content-Type": "application/json"
    }
  })
}