import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import LabeledMultipleFields from '../../../../../../shared/LabeledMultipleFields/LabeledMultipleFields';
import LabeledText from '../../../../../../shared/LabeledText/LabeledText';
import { GET_DEAL_OVERVIEW_SECTION } from '../../../../../../utils/deal-queries';
import { capitalize, formatValue } from '../../../../../../utils/formats';
import { generalStyleClasses } from '../../../../../../utils/general-styles';

const styles = makeStyles({
  informationContainer: {
    display: "flex",
    background: "#F9F9F9",
    border: "1px solid #F4F4F4",
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "16px",
    flexDirection: "column",
  }
})

const CompanyOverview = ({ opportunityId }) => {
  const classes = styles();
  const generalClasses = generalStyleClasses();
  const { data, loading, error } = useQuery(GET_DEAL_OVERVIEW_SECTION, { variables: { deal_id: opportunityId } })

  if (loading || !data || !data.Deal) return <div />
  return (
    <Fragment>
      <a name="section1"></a>
      <p className={[generalClasses.marginTop64, generalClasses.fontSize18, generalClasses.fontColorDark].join(' ')}>Summary</p>
      <div className={classes.informationContainer}>
        <div className={[generalClasses.flex].join(' ')}>
          <LabeledMultipleFields label="Sectors" fields={data.Deal[0].company.sectors.map(x => x.ENUM_interested_sector.description)} />
          <LabeledMultipleFields label="Innovation Enablers" fields={data.Deal[0].company.innovation_enablers.map(x => x.ENUM_innovation_enabler.description)} customStyles={{ marginLeft: "80px" }} />
        </div>
        <div className={[generalClasses.flex, generalClasses.marginTop24].join(' ')}>
          {data.Deal[0].status !== "CLOSED" && <LabeledText label="Financing Stage" text={data.Deal[0].company.funding_stage} customStyles={{ marginRight: "80px" }} />}
          {data.Deal[0].status === "ACTIVE" && <LabeledText label="Offering Type" text={data.Deal[0].offering_type === "COMBINATION" ? "Combination of primary and secondary shares" : capitalize(data.Deal[0].offering_type)} customStyles={{ marginRight: "80px" }} />}
          {data.Deal[0].status !== "PROSPECTIVE" && <LabeledText label="Proposed Valuation " text={`$${formatValue(data.Deal[0].valuation)}`} customStyles={{ marginRight: "80px" }} />}
          {data.Deal[0].status !== "PROSPECTIVE" && <LabeledText label="Offered Amount" text={`$${formatValue(data.Deal[0].investment_value)}`} customStyles={{ marginRight: "80px" }} />}
          {data.Deal[0].status !== "PROSPECTIVE" && <LabeledText label="Entity Type" text={data.Deal[0].company.entity_type} />}
          {data.Deal[0].status === "PROSPECTIVE" && <LabeledText label="Last Valuation" text={`$${formatValue(data.Deal[0].company.last_valuation)}`} />}
        </div>
        {
          data.Deal[0].fees && data.Deal[0].status !== "PROSPECTIVE" &&
          (
            <div className={[generalClasses.flex, generalClasses.marginTop24].join(' ')}>
              <LabeledText label="Fees" text={data.Deal[0].fees} />
            </div>
          )
        }
      </div>
    </Fragment>
  )
}

export default CompanyOverview;