import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { topsideMenuStyles } from './TopsideMenuStyle';
import { Button, FormControl, Select } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { debounce } from 'lodash';
import { generalStyleClasses } from '../../../../../../utils/general-styles';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_NAMES } from '../../../../../../utils/deal-queries';

const sortDetails = {
  "RECENT": { up: "Most recent", low: "Least recent", sort: (value) => `updated_at: ${value > 0 ? "asc" : "desc"}` },
  "NAME": { up: "Name A-Z", low: "Name Z-A", sort: (value) => `company: {name: ${value > 0 ? "asc" : "desc"}}` },
  "VALUATION": { up: "Lowest valuation", low: "Highest valuation", sort: (value) => `valuation: ${value > 0 ? "asc" : "desc"}` },
  "OFFERING": { up: "Lowest offering", low: "Highest offering", sort: (value) => `investment_amount_available: ${value > 0 ? "asc" : "desc"}`}
}

const mapWatchlistedCompanies = (data) => {
  const mappedData = [];
  data.forEach(dealData => {
    if(dealData.deal.company.name && mappedData.filter(x => x.id === dealData.deal.company.id).length === 0) {
      mappedData.push({id: dealData.deal.company.id, name: dealData.deal.company.name});
    }
  })
  return mappedData;
}

const TopsideMenu = ({handleNewOrder, setNewNameFilter}) => {
  const classes = topsideMenuStyles();
  const generalStyles = generalStyleClasses();
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const [orderType, setOrderType] = useState(sortDetails["RECENT"]);
  const [orderDirection, setOrderDirection] = useState(true);
  const [company, setCompany] = useState(0);
  const { url } = useRouteMatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { data } = useQuery(GET_COMPANY_NAMES, {variables: {firebase_id: firebaseId}, fetchPolicy: "network-only"});
  const [companySelection, setCompanySelection] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = debounce(value => {
    if(setNewNameFilter) {
      setNewNameFilter(value);
    }
  }, 300);


  const switchSortOrder = () => {
    setOrderDirection(!orderDirection)
  }

  useEffect(() => {
    if (handleNewOrder) {
      handleNewOrder(orderType.sort(orderDirection))
    }
  }, [orderType, orderDirection])

  useEffect(() => {
    if(data) {
      setCompanySelection(mapWatchlistedCompanies(data.Watchlist));
    }
  }, [data])
  return (
    <div className={[generalStyles.mainPageWidth, generalStyles.flexSpaceBetweenCenter].join(' ')}>
      <FormControl>
        <InputBase
          id="input-with-icon-adornment"
          inputProps={{ 'aria-label': 'naked' }}
          placeholder="Search..."
          onChange={e => handleSearchChange(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
      <div className={[classes.buttonGroup, generalStyles.flexCenterElements].join(' ')}>
        <span className={[generalStyles.fontSize16, generalStyles.fontColorDark].join(' ')} style={{ marginRight: "16px" }}>Watchlist</span>
        <div className={generalStyles.flex}>
          {/* <NavLink onClick={() => setCompany(0)} style={{ width: "100px" }} className={[classes.buttonGroupItem, generalStyles.fontSize15, generalStyles.fontColorLight].join(' ')} activeClassName={classes.activeLink} to={`${url}/all`}>
            All
          </NavLink> */}
          <span className={[generalStyles.fontSize16, generalStyles.fontColorDark, generalStyles.flexCenterElements].join(' ')} style={{marginLeft: "16px", marginRight: "16px" }}>Company: </span>
          <FormControl variant="filled" style={{ width: "200px" }}>
            <Select
              style={{ height: "42px" }}
              labelId="company-label"
              className={classes.companySelect}
              value={company}
              onChange={e => setCompany(e.target.value)}
              MenuProps={{variant: "menu"}}
            >
              <MenuItem value={0} component={Link} to={`${url}/all`}><em>All</em></MenuItem>
              {
                companySelection.map((x, index) => (
                <MenuItem key={index} value={x.id} component={Link} to={`${url}/company/${x.id}`}>{x.name}</MenuItem>)
                )
              }
            </Select>
          </FormControl>
        </div>
      </div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={[classes.dropdownButton, generalStyles.fontSize13, generalStyles.fontColorLight].join(' ')}>
        {orderDirection ? orderType.up : orderType.low}
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          setOrderType(sortDetails["RECENT"])
          handleClose();
        }} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Published date
        </MenuItem>
        <MenuItem onClick={() => {
          setOrderType(sortDetails["NAME"])
          handleClose();
        }} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Name
        </MenuItem>
        <MenuItem onClick={() => {
          setOrderType(sortDetails["VALUATION"])
          handleClose();
        }} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Valuation
        </MenuItem>
        <MenuItem onClick={() => {
          setOrderType(sortDetails["OFFERING"])
          handleClose();
        }} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Offering
        </MenuItem>
      </Menu>
      <div className={classes.sortArrows}>
        <svg onClick={() => switchSortOrder()} className={!orderDirection ? classes.activeSortArrow : ''} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.25 1.83588e-06L6 6L0.75 0L11.25 1.83588e-06Z" fill="#A9A9A9" />
        </svg>
        <svg onClick={() => switchSortOrder()} className={orderDirection ? classes.activeSortArrow : ''} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.75 6L6 4.5897e-07L11.25 6L0.75 6Z" fill="#A9A9A9" />
        </svg>
      </div>
    </div>
  );
}

export default TopsideMenu