import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import BussinessModels from '../../../../shared/InvestorPreferancesInputs/BussinessModels';
import CountryOfCompany from '../../../../shared/InvestorPreferancesInputs/CountryOfCompany';
import DevelopmentStage from '../../../../shared/InvestorPreferancesInputs/DevelopmentStage';
import FundingStage from '../../../../shared/InvestorPreferancesInputs/FundingStage';
import { UPDATE_COMPANY_INVESTOR_PREFERANCES } from '../../../../utils/investor-preferances-queries';
import { generalStyleClasses } from '../../../../utils/general-styles';
import { useMutation } from '@apollo/client';
import AdditionalInformations from '../../../../shared/InvestorPreferancesInputs/AdditionalInformation';
import { useSelector } from 'react-redux';

const Company = ({ nextStep }) => {
    const classes = generalStyleClasses();
    const [additionalInformations, setAdditionalInformations] = useState("");
    const [bussinessModels, setBussinessModels] = useState([]);
    const [countries, setCountries] = useState([]);
    const [developmentStage, setDevelopmentStage] = useState([]);
    const [fundingStage, setFundingState] = useState([]);
    const [insertInvestorPreferances] = useMutation(UPDATE_COMPANY_INVESTOR_PREFERANCES);
    const clientCreationInvestorProfileId = useSelector(store => store.adminCreateAccount.investorProfileId);
    const clientCreationId = useSelector(store => store.adminCreateAccount.accountId);

    const handleSaveChanges = () => {
        const companyInformations = {
            firebase_id: clientCreationId,
            company_business_model_B2B: bussinessModels.filter(x => x.value === 1).length > 0,
            company_business_model_B2B2C: bussinessModels.filter(x => x.value === 2).length > 0,
            company_business_model_B2C: bussinessModels.filter(x => x.value === 3).length > 0,
            company_development_stage_build_product: developmentStage.filter(x => x.value === 1).length > 0,
            company_development_stage_generates_revenue: developmentStage.filter(x => x.value === 3).length > 0,
            company_development_stage_has_product: developmentStage.filter(x => x.value === 2).length > 0,
            company_development_stage_is_profitable: developmentStage.filter(x => x.value === 4).length > 0,
            company_funding_stage_any_stage: fundingStage.filter(x => x.value === 1).length > 0,
            company_funding_stage_post_seed: fundingStage.filter(x => x.value === 2).length > 0,
            company_funding_stage_pre_ipo: fundingStage.filter(x => x.value === 3).length > 0,
            company_funding_stage_seed: fundingStage.filter(x => x.value === 4).length > 0,
            company_funding_stage_series_a: fundingStage.filter(x => x.value === 5).length > 0,
            company_funding_stage_series_b: fundingStage.filter(x => x.value === 6).length > 0,
            company_funding_stage_series_c: fundingStage.filter(x => x.value === 7).length > 0,
            company_funding_stage_series_d: fundingStage.filter(x => x.value === 8).length > 0,
            company_funding_stage_series_f: fundingStage.filter(x => x.value === 9).length > 0,
            company_funding_stage_series_e: fundingStage.filter(x => x.value === 10).length > 0,
            company_funding_stage_convertible_note: fundingStage.filter(x => x.value === 11).length > 0,
            company_funding_stage_other: fundingStage.filter(x => x.value === 12).length > 0,
            company_funding_stage_pre_seed: fundingStage.filter(x => x.value === 13).length > 0,
            company_funding_stage_series_g: fundingStage.filter(x => x.value === 14).length > 0,
            company_funding_stage_series_h: fundingStage.filter(x => x.value === 15).length > 0,
            company_funding_stage_series_i: fundingStage.filter(x => x.value === 16).length > 0,
            company_funding_stage_series_j: fundingStage.filter(x => x.value === 17).length > 0,
            company_funding_stage_series_k: fundingStage.filter(x => x.value === 18).length > 0,
            company_additional_information: additionalInformations,
            countries: countries.map(x => ({ country: x.value, profile_id: clientCreationInvestorProfileId }))
        }
        insertInvestorPreferances({ variables: companyInformations }).then(() => {
            nextStep();
        }).catch(console.log);
    }

    return (
        <Fragment>
            <BussinessModels overwriteDefaultValues={[]} handleChange={value => setBussinessModels(value)} />
            <CountryOfCompany overwriteDefaultValues={[]} handleChange={value => { setCountries(value) }} rootClasses={classes.marginTop16} />
            <DevelopmentStage overwriteDefaultValues={[]} handleChange={value => setDevelopmentStage(value)} rootClasses={classes.marginTop16} />
            <FundingStage overwriteDefaultValues={[]} handleChange={value => setFundingState(value)} values={fundingStage} enableSelectAll={true} containerStyle={{ width: '111%' }} optionalStyle={{ width: '22%', whiteSpace: 'pre' }} rootClasses={classes.marginTop16} />
            <AdditionalInformations handleChange={setAdditionalInformations} rootClasses={classes.marginTop16} />
            <div className={[classes.flexJustifyEnd, classes.marginTop32].join(' ')}>
                <Button variant="contained" color="primary" onClick={handleSaveChanges} style={{ width: "200px" }} > Next </Button>
            </div>
        </Fragment >
    )
}

export default Company;