import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useQuery } from '@apollo/client';
import { GET_PERMISSIONS_DETAILS_FOR_EMPLOYEE } from '../../queries/admin-queries';
import { Box, IconButton, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { adminDimensionsStyle, adminAlignmentStyles } from '../../AdminStyles';
import { Link } from 'react-router-dom';

const styles = makeStyles({
  container: {
    padding: "12px 4px",
    display: "flex",
    flexWrap: "wrap",
  },
  box: {
    height: "32px",
    padding: "4px 8px",
    margin: "4px",
    borderRadius: "4px",
    cursor: "pointer",
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = styles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.container}>
          {children}
        </Box>
      )}
    </div>
  );
}

const EmployeeManagementDialog = ({ open, close, employeeId }) => {
  const classes = styles();
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const {data: permissionDetails} = useQuery(GET_PERMISSIONS_DETAILS_FOR_EMPLOYEE, {variables: {firebase_id: employeeId}, fetchPolicy: "network-only"});
  const [tabValue, setTabValue] = useState(1);
  const handleClose = () => {
    close();
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ style: { padding: "24px" } }} disableScrollLock={true}>
      <DialogTitle className={[alignmentClasses.modalTitle, dimensionClasses.padding0].join(' ')}>
        Employee: {permissionDetails && (`${permissionDetails.Account[0].first_name} ${permissionDetails.Account[0].last_name}`)}
      <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={[dimensionClasses.padding0, dimensionClasses.hideOverflow].join(' ')} >
      <Tabs
          value={tabValue}
          onChange={(e, value) => setTabValue(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Deals" value={1}/>
          <Tab label="Investors" value={2}/>
        </Tabs>
        <TabPanel value={tabValue} index={1} component="div">
          {
            permissionDetails && permissionDetails.Account[0] && permissionDetails.Account[0].dealPermissions.length > 0 &&
            permissionDetails.Account[0].dealPermissions.map(dealData => (
              <Button variant="outlined" color="primary" className={classes.box} component={Link} to={`/admin/opportunities/edit-opportunity/${dealData.deal.id}`}>
                {dealData.deal.company.name}
                {dealData.deal.title && ` - ${dealData.deal.title}`}
              </Button>
            )) || <span>This employee is does not manage any deals</span>
          }
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
        {
            permissionDetails && permissionDetails.Account[0] && permissionDetails.Account[0].investorPermissions.length > 0 &&
            permissionDetails.Account[0].investorPermissions.map(accountData => (
              <Button variant="outlined" color="primary" className={classes.box} component={Link} to={`/admin/clients/view/${accountData.Account.firebase_id}`}>
                {`${accountData.Account.first_name} ${accountData.Account.last_name}`}
              </Button>
            )) || <span>This employee is does not manage any investors</span>
          }
        </TabPanel>
      </DialogContent>
      <DialogActions className={dimensionClasses.padding0}>
        <Button className={[dimensionClasses.smallHeigth].join(' ')} onClick={handleClose} variant="outlined" color="primary">
          Close
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeManagementDialog;