import React, { Fragment, useEffect, useState } from 'react';
import {
  REPLACE_RELATIONSHIP_MANAGER,
  ASSIGN_RELATIONSHIP_MANAGER,
  GET_ALL_ACCOUNTS,
  GET_RELATIONSHIP_MANAGERS_AND_ADMINS,
} from "../../queries/admin-queries";
import { useMutation, useQuery } from '@apollo/client';
import { filterByName } from './tableUtils';
import { Link } from 'react-router-dom';
import { adminAlignmentStyles, adminDimensionsStyle, adminInputStyles, adminTablesStyle, adminTypografyStyles } from '../../AdminStyles';
import { Events, EventThrower, hasuraErrorHandler } from '../../../shared/EventEmitter/EventEmitter';
import OrderButtons from '../../../shared/OrderButtons/OrderButtons';
import { Button, FormControl, MenuItem, Select } from '@material-ui/core';
import { useSelector } from 'react-redux';

const AllClientsTable = ({ nameFilter = "" }) => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const tableClasses = adminTablesStyle();
  const typografyClasses = adminTypografyStyles();
  const inputClasses = adminInputStyles();
  const {data: allRelationshipManagers} = useQuery(GET_RELATIONSHIP_MANAGERS_AND_ADMINS, {fetchPolicy: "network-only"});
  const [assignNewRelationshipManager] = useMutation(REPLACE_RELATIONSHIP_MANAGER );
  const [assignRelationshipManager] = useMutation(ASSIGN_RELATIONSHIP_MANAGER);
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const [emailOrder, setEmailOrder] = useState(null);
  const [nameOrder, setNameOrder] = useState(null);
  const [createdOrder, setCreatedOrder] = useState(null);
  const [approvedOrder, setApprovedOrder] = useState(null);
  const data = useQuery(GET_ALL_ACCOUNTS, { variables: { name: nameOrder, account_created_at: createdOrder, approved_date: approvedOrder, email: emailOrder }, fetchPolicy: "network-only" }).data;
  const [dataToShow, setDataToShow] = useState([]);
  const canAssignRM = useSelector(store => store.permissionAsignments.assign_roles);
  const canViewClients = useSelector(store => store.permissionAsignments.read_client_info);

  const getAccountStatus = (user) => {
    if(user.account.disabled) return "Account disabled";
    if (!user.account || (!user.account.city && !user.account.country && !user.account.first_name && !user.account.last_name && !user.account.phone_number)) return "Missing personal information";
    if (!user.account.city || !user.account.country || !user.account.first_name || !user.account.last_name || !user.account.phone_number) return "Incomplete personal information";
    if (user.status === "interested") return "Confidentiality agreement not accepted";
    if (user.status === "approved") {
      if (!user.account.accepted_confidentiality) return "Investor profile not created";
      if (user.account.investor_profile.length === 0) return "Investor profile not created";
      if (user.account.investor_profile.length !== 0) return "Approved investor";
      return "Account approved";
    }
  }

  useEffect(() => {
    if (data) {
      hasuraErrorHandler.checkResponseForErrors(data);
      setDataToShow(data.Whitelist);
    }
  }, [data])

  const handleAssignNewRMResult = (relationshipManagerId, oldRelationshipId, investorId ) => {
    setDataToShow(dataToShow.map(x => {
      if(x.firebase_id === investorId) {
        let newAccount = JSON.parse(JSON.stringify(x));
        newAccount.account.Relationship_managers = [{
          firebase_id: relationshipManagerId,
          id: oldRelationshipId
        }];
        return newAccount;
      }
      return x;
    }))
    EventThrower.throwClientSideMessage(Events.SuccessMessage('Relationship manager changed'))
  }

  const handleAssignRelationshipManager = (relationshipManagerId, investorId, oldRelationshipId = 0) => {
    if(oldRelationshipId) {
      assignNewRelationshipManager({variables: {
        firebase_id: relationshipManagerId,
        investor_firebase_id: investorId,
        id: oldRelationshipId,
      }}).then(() => {
        handleAssignNewRMResult(relationshipManagerId, oldRelationshipId, investorId ) 
      }).catch(console.log)
    } else {
      assignRelationshipManager({variables: {
        firebase_id: relationshipManagerId,
        investor_firebase_id: investorId,
      }}).then(result => {
        handleAssignNewRMResult(relationshipManagerId, result.data.insert_Permissions_assignment_one.id, investorId ) 
      }).catch(console.log)
    }
  }

  const tableData = dataToShow
    ? dataToShow
    .filter(x => {
      if(canAssignRM || canViewClients) return true;
      if(firebaseId === x.account.Relationship_managers[0]?.firebase_id) return true;
      return false;
    })
    .filter(x => filterByName(x, nameFilter)).map((account, index) => (
      <div className={[
        dimensionClasses.containerWidth,
        alignmentClasses.flexAlignCenter,
        tableClasses.row
      ].join(' ')} key={index} style={{ maxWidth: "none", width: "auto" }}>
        <Button component={Link} to={`/admin/clients/view/${account.firebase_id}`} className={[dimensionClasses.width200, alignmentClasses.flex, dimensionClasses.mediumHeight, alignmentClasses.flexJustifyStart, typografyClasses.fontColor0258A8, dimensionClasses.padding0].join(' ')}>
          {
            account.account && account.account.first_name && account.account.last_name
              ? `${account.account.first_name} ${account.account.last_name}`
              : "Name not provided"
          }
        </Button>
        <p style={{width: "242px", marginRight: "8px", whiteSpace: "pre", textOverflow: "ellipsis", overflow: "hidden"}}>{account.email}</p>
        <p className={dimensionClasses.width250}>{getAccountStatus(account)}</p>
        <p className={dimensionClasses.width150}>{new Date(account.created_at).toLocaleDateString()}</p>
        <p className={dimensionClasses.width150}>{new Date(account.created_at).toLocaleDateString()}</p>
        <p className={dimensionClasses.width150}>{new Date(account.approved_at).toLocaleDateString()}</p>
        <FormControl variant="outlined" className={dimensionClasses.width200}>
          <Select
            variant="outlined"
            className={[
              inputClasses.singleSelect,
              dimensionClasses.mediumHeight,
              dimensionClasses.width200,
              typografyClasses.fontSize14,
            ].join(' ')}
            disabled={!canAssignRM}
            value={account.account.Relationship_managers[0]?.firebase_id}
            onChange={e => handleAssignRelationshipManager(e.target.value, account.firebase_id, account.account.Relationship_managers[0]?.id)}
            MenuProps={{ variant: "menu", disableScrollLock: true }}>
            {
              allRelationshipManagers && allRelationshipManagers.Account.map(x => (
                <MenuItem key={x.firebase_id} value={x.firebase_id} >
                  {x.first_name} {x.last_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    )) : []

  return (
    <div className={dimensionClasses.marginTop24}>
      <div className={[
        dimensionClasses.containerWidth,
        alignmentClasses.flexAlignCenter,
        tableClasses.tableHeader,
        dimensionClasses.paddingBottom16,
        typografyClasses.fontSize14,
        dimensionClasses.headerMarginFix
      ].join(' ')} style={{ maxWidth: "none", width: "auto" }}>
        <div className={[dimensionClasses.width200, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Name
            <OrderButtons value={nameOrder} orderFunctions={[setApprovedOrder, setCreatedOrder, setEmailOrder, setNameOrder]} specificFunction={value => setNameOrder(value)} />
        </div>
        <div className={[dimensionClasses.width250, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Email
            <OrderButtons value={emailOrder} orderFunctions={[setApprovedOrder, setCreatedOrder, setEmailOrder, setNameOrder]} specificFunction={value => setEmailOrder(value)} />
        </div>
        <div className={dimensionClasses.width250}>Status</div>
        <div className={dimensionClasses.width150}>Last login</div>
        <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Account created
            <OrderButtons value={createdOrder} orderFunctions={[setApprovedOrder, setCreatedOrder, setEmailOrder, setNameOrder]} specificFunction={value => setCreatedOrder(value)} />
        </div>
        <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Approved date
            <OrderButtons value={approvedOrder} orderFunctions={[setApprovedOrder, setCreatedOrder, setEmailOrder, setNameOrder]} specificFunction={value => setApprovedOrder(value)} />
        </div>
        <div className={dimensionClasses.width200}>Relationship manager</div>
      </div>
      <div>
        {tableData.length === 0 && "You do not have any clients to manage"}
        {tableData}
      </div>
    </div>
  )
}

export default AllClientsTable;