
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { addInvestmentFlowActions } from '../../store/utils/action-types';
import { adminStyles, adminAlignmentStyles } from '../AdminStyles';
import AddInvestmentFlow from './AddInvestmentFlow';
import Documents from './Documents';
import FlowTemplatesList from './FlowTemplatesList';
import { AddFlowHeader, ClientFlowHeader, DefaultInvestmentHeader } from './Headers';

const AdminInvestmentPanel = () => {
  const adminClasses = adminStyles();
  const alignmentClasses = adminAlignmentStyles();
  const { url } = useRouteMatch();
  const [investmentId, setInvestmentId] = useState(0);
  const dispatch = useDispatch()
  const handleAddStep = () => {
    dispatch({type: addInvestmentFlowActions.CREATE_STEP})
  }

  return (
    <div style={{marginTop: "63px"}}>
      <div className={adminClasses.headerContainer}>
        <div className={adminClasses.header}>
        {
          window.location.href.indexOf("client-documents") !== -1 
          ? <ClientFlowHeader addStep={() => handleAddStep()} investmentId={investmentId}/>
          : (
            window.location.href.indexOf("add-flow") !== -1 || window.location.href.indexOf("edit-flow") !== -1 || window.location.href.indexOf("edit-deal-flow") !== -1
            ?  <AddFlowHeader addStep={() => handleAddStep()}/>
            : <DefaultInvestmentHeader />
          )
          }
        </div>
      </div>
      <div className={[adminClasses.contentContainer, alignmentClasses.flexAlignCenterOncolumn].join(' ')} >
        <Switch>
          <Route path={`${url}/documents`} component={() => <Documents />} />
          <Route path={`${url}/client-documents/:dealId/:flowId/:investmentId`} component={() => <AddInvestmentFlow isClient={true} setInvestmentId={value => setInvestmentId(value)}/>} />
          <Route path={`${url}/flow-list`} component={() => <FlowTemplatesList />} />
          <Route path={`${url}/add-flow`} component={() => <AddInvestmentFlow />} />
          <Route path={`${url}/edit-flow/:flowId`} component={() => <AddInvestmentFlow />} />
          <Route path={`${url}/edit-deal-flow/:flowId/:dealId`} component={() => <AddInvestmentFlow />} />
          <Route path="*" component={() => <Redirect to={`${url}/documents`} />} />
        </Switch>
      </div>
    </div>
  )
}

export default AdminInvestmentPanel;