import React, { Fragment, useEffect, useState } from 'react';
import TextInput from '../../../shared/TextInput/TextInput';
import { adminAlignmentStyles, adminDimensionsStyle, adminInputStyles, adminTypografyStyles } from '../../AdminStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useQuery } from '@apollo/client';
import { GET_ALL_NOTABLE_INVESTORS } from '../../queries/admin-queries';
const ManagementCard = ({ management, handleDelete, handleEdit }) => {
  const dimensionClasses = adminDimensionsStyle();
  const typografyClasses = adminTypografyStyles();
  const alignmentClasses = adminAlignmentStyles();
  return (
    <Fragment>
      <div className={[dimensionClasses.marginTop8, alignmentClasses.flexWrap ].join(' ')}>
        <div className={[dimensionClasses.width150, typografyClasses.fontSize14, typografyClasses.fontColor373737].join(' ')}>{management.name}</div>
        <div className={[dimensionClasses.width200, typografyClasses.fontSize14, typografyClasses.fontColor747474, dimensionClasses.marginLeft16].join(' ')}>{management.position}</div>
        <IconButton onClick={handleEdit} className={[dimensionClasses.padding0, dimensionClasses.marginLeft16].join(' ')}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDelete} className={[dimensionClasses.padding0].join(' ')}>
          <DeleteIcon />
        </IconButton>
      </div>
      <div className={[typografyClasses.fontColor4A4A4A, typografyClasses.fontSize12].join(' ')} style={{ maxWidth: "420px" }}>
        {management.description}
      </div>
    </Fragment>
  )
}

const AddManagement = ({initialValue = [], handleNewManagement}) => {
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const typografyClasses = adminTypografyStyles();
  const inputClasses = adminInputStyles();
  const {data: notableInvestorsData} = useQuery(GET_ALL_NOTABLE_INVESTORS, {fetchPolicy: 'network-only'});
  const [name, setName] = useState("")
  const [position, setPosition] = useState("");
  const [description, setDescription] = useState("");
  const [management, setManagement] = useState([]);
  const [isEdit, setIsEdit] = useState(-1)

  const addManagement = () => {
    if(name !== "" && position !== "") {
      if(isEdit === -1) {
        const newManagement = [...management, { name: name, position: position, description: description }];
        setManagement(newManagement);
      } else {
        setManagement(management.map((x, index) => {
          if(index === isEdit) {
            return { 
              name: name, 
              position: position, 
              description: description 
            }
          }
          return x;
        }))
      }
      setName("");
      setDescription("");
      setPosition("");
      setIsEdit(-1)
    }
  }

  const deleteManagement = (position) => {
    setManagement(management.filter((x, index) => index !== position));
  }

  const handleEdit = (management, index) => {
    setName(management.name);
    setPosition(management.position);
    setDescription(management.description);
    setIsEdit(index);
  }

  useEffect(() => {
    if(initialValue && initialValue.length > 0) {
      setManagement(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if(handleNewManagement) {
      handleNewManagement(management);
    }
  }, [management])

  return (
    <Fragment>
      <div className={[alignmentClasses.flexWrap, alignmentClasses.flexAlignCenter, dimensionClasses.marginTop24].join(' ')}>
        <span className={[typografyClasses.fontSize14, typografyClasses.fontbold].join(' ')} style={{ color: "#929292" }}>Management</span>
        <p className={[dimensionClasses.widthGrow, dimensionClasses.marginLeft16, dimensionClasses.marginTop0, dimensionClasses.marginBottom0].join(' ')} style={{ border: "1px solid #EBEBEB" }} ></p>
      </div>
      <div className={[dimensionClasses.marginTop24, dimensionClasses.width100Percent, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
        <TextInput label="Full Name" className="small36-input-field" initialValue={name} handleChange={setName} customStyle={{ width: "436px" }} />
        <TextInput label="Position" className="small36-input-field" initialValue={position} handleChange={setPosition} customStyle={{ width: "436px" }} />

      
      </div>
      <div className={dimensionClasses.marginTop16}>
        <p className={[typografyClasses.fontSize14, dimensionClasses.marginTop0, dimensionClasses.marginBottom8].join(' ')} style={{ color: "#796E6E" }}>Short bio</p>
        <div className={[dimensionClasses.width100Percent, inputClasses.textAreaContainer].join(' ')}>
          <textarea value={description} onChange={e => setDescription(e.target.value)} />
        </div>
      </div>
      <div className={alignmentClasses.flex} style={{ justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" style={{ marginLeft: "16px" }} className={dimensionClasses.mediumHeight} onClick={() => addManagement()}>
          <AddIcon />
            {isEdit === -1 ? 'Add' : 'Update'} management
          </Button>
      </div>
      <div>
        {
          management.map((x, index) => <ManagementCard key={index} management={x} handleDelete={() => deleteManagement(index)} handleEdit={() => handleEdit(x, index)} />)
        }
      </div>
    </Fragment>
  )
}

export default AddManagement;