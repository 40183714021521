import React, { useEffect, useState } from 'react';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from '../AdminStyles';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { GET_ALL_FLOWS, GET_FLOW_FOR_CLONE, GET_FLOW_SECTION_IDS } from '../queries/admin-queries';
import Loading from '../../shared/Loading/Loading';
import { useLazyQuery } from '@apollo/client';
import { deleteEdges, saveGeneralFlowTemplate } from '../queries/admin-http-calls';
import { Events, EventThrower } from '../../shared/EventEmitter/EventEmitter';
import { mapFlowForClone, mapFlowToEdges } from './flowMapper';
import { Fragment } from 'react';
import TextInput from '../../shared/TextInput/TextInput';

export const FlowTemplatesList = () => {
  const tableClasses = adminTablesStyle();
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const typographyClasses = adminTypografyStyles();
  const [cloneFlowInitial, setCloneFlowInitial] = useState(null);
  const [newFlowName, setNewFlowName] = useState("");
  const [getFlowSectionIds, { data: deleteFlowEdgesData, loading: deleteFlowEdgesLoading }] = useLazyQuery(GET_FLOW_SECTION_IDS);
  const [getFlowForClone, { data: cloneFlowData, loading: cloneFlowLoading }] = useLazyQuery(GET_FLOW_FOR_CLONE);
  const [getAllFlows, { data, loading }] = useLazyQuery(GET_ALL_FLOWS, { fetchPolicy: "network-only" });

  const handleDeleteButton = (flowId) => {
    getFlowSectionIds({ variables: { flow_id: flowId } });
  }

  const handleCloneButton = (flowId) => {
    if(flowId) {
      getFlowForClone({ variables: { id: flowId } });
    }
  }

  useEffect(() => {
    getAllFlows();
  }, [])

  useEffect(() => {
    setCloneFlowInitial(null);
  }, [data])
  
  useEffect(() => {
    if (cloneFlowData && cloneFlowData.Flow_node_by_pk) {
      saveGeneralFlowTemplate(mapFlowForClone(cloneFlowData.Flow_node_by_pk, newFlowName))
        .then(resp => resp.json())
        .then(result => {
          if (result.status === "OK") {
            getAllFlows();
          }
        }).catch(console.log)
      
    }
  }, [cloneFlowData, cloneFlowLoading])

  useEffect(() => {
    if (deleteFlowEdgesData) {
      const edges = mapFlowToEdges(deleteFlowEdgesData)
      deleteEdges(edges.sectionEdges, edges.itemEdges).then(() => {
        getAllFlows();
        EventThrower.throwClientSideMessage(Events.SuccessMessage("General flow deleted"));
      }).catch(console.log);
    }
  }, [deleteFlowEdgesData, deleteFlowEdgesLoading])

  if (loading) return <Loading fullWidth />

  return (
    <Fragment>
    <div className={dimensionClasses.headerMarginFix}>
      <Button color="primary" variant="outlined" className={[dimensionClasses.mediumHeight, dimensionClasses.marginTop12].join(' ')} component={Link} to="/admin/investment/add-flow">
        <AddIcon className={dimensionClasses.marginRight12} />
        Add Investment Flow
      </Button>
      <div className={[dimensionClasses.containerWidth, alignmentClasses.flexAlignCenter, tableClasses.tableHeader, typographyClasses.fontSize14, dimensionClasses.marginTop24].join(' ')}>
        <div className={dimensionClasses.widthGrow} style={{ minWidth: "400px" }}>Name</div>
        <div className={dimensionClasses.width200}>Created</div>
        <div className={dimensionClasses.width200}>Updated</div>
        <div className={dimensionClasses.width150}>Actions</div>
      </div>
      {
        data && data.Flow_node && data.Flow_node.map((flow, index) => (
          <div key={index} className={[dimensionClasses.containerWidth, alignmentClasses.flexAlignCenter, tableClasses.row, typographyClasses.fontSize14].join(' ')}>
            <p className={dimensionClasses.widthGrow}>{flow.name}</p>
            <p className={dimensionClasses.width200}>{new Date(flow.created_at).toLocaleDateString()}</p>
            <p className={dimensionClasses.width200}>{new Date(flow.updated_at).toLocaleDateString()}</p>
            <div className={dimensionClasses.width150}>
              <Tooltip arrow title="Clone">
                <IconButton style={{ padding: "4px", marginLeft: "8px" }} onClick={() => setCloneFlowInitial({name: flow.name, id: flow.id})}>
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title="Edit">
                <IconButton style={{ padding: "4px", marginLeft: "8px" }} component={Link} to={`/admin/investment/edit-flow/${flow.id}`}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title="Delete">
                <IconButton style={{ padding: "4px", marginLeft: "8px" }} onClick={() => handleDeleteButton(flow.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ))
      }
    </div>
      <Dialog onClose={() => setCloneFlowInitial(null)} open={cloneFlowInitial !== null} style={{padding: "24px"}}>
      <DialogTitle onClose={() => setCloneFlowInitial(null)} style={{padding: "0px"}}>
        Name of the {cloneFlowInitial?.name} flow's clone:
      </DialogTitle>
      <DialogContent style={{padding: "0px"}}>
        <TextInput initialValue={newFlowName} handleChange={value => setNewFlowName(value)} />
      </DialogContent>
      <DialogActions style={{padding: "0px", marginTop: "24px"}}>
      <Button variant="outlined" color="primary" style={{height: "36px"}} onClick={() => setCloneFlowInitial(null)}>
          Close
        </Button>
        <Button variant="contained" color="primary" style={{height: "36px"}} onClick={() => handleCloneButton(cloneFlowInitial?.id)}>
          Clone 
        </Button>
      </DialogActions>
    </Dialog>
    </Fragment>
  )
}

export default FlowTemplatesList;