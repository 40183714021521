import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import BussinessModels from '../../shared/InvestorPreferancesInputs/BussinessModels';
import CountryOfCompany from '../../shared/InvestorPreferancesInputs/CountryOfCompany';
import DevelopmentStage from '../../shared/InvestorPreferancesInputs/DevelopmentStage';
import FundingStage from '../../shared/InvestorPreferancesInputs/FundingStage';
import { UPDATE_COMPANY_INVESTOR_PREFERANCES } from '../../utils/investor-preferances-queries';
import { generalStyleClasses } from '../../utils/general-styles';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import AdditionalInformations from '../../shared/InvestorPreferancesInputs/AdditionalInformation';
import { SET_INVESTOR_PROFILE_COMPANY } from '../../store/utils/action-types';

const Company = ({ nextStep, handleSkip }) => {
    const history = useHistory();
    const classes = generalStyleClasses();
    const [additionalInformations, setAdditionalInformations] = useState("");
    const [bussinessModels, setBussinessModels] = useState([]);
    const [countries, setCountries] = useState([]);
    const [developmentStage, setDevelopmentStage] = useState([]);
    const [fundingStage, setFundingState] = useState([]);
    const [insertInvestorPreferances] = useMutation(UPDATE_COMPANY_INVESTOR_PREFERANCES);
    const account = useSelector(store => store.account.user);
    const investorProfileId = useSelector(store => store.account.investorProfileId || store.account.user?.investor_profile && store.account.user?.investor_profile[0]?.id);
    const dispatch = useDispatch();
    const handleSaveChanges = () => {
        const companyInformations = {
            firebase_id: account.firebase_id,
            company_business_model_B2B: bussinessModels.filter(x => x.value === 1).length > 0,
            company_business_model_B2B2C: bussinessModels.filter(x => x.value === 2).length > 0,
            company_business_model_B2C: bussinessModels.filter(x => x.value === 3).length > 0,
            company_development_stage_build_product: developmentStage.filter(x => x.value === 1).length > 0,
            company_development_stage_generates_revenue: developmentStage.filter(x => x.value === 3).length > 0,
            company_development_stage_has_product: developmentStage.filter(x => x.value === 2).length > 0,
            company_development_stage_is_profitable: developmentStage.filter(x => x.value === 4).length > 0,
            company_funding_stage_any_stage: fundingStage.filter(x => x.value === 11).length > 0,
            company_funding_stage_post_seed: fundingStage.filter(x => x.value === 2).length > 0,
            company_funding_stage_pre_ipo: fundingStage.filter(x => x.value === 10).length > 0,
            company_funding_stage_seed: fundingStage.filter(x => x.value === 1).length > 0,
            company_funding_stage_series_a: fundingStage.filter(x => x.value === 3).length > 0,
            company_funding_stage_series_b: fundingStage.filter(x => x.value === 4).length > 0,
            company_funding_stage_series_c: fundingStage.filter(x => x.value === 5).length > 0,
            company_funding_stage_series_d: fundingStage.filter(x => x.value === 6).length > 0,
            company_funding_stage_series_e: fundingStage.filter(x => x.value === 7).length > 0,
            company_funding_stage_series_f: fundingStage.filter(x => x.value === 8).length > 0,
            company_additional_information: additionalInformations,
            countries: countries.map(x => ({ country: x.value, profile_id: investorProfileId }))
        }
        insertInvestorPreferances({ variables: companyInformations }).then(() => {
            dispatch({
                type: SET_INVESTOR_PROFILE_COMPANY, payload: {
                    company_business_model_B2B: bussinessModels.filter(x => x.value === 1).length > 0,
                    company_business_model_B2B2C: bussinessModels.filter(x => x.value === 2).length > 0,
                    company_business_model_B2C: bussinessModels.filter(x => x.value === 3).length > 0,
                    company_development_stage_build_product: developmentStage.filter(x => x.value === 1).length > 0,
                    company_development_stage_generates_revenue: developmentStage.filter(x => x.value === 3).length > 0,
                    company_development_stage_has_product: developmentStage.filter(x => x.value === 2).length > 0,
                    company_development_stage_is_profitable: developmentStage.filter(x => x.value === 4).length > 0,
                    company_funding_stage_any_stage: fundingStage.filter(x => x.value === 11).length > 0,
                    company_funding_stage_post_seed: fundingStage.filter(x => x.value === 2).length > 0,
                    company_funding_stage_pre_ipo: fundingStage.filter(x => x.value === 10).length > 0,
                    company_funding_stage_seed: fundingStage.filter(x => x.value === 1).length > 0,
                    company_funding_stage_series_a: fundingStage.filter(x => x.value === 3).length > 0,
                    company_funding_stage_series_b: fundingStage.filter(x => x.value === 4).length > 0,
                    company_funding_stage_series_c: fundingStage.filter(x => x.value === 5).length > 0,
                    company_funding_stage_series_d: fundingStage.filter(x => x.value === 6).length > 0,
                    company_funding_stage_series_e: fundingStage.filter(x => x.value === 7).length > 0,
                    company_funding_stage_series_f: fundingStage.filter(x => x.value === 8).length > 0,
                    company_additional_information: additionalInformations,
                    countries: countries
                }
            })
            nextStep();
            history.push("/investor-preferences/investment");
        }).catch(console.log);
    }

    return (
        <Fragment>
            <BussinessModels handleChange={value => setBussinessModels(value)} />
            <CountryOfCompany handleChange={value => { setCountries(value) }} rootClasses={classes.marginTop32} />
            <DevelopmentStage handleChange={value => setDevelopmentStage(value)} rootClasses={classes.marginTop32} />
            <FundingStage handleChange={value => setFundingState(value)} containerStyle={{ width: '111%' }} optionalStyle={{ width: '22%', whiteSpace: 'pre' }} rootClasses={classes.marginTop32} />
            <AdditionalInformations handleChange={setAdditionalInformations} rootClasses={classes.marginTop32} />
            <div className={[classes.dualLinkButtonsGroup, classes.marginTop32].join(' ')}>
                <Button variant="outlined" color="primary" onClick={() => handleSkip()} > Skip </Button>
                <Button variant="contained" color="primary" onClick={handleSaveChanges} > Continue </Button>
            </div>
        </Fragment >
    )
}

export default Company;