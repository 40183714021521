import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { capitalize, formatValue } from '../../../../../utils/formats';
import { generalStyleClasses } from '../../../../../utils/general-styles';
import LabeledText from '../../../../../shared/LabeledText/LabeledText';
import { GET_COMPANY_OVERVIEW_BY_ID } from '../../../../queries/preview-queries';
import { useSelector } from 'react-redux';
import LabeledMultipleFields from '../../../../../shared/LabeledMultipleFields/LabeledMultipleFields';

const styles = makeStyles({
  informationContainer: {
    display: "flex",
    background: "#F9F9F9",
    border: "1px solid #F4F4F4",
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "16px",
    flexDirection: "column",
  }
})

const CompanyOverview = ({ companyId }) => {
  const classes = styles();
  const generalClasses = generalStyleClasses();
  const { data, loading } = useQuery(GET_COMPANY_OVERVIEW_BY_ID, { variables: { id: companyId } });
  const offering_type = useSelector(store => capitalize(store.adminOpportunity.resultingOpportunity.offeringType))
  const fees = useSelector(store => store.adminOpportunity.resultingOpportunity.fees)
  const status = useSelector(store => store.adminOpportunity.resultingOpportunity.type)
  const totalOfferedAmount = useSelector(store => store.adminOpportunity.resultingOpportunity.totalOfferedAmount)
  const valuation = useSelector(store => store.adminOpportunity.resultingOpportunity.valuation)
  if (loading || !data || !data.Company_by_pk) return <div />

  return (
    <Fragment>
      <a name="section1"></a>
      <p className={[generalClasses.marginTop64, generalClasses.fontSize18, generalClasses.fontColorDark].join(' ')}>Overview</p>
      <div className={classes.informationContainer}>
        <div className={[generalClasses.flex].join(' ')}>
          <LabeledMultipleFields label="Sectors" fields={data.Company_by_pk.sectors.map(x => x.ENUM_interested_sector.description)} />
          <LabeledMultipleFields label="Innovation Enablers" fields={data.Company_by_pk.innovation_enablers.map(x => x.ENUM_innovation_enabler.description)} customStyles={{ marginLeft: "80px" }} />
        </div>
        <div className={[generalClasses.flex, generalClasses.marginTop24].join(' ')}>
          {status !== "CLOSED" && <LabeledText label="Financing Stage" text={data.Company_by_pk.funding_stage} customStyles={{ marginRight: "80px" }} />}
          {status === "ACTIVE" && <LabeledText label="Offering Type" text={offering_type === "COMBINATION" ? "Combination of primary and secondary shares" : capitalize(offering_type)} customStyles={{ marginRight: "80px" }} />}
          {status !== "PROSPECTIVE" && <LabeledText label="Proposed Valuation " text={`$${formatValue(valuation)}`} customStyles={{ marginRight: "80px" }} />}
          {status !== "PROSPECTIVE" && <LabeledText label="Offered Amount" text={`$${formatValue(totalOfferedAmount)}`} customStyles={{ marginRight: "80px" }} />}
          {status !== "PROSPECTIVE" && <LabeledText label="Entity Type" text={data.Company_by_pk.entity_type} />}
          {status === "PROSPECTIVE" && <LabeledText label="Last Valuation" text={`$${formatValue(data.Company_by_pk.last_valuation)}`} />}

        </div>
        {
          fees && status !== "PROSPECTIVE" &&
          (
            <div className={[generalClasses.flex, generalClasses.marginTop24].join(' ')}>
              <LabeledText label="Fees" text={fees} />
            </div>
          )
        }
      </div>
    </Fragment>
  )
}

export default CompanyOverview;