import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generalStyleClasses } from '../../utils/general-styles';
import CheckboxesGroup from '../CheckboxesGroup/CheckboxesGroup';
import { developmentStage } from './Options/CompanyOptions';

const DevelopmentStage = ({ handleChange, rootClasses={}, overwriteDefaultValues }) => {
  const classes = generalStyleClasses();
  const storeValues = useSelector(store => {
    let selectedValues = [];
    if(store.investorProfile.company_development_stage_build_product) {
      selectedValues.push({value: 1})
    }
    if(store.investorProfile.company_development_stage_has_product) {
      selectedValues.push({value: 2})
    }
    if(store.investorProfile.company_development_stage_generates_revenue) {
      selectedValues.push({value: 3})
    }
    if(store.investorProfile.company_development_stage_is_profitable) {
      selectedValues.push({value: 4})
    }
    return selectedValues;
  })
  const [defaultValues] = useState(overwriteDefaultValues || storeValues);

  useEffect(() => {
    if(defaultValues && defaultValues.length > 0) {
      handleChange(defaultValues);
    }
  }, [defaultValues])

  return (
    <div className={rootClasses}>
      <span className={[classes.fontSize14, classes.fontInputColor].join(' ')}>Development Stage</span>
      <div className={classes.marginTop16}>
        <CheckboxesGroup optionsInput={developmentStage} optionsStyle={{ minWidth: '165px' }} handleChange={handleChange} defaultValues={defaultValues}/>
      </div>
    </div>
  )
}

export default DevelopmentStage;