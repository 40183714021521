
export const filterByName = (account, nameFilter) => {
  if(!nameFilter || !account.account) return true;
  if(nameFilter && (!account.account.first_name || !account.account.last_name || !account.account.email)) return false;
  return account != null && account.account != null &&
    (account.account.first_name !== null && account.account.last_name !== null) &&
    (nameFilter === "" ||
      account.email.toLowerCase().search(nameFilter.toLowerCase()) > -1 ||
      account.account.first_name.toLowerCase().search(nameFilter.toLowerCase()) > -1 ||
      account.account.last_name.toLowerCase().search(nameFilter.toLowerCase()) > -1)
}