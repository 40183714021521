import { OutlinedInput, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { adminAlignmentStyles, adminDimensionsStyle, adminStyles, adminTypografyStyles } from '../AdminStyles';
import EmployeesManagement from './EmployeeManagement/EmployeesManagement';

const AdminPanel = () => {
  const adminClasses = adminStyles();
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const typografyStyles = adminTypografyStyles();
  const canAccess = useSelector(store => store.permissionAsignments.manage_roles);
  const [nameFilter, setNameFilter] = useState("");
  const { url } = useRouteMatch();

  if(!canAccess) return <div style={{marginTop: "80px", textAlign: "center"}}>You do not have permission to view the admin tab</div>
  return (
    <div style={{marginTop: "63px"}}>
      <div className={adminClasses.headerContainer}>
        <div className={adminClasses.header}>
          <div className={alignmentClasses.flex}>
            <NavLink className={[
              adminClasses.navLinkButton, 
              dimensionClasses.smallHeigth, 
              alignmentClasses.flexAlignCenter, 
              typografyStyles.fontSize14, 
              typografyStyles.fontColor7B7B7B].join(' ')} activeClassName={adminClasses.navLinkActiveButton} to={`${url}/employees`}>Employees Management</NavLink>
          </div>
          <OutlinedInput
            placeholder="Search..."
            variant="outlined"
            className={adminClasses.searchField}
            value={nameFilter}
            onChange={e => setNameFilter(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </div>
      </div>
      <div className={[adminClasses.contentContainer, alignmentClasses.flexAlignCenterOncolumn].join(' ')} >
        <Switch>
          <Route path="/admin/admin/employees" component={() => <EmployeesManagement nameFilter={nameFilter}/>} />
          <Route path="/admin/admin/" component={() => <Redirect to="/admin/admin/employees" />} />
        </Switch>
      </div>
    </div>
  )
}

export default AdminPanel;