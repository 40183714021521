export const DealSectionType = {
  RICH_TEXT_AREA: "RICH_TEXT_AREA" ,
  OVERVIEW: "OVERVIEW" ,
  DESCRIPTION: "DESCRIPTION" ,
  MANAGEMENT: "MANAGEMENT" ,
  FUNDING: "FUNDING", 
  TABLES: "TABLES" ,
  FILES: "FILES" ,
  LINKS: "LINKS" ,
  IMAGES: "IMAGES"
} 