import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { timingReducerActions, packTimingParams } from '../../store/slices/timer';
import { insertAnalytics } from '../../utils/analytics-queries';

export const TimingSetup = () => {
  const dispatch = useDispatch();
  const toLog = useSelector(store => store.timing.toLog);
  const timers = useSelector(store => store.timing.timers);

  useEffect(() => {
    if(toLog && toLog.length){
      for (let index = 0; index < toLog.length; index++) {
        const timerToLog = toLog[index];
        insertAnalytics(timerToLog);
      }
      dispatch({type: timingReducerActions.TIMING_LOGGING_DONE});
    }
  }, [toLog, dispatch]);
  
  useEffect(() => {
    if(timers && timers.length){
      const handleUnload = (...args) => {
        const promises = [];
        timers.map(timerParams => packTimingParams(timerParams)).forEach(timerToLog => promises.push(insertAnalytics(timerToLog, true)));
        Promise.all(promises).then(() => {
        }).catch(err => {
          console.error(err);
        });
      }
      window.addEventListener('beforeunload', handleUnload);
      return () => window.removeEventListener('beforeunload', handleUnload);
    }
    return () => {};
  }, [timers])

  return null;
}

/* 
Example How To Use: 
  useTiming({
    firebaseId: firebaseId || undefined,
    companyId: (dealData && dealData.Deal_by_pk && dealData.Deal_by_pk.company_id) || undefined,
    dealId: oportunityId || undefined,
    event: "DEAL_DETAILED_VIEW_SESSION",
    payload: "",
  })
*/
export const useTiming = ({ payload, dealId, companyId, event }) => {
  const dispatch = useDispatch();
  const firebaseId = useSelector(store => store.account.user.firebase_id)
  const time = useRef(0)
  useEffect(() => {
    if (payload !== undefined && dealId !== undefined && companyId !== undefined && event !== undefined && firebaseId !== undefined) {
      time.current = Date.now();
      dispatch({type: timingReducerActions.TIMING_START_TIMER, payload:{firebaseId, companyId, dealId, event}});
      return () => {
        dispatch({type: timingReducerActions.TIMING_END_TIMER, payload:{firebaseId, companyId, dealId, event}});
      }
    }
  }, [payload, dealId, companyId, event, firebaseId, dispatch]);

  return () => {};
}
