import { Button } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Link, NavLink, useHistory, useRouteMatch } from "react-router-dom";
import {
  adminStyles,
  adminAlignmentStyles,
  adminDimensionsStyle,
  adminTypografyStyles,
} from "../AdminStyles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddIcon from "@material-ui/icons/Add";
import store from "../../store/store";
import { mapNewFlow } from "./flowMapper";
import {
  deleteSectionEdges,
  saveGeneralFlowTemplate,
} from "../queries/admin-http-calls";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  UPDATE_SECTION_ORDER_BY_EDGE,
  MARK_INVESTMENT_COMPLETED,
  UPDATE_ITEM_ORDER_BY_EDGE,
  GET_INVESTMENT_HEADER_DATA,
} from "../queries/admin-queries";
import { addDocumentsForFlowEdge } from "../../utils/file-queries";
import { useDispatch, useSelector } from "react-redux";
import { addInvestmentFlowActions } from "../../store/utils/action-types";
import {
  Events,
  EventThrower,
  hasuraErrorHandler,
} from "../../shared/EventEmitter/EventEmitter";

export const DefaultInvestmentHeader = () => {
  const adminClasses = adminStyles();
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const typografyclasses = adminTypografyStyles();
  const { url } = useRouteMatch();
 
  return (
    <Fragment>
      <div className={alignmentClasses.flex}>
        <NavLink
          className={[
            adminClasses.navLinkButton,
            dimensionClasses.smallHeigth,
            alignmentClasses.flexAlignCenter,
            typografyclasses.fontSize14,
            typografyclasses.fontColor7B7B7B,
          ].join(" ")}
          activeClassName={adminClasses.navLinkActiveButton}
          to={`${url}/documents`}
        >
          Documents
        </NavLink>
        <NavLink
          className={[
            adminClasses.navLinkButton,
            dimensionClasses.smallHeigth,
            alignmentClasses.flexAlignCenter,
            typografyclasses.fontSize14,
            typografyclasses.fontColor7B7B7B,
          ].join(" ")}
          activeClassName={adminClasses.navLinkActiveButton}
          to={`${url}/flow-list`}
        >
          Flow Templates
        </NavLink>
      </div>
    </Fragment>
  );
};

export const AddFlowHeader = ({ addStep }) => {
  const dimensionClasses = adminDimensionsStyle();
  const typografyclasses = adminTypografyStyles();
  const disableSaveButton = useSelector(store => store.adminAddInvestorFlowState.steps.length === 0)
  const updateSectionOrder = useMutation(UPDATE_SECTION_ORDER_BY_EDGE)[0];
  const updateItemOrder = useMutation(UPDATE_ITEM_ORDER_BY_EDGE)[0];
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAddStep = () => {
    if (addStep) {
      dispatch({ type: addInvestmentFlowActions.CREATE_STEP });
    }
  };

  const handleSaveFlow = async () => {
    const flow = store.getState().adminAddInvestorFlowState;
    const mappedFlow = mapNewFlow(flow);
    await deleteSectionEdges(flow.deletedSectionEdges)
    await saveGeneralFlowTemplate(mappedFlow);


    if (flow.steps.filter((step) => step.edgeId).length > 0) {
      await Promise.all([
        flow.steps
          .filter((step) => step.edgeId)
          .reduce(async (previousValue, currentValue) => {
            await Promise.all([
              currentValue.parts
                .filter((part) => part.edgeId)
                .reduce((previousPart, currentPart, index) => {
                  return previousPart.then(() => updateItemOrder({ variables: { id: currentPart.edgeId, order: currentPart.order || index } }))
                }, Promise.resolve(true)),
            ])
            return previousValue.then(() => updateSectionOrder({ variables: { id: currentValue.edgeId, order: currentValue.order } }))
          }, Promise.resolve(true)),
          flow.steps
          .filter((step) => step.edgeId)
          .forEach(async (step) =>  {
            await Promise.all([
              step.parts
                .filter((x) => x.type === "DOWNLOAD" && x.edgeId && x.data)
                .reduce((previousValue, currentValue) => {
                  const files = currentValue.data.filter((x) => x instanceof File);
                  return previousValue.then(() =>
                    files.length > 0 ? addDocumentsForFlowEdge(files, null, currentValue.edgeId, flow.dealId).catch(() => hasuraErrorHandler.printError("File upload error")) : Promise.resolve(true));
                }, Promise.resolve(true))
            ])
          })
      ])
      EventThrower.throwClientSideMessage(
        Events.SuccessMessage("Investment updated")
      );
      history.goBack();
    } else {
      EventThrower.throwClientSideMessage(
        Events.SuccessMessage("Investment updated")
      );
      history.push("/admin/investment/documents");
    }

  };

  return (
    <Fragment>
      <div>
        <Button
          onClick={() => history.goBack()}
          className={[
            dimensionClasses.smallHeigth,
            typografyclasses.fontSize16,
            typografyclasses.noTrasnform,
            typografyclasses.fontColor78756F,
          ].join(' ')}
        >
          <KeyboardBackspaceIcon />
          Back
        </Button>
        <span>Investment Flow</span>
      </div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          className={dimensionClasses.smallHeigth}
          onClick={() => handleAddStep()}
        >
          <AddIcon style={{ marginRight: "8px" }} />
          Add step
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={[
            dimensionClasses.smallHeigth,
            dimensionClasses.marginLeft16,
          ].join(" ")}
          disabled={disableSaveButton}
          onClick={() => handleSaveFlow()}
        >
          Save changes
        </Button>
      </div>
    </Fragment>
  );
};

export const ClientFlowHeader = ({ addStep, investmentId }) => {
  const dimensionClasses = adminDimensionsStyle();
  const typografyclasses = adminTypografyStyles();
  const updateSectionOrder = useMutation(UPDATE_SECTION_ORDER_BY_EDGE)[0];
  const markCompleted = useMutation(MARK_INVESTMENT_COMPLETED)[0];
  const [getInvestmentHeaderData, getInvestmentHeaderDataResult] = useLazyQuery(GET_INVESTMENT_HEADER_DATA);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleAddStep = () => {
    if (addStep) {
      addStep();
    }
  };
  const flowCompleted = useSelector(
    (store) => store.adminAddInvestorFlowState.investmentCompleted
  );

  const handleSaveFlow = async () => {
    const flow = store.getState().adminAddInvestorFlowState;
    const mappedFlow = mapNewFlow(flow);
    await deleteSectionEdges(flow.deletedSectionEdges)
    await saveGeneralFlowTemplate(mappedFlow)
    if (flow.investmentId) {
      flow.steps.forEach(async (step) => {
        await Promise.all([
          step.parts
            .filter((x) => x.type === "DOWNLOAD" && x.edgeId && x.data)
            .reduce((previousValue, currentValue) => {
              const files = currentValue.data.filter((x) => x instanceof File);
              return previousValue.then(() =>
                files.length > 0 ? addDocumentsForFlowEdge(files, flow.investmentId, currentValue.edgeId).catch(() => hasuraErrorHandler.printError("File upload error")) : Promise.resolve(true));
            }, Promise.resolve(true))
        ])
      });
    }
    await Promise.all([
      flow.steps
        .filter((step) => step.edgeId)
        .reduce((previousValue, currentValue) =>
          previousValue.then(() => updateSectionOrder({ variables: { id: currentValue.edgeId, order: currentValue.order, completed: !!currentValue.completed, current: !!currentValue.current } })),
            Promise.resolve(true)
        )
    ])
    EventThrower.throwClientSideMessage(Events.SuccessMessage("Investment updated"));
    history.push("/admin/investment/documents");
  }


  const handleCompleteInvestment = () => {
    const flowId = store.getState().adminAddInvestorFlowState.investmentId;
    if (flowId) {
      store.getState();
      markCompleted({
        variables: {
          id: flowId,
          completed: !flowCompleted ? Date.now() : 0,
        },
      })
        .then(() => {
          return dispatch({
            type: addInvestmentFlowActions.SET_COMPLETED,
            payload: !flowCompleted,
          });
        })
        .catch(console.log);
    }
  };

  useEffect(() => {
    if(investmentId) {
      getInvestmentHeaderData({variables: {id: investmentId}})
    }
  }, [investmentId])

  return (
    <Fragment>
      <div>
        <Button
          onClick={() => history.goBack()}
          className={[
            dimensionClasses.smallHeigth,
            typografyclasses.fontSize16,
            typografyclasses.noTrasnform,
            typografyclasses.fontColor78756F,
          ]}
        >
          <KeyboardBackspaceIcon />
          Back
        </Button>
        <span>
          {
            getInvestmentHeaderDataResult.data && `${getInvestmentHeaderDataResult.data.Investment_by_pk.deal.company.name}/${getInvestmentHeaderDataResult.data.Investment_by_pk.account.first_name} ${getInvestmentHeaderDataResult.data.Investment_by_pk.account.last_name}`
          }
        </span>
      </div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          className={dimensionClasses.smallHeigth}
          onClick={() => handleAddStep()}
          disabled={flowCompleted}
        >
          <AddIcon style={{ marginRight: "8px" }} />
          Add step
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSaveFlow()}
          className={[
            dimensionClasses.smallHeigth,
            dimensionClasses.marginLeft16,
          ].join(" ")}
          disabled={flowCompleted}
        >
          Save changes
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCompleteInvestment()}
          className={[
            dimensionClasses.smallHeigth,
            dimensionClasses.marginLeft16,
          ].join(" ")}
        >
          {flowCompleted ? "Unmark completed" : "Mark completed"}
        </Button>
      </div>
    </Fragment>
  );
};
