
import React, { useRef, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { adminStyles, adminAlignmentStyles } from '../AdminStyles';
import AddCompany from "./AddCompany/AddCompany";
import CompanyListing from "./CompanyListing/CompanyListing";
import AddOportunityPage from "./AddOportunity/AddOpportunityPage";
import { AddCompanyHeader, AddOpportunityHeader, DefaultHeader } from './Headers';
import OportunitiesList from './OportunitiesList/OportunitiesList';
import { useDispatch,  } from 'react-redux';
import { adminOpportunityActions } from '../../store/slices/adminOpportunity';
import OpportunityPermissions from './OpportunityPermissions/OpportunityPermissions';

const AdminAdminPanel = () => {
  
  const adminClasses = adminStyles();
  const addCompanyRef = useRef();
  const alignmentClasses = adminAlignmentStyles();
  const [nameFilter, setNameFilter] = useState('');
  const dispatch = useDispatch();

  return (
    <div style={{marginTop: "63px"}}>
      <div className={adminClasses.headerContainer}>
        <div className={adminClasses.header}>
          {
            window.location.href.indexOf("add-company") !== -1 || window.location.href.indexOf("edit-company") !== -1
            ? <AddCompanyHeader saveCompanyCall={() => addCompanyRef.current.saveCompany()}/>
            :  (
              window.location.href.indexOf("add-opportunity") !== -1
              ? <AddOpportunityHeader 
                    saveData={() => dispatch(adminOpportunityActions.SAVE_DATA())} 
                    publishDeal={() =>  dispatch(adminOpportunityActions.PUBLISH_DEAL())}
                    saveDealChanges={() =>  dispatch(adminOpportunityActions.SAVE_CHANGES())}
                    />
              :  window.location.href.indexOf("edit-opportunity") !== -1
              ? <AddOpportunityHeader 
                    saveData={() => dispatch(adminOpportunityActions.SAVE_DATA())} 
                    publishDeal={() =>  dispatch(adminOpportunityActions.PUBLISH_DEAL())}
                    saveDealChanges={() =>  dispatch(adminOpportunityActions.SAVE_CHANGES())}
                    isEdit
                    />
              : <DefaultHeader changeFilter={value => setNameFilter(value)}/>  
              )
          }
          
        </div>
      </div>
      <div className={[adminClasses.contentContainer, alignmentClasses.flexAlignCenterOncolumn].join(' ')}>
        <Switch>
          <Route path="/admin/opportunities/list-opportunities" component={() => <OportunitiesList nameFilter={nameFilter}/>} />
          <Route path="/admin/opportunities/add-opportunity" component={() => <AddOportunityPage/>} />
          <Route path="/admin/opportunities/edit-opportunity/:opportunityId" component={() => <AddOportunityPage/>} />
          <Route path="/admin/opportunities/permissions" component={() => <OpportunityPermissions/>} />
          <Route path="/admin/opportunities/companies" component={() => <CompanyListing nameFilter={nameFilter}/>} />
          <Route path="/admin/opportunities/add-company" component={() => <AddCompany ref={addCompanyRef}/>} />
          <Route path="/admin/opportunities/edit-company/:companyId" component={() => <AddCompany ref={addCompanyRef}/>} />
          <Route path="*" component={() => <Redirect to="/admin/opportunities/list-opportunities" />} />
        </Switch>
      </div>
    </div>
  )
}

export default AdminAdminPanel;