import React, { Fragment, useState } from 'react';
import InterestedSectors from '../../../../shared/InvestorPreferancesInputs/InterestedSectors';
import InterestedSectorEnablers from '../../../../shared/InvestorPreferancesInputs/InovationEnablers';
import LessInterestedSectors from '../../../../shared/InvestorPreferancesInputs/LessInterestedSectors';
import LessInterestedInovationEnablers from '../../../../shared/InvestorPreferancesInputs/LessInterestedInovationEnablers';
import AdditionalInformations from '../../../../shared/InvestorPreferancesInputs/AdditionalInformation';
import { Events, EventThrower } from '../../../../shared/EventEmitter/EventEmitter';
import { makeStyles } from "@material-ui/core/styles";
import { generalStyleClasses } from "../../../../utils/general-styles";
import { Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { UPDATE_INVESTOR_PROFILE_INDUSTRY } from '../../../../utils/investor-preferances-queries';
import { useDispatch, useSelector } from 'react-redux';
import { SET_INVESTOR_PROFILE } from '../../../../store/utils/action-types';

const industryStyles = makeStyles({
  line: {
    width: "calc(100% + 48px)",
    borderBottom: "1px solid #D8CECE",
    marginLeft: "-24px",
  },
  input: {
    flexGrow: 1,
    width: "50%",
  },
  flexAlignTop: {
    alignItems: "flex-start !important",
  }
});

const Industry = () => {
  const classes = industryStyles();
  const dispatch = useDispatch();
  const generalStyles = generalStyleClasses();
  const [interestedSectors, setInterestedSectors] = useState([]);
  const [inovationEnablers, setInovationEnablers] = useState([]);
  const [interestedSectorsLess, setInterestedSectorsLess] = useState([]);
  const [inovationEnablersLess, setInovationEnablersLess] = useState([]);
  const [additionalInformations, setAdditionalInformations] = useState('');
  const [updateIndustryPreferences] = useMutation(UPDATE_INVESTOR_PROFILE_INDUSTRY);
  const additionalInfosValue = useSelector(store => store.investorProfile.industry_additional_information);
  const account = useSelector(store => store.account.user);
  const investorProfileId = useSelector(store => store.account.investorProfileId);

  const handleSaveChanges = () => {
    const industryPreferencesData = {
      firebase_id: account.firebase_id,
      sectors: interestedSectors.map(x => ({ sector: x.value, profile_id: investorProfileId })),
      enablers: inovationEnablers.map(x => ({ enabler: x.value, profile_id: investorProfileId })),
      industry_additional_information: additionalInformations,
      sectors_less: interestedSectorsLess.map(x => ({ sector: x.value, profile_id: investorProfileId })),
      enablers_less: inovationEnablersLess.map(x => ({ enabler: x.value, profile_id: investorProfileId })),
    }
    updateIndustryPreferences({ variables: industryPreferencesData })
      .then(result => {
        dispatch({type: SET_INVESTOR_PROFILE, payload: result.data.update_Investor_profile.returning[0]})
        EventThrower.throwClientSideMessage(Events.InformationChangedMessage)
      })
      .catch(errror => {
        EventThrower.throwClientSideError(Events.InformationChangedError)});
  }

  return (
    <Fragment>
      <div className={[generalStyles.flexSpaceBetweenCenter, classes.flexAlignTop].join(' ')}>
        <InterestedSectors handleChange={value => setInterestedSectors(value)} rootClasses={classes.input} />
        <LessInterestedSectors handleChange={value => setInterestedSectorsLess(value)} rootClasses={classes.input} />
      </div>
      <div className={[classes.line, generalStyles.marginTop32].join(' ')} />
      <div className={[generalStyles.flexSpaceBetweenCenter, generalStyles.marginTop24, classes.flexAlignTop].join(' ')}>
        <InterestedSectorEnablers handleChange={value => setInovationEnablers(value)} rootClasses={classes.input} />
        <LessInterestedInovationEnablers handleChange={value => setInovationEnablersLess(value)} rootClasses={classes.input} />
      </div>
      <div className={generalStyles.marginTop32} >
        <AdditionalInformations handleChange={setAdditionalInformations} defaultValue={additionalInfosValue} />
      </div>
      <Button variant="contained" color="primary" className={[generalStyles.floatRight, generalStyles.marginTop32].join(' ')} onClick={handleSaveChanges}>Save changes</Button>
    </Fragment>
  )
}

export default Industry;