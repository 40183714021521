import { Button, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, Fragment, useState } from 'react';
import { generalStyleClasses } from '../../../utils/general-styles';
import AttachmentIcon from '@material-ui/icons/Attachment';
import TextInput from '../../../shared/TextInput/TextInput';
import { formatMessageDate } from '../../../utils/formats';
import { useSelector } from 'react-redux';
import { useMutation, useSubscription } from '@apollo/client';
import { GET_DEAL_CHAT_MESSAGES_BY_CHAT_ID, SEND_DEAL_MESSAGE } from '../../../utils/deal-queries';
import Loading from '../../../shared/Loading/Loading';
import { SubscriptionClient } from 'subscriptions-transport-ws';


const styles = makeStyles({
  container: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    flexGrow: "1",
    height: "calc(100vh - 170px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    padding: "20px 16px",
    background: "#f9f9f9",
  },
  messageBox: {
    padding: "12px 15px",
    width: "fit-content",
    borderRadius: "4px",
    margin: "0",
  },
  messageContainer: {
    display: "flex",
    marginRight: "0px",
    marginLeft: "20px",
  },
  minimalMessageContainer: {
    display: "flex",
    marginRight: "80px",
    marginLeft: "4px",
  },
  messageOnRight: {
    justifyContent: "flex-end",
    marginRight: "20px",
    marginLeft: "0px",
    background: "none"
  },
  minimalRightMessage: {
    marginLeft: "80px",
    marginRight: "4px",
    justifyContent: "flex-end",
  },
  documentsMessage: {
    padding: "6px",
    color: "#1F3F70",
  },
  atachmentIcon: {
    transform: "rotate(135deg)",
  },
  messageStartWarning: {
    width: "100%",
    color: "#B67234",
  },
  messageInput: {
    width: "100%",
    boxSizing: "border-box",
    padding: "8px 14px",
    borderTop: "1px solid rgb(231, 231, 231)",
  },
  smallbutton: {
    height: "40px",
    width: "42px",
    minWidth: "42px",
  },
  messagesContainer: {
    overflow: "auto",
    flexGrow: "1",
    width: "100%",

    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },
    " &::-webkit-scrollbar-thumb": {
      background: "#666",
    }
  },
  messageBoxContainer: {
    display: "flex",
    flexDirection: "column",
  },
  textArea: {
    resize: "none",
    flexGrow: "1", 
    height: "36px",
    padding: "6px",
    boxSizing: "border-box",

  }
})


const Message = ({ message, isMinimal = false }) => {
  const classes = styles();
  const generalClasses = generalStyleClasses();
  return (
    <div className={[
      isMinimal ? classes.minimalMessageContainer : classes.messageContainer,
      message.isMine ? (isMinimal ? classes.minimalRightMessage : classes.messageOnRight) : "",
      generalClasses.marginTop12].join(' ')}>
      <div className={classes.messageBoxContainer} style={{ alignItems: message.isMine ? "flex-end" : "" }}>
        <p className={[classes.messageBox, generalClasses.fontSize14, message.isDocuments ? classes.documentsMessage : ""].join(' ')}
          style={{ background: !message.isMine ? "#EEEEEE" : "#505154", color: message.isMine && "#ffffff" }}>{message.text}</p>
        <span className={generalClasses.fontSize12} style={{ color: "#555555" }}>{formatMessageDate(message.date, true)}</span>
      </div>
    </div>
  )
}

const MessagesContainer = ({ isMinimal = false, dealId = null, admin = null }) => {
  const classes = styles();
  const generalClasses = generalStyleClasses();
  const messagesEndRef = useRef();
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const [sendMessageCall] = useMutation(SEND_DEAL_MESSAGE);
  const { data: messagesData, loading } = useSubscription(GET_DEAL_CHAT_MESSAGES_BY_CHAT_ID, { variables: { deal_chat_id: admin.chatId } });
  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState([]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleSendMessage = async () => {
    const sendMessageResult = await sendMessageCall({
      variables: {
        message: message,
        firebase_id: firebaseId,
        deal_chat_id: admin.chatId,
      }
    });
    if (sendMessageResult.data) {
      setMessage("")
    }
  }

  useEffect(() => {
    if (messagesData && messagesData.Deal_chat_message) {
      setMessages(messagesData.Deal_chat_message.map(message => ({
        text: message.message,
        isMine: message.firebase_id === firebaseId,
        isDocuments: false,
        downloadLink: "",
        date: message.created_at,
      })));
    }
  }, [messagesData])

  useEffect(() => scrollToBottom(), [messages])

  useEffect(() => {
    scrollToBottom()
  }, []);

  return (
    <Fragment>
      <div className={[classes.messagesContainer, !messagesData && loading && generalClasses.flexAlignCenter].join(' ')}>
        {
          !messagesData && loading
            ? <Loading fullWidth customStyle={{ width: "100px", height: "100px", flexGrow: "1", position: "relative" }} />
            : messages.map((message, index) => <Message key={index} message={message} isMinimal={isMinimal} />)
        }
        <div ref={messagesEndRef} />
      </div>
      <div className={[generalClasses.flexAlignCenter, classes.messageInput, generalClasses.flexWrap].join(' ')}>
        <textarea className={[classes.textArea, generalClasses.fontSize16].join(' ')} value={message} onChange={value => setMessage(value.target.value)}></textarea>
        <IconButton className={classes.atachmentIcon}>
          <AttachmentIcon />
        </IconButton>
        <Button variant="contained" color="primary" style={{ height: !isMinimal && "32px" }} className={isMinimal && classes.smallbutton} onClick={() => handleSendMessage()}>
          {
            isMinimal
              ? <img src={`${process.env.PUBLIC_URL}/images/send.svg`} alt="account" />
              : "Send message"
          }
        </Button>
      </div>
    </Fragment>
  )
}

export default MessagesContainer;