import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { adminTypografyStyles } from '../../admin/AdminStyles';

const styles = makeStyles({
  chartContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  chartElement: {
    display: "flex",
    marginRight: "20px",
    alignItems: "center",
    marginBottom: "20px",
  },
  chartColorInfo: {
    width: "16px",
    height: "16px",
    marginRight: "8px",
  },
  barContainer: {
    display: "flex",
    height: "32px",
    marginBottom: "12px",
  },
  bar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  }
})

const BarChart = ({ title, objects }) => {
  const typografyClasses = adminTypografyStyles();
  const classes = styles();
  const [elements, setElements] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (objects) {
      setTotal(objects.total)
      const elementsArray = []
      Object.keys(objects).filter(x => x !== "total").forEach(x => {
        elementsArray.push({ name: x, value: objects[x].value, color: objects[x].color });
      })
      setElements(elementsArray.sort((a, b) => b.value - a.value));
    }
  }, [])
  return (
    <div>
      <p style={{marginTop: "0px"}}  className={[typografyClasses.fontSize14, typografyClasses.fontColor5B5B5B].join(' ')}>{title}</p>
      {
        total 
        ? (
          <>
          <div className={classes.barContainer}>
          {
            elements.map(x => <div className={classes.bar} style={{width: `${(x.value ? 52 : 24)*x.value}px`, background: x.color, minWidth: "24px"}}>{x.value}</div>)
          }
        </div>
        <div className={classes.chartContainer}>
          {
            elements.map(x => <div className={classes.chartElement}>
              <div className={classes.chartColorInfo} style={{ background: x.color }} />
              <span className={[typografyClasses.fontSize12, typografyClasses.fontColor5B5B5B].join(' ')}>
                {x.name}
              </span>
              </div>)
          }
        </div>
        </>
        )
        : <p>No data avalabile</p>
      }
     
    </div>
  )
}

export default BarChart;