import { useLazyQuery, useQuery } from "@apollo/client";
import { Tabs, Tab, Box, makeStyles, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { GET_INVESTMENT_COMPLETED } from "../../../admin/queries/admin-queries";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { GET_INVESTMENT_FLOW_BY_ID, GET_INVESTMENT_FLOW_ITEMS_BY_EDGE_ID } from "../../../utils/deal-queries";
import {
  addDocumentsForFlowEdge,
  deleteDocumentsForFlowEdge,
} from "../../../utils/file-queries";
import DownloadComponent from "../../../shared/DocumentHandler/DownloadComponent";
import { mapServerFlow } from "../../../shared/DocumentHandler/flowMapper";
import UploadComponent from "../../../shared/DocumentHandler/UploadComponent";
import TextInput from "../../../shared/TextInput/TextInput";
import { hasuraErrorHandler } from "../../../shared/EventEmitter/EventEmitter";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const styles = makeStyles({
  container: {
    position: "absolute",
    top: "0",
    zIndex: "200",
    background: "#e5e5e5",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tabContainer: {
    width: "560px",
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "32px",
  },
});

const InvestmentFlow = () => {
  const [selectedTab, setSelectedTab] = useState(null);
  const classes = styles();
  const params = useRouteMatch().params;
  const { data } = useQuery(GET_INVESTMENT_FLOW_BY_ID, { variables: { id: params.investmentId },fetchPolicy: "network-only"});
  const { data: investmentCompletedData } = useQuery(GET_INVESTMENT_COMPLETED, { variables: { id: params.investmentId },});
  const [getEdgeItems, getEdgeItemsResult] = useLazyQuery(GET_INVESTMENT_FLOW_ITEMS_BY_EDGE_ID, {fetchPolicy: "network-only"});
  const [flow, setFlow] = useState([]);
  const [name, setName] = useState("");
  const [tabsNumber, setTabsNumber] = useState(0);
  const [isCompelted, setIsCompelted] = useState(false);
  const history = useHistory();

  const handleGoBack = () => {
    if(history.location.state && history.location.state.lastPage) {
      history.push( history.location.state.lastPage);
    } else if(!isCompelted) {
      history.push(`/app/view-opportunity/${params.dealId}`);
    } else {
      history.goBack();
    }
  }

  console.log(selectedTab);
  useEffect(() => {
    if (data) {
      if(data.Investment.length === 0) {
        hasuraErrorHandler.printError("This investment was deleted");
        history.push(`/app/view-opportunity/${params.dealId}`)
      }
      try {
        setTabsNumber(
          data.Investment[0]?.Investment_flow_section_edges[0]?.Flow_node
            ?.Investment_flow_section_edges.length || 0
        );
        setName(
          data.Investment[0]?.Investment_flow_section_edges[0]?.Flow_node
            ?.name || ""
        );
        const mappedData = mapServerFlow(data);
        setFlow(mappedData.flow);
        if(selectedTab === null) {
          setSelectedTab(Math.max(0, mappedData.current));
        }
      } catch (err) {
        console.log(err);
        if (params.dealId) {
          history.push(`/app/view-opportunity/${params.dealId}`);
        } else {
          history.push(`/app/opportunities/all`);
        }
      }
    }
    if (investmentCompletedData) {
      setIsCompelted(!!investmentCompletedData.Investment_by_pk?.completed);
    }
  }, [data]);

  const findNextStep = () => {
    for (let i = selectedTab + 1; i < flow.length; i++) {
      if (!flow[i].locked) {
        return i;
      }
    }
  };

  const findLastStep = () => {
    for (let i = flow.length - 1; i >= 0; i--) {
      if (!flow[i].locked) {
        return i;
      }
    }
  };

  const handleGoToNextStep = () => {
    if (selectedTab != findLastStep()) {
      setSelectedTab(findNextStep());
    } else {
      history.push(`/app/view-opportunity/${params.dealId}`);
    }
  };

  const handleAddFile = (files, edgeId) => {
    const filesToUpload = files.filter(x => x instanceof File);
    if(filesToUpload.length > 0) {
    addDocumentsForFlowEdge(filesToUpload, params.investmentId, edgeId)
      .then(() => getEdgeItems({variables: {id: edgeId}}))
      .catch((err) => console.log(err));
    }
  };

  const handleDeleteFile = (fileId) => {
    deleteDocumentsForFlowEdge(fileId).then(console.log).catch(console.log);
  };

  useEffect(() => {
    if(getEdgeItemsResult.data) {
      setFlow(flow.map(flowStep => {
        const flowItemIndex = flowStep.components.findIndex(item => item.edgeId === getEdgeItemsResult.data.Investment_section_item_edge_by_pk.id);
        if(flowItemIndex !== -1) {
          flowStep.components[flowItemIndex].files = getEdgeItemsResult.data.Investment_section_item_edge_by_pk.Files_metadata;
        }
        return flowStep;
      }))
    }
  }, [getEdgeItemsResult]);

  return (
    <div className={classes.container}>
      <p>{name} {isCompelted &&  "Investment Completed"}</p>
      <div style={{ width: "560px" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {flow
            ? flow.map((x, index) => <Tab key={index} label={x.tabName} style={{maxWidth: "none", flexGrow: "1"}}/>)
            : ""}
        </Tabs>
        <div className={classes.tabContainer}>
          {flow
            ? flow.map((x, index) => (
                <TabPanel key={index} value={selectedTab} index={index}>
                  {x.components.map((comp, compIndex) => {
                    if (comp.type === "download")
                      return (
                        <DownloadComponent
                          key={compIndex}
                          title="download documents: "
                          files={comp.files}
                        />
                      );
                    if (comp.type === "text")
                      return <p key={compIndex}>{comp.text}</p>;
                    if (comp.type === "upload")
                      return (
                        <UploadComponent
                          disabled={isCompelted}
                          deleteCallback={(fileId) => handleDeleteFile(fileId)}
                          downloadRoute="investment"
                          initialFiles={comp.files}
                          setNewFiles={(files) =>
                            handleAddFile(files, comp.edgeId)
                          }
                          key={compIndex}
                          title={comp.label}
                        />
                      );
                    if (comp.type === "docusign")
                      return <p key={compIndex}>docusign</p>;
                  })}
                  {
                    !isCompelted 
                    ? (
                  <div className={classes.buttonContainer} style={{flexWrap: "wrap"}}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ width: "248px" }}
                      onClick={() => handleGoBack()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: "248px" }}
                      onClick={() => handleGoToNextStep()}
                    >
                      {selectedTab === findLastStep() ? "Finish" : "Continue"}
                    </Button>
                    <Button variant="contained" color="primary" style={{width: "100%", marginTop: "8px"}} component={Link} to={`/app/modify-ammount/${params.dealId}/`}>Modify ammount</Button>
                  </div>
                    )
                    : <Button variant="outlined" color="primary" onClick={() => handleGoBack()} style={{width: "100%", height: "36px", marginTop: "24px"}}>Go back</Button>
                  }
                </TabPanel>
              ))
            : ""}
        </div>
      </div>

    </div>
  );
};

export default InvestmentFlow;
