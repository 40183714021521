import React, { Fragment, useEffect, useState } from 'react';
import LabeledText from '../../../../../shared/LabeledText/LabeledText';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodaySharpIcon from '@material-ui/icons/CalendarTodaySharp';
import StarBorderSharpIcon from '@material-ui/icons/StarBorderSharp';
import { generalStyleClasses } from "../../../../../utils/general-styles";
import { opportunityCardStyles } from "./OpportunityCardStyles";
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ADD_TO_WATCHLIST, DELETE_FROM_WATCHLIST } from '../../../../../utils/deal-queries';
import StarIcon from '@material-ui/icons/Star';
import { Events, EventThrower } from '../../../../../shared/EventEmitter/EventEmitter';
import { fileDownloader } from '../../../../../utils/file-queries';
import { formatDate, formatDateMonthYear, formatValue } from '../../../../../utils/formats';
import { useSelector } from 'react-redux';
import { insertAnalytics } from '../../../../../utils/analytics-queries';
import Loading from '../../../../../shared/Loading/Loading';

const OpportunityCard = ({ deal, isOnWatchlist = false, handleRemoveFromWatchlist, notification=null}) => {
  const classes = opportunityCardStyles();
  const generalStyles = generalStyleClasses();
  const firebaseId = useSelector(store => store.account.user.firebase_id)
  const [isWatchlist, setIsWatchlist] = useState(deal.watchlist && deal.watchlist.length > 0);
  const [addToWatchlist, addToWatchlistQueryData] = useMutation(ADD_TO_WATCHLIST, { variables: { deal_id: deal.id } })
  const [deleteFromWatchlist, deleteFromWatchlistQueryData] = useMutation(DELETE_FROM_WATCHLIST, { variables: { deal_id: deal.id } })
  const history = useHistory();
  const [logo, setLogo] = useState("");
  const [imageLoading, setImageLoading] = useState(true);

  const handleChangeWatchlistStatus = () => {
    if (isWatchlist) {
      deleteFromWatchlist();
    } else {
      addToWatchlist();
    }
  }

  const handleLearnMoreButton = () => {
    insertAnalytics({ companyId: deal.company.id, dealId: deal.id, firebaseId, event: "DEAL_VIEW_MORE_CLICK"}).then(() => null).catch(console.error);
    history.push(`/app/view-opportunity/${deal.id}`);
  }

  useEffect(() => {
    console.log("deal ", deal);
    if(deal.company.logo) {
      console.log("intro");
      const image = fileDownloader(`company/logo/${deal.company.logo}`);
      image.then(response =>{
        console.log("aici");
        setImageLoading(false);
        setLogo(URL.createObjectURL(response))
      });
    } else {
      setImageLoading(false)
    }
  }, [deal])

  useEffect(() => {
    if (addToWatchlistQueryData.data) {
      if (addToWatchlistQueryData.data.insert_Watchlist.affected_rows > 0) {
        setIsWatchlist(true);
        EventThrower.throwClientSideMessage(Events.CompanyAddedToWatchlist);
      }
    }
  }, [addToWatchlistQueryData.data])

  useEffect(() => {
    if (deleteFromWatchlistQueryData.data && deleteFromWatchlistQueryData.data.delete_Watchlist.affected_rows > 0) {
      if (isOnWatchlist && handleRemoveFromWatchlist != null) {
        handleRemoveFromWatchlist(deal.id);
      } else {
        setIsWatchlist(false);
      }
      EventThrower.throwClientSideMessage(Events.CompanyRemovedFromWatchlist);
    }
  }, [deleteFromWatchlistQueryData.data]);

  return (
    <div >
    {notification}
    <div className={classes.cardContainer}>
      <div className={generalStyles.flexSpaceBetweenCenter}>
        {
          imageLoading
          ? <Loading customStyle={{position: "relative",height: "52px", width: "60px",  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)", transform: "scale(0.5)"}}/>
          : <img src={logo ? logo : `${process.env.PUBLIC_URL}/images/no-image.png`} className={classes.cardTitleImage} />
        }
        <div className={classes.companyTitle}>
          <p className={[generalStyles.marginBottom0, generalStyles.marginTop0].join(' ')}>
            <span className={[generalStyles.fontInputColor, generalStyles.fontSize19].join(' ')} style={{cursor: "pointer"}} onClick={() => handleLearnMoreButton()}>{deal.company.name} {deal.title ? ` - ${deal.title}` : ""}</span>
            <span className={
              [generalStyles.fontSize15,
              deal.status === 'ACTIVE' ? classes.openSpanBackground : deal.status === 'PROSPECTIVE' ? classes.prospectiveSpanBackground : classes.closedSpanBackground].join(' ')}>
              {
                deal.status
                  ? deal.status.charAt(0).toUpperCase() + deal.status.slice(1).toLowerCase()
                  : ""
              }
            </span>
          </p>
          <p className={[generalStyles.marginBottom0, generalStyles.marginTop0, generalStyles.fontColorLight, generalStyles.fontSize13].join(' ')}>{`${deal.company.hq_city} ${deal.company.hq_state}, ${deal.company.hq_country}`}</p>
        </div>
        <Button onClick={() => handleChangeWatchlistStatus()} className={[generalStyles.flexAlignCenter, generalStyles.fontSize15, generalStyles.fontInputColor, classes.addToWatchList].join(' ')}>
          {isWatchlist ? "Remove from watchlist" : "Add to watchlist"}
          {
            isWatchlist
              ? <StarIcon style={{ fill: "#FAB54F" }} />
              : <StarBorderSharpIcon />
          }
        </Button>
      </div>
      <div className={classes.cardBody}>
        <p className={[generalStyles.marginTop24, generalStyles.marginbottom24, generalStyles.fontSize15, classes.cardBodyParagraphColor].join(' ')}>{deal.company.blurp}</p>
        <div className={classes.cardBodyInfos}>
          <div className={classes.notableInvestorsText}>
            <LabeledText label="Notable Investors" text={deal?.company?.funding_histories.map(x => x.notable_investors).reduce((a, b) => a.concat(b), []).map(x => x.notable_investor_name).join(', ') || 'None'} />
          </div>
          <div className={classes.proposedValuationText}>
            <LabeledText label="Proposed Valuation" text={`$${formatValue(deal.valuation)}`} />
          </div>
          <LabeledText label="Stage" text={deal.company.funding_stage} />
        </div>
      </div>
      <div className={[deal.status !== 'CLOSED' ? generalStyles.flexSpaceBetweenCenter : generalStyles.flexJustifyEnd, generalStyles.marginTop24].join(' ')}
        style={{ justifyContent: "flex-end !important" }}>
        {
          deal.status !== 'CLOSED'
            ? (
              deal.status === 'ACTIVE'
                ? (
                  <Fragment >
                    <span className={classes.avalabileSumInfo}>
                      <AttachMoneyIcon />
                      {
                      deal.investment_amount_available
                      ? `${formatValue(deal.investment_amount_available)} Available`
                      : (
                        deal.investment_value 
                        ? `${formatValue(deal.investment_value-deal.Investments.map(x => x.value).reduce((a, b) => a+b, 0))} Available`
                        : "Amount unavalabile"
                      )
                    }
                    </span>
                    <span className={classes.dateInfo}>
                      <CalendarTodaySharpIcon />
                      {
                        deal.investment_deadline
                          ? formatDate(new Date(deal.investment_deadline))
                          : "Date unavalabile"
                      }
                    </span>
                  </Fragment>
                )
                : (
                  <span className={classes.prospectiveDateInfo}>
                    <CalendarTodaySharpIcon />
                    { deal.opening_date
                      ? formatDateMonthYear(deal.opening_date)
                      : "Date unavalabile"}
                  </span>
                )
            )
            : (
              <span className={classes.dateInfo} style={{marginLeft: "0px"}}>
              <CalendarTodaySharpIcon />
              { deal.investment_deadline
                ? formatDateMonthYear(deal.investment_deadline)
                : "Date unavalabile"}
            </span>
            )
        }
        <Button variant="contained" color="primary"  onClick={() => handleLearnMoreButton()}>Learn more</Button>
      </div>
    </div>
    </div>
  )
}

export default OpportunityCard;