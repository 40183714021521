import React, { Fragment, useState } from "react";
import TextInput from '../../shared/TextInput/TextInput';
import { Link, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import LandingPageNavbar from './LandingPageNavbar/Navbar';
import firebase from "firebase";
import { useDispatch, useSelector } from 'react-redux';
import { EventThrower, Events, hasuraErrorHandler } from '../../shared/EventEmitter/EventEmitter';
import { useLazyQuery } from '@apollo/client';
import { SET_USER_ACCOUNT, SET_USER_AUTH } from '../../store/utils/action-types';
import { CAN_ENTER_APP, GET_ACCOUNT__BY_ID } from '../../utils/account-queries'
import './landing-page.scss';
import { useEffect } from "react";

const LandingPage = () => {
    const history = useHistory();
    const [getAccountById, { data }] = useLazyQuery(GET_ACCOUNT__BY_ID, { fetchPolicy: "network-only" });
    const dispatch = useDispatch();
    const jwtToken = useSelector(store => store.account.jwtToken);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const login = async () => {
        try {
            let userResult = await firebase.auth().signInWithEmailAndPassword(email, password);
            let tokenResult = await userResult.user.getIdTokenResult(true);
            if (tokenResult.token) {
                dispatch({ type: SET_USER_AUTH, payload: { jwtToken: tokenResult.token } });
            }
        }
        catch (error) {
            console.log(error);
            if (error) {
                if (error.code === "auth/wrong-password") {
                    setPasswordError(true);
                } else if (error.code === "auth/user-not-found" || error.code === "auth/invalid-email" || error.code === "auth/argument-error") {
                    setEmailError(true);
                } else if(error.code === "auth/user-disabled") {
                    hasuraErrorHandler.printError("Your account has been disabled");
                }
            }
        };
    }

    useEffect(() => {
        const onKeyUp = ({ key }) => {
            if (key === 'Enter') {
                login();
            }
        }
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('keyup', onKeyUp);
        }
    })

    const handleSetEmail = (email) => {
        setEmail(email);
        setEmailError(false);
    }

    const handleSetPassword = (email) => {
        setPassword(email);
        setPasswordError(false);
    }

    return (
        <Fragment>
            <div className="landing-page-container">
                <LandingPageNavbar />
                <div className="landing-page-root">
                    <div className="image-container">
                        <img src={process.env.PUBLIC_URL + "/images/landing-page-main-image.png"} />
                        <img src={process.env.PUBLIC_URL + "/images/landing-page-main-image-overlay.png"} />
                        <h3>Redefining access to the innovation economy</h3>
                        <h4>Invest in specific high-growth private technology companies of your choice</h4>
                    </div>
                    <div className="login-container">
                        <p id="title">Login</p>
                        <TextInput label="Email" isPassword={false} handleChange={value => handleSetEmail(value)} error={emailError} />
                        {
                            emailError
                                ? <p className="login-error-filed" style={{ marginTop: "-22px" }}>No account associated with this email address.</p>
                                : ""
                        }
                        <div>
                            <TextInput label="Password" isPassword={true} handleChange={value => handleSetPassword(value)} error={passwordError} />
                            {
                                passwordError
                                    ? <p className="login-error-filed">Wrong password. Try again or click Forgot password to reset it.</p>
                                    : ""
                            }
                            <Link to="/reset-password">Forgot password?</Link>
                        </div>

                        <Button variant="contained" color="primary" onClick={login}> Login </Button>
                        <p>Don't have an account? <Link to="/onboarding/create-account">Register</Link></p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default LandingPage;