import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { adminAlignmentStyles, adminDimensionsStyle, adminTypografyStyles } from '../../AdminStyles';

const OportunityWatchlistDialog = ({ open, setOpen, investors = [], name }) => {
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const typografyClasses = adminTypografyStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} style={{ padding: "24px" }} PaperProps={{ style: { padding: "24px" } }} disableScrollLock={true}>
      <DialogTitle className={[alignmentClasses.modalTitle, dimensionClasses.padding0].join(' ')} >
        {name} Watchlist
      <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={dimensionClasses.padding0}>
        <div className={[alignmentClasses.flex, typografyClasses.fontColor5B5B5B, typografyClasses.fontSize14].join(' ')} style={{ borderBottom: "1px solid #DADADA" }}>
          <p className={dimensionClasses.widthGrow}>Name</p>
          <p className={dimensionClasses.width200}>Date</p>
        </div>
        {
          investors.map(x => (
            <div className={[dimensionClasses.smallHeigth, dimensionClasses.marginTop8, alignmentClasses.flexAlignCenter].join(' ')}>
              <Link className={[dimensionClasses.widthGrow, typografyClasses.fontColor0258A8, typografyClasses.fontSize14].join(' ')}
                to={`/admin/clients/view/${x.account.firebase_id}`}>
                {`${x.account.first_name} ${x.account.last_name}`}
            </Link>
              <p className={[dimensionClasses.width200, dimensionClasses.marginBottom0, dimensionClasses.marginTop0, typografyClasses.fontSize14, typografyClasses.fontColor7B7B7B].join(' ')}>
                {new Date(x.created_at).toLocaleDateString()}
            </p>
            </div>
          ))
        }
      </DialogContent>
      <DialogActions className={dimensionClasses.padding0}>
        <Button className={[dimensionClasses.marginTop24, dimensionClasses.smallHeigth]} onClick={handleClose} variant="outlined" color="primary">
          Close
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OportunityWatchlistDialog;