import React, { Fragment, useState } from 'react';
import { oportunitySectionStyle } from "./OportunitySectionStyles";
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { adminInputStyles, adminTypografyStyles } from '../../../AdminStyles';
import { adminOpportunityActions } from '../../../../store/slices/adminOpportunity';

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'align': 'right' }, { 'align': 'center' }, { 'align': null }, { 'align': 'justify' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'list': 'bullet' }, { 'list': 'ordered' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link'],
  ]
}

const RichTextArea =  ({section}) => {
  const classes = oportunitySectionStyle();
  const text = section?.data?.content || '';
  const dispatch = useDispatch();
  
  const handleValueChange = (content) => {
    dispatch(adminOpportunityActions.UPDATE_SECTION({
      id: section.id, 
      data: {
        ...(section.data || {}),
        content
      }}))
  }
  return <ReactQuill value={text || ''} onChange={handleValueChange} modules={modules} className={classes.quillEditor} placeholder="Section content...." />
}

export default RichTextArea;

