import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { GET_COMPANY_DETAILS_BY_ID } from '../../../queries/admin-queries';
import LabeledText from '../../../../shared/LabeledText/LabeledText';
import { oportunitySectionStyle } from "./OportunitySectionStyles";
import { useSelector } from 'react-redux';
import { adminDimensionsStyle, adminTypografyStyles } from '../../../AdminStyles';

const ManagementSection = () => {
  const classes = oportunitySectionStyle();
  const { companyId } = useSelector(store => store.adminOpportunity.resultingOpportunity) || {};
  const [getCompanyDetails, { data }] = useLazyQuery(GET_COMPANY_DETAILS_BY_ID, { variables: { id: companyId } });
  useEffect(() => {
    companyId && getCompanyDetails && getCompanyDetails();
  }, [companyId, getCompanyDetails]);

  if (!data || !data.Company_by_pk) {
    return <div className={classes.content}>Company management loading</div>
  }

  return (
            <div className={classes.content}>
              {
                data.Company_by_pk.company_managements.map((x, index) =>
                  <div key={index} className={classes.managementSection}>
                    <LabeledText label={x.position} text={x.name} />
                  </div>)
              }
            </div>
  )
}

export const companyManagementAreaSection = () => ({
  component: <ManagementSection />,
  title: (<span>Management (this section is editable in the company page)</span>)
})


export default ManagementSection;