import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const utilStore = createSlice({
  initialState: initialState,
  name: "utils",
  reducers: {
    SET_UTIL_VALUE: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    DELETE_UTIL_VALUE: (state, action) => {
      delete state[action.payload.name];
    },
  }
  })

export const utilStoreActions = utilStore.actions;
export const utilStoreReducer = utilStore.reducer;