import { useQuery } from '@apollo/client';
import { Button, makeStyles } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { GET_AMMOUNT_AVAILABLE } from '../../../../../../utils/deal-queries';
import { generalStyleClasses } from '../../../../../../utils/general-styles';
import { getRoundedCeilValue, getRoundedFloorValue, numberWithCommas } from "../../../../../../utils/formats";
import { useSelector } from 'react-redux';
const styles = makeStyles({
  container: {
    padding: "32px",
    background: "#F9F9F9",
    border: "1px solid #F4F4F4",
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    background: "white",
    margin: "0px 8px",
    textTransform: "none",
    border: "1px solid #EBEBEB",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
  }
})

const makeRoute = (data) => {
  if(data) {
    if(data.Deal_by_pk.Investments.length > 0) {
      return `/app/modify-ammount`
    }
    if(data.Deal_by_pk.status === "ACTIVE")
      return `/app/invest`
  }
  return `/app/indicate-interest`
}

const DetailedOpportunityFooter = ({dealId}) => {
  const classes = styles();
  const generalClasses = generalStyleClasses();
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const {data: ammountAvailable} = useQuery(GET_AMMOUNT_AVAILABLE, {variables: {id: dealId, firebase_id: firebaseId}, fetchPolicy: "network-only"});
  const [sums, setSums] = useState([0, 0, 0]);
  const [route, setRoute] = useState(`/app/indicate-interest`);

  useEffect(() => {
      if(ammountAvailable) {
        setRoute(makeRoute(ammountAvailable));
        setSums([
          getRoundedCeilValue(ammountAvailable.Deal_by_pk.investment_min),
          getRoundedFloorValue((ammountAvailable.Deal_by_pk.investment_min + ammountAvailable.Deal_by_pk.investment_max)/2),
          getRoundedFloorValue(ammountAvailable.Deal_by_pk.investment_max)
        ])
      }
  }, [ammountAvailable])

  return (
    <div className={classes.container}>
      <p className={generalClasses.fontSize14} style={{color: "#484642"}}>
      What amount would you be interested to invest?
      </p>
      <div>
        <Button disableElevation disableRipple variant="contained" component={Link} to={`/app/not-interested/${dealId}`} className={[classes.button, generalClasses.fontSize14].join(' ')} style={{color: "#78756F"}}>Not interested</Button>
        <Button disableElevation disableRipple variant="contained" component={Link} to={`${route}/${dealId}/${sums[0]}`} className={[classes.button, generalClasses.fontSize14].join(' ')} style={{color: "#78756F"}}>
          ${ammountAvailable && numberWithCommas(sums[0])}
          </Button>
        <Button disableElevation disableRipple variant="contained" component={Link} to={`${route}/${dealId}/${sums[1]}`} className={[classes.button, generalClasses.fontSize14].join(' ')} style={{color: "#78756F"}}>
          ${ammountAvailable && numberWithCommas(sums[1])}
          </Button>
        <Button disableElevation disableRipple variant="contained" component={Link} to={`${route}/${dealId}/${sums[2]}`} className={[classes.button, generalClasses.fontSize14].join(' ')} style={{color: "#78756F"}}>
          ${ammountAvailable && numberWithCommas(sums[2])}
          </Button>
        <Button disableElevation disableRipple variant="contained" component={Link} to={`${route}/${dealId}`} className={[classes.button, generalClasses.fontSize14].join(' ')} style={{color: "#78756F"}}>Custom</Button>
      </div>
    </div>
  )
}

export default DetailedOpportunityFooter;