import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generalStyleClasses } from '../../utils/general-styles';
import RadioButtonsGroup from '../RadioButtonsGroup/RadioButtonsGroup';
import { securityType } from './Options/InvestmentOptions';

const PreferredSecurityType = ({ handleChange, rootClasses = {} }) => {
  const classes = generalStyleClasses();
  const defaultValue = useSelector(store => store.investorProfile.investment_security_type);
 
  useEffect(() => {
    if(defaultValue && defaultValue > 0) {
      handleChange(defaultValue);
    }
  }, [])

  return (
    <div className={rootClasses}>
      <span className={[classes.fontSize14, classes.fontInputColor].join(' ')}>Preferred Security Type</span>
      <div className={classes.marginTop16}>
        <RadioButtonsGroup row options={securityType} handleChange={handleChange} defaultValue={defaultValue}/>
      </div>
    </div>
  )
}

export default PreferredSecurityType;