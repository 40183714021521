import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { generalStyleClasses } from '../../../../../../utils/general-styles'

const notificationStyle = makeStyles({
  container: {
    background: "#FEFBEF",
    marginBottom: "-2px",
    padding: "6px 24px",
  },
  button: {
    color: "#47567B",
    padding: "0px 8px",
    textTransform: "none"
  }
})

export const ReviewAndSignCardNotification = ({url = "#"}) => {
  const generalClasses = generalStyleClasses();
  const notificationClasses = notificationStyle()
  return (
    <div className={notificationClasses.container}>
      <span className={generalClasses.fontSize15}>Please complete your investment</span>
    </div>
  )
}

export default ReviewAndSignCardNotification