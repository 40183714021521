import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import AdminLogin from './AdminLogin';
import AdminPanel from './AdminPanel';

const AdminRouter = () => {
  const admin = useSelector(store => store.account.isAdmin);
  return (
    <div style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
      <Switch>
        {
          admin === true
            ? <Route path="/admin/" component={() => (<div style={{background: "white", flexGrow: "1"}}><AdminPanel /></div>)} />
            : <Route path="/admin/" component={AdminLogin} />
        }
        <Route path="*" component={() => <Redirect to="/admin" />} />
      </Switch>
    </div>
  )
}

export default AdminRouter;