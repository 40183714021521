import React, { Fragment} from "react"
import { NavLink, useRouteMatch } from "react-router-dom";
import { adminStyles, adminAlignmentStyles, adminDimensionsStyle, adminTypografyStyles } from "../AdminStyles";


export const DefaultAnalyticsHeader = () => {
  const adminClasses = adminStyles();
  const alignmentClasses = adminAlignmentStyles();
  const dimensionClasses = adminDimensionsStyle();
  const typografyclasses = adminTypografyStyles();
  const { url } = useRouteMatch();

  return (
    <Fragment>
      <div className={alignmentClasses.flex}>
        <NavLink
          className={[
            adminClasses.navLinkButton,
            dimensionClasses.smallHeigth,
            alignmentClasses.flexAlignCenter,
            typografyclasses.fontSize14,
            typografyclasses.fontColor7B7B7B,
          ].join(" ")}
          activeClassName={adminClasses.navLinkActiveButton}
          to={`${url}/general`}
        >
          General analytics
        </NavLink>
        <NavLink
          className={[
            adminClasses.navLinkButton,
            dimensionClasses.smallHeigth,
            alignmentClasses.flexAlignCenter,
            typografyclasses.fontSize14,
            typografyclasses.fontColor7B7B7B,
          ].join(" ")}
          activeClassName={adminClasses.navLinkActiveButton}
          to={`${url}/sectors`}
        >
          Sectors
        </NavLink>
        <NavLink
          className={[
            adminClasses.navLinkButton,
            dimensionClasses.smallHeigth,
            alignmentClasses.flexAlignCenter,
            typografyclasses.fontSize14,
            typografyclasses.fontColor7B7B7B,
          ].join(" ")}
          activeClassName={adminClasses.navLinkActiveButton}
          to={`${url}/company`}
        >
          Deals
        </NavLink>
        <NavLink
          className={[
            adminClasses.navLinkButton,
            dimensionClasses.smallHeigth,
            alignmentClasses.flexAlignCenter,
            typografyclasses.fontSize14,
            typografyclasses.fontColor7B7B7B,
          ].join(" ")}
          activeClassName={adminClasses.navLinkActiveButton}
          to={`${url}/feedback`}
        >
          Feedback
        </NavLink>
      </div>
    </Fragment>
  );
};
