import firebase from "firebase";
import "firebase/auth";
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import './App.css';
import InvestorPreferences from './investor-preferences/preferences';
import Onboarding from './onboarding/Onboarding';
import ConfidentialityAgreement from './pages/ConfidentialityAgreement/ConfidentialityAgreement';
import LandingPage from './pages/LandingPage/LandingPage';
import Loading from './shared/Loading/Loading';
import { useLazyQuery } from '@apollo/client';
import { GET_ACCOUNT_PERMISSIONS, GET_ACCOUNT_STATE, GET_ACCOUNT__BY_ID } from './utils/account-queries';
import { USER_NOT_LOGGEDIN, SET_USER_ACCOUNT, SET_USER_AUTH, SET_INVESTOR_PROFILE } from './store/utils/action-types';
import MainApp from './pages/MainApp/MainApp';
import AdminRouter from './admin/AdminRouter';
import ForgotPersonalInformations from "./onboarding/StepPages/ForgotPersonalInformations";
import ResetPasswordFinalStep from "./pages/ResetPassword/ResetPasswordFinalStep";
import ResetPasswordFirstStep from "./pages/ResetPassword/ResetPasswordFirstStep";
import CustomOnboardingProcess from "./onboarding/CustomOnboardingProcess";
import { permissionAsignmentsActions } from "./store/slices/permissions";
import {  mapSignupDataToSignupState, signupSteps } from './utils/router-checks';
import ForgotLastStep from "./onboarding/StepPages/ForgotLastStep";
import SignupSuccessfulFinal from "./onboarding/StepPages/SignupSuccessfulFinal";
import { GET_INVESTOR_PROFILE } from "./utils/investor-preferances-queries";
import NotApproved from "./utils/not-approved-component";

function App() {
  const account = useSelector(store => store.account);
  const canAccess = useSelector(store => store.permissionAsignments.app_content_access);
  const investorProfileId = useSelector(store => store.account.investorProfileId);
  const investorProfileViewed = useSelector(store => store.investorProfile.first_time_viewed);
  const accountData = useSelector(store => store.account.user);
  const accepted_confidentiality = useSelector(store =>  store.account.user && store.account.user.accepted_confidentiality);
  const goToOnboarding = useSelector(store => store.account.user?.Onboarding_flow_section_edges.filter(x => !x.completed).length > 0 || false)
  const [callGetAccountState, {data: getAccountStateData}] = useLazyQuery(GET_ACCOUNT_STATE, {fetchPolicy: "network-only"});
  const dispatch = useDispatch();
  const [userId, setUserId] = useState('');
  const [refreshJwtToken, setRefreshJwtToken] = useState(null);
  const investorProfileViewedData = useSelector(store => store.investorProfile.first_time_viewed);
  const [getInvestorProfile, { data: investorProfileData }] = useLazyQuery(GET_INVESTOR_PROFILE, { variables: { profile_id: investorProfileId }, fetchPolicy: 'network-only' })
  const [getAccount, { data, error }] = useLazyQuery(GET_ACCOUNT__BY_ID, { fetchPolicy: "network-only" });
  const [signupState, setSignupState] = useState("");

  const getJWTToken = (user) => {
    user.getIdTokenResult(true).then(token => {
      dispatch({ type: SET_USER_AUTH, payload: { jwtToken: token.token } });
    })
  }

  const updateJWTToken = (user, delay) => {
    setRefreshJwtToken(setTimeout(() => getJWTToken(user), delay * 1000 - 5000));
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        const idTokenResult = await user.getIdTokenResult(true);
        if(idTokenResult && idTokenResult.claims && idTokenResult.claims['https://hasura.io/jwt/claims']){
          setUserId(idTokenResult.claims['https://hasura.io/jwt/claims']['x-hasura-user-id']);
        }else{
          setUserId(user.uid);
        }
        getJWTToken(user);
       // console.log("got idtokenresult refreshed", idTokenResult);
        updateJWTToken(user, 3600);
      } else {
        dispatch({ type: USER_NOT_LOGGEDIN });
        clearTimeout(refreshJwtToken);
      }
    }, error => {
      console.log(error);
    });
  }, [])

  useEffect(() => {
    if (investorProfileId) {
      getInvestorProfile()
    }
  }, [investorProfileId])

  useEffect(() => {
    if (account.jwtToken && userId) {
      getAccount({ variables: { userId: userId } })
      callGetAccountState({variables: {firebase_id: userId}});
    }
  }, [account.jwtToken, userId])

  useEffect(() => {
    callGetAccountState({variables: {firebase_id: userId}});
  },[investorProfileViewedData, accountData, accepted_confidentiality, investorProfileViewed, goToOnboarding])

  useEffect(() =>{
    if(getAccountStateData) {
      setSignupState(mapSignupDataToSignupState(getAccountStateData.Account_by_pk, getAccountStateData.Onboarding_flow_section_edge, userId));
    }
  }, [getAccountStateData]);

  useEffect(() => {
    if (data) {
      if (data.Account_by_pk) {
        dispatch({ type: SET_USER_ACCOUNT, payload: data.Account_by_pk });
        dispatch(permissionAsignmentsActions.SET_PERMISSIONS(data.Account_by_pk.permissions.map(x => x.permission)));
        data.Account_by_pk.permissions.map(x => x.permission)
      } else if (account.checkedForLoggedUser) {
        dispatch({ type: USER_NOT_LOGGEDIN });
        clearTimeout(refreshJwtToken);
      }
    }
  }, [data]);

  useEffect(() => {
    if (investorProfileData) {
      dispatch({ type: SET_INVESTOR_PROFILE, payload: investorProfileData.Investor_profile[0] })
    }
  }, [investorProfileData])

  useEffect(() => {
    if (error) {
      dispatch({ type: USER_NOT_LOGGEDIN });
      clearTimeout(refreshJwtToken);
    }
  }, [error]);

  if (!account.checkedForLoggedUser) {
    return (
      <Fragment>
        <Loading fullWidth />
      </Fragment>
    )
  }
  const redirects = (signupState === signupSteps.FORGOT_PERSONAL_INFORMATIONS && <Redirect to="/add-personal-informations" />) ||
    (signupState === signupSteps.ONBOARDING && <Redirect to="/custom-onboarding" />) ||
    (signupState === signupSteps.CONFIDENTIALITY_AGREEMENT && <Redirect to="/confidentiality-agreement" />) ||
    (signupState === signupSteps.INVESTOR_PREFERANCES && <Redirect to="/investor-preferences" />) ||
    (signupState === signupSteps.ACCESS_APP && <Redirect to="/app" />) ||
    (signupState === signupSteps.AWAIT_APPROVAL && <Redirect to="/signup-successful" />)
    // <NotApproved />;

  const noAppRedirects = (signupState === signupSteps.FORGOT_PERSONAL_INFORMATIONS && <Redirect to="/add-personal-informations" />) ||
    (signupState === signupSteps.ONBOARDING && <Redirect to="/custom-onboarding" />) ||
    <Redirect to="/signup-successful" />

  if (account.loggedIn) {
    return (
      <Switch>
        <Route path="/app" component={() => signupState === signupSteps.ACCESS_APP ? <MainApp /> : redirects} />
        <Route path="/signup-successful" component={() => <SignupSuccessfulFinal />} />
        <Route path="/investor-preferences" component={() => signupState === signupSteps.INVESTOR_PREFERANCES ? <InvestorPreferences /> : redirects} />
        <Route path="/confidentiality-agreement" component={() => signupState === signupSteps.CONFIDENTIALITY_AGREEMENT ? <ConfidentialityAgreement /> : redirects} />
        <Route path="/add-personal-informations" component={() => signupState === signupSteps.FORGOT_PERSONAL_INFORMATIONS ? <ForgotPersonalInformations /> : redirects} />
        <Route path="/custom-onboarding" component={() => signupState === signupSteps.ONBOARDING ? <CustomOnboardingProcess /> : redirects} />
        <Route path="/admin" component={() => <AdminRouter />} />
        <Route path="/onboarding" component={() => <Onboarding />} />
        <Redirect from="*" to="/app" />
      </Switch>
    )
  } else {
    return (
      <Switch>
        <Route exact path="/" component={() => account.loggedIn ? (canAccess ? redirects : noAppRedirects) : <LandingPage />} />
        <Route path="/admin" component={() => <AdminRouter />} />
        <Route path="/onboarding" component={() => <Onboarding />} />
        <Route path="/reset-password" component={ResetPasswordFirstStep} />
        <Route path="/set-new-password" component={ResetPasswordFinalStep} />
        <Redirect from="*" to="/" />
      </Switch>
    )
  }
}

export default App;
