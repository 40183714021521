import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GET_INVESTOR_PROFILE_BY_ID } from '../../queries/admin-queries';
import Loading from '../../../shared/Loading/Loading';
import { companyDataExists, industriesDataExists, investmentDataExists } from '../utils';
import { CompanySection, IndustriesSection, InvestmentSection } from './Sections';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { adminAlignmentStyles, adminDimensionsStyle, adminTypografyStyles } from '../../AdminStyles';
import { hasuraErrorHandler } from '../../../shared/EventEmitter/EventEmitter';
import LabeledText from '../../../shared/LabeledText/LabeledText';

const SingleClientPageInvestorProfile = () => {
  const params = useParams();
  const dimensionsClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const typografyClasses = adminTypografyStyles();
  const {data, error} = useQuery(GET_INVESTOR_PROFILE_BY_ID, { variables: { firebase_id: params.id }, fetchPolicy: "network-only" });
  const [status, setStatus] = useState(0);

  useEffect(() => {
    if (data &&
      data.Account[0] &&
      data.Account[0].permissions &&
      data.Account[0].permissions.length > 0) {
      setStatus(data.Account[0].permissions.filter(x => x.role_id === 1).length > 0 ? 1 : 2)
    }
  }, [data]);

  useEffect(() => {
    if(error) {
      hasuraErrorHandler.printError("Error fetching investor profile");
    }
  }, [error])

   if (!data) {
    return <Loading fullWidth />
  }

  hasuraErrorHandler.checkResponseForErrors(data);

  const titleClasses = [typografyClasses.fontSize14, typografyClasses.fontbold, dimensionsClasses.marginTop32, dimensionsClasses.width100Percent, dimensionsClasses.paddingBottom8, dimensionsClasses.marginBottom8].join(' ');
  return (
    <div className={dimensionsClasses.containerWidth}>
      <div className={[alignmentClasses.flexAlignCenter, typografyClasses.fontSize14].join(' ')} style={{ color: "#6A6A6A" }}>
        <span>Status</span>
        <span style={{marginLeft: "12px"}}>{status === 1 ? "Approved" : "Not approved"}</span>
      </div>
      <div style={{display: "flex", flexWrap: "wrap", marginTop: "36px"}}>
          <LabeledText customStyles={{width: "200px"}} label="First name" text={data.Account[0].first_name}/>
          <LabeledText customStyles={{width: "200px"}} label="Last name" text={data.Account[0].last_name} />
          <LabeledText customStyles={{width: "200px"}} label="Phone" text={data.Account[0].phone_number} />
          <LabeledText customStyles={{width: "200px"}} label="Country" text={data.Account[0].country} />
          <LabeledText customStyles={{flexGrow: "1"}} label="City" text={data.Account[0].city} />
          <LabeledText customStyles={{width: "200px", marginTop: "32px"}} label="Client" text={data.Account[0].app_client} />
          <LabeledText customStyles={{width: "200px", marginTop: "32px"}} label="Accredited Investor" text={data.Account[0].accredited_investor} />

      </div>
      <div className={titleClasses} style={{ borderBottom: "1px solid #D4D4D4" }}>
        Industries
        </div>
      <div className={alignmentClasses.flexWrap}>
        {
          data.Investor_profile[0] && industriesDataExists(data.Investor_profile[0])
            ? <IndustriesSection investorProfile={data.Investor_profile[0]} />
            : <p className={typografyClasses.fontSize14} style={{ color: "#6A6A6A" }}>No data added</p>
        }
      </div>
      <p className={titleClasses} style={{ borderBottom: "1px solid #D4D4D4" }}>
        Company
      </p>
      <div className={alignmentClasses.flexWrap}>
        {
          data.Investor_profile[0] && companyDataExists(data.Investor_profile[0])
            ? <CompanySection investorProfile={data.Investor_profile[0]} />
            : <p className={typografyClasses.fontSize14} style={{ color: "#6A6A6A" }}>No data added</p>
        }
      </div>
      <p className={titleClasses} style={{ borderBottom: "1px solid #D4D4D4" }}>
        Investment
          </p>
      <div className={alignmentClasses.flexWrap}>
        {
          data.Investor_profile[0] && investmentDataExists(data.Investor_profile[0])
            ? <InvestmentSection investorProfile={data.Investor_profile[0]} />
            : <p className={typografyClasses.fontSize14} style={{ color: "#6A6A6A" }}>No data added</p>
        }
      </div>
    </div>
  )
}

export default SingleClientPageInvestorProfile;