import React, { useState } from 'react';
import { adminAlignmentStyles, adminDimensionsStyle, adminStyles, adminTablesStyle, adminTypografyStyles } from '../AdminStyles';
import ChatMessages from './ChatMessages';
import { DefaultChatHeader } from './Headers';
import NewMessagesContainer from './NewMessagesContainer';

const AdminChatPanel = () => {
  const adminClasses = adminStyles();
  const alignmentClasses = adminAlignmentStyles();
  const [selectedChat, setSelectedChat] = useState(null);
  const [nameFilter, setNameFilter] = useState("");

  return (
    <div style={{marginTop: "63px"}}>
    <div className={adminClasses.headerContainer} style={{justifyContent: "flex-start"}}>
      <div className={adminClasses.header} style={{marginLeft: "164px"}}>
        <DefaultChatHeader handleNameFilterChange={value => setNameFilter(value)}/>        
      </div>
    </div>
    <div className={[adminClasses.contentContainer, alignmentClasses.flex].join(' ')} style={{height: "calc(100vh - 116px)"}}>
      <NewMessagesContainer setSelectedChat={chat => setSelectedChat(chat)} nameFilter={nameFilter}/>
      {
        selectedChat && <ChatMessages selectedChat={selectedChat}/>
      }
     
    </div>
  </div>
  )
}

export default AdminChatPanel;