import React, { useEffect, useState } from 'react';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from '../../AdminStyles';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { GET_ALL_ONBOARDING_FLOWS, GET_DEFAULT_FLOW, GET_ONBOARDING_FLOW_FOR_CLONE, GET_ONBOARDING_FLOW_SECTION_IDS, SET_ALL_FLOWS_NOT_DEFAULT, SET_FLOW_DEFAULT } from '../../queries/admin-queries';
import Loading from '../../../shared/Loading/Loading';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { deleteEdges, saveGeneralFlowTemplate } from '../../queries/admin-http-calls';
import { Events, EventThrower } from '../../../shared/EventEmitter/EventEmitter';
import { mapOnboardingToEdges, mapOnboardingForClone } from './onboardingMapper';
import { useSelector } from 'react-redux';


export const OnboardingTemplateList = ({ nameFilter }) => {
  const tableClasses = adminTablesStyle();
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const typographyClasses = adminTypografyStyles();
  const canAccess = useSelector(store => store.permissionAsignments.handle_onboarding)
  const [defaultFlowId, setDefaultFlowId] = useState(0);
  const [getFlowSectionIds, { data: deleteFlowEdgesData, loading: deleteFlowEdgesLoading }] = useLazyQuery(GET_ONBOARDING_FLOW_SECTION_IDS);
  const [getFlowSectionIdsForDefault, { data: defaultFlowEdgesData, loading: defaultFlowEdgesLoading }] = useLazyQuery(GET_ONBOARDING_FLOW_SECTION_IDS);
  const [setFlowsDefaultFalse] = useMutation(SET_ALL_FLOWS_NOT_DEFAULT);
  const [setDefaultFlow] = useMutation(SET_FLOW_DEFAULT);
  const [getFlowForClone, { data: cloneFlowData, loading: cloneFlowLoading }] = useLazyQuery(GET_ONBOARDING_FLOW_FOR_CLONE);
  const [getAllFlows, { data, loading }] = useLazyQuery(GET_ALL_ONBOARDING_FLOWS, { variables: { filter: `%${nameFilter}%` }, fetchPolicy: "network-only" });
  const { data: defaultFlowIdData } = useQuery(GET_DEFAULT_FLOW, {fetchPolicy: "network-only"});

  const handleDeleteButton = (flowId) => {
    getFlowSectionIds({ variables: { flow_id: flowId } });
  }

  const handleCloneButton = (flowId) => {
    getFlowForClone({ variables: { id: flowId } });
  }

  const handleDefaultbutton = (flowId) => {
    getFlowSectionIdsForDefault({ variables: { flow_id: flowId } });
    setDefaultFlowId(flowId);
  }

  useEffect(() => {
    getAllFlows();
  }, [])

  useEffect(() => {
    if (cloneFlowData && cloneFlowData.Flow_node_by_pk) {
      saveGeneralFlowTemplate(mapOnboardingForClone(cloneFlowData.Flow_node_by_pk), "onboarding")
        .then(resp => resp.json())
        .then(result => {
          if (result.status === "OK") {
            getAllFlows();
          }
        }).catch(console.log)
    }
  }, [cloneFlowData, cloneFlowLoading])

  useEffect(() => {
    if (deleteFlowEdgesData) {
      const edges = mapOnboardingToEdges(deleteFlowEdgesData);
      deleteEdges(edges.sectionEdges, edges.itemEdges, "onboarding").then(() => {
        getAllFlows();
        EventThrower.throwClientSideMessage(Events.SuccessMessage("General flow deleted"));
      }).catch(console.log);
    }
  }, [deleteFlowEdgesData, deleteFlowEdgesLoading])

  useEffect(() => {
    if (defaultFlowEdgesData) {
      const edges = mapOnboardingToEdges(defaultFlowEdgesData);
      setFlowsDefaultFalse().then(setAllFlowsNotDefaultResult => {
        setDefaultFlow({
          variables: {
            flow_section_edges_id: edges.sectionEdges,
            flow_item_edge_id: edges.itemEdges
          }
        })
      })

    }
  }, [defaultFlowEdgesData, defaultFlowEdgesLoading])

  useEffect(() => {
    if (defaultFlowIdData && defaultFlowIdData.Onboarding_flow_section_edge[0]?.Flow_node.id) {
      setDefaultFlowId(defaultFlowIdData.Onboarding_flow_section_edge[0]?.Flow_node.id)
    }
  }, [defaultFlowIdData])

  if(!canAccess) return <div style={{marginTop: "80px", textAlign: "center"}}>You do not have permission to view the onboardings tab</div>
  
  if (loading) return <Loading fullWidth />

  return (
    <div className={dimensionClasses.headerMarginFix}>
      <Button color="primary" variant="outlined" className={[dimensionClasses.mediumHeight, dimensionClasses.marginTop12].join(' ')} component={Link} to="/admin/clients/onboarding/add">
        <AddIcon className={dimensionClasses.marginRight12} />
        Add Onboarding Flow
      </Button>
      <div className={[dimensionClasses.containerWidth, alignmentClasses.flexAlignCenter, tableClasses.tableHeader, typographyClasses.fontSize14, dimensionClasses.marginTop24].join(' ')}>
        <div className={dimensionClasses.widthGrow} style={{ minWidth: "400px" }}>Name</div>
        <div className={dimensionClasses.width150}>Created</div>
        <div className={dimensionClasses.width150}>Updated</div>
        <div className={dimensionClasses.width250}>Actions</div>
      </div>
      {
        data && data.Flow_node && data.Flow_node.slice()
          .sort((a, b) => {
            if (a.id === defaultFlowId) return -1;
            if (b.id === defaultFlowId) return 1;
            return a.updated_at - b.updated_at;
          })
          .map((flow, index) => (
            <div key={index} className={[dimensionClasses.containerWidth, alignmentClasses.flexAlignCenter, tableClasses.row, typographyClasses.fontSize14].join(' ')}>
              <p className={dimensionClasses.widthGrow}>{flow.name} {defaultFlowId === flow.id && "(DEFAULT)"}</p>
              <p className={dimensionClasses.width150}>{new Date(flow.created_at).toLocaleDateString()}</p>
              <p className={dimensionClasses.width150}>{new Date(flow.updated_at).toLocaleDateString()}</p>
              <div className={dimensionClasses.width250}>
                <Tooltip arrow title="Clone">
                  <IconButton style={{ padding: "4px", marginLeft: "8px" }} onClick={() => handleCloneButton(flow.id)}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Edit">
                  <IconButton style={{ padding: "4px", marginLeft: "8px" }} component={Link} to={`/admin/clients/onboarding/edit/${flow.id}`}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Delete">
                  <IconButton style={{ padding: "4px", marginLeft: "8px" }} onClick={() => handleDeleteButton(flow.id)}  disabled={defaultFlowId === flow.id}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Button variant="contained" color="primary" className={dimensionClasses.smallHeigth} disabled={defaultFlowId === flow.id} onClick={() => handleDefaultbutton(flow.id)}>Set default</Button>
              </div>
            </div>
          ))
      }
    </div>
  )
}

export default OnboardingTemplateList;