import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { adminDimensionsStyle, adminAlignmentStyles } from '../../../AdminStyles';
import OpportunityPreviewContainer from './OpportunityPreviewContainer';

const containerStyle={
  padding: "40px 0px 0px 40px", 
  width: "890px", 
  maxWidth: "none", 
  height: "90%",
  boxSizing: "border-box", 
  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)"
}
const OpportunityPreviewDialog = ({ open, setOpen }) => {
  const dimensionClasses = adminDimensionsStyle();

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ style: containerStyle }} disableScrollLock={true}>
      <IconButton onClick={handleClose} style={{position: "absolute", top: "4px", right: "4px", padding: "0px"}}>
          <CloseIcon />
        </IconButton>
      <DialogContent className={[dimensionClasses.padding0, dimensionClasses.hideOverflow].join(' ')} >
       <OpportunityPreviewContainer />
      </DialogContent>
    </Dialog>
  );
}

export default OpportunityPreviewDialog;