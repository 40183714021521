import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generalStyleClasses } from '../../utils/general-styles';
import CheckboxesGroup from '../CheckboxesGroup/CheckboxesGroup';
import { businessModels } from './Options/CompanyOptions';

const BussinessModels = ({handleChange, rootClasses={}, ovwerwriteDefaultValues}) => {
  const classes = generalStyleClasses();
  const storeValues = useSelector(store => {
    let selectedValues = [];
    if(store.investorProfile.company_business_model_B2B) {
      selectedValues.push({value: 1})
    }
    if(store.investorProfile.company_business_model_B2B2C) {
      selectedValues.push({value: 3})
    }
    if(store.investorProfile.company_business_model_B2C) {
      selectedValues.push({value: 2})
    }
    return selectedValues;
  });
  const [defaultValues] = useState(ovwerwriteDefaultValues || storeValues);

  useEffect(() => {
    if(defaultValues && defaultValues.length > 0) {
      handleChange(defaultValues);
    }
  }, [defaultValues])
  
  return (
    <div className={rootClasses}>
      <span className={[classes.fontSize14, classes.fontInputColor].join(' ')}>Business models</span>
      <div className={classes.marginTop16}>
        <CheckboxesGroup optionsInput={businessModels} handleChange={handleChange} defaultValues={defaultValues}/>
      </div>
    </div>
  )
}

export default BussinessModels;