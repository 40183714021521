import React, { useEffect, useState } from 'react';
import EventEmitter from '../EventEmitter/EventEmitter';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

const ErrorHandler = () => {
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageSeverity, setMessageSeverity] = useState('');
    const [icon, setIcon] = useState(null);
    useEffect(() => {
        EventEmitter.on("CLIENT_SIDE_ERROR", (payload) => {
            setOpen(true);
            setMessageSeverity(payload.severity);
            setSnackbarMessage(payload.message);
            setIcon(payload.icon || null);
        })
        EventEmitter.on("CLIENT_SIDE_MESSAGE", (payload) => {
            setOpen(true);
            setMessageSeverity(payload.severity);
            setSnackbarMessage(payload.message);
            setIcon(payload.icon || null);
        })
    });

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={3000} onClose={() => setOpen(false)}>
                <MuiAlert style={{ paddingRight: '8px' }} variant="filled" severity={messageSeverity} icon={icon} >{snackbarMessage}
                    {
                        messageSeverity !== "success"
                            ? (
                                <IconButton style={{ marginLeft: '16px' }} size="small" aria-label="close" color="inherit" onClick={() => setOpen(false)}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            )
                            : ""
                    }

                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default ErrorHandler;