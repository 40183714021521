import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import './navbar.scss';
import firebase from 'firebase';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { LOGOUT, SET_USER_AUTH } from '../../store/utils/action-types';
import { permissionAsignmentsActions } from '../../store/slices/permissions';

const Navbar = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const handleLogoClick = () => {
            firebase.auth().signOut().then(() => {
              dispatch({type: SET_USER_AUTH, payload: {jwtToken: ''}});
              dispatch({ type: LOGOUT });
              dispatch(permissionAsignmentsActions.RESET_PERMISSIONS());
              history.push('/');
            })
    }
    return (
        <div className="navbar-root" {...props}>
            <div className="container">
                <Button onClick={() => handleLogoClick()}>
                    <img src={`${process.env.PUBLIC_URL}/images/logos/${localStorage.getItem("companyLogo")}`} alt="company logo" />
                </Button>
                <span style={{ fontSize: '14px', color: '#6F7074' }}>{localStorage.getItem("companySlogan")}</span>
            </div>
        </div >
    )
}

export default Navbar;