import React, { useEffect, useState } from "react";
import {
  adminAlignmentStyles,
  adminDimensionsStyle,
  adminInputStyles,
  adminTypografyStyles,
} from "../AdminStyles";
import { oportunitySectionStyle } from "../OpportunitiesPage/AddOportunity/OpportunitySections/OportunitySectionStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import UploadComponent from "../../shared/DocumentHandler/UploadComponent";
import { useDispatch, useSelector } from "react-redux";
import { addInvestmentFlowActions } from "../../store/utils/action-types";
import { Radio } from "@material-ui/core";
import DownloadComponent from "../../shared/DocumentHandler/DownloadComponent";
import { deleteDocumentsForFlowEdge } from "../../utils/file-queries";
import { Fragment } from "react";

const customStyles = makeStyles({
  footer: {
    padding: "16px",
    background: "#F9F9F9",
  },
  content: {
    padding: "16px",
  },
  section: {
    borderLeft: "1px solid #E5E5E5",
    paddingLeft: "12px",
  },
});

const sectionTypes = {
  DOCUSIGN: "DOCUSIGN",
  TEXT: "TEXT",
  UPLOAD: "UPLOAD",
  DOWNLOAD: "DOWNLOAD",
};

const StepPartActions = ({ part, sectionId, flowCompleted, sectionsLength, order }) => {
  const stepClasses = oportunitySectionStyle();
  const dispatch = useDispatch();

  const handleRemovePart = () => {
    dispatch({
      type: addInvestmentFlowActions.DELETE_STEP_PART,
      payload: {
        sectionId: sectionId,
        partId: part.id,
      }
    })
  }

  const handleReorderPart = (newOrder) => {
    dispatch({
      type: addInvestmentFlowActions.REORDER_STEP_PARTS,
      payload: {
        sectionId: sectionId,
        oldOrder: part.order,
        newOrder: newOrder,
      }
    })
  }

  return (
    <Fragment>
      <span className={stepClasses.headerTitle}>Order</span>
      <FormControl variant="outlined" style={{ width: "100%" }}>
        <Select
          MenuProps={{ variant: "menu", disableScrollLock: true }}
          className={stepClasses.select}
          value={part.order || order}
          disabled={part.disableUpdate}
          onChange={(e) =>
            handleReorderPart(e.target.value)
          }
        >
          {Array.from({ length: sectionsLength }, (_, i) => i).map((x, index) => (
            <MenuItem key={index} value={x}>
              {x + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton
        disabled={flowCompleted}
        onClick={() => handleRemovePart()}
      >
        <DeleteIcon />
      </IconButton>
    </Fragment>
  )
}

const InvestmentFlowStep = ({ stepData, isClient }) => {
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const inputClasses = adminInputStyles();
  const typografyClasses = adminTypografyStyles();
  const stepClasses = oportunitySectionStyle();
  const customClasses = customStyles();
  const [order, setOrder] = useState(stepData.order);
  const stepNumber = useSelector(
    (store) => store.adminAddInvestorFlowState.steps.length
  );
  const dispatch = useDispatch();
  const [extended, setExtended] = useState(true);
  const [sections, setSections] = useState(stepData.parts);
  const [title, setTitle] = useState(stepData.title);
  const [userDocuments, setUserDocuments] = useState([]);
  const [completed, setCompleted] = useState(!!stepData.completed);
  const [active, setActive] = useState(!!stepData.current);
  const flowCompleted = useSelector(
    (store) => store.adminAddInvestorFlowState.investmentCompleted
  );

  useEffect(() => {
    if (isClient) {
      setUserDocuments(
        [].concat.apply(
          [],
          [
            ...[...stepData.parts.filter((x) => x.type === "UPLOAD")].map(
              (x) => x.data?.files || []
            ),
          ]
        )
      );
    }
  }, [isClient, stepData]);

  const addSection = (type) => {
    dispatch({
      type: addInvestmentFlowActions.SET_STEP_DATA,
      payload: {
        id: stepData.id,
        parts: [
          ...sections,
          {
            id: sections.length > 0 ? Math.max(...sections.map((x) => x.id)) + 1 : 1,
            type: type,
            data: null,
            disableUpdate: false,
            order: sections.length,
          },
        ],
      },
    });
  };

  const setSectionData = (sectionId, data) => {
    const newSections = sections.map((x) => {
      if (x.id === sectionId) {
        x.data = data;
      }
      return x;
    })
    dispatch({ type: addInvestmentFlowActions.SET_STEP_DATA, payload: { id: stepData.id, parts: newSections } });
  };

  const handleChangeStepTitle = (name) => {
    setTitle(name);
    dispatch({
      type: addInvestmentFlowActions.SET_STEP_TITLE,
      payload: { id: stepData.id, name: name },
    });
  };

  const handleReorderSections = (newSectionOrder) => {
    dispatch({
      type: addInvestmentFlowActions.REORDER_STEPS,
      payload: { newOrder: newSectionOrder, oldOrder: order },
    });
  };

  const handleChangeExtended = () => {
    dispatch({
      type: addInvestmentFlowActions.SET_STEP_EXPANDED,
      payload: { id: stepData.id },
    });
    setExtended(!extended);
  };

  const handleDeleteStep = () => {
    dispatch({
      type: addInvestmentFlowActions.DELETE_STEP,
      payload: { id: stepData.id, edgeId: stepData.edgeId || null },
    });
  };

  useEffect(() => {
    setOrder(stepData.order);
  }, [stepData.order]);

  useEffect(() => {
    setTitle(stepData.title);
  }, [stepData.title]);

  useEffect(() => {
    setSections(stepData.parts);
  }, [stepData.parts]);

  useEffect(() => {
    setExtended(stepData.extended);
  }, [stepData.extended]);

  useEffect(() => {
    setCompleted(!!stepData.completed);
  }, [stepData.completed]);

  useEffect(() => {
    setActive(!!stepData.current);
  }, [stepData.current]);

  const handleChangeCompletedStatus = () => {
    dispatch({
      type: addInvestmentFlowActions.CHANGE_STEP_COMPLETED,
      payload: { id: stepData.id },
    });
  };

  const handleSetActive = () => {
    dispatch({
      type: addInvestmentFlowActions.SET_ACTIVE_STEP,
      payload: { id: stepData.id },
    });
  };

  return (
    <div className={stepClasses.container}>
      <div className={stepClasses.header}>
        <div className={stepClasses.headerSection} style={{ flexGrow: "1" }}>
          <IconButton onClick={() => handleChangeExtended()}>
            {extended ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <span>Step Title</span>
          <input
            disabled={stepData.disableTitleUpdate}
            placeholder="eg. Deal terms"
            value={title}
            onChange={(e) => handleChangeStepTitle(e.target.value)}
            className={[inputClasses.input, typografyClasses.fontSize14].join(
              " "
            )}
            style={{
              height: "32px",
              maxWidth: "580px",
              flexGrow: "1",
              marginLeft: "12px",
            }}
          />
        </div>
        <div className={stepClasses.headerSection}>
          <span className={stepClasses.headerTitle}>Order</span>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <Select
              MenuProps={{ variant: "menu", disableScrollLock: true }}
              className={stepClasses.select}
              value={order}
              onChange={(e) => handleReorderSections(e.target.value)}
              disabled={stepData.disableTitleUpdate}
            >
              {Array.from({ length: stepNumber }, (_, i) => i).map(
                (x, index) => (
                  <MenuItem key={index} value={x}>
                    {x + 1}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <IconButton
            disabled={flowCompleted}
            onClick={() => handleDeleteStep()}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      {extended ? (
        <div className={customClasses.content}>
          {sections
            .sort((a, b) => a.order - b.order)
            .map((section, index) => {
              switch (section.type) {
                case sectionTypes.DOCUSIGN:
                  return (
                    <div
                      key={index}
                      className={[
                        dimensionClasses.width100Percent,
                        customClasses.section,
                        dimensionClasses.marginbottom16,
                        alignmentClasses.flexAlignCenter,
                        dimensionClasses.smallHeigth,
                      ].join(" ")}
                    >
                      <p
                        className={[
                          dimensionClasses.marginBottom0,
                          dimensionClasses.widthGrow,
                          typografyClasses.fontSize14,
                          dimensionClasses.marginTop0,
                        ].join(" ")}
                        style={{ color: "#333333" }}
                      >
                        Docusign (visible on the client)
                      </p>
                      <div className={stepClasses.headerSection}>
                        <StepPartActions order={index} part={section} sectionsLength={sections.length} flowCompleted={flowCompleted} sectionId={stepData.id} />
                      </div>
                    </div>
                  );

                case sectionTypes.DOWNLOAD:
                  return (
                    <div
                      key={index}
                      className={[
                        dimensionClasses.width100Percent,
                        customClasses.section,
                        dimensionClasses.marginbottom16,
                        alignmentClasses.flexWrap
                      ].join(" ")}
                    >
                        <p
                          className={[
                            dimensionClasses.marginBottom0,
                            "auto",
                            typografyClasses.fontSize14,
                            dimensionClasses.marginTop0,
                          ].join(" ")}
                          style={{ color: "#333333" }}
                        >
                          Add documents (downloadable for the client)
                        {!section.edgeId
                            ? " - Please save the changes first in order to upload files"
                            : ""}
                        </p>
                   
                      <div className={stepClasses.headerSection} style={{ marginLeft: "auto", height: "24px" }} >
                        <StepPartActions order={index} part={section} sectionsLength={sections.length} flowCompleted={flowCompleted} sectionId={stepData.id} />
                      </div>
                      <UploadComponent
                          deleteCallback={(fileId) =>
                            deleteDocumentsForFlowEdge(fileId)
                          }
                          downloadRoute="investment"
                          initialFiles={section.data || []}
                          customStyle={{ width: "calc(100% - 12px)" }}
                          disabled={!section.edgeId || flowCompleted}
                          setNewFiles={(files) =>
                            setSectionData(section.id, files)
                          }
                        />
                    </div>
                  );

                case sectionTypes.TEXT:
                  return (
                    <div
                      key={index}
                      className={[
                        dimensionClasses.width100Percent,
                        customClasses.section,
                        dimensionClasses.marginbottom16,
                        alignmentClasses.flexWrap,
                        alignmentClasses.flexAlignCenter,
                      ].join(" ")}
                    >
                      <p
                        className={[
                          dimensionClasses.marginBottom0,
                          dimensionClasses.width100Percent,
                          typografyClasses.fontSize14,
                          dimensionClasses.marginTop0,
                        ].join(" ")}
                        style={{ color: "#333333" }}
                      >
                        Text
                      </p>
                      <input
                        disabled={section.disableUpdate}
                        onChange={(x) =>
                          setSectionData(section.id, x.target.value)
                        }
                        value={section.data || null}
                        placeholder="eg. Deal terms"
                        className={[
                          inputClasses.input,
                          typografyClasses.fontSize14,
                          dimensionClasses.widthGrow,
                        ].join(" ")}
                        style={{ height: "32px" }}
                      />
                      <div className={stepClasses.headerSection} style={{ marginLeft: "10px" }}>
                        <StepPartActions order={index} part={section} sectionsLength={sections.length} flowCompleted={flowCompleted} sectionId={stepData.id} />
                      </div>
                    </div>
                  );

                case sectionTypes.UPLOAD:
                  return (
                    <div
                      key={index}
                      className={[
                        dimensionClasses.width100Percent,
                        customClasses.section,
                        dimensionClasses.marginbottom16,
                        alignmentClasses.flexAlignCenter,
                        dimensionClasses.smallHeigth,
                      ].join(" ")}
                    >
                      <p
                        className={[
                          dimensionClasses.marginBottom0,
                          dimensionClasses.widthGrow,
                          typografyClasses.fontSize14,
                          dimensionClasses.marginTop0,
                        ].join(" ")}
                        style={{ color: "#333333" }}
                      >
                        Document sender (client document uploader)
                      </p>
                      <div className={stepClasses.headerSection}>
                        <StepPartActions order={index} part={section} sectionsLength={sections.length} flowCompleted={flowCompleted} sectionId={stepData.id} />
                      </div>
                    </div>
                  );

                default:
                  break;
              }
            })}
          <div className={[alignmentClasses.flex].join(" ")}>
            <Button
              variant="outlined"
              color="primary"
              className={[
                dimensionClasses.marginRight12,
                dimensionClasses.smallHeigth,
              ].join(" ")}
              onClick={() => addSection(sectionTypes.TEXT)}
              disabled={flowCompleted}
            >
              <AddIcon style={{ marginRight: "8px" }} />
              Text
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={[
                dimensionClasses.marginRight12,
                dimensionClasses.smallHeigth,
              ].join(" ")}
              onClick={() => addSection(sectionTypes.UPLOAD)}
              disabled={flowCompleted}
            >
              <AddIcon style={{ marginRight: "8px" }} />
              Request Documents
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={[
                dimensionClasses.marginRight12,
                dimensionClasses.smallHeigth,
              ].join(" ")}
              onClick={() => addSection(sectionTypes.DOCUSIGN)}
              disabled={flowCompleted}
            >
              <AddIcon style={{ marginRight: "8px" }} />
              Docusign
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={[
                dimensionClasses.marginRight12,
                dimensionClasses.smallHeigth,
              ].join(" ")}
              onClick={() => addSection(sectionTypes.DOWNLOAD)}
              disabled={flowCompleted}
            >
              <AddIcon style={{ marginRight: "8px" }} />
              Send Documents
            </Button>
          </div>
        </div>
      ) : (
          ""
        )}
      {isClient ? (
        <div
          style={{
            padding: "16px",
            background: "#F9F9F9",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <FormControlLabel
            disabled={flowCompleted}
            value="active"
            control={
              <Radio checked={active} onClick={() => handleSetActive()} />
            }
            label="Active"
          />
          <div>
            <span>Status: </span>
            <FormControlLabel
              disabled={flowCompleted}
              value="completed"
              control={
                <Checkbox
                  color="primary"
                  checked={completed}
                  onClick={() => handleChangeCompletedStatus()}
                />
              }
              label="Completed"
              labelPlacement="end"
            />
          </div>
          <div style={{ width: "100%" }}>
            <DownloadComponent title={""} files={userDocuments} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InvestmentFlowStep;
