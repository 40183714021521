import React, { Fragment, useEffect, useState } from 'react';
import Loading from '../../../../../../shared/Loading/Loading';
import { fileDownloader } from '../../../../../../utils/file-queries';
import { generalStyleClasses } from '../../../../../../utils/general-styles';

const ImageSection = ({ title, fieldId }) => {
  const generalClasses = generalStyleClasses();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if(fieldId) {
      fileDownloader(`deal/section/image/${fieldId}`).then(response => {
        setLoading(false);
        setImage(URL.createObjectURL(response))
      });
    }
  }, [fieldId])

  return (
    <Fragment>
      <p className={[generalClasses.marginTop64, generalClasses.fontSize18, generalClasses.fontColorDark].join(' ')}>{title}</p>
      {
        loading
        ? <Loading customStyle={{position: "relative",height: "auto"}}/>
        : <img src={image || `${process.env.PUBLIC_URL}/images/no-image.png`} alt="image section" style={{width: "100%"}}/>
      }
      
    
    </Fragment>
  )
}

export default ImageSection;