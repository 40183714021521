import { makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import ReactQuill from 'react-quill';
import { generalStyleClasses } from '../../../../../../utils/general-styles';

const quillStyle = makeStyles({
  quill: {
    "& .ql-container.ql-snow.ql-disabled": {
      border: "none",
      "& .ql-editor": {
        padding: "0",
      }
    }
  }
})
const RichTextArea = ({ title, body }) => {
  const generalClasses = generalStyleClasses();
  const quillClasses = quillStyle();
  return (
    <Fragment>
      <p className={[generalClasses.marginTop64, generalClasses.fontSize18, generalClasses.fontColorDark].join(' ')}>{title}</p>
      <ReactQuill value={body} readOnly modules={{toolbar: false}} className={quillClasses.quill} />
    </Fragment>
  )
}

export default RichTextArea;