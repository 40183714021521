import React, { Fragment, useEffect, useRef, useState } from 'react';
import SmallButtonGroup from '../../../../../shared/SmallButtonsGroup/SmallButttonsGroup';
import MultipleSelect from '../../../../../shared/MultipleSelect/MultipleSelectField';
import { lastValuationFilter, companyAge } from "./filterOptions";
import { generalStyleClasses } from '../../../../../utils/general-styles';
import { useQuery } from '@apollo/client';
import { SECTORS, SECTOR_ENABLERS } from "../../../../../utils/enum-queries";
import { Button, FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import { Filters } from "./filterHolder";
import { useDispatch } from 'react-redux';
import { SET_DEAL_FILTERS } from '../../../../../store/utils/action-types';

const diabledButtonStyle = makeStyles({
  button: {
    "&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.makeStyles-fontSize14-19.Mui-disabled.Mui-disabled": {
      background: "none !important",
      color: "#A9A9A9"
    }
  }
})

const OpportunitiesFilters = () => {
  const buttonClasses = diabledButtonStyle();
  const classes = generalStyleClasses();
  const sectorOptions = useQuery(SECTORS).data;
  const sectorEnablerOptions = useQuery(SECTOR_ENABLERS).data
  const [lastValuationInput, setLastValuationInput] = useState(-1);
  const [companyAgeInput, setCompanyAgeInput] = useState(-1);
  const [industriesInput, setIndustriesInput] = useState([]);
  const [inovationEnablersInput, setInovationEnablersInput] = useState([]);
  const [foundingStageInput, setFoundingStageInput] = useState("_");
  const industriesInputRef = useRef();
  const inovationEnablersInputRef = useRef();
  const lastValuationRef = useRef();
  const companyAgeRef = useRef();
  const dispatch = useDispatch();

  const disabledButtons = () => {
    return lastValuationInput === -1 && companyAgeInput === -1 && industriesInput.length === 0 && inovationEnablersInput.length === 0 && foundingStageInput === "_";
  }

  useEffect(() => {
    if (industriesInput.length > 0) {
      Filters.setIndustryTypes(industriesInput.map(x => x.value))
    }else {
      Filters.setIndustryTypes([]);
    }
    applyFilters();
  }, [industriesInput])

  useEffect(() => {
    if (inovationEnablersInput.length > 0) {
      Filters.setInovationEnablers(inovationEnablersInput.map(x => x.value))
    } else {
      Filters.setInovationEnablers([]);
    }
    applyFilters();
  }, [inovationEnablersInput])

  useEffect(() => {
    if (lastValuationInput !== -1) {
      Filters.setLastValuation(lastValuationInput.value || 0)
    } else {
     Filters.setLastValuation(0)
    }
    applyFilters();
  }, [lastValuationInput])

  useEffect(() => {
    if (companyAgeInput !== -1) {
      Filters.setCompanyAge(companyAgeInput.value || 0)
    } else {
     Filters.setCompanyAge(0) 
    }
    applyFilters();
  }, [companyAgeInput])

  useEffect(() => {
    if (foundingStageInput !== "_") {
      Filters.setFundingStage(foundingStageInput === "ANY" ? "" : foundingStageInput)
    } else {
      Filters.setFundingStage("");
    }
    applyFilters();
  }, [foundingStageInput])

  const applyFilters = () => {
    Filters.page = 0;
    dispatch({ type: SET_DEAL_FILTERS, payload: Filters.getContent() });
  }

  const resetFilters = () => {
    Filters.resetFilters();
    lastValuationRef.current.reset();
    companyAgeRef.current.reset();
    industriesInputRef.current.reset();
    inovationEnablersInputRef.current.reset();
    setLastValuationInput(-1);
    setCompanyAgeInput(-1);
    setFoundingStageInput("_");
    setIndustriesInput([]);
    setInovationEnablersInput([]);
    dispatch({ type: SET_DEAL_FILTERS, payload: Filters.getContent() });
  }

  return (
    <Fragment>
      <p className={[classes.fontColorLight, classes.marginTop16, classes.marginBottom0].join(' ')}>Last Valuation</p>
      <SmallButtonGroup ref={lastValuationRef} buttonList={lastValuationFilter} handleChange={setLastValuationInput} initialValue={lastValuationInput}/>
      <p className={[classes.fontColorLight, classes.marginTop16, classes.marginBottom0].join(' ')}>Company Age</p>
      <SmallButtonGroup ref={companyAgeRef} buttonList={companyAge} handleChange={setCompanyAgeInput} initialValue={companyAgeInput}/>
      <p className={[classes.fontColorLight, classes.marginTop16, classes.marginBottom8].join(' ')}>Sectors</p>
      <MultipleSelect ref={industriesInputRef} optionsInput={sectorOptions ? sectorOptions.ENUM_interested_sectors : []} defaultValues={industriesInput} placeholder="e.g Retail, Software" handleChange={setIndustriesInput} />
      <p className={[classes.fontColorLight, classes.marginTop16, classes.marginBottom8].join(' ')}>Innovation Enablers</p>
      <MultipleSelect ref={inovationEnablersInputRef} optionsInput={sectorEnablerOptions ? sectorEnablerOptions.ENUM_innovation_enablers : []} defaultValues={inovationEnablersInput} placeholder="e.g. SaaS, Fintech" handleChange={setInovationEnablersInput} />
      <FormControl variant="outlined" style={{ width: "100%" }} >
        <Select
          className={[classes.fontColorLight, classes.marginTop16, classes.styledSelect].join(' ')}
          MenuProps={{ variant: "menu", disableScrollLock: true }}
          value={foundingStageInput}
          onChange={e => setFoundingStageInput(foundingStageInput === e.target.value ? "_" : e.target.value)}
        >
          <MenuItem value={"_"}>Funding stage</MenuItem>
          <MenuItem value={"SEED"}>Seed</MenuItem>
          <MenuItem value={"EARLY"}>Early</MenuItem>
          <MenuItem value={"MID"}>Mid</MenuItem>
          <MenuItem value={"LATE"}>Late</MenuItem>
          <MenuItem value={"ANY"}>Any stage</MenuItem>
        </Select>
      </FormControl>
      <Button disabled={disabledButtons()} style={{width: "100%", height: "30px", textTransform: "none", background: "white" }} onClick={() => resetFilters()} className={[classes.marginTop24, classes.fontSize14, buttonClasses.button, classes.fontColorLight].join(' ')} variant="outlined">Clear filters</Button>
      {/* <div className={[classes.flexSpaceBetweenCenter, classes.marginTop24].join(' ')}>
        <Button disabled={disabledButtons()} style={{ width: "126px", height: "30px" }} variant="contained" color="primary" onClick={() => applyFilters()}>Apply filters</Button>
      </div> */}
    </Fragment>
  )
}

export default OpportunitiesFilters;