import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const managementLabeledTextStyle = makeStyles({
  label: {
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
    fontSize: '16px !important',
    lineHeight: '19px !important',
    margin: '0 !important',
    color: '#796E6E !important',
   },
  text: {
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    margin: '0 !important',
    color: '#505154 !important',
    marginTop: '4px !important',
  }
})
const ManagementLabeledText = ({text, label, customStyles={}, labelStyle={}}) => {
  const classes = managementLabeledTextStyle();

  return (
    <div style={customStyles}>
      <p className={classes.label} style={labelStyle}>{label}</p>
      <p className={classes.text}>{text}</p>
    </div>
  )
}

export default ManagementLabeledText;