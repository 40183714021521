import { Button, IconButton, Tooltip } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import OportunityWatchlistDialog from './OportunityWatchlistDialog';
import { adminAlignmentStyles, adminDimensionsStyle, adminTablesStyle, adminTypografyStyles } from '../../AdminStyles';
import { useMutation, useQuery } from '@apollo/client';
import { CANCEL_DEAL, GET_DEALS_SUMMARY } from '../../queries/admin-queries';
import { Link } from 'react-router-dom';
import { EventThrower, hasuraErrorHandler, Events } from '../../../shared/EventEmitter/EventEmitter';
import { useSelector } from 'react-redux';
import { formatValue } from '../../../utils/formats';
import OrderButtons from '../../../shared/OrderButtons/OrderButtons';
import OportunityEmployeesDialog from './OpportunityEmployeesDialog';

const OportunitiesList = ({ nameFilter }) => {
  const dimensionClasses = adminDimensionsStyle();
  const tableClasses = adminTablesStyle();
  const typografyClasses = adminTypografyStyles();
  const alignmentClasses = adminAlignmentStyles();
  const firebase_id = useSelector(store => store.account.user.firebase_id);
  const [cancelDeal] = useMutation(CANCEL_DEAL);
  const [nameOrder, setNameOrder] = useState(null);
  const [stageOrder, setStageOrder] = useState(null);
  const [investmentOrder, setInvestmentOrder] = useState(null);
  const [publishedOrder, setPublishedOrder] = useState(null);
  const [watchlistOrder, setWatchlistOrder] = useState(null);
  const [investmentAvalabileOrder, setInvestmentAvalabileOrder] = useState(null);
  const [stautsOrder, setStatusOrder] = useState(null);
  const [offeredOrder, setOfferedOrder] = useState(null);
  const [deals, setDeals] = useState([]);

  const { data } = useQuery(GET_DEALS_SUMMARY, {
    variables: {
      name: `%${nameFilter}%`,
      firebase_id: firebase_id,
      nameOrder: nameOrder,
      stage: stageOrder,
      investment_deadline: investmentOrder,
      published_at: publishedOrder || stautsOrder,
      watchlist: watchlistOrder,
      investment_amount_available: investmentAvalabileOrder,
      valuationOrder: offeredOrder,
    }, fetchPolicy: "network-only"
  });
  const [openWatchlistDialog, setOpenWatchlistDialog] = useState(false);
  const [openEmployeesDialog, setOpenEmployeesDialog] = useState(0);
  const [opportunityWatchlistInvestors, setOpportunityWatchlistInvestors] = useState([]);
  const [opportunityDialogName, setOpportunityDialogName] = useState("");

  const handleOpenWatchlistModal = (investors, deal) => {
    setOpportunityDialogName(`${deal.company.name} ${deal.title && '-'} ${deal.title}`);
    setOpportunityWatchlistInvestors(investors);
    setOpenWatchlistDialog(true);
  }

  const handleCancelDeal = (dealId) => {
    cancelDeal({ variables: { id: dealId } })
      .then(() => {
        EventThrower.throwClientSideMessage(Events.SuccessMessage("Deal canceled"))
        setDeals(deals.map(deal => {
          if (deal.id === dealId) {
            return {
              ...deal,
              status: "CANCELED"
            }
          }
          return deal;
        }))
      })
      .catch(console.log)
  }
  useEffect(() => {
    if (data) {
      hasuraErrorHandler.checkResponseForErrors(data);
      setDeals(
        Boolean(data.Permissions_assignment && data.Permissions_assignment.find(p => p.permission.edit_opportunity))
        ? data.Deal 
        : data.Deal.filter(d => Boolean(d.permissions_assignments && d.permissions_assignments.length))
      );
    }
  }, [data])

  const sortables = [setOfferedOrder, setNameOrder, setStageOrder, setInvestmentOrder, setPublishedOrder, setWatchlistOrder, setInvestmentAvalabileOrder, setStatusOrder];

  return (
    <Fragment>
      <div className={[dimensionClasses.containerWidth, dimensionClasses.headerMarginFix].join(' ')} style={{ width: "1080px", maxWidth: "none" }}>
        <div className={[tableClasses.tableHeader, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14, dimensionClasses.marginTop24].join(' ')}>
          <div className={[[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')].join(' ')}>
            Deal
            <OrderButtons value={nameOrder} orderFunctions={sortables} specificFunction={value => setNameOrder(value)} />
          </div>
          <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Stage
          <OrderButtons value={stageOrder} orderFunctions={sortables} specificFunction={value => setStageOrder(value)} />
          </div>
          <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Key Date
          <OrderButtons value={investmentOrder} orderFunctions={sortables} specificFunction={value => setInvestmentOrder(value)} />
          </div>
          <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
            Published
          <OrderButtons value={publishedOrder} orderFunctions={sortables} specificFunction={value => setPublishedOrder(value)} />
          </div>
          <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
            Watchlist
          <OrderButtons value={watchlistOrder} orderFunctions={sortables} specificFunction={value => setWatchlistOrder(value)} />
          </div>
          <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
            Offered
            <OrderButtons value={offeredOrder} orderFunctions={sortables} specificFunction={value => setOfferedOrder(value)} />
          </div>
          <div className={[dimensionClasses.width150, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
            Available
          <OrderButtons value={investmentAvalabileOrder} orderFunctions={sortables} specificFunction={value => setInvestmentAvalabileOrder(value)} />
          </div>
          <div className={[dimensionClasses.width100, alignmentClasses.flexCenterSpaceBetween].join(' ')}>
          Status
          <OrderButtons value={stautsOrder} orderFunctions={sortables} specificFunction={value => setStatusOrder(value)} />
          </div>
          <Button
            variant="outlined"
            color="primary"
            className={[dimensionClasses.smallHeigth, dimensionClasses.widthGrow, dimensionClasses.padding0].join(' ')}
            component={Link}
            to={`/admin/opportunities/add-opportunity`}>
            Add Deal
            <AddIcon style={{marginLeft: "12px"}}/>
          </Button>
        </div>
        {
          deals && deals.length !== 0 && deals.map((x, index) => (
            <div key={index} className={[tableClasses.row, alignmentClasses.flexAlignCenter, typografyClasses.fontSize14].join(' ')} style={x.status === "CANCELED" ? { color: "#ababab", width: "1080px" } : { width: "1080px" }}>
              <p className={dimensionClasses.width150}>
                {x.company.name} {x.title && ` - ${x.title}`}
              </p>
              <p className={dimensionClasses.width100}>{x.status.charAt(0).toUpperCase() + x.status.slice(1).toLowerCase()}</p>
              <p className={dimensionClasses.width100}>{new Date(x.investment_deadline).toLocaleDateString()}</p>
              <p className={dimensionClasses.width100}>{x.published_at ? new Date(x.published_at).toLocaleDateString() : ""}</p>
              <div className={dimensionClasses.width100}>
                <Button disabled={x.watchlist.length === 0}
                  className={[alignmentClasses.flex, dimensionClasses.mediumHeight, alignmentClasses.flexJustifyStart, typografyClasses.fontColor0258A8].join(' ')}
                  onClick={() => handleOpenWatchlistModal(x.watchlist, x)}>
                  {x.watchlist.length}
                </Button>
              </div>
              <div className={dimensionClasses.width150}>
                ${formatValue(x.investment_value)}
              </div>
              <p className={dimensionClasses.width150}>${formatValue(x.investment_amount_available)}</p>
              <p className={dimensionClasses.width100}>{x.published_at ? "Published" : "Draft"}</p>
              <div className={[alignmentClasses.flexAlignCenter, dimensionClasses.marginLeft20].join(' ')}>
                <Tooltip arrow title="Edit">
                  <IconButton className={dimensionClasses.padding0} component={Link} to={`/admin/opportunities/edit-opportunity/${x.id}`}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Cancel">
                  <IconButton className={dimensionClasses.padding0} onClick={() => handleCancelDeal(x.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Permission">
                  <IconButton className={dimensionClasses.padding0} onClick={() => setOpenEmployeesDialog(x.id)}>
                    <PersonIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ))
        }
      </div>
      <OportunityEmployeesDialog open={openEmployeesDialog !== 0} setOpen={() => setOpenEmployeesDialog(0)} dealId={openEmployeesDialog} />
      <OportunityWatchlistDialog investors={opportunityWatchlistInvestors} open={openWatchlistDialog} name={opportunityDialogName} setOpen={value => setOpenWatchlistDialog(value)} />
    </Fragment>
  )
}

export default OportunitiesList;