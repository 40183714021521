import { Avatar, Button, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import TextInput from '../../shared/TextInput/TextInput';
import { adminAlignmentStyles, adminDimensionsStyle, adminTypografyStyles } from '../AdminStyles';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { formatMessageDate } from '../../utils/formats';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { GET_CHAT_MESSAGES, GET_DEAL_NAME_BY_CHAT_ID, SET_MESSAGES_SEEN } from '../queries/admin-queries';
import { useSelector } from 'react-redux';
import { GET_DEAL_CHAT_MESSAGES_BY_CHAT_ID, SEND_DEAL_MESSAGE } from '../../utils/deal-queries';
import { scrollToBottom } from 'react-scroll/modules/mixins/animate-scroll';


const styles = makeStyles({
  container: {
    position: "fixed",
    top: "118px",
    left: "404px",
    right: "0",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: "20px 16px",
    background: "#f9f9f9",
  },
  avatar: {
    color: "black",
    background: "#F0E2E2",
    marginRight: "14px",
  },
  messageBox: {
    padding: "12px 15px",
    border: "1px solid #D8CECE",
    width: "fit-content",
    borderRadius: "4px",
    margin: "0",
  },
  messageContainer: {
    display: "flex",
    marginRight: "0px",
    marginLeft: "20px",

  },
  messageOnRight: {
    justifyContent: "flex-end",
    marginRight: "20px",
    marginLeft: "0px",
    background: "none"
  },
  documentsMessage: {
    padding: "6px",
    color: "#1F3F70",
  },
  atachmentIcon: {
    transform: "rotate(135deg)",
  },
  messageInput: {
    padding: "8px 14px 20px 8px",
    borderTop: "1px solid rgb(231, 231, 231)",
  },
})

const Message = ({ message }) => {
  const classes = styles();
  const typografyClasses = adminTypografyStyles();
  const dimensionClasses = adminDimensionsStyle();

  return (
    <div className={[classes.messageContainer, message.isMine ? classes.messageOnRight : "", dimensionClasses.marginTop12].join(' ')}>
      {!message.isMine && <Avatar className={classes.avatar}>{message.initials}</Avatar>}
      <div>
        <p className={[classes.messageBox, typografyClasses.fontSize14, message.isDocuments ? classes.documentsMessage : ""].join(' ')}
          style={{ background: !message.isMine && "#EEEEEE", borderWidth: message.isMine ? "1px" : "0px" }}>{message.text}</p>
        <span className={typografyClasses.fontSize12} style={{ color: "#555555" }}>{new Date(message.date).toLocaleString()}</span>
      </div>
    </div>
  )
}
const ChatMessages = ({ selectedChat }) => {
  const classes = styles();
  const typografyClasses = adminTypografyStyles();
  const dimensionClasses = adminDimensionsStyle();
  const alignmentClasses = adminAlignmentStyles();
  const firebaseId = useSelector(store => store.account.user.firebase_id);
  const { data: _dealName } = useQuery(GET_DEAL_NAME_BY_CHAT_ID, {
    variables: { deal_chat_id: selectedChat.chatId },
  });
  const dealName = (_dealName && _dealName.Deal && _dealName.Deal[0]) || {
    company: { name: "N/A" },
    title: "N/A",
  };
  const { data: messagesData } = useSubscription(GET_CHAT_MESSAGES, { variables: { deal_chat_id: selectedChat.chatId } });
  const [setMessagesSeen] = useMutation(SET_MESSAGES_SEEN, {variables: {deal_chat_id: selectedChat.chatId}});
  const [sendMessage] = useMutation(SEND_DEAL_MESSAGE)
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const messagesEndRef = useRef();

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleSendMessage = () => {
    sendMessage({
      variables: {
        message: messageText,
        firebase_id: firebaseId,
        deal_chat_id: selectedChat.chatId,
      }
    }).then(() => setMessageText(''))
  }

  useEffect(() => {
    if (messagesData && messagesData.Deal_chat_message) {
      setMessages(messagesData.Deal_chat_message.map(message => ({
        text: message.message,
        initials: `${selectedChat.firstName[0]}${selectedChat.lastName[0]}`,
        isMine: message.firebase_id === firebaseId,
        isDocuments: false,
        downloadLink: "",
        date: new Date(message.created_at).valueOf(),
      })))
      setMessagesSeen();
    }
  }, [messagesData])

  useEffect(() => scrollToBottom(), [messages])
  useEffect(() => scrollToBottom(), []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span className={typografyClasses.fontSize14}><strong>{`${selectedChat.firstName} ${selectedChat.lastName}`}</strong></span>
        <span className={[dimensionClasses.marginLeft16, typografyClasses.fontSize14].join(' ')} style={{ color: "#959595" }}>Last message: {formatMessageDate(selectedChat.lastMessageDate).toLowerCase()}</span>
        <span className={[dimensionClasses.marginLeft16, typografyClasses.fontSize14].join(' ')} style={{ color: "#959595", float: "right" }}>
          {dealName && `Company: ${dealName.company.name || '-'}, Deal: ${dealName.title || '-'}`}
        </span>
     
      </div>
      <div className={dimensionClasses.widthGrow} style={{ overflow: "auto" }}>
        {messages.map(message => <Message message={message} />)}
        <div ref={messagesEndRef} />
      </div>
      <div className={[alignmentClasses.flexAlignCenter, classes.messageInput, alignmentClasses.flexWrap].join(' ')}>
        <TextInput initialValue={messageText} handleChange={value => setMessageText(value)} placeholder="send message..." customStyle={{ flexGrow: "1", marginTop: "-8px" }} />
        <IconButton className={classes.atachmentIcon}>
          <AttachmentIcon />
        </IconButton>
        <Button variant="contained" color="primary" className={dimensionClasses.smallHeigth} onClick={() => handleSendMessage()}>Send message</Button>
      </div>
    </div>
  )
}

export default ChatMessages;