import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import CompanyTitleSection from './Sections/CompanyTitle';
import CompanyOverview from './Sections/CompanyOverview';
import CompanyDescription from './Sections/CompanyDescription';
import CompanyManagement from './Sections/CompanyManagement';
import { useQuery } from '@apollo/client';
import { GET_DEAL_SECTION_ORDER_BY_ID } from '../../../../utils/deal-queries';
import RichTextArea from './Sections/RichTextArea';
import CompanyFundingHistory from './Sections/CompanyFundingHistory';
import ImageSection from './Sections/ImageSection';
import DocumentsSection from './Sections/DocumentsSection';
import DetailedOpportunityFooter from './Sections/Footer';
import { Fragment } from 'react';
import { Element } from 'react-scroll';
import { insertAnalytics } from '../../../../utils/analytics-queries';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTiming } from '../../../../shared/TimingHook/Timer';
import { DealSectionType } from '../../../../utils/enums';

const containerStyle = {
  padding: "0px 40px 40px 0px",
  overflow: "scroll",
  height: "100%",
}

const OpportunityPreviewContainer = () => {
  const companyId = useSelector(store => store.adminOpportunity.resultingOpportunity.companyId || 0)
  const sections =  useSelector(store => store.adminOpportunity.resultingOpportunity.sections);
  const status = useSelector(store => store.adminOpportunity.resultingOpportunity.type);
  if(!companyId) {
    return <p>Please select a company first</p>
  }
  return (
    <div style={containerStyle}>
      <CompanyTitleSection companyId={companyId} />
      <CompanyOverview companyId={companyId} />
      {
        sections.map(section => {
          switch (section.type) {
            case DealSectionType.DESCRIPTION:
                return companyId && <CompanyDescription companyId={companyId} />
            case DealSectionType.MANAGEMENT: 
                return companyId && <CompanyManagement companyId={companyId} /> 
            case DealSectionType.FUNDING:
                return companyId && <CompanyFundingHistory companyId={companyId} />
            case DealSectionType.RICH_TEXT_AREA:
                return <RichTextArea title={section.title} body={section.data?.content} />
            case DealSectionType.IMAGES:
              return <ImageSection title={section.title} image={section.data?.content[0] || null} />
            case DealSectionType.FILES: 
              return <DocumentsSection title={section.title} files={section.data?.content  || []} />
            default:
              break;
          }
        })
      }
      {
        (status === "ACTIVE" || status === "PROSPECTIVE") && <DetailedOpportunityFooter />
      }
      
    </div>
  )
}

export default OpportunityPreviewContainer;