import { useSubscription } from '@apollo/client';
import { Avatar, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { adminDimensionsStyle, adminTypografyStyles } from '../AdminStyles';
import { CHECK_FOR_NEW_MESSAGES, GET_ALL_CONVERSATIONS } from '../queries/admin-queries';

const customClasses = makeStyles({
  personContainer: {
    display: "flex",
    padding: "12px 4px",
    marginRight: "12px",
    cursor: "pointer"
  },
  avatar: {
    color: "black",
    background: "#F0E2E2",
  },
  allPersonsContainer: {
    top: "112px",
    bottom: "0",
    position: "fixed",
    left: "164px",
    width: "240px",
    borderRight: "1px solid rgb(231, 231, 231)",
  }
})

const PersonComponent = ({person, isActive = false, handleClick = () => null}) => {
  const classes = customClasses();
  const typografyClasses = adminTypografyStyles();
  const dimensionClasses = adminDimensionsStyle();
  const {data: hasNewMessages} = useSubscription(CHECK_FOR_NEW_MESSAGES, {variables: {deal_chat_id: person.chatId}})
  return (
    <div className={[classes.personContainer, dimensionClasses.marginTop20].join(' ')} onClick={() => handleClick()} style={{background: isActive && "#F4F4F4"}}>
      <Avatar className={classes.avatar}>{`${person.firstName[0] || ''}${person.lastName[0] || ''}`}</Avatar>
      <div className={dimensionClasses.marginLeft16}>
        <p className={[typografyClasses.fontSize14, dimensionClasses.marginTop0, dimensionClasses.marginBottom8].join(' ')} style={{color: "#000000"}}>{`${person.firstName || ''} ${person.lastName || ''}`}</p>
        <p className={[typografyClasses.fontSize12, dimensionClasses.marginBottom0, dimensionClasses.marginTop0].join(' ')} style={{color: "#959595"}}>
          {hasNewMessages && hasNewMessages.Deal_chat_message.length > 0 && <strong style={{color: "#000000"}}>New! </strong>}
          { new Date(person.lastMessageDate).toDateString()}
        </p>
      </div>
    </div>
  )
}

const NewMessagesContainer = ({nameFilter="", setSelectedChat}) => {
  const classes = customClasses();
  const firebaseId = useSelector(store => store.account.user.firebase_id)
  const {data: conversations} = useSubscription(GET_ALL_CONVERSATIONS, {variables: {employee_id: firebaseId}, fetchPolicy: 'network-only'})
  const [people, setPeople] = useState([]);
  const [selectedChatId, setSelctedChatId] = useState(0);

  const handleSelectChat = (chat) => {
    if(setSelectedChat) {
      setSelectedChat(chat);
    }
    setSelctedChatId(chat.chatId);
  }

  const contains = (initialString = "", searchedString = "") => {
    return initialString.toLowerCase().indexOf(searchedString.toLowerCase()) !== -1
  }

  useEffect(() => {
    if(conversations && conversations.Deal_chat) {
      setPeople(conversations.Deal_chat.map(conversation => ({
        firstName: conversation.accountByInvestorId.first_name,
        lastName: conversation.accountByInvestorId.last_name,
        lastMessageDate: new Date(conversation.Deal_chat_messages[0]?.created_at || Date.now()).valueOf(),
        hasMessages: conversation.Deal_chat_messages.length > 0,
        chatId: conversation.id
      })))
    }
  }, [conversations])
  return (
    <div className={classes.allPersonsContainer}>
      {people.filter(x => x.hasMessages && (contains(x.firstName, nameFilter) || contains(x.lastName, nameFilter))).sort((a, b) => b.lastMessageDate - a.lastMessageDate).map((person, index) => <PersonComponent key={index} person={person} isActive={person.chatId === selectedChatId} handleClick={() => handleSelectChat(person)} />)}
    </div>
  )
}

export default NewMessagesContainer;