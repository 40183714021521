import React, { Fragment, useState } from 'react';
import { generalStyleClasses } from '../../../../utils/general-styles';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import PreferedInvestmentType from '../../../../shared/InvestorPreferancesInputs/PreferedInvestmentType';
import PreferredSecurityType from '../../../../shared/InvestorPreferancesInputs/PreferredSecurityType';
import ExecutionTimeline from '../../../../shared/InvestorPreferancesInputs/ExecutionTimeline';
import InvestmentSize from '../../../../shared/InvestorPreferancesInputs/InvestmentSize';
import InvestmentStatus from '../../../../shared/InvestorPreferancesInputs/InvestmentStatus';
import { UPDATE_INVESTMENT_PREFERANCES } from '../../../../utils/investor-preferances-queries';
import { useMutation } from '@apollo/client';
import AdditionalInformations from '../../../../shared/InvestorPreferancesInputs/AdditionalInformation';
import { hasuraErrorHandler } from '../../../../shared/EventEmitter/EventEmitter';
import { useSelector } from 'react-redux';

const Investment = ({ nextStep }) => {
    const classes = generalStyleClasses();
    const history = useHistory();
    const [preferedInvestmentType, setPreferedInvestmentType] = useState("0");
    const [preferredSecurityType, setPreferredSecurityType] = useState("0");
    const [executionTimeline, setExecutionTimeline] = useState("0");
    const [investmentSizeLow, setInvestmentSizeLow] = useState(0);
    const [investmentSizeHigh, setInvestmentSizeHigh] = useState(0);
    const [investmentStatus, setInvestmentStatus] = useState("0");
    const [additionalInformations, setAdditionalInformations] = useState("");
    const [updateInvestmentPreferances] = useMutation(UPDATE_INVESTMENT_PREFERANCES);
    const clientCreationId = useSelector(store => store.adminCreateAccount.accountId);

    const handleSaveChanges = () => {
        if (investmentSizeLow > investmentSizeHigh) {
            hasuraErrorHandler.printError("Lowest margin of investment size must be lower than the highest margin");
            return;
        }
        const variables = {
            firebase_id: clientCreationId,
            investment_type: preferedInvestmentType.toString(),
            investment_security_type: preferredSecurityType.toString(),
            investment_execution_timeline: executionTimeline.toString(),
            investment_size_low: investmentSizeLow,
            investment_size_high: investmentSizeHigh,
            investment_status: investmentStatus.toString(),
            investment_additional_information: additionalInformations,
        }
        updateInvestmentPreferances({ variables: variables }).then(() => {
            nextStep();

        }).catch(console.log);
    }

    return (
        <Fragment>
            <PreferedInvestmentType handleChange={setPreferedInvestmentType} />
            <PreferredSecurityType handleChange={setPreferredSecurityType} rootClasses={classes.marginTop16} />
            <ExecutionTimeline handleChange={setExecutionTimeline} rootClasses={classes.marginTop16} />
            <InvestmentSize changeMinValue={setInvestmentSizeLow} changeMaxValue={setInvestmentSizeHigh} rootClasses={classes.marginTop16} />
            <InvestmentStatus handleChange={setInvestmentStatus} rootClasses={classes.marginTop16} />
            <AdditionalInformations handleChange={setAdditionalInformations} rootClasses={classes.marginTop16} />
            <div className={[classes.flexJustifyEnd, classes.marginTop32].join(' ')}>
                <Button variant="contained" color="primary" onClick={handleSaveChanges} style={{ width: "200px" }} > Next </Button>
            </div>
        </Fragment >
    )
}

export default Investment;