import { makeStyles } from '@material-ui/core';
import React from 'react';
import { generalStyleClasses } from '../../utils/general-styles';
import LastStep from './LastStep';

const styles = makeStyles({
    container: {
        position: 'absolute',
        width: '480px',
        marginTop: '64px',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        alignItems: 'baseline',
        boxSizing: 'border-box',
        padding: '40px',
        background: 'white',
        borderRadius: '4px',
        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.08)',
        "& div": {
            width: "100%",
        },
    }
})

const ForgotLastStep = () => {
    const generalClasses = generalStyleClasses();
    const classes =  styles();
    return (
        <div className={[generalClasses.flexAlignCenterOnColumn, classes.container].join(' ')}>
            <LastStep isForgot/>
        </div>
    )
}

export default ForgotLastStep;