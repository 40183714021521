import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    height: "64px",
    alignItems: "center",
    background: "white",
    padding: "12px",
    width: "100%",
    zIndex: "100",
    position: "fixed",
    boxSizing: "border-box",
  },
  menuContainer: {
    marginLeft: "72px",
    flexGrow: "1",
    alignItems: "center",
  },
  menuItem: {
    padding: "12px 20px 21px 12px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    textDecoration: "none",
    color: "#505050",
  },
  menuItemActive: {
    background: "#F4F4F4",
  } 
})
