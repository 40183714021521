export const businessModels = [
    { name: 'B2B', value: 1 },
    { name: 'B2C', value: 2 },
    { name: 'B2B2C', value: 3 },
]
export const developmentStage = [
    { name: 'Built product', value: 1 },
    { name: 'Has product market fit', value: 2 },
    { name: 'Generates revenue', value: 3 },
    { name: 'Is profitable', value: 4 },
]
export const fundingStage = [
    { name: 'Post-Seed', value: 2 },
    { name: 'Pre-IPO', value: 3 },
    { name: 'Seed', value: 4 },
    { name: 'Series A', value: 5 },
    { name: 'Series B', value: 6 },
    { name: 'Series C', value: 7 },
    { name: 'Series D', value: 8 },
    { name: 'Series F', value: 9 },
    { name: 'Series E', value: 10 },
    { name: 'Convertible Note', value: 11 },
    { name: 'Other', value: 12 },
    { name: "Pre-Seed", value: 13},
    { name: 'Series G', value: 14 },
    { name: 'Series H', value: 15 },
    { name: 'Series I', value: 16 },
    { name: 'Series J', value: 17 },
    { name: 'Series K', value: 18 },
]
