import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import CreateAccountStep from './ClientCreationSteps/CreateAccount';
import PersonalInformationStep from './ClientCreationSteps/PersonalInformation';
import AccreditedClientStep from './ClientCreationSteps/AccreditedClientStep';
import Company from './ClientCreationSteps/InvestorProfile/Company';
import Investment from './ClientCreationSteps/InvestorProfile/Investment';
import Industry from './ClientCreationSteps/InvestorProfile/Industry';
import { Fragment } from 'react';
import { adminDimensionsStyle, adminTypografyStyles } from '../AdminStyles';
import { useDispatch, useSelector } from 'react-redux';
import { adminCreateAccountActions } from '../../store/slices/rmCreatedAccount';
import { createInvestorAccount } from '../queries/admin-http-calls';
import { EventThrower, Events, hasuraErrorHandler } from '../../shared/EventEmitter/EventEmitter';
import { useMutation } from '@apollo/client';
import { ASSIGN_RELATIONSHIP_MANAGER } from '../queries/admin-queries';
import { emailValidator } from '../../utils/validators';

const CreateAccountDialog = (props) => {
  const { onClose, open } = props;
  const dimensionClasses = adminDimensionsStyle();
  const typografyClasses = adminTypografyStyles();
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const createAccountData = useSelector(store => store.adminCreateAccount);
  const [setRelationshipManager] = useMutation(ASSIGN_RELATIONSHIP_MANAGER);
  useEffect(() => {
    dispatch(adminCreateAccountActions.RESET())
  }, [])

  const handleClose = () => {
    dispatch(adminCreateAccountActions.RESET());
    props.onClose();
  };

  const isValid = () => {
    if(step === 0) {
      return createAccountData.email !== "" && emailValidator(createAccountData.email);
    } 
    if(step === 1) {
      return createAccountData.firstName !== "" && createAccountData.lastName !== "" && createAccountData.phoneNumber !== "" && createAccountData.country !== "" && createAccountData.city !== "";
    }
    if(step === 2) {
      return true;
    }
  }

  const handleComplete = () => {
    EventThrower.throwClientSideMessage(Events.SuccessMessage("Client account created"))
    handleClose();
  }

  const handleGoToNextStep = () => {
    if(step === 2 && !createAccountData.accountId && !createAccountData.investorProfileId) {
      createInvestorAccount({
        email: createAccountData.email,
        firstName: createAccountData.firstName,
        lastName: createAccountData.lastName,
        appClient: createAccountData.isClient,
        accreditedInvestor: createAccountData.isAccredited,
        phoneNumber: createAccountData.phoneNumber.toString(),
        phoneZone: createAccountData.phoneZone,
        country: createAccountData.country,
        city: createAccountData.city,
      })
      .then(resp => resp.json())
      .then(resp => {
        dispatch(adminCreateAccountActions.SET_ACCOUNT_ID(resp.result.firebaseId));
        dispatch(adminCreateAccountActions.SET_INVESTOR_PROFILE_ID(resp.result.investorProfileId));
        if(createAccountData.relationshipManagerId) {
          setRelationshipManager({variables: {
            firebase_id: createAccountData.relationshipManagerId,
            investor_firebase_id: resp.result.firebaseId,
          }}).then(() => setStep(step+1));
        } else {
          setStep(step+1)
        }
      })
      .catch(err => {
        hasuraErrorHandler.printError("Cound not create account");
        setStep(2);
      })
    } else {
      setStep(step + 1);
    }
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} PaperProps={{ style: { width: "600px" } }} disableScrollLock={true}>
      <DialogTitle id="simple-dialog-title" style={{ padding: "0px" }}>Create user account</DialogTitle>
      <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
        {
          step == 0 && <CreateAccountStep />
        }
        {
          step == 1 && <PersonalInformationStep />
        }
        {
          step == 2 && <AccreditedClientStep />
        }
        {
          step == 3 && (
            <Fragment>
              <h4 className={[dimensionClasses.marginbottom16, dimensionClasses.marginTop12, typografyClasses.fontSize16, typografyClasses.fontColor505154].join(' ')}>Investor Profile - Company</h4>
              <Company nextStep={() => handleGoToNextStep()} />
            </Fragment>
          )
        }
        {
          step == 4 && (
            <Fragment>
              <h4 className={[dimensionClasses.marginbottom16, dimensionClasses.marginTop12, typografyClasses.fontSize16, typografyClasses.fontColor505154].join(' ')}>Investor Profile - Investment</h4>
              <Investment nextStep={() => handleGoToNextStep()} />
            </Fragment>
          )
        }
        {
          step == 5 && (
            <Fragment>
              <h4 className={[dimensionClasses.marginbottom16, dimensionClasses.marginTop12, typografyClasses.fontSize16, typografyClasses.fontColor505154].join(' ')}>Investor Profile - Company</h4>
              <Industry nextStep={() => handleComplete()}/>
            </Fragment>
          )
        }
      </DialogContent >
      {
        step < 3 && (
          <DialogActions style={{ padding: "0px", marginTop: "24px" }}>
            <Button variant="contained" color="primary" style={{ width: "200px" }} onClick={() => handleGoToNextStep()} disabled={!isValid()}>Next</Button>
          </DialogActions>
        )
      }
    </Dialog>
  );
}

export default CreateAccountDialog;

