import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, FormControl } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { debounce } from 'lodash';
import { generalStyleClasses } from '../../../../../utils/general-styles';
import { topsideMenuStyles } from '../../OpportunitiesPage/TopsideMenu/TopsideMenuStyle';

const sortDetails = {
  "RECENT": { up: "Most recent", low: "Least recent", sort: (value) => `updated_at: ${value > 0 ? "asc" : "desc"}` },
  "NAME": { up: "Name A-Z", low: "Name Z-A", sort: (value) => `company: {name: ${value > 0 ? "asc" : "desc"}}` },
  "VALUATION": { up: "Lowest valuation", low: "Highest valuation", sort: (value) => `valuation: ${value > 0 ? "asc" : "desc"}` },
  "OFFERING": { up: "Lowest offering", low: "Highest offering", sort: (value) => `investment_amount_available: ${value > 0 ? "asc" : "desc"}`}
}

const TopsideMenu = ({ handleNewOrder, setNewNameFilter }) => {
  const classes = topsideMenuStyles();
  const generalStyles = generalStyleClasses();
  const { url } = useRouteMatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [orderType, setOrderType] = useState(sortDetails["RECENT"]);
  const [orderDirection, setOrderDirection] = useState(true);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    const handleSearchChange = debounce(value => {
    if(setNewNameFilter) {
      setNewNameFilter(value);
    }
  }, 300);

  const switchSortOrder = () => {
    setOrderDirection(!orderDirection)
  }

  useEffect(() => {
    if (handleNewOrder) {
      handleNewOrder(orderType.sort(orderDirection))
    }
  }, [orderType, orderDirection])


  return (
    <div className={[generalStyles.mainPageWidth, generalStyles.flexSpaceBetweenCenter].join(' ')}>
      <FormControl>
        <InputBase
          id="input-with-icon-adornment"
          inputProps={{ 'aria-label': 'naked' }}
          placeholder="Search..."
          onChange={e => handleSearchChange(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
      <div className={classes.buttonGroup}>
        <span className={[generalStyles.fontSize16, generalStyles.fontColorDark].join(' ')} style={{ marginRight: "16px" }}>Investments</span>
        <ButtonGroup variant="contained" color="inherit" aria-label="contained primary button group" disableElevation>
          <NavLink
            className={[classes.buttonGroupItem, generalStyles.fontSize15, generalStyles.fontColorLight].join(' ')}
            activeClassName={classes.activeLink} to={`${url}/all`}
            >All</NavLink>
          <NavLink
            className={[classes.buttonGroupItem, generalStyles.fontSize15, generalStyles.fontColorLight].join(' ')}
            activeClassName={classes.activeLink}
            to={`${url}/in-progress`}
            >In progress</NavLink>
          <NavLink
            className={[classes.buttonGroupItem, generalStyles.fontSize15, generalStyles.fontColorLight].join(' ')}
            activeClassName={classes.activeLink}
            to={`${url}/complated`}
            >Completed</NavLink>
        </ButtonGroup>
      </div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
        style={{ width: "180px", display: "flex", justifyContent: "space-between" }}
        className={[classes.dropdownButton, generalStyles.fontSize13, generalStyles.fontColorLight].join(' ')}>
        {orderDirection ? orderType.up : orderType.low}
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        PaperProps={{ style: { width: "180px" } }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          setOrderType(sortDetails["RECENT"])
          handleClose();
        }} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Published date
        </MenuItem>
        <MenuItem onClick={() => {
          setOrderType(sortDetails["NAME"])
          handleClose();
        }} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Name
        </MenuItem>
        <MenuItem onClick={() => {
          setOrderType(sortDetails["VALUATION"])
          handleClose();
        }} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Valuation
        </MenuItem>
        <MenuItem onClick={() => {
          setOrderType(sortDetails["OFFERING"])
          handleClose();
        }} className={[classes.dropdownItem, generalStyles.flexSpaceBetweenCenter].join(' ')}>
          Offering
        </MenuItem>
      </Menu>
      <div className={classes.sortArrows}>
        <svg onClick={() => switchSortOrder()} className={!orderDirection ? classes.activeSortArrow : ''} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.25 1.83588e-06L6 6L0.75 0L11.25 1.83588e-06Z" fill="#A9A9A9" />
        </svg>
        <svg onClick={() => switchSortOrder()} className={orderDirection ? classes.activeSortArrow : ''} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.75 6L6 4.5897e-07L11.25 6L0.75 6Z" fill="#A9A9A9" />
        </svg>
      </div>
    </div>
  );
}

export default TopsideMenu