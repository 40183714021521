import EventEmitter from 'eventemitter3';
import DoneIcon from '@material-ui/icons/Done';
import StarIcon from '@material-ui/icons/Star';
import StarBorderSharpIcon from '@material-ui/icons/StarBorderSharp';
import React from 'react';
const eventEmitter = new EventEmitter();

const Emitter = {
    on: (event, fn) => eventEmitter.on(event, fn),
    once: (event, fn) => eventEmitter.once(event, fn),
    off: (event, fn) => eventEmitter.off(event, fn),
    emit: (event, payload) => eventEmitter.emit(event, payload)
}

Object.freeze(Emitter);

export const Events = {
    InvalidEmailError: {
        message: "Invalid email",
        severity: "error"
    },
    WeekPasswordError: {
        message: "Password not strong enough",
        severity: "error"
    },
    PasswordsMismatchError: {
        message: "Passwords mismatch",
        severity: "error"
    },
    InvalidParamsOrProps: {
        message: "Could not complete request...",
        severity: "error"
    },
    InformationChangedMessage: {
        message: "Profile Updated",
        severity: "success",
        icon: <DoneIcon />,
    },
    InformationChangedError: {
        message: "There was an error saving your data",
        severity: "error"
    },
    EmailSentSuccessfully: {
        message: "Email Sent!",
        severity: "success"
    },
    RolesUpdatedSuccessfully: {
        message: "Role Updated!",
        severity: "success"
    },
    CompanyAddedToWatchlist: {
        message: "Company added to your watchilist",
        severity: "success",
        icon: <StarIcon style={{ fill: "#FAB54F" }} />
    },
    CompanyRemovedFromWatchlist: {
        message: "Company removed from your watchilist",
        severity: "success",
        icon: <StarBorderSharpIcon />
    },
    InformationsUpdated: (object) => ({message: `${object} details updated!`,severity: "success" }), 
    LoginError: (message) => ({message: message, severity: "error"}),
    RegisterError: (message) => ({message: message, severity: "error"}),
    FieldValidationError: (field) => ({message: `Field ${field} is not valid`, severity: "error"}),
    PasswordResetEmailSent: (message) => ({message: `Password reset email sent to ${message}`, severity: "success"}),
    PasswordResetError: (message) => ({message: `Error sending password reset email`, severity: "error"}),
    SuccessMessage: (message) => ({message: message,severity: "success" }), 
}

export const EventThrower = {
    throwClientSideError: (error) => Emitter.emit("CLIENT_SIDE_ERROR", error),
    throwClientSideMessage: (message) => Emitter.emit("CLIENT_SIDE_MESSAGE", message),
}

export const hasuraErrorHandler = {
    printError: (err) => EventThrower.throwClientSideError({message: err, severity: "error"}),
    showRequestErrorMessage: () => EventThrower.throwClientSideError({message: "Request completed with errors", severity: "error"}),
    checkResponseForErrors: (response) => {
        if(response.error && response.message) {
            EventThrower.throwClientSideError({message: "Request completed with errors", severity: "error"})
        }
    }
}

export default Emitter;