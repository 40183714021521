export const buildDevelopmentStageText = (investorProfile) => {
  let developmentStages = [];
  if (investorProfile.company_development_stage_build_product) developmentStages.push("Built product");
  if (investorProfile.company_development_stage_generates_revenue) developmentStages.push("Built product");
  if (investorProfile.company_development_stage_has_product) developmentStages.push("Has product market fit");
  if (investorProfile.company_development_stage_is_profitable) developmentStages.push("Is profitable");
  return developmentStages.join(', ');
}

export const buildFoundingStageText = (investorProfile) => {
  let foundingStages = [];
  if (investorProfile.company_funding_stage_seed) foundingStages.push("Seed");
  if (investorProfile.company_funding_stage_post_seed) foundingStages.push("Post-Seed");
  if (investorProfile.company_funding_stage_series_a) foundingStages.push("Series A");
  if (investorProfile.company_funding_stage_series_b) foundingStages.push("Series B");
  if (investorProfile.company_funding_stage_series_c) foundingStages.push("Series C");
  if (investorProfile.company_funding_stage_series_d) foundingStages.push("Series D");
  if (investorProfile.company_funding_stage_series_e) foundingStages.push("Series E");
  if (investorProfile.company_funding_stage_series_f) foundingStages.push("Series F");
  if (investorProfile.company_funding_stage_pre_ipo) foundingStages.push("Pre-IPO");
  if (investorProfile.company_funding_stage_convertible_note) foundingStages.push("Convertible Note");
  if (investorProfile.company_funding_stage_other) foundingStages.push("Other");
  if (investorProfile.company_funding_stage_pre_seed) foundingStages.push("Pre-Seed");
  if (investorProfile.company_funding_stage_series_g) foundingStages.push("Series G");
  if (investorProfile.company_funding_stage_series_h) foundingStages.push("Series H");
  if (investorProfile.company_funding_stage_series_i) foundingStages.push("Series I");
  if (investorProfile.company_funding_stage_series_j) foundingStages.push("Series J");
  if (investorProfile.company_funding_stage_series_k) foundingStages.push("Series K");
  return foundingStages.join(', ');
}

export const buildBussinessModelText = (investorProfile) => {
  let bussinessModels = [];
  if (investorProfile.company_business_model_B2B) bussinessModels.push("B2B");
  if (investorProfile.company_business_model_B2B2C) bussinessModels.push("B2B2C");
  if (investorProfile.company_business_model_B2C) bussinessModels.push("B2C");
  return bussinessModels.join(', ');
}

export const getInvestmentType = (type) => {
  if (type === "1") {
    return "Primary";
  }
  if (type === "2") {
    return "Secondary";
  }
  if (type === "3") {
    return "Indiferent";
  }
  return "";
}

export const getSecurityType = (type) => {
  if (type === "1") {
    return "Common";
  }
  if (type === "2") {
    return "Secondary";
  }
  if (type === "3") {
    return "Indiferent";
  }
  return "";
}

export const getExecutionStats = (type) => {
  if (type === "1") {
    return "Prefers to see opportunities that are active so he can move quickly";
  }
  if (type === "2") {
    return "Needs a long-enough time to consider investing and is comfortable expressing interest at prospective stage";
  }
  return "";
}

export const getInvestmentStatus = (type) => {
  if (type === "1") {
    return "I am currently looking to invest in new deals";
  }
  if (type === "2") {
    return "I am looking for new new opportunities, but not likely to invest in the next 3 months";
  }
  if (type === "3") {
    return "I am no longer investing until further notice";
  }
  return "";
}

export const industriesDataExists = (investorProfile) => {
  if (investorProfile.innovation_enablers && investorProfile.innovation_enablers.length > 0) {
    return true;
  }
  if (investorProfile.interested_sectors && investorProfile.interested_sectors.length > 0) {
    return true;
  }
  if (investorProfile.investor_profile_innovation_enablers_lesses && investorProfile.investor_profile_innovation_enablers_lesses.length > 0) {
    return true;
  }
  if (investorProfile.investor_profile_interested_sectors_lesses && investorProfile.investor_profile_interested_sectors_lesses.length > 0) {
    return true;
  }
  if (investorProfile.industry_additional_information) {
    return true;
  }
  return false;
}

export const companyDataExists = (investorProfile) => {
  if (investorProfile.company_additional_information ||
    investorProfile.company_business_model_B2B ||
    investorProfile.company_business_model_B2B2C ||
    investorProfile.company_business_model_B2C ||
    investorProfile.company_development_stage_build_product ||
    investorProfile.company_business_model_B2B2C ||
    investorProfile.company_development_stage_has_product ||
    investorProfile.company_development_stage_is_profitable ||
    investorProfile.company_funding_stage_seed ||
    investorProfile.company_funding_stage_post_seed ||
    investorProfile.company_funding_stage_series_a ||
    investorProfile.company_funding_stage_series_b ||
    investorProfile.company_funding_stage_series_c ||
    investorProfile.company_funding_stage_series_d ||
    investorProfile.company_funding_stage_series_e ||
    investorProfile.company_funding_stage_series_f ||
    investorProfile.company_funding_stage_pre_ipo ||
    investorProfile.company_funding_stage_any_stage ||
    investorProfile.company_funding_stage_convertible_note ||
    investorProfile.company_funding_stage_other ||
    investorProfile.company_funding_stage_pre_seed ||
    investorProfile.company_funding_stage_series_g ||
    investorProfile.company_funding_stage_series_h ||
    investorProfile.company_funding_stage_series_i ||
    investorProfile.company_funding_stage_series_j ||
    investorProfile.company_funding_stage_series_k ||
    (investorProfile.countries && investorProfile.countries.length) > 0) {
    return true;
  }
  return false;
}

export const investmentDataExists = (investorProfile) => {
  if (investorProfile.investment_type && investorProfile.investment_type > 0) {
    return true;
  }
  if (investorProfile.investment_security_type && investorProfile.investment_security_type > 0) {
    return true;
  }
  if (investorProfile.investment_execution_timeline && investorProfile.investment_execution_timeline > 0) {
    return true;
  }
  if (investorProfile.investment_status && investorProfile.investment_status > 0) {
    return true;
  }
  if (investorProfile.investment_size_high && investorProfile.investment_size_high > 0) {
    return true;
  }
  if (investorProfile.investment_size_low && investorProfile.investment_size_low > 0) {
    return true;
  }
  if (investorProfile.investment_additional_information) {
    return true;
  }
  return false;
}

export const deleteNulls = (input) => {
  if (input instanceof FormData) {
    const keysToDelete = [];
    input.forEach((value, key) => {
      if (input.get(key) === 'null' || input.get(key) === 'undefined') {
        keysToDelete.push(key);
      }
    })
    keysToDelete.forEach(key => input.delete(key));
    return input;
  }

  for (let key of Object.keys(input)) {
    const keysToDelete = [];
    if (input[key] === null || input[key] === undefined) {
      keysToDelete.push(key);
    }
    keysToDelete.forEach(key => delete input[key]);

  }
  return input;
}

const activityStatus = {
  "COMPLETED": { rank: 0, status: "Investment Completed" },
  "ACTIVE": { rank: 1, status: "Investment Active" },
  "WATCHLIST": { rank: 2, status: "Added to watchlist" },
  "VIEWED": { rank: 3, status: "Opportunity viewed" }
}

export const mapClientActivity = (investments, watchlists, analytics) => {
  const result = [];
  investments.filter(x => x.deal).forEach(investment => {
    const newData = {
      id: investment.deal.id,
      name: `${investment.deal.company.name} ${investment.deal.title && '-'} ${investment.deal.title}`,
      status: investment.completed ? activityStatus.COMPLETED : activityStatus.ACTIVE,
      time: investment.deal.session.aggregate.avg.value,
      views: investment.deal.views.aggregate.count,
      value: investment.value
    };
    result.push(newData);
  })
  watchlists.filter(x => x.deal).forEach(watchlist => {
    if (result.findIndex(x => x.id === watchlist.deal?.id) === -1) {
      const newData = {
        id: watchlist.deal?.id || 0,
        name: `${watchlist.deal.company.name} ${watchlist.deal.title && '-'} ${watchlist.deal.title}`,
        status: activityStatus.WATCHLIST,
        time: watchlist.deal.session.aggregate.avg.value,
        views: watchlist.deal.views.aggregate.count,
        value: null
      };
      result.push(newData);
    }
  })
  analytics.filter(x => x.Deal).forEach(analytic => {
    if (result.findIndex(x => x.id === analytic.Deal.id) === -1) {
      const newData = {
        id: analytic.Deal.id,
        name: `${analytic.Deal.company.name} ${analytic.Deal.title && '-'} ${analytic.Deal.title}`,
        status: activityStatus.VIEWED,
        time: analytic.Deal.session.aggregate.avg.value,
        views: analytic.Deal.views.aggregate.count,
        value: null
      };
      result.push(newData);
    }
  })
  return result;
}