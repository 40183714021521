import { DealSectionType } from "../../../utils/enums";

export const sectionUpdates = {
  [DealSectionType.FILES]: (section) =>
    `sectionUpdate${section.id}: update_Deal_section_files_by_pk(pk_columns: {id: "${section.id}"}, _set:{title: "${section.title}", order: ${section.order}}){
      id
      order
    }`,
  [DealSectionType.IMAGES]: (section) =>
    `sectionUpdate${section.id}: update_Deal_section_image_by_pk(pk_columns: {id: "${section.id}"}, _set:{title: "${section.title}", order: ${section.order}}){
      id
      order
    }`,
  [DealSectionType.LINKS]: (section) =>
    `sectionUpdate${section.id}: update_Deal_section_links_by_pk(pk_columns: {id: "${section.id}"}, _set:{title: "${section.title}", order: ${section.order}}){
      id
      order
    }`,
  [DealSectionType.RICH_TEXT_AREA]: (section) => {
    return `sectionUpdate${section.id}: update_Deal_section_text_area_by_pk(pk_columns: {id: "${section.id}"}, _set:{body: "${section.data?.content.replaceAll('"', '\\"') || ""}", title: "${section.title}", order: ${section.order}}){
      id
      order
    }`},
}

export const sectionInserts = {
  [DealSectionType.FILES]: (section, dealId) =>
    `sectionInsert${section.order}: insert_Deal_section_files(objects: {deal_id: ${dealId}, title: "${section.title}", order: ${section.order}}){
      returning {
        id
        order
      }
    }`,
  [DealSectionType.IMAGES]: (section, dealId) =>
    `sectionInsert${section.order}: insert_Deal_section_image(objects: {deal_id: ${dealId}, title: "${section.title}", order: ${section.order}}){
      returning {
        id
        order
      }
    }`,
  [DealSectionType.LINKS]: (section, dealId) =>
    `sectionInsert${section.order}: insert_Deal_section_links(objects: {deal_id: ${dealId}, title: "${section.title}", order: ${section.order}}){
      returning {
        id
        order
      }
    }`,
  [DealSectionType.RICH_TEXT_AREA]: (section, dealId) => `sectionInsert${section.order}: insert_Deal_section_text_area(objects: {body: "${section.data?.content.replaceAll('"', '\\"') || ""}", deal_id: ${dealId},  title: "${section.title}", order: ${section.order}}) {
      returning {
        id
        order
      }
    }`,
}

export const sectionDeletes = {
  [DealSectionType.FILES]: (section) =>
    `sectionDelete${section.id}: delete_Deal_section_files_by_pk(id: "${section.id}") {
    id
  }
  `,
  [DealSectionType.IMAGES]: (section) => 
    `sectionDelete${section.id}: delete_Deal_section_image_by_pk(id: "${section.id}") {
    id
  }
  `,
  [DealSectionType.LINKS]: (section) =>
    `sectionDelete${section.id}: delete_Deal_section_links_by_pk(id: "${section.id}") {
      id
    }
    `,
  [DealSectionType.RICH_TEXT_AREA]: (section) =>
    `sectionDelete${section.id}: delete_Deal_section_text_area_by_pk(id: "${section.id}") {
      id
    }
    `,
}