
import React, { useEffect, useRef, useState } from 'react';
import AllClientsTable from './ClientTables/AllClientsTable';
import InterestedClientsTable from './ClientTables/InterestedClientsTable';
import WhitelistedClientsTable from './ClientTables/WhitelistedClientsTable';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { adminStyles, adminAlignmentStyles, adminDimensionsStyle } from '../AdminStyles';
import SingleClientPage from './SingleClientPage/SingleClientPage';
import DefaultHeader from './Headers/DefaultHeader';
import SingleClientHeader from './Headers/SingleClientHeader';
import OnboardingTemplateList from './ClinetOnboarding/OnboardingTemplateList';
import AddOnboardingFlow from './ClinetOnboarding/AddOnboardingFlow';
import { AddOnboardingFlowHeader } from './ClinetOnboarding/Headers';
import CreateAccountDialog from './ClientCreation';
import { useDispatch, useSelector } from 'react-redux';
import { adminCreateAccountActions } from '../../store/slices/rmCreatedAccount';
import { hasuraErrorHandler } from '../../shared/EventEmitter/EventEmitter';

const ClientsPanel = () => {
  const adminClasses = adminStyles();
  const canAccess = useSelector(store => store.permissionAsignments.read_client_info);
  const canCreateAccount = useSelector(store => store.permissionAsignments.create_acc_for_investor);
  const alignmentClasses = adminAlignmentStyles();
  const [nameFilter, setNameFilter] = useState("");
  const [showCreateAccountDialog, setShowCreateAccountDialog] = useState(false);
  const dispatch = useDispatch();

  const handleOpenCreateAccount = () => {
    if(canCreateAccount) {
      dispatch(adminCreateAccountActions.RESET());
      setShowCreateAccountDialog(true);
    } else {
      hasuraErrorHandler.printError("You do not have permission to create accounts");
    }
  }

  const handleCloseDialog = () => {
    setShowCreateAccountDialog(false);
    dispatch(adminCreateAccountActions.RESET());
  }

  if(!canAccess) {
    return <div style={{marginTop: "80px", textAlign: "center"}}>You do not have permission to view the clients tab</div>
  }
  return (
    <div style={{ marginTop: "63px" }}>
      <div className={adminClasses.headerContainer}>
        <div className={adminClasses.header}>
          {
            window.location.href.indexOf("view") !== -1
              ? (
                <Switch>
                  <Route path="/admin/clients/view/:id" component={() => <SingleClientHeader />} />
                </Switch>
              )
              : (
                window.location.href.indexOf("onboarding/add") !== -1 || window.location.href.indexOf("onboarding/edit") !== -1
                  ? <AddOnboardingFlowHeader/>
                  : <DefaultHeader changeFilter={setNameFilter} openCreateAccountDialog={() => handleOpenCreateAccount()}/>
              )
          }

        </div>
      </div>
      <div className={[adminClasses.contentContainer, alignmentClasses.flexAlignCenterOncolumn].join(' ')}>
        <Switch>
          <Route path="/admin/clients/interested" component={() => <InterestedClientsTable nameFilter={nameFilter} />} />
          <Route path="/admin/clients/whitelist" component={() => <WhitelistedClientsTable nameFilter={nameFilter} />} />
          <Route path="/admin/clients/onboarding/add" component={() => <AddOnboardingFlow/>} />
          <Route path="/admin/clients/onboarding/edit/:flowId/:clientFirebaseId" component={() => <AddOnboardingFlow/>} />
          <Route path="/admin/clients/onboarding/edit/:flowId" component={() => <AddOnboardingFlow/>} />
          <Route path="/admin/clients/onboarding" component={() => <OnboardingTemplateList nameFilter={nameFilter} />} />
          <Route path="/admin/clients/all" component={() => <AllClientsTable nameFilter={nameFilter} />} />
          <Route path="/admin/clients/view/:id" component={() => <SingleClientPage />} />
          <Route path="/admin/clients/" component={() => <Redirect to="/admin/clients/all" />} />
        </Switch>
      </div>
     {showCreateAccountDialog && <CreateAccountDialog onClose={() => handleCloseDialog()} open={showCreateAccountDialog}/>}
    </div>
  )
}

export default ClientsPanel;