import React from 'react';
import { useDispatch } from 'react-redux';
import UploadComponent, { fileTypeOptions } from '../../../../shared/DocumentHandler/UploadComponent';
import { adminOpportunityActions } from '../../../../store/slices/adminOpportunity';

const ImagesSection =  ({section}) => {
  const image = section.data?.content || [];
  const dispatch = useDispatch();

  const handleValueChange = (content) => {
    dispatch(adminOpportunityActions.UPDATE_SECTION_CONTENT_DIFFERENCE({
      id: section.id, 
      content: content && content.length && content[0] ? content : []
    }));
  }
  
  const handleDeleteFile = (fileId) => {
    dispatch(adminOpportunityActions.DELETE_FILE({
      fileId: fileId,
      route: "image"
    }))
  }

  return (
    <div>
        <UploadComponent acceptedFileType={fileTypeOptions.IMAGE} deleteCallback={fileId => handleDeleteFile(fileId)} label="Drag and drop image here or" initialFiles={image} multiple={false} setNewFiles={value => handleValueChange(value)} customStyle={{marginBottom: "20px", marginLeft: "20px", marginRight: "20px"}}/>
    </div>
  )
}

export default ImagesSection;

