const initialState = {
  timers: [],
  toLog: [],
}

export const timingReducerActions = {
  "TIMING_START_TIMER": "TIMING_START_TIMER",
  "TIMING_END_TIMER": "TIMING_END_TIMER",
  "TIMING_END_ALL_TIMERS": "TIMING_END_ALL_TIMERS",
  "TIMING_LOGGING_DONE": "TIMING_LOGGING_DONE",
}

const extractTimingParams = (actionPayload) => [actionPayload.firebaseId, actionPayload.companyId, actionPayload.dealId, actionPayload.event, Date.now()];
export const packTimingParams = (paramsArray) => {
  const [firebaseId, companyId, dealId, event, value] = paramsArray;
  return {firebaseId, companyId, dealId, event, value: Date.now() - value};
};

export const timingReducer = (state = initialState, action = {type: null}) => {
  if(!timingReducerActions[action.type]){
    return state;
  }
  switch (action.type) {
    case timingReducerActions.TIMING_START_TIMER:
      return {
        ...state,
        timers: [...state.timers, extractTimingParams(action.payload)],
      }

    case timingReducerActions.TIMING_END_TIMER:
      const timerToEnd = extractTimingParams(action.payload);
      const foundTimer = state.timers.findIndex((timer) => timer.reduce((matchesSoFar, value, index) => matchesSoFar && index < 4 ? value === timerToEnd[index] : true, true));
      if(foundTimer > -1){
        return {
          ...state,
          toLog: [...state.toLog, packTimingParams(state.timers.splice(foundTimer,1)[0])],
          timers: [...state.timers],
        }
      }else{
        return {
          ...state 
        }
      }
    
    case timingReducerActions.TIMING_END_ALL_TIMERS:
      return {
        ...state,
        timers: [],
        toLog: state.timers.map(timerParams => packTimingParams(timerParams)),
      }
  
    case timingReducerActions.TIMING_LOGGING_DONE: 
      return {
        ...state,
        toLog: [],
      }
    default:
      // EventThrower.throwClientSideError(Events.InvalidParamsOrProps);
      // console.error('Timing error > Unknown action type', action.type);
      return state;
  }
};