import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import store from './store/store';
import { Provider } from 'react-redux';
import 'fontsource-roboto';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';

document.documentElement.dataset.scroll = window.scrollY;

if (process.env.REACT_APP_SENTRY_ENVIRONMENT)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_RELEASE_VERSION,
  });

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_GRAPHQL_HTTP_ENDPOINT,
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_HASURA_GRAPHQL_WS_ENDPOINT,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      return {
        headers: {
          Authorization: `Bearer ${store.getState().account.jwtToken}`,
        },
      }
    },
  },
})

const authLink = setContext((_, { headers }) => {
  const storeData = store.getState();
  if (storeData.account && storeData.account.jwtToken) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${storeData.account.jwtToken}`,
      }
    }
  } else {
    return {
      headers
    }
  }
});

const link = split(({ query }) => {
  const { kind, operation } = getMainDefinition(query);
  return kind === 'OperationDefinition' && operation === 'subscription';
},
  wsLink,
  authLink.concat(httpLink)
)

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
