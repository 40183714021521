import { InputAdornment, OutlinedInput } from "@material-ui/core";
import React, { useEffect, useState } from "react"
import { adminStyles, adminAlignmentStyles } from "../AdminStyles";
import SearchIcon from '@material-ui/icons/Search';

export const DefaultChatHeader = ({handleNameFilterChange}) => {
  const adminClasses = adminStyles();
  const alignmentClasses = adminAlignmentStyles();
  const [nameFilter, setNameFilter] = useState("")

  useEffect(() => {
    if(handleNameFilterChange) {
      handleNameFilterChange(nameFilter)
    }
  }, [nameFilter])

  return (
      <div className={alignmentClasses.flex}>
        <OutlinedInput
          placeholder="Search..."
          variant="outlined"
          className={adminClasses.searchField}
          style={{width: "274px"}}
          value={nameFilter}
          onChange={e => setNameFilter(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />     
        </div>
  )
}
