import { Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../shared/TextInput/TextInput'
import firebase from "firebase";
import { useDispatch } from 'react-redux';
import { LOGOUT, SET_USER_AUTH } from '../store/utils/action-types';
import { hasuraErrorHandler } from '../shared/EventEmitter/EventEmitter';
import { useLazyQuery } from '@apollo/client';
import { ADMIN_CHECK } from './queries/admin-queries';

const styles = makeStyles({
  alignCenter: {
    width: "100%",
    height: "calc(100vh - 50px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
})
const AdminLogin = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [adminCheck, adminCheckResult] = useLazyQuery(ADMIN_CHECK);

  const login = async () => {
    try {
      const firebaseUser = await firebase.auth().signInWithEmailAndPassword(email, password);
      const tokenResult = await firebaseUser.user.getIdTokenResult(true);
      if (tokenResult.token) {
        dispatch({ type: SET_USER_AUTH, payload: { jwtToken: tokenResult.token } });
        // adminCheck({variables: {firebase_id: firebaseUser.user.uid}});
        adminCheck({variables: {firebase_id: tokenResult.claims['https://hasura.io/jwt/claims']['x-hasura-user-id']}});
      }
    }
    catch (error) {
      if(error.message) {
        hasuraErrorHandler.printError(error.message)
      }
    }
  }

  useEffect(() => {
    if(adminCheckResult.data) {
      if(adminCheckResult.data.Permissions_assignment.length === 0) {
        hasuraErrorHandler.printError("You are not an admin")
        firebase.auth().signOut().then(() => {
          dispatch({ type: LOGOUT });
        });
      }
    }
  }, [adminCheckResult]);

  useEffect(() => {
    const onKeyUp = ({ key }) => {
      if (key === 'Enter') {
        login();
      }
    }
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    }
  })

  return (
    <div className={classes.alignCenter}>
      <img src={`${process.env.PUBLIC_URL}/images/logos/${localStorage.getItem("companyLogo")}`} alt="company logo" style={{ marginBottom: "60px" }} />
      <div className="login-container">
        <p id="title">Login</p>
        <TextInput label="Email" isPassword={false} handleChange={value => setEmail(value)} />
        <div>
          <TextInput label="Password" isPassword={true} handleChange={value => setPassword(value)} />
          <Link to="/reset-password">Reset password</Link>
        </div>
        <Button variant="contained" color="primary" onClick={login}> Login </Button>
      </div>
    </div>

  )
}

export default AdminLogin;